import { createAction } from '@reduxjs/toolkit';

/**
 * Defines an action to reset the slice back to initial state
 *
 * This action is meant to be called during user logout to reset
 * persisted state values back to initial state
 *
 * Only affects slices that implement `resetOnLogout`
 * as part of the `extraReducers` callback
 *
 * IMPORTANT:
 * + Our `redux-persist` configuration assumes every slice will be whitelisted
 *   in order to preserve state between browser refreshes
 * + By default, all slices should include `resetOnLogout`
 *   as part of the `extraReducers` callback
 * + Slices that require their current state to be preserved after user logout
 *   should not include `resetOnLogout` as part of the `extraReducers` callback
 */
export const resetOnLogout = createAction('RESET');

/**
 * Defines an action to sync the current slice's state with the initial state
 *
 * This action is meant to be called from the user login page to sync
 * persisted state values with the most up-to-date slice structure
 *
 * Only affects slices that implement `syncOnLogin`
 * as part of the `extraReducers` callback
 *
 * IMPORTANT:
 * + Our `redux-persist` configuration assumes every slice will be whitelisted
 *   in order to preserve state between browser refreshes
 * + Specific slices are intended to preserve their current state values
 *   into local storage after user logout
 * + Slices that require their current state to be preserved after user logout
 *   should include `syncOnLogin` as part of the `extraReducers` callback
 *
 * @see equipmentTableSlice
 * @see liveDispatchTableSlice
 * @see mapSettings
 */
export const syncOnLogin = createAction('SYNC');
