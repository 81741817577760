import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import clientTemplateApi from '~/api/ClientTemplateApi';
import { ClientTemplate } from '~/api/types/ApiClient';

interface UseGetSelectedClientTemplateReturnValue {
    templateData: ClientTemplate;
    isTemplateDataEmpty: boolean;
}

export const useGetSelectedClientTemplate = (
    selectedClientId: string
): UseGetSelectedClientTemplateReturnValue => {
    const [selectedClientTemplateData, setSelectedClientTemplateData] =
        useState({});
    const isTemplateDataEmpty = isEmpty(selectedClientTemplateData);

    const getTemplate = async (id: string) => {
        try {
            const {
                data: { data: clientData }
            } = await clientTemplateApi.get(id);
            setSelectedClientTemplateData(clientData);
        } catch (e) {
            setSelectedClientTemplateData({});
        }
    };

    useEffect(() => {
        getTemplate(selectedClientId);
        return () => setSelectedClientTemplateData({});
    }, [selectedClientId]);

    return { templateData: selectedClientTemplateData, isTemplateDataEmpty };
};
