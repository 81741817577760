import React from 'react';
import { TooltipButton } from '~/ui';
import classNames from 'classnames';

import './drawer-footerbutton.scss';

interface DrawerFooterButtonProps {
    className: string;
    buttonText: string;
    tooltipMessage: string;
    onClick: () => void;
}

function DrawerFooterButton({
    className,
    buttonText,
    tooltipMessage = '',
    onClick = () => {}
}: DrawerFooterButtonProps) {
    function handleOnClick() {
        return onClick && onClick();
    }

    function getClassName(): string {
        const boxClassName = 'drawer-footerbutton _text-2 button_clear';
        return classNames(boxClassName, className);
    }

    return (
        <TooltipButton
            className={getClassName()}
            tooltipMessage={tooltipMessage}
            onClick={handleOnClick}
            data-testid="drawerFooterButton"
        >
            <span>{buttonText}</span>
        </TooltipButton>
    );
}

export default DrawerFooterButton;
