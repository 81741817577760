import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownMenu, DropDownMenuItem } from '~/ui/components/DropDownMenu';

import './GeofenceDropdown.scss';
import { Tooltip } from '~/ui';

function GeofenceDropdown({ onCircularClick, onFreehandClick }) {
    const { t } = useTranslation(['customerManagement']);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownMenu = useRef();

    return (
        <Tooltip content={t('customerMap.dropdownTooltip')} placement="right">
            <DropDownMenu
                ref={dropdownMenu}
                title={{ icon: 'edit', name: t('customerMap.addGeofence') }}
                icon="chevronDown"
                isOpen={isDropdownVisible}
                toggle={() => setDropdownVisible(!isDropdownVisible)}
                close={() => setDropdownVisible(false)}
                styles={{
                    innerContainer: 'dropdown-flex geofencedropdown',
                    menuTitle: 'dropdown-selection geofencedropdown-title',
                    menuTitleText: 'dropdown-title-text',
                    dropdownToggleColor: 'var(--color-galaxy-800)',
                    menuItems: 'geofencedropdown-menuitems'
                }}
            >
                <DropDownMenuItem
                    menuItem={{
                        text: t('customerMap.circular'),
                        onClick: onCircularClick
                    }}
                />
                <DropDownMenuItem
                    menuItem={{
                        text: t('customerMap.freehand'),
                        onClick: onFreehandClick
                    }}
                />
            </DropDownMenu>
        </Tooltip>
    );
}

export default GeofenceDropdown;
