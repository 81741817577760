import {
    getFillColor,
    getStrokeColor,
    hextoRGB
} from '~/utils/map/drawing/utils';
import { HereMapsConstants } from '~/utils/hereMapsConstants';
import { HereMapsPolygon } from '~/components/HereMaps/types';
import { Coordinates } from '~/api/types';

export const highlightPolygon = (polygon: HereMapsPolygon) => {
    const data = polygon.getData();
    const { initialColor } = data || {};
    const rgba = hextoRGB(
        initialColor as string,
        HereMapsConstants.polygons.DEFAULT_POLYGON_HIGHLIGHT_FILL_OPACITY
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polygon.setStyle({ ...polygon.style, fillColor: rgba });
};

export const unhighlightPolygon = (polygon: HereMapsPolygon) => {
    const data = polygon.getData();
    const { initialColor } = data || {};
    const rgba = hextoRGB(
        initialColor as string,
        HereMapsConstants.polygons.DEFAULT_POLYGON_FILL_OPACITY
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polygon.setStyle({ ...polygon.style, fillColor: rgba });
};

export const getPolygon = (config: {
    perimeterCoordinates: Coordinates[];
    color: string;
    strokeColor: string;
    strokeWeight: number;
}) => {
    const { perimeterCoordinates, color, strokeColor, strokeWeight } = config;

    const linestring = new H.geo.LineString();
    perimeterCoordinates.forEach((point: Coordinates) => {
        linestring.pushPoint(point);
    });

    const fillColor = getFillColor(color);
    const stroke = getStrokeColor(strokeColor);
    const polygon = new H.map.Polygon(linestring, {
        style: {
            lineWidth: strokeWeight,
            fillColor,
            strokeColor: stroke
        },
        data: {
            initialColor: strokeColor
        }
    });
    return polygon;
};
