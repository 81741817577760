import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { Icon } from '~/ui';
import { useTranslation } from 'react-i18next';
import { useStopMarkerLabel } from './useStopMarkerLabel';
import eventBus from '~/EventBus';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import './stopmarker.scss';

function StopMarker({
    className,
    number = '-1',
    label = '',
    colorCSS = {},
    data = {},
    isPlanned,
    isHighPriority,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler = () => {},
    customLabel = undefined
}) {
    const { t } = useTranslation('common');

    const [isDisabled, setIsDisabled] = useState(false);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));

    const isSelected = useMemo(() => {
        return (
            selectedMapStops.includes(data.clientRouteTaskId) ||
            selectedTaskIds.includes(data.id)
        );
    }, [selectedMapStops, selectedTaskIds, data]);

    const { isAtRisk, isLate, taskType, isUnassigned, isDelivery } = data;

    const stopMarkerLabel = useStopMarkerLabel({
        customLabel,
        label,
        mapRouteMode
    });

    useEffect(() => {
        setIsDisabled(typeof emittedEventHandler !== 'function');
    }, [emittedEventHandler]);

    const handleMouseEnter = useCallback(() => {
        const payload = {
            location: {
                lat,
                lng
            },
            stopMarkerData: data
        };
        eventBus.publish(constants.mapChildEvents.STOP_MOUSEENTER, payload);

        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload
        });
    }, [lat, lng, data, emittedEventHandler]);

    const handleDragStart = useCallback(() => {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_DRAGSTART,
            payload: {
                isSelected,
                isPlanned,
                id: data.clientRouteTaskId,
                combinedIds: [data.clientRouteTaskId],
                clientRouteId: data.clientRouteId,
                type: constants.mapChildEvents.STOP_DRAGSTART,
                selectedMapStops
            }
        });
    }, [isSelected, isPlanned, selectedMapStops, data, emittedEventHandler]);

    const handleMouseUp = useCallback(() => {
        const payload = {
            isSelected,
            isTwoPart: data.isTwoPart,
            isPlanned,
            id: data.clientRouteTaskId,
            routeDate: data.routeDate,
            clientRouteId: data.clientRouteId,
            selectedMapStops,
            taskId: data.taskId,
            stopLevelData: data.toJSON()
        };
        eventBus.publish(constants.mapChildEvents.STOP_MOUSEUP, payload);
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload
        });
    }, [isSelected, isPlanned, selectedMapStops, data, emittedEventHandler]);

    const getClassName = () => {
        const boxClassName = 'stopmarker';
        const conditionalClasses = {
            stopmarker_selected: isSelected,
            'stopmarker-star': isHighPriority
        };
        return classNames(boxClassName, conditionalClasses, className);
    };

    const getIndicatorIconClass = () => {
        const boxClassName = 'stopmarker__icon _p-absolute';
        const conditionalClasses = {
            'icon--priority': isHighPriority,
            'icon--default': !isHighPriority
        };
        return classNames(boxClassName, conditionalClasses);
    };

    const getIndicatorIcon = () => {
        if (!isAtRisk && !isLate) return null;
        return (
            <Icon
                className={getIndicatorIconClass()}
                icon="clockFill3"
                color={isAtRisk ? 'venus' : 'mars'}
                size="s"
                stroke="comet"
                data-testid="clock-fill-icon"
            />
        );
    };

    const getUnassignedTaskStopIcon = () => {
        const isDeliveryTask =
            taskType === constants.taskTypes.DELIVERY || isDelivery;

        return isDeliveryTask
            ? t('unassignedTaskIcons.delivery')
            : t('unassignedTaskIcons.pickup');
    };

    return (
        <div className="_d-grid">
            <button
                type="button"
                className={getClassName()}
                onMouseEnter={handleMouseEnter}
                draggable="true"
                onDragStart={handleDragStart}
                onMouseUp={handleMouseUp}
                disabled={isDisabled}
                data-testid="stop-marker"
            >
                {isHighPriority && (
                    <div className="stopmarker-body">
                        <Icon
                            color={colorCSS.backgroundColor}
                            fillOpacity={colorCSS.opacity || 1}
                            icon="stopPriority"
                            size="xl"
                            data-testid="stop-marker-proprity"
                        />
                        {showStopNumber && (
                            <span className="stopmarker-star_number">
                                {number}
                            </span>
                        )}
                        {getIndicatorIcon()}
                    </div>
                )}
                {!isHighPriority && (
                    <div className="stopmarker-body">
                        <div
                            className="_p-relative"
                            data-testid="default-stop-marker"
                        >
                            <span
                                className="stopmarker-circle"
                                data-testid="stop-marker-circle"
                                style={colorCSS}
                            >
                                {isUnassigned
                                    ? getUnassignedTaskStopIcon()
                                    : showStopNumber && number}
                            </span>
                            {getIndicatorIcon()}
                        </div>
                    </div>
                )}
            </button>
            {stopMarkerLabel}
        </div>
    );
}

export default StopMarker;
