import {
    DateTime,
    DateTimeJSOptions,
    Duration,
    DurationObjectUnits
} from 'luxon';
import { TimeWindow } from '~/api/types';
import dateUtilsHelpers from './date-utils-helpers';

export type DateConstructorInput = Date | string | number;

/**
 * Date Converters
 *
 * @category Utils
 * @module utils/dateUtilsConverters
 *
 * @example
 * import dateUtilsConverters from '~/utils/date-utils-converters';
 */

/**
 * Converts ISO date string to JS Date
 *
 * Note: Until the entire app is converted to ts, we must return null as well
 *
 * @param {String} date - string format of ISO date, for example: 2021-01-01
 * @returns {Date} - JS Date
 *
 * @example
 * // returns JS date for 2021-01-01
 * const isoDate = dateUtilsConverters.convertISODateToJsDate('2021-01-01');
 *
 * // returns JS date for 1978-07-04
 * const nonIsoDate = dateUtilsConverters.convertISODateToJsDate('July 4 1978');
 *
 * // returns 'Invalid Date'
 * const badDate = dateUtilsConverters.convertISODateToJsDate('a long, long time ago...');
 *
 * // returns null
 * const nullDate = dateUtilsConverters.convertISODateToJsDate();
 *
 * @see [JS Date Object]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
function convertISODateToJsDate(date: string): Date | null {
    const dateObj = new Date(date);
    const isValidDate =
        date && dateObj instanceof Date && !Number.isNaN(dateObj.getTime());
    return isValidDate ? dateObj : null;
}

/**
 * Convert ISO date to locale string (example: "5/12/2022, 2:21 PM")
 *
 * @param {string} date
 * @returns {string}
 */
function convertISODateToLocaleString(date: string): string {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
}

/**
 * Convert ISO date to numeric date (example: "2022/01/20")
 *
 * @param {string} date
 * @returns {string}
 */
function convertISODateToNumericDate(date: string, format = 'D'): string {
    return DateTime.fromISO(date).toFormat(format);
}

/**
 * Converts JS Date to ISO date string
 *
 * @param {Date} date - JS Date
 * @returns {String} date - string format of ISO date
 */
function convertJsDateToISODate(date: Date): string {
    return DateTime.fromJSDate(date).toISO();
}

/**
 * Formats the date or timestamp to ISO Date without time
 *
 * Note: until the entire app is converted to TS, we must return also return null
 *
 * @param {DateConstructorInput} date - JS Date or timestamp (example: 2021-04-07T00:00:00.000-04:00)
 * @returns {string} - ISO date format without time (example: 2021-04-07)
 */
function convertToISODateOnly(date: DateConstructorInput): string | null {
    if (!date) return null;

    const isDateString = typeof date === 'string';

    if (isDateString && !dateUtilsHelpers.isDateInvalid(date)) return date;

    return DateTime.fromJSDate(new Date(date)).toISODate();
}

/**
 * Convert date and time to ISO format
 *
 * @param {Date} date - JS date
 * @param {string | Date} time - 24-hour time (example: "09:00") | Date (example: Tue Apr 23 2024 11:55:48 GMT+0530)
 * @param {DateTimeJSOptions} options
 * @returns {string}
 */
function convert24HourDateTimeToISO(
    date: Date,
    time: string | Date,
    options: {
        dateTimeJSOptions?: DateTimeJSOptions;
        isConvertToUTC?: boolean;
    } = {}
): string {
    const { dateTimeJSOptions, isConvertToUTC } = options;
    let currentDateTimeWindow;
    let currentHour;
    let currentMinute;
    if (typeof time === 'string') {
        currentDateTimeWindow = date;
        [currentHour, currentMinute] = time.split(':');
    } else {
        currentDateTimeWindow = new Date(time);
        currentHour = currentDateTimeWindow.getHours().toString();
        currentMinute = currentDateTimeWindow.getMinutes().toString();
    }

    const dateTimeObject = DateTime.fromObject(
        {
            year: currentDateTimeWindow.getFullYear(),
            month: currentDateTimeWindow.getMonth() + 1,
            day: currentDateTimeWindow.getDate(),
            hour: parseInt(currentHour, 10),
            minute: parseInt(currentMinute, 10)
        },
        dateTimeJSOptions
    );

    return isConvertToUTC
        ? dateTimeObject.toUTC().toISO()
        : dateTimeObject.toISO();
}

/**
 * Get 24-hour time from ISO date time (example: "19:00")
 *
 * @param {string} time - time in ISO format
 * @returns {string}
 */
function get24HourTime(time: string): string {
    return DateTime.fromISO(time).toFormat('T');
}

/**
 * Convert milliseconds to number of hours and minutes
 *
 * @param { Number } milliseconds
 * @returns {DurationObjectUnits}
 */
function convertMillisecondsToHoursAndMinutesAndSeconds(
    milliseconds: number
): DurationObjectUnits {
    const duration = Duration.fromMillis(milliseconds)
        .shiftTo('hours', 'minutes', 'seconds')
        .normalize()
        .toObject();
    duration.minutes = duration.minutes && Math.floor(duration.minutes);
    duration.seconds = duration.seconds && Math.floor(duration.seconds);
    return duration;
}

/**
 * Convert seconds to number of hours and minutes and seconds
 *
 * @param { Number } seconds - The number of seconds to calculate the duration from
 * @returns { Object } An object representing the duration in hours, minutes, and seconds.
 */
function convertSecondsToHoursAndMinutesAndSeconds(seconds: number) {
    return Duration.fromMillis(seconds * 1000)
        .shiftTo('hours', 'minutes', 'seconds')
        .normalize()
        .toObject();
}

/**
 * Convert hours and minutes to seconds
 *
 * @param { Number } hours
 * @param { Number } minutes
 * @returns {Number}
 */
function convertHoursAndMinutesToSeconds(hours: number, minutes: number) {
    return Duration.fromObject({ hours, minutes }).as('second');
}

/**
 * Convert the start and end ISO date-time strings of a `TimeWindow`
 * to a specified string format.
 *
 * By default, they will be converted to `HH:mm` format, unless specified
 */
function convertTimeWindowToStringFormat(
    timeWindow: TimeWindow,
    format = 'HH:mm'
) {
    const { start, end } = timeWindow;
    const startTime = DateTime.fromISO(start).toFormat(format);
    const endTime = DateTime.fromISO(end).toFormat(format);
    return { startTime, endTime };
}

/**
 * Convert seconds to number of hours
 *
 * @param { Number } seconds - The number of seconds to calculate the duration from
 * @returns { Object } An object representing the duration in hours
 */
function convertSecondsToHours(seconds: number) {
    return Duration.fromMillis(seconds * 1000)
        .shiftTo('hours')
        .normalize()
        .toObject();
}

/**
 * Converts the time from UTC to a formatted localized time
 * @param {String} utcTime - time in UTC
 * @param {String} timezone - time zone identifier
 * @returns {String} localized 24-hour time
 */
const convertFromUTCToLocalTime = (utcTime: string, timezone?: string) => {
    return DateTime.fromISO(utcTime, { zone: 'utc' })
        .setZone(timezone)
        .toFormat('T');
};

/**
 * Convert the local time to UTC time (for the API)
 * @param {String} localTime - localized time
 * @param {String} timezone - time zone identifier
 * @returns {String} UTC 24-hour time
 */
const convertFromLocalToUTCTime = (localTime: string, timezone?: string) => {
    return DateTime.fromISO(localTime, { zone: timezone })
        .setZone('utc')
        .toFormat('HH:mm');
};

export default {
    convertISODateToJsDate,
    convertISODateToLocaleString,
    convertISODateToNumericDate,
    convertJsDateToISODate,
    convertToISODateOnly,
    convert24HourDateTimeToISO,
    get24HourTime,
    convertMillisecondsToHoursAndMinutesAndSeconds,
    convertHoursAndMinutesToSeconds,
    convertSecondsToHoursAndMinutesAndSeconds,
    convertTimeWindowToStringFormat,
    convertSecondsToHours,
    convertFromUTCToLocalTime,
    convertFromLocalToUTCTime
};
