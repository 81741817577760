import axios from 'axios';
import {
    AxiosApiResponse,
    IApiResponse,
    ApiShiftEndParams,
    ApiShiftEnd,
    DeleteDriverResponseData
} from './types';
import {
    getParamErrorDriverId,
    getParamErrorAssignmentId,
    getParamErrorTimestamp
} from './utils';

/**
 * Implementations of API methods under the /shiftend path
 *
 * @category API
 */
export class ShiftEndApi {
    /**
     * Path of the Shiftend API endpoint
     */
    private static readonly shiftendPath = '/shiftend';

    /**
     * Sends a POST /shiftend request with the given data
     */
    static post(data: ApiShiftEndParams): Promise<AxiosApiResponse> {
        const { driverId, lastAssignmentId, timestamp } = data;
        const driverIdError = getParamErrorDriverId(driverId);
        const lastAssignmentIdError =
            getParamErrorAssignmentId(lastAssignmentId);
        const timestampError = getParamErrorTimestamp(timestamp);
        const paramError =
            driverIdError || lastAssignmentIdError || timestampError;

        if (paramError) {
            return Promise.reject(paramError);
        }

        return axios.post<IApiResponse>(`${this.shiftendPath}`, data);
    }

    /**
     * Retrieves shift end data for a driver
     */
    static get(driverId: string): Promise<AxiosApiResponse<ApiShiftEnd>> {
        const driverIdError = getParamErrorDriverId(driverId);
        if (driverIdError) {
            return Promise.reject(driverIdError);
        }
        return axios.get(`${this.shiftendPath}/${driverId}`);
    }

    /**
     * Removes shift end data for a driver
     */
    static delete(
        driverId: string
    ): Promise<AxiosApiResponse<DeleteDriverResponseData>> {
        const driverIdError = getParamErrorDriverId(driverId);
        if (driverIdError) {
            return Promise.reject(driverIdError);
        }
        return axios.delete<IApiResponse>(
            `${this.shiftendPath}?driverId=${driverId}`
        );
    }
}
