import React from 'react';
import Text from '~/ui/components/Text';
import StopSearchField from './StopSearchField';

import './StopSelectionContent.scss';
import { LiveStop, PlanStop } from '~/data-classes';

type StopSelectionContentProps = {
    title: string;
    handleSelect: (value: PlanStop | LiveStop | null) => void;
    selectedStop: PlanStop | LiveStop | null;
};

const StopSelectionContent = ({
    title,
    selectedStop,
    handleSelect
}: StopSelectionContentProps) => {
    return (
        <div
            className="stop-selection-content"
            data-testid="stop-selection-content"
        >
            <Text className="_text-3" color="galaxy-800" variant="12-normal">
                {title}
            </Text>
            <StopSearchField
                setSelectedStop={handleSelect}
                selectedStop={selectedStop}
            />
        </div>
    );
};

export default StopSelectionContent;
