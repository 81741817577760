/* eslint-disable @typescript-eslint/no-explicit-any */

import { isNil, isPlainObject } from 'lodash';

/**
 * Given an object, returns a new object matching the structure of the original, where any values
 * that pass the condition function are retained in the object
 *
 * @param obj - The input object to filter from
 * @param condition - A function where the objects' values are passed as the argument, and a
 * a boolean specifying if the value passes the condition is returned
 * @returns - A filtered output object matching the structure of the input object
 */
export function filterObj<T extends Record<string, any>>(
    obj: T,
    condition: (val: any) => boolean
): T {
    const output = {} as T;
    const entries = Object.entries(obj);
    entries.forEach(([key, val]) => {
        if (condition(val)) {
            (output as any)[key] = val;
        }
    });

    return output;
}

/**
 * Given an object, returns a new object matching the structure of the original, where any values
 * that are `null` or `undefined` are filtered out of that object
 *
 * @param obj - The input object to filter from
 * @returns - A filtered output object with no `null` or `undefined` values
 */
export function filterNilFromObj<T extends Record<string, any>>(
    obj: T
): { [key in keyof T]: Exclude<T[key], null | undefined> } {
    return filterObj(obj, (val) => !isNil(val));
}

/**
 * Type-guard that checks if an input is an object, and types it as a Record if so
 */
export function isRecord(o: unknown): o is Record<string, unknown> {
    return isPlainObject(o);
}

/**
 * Given an object, returns whether the object has any values that are not null or undefined
 */
export function hasDefinedValue(o: Record<string, any>): boolean {
    const values = Object.values(o);
    return values.some((value) => !isNil(value));
}
