import { IConfig as UnleashClientConfig } from '@unleash/proxy-client-react';
import { ServerCIEnvironment } from '~/servers/servers';

export interface UnleashConfig extends UnleashClientConfig {
    /**
     * The app name / CI environment
     *
     * The name of your application. It's only used for identifying your application
     */
    appName: ServerCIEnvironment;

    /**
     * The unleash proxy url
     *
     * Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
     */
    url: string;

    /**
     * The refresh interval in seconds
     *
     * How often (in seconds) the client should poll the proxy for updates
     */
    refreshInterval: number;

    /**
     * The client key
     *
     * A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
     */
    clientKey: string;
}

/**
 * Describes a map of CI environments to Unleash feature flags
 *
 * Each CI environment can have an associated Unleash feature flag
 */
export type EnvironmentFeatureFlags = { [K in ServerCIEnvironment]?: string };

/**
 * Describes a tuple of CI environments to Unleash feature flags
 */
export type EnvironmentFeatureFlagEntry = [
    ServerCIEnvironment,
    string | undefined
];

/**
 * Supported feature flag suffixes
 */
export const FeatureFlagEnvironmentSuffix: Partial<
    Record<ServerCIEnvironment, string>
> = {
    [ServerCIEnvironment.STAGE]: 'stage',
    [ServerCIEnvironment.UAT]: 'uat',
    [ServerCIEnvironment.CN_UAT]: 'cn-uat',
    [ServerCIEnvironment.DEMO]: 'demo',
    [ServerCIEnvironment.PRODUCTION]: 'production'
};
