import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { theme } from '~/ui';
import { colorUtils } from '~/utils/color-utils';
import { idUtils } from '~/utils/id-utils';

/**
 * Represents the styles for the selected drawer card.
 */
interface SelectedDrawerCardStyles {
    /**
     * The color for the icon
     */
    iconColor: string;

    /**
     * The style object for the icon
     * Typically consists of backgroundColor for the icon
     */
    iconStyle: React.CSSProperties;
}

interface SelectedDrawerCard {
    id?: string;
    clientId?: string;
    client?: {
        clientId: string;
    };
    driver?: {
        driverId: string;
    };
}

interface UseSelectedDrawerCardStylesProps {
    selectedDrawerCardData?: unknown;
}

/**
 * Custom hook that provides styles for the selected drawer card.
 * @returns {SelectedDrawerCardStyles} The styles for the selected drawer card.
 */
export const useSelectedDrawerCardStyles = ({
    selectedDrawerCardData: selectedDrawerCardDataProps
}: UseSelectedDrawerCardStylesProps = {}): SelectedDrawerCardStyles => {
    const { isPlanRouteMode } = useMapUtils();

    // Get the selected drawer card from the Redux store
    const { data: selectedDrawerCardDataRedux } =
        useSelector(selectSelectedDrawerCardData) ?? {};
    const selectedDrawerCard = (selectedDrawerCardDataProps ??
        selectedDrawerCardDataRedux) as SelectedDrawerCard;

    return useMemo(() => {
        const cardStyles = {
            iconColor: theme.colors.comet,
            iconStyle: {
                backgroundColor: theme.colors['galaxy-500']
            }
        };

        if (!selectedDrawerCard || isEmpty(selectedDrawerCard))
            return cardStyles;

        let clientDriverId = '';

        if (isPlanRouteMode) {
            const { driverId = '' } = selectedDrawerCard?.driver || {};
            const { clientId = '' } = selectedDrawerCard?.client || {};
            clientDriverId = idUtils.getCombinedId(clientId, driverId);
        } else {
            const { id: driverId = '', clientId = '' } = selectedDrawerCard;
            clientDriverId = idUtils.getCombinedId(clientId, driverId);
        }

        const { color, backgroundColor } =
            colorUtils.getWebColorsForId(clientDriverId);

        if (color) cardStyles.iconColor = color;
        if (backgroundColor)
            cardStyles.iconStyle.backgroundColor = backgroundColor;

        return cardStyles;
    }, [isPlanRouteMode, selectedDrawerCard]);
};
