import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnFiltersState,
    VisibilityState,
    SortingState,
    ColumnOrderState
} from '@tanstack/react-table';
import {
    selectGlobalFilter,
    selectSortConfig,
    selectTasksColumnFilters,
    selectTasksColumnOrder,
    selectTasksColumnVisibility,
    setGlobalFilter,
    setSortConfig,
    setTasksColumnFilters,
    setTasksColumnOrder,
    setTasksColumnVisibility
} from '~/reducers/liveDispatchTableSlice';
import constants from '~/utils/constants';
import {
    columnIdAndSortablePropertyKeysMapping,
    LiveDispatchTaskTableColumnIds as columnIds
} from '../types';
import { isEmpty } from 'lodash';

export const useLiveDispatchTableStates = () => {
    const columnFilters = useSelector(selectTasksColumnFilters);
    const columnOrder = useSelector(selectTasksColumnOrder);
    const columnVisibility = useSelector(selectTasksColumnVisibility);
    const sortConfig = useSelector(selectSortConfig);
    const globalFilter = useSelector(selectGlobalFilter);

    const dispatch = useDispatch();

    const setColumnFilters = useCallback(
        (payload: ColumnFiltersState) => {
            dispatch(setTasksColumnFilters(payload));
        },
        [dispatch]
    );

    const setColumnOrder = useCallback(
        (payload: ColumnOrderState) => {
            dispatch(setTasksColumnOrder(payload));
        },
        [dispatch]
    );

    const setColumnVisibility = useCallback(
        (payload: VisibilityState) => {
            dispatch(setTasksColumnVisibility(payload));
        },
        [dispatch]
    );

    const setSorting = useCallback(
        (payload: SortingState) => {
            dispatch(setSortConfig(payload));
        },
        [dispatch]
    );

    const setLiveDispatchGlobalFilter = useCallback(
        (payload: string) => {
            dispatch(setGlobalFilter(payload));
        },
        [dispatch]
    );

    const onApplySorting = useCallback(
        (id: string, sortingType: string) => {
            const convertedId = columnIdAndSortablePropertyKeysMapping[id];
            const isSortDescending = sortingType === constants.descending;
            setSorting([
                {
                    id: convertedId,
                    desc: isSortDescending
                }
            ]);
        },
        [setSorting]
    );

    const onClearSorting = useCallback(() => {
        setSorting([]);
    }, [setSorting]);

    const onClearColumnOrder = useCallback(() => {
        setColumnOrder(columnIds);
    }, [setColumnOrder]);

    const onClearColumnVisibility = useCallback(() => {
        const visibleColumns = columnIds.map((columnId) => [columnId, true]);
        setColumnVisibility(Object.fromEntries(visibleColumns));
    }, [setColumnVisibility]);

    const resetColumnFilters = useCallback(() => {
        dispatch(setTasksColumnFilters([]));
    }, [dispatch]);

    useEffect(() => {
        if (!columnOrder.length || columnOrder.length !== columnIds.length) {
            onClearColumnOrder();
        }
    }, [columnOrder, onClearColumnOrder]);

    useEffect(() => {
        if (
            isEmpty(columnVisibility) ||
            Object.keys(columnVisibility).length !== columnIds.length
        ) {
            onClearColumnVisibility();
        }
    }, [columnVisibility, onClearColumnVisibility]);

    return {
        columnFilters,
        columnOrder,
        columnVisibility,
        globalFilter,
        sorting: sortConfig,
        setColumnFilters,
        resetColumnFilters,
        setColumnOrder,
        setColumnVisibility,
        setLiveDispatchGlobalFilter,
        setSorting,
        onApplySorting,
        onClearSorting,
        onClearColumnOrder,
        onClearColumnVisibility
    };
};
