import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useClickOutside } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { ModalSize, ModalProps } from './types';
import { ModalTitle } from './ModalTitle';
import { ModalCloseButton } from './ModalCloseButton';
import './modal.scss';

/**
 * The root class name that represents a `block` in BEM naming convention
 *
 * All base classnames for `elements` and `modifiers` will be derived from this
 *
 * This will also be used for the default `data-testid` for the component.
 */
const ROOT_CLASS_NAME = 'modal';

/**
 * The element ID where the modal component will be rendered to
 */
const MODAL_ROOT_ID = 'modal-root';

const Modal = ({
    title,
    children,
    variant = ModalSize.SMALL,
    className,
    overlayClassName,
    icon,
    iconColor,
    iconSize = IconSizes.M,
    hasCustomHeader = false,
    hasAutoHide = true,
    hasCloseButton = true,
    onHide,
    ...extra
}: ModalProps) => {
    const dataTestId = extra['data-testid'] || ROOT_CLASS_NAME;
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        {
            [`${ROOT_CLASS_NAME}_${variant}`]: variant
        },
        className
    );
    const headerClassName = classNames(`${ROOT_CLASS_NAME}-header`, {
        [`${className}__header`]: className
    });
    const wrapperClassName = classNames(
        `${ROOT_CLASS_NAME}-content-wrapper`,
        '_fd-column',
        {
            [`${className}__wrapper`]: className
        }
    );

    const domElement = document.getElementById(MODAL_ROOT_ID);
    const ref = useRef<HTMLDivElement | null>(null);

    const autoHide = () => {
        return hasAutoHide && onHide?.();
    };

    useClickOutside(ref, autoHide);

    // Modal will be rendered under modal-root div
    return ReactDOM.createPortal(
        <div
            className={classNames('overlay', overlayClassName)}
            data-testid={`${dataTestId}-overlay`}
        >
            <div
                className={elementClassName}
                ref={ref}
                data-testid={dataTestId}
            >
                <div
                    className={wrapperClassName}
                    data-testid={`${dataTestId}-content-wrapper`}
                >
                    {!hasCustomHeader && (
                        <div
                            className={headerClassName}
                            data-testid={`${dataTestId}-header`}
                        >
                            {title && (
                                <ModalTitle
                                    className={className}
                                    dataTestId={dataTestId}
                                    icon={icon}
                                    iconSize={iconSize}
                                    iconColor={iconColor}
                                    title={title}
                                />
                            )}
                            {hasCloseButton && (
                                <ModalCloseButton
                                    dataTestId={dataTestId}
                                    onClick={onHide}
                                />
                            )}
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>,
        domElement as Element
    );
};

export default Modal;
