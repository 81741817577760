import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import InventoryItemsApi from '~/api/InventoryItemsApi';
import { useToastContext } from '~/hooks';
import generalUtils from '~/utils/general-utils';
import { ApiInventoryItem } from '~/api/types';

const initialData: ApiInventoryItem[] = [];

const getInventoryItems = async ({
    taskId,
    limit = 1000
}: {
    taskId: string;
    limit?: number;
}): Promise<ApiInventoryItem[]> => {
    const {
        data: { data }
    } = await InventoryItemsApi.get({
        limit,
        task_id: taskId,
        signed: true
    });

    return data;
};

interface UseGetTaskInventoryItemsProps {
    /**
     * The task id
     */
    taskId?: string | null;
    /**
     * The max number of inventory items to fetch
     */
    limit?: number;
    /**
     * Whether to disable fetching the inventory items
     */
    isDisabled?: boolean;
}

export const useGetTaskInventoryItems = ({
    taskId,
    limit,
    isDisabled
}: UseGetTaskInventoryItemsProps) => {
    const { t } = useTranslation('error');
    const { addToast } = useToastContext();
    const isValidTaskId = Boolean(taskId && generalUtils.isValidUUID(taskId));
    const enabled = isValidTaskId && !isDisabled;

    const { data: taskInventoryItems = initialData, isLoading } = useQuery(
        [InventoryItemsApi.REACT_QUERY_KEY, taskId, limit],
        () => getInventoryItems({ taskId: taskId ?? '', limit }),
        {
            enabled,
            onError: () => {
                addToast({
                    message: t('inventoryItemsFetchError'),
                    variant: 'error'
                });
            }
        }
    );

    return {
        isLoading,
        taskInventoryItems
    };
};
