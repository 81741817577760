import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { FlexLayout, Icon, Text } from '~/ui';
import { ToastVariants } from './ToastVariants';

const variantStyles = {
    [ToastVariants.INFO]: {
        icon: 'infoCircle',
        iconColor: 'sky'
    },
    [ToastVariants.WARNING]: {
        icon: 'warningFill',
        iconColor: 'venus'
    },
    [ToastVariants.ERROR]: {
        icon: 'declinedFill',
        iconColor: 'mars'
    },
    [ToastVariants.SUCCESS]: {
        icon: 'checked',
        iconColor: 'earth'
    }
};

const FADEIN_TIMEOUT = 10;
const CLOSING_DURATION = 500;

const Toast = React.forwardRef(function Toast(
    {
        isPersisted = false,
        duration = 3000,
        message,
        variant = 'info',
        sx,
        onRemove,
        ...extra
    },
    ref
) {
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [transition, setTransition] = useState({
        opacity: 0
    });
    const selectedStyle = variantStyles[variant];

    const transitionState = {
        opacity: 0,
        height: 'unset',
        transition: 'all ease'
    };

    useEffect(() => {
        let fadeOutTimeout;

        // on load fade in
        const fadeInTimeout = setTimeout(
            () =>
                setTransition({
                    ...transitionState,
                    opacity: 1,
                    transition: 'all 0.8s ease'
                }),
            FADEIN_TIMEOUT
        );

        // on unload fade out
        if (isPersisted) {
            fadeOutTimeout =
                isCancelClicked &&
                setTimeout(
                    () =>
                        setTransition({
                            ...transitionState
                        }),
                    FADEIN_TIMEOUT
                );
        } else {
            fadeOutTimeout = setTimeout(
                () =>
                    setTransition({
                        ...transitionState,
                        transitionDuration: `${CLOSING_DURATION}ms`
                    }),
                duration
            );
        }

        // remove after fade out
        const onRemoveTimeout = setTimeout(() => {
            if (!isPersisted) onRemove();
        }, duration + CLOSING_DURATION);

        return () => {
            [fadeInTimeout, fadeOutTimeout, onRemoveTimeout].forEach(
                clearTimeout
            );
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isCancelClicked, isPersisted]);

    return (
        <FlexLayout
            ref={ref}
            alignItems="center"
            sx={{
                height: 0,
                backgroundColor: 'neptune-800',
                borderRadius: '0.8rem',
                padding: '1rem 1.5rem',
                ...transition,
                ...sx
            }}
            {...extra}
        >
            <Icon
                className="toast__icon-variant"
                color={selectedStyle.iconColor}
                icon={selectedStyle.icon}
                size="s"
                marginRight="0.8rem"
                data-testid="variant-icon"
            />
            <Text
                color="comet"
                variant="14-normal"
                data-testid="toast-message-container"
            >
                {message}
            </Text>
            {isPersisted && (
                <Icon
                    className="toast__icon-cancel"
                    icon="cancel"
                    color="comet"
                    size="s"
                    onClick={() => {
                        setIsCancelClicked(true);
                        onRemove();
                    }}
                    data-testid="toast-cancel-button"
                />
            )}
        </FlexLayout>
    );
});

Toast.variants = ToastVariants;

Toast.propTypes = {
    /** Duration of Toast */
    duration: PT.number,
    /** Toast message */
    message: PT.string,
    /** Toast style variant */
    variant: PT.oneOf(Object.values(ToastVariants)),
    /** Toast remove function - called when the toast wants to be removed */
    onRemove: PT.func
};

export default Toast;
