import axios from 'axios';
import {
    ApiCompartmentTypes,
    AxiosApiResponse,
    CompartmentType
} from './types';

/**
 * Implementations of API methods under the /compartment_types path
 *
 * @category API
 */
export default class CompartmentTypesApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/compartment_types';

    /**
     * Sends a GET /compartment_types request to get all compartment types
     * @returns {IApiResponse}
     */
    static get(): Promise<AxiosApiResponse<CompartmentType[]>> {
        return axios.get(this.PATH);
    }

    /**
     * Sends a POST /compartment_types request to add a new compartment type
     * @returns {AxiosApiResponse<ApiCompartmentTypes[]>}
     */
    static post(
        data: Partial<ApiCompartmentTypes>
    ): Promise<AxiosApiResponse<ApiCompartmentTypes>> {
        if (!data)
            return Promise.reject('Missing required compartment attributes');

        return axios.post(this.PATH, data);
    }

    /**
     * Sends a PUT /compartment_types/{id} request to edit a compartment type by id
     * @returns {AxiosApiResponse<ApiCompartmentTypes>}
     */
    static put(
        id: string,
        data: Partial<ApiCompartmentTypes>
    ): Promise<AxiosApiResponse<ApiCompartmentTypes>> {
        if (!data)
            return Promise.reject('Missing required compartment attributes');

        return axios.put(`${this.PATH}/${id}`, data);
    }

    /**
     * Sends a DELETE /compartment_types/{id} request to delete a compartment type by id
     */
    static delete(
        id: string
    ): Promise<AxiosApiResponse<Partial<ApiCompartmentTypes>>> {
        if (!id) return Promise.reject('Missing id');
        return axios.delete(`${this.PATH}/${id}`);
    }
}
