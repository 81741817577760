import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

export const routePlanEditSuggestionSlice = createSlice({
    name: 'routePlanEditSuggestion',
    initialState: {},
    reducers: {
        setRoutePlanEditSuggestion: (state, action) => {
            return action.payload;
        },
        resetRoutePlanEditSuggestion: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setRoutePlanEditSuggestion, resetRoutePlanEditSuggestion } =
    routePlanEditSuggestionSlice.actions;

export const selectRoutePlanEditSuggestion = (state: RootState) =>
    state.routePlanEditSuggestion;

export default routePlanEditSuggestionSlice.reducer;
