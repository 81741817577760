import React from 'react';
import { TextOverflowTooltip } from '~/ui/components';

import './InventoryListItem.scss';

const ROOT_CLASS_NAME = 'inventory-list-item';

interface InventoryListItemProps {
    itemName: string;
    planned: number;
    actual: number;
    isCompleted?: boolean;
}

export const InventoryListItem = ({
    itemName,
    planned,
    actual,
    isCompleted
}: InventoryListItemProps) => {
    return (
        <div className={ROOT_CLASS_NAME} data-testid="inventory-list-item">
            <TextOverflowTooltip content={itemName} placement="top">
                <span
                    className={`${ROOT_CLASS_NAME}__name _to-ellipsis`}
                    data-testid={`${ROOT_CLASS_NAME}-name`}
                >
                    {itemName}
                </span>
            </TextOverflowTooltip>
            <div>
                <span
                    className={`${ROOT_CLASS_NAME}__value`}
                    data-testid="planned-value"
                >
                    {planned}
                </span>
                {isCompleted && (
                    <span
                        className={`${ROOT_CLASS_NAME}__value`}
                        data-testid="actual-value"
                    >
                        &nbsp;| {actual}
                    </span>
                )}
            </div>
        </div>
    );
};
