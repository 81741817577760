import React from 'react';
import './Toggle.scss';
import classNames from 'classnames';
import { UIElement } from '../types';

interface ToggleProps extends UIElement {
    /**
     * Whether the toggle is in the `ON` state
     */
    isOn: boolean;

    /**
     * Whether the toggle is disabled
     */
    disabled?: boolean;

    /**
     * handler to call when the toggle is clicked
     */
    onToggle: () => void;
}

/**
 * The root class name that represents a `block` in BEM naming convention
 *
 * All base classnames for `elements` and `modifiers` will be derived from this
 *
 * This will also be used for the default `data-testid` for the component.
 */
const ROOT_CLASS_NAME = 'toggle';

export default function Toggle({
    isOn,
    disabled = false,
    onToggle,
    className,
    ...extra
}: ToggleProps) {
    const dataTestId = extra['data-testid'] || `${ROOT_CLASS_NAME}-switch`;
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        {
            'toggle--off': !isOn,
            'toggle--on': isOn,
            'toggle--disabled': disabled
        },
        className
    );
    const circleClassName = classNames(`${ROOT_CLASS_NAME}__circle`, {
        'toggle__circle--on': isOn,
        'toggle__circle--off': !isOn
    });

    return (
        <button
            type="button"
            data-testid={dataTestId}
            className={elementClassName}
            disabled={disabled}
            onClick={onToggle}
        >
            <div className={circleClassName} />
        </button>
    );
}
