/* eslint-disable camelcase */
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';
import getEntry from './getEntry';
import getErrors from './getErrors';

const transformZoneToZoneSheet = (sheetToJson, zonesData) => {
    const zoneEntries = sheetToJson.reduce(
        (entries, rowData) => {
            const errorObj = getErrors(
                terms.sheetName.ZONETOZONECOSTS,
                rowData,
                zonesData
            );

            if (errorObj.hasErrors) {
                entries.zoneErrors[errorObj.key] = errorObj;
                if (!entries.hasErrors) entries.hasErrors = true;
            } else {
                const entryObj = getEntry(
                    terms.sheetName.ZONETOZONECOSTS,
                    rowData,
                    zonesData
                );
                entries.zoneEntries[entryObj.key] = entryObj;
            }

            return entries;
        },
        {
            zoneEntries: {},
            zoneErrors: {},
            hasErrors: false
        }
    );

    return zoneEntries;
};

export default transformZoneToZoneSheet;
