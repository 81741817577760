/**
 * Enum of supported icon sizes
 */
export enum IconSizes {
    XXXS = 'xxxs',
    XXS = 'xxs',
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
    XXL = 'xxl'
}

export type IconSize = `${IconSizes}`;
