function getInitials(string) {
    const words = string.split(' ');
    return words
        .filter((word) => word.length > 0)
        .map((word) => word[0])
        .join('.')
        .toUpperCase();
}

export default {
    getInitials
};
