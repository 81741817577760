import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectDispatchedDrivers,
    selectCompletedDrivers
} from '~/reducers/liveDriversSlice';
import { useIntermodalFeatures, useMapUtils } from '~/hooks';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import makeDispatchedComponents from '~/utils/map-modes/dispatched-routes-mode';
import { UseDispatchedRouteMarkersReturnValue } from './types';

export const useDispatchedRouteMarkers = () => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance } = usePlanMapPropsContext();
    const { isDispatchedRouteMode } = useMapUtils();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const completedDrivers = useSelector(selectCompletedDrivers);

    const { enableLiveDispatch } = useIntermodalFeatures();

    const liveDrivers = useMemo(() => {
        if (!enableLiveDispatch) return dispatchedDrivers;
        return [...dispatchedDrivers, ...completedDrivers];
    }, [dispatchedDrivers, completedDrivers, enableLiveDispatch]);

    return useMemo<UseDispatchedRouteMarkersReturnValue>(() => {
        if (!isDispatchedRouteMode || !mapInstance) {
            return {
                routeMarkers: [],
                routeLines: [],
                routeMarkerCoordinates: []
            };
        }

        const {
            driverMarkers: routeMarkers,
            routeLines,
            driverMarkerCoordinates: routeMarkerCoordinates
        } = makeDispatchedComponents(liveDrivers, mapInstance);

        return {
            routeMarkers,
            routeLines,
            routeMarkerCoordinates
        } as unknown as UseDispatchedRouteMarkersReturnValue;
    }, [isDispatchedRouteMode, liveDrivers, mapInstance]);
};
