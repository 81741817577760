import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';
import { getUserRoleForFeature } from '~/utils/getUserRoleForFeature';
import constants from '~/utils/constants';

export const usePlanningFeaturePermissions = () => {
    const userGroups = useSelector(selectUserGroupsForCurrentUser);

    return useMemo(() => {
        const role = getUserRoleForFeature(
            userGroups,
            constants.userPermissionFeatures.PLANNING
        );
        const isAllowedToEdit = role === constants.userPermissionRoles.EDITOR;
        const isAllowedToView =
            isAllowedToEdit || role === constants.userPermissionRoles.VIEWER;
        const isAccessDenied = !isAllowedToEdit && !isAllowedToView;

        return {
            isAccessDenied,
            isAllowedToEdit,
            isAllowedToView
        };
    }, [userGroups]);
};
