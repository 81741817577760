import React, { useEffect, useState } from 'react';
import { Box, Icon } from '~/ui';
import { Input as ThemeInput } from 'theme-ui';

import {
    InputProps,
    TextInputProps,
    TextInputSupportedType,
    TextInputVariant,
    TextInputWidth,
    textInputPresetWidths,
    textInputVariantStyles
} from './types';

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
    { disabled, placeholder, value, type, sx, onChange, className, ...extra },
    ref
) {
    const [state, setState] = useState<string | number | undefined>(value);
    useEffect(() => setState(value), [value]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value: inputValue }
        } = event;

        setState(inputValue);
        onChange?.(inputValue);
    };

    return (
        <ThemeInput
            disabled={disabled}
            placeholder={placeholder}
            ref={ref}
            sx={sx}
            type={type}
            value={state}
            onChange={onInputChange}
            {...extra}
        />
    );
});

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    function TextInput(
        {
            disabled = false,
            placeholder = '',
            value = '',
            width = TextInputWidth.M,
            variant = TextInputVariant.BASIC,
            type = TextInputSupportedType.TEXT,
            sx = {},
            wrapperSx = {},
            onChange,
            className,
            ...extra
        },
        ref
    ) {
        const presetSx = {
            width: textInputPresetWidths[width],
            ...textInputVariantStyles[variant]
        };

        const {
            padding,
            border,
            borderRadius,
            color,
            backgroundColor,
            fontSize,
            width: inputWidth,
            position,
            bottom,
            placeholder: placeholderSx,
            disabled: disabledSx,
            focus: focusSx,
            icon
        } = presetSx;

        const themeInputSx = {
            padding,
            border,
            borderRadius,
            color,
            backgroundColor,
            fontSize,
            width: inputWidth,
            position,
            bottom,
            '&::placeholder': placeholderSx,
            '&:disabled': disabledSx,
            '&:focus': focusSx,
            ...sx
        };

        return (
            <Box
                className={className}
                sx={{
                    position: 'relative',
                    ...wrapperSx
                }}
            >
                {icon && (
                    <Icon
                        icon={icon}
                        size="s"
                        sx={{ position: 'absolute', left: '1rem', top: '1rem' }}
                    />
                )}
                <Input
                    ref={ref}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    type={type}
                    onChange={onChange}
                    sx={themeInputSx}
                    {...extra}
                />
            </Box>
        );
    }
);

export default TextInput;
