import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import throttleRequestsHandler from '~/utils/socket/throttle-requests';

export const useRequestWebSolutionOnDateChange = (): void => {
    const routeDate = useSelector(selectDateOnly);
    const mainClient = useSelector(selectMainClient);
    const clientId = mainClient?.id;

    useEffect(() => {
        if (!clientId || !routeDate) {
            return;
        }

        throttleRequestsHandler.requestWebPlan({
            clientId,
            routeDate
        });
    }, [clientId, routeDate]);
};
