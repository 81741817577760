import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import constants, { isValueInConstantObject } from '~/utils/constants';
import type { RootState } from '~/store';
import type {
    ConfigurableMapRouteMode,
    MapMarkerMode,
    MapRouteMode,
    MapSettingsState,
    SetTabSpecificSettingAction
} from './types';
import { defaultGlobalMapSettings, stateObjectKeyMap } from './constants';
import { applyToPerTabSettings } from './utils';
import zonePolygonsReducers from './zonePolygonsReducers';

export const mapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState: defaultGlobalMapSettings,
    reducers: {
        ...zonePolygonsReducers,
        /**
         * set the current map mode setting for showing zone polygons
         */
        setShowZonePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            const {
                payload: { mode, value: isShowZonePolygons }
            } = action;
            const {
                isZonePolygonsEditMode: isZonePolygonsEditModeKey,
                showZonePolygons: showZonePolygonsKey
            } = stateObjectKeyMap;
            const newState = applyToPerTabSettings(
                mode,
                showZonePolygonsKey,
                state,
                isShowZonePolygons
            );

            if (isShowZonePolygons) return newState;

            return applyToPerTabSettings(
                mode,
                isZonePolygonsEditModeKey,
                newState,
                false
            );
        },

        /**
         * set the current map mode setting for showing route polygons
         */
        setShowRoutePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePolygons',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing route popups
         */
        setShowRoutePopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePopup',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing route labels
         */
        setShowRouteLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRouteLabel',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing stop popups
         */
        setShowStopPopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopPopup',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing stop numbers
         */
        setShowStopNumber: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopNumber',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop labels
         */
        setShowStopLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver popups
         */
        setShowLiveRoutePopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showLiveRoutesPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         */
        setShowLiveRouteLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showLiveRoutesLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         */
        setShowDriverName: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverName',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         */
        setShowVehicleEid: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showVehicleEid',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver lines
         */
        setShowDriverLines: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLines',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing equipment marker
         */
        setShowEquipmentMarker: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showEquipmentMarker',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting for showing unassigned tasks
         */
        setShowUnassignedTasks: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showUnassignedTasks',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting enabling showing unassigned tasks in cluster mode
         */
        setIsClusteringUnassignedTasks: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringUnassignedTasks',
                state,
                action.payload.value
            );
        },

        /**
         * set the global map setting whether the map has isolated routes
         */
        setHasIsolatedRoutes: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, hasIsolatedRoutes: action.payload };
        },

        /**
         * set the global map setting identifying the map marker mode
         */
        setMapMarkerMode: (
            state: MapSettingsState,
            action: PayloadAction<MapMarkerMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapMarkerMode>(
                    'mapMarkerModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapMarkerMode: action.payload };
        },

        /**
         * set the global map setting identifying the map route mode
         */
        setMapRouteMode: (
            state: MapSettingsState,
            action: PayloadAction<MapRouteMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapRouteMode>(
                    'mapRouteModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapRouteMode: action.payload };
        },

        /**
         * replace the global map settings
         */
        replaceMapSettings: (state, action) => {
            return action.payload;
        },

        /**
         * set the global map setting whether the map should fit markers to map bounds
         */
        setShouldFitPlanMapToBounds: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, shouldFitPlanMapToBounds: action.payload };
        },
        /**
         * set the global map setting whether the user was redirected to map page
         */
        setRedirectedToMapPage: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.redirectedToMapPage = action.payload;
            return state;
        },
        /**
         * set the current map mode setting enabling clustering stops
         */
        setIsClusteringStops: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringStops',
                state,
                action.payload.value
            );
        },
        /**
         * set the global map setting whether the map clustering toggle is enabled
         */
        setIsClusteringToggleEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringToggleEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is allowing multiple cards to be selected from the drawers
         */
        setIsMultipleCardSelectEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isMultipleCardSelectEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map drawer is set to view card details
         */
        setViewCardDetails: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.viewCardDetails = action.payload;
            return state;
        },

        /**
         * set the global map setting that determines currently active equipment cluster id
         */
        setActiveEquipmentClusterId: (
            state: MapSettingsState,
            action: PayloadAction<number>
        ): MapSettingsState => {
            state.activeEquipmentClusterId = action.payload;
            return state;
        },

        /**
         * set the current map mode setting for showing driver actual lines
         */
        setShowDriverActualLines: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverActualLines',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting enabling equipment cluster mode
         */
        setIsClusteringEquipment: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringEquipment',
                state,
                action.payload.value
            );
        },
        /**
         * Reset most global map settings back to default values, persisting select settings
         */
        resetMapState: (state: MapSettingsState): MapSettingsState => {
            const persistSettings = _.pick(state, [
                constants.mapRouteModes.PLAN,
                constants.mapRouteModes.DISPATCHED,
                constants.mapRouteModes.COMPLETED,
                'isClusteringToggleEnabled',
                'isClusteringStops',
                'mapRouteMode'
            ]);
            return {
                ...defaultGlobalMapSettings,
                ...persistSettings
            };
        },
        /**
         * reset the all global map settings back to default values
         */
        resetMapSettings: (): MapSettingsState => {
            return defaultGlobalMapSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            const { plan, dispatched, completed } = defaultGlobalMapSettings;
            const {
                plan: currentPlan,
                dispatched: currentDispatched,
                completed: currentCompleted
            } = state;

            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...defaultGlobalMapSettings,
                ...state,
                plan: {
                    ...plan,
                    ...currentPlan
                },
                dispatched: {
                    ...dispatched,
                    ...currentDispatched
                },
                completed: {
                    ...completed,
                    ...currentCompleted
                }
            };
        });
    }
});

export const {
    setActiveEquipmentClusterId,
    setHasIsolatedRoutes,
    setIsClusteringEquipment,
    setIsClusteringStops,
    setIsClusteringToggleEnabled,
    setIsClusteringUnassignedTasks,
    setIsMultipleCardSelectEnabled,
    setIsZonePolygonsEditMode,
    setMapMarkerMode,
    setMapRouteMode,
    setRedirectedToMapPage,
    setShouldFitPlanMapToBounds,
    setShowDriverActualLines,
    setShowDriverLines,
    setShowDriverName,
    setShowEquipmentMarker,
    setShowLiveRouteLabel,
    setShowLiveRoutePopup,
    setShowRouteLabel,
    setShowRoutePolygons,
    setShowRoutePopup,
    setShowStopLabel,
    setShowStopNumber,
    setShowStopPopup,
    setShowUnassignedTasks,
    setShowVehicleEid,
    setShowZonePolygons,
    setViewCardDetails,
    replaceMapSettings,
    resetMapSettings,
    resetMapState
} = mapSettingsSlice.actions;

/**
 * selects the current map mode setting for showing zone polygons
 */
export const selectShowZonePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showZonePolygons;

export const selectIsZonePolygonsEditMode =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.[
            stateObjectKeyMap.isZonePolygonsEditMode
        ];

/**
 * selects the current map mode setting for showing route polygons
 */
export const selectShowRoutePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePolygons;

/**
 * selects the current map mode setting for showing route popups
 */
export const selectShowRoutePopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePopup;

/**
 * selects the current map mode setting for showing route labels
 */
export const selectShowRouteLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRouteLabel;

/**
 * selects the current map mode setting for showing stop popups
 */
export const selectShowStopPopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopPopup;

/**
 * selects the current map mode setting for showing stop numbers
 */
export const selectShowStopNumber =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopNumber;

/**
 * selects the current map mode setting for showing stop labels
 */
export const selectShowStopLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopLabel;

/**
 * selects the current map mode setting for showing driver popups
 */
export const selectShowLiveRoutePopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showLiveRoutesPopup;

/**
 * selects the current map mode setting for showing driver labels
 */
export const selectShowLiveRouteLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showLiveRoutesLabel;

/**
 * selects the current map mode setting for showing driver lines
 */
export const selectShowDriverName =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverName;

/**
 * selects the current map mode setting for showing driver lines
 */
export const selectShowVehicleEid =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showVehicleEid;

/**
 * selects the current map mode setting for showing driver lines
 */
export const selectShowDriverLines =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverLines;

/**
 * selects the current map mode setting for showing equipment marker
 */
export const selectShowUnassignedTasks =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showUnassignedTasks;

/**
 * selects the current map mode setting for showing equipment marker
 */
export const selectShowEquipmentMarker =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showEquipmentMarker;

/**
 * selects the current map mode setting for showing unassigned tasks cluster mode
 */
export const selectedIsClusteringUnassignedTasks =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringUnassignedTasks;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 */
export const selectHasIsolatedRoutes = (state: RootState): boolean =>
    state.mapSettings.hasIsolatedRoutes;

/**
 * selects the current global map setting identifying the map marker mode
 */
export const selectMapMarkerMode = (state: RootState): MapMarkerMode =>
    state.mapSettings.mapMarkerMode;

/**
 * selects the current global map setting identifying the map route mode
 */
export const selectMapRouteMode = (state: RootState): MapRouteMode =>
    state.mapSettings.mapRouteMode;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 */
export const selectShouldFitPlanMapToBounds = (state: RootState): boolean =>
    state.mapSettings.shouldFitPlanMapToBounds;

/**
 * selects the current global map setting whether the user was redirected to map page
 */
export const selectRedirectedToMapPage = (state: RootState): boolean =>
    state.mapSettings.redirectedToMapPage;

/**
 * selects the current map mode setting enabling clustering stops
 */
export const selectIsClusteringStops =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringStops;

/**
 * selects the current global map setting whether the map clustering toggle is enabled
 */
export const selectIsClusteringToggleEnabled = (state: RootState): boolean =>
    state.mapSettings.isClusteringToggleEnabled;

/**
 * selects the current global map setting whether the map is allowing multiple cards to be selected from the drawers
 */
export const selectIsMultipleCardSelectEnabled = (state: RootState): boolean =>
    state.mapSettings.isMultipleCardSelectEnabled;

/**
 * selects the current global map settings
 */
export const selectMapSettings = (state: RootState): MapSettingsState =>
    state.mapSettings;

/**
 * selects the current global map setting whether the map drawer is set to view card details
 */
export const selectViewCardDetails = (state: RootState): boolean =>
    state.mapSettings.viewCardDetails;

/**
 * selects the current global map setting that determines the active equipment cluster id
 */
export const selectActiveEquipmentClusterId = (state: RootState): number =>
    state.mapSettings.activeEquipmentClusterId;

/**
 * selects the current map mode setting for showing driver actual lines
 */
export const selectShowDriverActualLines =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        Boolean(state.mapSettings[mapRouteMode]?.showDriverActualLines);

/**
 * selects the current map mode setting for showing equipment cluster mode
 */
export const selectIsClusteringEquipment =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringEquipment;

export default mapSettingsSlice.reducer;
