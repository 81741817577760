import React from 'react';
import { IconButton } from '~/ui';

interface ModalCloseButtonProps {
    dataTestId?: string;
    onClick?: () => void;
}

export const ModalCloseButton = ({
    dataTestId,
    onClick
}: ModalCloseButtonProps) => {
    const testId = `${dataTestId ?? 'modal'}-close-icon`;

    return (
        <IconButton
            className="icon-wrapper"
            icon="cancel"
            iconColor="galaxy-800"
            onClick={onClick}
            data-testid={testId}
        />
    );
};
