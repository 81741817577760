import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import dateUtils from '~/utils/date-utils';

export const useIsDispatchingAllowed = (): boolean => {
    const mainClient = useSelector(selectMainClient);
    const { allowDispatchingFutureDates } = mainClient?.preferences || {};

    const selectedDate = useSelector(selectDate);
    return (
        Boolean(allowDispatchingFutureDates) ||
        dateUtils.isSameDayOrToday(selectedDate)
    );
};
