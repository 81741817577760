import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectShowRoutePolygons,
    selectShowRoutePopup,
    selectShowRouteLabel,
    setShowRoutePolygons,
    setShowRoutePopup,
    setShowRouteLabel
} from '~/reducers/mapSettingsSlice';

import MapSettingMenuItem from '../MapSettingMenuItem/MapSettingMenuItem';
import MapSettingMenuButton from '../MapSettingMenuButton/MapSettingMenuButton';

import './map-settings-menu-sections.scss';

export default function MapSettingMenuRoutesSection({
    mapRouteMode,
    openSubMenu,
    showPolygonToggle
}) {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const showRoutePopupState = useSelector(selectShowRoutePopup(mapRouteMode));
    const showRoutePolygonsState = useSelector(
        selectShowRoutePolygons(mapRouteMode)
    );
    const showRouteLabelState = useSelector(selectShowRouteLabel(mapRouteMode));
    const routeMarkerClick = useCallback(() => {
        openSubMenu(t('routeMarker'), [
            {
                iconName: 'stopDetails',
                text: t('routePopup'),
                checkedState: showRoutePopupState,
                onChangeHandler: setShowRoutePopup
            },
            {
                iconName: 'stopLabel',
                text: t('routeLabel'),
                checkedState: showRouteLabelState,
                onChangeHandler: setShowRouteLabel
            }
        ]);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [showRoutePopupState, showRouteLabelState]);
    const onRoutePolygonToggleStateChange = useCallback(
        (checked) => {
            dispatch(
                setShowRoutePolygons({ mode: mapRouteMode, value: checked })
            );
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [mapRouteMode]
    );

    return (
        <>
            <span
                className="mapsettingssection_title"
                data-testid="map-setting-menu-routes-section-title"
            >
                {t('route_one')}
            </span>
            <MapSettingMenuButton
                iconName="routeMarker"
                text={t('routeMarker')}
                onClickHandler={routeMarkerClick}
            />
            {showPolygonToggle && (
                <MapSettingMenuItem
                    iconName="polygons"
                    text={t('routePolygons')}
                    checkedState={showRoutePolygonsState}
                    onChangeState={onRoutePolygonToggleStateChange}
                />
            )}
        </>
    );
}
