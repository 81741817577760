import { isInteger, partition, keyBy, isString } from 'lodash';
import {
    createAsyncThunk,
    createSlice,
    createSelector
} from '@reduxjs/toolkit';
import { v1 as uuid } from 'uuid';
import { DateTime } from 'luxon';

import { resetOnLogout } from '~/reducers/common-actions';
import { setTaskMetrics } from '~/reducers/taskMetricsSlice';
import tasksApi from '~/api/TasksApi';
import taskUtils from '~/utils/task-utils';
import constants from '~/utils/constants';

import {
    dispatchLoadTasksState,
    isFetchAllTasksAllowed,
    getRouteDate
} from './utils/tasksSlice';

export const getAllTasks = createAsyncThunk(
    'tasks/getAllTasks',
    async (
        { routeDate: routeDateProp, status, limit, selectedClientIds },
        { dispatch, getState, fulfillWithValue, rejectWithValue }
    ) => {
        const newRequestId = uuid();
        try {
            const routeDate = getRouteDate({
                getState,
                routeDate: routeDateProp
            });
            const newLoadTaskProps = [
                routeDate,
                status,
                limit,
                [...(selectedClientIds || [])]
            ];

            dispatchLoadTasksState({
                dispatch,
                getState,
                newRequestId,
                isRequestIdsMustMatch: false,
                newState: {
                    requestId: newRequestId,
                    requestProps: newLoadTaskProps,
                    lastFetchMilliseconds: DateTime.local().toMillis()
                }
            });

            // get task metrics and total task count
            const taskMetricsResponse = await tasksApi.getMetrics({
                date: routeDate
            });
            const taskMetrics = taskMetricsResponse.data.data;
            const totalTaskNum = taskMetrics.total;
            const apiStatus = taskUtils.getApiStatusFromWebStatus(status);

            // get tasks
            const tasksResponse = await tasksApi.get({
                date: routeDate,
                extent: constants.allSupportedTaskExtents,
                status: apiStatus,
                limit: isInteger(limit) ? limit : totalTaskNum
            });

            // remove depots from tasks and task metrics
            const [depotTasks, tasks] = partition(
                tasksResponse.data.data,
                taskUtils.checkIsDepot
            );
            const updatedTaskMetrics = taskUtils.removeDepotsFromTaskMetrics(
                depotTasks,
                taskMetrics
            );
            dispatch(setTaskMetrics(updatedTaskMetrics));

            // filter and format tasks for web
            const updatedTasks = taskUtils.formatApiTasksToWebTasks(tasks);
            const filteredTasks = taskUtils.filterTasksByTaskStatus(
                updatedTasks,
                status
            );
            const tasksById = keyBy(filteredTasks, 'id');

            dispatchLoadTasksState({
                dispatch,
                getState,
                newRequestId,
                newState: {
                    requestId: null
                }
            });

            const { tasks: currentState } = getState();

            // update the current state instead of totally replacing it
            const fulfilledValue = {
                ...currentState,
                ...tasksById
            };

            if (!selectedClientIds?.length)
                return fulfillWithValue(fulfilledValue);

            const fulfilledTasksValue = Object.fromEntries(
                Object.entries(fulfilledValue).filter(([, task]) => {
                    return selectedClientIds.includes(task.client);
                })
            );

            return fulfillWithValue(fulfilledTasksValue);
        } catch (error) {
            console.error(error);
            dispatchLoadTasksState({
                dispatch,
                getState,
                newRequestId,
                newState: {
                    requestId: null,
                    requestProps: null
                }
            });

            return rejectWithValue({});
        }
    },
    {
        condition: isFetchAllTasksAllowed
    }
);

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: { tasksListRefresh: false },
    reducers: {
        addTasks: (state, action) => {
            const tasksPayload = action.payload;
            const newState =
                tasksPayload?.reduce((aggregator, apiTask) => {
                    if (apiTask?.id) {
                        const taskStatus = taskUtils.getTaskStatus(apiTask);
                        aggregator[apiTask.id] = { ...apiTask, taskStatus };
                    }
                    return aggregator;
                }, {}) || {};

            return {
                ...state,
                ...newState
            };
        },

        addNewTask: (state, action) => {
            const task = action.payload;
            const taskStatus = taskUtils.getTaskStatus(task);
            state = { [task.id]: { taskStatus, ...task }, ...state };
            return state;
        },

        removeTasksById: (state, action) => {
            const taskIds = action.payload;
            taskIds.forEach((id) => {
                if (!isString(id)) return;
                delete state[id];
            });
        },

        updateTaskProperty: (state, action) => {
            const { taskId, property, value } = action.payload;
            const newState = {
                ...state,
                [taskId]: { ...state[taskId], [property]: value }
            };
            return newState;
        },

        updateTaskById: (state, action) => {
            const { taskId, value } = action.payload;
            return {
                ...state,
                [taskId]: { ...value }
            };
        },

        updateTaskRefresh: (state, action) => {
            return {
                ...state,
                tasksListRefresh: action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
        builder.addCase(getAllTasks.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
        builder.addCase(getAllTasks.rejected, (state, action) => {
            state = action.payload;
            return state;
        });
    }
});

export const {
    addTasks,
    addNewTask,
    removeTasksById,
    updateTaskProperty,
    updateTaskById,
    updateTaskRefresh
} = tasksSlice.actions;

export const selectTaskById = (id) => (state) => state.tasks[id];

export const selectTasksById = (state, ids) =>
    ids?.map((id) => state.tasks?.[id]);

export const selectTasksByStatus = (tasks, taskStatus) => {
    // set single status code to array
    const desiredTaskStatus = isInteger(taskStatus) ? [taskStatus] : taskStatus;

    // filter tasks
    return Object.values(tasks).filter((task) =>
        desiredTaskStatus.includes(task.taskStatus)
    );
};

export const selectOnDemandDispatchTasks = createSelector(
    (state) => state.tasks,

    (tasks) => {
        return selectTasksByStatus(tasks, [
            constants.taskStatus.UNASSIGNED,
            constants.taskStatus.PLANNED
        ]);
    }
);

export const selectUnassignedPlanTasks = createSelector(
    (state) => state.tasks,
    (tasks) => {
        return selectTasksByStatus(tasks, [constants.taskStatus.UNASSIGNED]);
    }
);

export const selectTasksByIds = createSelector(
    [(state) => state.tasks, (state, taskIds) => taskIds],
    (tasks, taskIds) => {
        const matchingTasks = taskIds.map((id) => {
            return tasks[id];
        });
        return matchingTasks.filter(Boolean);
    }
);

export const selectTasksByRouteIds = createSelector(
    [(state) => state.tasks, (state, routeIds) => routeIds],
    (tasks, routeIds) => {
        return Object.values(tasks).filter((task) =>
            routeIds.includes(task.routeId)
        );
    }
);

export const selectTasks = (state) => state.tasks;

export const selectNewTaskRefreshActive = (state) =>
    state.tasks.tasksListRefresh;

export default tasksSlice.reducer;
