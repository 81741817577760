import { v4 as uuid } from 'uuid';
import { useAddTaskModalContext } from '../../AddTaskModalContext';

export const useClickHandler = () => {
    const {
        clientTask: { invoices },
        onInvoicesChange
    } = useAddTaskModalContext();

    return () => {
        const newInvoices = [...invoices];
        newInvoices.push({
            frontendOnlyId: uuid(),
            amount: undefined,
            referenceNumber: undefined
        });
        onInvoicesChange(newInvoices);
    };
};
