import { HereMapsConstants } from '~/utils/hereMapsConstants';
import { getBoundingBoxParam } from '~/api/HereMaps/GeoApi/utils';

interface GeoSearchParams {
    queryString: string;
    bounds?: number[];
}

/**
 * Base URL of the API endpoint
 */
const BASEURL = 'https://autosuggest.search.hereapi.com/v1';

/**
 * Implementation of API methods for Here Maps autocomplete API
 *
 * @category API
 */
export class GeoSearchApi {
    /**
     * Base URL of the API endpoint
     */
    private static readonly baseURL = BASEURL;

    /**
     * Path of the API endpoint
     */
    private static readonly path = '/autosuggest';

    /**
     * API key
     */
    private static readonly apiKey = HereMapsConstants.apiKey;

    static async autoSuggestPlaces({ queryString, bounds }: GeoSearchParams) {
        if (!queryString) {
            return Promise.reject('Query string is not provided');
        }
        try {
            let boundsFormat;
            if (bounds) {
                boundsFormat = getBoundingBoxParam(bounds);
            }
            const urlParams = new URLSearchParams({
                show: 'details',
                apiKey: this.apiKey,
                q: queryString,
                ...(boundsFormat && { in: boundsFormat })
            });
            const url = `${this.baseURL}${this.path}?${urlParams}`;
            const response = await fetch(url);
            const results = await response.json();
            return await Promise.resolve(results);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
