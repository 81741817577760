import { useContext } from 'react';
import { ReportsPageContext, ReportsContext } from './ReportsContextProvider';

export const useReportsContext = (): ReportsContext => {
    const context = useContext(ReportsPageContext);

    if (!context) {
        throw new Error('useReportsContext must be within context provider');
    }

    return context;
};
