import React from 'react';
import { Icon } from '~/ui';

import './RouteCardLateTaskPrompt.scss';

type RouteItemTaskPromptProps = {
    numLateStops: number;
    numAtRiskStops: number;
};

const RouteCardLateTaskPrompt = ({
    numLateStops,
    numAtRiskStops
}: RouteItemTaskPromptProps) => {
    const totalRiskyStops = numLateStops + numAtRiskStops;

    if (!totalRiskyStops) {
        return null;
    }

    return (
        <div
            data-testid="prompt-container"
            className="routeitem__tasks-prompt _d-flex _fd-row _jc-space-between _ai-center"
        >
            <div className="routeitem__late-prompt">
                {Boolean(numAtRiskStops) && (
                    <Icon
                        icon="clockFill3"
                        color="venus"
                        size="s"
                        data-testid="at-risk-icon"
                        className="routeitem__risk-icon"
                    />
                )}
                {Boolean(numLateStops) && (
                    <Icon
                        icon="clockFill3"
                        color="mars"
                        size="s"
                        data-testid="late-icon"
                        className="routeitem__late-icon"
                    />
                )}
            </div>
            {Boolean(totalRiskyStops) && (
                <span data-testid="total-risky-count">{totalRiskyStops}</span>
            )}
        </div>
    );
};

export default RouteCardLateTaskPrompt;
