import { MutableRefObject } from 'react';
import { isEqual } from 'lodash';
import { TaskDetailSetting } from '../types';

export const localStorageKey = 'task-details-settings';

export const getStorageEventListener = ({
    currentUserId,
    setSelectedSettings,
    lastSavedSettingsRef
}: {
    currentUserId?: string;
    setSelectedSettings: (settings: TaskDetailSetting[]) => void;
    lastSavedSettingsRef: MutableRefObject<TaskDetailSetting[]>;
}) => {
    return (event: StorageEvent) => {
        if (!currentUserId || event.key !== localStorageKey) return;

        const parsedSettings = JSON.parse(event.newValue || '{}');
        const currentUserSettings = parsedSettings[currentUserId];

        if (
            currentUserSettings &&
            !isEqual(currentUserSettings, lastSavedSettingsRef.current)
        ) {
            lastSavedSettingsRef.current = currentUserSettings;
            setSelectedSettings(currentUserSettings);
        }
    };
};
