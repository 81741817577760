import { useQuery } from 'react-query';
import DriverApi from '~/api/DriverApi';

interface UseDriverRouteProps {
    /**
     * The selected driver id
     */
    driverId: string;
    /**
     * A boolean indicating whether fetching is enabled for the selected driver
     */
    isDisableFetch?: boolean;
}

export const useDriverRoute = ({
    driverId,
    isDisableFetch
}: UseDriverRouteProps) => {
    const REACT_QUERY_KEY = 'driverRoute';

    const { data: driverRouteData } = useQuery(
        [REACT_QUERY_KEY, driverId],
        async () => {
            const {
                data: { data }
            } = await DriverApi.getDriverRoute(driverId);
            return data;
        },
        {
            enabled: !isDisableFetch,
            onError: (error) => {
                console.error(error);
            }
        }
    );

    return {
        driverRouteData
    };
};
