import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Alert, CollapsibleCard, Icon, RouteCardMarkerNoButton } from '~/ui';
import SuggestedDriverMetric, {
    SuggestedMetricType,
    suggestMetricTypes
} from './SuggestedDriverMetric';
import generalUtils from '~/utils/general-utils';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';

import './suggesteddrivercard.scss';
import { selectMainClientPreference } from '~/reducers/mainClientSlice';
import { OnDemandDispatchSuggestions } from '~/utils/data-factory';
import { SuggestMetric, SupportedSuggestMetric } from '~/api/types';

interface SuggestedDriverCardProps {
    suggestedDriverData: OnDemandDispatchSuggestions;
    clientDriverId: string;
    driverColors: Pick<React.CSSProperties, 'color' | 'backgroundColor'>;
    orderedBy: SupportedSuggestMetric;
    isBestDriver: boolean;
    isOpen: boolean;
    onClick: (payload: Record<string, unknown>) => void;
}

export const SuggestedDriverCard = ({
    suggestedDriverData,
    clientDriverId,
    driverColors,
    orderedBy,
    isBestDriver,
    isOpen,
    onClick = () => {}
}: SuggestedDriverCardProps) => {
    const { t } = useTranslation();
    const { driver: driverData, suggestions: suggestionsData } =
        suggestedDriverData;
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(clientDriverId)
    );
    const isCustomCostEnabled = useSelector(
        selectMainClientPreference('useCustomCost')
    );
    const inducedDelays = suggestionsData[0].explainMetrics.newNumberOfDelays;

    function getCardHeader() {
        return (
            <div className="suggesteddrivercard-header _d-flex _ai-center">
                <RouteCardMarkerNoButton
                    initials={generalUtils.getUserInitials(
                        driverData.firstname as string,
                        driverData.lastname as string
                    )}
                    colorCSS={driverColors}
                />
                <div className="suggesteddrivercard-header_text _d-flex _fd-column _ai-flex-start">
                    <div className="_text-2 _to-ellipsis">
                        {generalUtils.getUserName(
                            driverData.firstname as string,
                            driverData.lastname as string
                        )}
                    </div>
                    {isBestDriver && (
                        <div className="suggesteddrivercard-best">
                            {t(
                                `SuggestMetrics.BestDriverOrderedBy.${orderedBy}`
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function getRouteTimeMetrics(
        metricData: SuggestedMetricType,
        explainMetrics: SuggestMetric
    ) {
        return (
            <SuggestedDriverMetric
                key={metricData.metricType}
                suggestMetricType={metricData}
                totalValue={
                    explainMetrics[metricData.totalSuggestMetricName] || 0
                }
                inducedValue={
                    explainMetrics[metricData.inducedSuggestMetricName] || 0
                }
                orderedBy={orderedBy}
            />
        );
    }

    function getCustomCostMetrics(
        metricData: SuggestedMetricType,
        explainMetrics: SuggestMetric
    ) {
        if (!isCustomCostEnabled) {
            return null;
        }
        return (
            <SuggestedDriverMetric
                key={metricData.metricType}
                suggestMetricType={metricData}
                totalValue={
                    explainMetrics[metricData.totalSuggestMetricName] || 0
                }
                inducedValue={
                    explainMetrics[metricData.inducedSuggestMetricName] || 0
                }
                orderedBy={orderedBy}
            />
        );
    }

    function getRouteDistanceMetrics(metricData: SuggestedMetricType) {
        if (!generalUtils.isFlaggedFeatureDisplayed()) {
            return null;
        }
        // currently under feature flag with dummy data since
        // metrics are not supported by API at the moment
        return (
            <SuggestedDriverMetric
                key={metricData.metricType}
                suggestMetricType={metricData}
                totalValue={7100}
                inducedValue={2500}
                orderedBy={orderedBy}
            />
        );
    }

    function getMetrics() {
        const { explainMetrics } = suggestionsData[0];
        const routeTimeMetrics = getRouteTimeMetrics(
            suggestMetricTypes.routeTime,
            explainMetrics
        );
        const customCostMetrics = getCustomCostMetrics(
            suggestMetricTypes.customCost,
            explainMetrics
        );
        const routeDistanceMetrics = getRouteDistanceMetrics(
            suggestMetricTypes.routeDistance
        );
        return [routeTimeMetrics, customCostMetrics, routeDistanceMetrics];
    }

    function handleClickDispatch() {
        const tasksToDispatch = suggestionsData.map((task) => {
            const {
                taskId,
                deliveryTime,
                pickupTime,
                pickupPosition,
                deliveryPosition
            } = task;

            // convert times from seconds to ISO date
            return {
                id: taskId,
                deliveryTime: DateTime.fromSeconds(
                    (deliveryTime || pickupTime) as number
                ).toISO(),
                pickupTime: DateTime.fromSeconds(
                    (pickupTime || deliveryTime) as number
                ).toISO(),
                position: pickupPosition || deliveryPosition
            };
        });

        const payload = {
            clientId: driverData.client,
            driverId: driverData.id,
            tasksToDispatch,
            isLiveDriver: Boolean(serialSelectedLiveDriver)
        };

        return onClick && onClick(payload);
    }

    return (
        <CollapsibleCard
            className="suggesteddrivercard"
            cardHeader={getCardHeader()}
            initOpen={isOpen}
        >
            <div className="suggesteddrivercard-metrics">
                {getMetrics()}
                {Boolean(inducedDelays) && (
                    <Alert
                        className="suggesteddrivermetric-delays"
                        variant="danger"
                        icon="warningFill2"
                        iconColor="mars"
                    >
                        {t('SuggestMetrics.inducedDelays', {
                            count: inducedDelays
                        })}
                    </Alert>
                )}
            </div>
            <button
                data-testid="dispatch-button"
                className="button _w-100"
                type="button"
                onClick={handleClickDispatch}
            >
                <Icon icon="iconRouteFill" size="s" color="comet" />
                <div className="suggesteddrivercard-dispatch_text">
                    {t('UnassignedTasksControl.button.dispatch')}
                </div>
            </button>
        </CollapsibleCard>
    );
};
