import SinglePlanEditApi, { TaskIdsByRoute } from '~/api/SinglePlanEditApi';
import constants from '~/utils/constants';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

export interface ReassignTasksHandlerProps {
    /**
     * The client id
     */
    clientId: string;
    /**
     * The driver id
     */
    driverId: string;
    /**
     * The id of the old route
     */
    oldRouteId: string;
    /**
     * The id of the new route
     */
    targetRouteId: string;
    /**
     * The ids of the tasks to be reassigned
     */
    taskIds: string[];
    /**
     * The map of route id to task ids
     */
    taskIdsByRouteId: TaskIdsByRoute;
}

interface ReassignPlanRouteTasksProps {
    /**
     * The available clients
     */
    clients: Record<string, { preferences: Record<string, unknown> }>;
    /**
     *
     * The dispatch function
     */
    dispatch: (props: unknown) => void;
    /**
     * The props passed to the reassign handler
     */
    reassignHandlerProps: ReassignTasksHandlerProps;
    /**
     * The selected date
     */
    selectedDate: string;
    /**
     * The translation function
     */
    translationFunction: (
        key: string,
        props: Record<string, unknown>
    ) => string;
}

export const reassignPlanRouteTasks = async ({
    clients,
    dispatch,
    reassignHandlerProps,
    selectedDate,
    translationFunction
}: ReassignPlanRouteTasksProps) => {
    const {
        clientId,
        driverId,
        oldRouteId,
        taskIds,
        taskIdsByRouteId,
        targetRouteId
    } = reassignHandlerProps;
    const selectedClient = clients[clientId];
    const { preferences: clientPreferences } = selectedClient;
    const numberOfTasks = taskIds.length;

    const processIndicatorState = {
        message: translationFunction('ReassigningStop', {
            count: numberOfTasks
        }),
        type: constants.processIndicator.REASSIGN_STOP,
        payload: numberOfTasks,
        inProgress: true,
        error: false,
        position: 'center'
    };

    try {
        dispatch(addProcessIndicator(processIndicatorState));

        if (oldRouteId === constants.entityStates.UNPLANNED) {
            await SinglePlanEditApi.augmentRoute(clientId, {
                driverId,
                taskIds,
                date: selectedDate,
                newRouteId: targetRouteId
            });
        } else {
            await SinglePlanEditApi.reassignTasks({
                clientId,
                clientPreferences,
                oldRouteId,
                taskIdsByRouteId,
                date: selectedDate,
                selectedDriverId: driverId,
                selectedRouteId: targetRouteId
            });
        }

        dispatch(resetSelectedMapStops());
    } catch (e) {
        console.error(e);
        dispatch(
            addProcessIndicator({
                ...processIndicatorState,
                inProgress: false,
                error: true
            })
        );
    }
};
