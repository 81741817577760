import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedInput } from '~/components/ValidatedInput';
import { LabelInput } from '~/ui';
import {
    alphaNumericCommitValidator,
    twoDecimalPlacesNonNegativeFloatValidator
} from '~/hooks';
import { DeleteButton } from '../../DeleteButton';
import { useAddTaskModalContext } from '../../AddTaskModalContext';
import { useOnInvoiceUpdate } from './useOnInvoiceUpdate';
import { useDeleteClickHandler } from './useDeleteClickHandler';

type Props = {
    invoiceId?: string;
};

export const InvoiceRow = ({ invoiceId }: Props) => {
    const { t } = useTranslation('addTask');
    const {
        clientTask: { invoices = [] }
    } = useAddTaskModalContext();
    const onInvoiceUpdate = useOnInvoiceUpdate({ invoiceId });
    const onDeleteHandler = useDeleteClickHandler({ invoiceId });

    const onAmountChange = (value: string) => {
        onInvoiceUpdate('amount', value);
    };

    const onReferenceNumberChange = (value: string) => {
        onInvoiceUpdate('referenceNumber', value);
    };

    const index = invoices.findIndex(
        ({ frontendOnlyId }) => frontendOnlyId === invoiceId
    );

    if (index === -1) return null;

    const isDeleteAllowed = Boolean(index);
    const { amount, referenceNumber } = invoices[index];

    return (
        <div
            className="add-task-modal__invoice__row _d-flex _p-relative"
            data-testid="invoice-row"
        >
            <ValidatedInput
                required
                inputComponent={LabelInput}
                validator={alphaNumericCommitValidator}
                value={referenceNumber}
                onValueChange={onReferenceNumberChange}
                data-testid="invoice-row-reference-number"
                placeholder={t('invoice.referenceNumber')}
            />
            <ValidatedInput
                required
                inputComponent={LabelInput}
                validator={twoDecimalPlacesNonNegativeFloatValidator}
                value={amount}
                onValueChange={onAmountChange}
                data-testid="invoice-row-amount"
                placeholder={t('invoice.amount')}
            />
            <span className="_d-grid">{t('invoice.currency')}</span>
            {isDeleteAllowed && (
                <DeleteButton
                    className="_p-absolute"
                    data-testid="invoice-row-delete"
                    onClick={onDeleteHandler}
                />
            )}
        </div>
    );
};
