import { useState, useEffect } from 'react';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import {
    selectIsClusteringStops,
    selectIsClusteringToggleEnabled,
    setIsClusteringStops,
    setIsClusteringToggleEnabled
} from '~/reducers/mapSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { PlanRoute } from '~/data-classes';

import { checkShouldFilterOutRoute } from '../utils';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

type UseRouteFilteringProps = {
    routesLevelData: PlanRoute[];
};

export const useRouteFiltering = ({
    routesLevelData
}: UseRouteFilteringProps) => {
    const dispatch = useDispatch();
    const { mapRouteMode } = useMapUtils();
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const isClusteringToggleEnabled = useSelector(
        selectIsClusteringToggleEnabled
    );
    const hiddenRoutes = useSelector(selectHiddenRoutes);
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);

    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const [visibleOnMapRouteData, setVisibleOnMapRouteData] = useState<
        PlanRoute[]
    >([]);

    // @TODO extract into a separate hook https://wisesys.atlassian.net/browse/RP-822
    function handleTasksLimit(numTasks: number) {
        const maxTasks = 2000;
        if (numTasks > maxTasks) {
            if (!isClusteringStops) {
                dispatch(
                    setIsClusteringStops({
                        mode: mapRouteMode as ConfigurableMapRouteMode,
                        value: true
                    })
                );
            }
            if (isClusteringToggleEnabled) {
                dispatch(setIsClusteringToggleEnabled(false));
            }
        } else if (!isClusteringToggleEnabled) {
            dispatch(setIsClusteringToggleEnabled(true));
        }
    }

    useEffect(() => {
        const visibleRoutes = [];
        let numKeptTasks = 0;
        for (const routeLevelData of routesLevelData) {
            const { numTasks, clientRouteId } = routeLevelData;
            if (!numTasks) {
                continue;
            }
            const shouldFilterOutNew = checkShouldFilterOutRoute(
                clientRouteId,
                hiddenRoutes,
                selectedMapRoutes,
                hasIsolatedRoutes,
                selectedDrawerRouteId
            );
            if (!shouldFilterOutNew) {
                numKeptTasks += numTasks;
                visibleRoutes.push(routeLevelData);
            }
        }
        handleTasksLimit(numKeptTasks);
        setVisibleOnMapRouteData(visibleRoutes);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        routesLevelData,
        hiddenRoutes,
        hasIsolatedRoutes,
        selectedDrawerRouteId
    ]);

    return {
        visibleOnMapRouteData
    };
};
