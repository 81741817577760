import classnames from 'classnames';
import { theme } from '~/ui';
import { useSelectedDrawerCardStyles } from '~/hooks';

const defaultClassName = 'resequence-modal-stop-item__left';

interface Props {
    /**
     * Whether the stop is unassigned
     */
    isUnassigned?: boolean;
    selectedDrawerCardData?: unknown;
}
export const useIconButtonStyles = ({
    isUnassigned,
    selectedDrawerCardData
}: Props) => {
    const { iconColor, iconStyle } = useSelectedDrawerCardStyles({
        selectedDrawerCardData
    });
    let iconButtonClassName = defaultClassName;
    let iconButtonTextColor;
    let iconButtonStyle;

    if (isUnassigned) {
        iconButtonClassName = classnames(
            iconButtonClassName,
            'unassigned-stop-item'
        );
        iconButtonTextColor = theme.colors.comet;
        iconButtonStyle = {
            backgroundColor: theme.colors['galaxy-500']
        };
    } else {
        iconButtonTextColor = iconColor;
        iconButtonStyle = iconStyle;
    }

    return {
        iconButtonClassName,
        iconButtonStyle,
        iconButtonTextColor
    };
};
