import { isBoolean } from 'lodash';
import {
    ConfigurableMapRouteMode,
    MapSettingsState,
    PerTabMapSettings
} from './types';
import { defaultPerTabMapSettings, perTabMapSettings } from './constants';

export const applyToPerTabSettings = (
    mapRouteMode: ConfigurableMapRouteMode,
    setting: keyof PerTabMapSettings,
    state: MapSettingsState,
    value: boolean | string
): MapSettingsState => {
    const isMapRouteModeSupported = perTabMapSettings[mapRouteMode];
    const isMapSettingSupported = Object.keys(
        defaultPerTabMapSettings
    ).includes(setting);
    const isValueBoolean = isBoolean(value);
    const isValueTypeInvalid =
        typeof value !== typeof state?.[mapRouteMode]?.[setting];

    if (
        !isMapRouteModeSupported ||
        !isMapSettingSupported ||
        !isValueBoolean ||
        isValueTypeInvalid
    ) {
        return state;
    }

    const updatedModeSettings = {
        ...state[mapRouteMode],
        [setting]: value
    };
    return { ...state, [mapRouteMode]: updatedModeSettings };
};
