import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { LiveStop } from '~/data-classes';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

import {
    selectShowStopLabel,
    selectShowStopNumber
} from '~/reducers/mapSettingsSlice';

import { Icon, IconData, RouteCardMarkerWithHollow } from '~/ui/';
import constants from '~/utils/constants';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { ViolationBadge } from '../ViolationBadge';
import {
    EmittedEventHandler,
    OnDemandDispatchMarkerEventHandler
} from './types';

import { IconSizes } from '../Icon/IconSizes';
import { useMapUtils } from '~/hooks';
import { StopMarkerNameAndLabels } from '~/ui/components/StopMarker/StopMarkerNameAndLabels';
import { useGetDriverColor } from './useGetDriverColor';
import eventBus from '~/EventBus';

import './LiveStopMarker.scss';

/**
 * Represents the properties of a live stop marker
 */
interface LiveStopMarkerProps {
    /** The sequence number of the stop */
    sequenceNumber: number;
    /** The stop data associated with the marker */
    stopData: LiveStop;
    /** The status of the stop */
    status: string;
    /** The map route mode for displaying the marker */
    mapRouteMode: ConfigurableMapRouteMode;
    /** Event handler for on-demand dispatch marker events */
    onDemandDispatchMarkerEventHandler: OnDemandDispatchMarkerEventHandler;
    /** Event handler for emitted events */
    emittedEventHandler: EmittedEventHandler;
    /** The latitude coordinate of the stop. */
    lat: number;
    /** The longitude coordinate of the stop. */
    lng: number;
}

function LiveStopMarker({
    sequenceNumber,
    stopData,
    status = constants.liveStopMarkerStatus.default,
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    emittedEventHandler,
    lat,
    lng
}: LiveStopMarkerProps) {
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const showStopLabel = useSelector(selectShowStopLabel(mapRouteMode));
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));
    const { isCompletedRouteMode } = useMapUtils();
    const { t } = useTranslation('translation');
    const colorCSS = useGetDriverColor(stopData?.driverId);

    const isSelected = useMemo(() => {
        const isStopSelected = selectedMapStops.includes(stopData?.id);
        return isStopSelected;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedMapStops]);

    if (!stopData) {
        return null;
    }

    const {
        isCompleted,
        isLate,
        isCanceled,
        isInProgress,
        hasInventoryException,
        isServiceTimeViolated,
        isHighPriority
    } = stopData;

    const isInteractive = !(isCanceled || isInProgress);
    const isDisabled = !isInteractive;
    const { backgroundColor, color } = colorCSS || {};
    const isStopCompletedStyle = {
        borderColor: backgroundColor,
        color: backgroundColor
    };

    function handleMouseEnter() {
        const payload = {
            location: {
                lat,
                lng
            },
            stopMarkerData: stopData
        };
        eventBus.publish(constants.mapChildEvents.STOP_MOUSEENTER, payload);
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload
        });
    }

    const iconData: IconData[] = [
        { icon: hasInventoryException ? 'inventoryWarning' : undefined },
        { icon: isLate ? 'etaWarning' : undefined },
        { icon: isServiceTimeViolated ? 'serviceTimeWarning' : undefined }
    ];

    function getClassNames() {
        const boxClassName = 'livestopmarker__icon';

        const conditionalClasses = {
            'icon-priority': status === constants.liveStopMarkerStatus.priority
        };
        return classNames(boxClassName, conditionalClasses);
    }

    function getDefaultMarkerStyle() {
        if (isCompleted) {
            return isStopCompletedStyle;
        }
        return colorCSS;
    }

    function getExceptionDelayClass() {
        const baseClass = 'livestopmarker__circle';

        const conditionalClasses = {
            'livestopmarker--completed': isCompleted
        };
        return classNames(baseClass, conditionalClasses);
    }

    function getHighPriorityStopBackgroundColor() {
        if (isCompleted) {
            return 'comet';
        }
        return backgroundColor;
    }

    function getMapMarkerLabel() {
        const { isDepot, name, labels } = stopData;
        const stopName = isDepot ? t('depot') : name;

        return showStopLabel ? (
            <StopMarkerNameAndLabels name={stopName} labels={labels} />
        ) : null;
    }

    function handleOnClick() {
        const isClickable = isInteractive && !isCompleted;
        if (!isClickable) return;

        onDemandDispatchMarkerEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                id: stopData.id,
                selectedMapStops,
                taskId: stopData.id,
                isTwoPart: stopData.isTwoPart,
                stopId: stopData.stopId,
                stopLevelData: stopData.toJSON()
            }
        });
    }

    function getClassName() {
        const defaultClassName = 'livestopmarker';
        const conditionalClasses = {
            'livestopmarker--selected': isSelected
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    const renderHighPriority = () => {
        return (
            <div className="livestopmarker livstopmarker__priority">
                <Icon
                    className="livestopmarker__canceled-priority"
                    onMouseEnter={handleMouseEnter}
                    icon="stopPriority"
                    size={IconSizes.L}
                    stroke={backgroundColor}
                    data-testid="live-stop-marker-canceled-priority"
                />
                <span className="livestopmarker__icon-dash">
                    <Icon
                        icon="remove"
                        color={isCompletedRouteMode ? backgroundColor : ''}
                        size={IconSizes.XS}
                        data-testid="icon-remove"
                    />
                </span>
            </div>
        );
    };

    const getCanceledStopMarker = () => {
        if (isHighPriority) {
            return renderHighPriority();
        }

        return (
            <RouteCardMarkerWithHollow
                iconColor={isCompletedRouteMode ? backgroundColor : ''}
                icon="remove"
                iconSize={IconSizes.S}
                className="livestopmarker livestopmarker__canceled-default"
                iconStyle={colorCSS}
                onMouseEnter={handleMouseEnter}
                data-testid="live-stop-marker-canceled-default"
            />
        );
    };

    const getRegularMarker = () => {
        return (
            <div className="_d-grid">
                <button
                    data-testid="live-stop-marker"
                    type="button"
                    className={getClassName()}
                    onMouseEnter={handleMouseEnter}
                    disabled={isDisabled}
                    onClick={handleOnClick}
                >
                    <div className="livestopmarker__icon-group _p-relative">
                        <span
                            className={getExceptionDelayClass()}
                            style={getDefaultMarkerStyle()}
                            data-testid="live-stop-marker-circle"
                        >
                            {showStopNumber && sequenceNumber}
                        </span>
                        <ViolationBadge
                            iconsData={iconData}
                            className={getClassNames()}
                        />
                    </div>
                </button>
                {getMapMarkerLabel()}
            </div>
        );
    };

    function getComponent() {
        switch (status) {
            case constants.liveStopMarkerStatus.canceled:
                return getCanceledStopMarker();
            case constants.liveStopMarkerStatus.priority:
                return (
                    <div className="_d-grid">
                        <button
                            type="button"
                            className="livestopmarker livestopmarker__priority"
                            onMouseEnter={handleMouseEnter}
                            disabled={isDisabled}
                            onClick={handleOnClick}
                        >
                            <div className="livestopmarker__icon-group _p-relative">
                                <span className="_p-relative">
                                    <Icon
                                        color={getHighPriorityStopBackgroundColor()}
                                        icon="stopPriority"
                                        size={IconSizes.XL}
                                        stroke={
                                            isCompleted ? backgroundColor : ''
                                        }
                                        data-testid="live-stop-marker-proprity"
                                    />
                                    {showStopNumber && (
                                        <span
                                            className="livestopmarker__priority-text"
                                            style={
                                                isCompleted
                                                    ? {
                                                          color: backgroundColor
                                                      }
                                                    : { color }
                                            }
                                        >
                                            {sequenceNumber}
                                        </span>
                                    )}
                                </span>
                                <ViolationBadge
                                    iconsData={iconData}
                                    className={getClassNames()}
                                />
                            </div>
                        </button>
                        {getMapMarkerLabel()}
                    </div>
                );
            default:
                return getRegularMarker();
        }
    }
    return getComponent();
}

export default LiveStopMarker;
