import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

type AccountSetup = {
    isMultiCompartmentClient?: boolean;
};

type AccountSetupState = {
    [key: string]: AccountSetup;
};

export const accountSetupByClientSlice = createSlice({
    name: 'accountSetupByClient',
    initialState: {} as AccountSetupState,
    reducers: {
        setAccountSetupForAllClients: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setAccountSetupForAllClients } =
    accountSetupByClientSlice.actions;
export const selectAccountSetupByClientId =
    (clientId: string) =>
    (state: RootState): AccountSetup => {
        return state.accountSetupByClient[clientId] as AccountSetup;
    };

export default accountSetupByClientSlice.reducer;
