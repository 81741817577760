import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DropDownWithInputBox,
    Icon,
    IconButton,
    RequiredFieldLabel,
    TooltipButton
} from '~/ui';
import { useGetCompartments } from '~/hooks/useGetCompartments';
import './MultiCompartmentInputs.scss';
import { useAddTaskModalContext } from '~/components/AddTaskModal/AddTaskModalContext';
import { TaskCompartment } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { v4 as uuid } from 'uuid';

type MultiCompartmentInputsType = {
    title: string;
    isRequiredField?: boolean;
};

export default function MultiCompartmentInputs({
    title,
    isRequiredField = true
}: MultiCompartmentInputsType): JSX.Element {
    const { t } = useTranslation('driverManagement');

    const { clientTask, onSizeByCompartmentChange } = useAddTaskModalContext();
    const { compartments } = useGetCompartments();
    const compartmentList = Object.values(compartments).map((compartment) => {
        return {
            text: compartment.label || '',
            unit: compartment.unit || '',
            id: compartment.id || ''
        };
    });

    const handleUpdateTaskCompartmentValue = useCallback(
        (values: Partial<TaskCompartment>, index: number) => {
            const newCompartments = clientTask.sizeByCompartment.map(
                (compartmentSize, i) => {
                    if (i === index) {
                        return {
                            ...compartmentSize,
                            ...values
                        };
                    }
                    return compartmentSize;
                }
            );

            onSizeByCompartmentChange(newCompartments);
        },
        [onSizeByCompartmentChange, clientTask.sizeByCompartment]
    );

    const handleAddTaskCompartment = useCallback(() => {
        onSizeByCompartmentChange([
            ...clientTask.sizeByCompartment,
            {
                componentId: uuid(),
                selectedCompartmentId: '',
                capacity: '',
                label: '',
                unit: '',
                isFirst: false
            }
        ]);
    }, [onSizeByCompartmentChange, clientTask.sizeByCompartment]);

    const handleDeleteTaskCompartment = useCallback(
        (index: number) => {
            clientTask.sizeByCompartment.splice(index, 1);

            onSizeByCompartmentChange(clientTask.sizeByCompartment);
        },
        [onSizeByCompartmentChange, clientTask.sizeByCompartment]
    );

    return (
        <div
            className="_fd-column multi-compartment-inputs"
            data-testid="multi-compartment-inputs"
        >
            <div className="_d-flex _jc-space-between _ai-center">
                {isRequiredField ? (
                    <RequiredFieldLabel label={title} />
                ) : (
                    <span className="multi-compartment-inputs__title">
                        {title}
                    </span>
                )}
                <TooltipButton
                    className="multi-compartment-inputs__add-button"
                    size={TooltipButton.sizes.MEDIUM}
                    data-testid="add-compartment-button"
                    onClick={handleAddTaskCompartment}
                >
                    <Icon icon="plus" size="xs" color="ocean" />
                    {t('addNewVehicleForm.compartment.addButton')}
                </TooltipButton>
            </div>
            <div className="multi-compartment-inputs__content _fd-column">
                {clientTask.sizeByCompartment.map((compartment, idx) => (
                    <div
                        key={compartment.componentId}
                        className="_d-flex _ai-center _w-100 _p-relative multi-compartment-inputs__input-row"
                    >
                        <DropDownWithInputBox
                            id={compartment.componentId as string}
                            dropDownTitle={compartment.label}
                            dropDownPlaceholder={t(
                                'addNewVehicleForm.compartment.dropdownPlaceholder'
                            )}
                            dropDownSelectedItemId={
                                compartment.selectedCompartmentId
                            }
                            dropDownMenuItems={compartmentList}
                            inputTestId={`validated-input-${compartment.componentId}`}
                            inputBoxValue={compartment.capacity || ''}
                            inputBoxPlaceholder={t(
                                'addNewVehicleForm.compartment.textboxPlaceholder'
                            )}
                            unitValue={compartment.unit}
                            onChange={(values) =>
                                handleUpdateTaskCompartmentValue(
                                    {
                                        label: values.label,
                                        selectedCompartmentId:
                                            values.dropDownItemId,
                                        capacity: values.value
                                    },
                                    idx
                                )
                            }
                        />
                        {idx !== 0 && (
                            <IconButton
                                className="multi-compartment-inputs__delete-icon"
                                icon="delete"
                                iconColor="galaxy-500"
                                onClick={() => handleDeleteTaskCompartment(idx)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
