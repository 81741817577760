import React, { WheelEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconButton, EquipmentMarker } from '~/ui';
import { EquipmentStackPinMarkerPopupProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedEquipmentIds,
    setSelectedEquipmentIds
} from '~/reducers/selectedEquipmentIdsSlice';
import {
    setIsLiveDispatchDrawerExpanded,
    setIsOpenLiveDispatchDrawer,
    setIsOpenUnassignedTasksDrawer,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import { setActiveEquipmentClusterId } from '~/reducers/mapSettingsSlice';
import { cloneDeep } from 'lodash';
import { Equipment } from '~/data-classes';

import './EquipmentMarkerPopup.scss';

export const rootClassName = 'stacked-equipment-pin-popup';
export const stackedStops = `${rootClassName}__stacked-equipment`;
export const scrollText = `${rootClassName}__scroll-text`;
export const stackPin = `${rootClassName}__stack-pin`;

export const EquipmentMarkerPopup = ({
    equipment
}: EquipmentStackPinMarkerPopupProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);

    const handleWheel = (event: WheelEvent<HTMLDivElement>) => {
        /**
         * This function stops the map zooming when scrolling the Pins inside the Popup
         */
        event.stopPropagation();
    };

    const handleAllButtonClick = () => {
        const selectedEquipment = equipment.map(
            (eachEquipment) => eachEquipment.id
        );
        dispatch(setSelectedEquipmentIds(selectedEquipment));
        dispatch(setActiveEquipmentClusterId(-1));
        dispatch(setIsLiveDispatchDrawerExpanded(true));
        dispatch(setIsUnassignedTasksTabActive(false));
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenLiveDispatchDrawer(true));
    };

    const handlePinClick = (selectedEquipment: Equipment) => {
        const { id } = selectedEquipment;

        const updatedEquipmentIds = cloneDeep(selectedEquipmentIds);
        updatedEquipmentIds.push(id);

        dispatch(setSelectedEquipmentIds(updatedEquipmentIds));
        dispatch(setActiveEquipmentClusterId(-1));
        dispatch(setIsLiveDispatchDrawerExpanded(true));
        dispatch(setIsUnassignedTasksTabActive(false));
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenLiveDispatchDrawer(true));
    };

    const getScrollableClassName = (boxClassName: string) => {
        const conditionalClasses = {
            [`${boxClassName}__scrollable`]: equipment?.length > 5
        };
        return classNames(boxClassName, conditionalClasses);
    };

    return (
        <div
            className={getScrollableClassName(rootClassName)}
            data-testid="equipment-marker-popup"
        >
            <div
                className={getScrollableClassName(stackedStops)}
                onWheel={handleWheel}
                data-testid={stackedStops}
            >
                <button
                    type="button"
                    className={stackPin}
                    data-testid={stackPin}
                    onClick={handleAllButtonClick}
                >
                    {t('stackStopsPopup.all')}
                </button>
                {equipment?.map((eachEquipment) => {
                    return (
                        <EquipmentMarker
                            key={eachEquipment.id}
                            onMarkerClick={() => handlePinClick(eachEquipment)}
                            markerLabel={eachEquipment.id}
                        />
                    );
                })}
            </div>
            <div className={scrollText} data-testid={scrollText}>
                <span>{t('stackStopsPopup.scrollText')}</span>
                <IconButton
                    iconColor="galaxy-500"
                    icon="arrowRight"
                    iconSize="s"
                />
            </div>
        </div>
    );
};
