/* eslint-disable camelcase */

export const enum ApiBookingStatus {
    PENDING = 'pending',
    CONFIRMED = 'confirmed',
    DECLINED = 'declined',
    CANCELED = 'canceled',
    WITHDRAWN = 'withdrawn'
}

export interface ApiBooking {
    id?: string;
    driver_id?: string;
    client_id?: string;
    owner_id?: string;
    status?: ApiBookingStatus;
    route_date?: string;
}
