import { useMutation } from 'react-query';
import { ShiftEndApi } from '~/api/ShiftEndApi';
import {
    ApiShiftEnd,
    ApiShiftEndParams,
    AxiosApiResponse,
    ReactQuerySideEffects
} from '~/api/types';
import { useWebInterval } from '~/hooks/useWebInterval';

export const useMarkEndOfShift = () => {
    const { refetch } = useWebInterval();

    const {
        isLoading: isLoadingMarkEndOfShift,
        mutateAsync: doMarkEndOfShiftAsync
    } = useMutation((params: ApiShiftEndParams): Promise<
        AxiosApiResponse<ApiShiftEnd>
    > => {
        return ShiftEndApi.post(params);
    });

    const markEndOfShift = async (
        params: ApiShiftEndParams,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData = await doMarkEndOfShiftAsync(params);
            onSuccess?.(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            onError?.(error);
        }

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    return { isLoadingMarkEndOfShift, markEndOfShift };
};
