import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { selectAllSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { selectPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { useMapUtils } from '~/hooks';
import { getSelectedDrawerCardDataPlanStops } from '~/components/MapPageDrawers/SelectedCardsDrawer/utils';
import { PlanStop } from '~/data-classes';

interface SelectedStop {
    clientRouteTaskId: string;
}

export const useSelectedStopDrawerCardData = () => {
    const allSelectedDrawerCardData = useSelector(
        selectAllSelectedDrawerCardData
    );
    const planStopsLevelData = useSelector(selectPlanStopsLevelData);
    const { isPlanRouteMode } = useMapUtils();

    const listOfDrawerCardDataWithPlanStops = useMemo(() => {
        if (!isPlanRouteMode) return [];

        return allSelectedDrawerCardData.map(
            ({ data: selectedDrawerCardData }) => {
                const planStops = getSelectedDrawerCardDataPlanStops({
                    planStopsLevelData,
                    selectedDrawerCardData
                }).map((stop) => new PlanStop(stop));

                return {
                    selectedDrawerCardData,
                    planStops
                };
            }
        );
    }, [allSelectedDrawerCardData, isPlanRouteMode, planStopsLevelData]);

    const getDrawerCardData = useCallback(
        (selectedStop: SelectedStop) => {
            const drawerCardDataWithPlanStops =
                listOfDrawerCardDataWithPlanStops.find(({ planStops }) => {
                    return planStops.some(
                        ({ clientRouteTaskId }) =>
                            clientRouteTaskId === selectedStop.clientRouteTaskId
                    );
                });

            return drawerCardDataWithPlanStops?.selectedDrawerCardData;
        },
        [listOfDrawerCardDataWithPlanStops]
    );

    return {
        getDrawerCardData
    };
};
