import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, Button, Text, ManageStopControl } from '~/ui';
import { RouteCardTitle } from '../../../RouteCard';
import { BreakDurationContent } from '../BreakDurationContent';
import StopSelectionContent from '../StopSelectionContent';
import { setIsAddBreakModalOpen } from '~/reducers/driverBreaksModalSlice';
import { useTranslation } from 'react-i18next';
import { useChangeHandlers } from '../useChangeHandlers';
import { useLiveDriverBreak } from '~/hooks/useLiveDriverBreak';
import dateUtils from '~/utils/date-utils';
import driverBreakUtils from '~/utils/driver-break-utils';

import './AddLiveBreakModal.scss';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';

export const AddLiveBreakModal = () => {
    const { t } = useTranslation('driverBreak');
    const dispatch = useDispatch();
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const liveDriverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const {
        breakDuration,
        selectedStop,
        handleBreakDurationChange,
        handleStopSelection
    } = useChangeHandlers();

    const { addLiveBreak } = useLiveDriverBreak();

    const handleClose = () => {
        dispatch(setIsAddBreakModalOpen(false));
    };

    const handleAddLiveBreak = async () => {
        if (!selectedStop?.arrivalTime) return;
        const liveStopStartTime = dateUtils
            .convertISODateToJsDate(selectedStop.arrivalTime)
            ?.getTime();
        const routeStartTime = dateUtils
            .convertISODateToJsDate(liveDriverData.schedule[0].arrivalTime)
            ?.getTime();
        if (!liveStopStartTime || !routeStartTime) return;
        const currentTime = Date.now();
        const timeElapsedSinceRouteCreation =
            currentTime < routeStartTime ? 0 : currentTime - routeStartTime;
        const adjustedLiveStopStartTime =
            liveStopStartTime + timeElapsedSinceRouteCreation;
        const duration = dateUtils.convertHoursAndMinutesToSeconds(
            breakDuration.hrs,
            breakDuration.min
        );
        await addLiveBreak({
            startsAfter: adjustedLiveStopStartTime,
            duration
        });
        handleClose();
    };

    const isConfirmDisabled = (() => {
        const validBreakDuration =
            driverBreakUtils.isValidBreakDuration(breakDuration);
        return !selectedStop || !validBreakDuration;
    })();

    return (
        <ManageStopControl
            className="add-break-modal"
            data-testid="add-break-modal"
        >
            <div className="add-break-modal__body _fd-column">
                <div className="add-break-modal__header _d-flex">
                    <RouteCardTitle
                        className="_text-2"
                        title={t('addBreakModal.title')}
                    />
                    <IconButton
                        icon="cancel"
                        iconColor="galaxy-800"
                        onClick={handleClose}
                        data-testid="add-break-modal__cancel"
                    />
                </div>
                <div className="add-break-modal__menu  _fd-column">
                    <StopSelectionContent
                        title={t('addBreakModal.stopSelectionTitle')}
                        handleSelect={handleStopSelection}
                        selectedStop={selectedStop}
                    />
                    <BreakDurationContent
                        title={t('addBreakModal.breakDurationTitle')}
                        handleChange={handleBreakDurationChange}
                        duration={breakDuration}
                    />
                    <Button
                        onClick={handleAddLiveBreak}
                        disabled={isConfirmDisabled}
                        className="add-break-modal-content__button"
                        data-testid="add-break-modal-content__button"
                    >
                        <div className="_ai-center _jc-center">
                            <Text
                                className="_text-3"
                                color="white"
                                variant="12-normal"
                            >
                                {t('addBreakModal.confirm')}
                            </Text>
                        </div>
                    </Button>
                </div>
            </div>
        </ManageStopControl>
    );
};
