import _ from 'lodash';
import { BreakDuration } from '~/api/types';

const isValidHours = (hrs: number) =>
    Number.isInteger(hrs) && _.inRange(hrs, 0, 24);

const isValidMinutes = (min: number) =>
    Number.isInteger(min) && _.inRange(min, 0, 60);

const isValidBreakDuration = (breakDuration: BreakDuration) => {
    const { hrs, min } = breakDuration;
    const validHours = isValidHours(hrs);
    const validMinutes = isValidMinutes(hrs);
    const hasBreakDuration = !(hrs === 0 && min === 0);
    const validBreakDuration = validHours && validMinutes && hasBreakDuration;

    return validBreakDuration;
};

export default {
    isValidHours,
    isValidMinutes,
    isValidBreakDuration
};
