import constants from '~/utils/constants';

const HereMapsConstants = {
    staticMapImageURL: 'https://image.maps.hereapi.com/mia/v3/base/mc',
    apiKey: 'AN6lg_MO9Qldd_gX6w7zzp5uPe-1i6tEUc1Qnd04zWg',
    defaultZoom: 14,
    stopMarkerZoom: 14,
    defaultCenter: constants.mapOptionSettings.DEFAULT_CENTER,
    defaultBounds: constants.mapOptionSettings.DEFAULT_BOUNDS,
    defaultPadding: { top: 25, right: 25, bottom: 25, left: 25 },
    maxZoom: 22,
    maxCenter: 3,
    polygons: {
        DEFAULT_POLYGON_FILL_OPACITY: 0.4,
        DEFAULT_POLYGON_HIGHLIGHT_FILL_OPACITY: 0.8,
        DEFAULT_STROKE: 3
    },
    mapsDrawingStyles: {
        SOLID: {
            strokeColor: '#000',
            strokeWeight: 4
        },
        DOTTED: {
            strokeColor: '#000',
            strokeWeight: 4,
            lineDash: [1, 3],
            icons: [],
            arrowStyle: {
                lineWidth: 18,
                strokeColor: '#000',
                lineDash: [0, 10],
                lineTailCap: 'arrow-tail',
                lineHeadCap: 'arrow-head'
            }
        },
        DASHED: {
            strokeColor: '#000',
            strokeWeight: 4,
            lineDash: [5],
            icons: []
        }
    }
};

export { HereMapsConstants };
