import React from 'react';
import classNames from 'classnames';

interface CardCurrentDriverProps {
    className?: string;
    title?: string;
    text?: string;
}

export const CardCurrentDriver = ({
    className,
    title,
    text
}: CardCurrentDriverProps) => {
    const rootClassName = 'card-current-driver';
    const getClassName = () => {
        return classNames(rootClassName, className);
    };

    return (
        <div className={getClassName()} data-testid={rootClassName}>
            {title && <div className="card-title _text-4-alt">{title}</div>}
            {text && <div className="card-text _text-2">{text}</div>}
        </div>
    );
};
