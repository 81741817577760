import React from 'react';
import { PlanRoute } from '~/data-classes/plan';
import { UIElement } from '~/ui';

export enum StopDragAndDropTypes {
    STOP_REASSIGN = 'stop_reassign',
    STOP_RESEQUENCE = 'stop_resequence'
}

export interface CustomData {
    type?: StopDragAndDropTypes;
    renderOverlay?: () => React.ReactNode;
}

export interface MapPageDrawersProps extends UIElement {
    /**
     * The plan route data for the route drawers
     */
    planRoutes: PlanRoute[];
}
