/**
 * Enum of supported alert variants
 */
export enum AlertVariants {
    WARNING = 'warning',
    WARNING_DARK = 'warning-dark',
    DANGER = 'danger',
    DANGER_DARK = 'danger-dark',
    INFO = 'info'
}
