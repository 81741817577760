/* eslint-disable camelcase */
import _ from 'lodash';
import { customCostMatrixUtils } from '~/utils/custom-cost-matrix-utils';
import { idUtils } from '~/utils/id-utils';

const reduceVariableTerm = (terms, term) => {
    const {
        custom_cost_term,
        threshold_attribute,
        weight_attribute,
        thresholds,
        weights,
        isProgressive
    } = term;

    const key = idUtils.getCombinedId(
        custom_cost_term,
        threshold_attribute,
        weight_attribute
    );

    const termObj = { thresholds, weights, isProgressive };
    const existingTermObj = terms[custom_cost_term][key];
    if (!existingTermObj) {
        terms[custom_cost_term][key] = termObj;
    } else {
        terms[custom_cost_term][key] = _.merge(existingTermObj, termObj);
    }

    return terms;
};

const reduceFilteredEntries = (all, current) => {
    const { start_zone_id, end_zone_id, constantTerm, variableTerm } = current;

    all.start_zone_id = customCostMatrixUtils.getEntryZoneId(start_zone_id);
    all.end_zone_id = customCostMatrixUtils.getEntryZoneId(end_zone_id);

    // set constant cost term
    if (constantTerm) {
        all.custom_cost_terms.constantTerm = constantTerm;
    }

    // set all variable cost terms
    if (variableTerm) {
        const { trip, stop } = Object.values(variableTerm).reduce(
            reduceVariableTerm,
            { trip: {}, stop: {} }
        );

        // set trip terms
        if (Object.values(trip).length > 0)
            all.custom_cost_terms.tripTerms = Object.values(trip);

        // set stop terms
        if (Object.values(stop).length > 0)
            all.custom_cost_terms.stopTerms = Object.values(stop);
    }

    return all;
};

const transformToEntries = (rawData) => {
    const uniqueKeys = [...new Set(rawData.map((item) => item.key))];
    const entries = uniqueKeys.map((key) => {
        const filteredEntries = rawData
            .filter((item) => item.key === key)
            .reduce(reduceFilteredEntries, {
                custom_cost_terms: {}
            });
        return filteredEntries;
    });

    return entries;
};

export default transformToEntries;
