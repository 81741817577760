import React from 'react';
import { Icon } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import taskUtils from '~/utils/task-utils';
import { useDuplicateTaskModalContext } from '../DuplicateTaskModalContext';
import './DuplicateTaskModalHeader.scss';

export const DuplicateTaskModalHeader = () => {
    const rootClassName = 'duplicate-task-modal-header';
    const { apiTask } = useDuplicateTaskModalContext();
    const address = taskUtils.formatTaskAddress(apiTask);
    const taskName = taskUtils.formatTaskName(apiTask);

    return (
        <div className={`${rootClassName} _d-flex _ai-center`}>
            <Icon icon="nestSecureAlarm" size={IconSizes.XL} />
            <div className={`${rootClassName}__info _d-flex _fd-column`}>
                <span className={`${rootClassName}__title`}>{taskName}</span>
                <span className={`${rootClassName}__address`}>{address}</span>
            </div>
        </div>
    );
};
