import axios from 'axios';
import { getURL, ServerBaseUrlKey } from '~/servers/servers';
import {
    GetEquipmentParams,
    EquipmentApiGetAllResponse,
    EquipmentApiGetByIdResponse
} from './types';

/**
 * Base URL of the API endpoint
 */
const EQUIPMENT_BASEURL = getURL(ServerBaseUrlKey.EQUIPMENT);

/**
 * Implementations of API methods under the /equipment(s) path
 *
 * @category API
 */
export default class EquipmentApi {
    /**
     * Base URL of the API endpoint
     */
    private static readonly baseURL = EQUIPMENT_BASEURL;

    /**
     * Path of the API endpoint
     */
    private static readonly path = '/v1/equipment';

    /**
     * Path of the bulk API endpoint
     */
    private static readonly pathBulk = '/v1/equipments';

    /**
     * Bulk retrieve all equipment
     */
    static getEquipment(
        params: GetEquipmentParams
    ): EquipmentApiGetAllResponse {
        const options = { params, baseURL: this.baseURL };
        return axios.get(this.pathBulk, options);
    }

    /**
     * Retrieve equipment by ID
     */
    static getEquipmentById(equipmentId: string): EquipmentApiGetByIdResponse {
        const options = { baseURL: this.baseURL };
        return axios.get(`${this.path}/${equipmentId}`, options);
    }
}
