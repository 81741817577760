import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import { MarkEndOfShiftControl } from '~/components/MapPage/MarkEndOfShiftControl';
import { useRunRoutePlanner } from '~/components/MapPage/PlanStopsControl/useRunRoutePlanner';
import { hasAssignedStops } from '~/components/MapPage/PlanStopsControl/utils';
import { TextWithLineBreaks } from '~/components/TextWithLineBreaks';
import {
    useIntermodalFeatures,
    useOnDemandDispatchUnassignTasks,
    useToastContext,
    useUnassignStops
} from '~/hooks';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import constants from '~/utils/constants';
import { useEndOfShiftCallbacks } from '../../MarkEndOfShiftControl/useEndOfShiftCallbacks';
import { DuplicateTaskButton } from '../DuplicateTaskButton';
import { CreateTripButton } from '../CreateTripButton';

import { useUnarriveSelectedStopModal } from '../useUnarriveSelectedStopModal';

import './SingleStopControl.scss';

function SingleStopControl({
    stopData,
    onSwitchView,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    isPlanStopControl,
    onClickFooterButton
}) {
    const { isCompleted } = stopData || {};

    const { hasLastStopInShift, handleRemoveShiftEnd } =
        useEndOfShiftCallbacks(stopData);
    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();
    const { handleClickRunRoutePlanner, ReRunSchedulerModal } =
        useRunRoutePlanner();
    const {
        unarriveSelectedStopModal: UnarriveSelectedStopModal,
        onShowModal
    } = useUnarriveSelectedStopModal();

    const { enableUnarriveStops, enableEndOfShift } = useIntermodalFeatures();

    const mainClient = useSelector(selectMainClient);
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const mapRouteMode = useSelector(selectMapRouteMode);

    const isPlannedStopsSelected = hasAssignedStops(selectedMapStopIds);

    const showUnarriveButton =
        enableUnarriveStops && isLiveStopControl && stopData.status === 1;
    const showResequenceButton = !showUnarriveButton && !isCompleted;
    const showReassignButton =
        [constants.mapRouteModes.PLAN, constants.mapRouteModes.EMPTY].includes(
            mapRouteMode
        ) &&
        !showUnarriveButton &&
        !isCompleted;
    const showLiveStopUnassignButton =
        isLiveStopControl && !showUnarriveButton && !isCompleted;
    const showDispatchCancelButton =
        isLiveStopControl &&
        mainClient?.preferences?.showDispatchCancelButton &&
        !showUnarriveButton &&
        !isCompleted;
    const showMarkEndOfShiftButton = isLiveStopControl && enableEndOfShift;
    const showPlanStopUnassignButton =
        !isLiveStopControl && !showUnarriveButton;
    const showDuplicateStopButton = !isLiveStopControl && !showUnarriveButton;
    const shouldRenderButton = isPlanStopControl && !isPlannedStopsSelected;
    const showCreateTripButton = isPlanStopControl;

    function handleClickLiveStopUnassign() {
        unassignTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count
                    }),
                    variant: 'success'
                });
                if (hasLastStopInShift) {
                    handleRemoveShiftEnd(stopData);
                }
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    }

    function handleClickLiveStopCancel() {
        cancelTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: 1
                    }),
                    variant: 'error'
                });
            }
        });
    }

    const handleClickUnarriveStop = () => {
        onShowModal(stopData);
    };

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                {showResequenceButton && (
                    <StopModalMenuButton
                        buttonIcon="iconReorder"
                        buttonText={t('PlanStopsControl.button.resequence')}
                        onClick={() =>
                            onSwitchView(
                                constants.stopsControl.views.RESEQUENCE
                            )
                        }
                        buttonStopText={t('PlanStopsControl.button.stopText', {
                            stopIndex: stopData.stopNumber
                        })}
                        disabled={disableResequencing}
                        showButtonArrow
                        data-testid="button-resequence"
                    />
                )}
                {showReassignButton && (
                    <StopModalMenuButton
                        buttonIcon="iconRouteFill"
                        buttonText={t('PlanStopsControl.button.reassign')}
                        showButtonArrow
                        onClick={() =>
                            onSwitchView(constants.stopsControl.views.REASSIGN)
                        }
                        data-testid="button-reassign"
                    />
                )}
                {showLiveStopUnassignButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-live-stop-unassign"
                    />
                )}
                {showDispatchCancelButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                        data-testid="button-cancel"
                    />
                )}
                {showMarkEndOfShiftButton && (
                    <MarkEndOfShiftControl stopData={stopData} />
                )}
                {showPlanStopUnassignButton && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClick={unassignStops}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-plan-stop-unassign"
                    />
                )}
                {showDuplicateStopButton && (
                    <DuplicateTaskButton taskId={stopData.taskId} />
                )}
                {showUnarriveButton && (
                    <StopModalMenuButton
                        onClick={handleClickUnarriveStop}
                        buttonIcon="locationOff"
                        buttonText={t('PlanStopsControl.button.unarrive')}
                        data-testid="button-live-stop-unarrive"
                    />
                )}
                {showCreateTripButton && <CreateTripButton />}
            </ManageStopControlMenu>
            {shouldRenderButton && (
                <ManageStopControlMenu className="single-stop-control__control-menu">
                    <StopModalMenuButton
                        onClick={handleClickRunRoutePlanner}
                        className="single-stop-control__menu-button"
                        buttonIcon="autoplay"
                        buttonIconColor="white"
                        buttonText={
                            <TextWithLineBreaks
                                text={t(
                                    'PlanStopsControl.button.runRoutePlanner'
                                )}
                            />
                        }
                        data-testid="run-route-planner-menu-button"
                    />
                </ManageStopControlMenu>
            )}
            {ReRunSchedulerModal}
            <UnarriveSelectedStopModal />
        </ManageStopControlSingle>
    );
}

export default SingleStopControl;
