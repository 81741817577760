import { LiveStop, OnDemandDispatchStop, PlanStop } from '~/data-classes';
import { ApiLiveStop, SocketScheduleItem, ApiTask } from '~/api/types';
import taskUtils from '../task-utils';

export const getRouteSequenceNumber = (
    stop: (PlanStop | LiveStop) & {
        driverStopNumber?: number;
        stopNumber?: number;
    }
) => {
    const { driverStopNumber, stopNumber } = stop;
    return driverStopNumber || stopNumber;
};

export const getMetricsFromStopOrTask = ({
    stop,
    timeZone,
    isClientTimezoneFlagEnabled,
    format
}: {
    stop: PlanStop | OnDemandDispatchStop | SocketScheduleItem;
    timeZone?: string;
    isClientTimezoneFlagEnabled?: boolean;
    format?: string;
}) => {
    if (stop instanceof OnDemandDispatchStop) {
        // TODO: RP-975 Correctly display TW and ST for two-part tasks
        const task = stop.toJSON();
        const isDelivery = taskUtils.checkIsDeliveryTask(task);

        const { timeWindowsWithoutDate, serviceTime } = isDelivery
            ? taskUtils.filterDeliveryTask({
                  task,
                  selectedTimeZone: timeZone,
                  isClientTimezoneFlagEnabled,
                  format
              })
            : taskUtils.filterPickupTask({
                  task,
                  selectedTimeZone: timeZone,
                  isClientTimezoneFlagEnabled,
                  format
              });

        return {
            serviceTime,
            timeWindows: timeWindowsWithoutDate
        };
    }

    return {
        serviceTime: taskUtils.formatServiceTime(stop.serviceTime),
        timeWindows: taskUtils.formatTimeWindow({
            timeWindow: stop.timeWindow,
            selectedTimeZone: timeZone,
            isClientTimezoneFlagEnabled,
            format
        })
    };
};

export const getLastScheduledTaskTime = (
    schedule?: ApiLiveStop[]
): string | undefined => {
    const scheduledTasks = schedule?.filter(
        (apiLiveStop) => !apiLiveStop.isDepot
    );

    if (!scheduledTasks?.length) return;

    const lastTaskOnSchedule = scheduledTasks[scheduledTasks.length - 1];

    const { arrivalTime, startServiceAt, completedAt } = lastTaskOnSchedule;

    return completedAt || startServiceAt || arrivalTime || undefined;
};

export const getDeliveryTime = (task: ApiTask): string | undefined => {
    return task.props.deliveryTime || task.props.pickupTime || undefined;
};

export const getPickupTime = (task: ApiTask): string | undefined => {
    return task.props.pickupTime || task.props.deliveryTime || undefined;
};

export const getTaskId = (stop: PlanStop): string => {
    if (stop.isTwoPart) {
        return stop.twoPartTaskId as string;
    }
    return stop.taskId;
};

export const findTwoPartTask = ({
    idToExclude,
    liveDriverSchedule,
    stopId
}: {
    idToExclude: string;
    liveDriverSchedule: ApiLiveStop[];
    stopId: string;
}) => {
    return (
        liveDriverSchedule.find(
            (stop) => stop.task === stopId && stop.id !== idToExclude
        ) || null
    );
};
