import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import tasksApi from '~/api/TasksApi';

import { selectTasks } from '~/reducers/tasksSlice';

import constants from '~/utils/constants';

import ReRunSchedulerModalContent from '~/components/ReRunSchedulerModalContent';
import { useRunScheduler } from '~/hooks/useRunScheduler';
import useToastContext from '~/hooks/toastContext';
import { pageUtils } from '~/utils/page-utils';

function RunSchedulerControl({
    hideReRunSchedulerModal,
    selectedTasksIds = []
}) {
    const { runScheduler } = useRunScheduler();
    const { t } = useTranslation();
    const { addToast } = useToastContext();

    const [selectedOptimizationChoice, setSelectedOptimizationChoice] =
        useState(null);
    const [orderLockActive, setOrderLockActive] = useState(false);

    const tasks = useSelector(selectTasks);
    const taskIds = useMemo(() => Object.keys(tasks), [tasks]);

    function _handleSchedulerReRunOptimizationChoice({ target: { value } }) {
        setSelectedOptimizationChoice(value);
    }

    function _handleTaskLockChoice({ isChecked }) {
        setOrderLockActive(isChecked);
    }

    async function _handleRunScheduler() {
        try {
            pageUtils.resetStateBeforeSchedulerRun();
            switch (selectedOptimizationChoice) {
                case constants.schedulerKeys.lockRoutes:
                    if (!taskIds.length) break;
                    if (orderLockActive) {
                        await tasksApi.orderLockTasks(taskIds);
                    } else {
                        await tasksApi.taskLockTasks(taskIds);
                    }
                    break;
                case constants.schedulerKeys.closeAllRoutes:
                    await tasksApi.unlockTasks(taskIds);
                    break;
                case constants.schedulerKeys.uncloseAllRoutes:
                    await tasksApi.unlockTasks(taskIds);
                    break;
                default:
                    break;
            }
            await runScheduler({
                selectedOptimizationChoice,
                selectedTasksIds
            });
            hideReRunSchedulerModal();
        } catch (e) {
            const { data: { message } = {} } = e.response || {};
            const errorMessage = message ?? e;

            console.error(e);

            addToast({
                message: t('error:schedulerRunningError', {
                    message: errorMessage
                }),
                variant: 'error',
                isPersisted: true
            });
        }
    }

    return (
        <ReRunSchedulerModalContent
            selectedValue={selectedOptimizationChoice}
            handleChoice={_handleSchedulerReRunOptimizationChoice}
            handleTaskLockChoice={_handleTaskLockChoice}
            orderLockActive={orderLockActive}
            onSchedulerButtonClick={_handleRunScheduler}
        />
    );
}

export default RunSchedulerControl;
