import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectShowLiveRouteLabel,
    selectShowLiveRoutePopup,
    setShowLiveRouteLabel,
    setShowLiveRoutePopup
} from '~/reducers/mapSettingsSlice';

import { MapSettingMenuButton } from '~/ui';

import './map-settings-menu-sections.scss';
import RouteLabelSubmenu from '~/ui/components/PlanMapOverlay/RouteLabelSubmenu';

export default function MapSettingMenuLiveRoutesSection({
    mapRouteMode,
    openSubMenu
}) {
    const { t } = useTranslation(['translation']);
    const showLiveRoutesPopupState = useSelector(
        selectShowLiveRoutePopup(mapRouteMode)
    );
    const showLiveRoutesLabelState = useSelector(
        selectShowLiveRouteLabel(mapRouteMode)
    );

    const liveRouteMarkerClick = useCallback(() => {
        openSubMenu(t('routeMarker'), [
            {
                iconName: 'stopDetails',
                text: t('routePopup'),
                checkedState: showLiveRoutesPopupState,
                onChangeHandler: setShowLiveRoutePopup
            },
            {
                iconName: 'stopLabel',
                text: t('routeLabel'),
                checkedState: showLiveRoutesLabelState,
                onChangeHandler: setShowLiveRouteLabel,
                hasSub: <RouteLabelSubmenu />
            }
        ]);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [showLiveRoutesPopupState, showLiveRoutesLabelState]);

    return (
        <>
            <span
                className="mapsettingssection_title"
                data-testid="map-setting-menu-live-routes-section-title"
            >
                {t('route_one')}
            </span>
            <MapSettingMenuButton
                iconName="routeMarker"
                text={t('routeMarker')}
                onClickHandler={liveRouteMarkerClick}
            />
        </>
    );
}
