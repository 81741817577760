/**
 * List of commands supported by the cross domain authentication server in web-18
 * @type {object}
 */
const supportedCommands = {
    MCW_INIT: 'mcw-init'
};

/**
 * Time after which promises will reject is no response is received.
 * @type {number}
 */
const REQUEST_TIMEOUT = 15000;

/**
 * Implementation of a simple cross-domain messaging service through an iframe.
 */
export default class IframeMessagingAdaptor {
    constructor(origin, path) {
        this.origin = origin;
        this.path = path;
        this.iframe = null;
        this.iframeReady = false;
        this.queue = [];
        this.deferreds = [];
        this.id = 0;
        this.messageHandler = this.handleMessage.bind(this);
        this.iframeLoadedHandler = this.handleIframeLoaded.bind(this);
    }

    /**
     * Attaches an iframe to the document with the origin + path as the source.
     * Initializes event listeners to listen to message from the iframe.
     */
    initIframe() {
        if (!this.iframe) {
            this.iframe = document.createElement('iframe');
            this.iframe.style.cssText = 'display:none;';
            document.body.appendChild(this.iframe);
            window.addEventListener('message', this.messageHandler, true);
            this.iframe.addEventListener(
                'load',
                this.iframeLoadedHandler,
                true
            );
        }

        this.iframe.src = this.origin + this.path;
    }

    /**
     * Requests the initialization payload required to initialize MCW by posting a message to the iframe.
     * @returns {Promise<unknown>} a Promise for the initialization payload.
     */
    requestInitialization() {
        if (!this.iframe) {
            this.initIframe();
        }

        this.id += 1;
        const request = {
            command: supportedCommands.MCW_INIT,
            id: this.id
        };
        const promise = new Promise((resolve, reject) => {
            this.deferreds[request.id] = { resolve, reject };
            setTimeout(reject, REQUEST_TIMEOUT, 'Request timed out.');
        });

        if (this.iframeReady) {
            this.sendRequest(request);
        } else {
            this.queue.push(request);
        }

        return promise;
    }

    /**
     * Handles messages received from the iframe.
     * @param e
     */
    handleMessage(e) {
        if (e.origin === this.origin) {
            const response = e.data;
            if (response.data.error) {
                this.deferreds[response.id].reject(response.data);
            } else {
                this.deferreds[response.id].resolve(response.data);
            }
            delete this.deferreds[response.id];
        }
    }

    /**
     * Consumes any requests queued during the iframe's initialization.
     */
    handleIframeLoaded() {
        this.iframeReady = true;
        if (this.queue.length) {
            this.queue.forEach(this.sendRequest.bind(this));
            this.queue = [];
        }
    }

    /**
     * Posts a message to the iframe containing the given data
     * @param data
     */
    sendRequest(data) {
        this.iframe.contentWindow.postMessage(data, this.origin);
    }
}
