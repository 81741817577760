/* eslint-disable camelcase */
import { Contact, Coordinates } from './CommonTypes';
import { PlanClientRoute } from './ApiPlanClientRoute';
import { ApiTask } from './ApiTask';
import { ApiAddress } from './ApiAddress';
import { ApiWebDriver } from './ApiDriver';
import { ApiVehicle } from './ApiVehicle';

export enum ApiRoutePlanSolutionType {
    SCHEDULER = 'Scheduler',
    ROUTE_BUILDER = 'RouteBuilder'
}

export interface ApiRoutePlanSolution {
    id?: string;

    /* Date in ISO format */
    routeDate?: string;
    client?: string;
    jobId?: string;
    type?: ApiRoutePlanSolutionType;

    web_results?: InterstellarResultsWebResults;

    interstellar_results?: InterstellarResults;

    download_data?: Record<string, unknown>;

    unplanned_tasks?: ApiTask[];

    /* Date is ISO format */
    last_access?: string;
}

export interface InterstellarResults {
    client?: {
        clientId?: string;
        clientName?: string;
        stats?: {
            numRoutes?: number;
            numStops?: number;
            numUnplannedStops?: number;
            numUsedDrivers?: number;
            numTotalDrivers?: number;
            numUnits?: number;
            duration?: number;
        };
    };
    routes?: PlanClientRoute[];
    stops?: InterstellarResultsStop[];
}

export interface InterstellarResultsStop {
    clientId?: string;
    routeId?: string;
    task?: InterstellarResultsStopTask;
}

/**
 * Note: There is likely a better name for this type, just need to find the original inspriation
 * / origin of this type to name it more appropriately
 */
export interface InterstellarResultsStopTask {
    taskId?: string;
    taskName?: string;

    /* Date in YYYY-MM-DD ISO format */
    routeDate?: string;
    stopNumber?: number;
    isPlanned?: boolean;
    isTwoPart?: boolean;
    type?: string;
    markerCoordinates?: Coordinates;
    stats?: {
        volumeCapacityUsed?: number;
        weightUsed?: number;
        numInventoryItems?: number;
    };
}

/**
 * Note: There is likely a better name for this type, need to figure out the root use of this type
 */
export interface InterstellarResultsWebResults {
    activeType?: string;

    /* Date in YYYY-MM-DD ISO format */
    selectedDate?: string;
    timezone?: string;
    job?: string;
    wiseRoutes?: WebResultRoutesData;
    areCustomRoutesAvailable?: boolean;
    defaultRoutes?: WebResultRoutesData;
}

interface WebResultRoutesData {
    routes?: WebResultRoute;
    fleetStats?: FleetStats;
    solutionHasValidRoutes?: boolean;
    activeType?: string;

    /* Date in YYYY-MM-DD ISO format */
    routeDate?: string;
}

interface WebResultRoute {
    id?: string;
    driverId?: string;
    vehicleId?: string;
    route?: string;
    name?: string;
    driverIsValid?: boolean;
    vehicleIsValid?: boolean;
    routes?: WebResultRouteRoute[];
    aggregateStats?: {
        numberOfExtraNeededVehicles?: number;
        numberOfOpenRoutes?: number;
        numberOfTasks?: number;
        numberOfUnassignedTasks?: number;
        numberOfViolatedHardTW?: number;
        numberOfViolatedTW?: number;
        timeOnRoad?: number;
        totalCapacity?: {
            weight?: number;
            volume?: number;
        };
        totalDelay?: number;
        totalMeters?: number;
        totalServiceTime?: number;
        totalTravelTime?: number;
        totalWaitingTime?: number;
        eta_type?: string;
        largestSchedule?: number;
        uniqueRouteCounter?: number;
        totalRoutesCount?: number;
        weightViolation?: number;
        driverShiftCloseTimeViolation?: number;
        maxTaskViolation?: number;
        numberOfVehicleTypeViolation?: number;
        routeDurationViolation?: number;
        sizeViolation?: number;
        routesEnd?: number;
        routesStart?: number;
        totalWeight?: number;
        totalUnits?: number;
        mapIcon?: string;
        timeRemaining?: {
            hours?: number;
            minutes?: number;
            ms?: number;
        };
        metrics?: {
            no_driver?: boolean;
            no_vehicle?: boolean;
            valid?: boolean;
            conflicts?: boolean;
            same?: boolean;
            isPropTaskId?: boolean;
            conflicts_route?: number;
            active_routes?: number;
        };
        totalSch?: number;
        unitCost?: number;
        progressBarLength?: number;
    };
    routeMarkerColor?: {
        color?: string;
        icon?: string;
        textColor?: string;
        lastUsed?: number;
    };
    vehicle?: ApiVehicle;
    driver?: ApiWebDriver;
    depotId?: string;
    hasStartedRoute?: boolean;
    depots?: string[];
}

interface WebResultRouteRoute {
    route?: string;
    routeId?: string;
    name?: string;
    areTasksLocked?: boolean;
    hasStartedRoute?: boolean;
    isFirstUnstartedRoute?: boolean;
    multiPartTaskCount?: number;
    routeTaskIds?: string[];
    schedule?: WebRouteStop[];
    routeStats?: {
        numberOfExtraNeededVehicles?: number;
        numberOfOpenRoutes?: number;
        numberOfTasks?: number;
        numberOfUnassignedTasks?: number;
        numberOfViolatedHardTW?: number;
        numberOfViolatedTW?: number;
        timeOnRoad?: number;
        totalCapacity?: {
            weight?: number;
            volume?: number;
        };
        totalDelay?: number;
        totalMeters?: number;
        totalServiceTime?: number;
        totalTravelTime?: number;
        totalWaitingTime?: number;
        eta_type?: string;
        largestSchedule?: number;
        uniqueRouteCounter?: number;
        totalRoutesCount?: number;
        weightViolation?: number;
        driverShiftCloseTimeViolation?: number;
        maxTaskViolation?: number;
        numberOfVehicleTypeViolation?: number;
        routeDurationViolation?: number;
        sizeViolation?: number;
        routesEnd?: number;
        routesStart?: number;
        totalWeight?: number;
        totalUnits?: number;
        mapIcon?: string;
        timeRemaining?: {
            hours?: number;
            minutes?: number;
            ms?: number;
        };
        totalSch?: number;
        unitCost: number;
        progressBarLength?: number;
    };
}

interface WebRouteStop {
    isEditable?: boolean;
    deliveryTime?: number;
    departureTime?: number;
    location?: Coordinates;
    pickupTime?: number;
    taskId?: string;
    isDepot?: boolean;
    stopType?: string;
    delay?: number;
    delayFlag?: number;
    demandViolated?: boolean;
    distanceFromPrevious?: number;
    distanceToNext?: number;
    hardTWViolated?: boolean;
    startServiceTime?: number;
    travelTimeFromPrevious?: number;
    travelTimeToNext?: number;
    violatedTW?: boolean;
    waitingTime?: number;
    depotIcon?: string;
    depotId?: string;
    statusDisplayInfo?: {
        statusPin?: string;
        statusTextColor?: string;
        textColor?: string;
        statusText?: string;
        statusBorder?: string;
        statusBackground?: string;
        iconTextColor?: string;
        statusDisplay?: string;
        progressBar?: string;
    };
    task?: WebRouteDepotTask;
}

interface WebRouteDepotTask {
    deliveryContact?: Contact;
    contact?: Contact;
    routeId?: string;
    eta?: string;
    stopnumber?: number;
    id?: string;
    eorder?: number;
    euid?: string;
    isHq?: boolean;
    deliveryLocation?: ApiAddress;
    location?: ApiAddress;
    name?: string;
    depotId?: string;
    props?: Record<string, unknown>;
}

interface FleetStats {
    numberOfExtraNeededVehicles?: number;
    numberOfOpenRoutes?: number;
    numberOfTasks?: number;
    numberOfUnassignedTasks?: number;
    numberOfViolatedHardTW?: number;
    numberOfViolatedTW?: number;
    timeOnRoad?: number;
    totalDelay?: number;
    totalMeters?: number;
    totalServiceTime?: number;
    totalTravelTime?: number;
    totalWaitingTime?: number;
    eta_type?: string;
    largestSchedule?: number;
    uniqueRouteCounter?: number;
    totalRoutesCount?: number;

    /* Date in YYYY-MM-DD ISO format */
    date?: string;
    units?: number;
    stopsRemaining?: number;
    timeRemaining?: {
        hours?: number;
        minutes?: number;
        ms?: number;
    };
    metrics?: {
        no_driver?: number;
        no_vehicle?: number;
        valid?: number;
        conflicts?: number;
        same?: number;
        isPropTaskId?: boolean;
        conflicts_route?: number;
        active_routes?: number;
    };
}
