import constants from '~/utils/constants';

const {
    sessionStorageKeys: { EXPORTED_ROUTES }
} = constants;

export const saveExportedRoutesToStorage = (newValues: string[]) => {
    const existingValues = JSON.parse(
        sessionStorage.getItem(EXPORTED_ROUTES) || '{}'
    );

    newValues.forEach((value) => {
        existingValues[value] = true;
    });
    sessionStorage.setItem(EXPORTED_ROUTES, JSON.stringify(existingValues));
};

export const getExportedRouteIds = (routeId: string) => {
    const exportRouteIds = JSON.parse(
        sessionStorage.getItem(EXPORTED_ROUTES) || '{}'
    );

    return exportRouteIds?.[routeId] === true;
};
