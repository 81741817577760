import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import { ApiClient } from '~/api/types';

/**
 * The active client state
 */
type ActiveClientState = Record<ApiClient['id'], boolean>;

/**
 * Active client reducer payloads
 */
type ActiveClientPayload = ApiClient['id'][];

/**
 * Active client reducer actions
 */
type ActiveClientAction = PayloadAction<ActiveClientPayload>;

export const activeClientsSlice = createSlice({
    name: 'activeClients',
    initialState: {} as ActiveClientState,
    reducers: {
        setActiveClients: (state, action) => {
            state = action.payload;
            return state;
        },
        addActiveClients: (
            state: ActiveClientState,
            action: ActiveClientAction
        ): ActiveClientState => {
            const clientIds = action.payload;
            clientIds.forEach((id) => {
                state[id] = true;
            });
            return state;
        },
        removeActiveClients: (
            state: ActiveClientState,
            action: ActiveClientAction
        ): ActiveClientState => {
            const clientIds = action.payload;
            clientIds.forEach((id) => {
                delete state[id];
            });
            return state;
        },
        resetActiveClients: (): ActiveClientState => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const {
    addActiveClients,
    removeActiveClients,
    resetActiveClients,
    setActiveClients
} = activeClientsSlice.actions;
export const selectActiveClients = (state: RootState): ActiveClientState =>
    state.activeClients;

export const selectActiveClientIds = createSelector(
    (state: RootState) => state.activeClients,
    (activeClients) => {
        return Object.keys(activeClients);
    }
);

export default activeClientsSlice.reducer;
