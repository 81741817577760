export enum TaskDetailsSettings {
    ContactName = 'contact-name',
    InventoryItem = 'inventory-item',
    Invoice = 'invoice',
    TaskVolume = 'task-volume',
    TaskWeight = 'task-weight',
    TimeWindow = 'time-window'
}

export type TaskDetailSetting = `${TaskDetailsSettings}`;
