import { useMapUtils } from '~/hooks';
import { useChangeHandlers } from '../../../../AddBreakModal/useChangeHandlers';
import { useButtonHandlers } from '../../../DriverPlanBreakModal/useButtonHandlers';
import { useLiveButtonHandlers } from '../../../DriverLiveBreakModal/useLiveButtonHandlers';
import dateUtils from '~/utils/date-utils';

export const useResequenceBreakSetup = () => {
    const { isPlanRouteMode, isDispatchedRouteMode } = useMapUtils();
    const { selectedStop, handleStopSelection } = useChangeHandlers();
    const { handleResequence } = useButtonHandlers();
    const { handleResequence: handleLiveResequence } = useLiveButtonHandlers();

    const resequenceHandler = () => {
        if (isPlanRouteMode) {
            handleResequence(selectedStop?.stopId as string);
        } else if (isDispatchedRouteMode) {
            handleLiveResequence(
                dateUtils
                    .convertISODateToJsDate(selectedStop?.arrivalTime ?? '')
                    ?.getTime() ?? 0
            );
        }
    };

    return {
        selectedStop,
        handleStopSelection,
        resequenceHandler
    };
};
