import { useCallback, useMemo } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { getReassignPlanPayload } from '~/components/MapPage/ReassignModal/useReassignSetup/utils';
import { PlanStop, PlanRoute } from '~/data-classes';
import { useReassignPlanRouteTasks } from '~/components/MapPage/useReassignPlanRouteTasks';
import { StopDragAndDropTypes } from '~/components/MapPageDrawers/types';

interface UseDragToReassignStopsProps {
    stops: PlanStop[];
    routes: PlanRoute[];
}

type PersistFunction = (
    targetItemId: UniqueIdentifier,
    draggedItemIds: UniqueIdentifier[]
) => void;

type UseDragToReassignStops = Record<string, PersistFunction>;

export const useDragToReassignStops = ({
    stops,
    routes
}: UseDragToReassignStopsProps): UseDragToReassignStops => {
    const dragAndDropAction = StopDragAndDropTypes.STOP_REASSIGN;
    const { reassignPlanRouteTasks } = useReassignPlanRouteTasks();

    const dragToReassign = useCallback(
        (reassignTarget: PlanRoute, stopsToReassign: PlanStop[]) => {
            const payload = getReassignPlanPayload({
                selectedRoute: reassignTarget,
                selectedStops: stopsToReassign
            });
            if (!payload) {
                return;
            }

            const {
                taskIds,
                taskIdsByRouteId,
                driverId,
                selectedRouteId: targetRouteId,
                oldRouteId,
                clientId
            } = payload;

            reassignPlanRouteTasks({
                clientId,
                driverId,
                oldRouteId,
                targetRouteId,
                taskIds,
                taskIdsByRouteId
            });
        },
        [reassignPlanRouteTasks]
    );

    const persistReassign = useCallback(
        async (
            targetItemId: UniqueIdentifier,
            draggedItemIds: UniqueIdentifier[]
        ) => {
            if (!targetItemId || !draggedItemIds) {
                return;
            }

            const movedOverDragged = draggedItemIds.includes(targetItemId);
            if (movedOverDragged) {
                return;
            }
            const targetRoute = routes.find((tripData) => {
                const { clientRouteId } = tripData;
                return clientRouteId === targetItemId;
            });
            if (!targetRoute) {
                return;
            }

            const selectedStops = draggedItemIds
                .map((draggedId: UniqueIdentifier) => {
                    return stops.find((stop) => {
                        const { id } = stop;
                        return id === draggedId;
                    });
                })
                .filter(Boolean);

            await dragToReassign(targetRoute, selectedStops as PlanStop[]);
        },
        [dragToReassign, stops, routes]
    );

    const reassignConfig = useMemo(() => {
        return {
            [dragAndDropAction]: persistReassign
        };
    }, [persistReassign, dragAndDropAction]);

    return reassignConfig;
};
