import React from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, Button, Text, ManageStopControl } from '~/ui';
import { RouteCardTitle } from '../../../RouteCard';
import { BreakDurationContent } from '../BreakDurationContent';
import StopSelectionContent from '../StopSelectionContent';
import { setIsAddBreakModalOpen } from '~/reducers/driverBreaksModalSlice';
import { useTranslation } from 'react-i18next';
import { useChangeHandlers } from '../useChangeHandlers';
import { useDriverBreak } from '~/hooks';
import { useGetParams } from '../../DriverBreakModal/DriverPlanBreakModal/useButtonHandlers/useGetParams';
import dateUtils from '~/utils/date-utils';
import driverBreakUtils from '~/utils/driver-break-utils';

import './AddBreakModal.scss';
import { PlanStop } from '~/data-classes';

export const AddBreakModal = () => {
    const { t } = useTranslation('driverBreak');
    const dispatch = useDispatch();
    const {
        breakDuration,
        selectedStop,
        handleBreakDurationChange,
        handleStopSelection
    } = useChangeHandlers();

    const { clientId, date } = useGetParams();
    const { addBreak } = useDriverBreak();

    const handleClose = () => {
        dispatch(setIsAddBreakModalOpen(false));
    };

    const handleAddBreak = () => {
        const durationSeconds = dateUtils.convertHoursAndMinutesToSeconds(
            breakDuration.hrs,
            breakDuration.min
        );
        if (selectedStop instanceof PlanStop) {
            addBreak({
                clientId,
                routeId: selectedStop?.routeId,
                date,
                stopIdBeforeBreak: selectedStop?.stopId as string,
                durationSeconds
            });
        }
        handleClose();
    };

    const isConfirmDisabled = () => {
        const validBreakDuration =
            driverBreakUtils.isValidBreakDuration(breakDuration);
        return !selectedStop || !validBreakDuration;
    };

    return (
        <ManageStopControl
            className="add-break-modal"
            data-testid="add-break-modal"
        >
            <div className="add-break-modal__body _fd-column">
                <div className="add-break-modal__header _d-flex">
                    <RouteCardTitle
                        className="_text-2"
                        title={t('addBreakModal.title')}
                    />
                    <IconButton
                        icon="cancel"
                        iconColor="galaxy-800"
                        onClick={handleClose}
                        data-testid="add-break-modal__cancel"
                    />
                </div>
                <div className="add-break-modal__menu  _fd-column">
                    <StopSelectionContent
                        title={t('addBreakModal.stopSelectionTitle')}
                        handleSelect={handleStopSelection}
                        selectedStop={selectedStop}
                    />
                    <BreakDurationContent
                        title={t('addBreakModal.breakDurationTitle')}
                        handleChange={handleBreakDurationChange}
                        duration={breakDuration}
                    />
                    <Button
                        onClick={handleAddBreak}
                        disabled={isConfirmDisabled()}
                        className="add-break-modal-content__button"
                        data-testid="add-break-modal-content__button"
                    >
                        <div className="_ai-center _jc-center">
                            <Text
                                className="_text-3"
                                color="white"
                                variant="12-normal"
                            >
                                {t('addBreakModal.confirm')}
                            </Text>
                        </div>
                    </Button>
                </div>
            </div>
        </ManageStopControl>
    );
};
