export { default as createWebStorage } from 'redux-persist/lib/storage/createWebStorage';

export const createNoopStorage = () => {
    return {
        getItem() {
            return Promise.resolve(null);
        },
        setItem(_key: unknown, value: unknown) {
            return Promise.resolve(value);
        },
        removeItem() {
            return Promise.resolve();
        }
    };
};
