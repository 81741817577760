import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTable, Cell, Column, Row } from 'react-table';

import { ValidatedInput } from '~/components/ValidatedInput';

import './input-table.scss';

type OnBlur = NonNullable<React.ComponentProps<'input'>['onBlur']>;
type OnFocus = NonNullable<React.ComponentProps<'input'>['onFocus']>;
type TdProps = React.ComponentProps<'td'>;

export const inputTableClassName = 'input-table';

export const inputTableTestId = 'input-table_testId';
export const inputTableHeaderTestId = 'input-table_headerTestId';
export const inputTableHeaderCellTestId = 'input-table_headerCellTestId';
export const inputTableRowTestId = 'input-table_rowTestId';
export const inputTableCellTestId = 'input-table_cell';
export const inputTableCellInputTestId = 'input-table_cellInput';
export interface InputTableProps<D extends Record<string, unknown>> {
    data: readonly Partial<D>[];
    updateData: <K extends keyof Partial<D>>(
        row: number,
        colId: K,
        value: string
    ) => void;
    columns: Column<Partial<D>>[];
    errorState?: ErrorStateTypeGen<D>[];
}

const InputTable = <D extends Record<string, unknown>>({
    data,
    updateData,
    columns,
    errorState
}: InputTableProps<D>) => {
    const defaultColumn = useMemo(
        () => ({
            /* This determines the contents of a `td` element when `cell.render('Cell')` is used */
            Cell: EditableCellInput
        }),
        []
    );

    const tableInstance = useTable({
        columns,
        data,
        updateData,
        defaultColumn
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    const tableProps = getTableProps();

    return (
        <table
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...tableProps}
            className={classNames(tableProps.className, inputTableClassName)}
            data-testid={inputTableTestId}
        >
            <thead data-testid={inputTableHeaderTestId}>
                {headerGroups.map((headerGroup) => {
                    const headerGroupProps = headerGroup.getHeaderGroupProps();

                    return (
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        <tr {...headerGroupProps} key={headerGroupProps.key}>
                            {headerGroup.headers.map((column) => {
                                const headerProps = column.getHeaderProps();
                                const className = classNames(
                                    headerProps.className,
                                    '_text-2'
                                );

                                return (
                                    <th
                                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                                        {...headerProps}
                                        key={headerProps.key}
                                        className={className}
                                        data-testid={inputTableHeaderCellTestId}
                                    >
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();

                    return (
                        <tr
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...rowProps}
                            key={rowProps.key}
                            data-testid={inputTableRowTestId}
                        >
                            {row.cells.map((cell) => {
                                const { key } = cell.getCellProps();
                                const errorStateHasError =
                                    !!errorState?.[row.index][cell.column.id];

                                return (
                                    <EditableCell
                                        key={key}
                                        cell={cell}
                                        hasError={errorStateHasError}
                                    />
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

interface EditableCellProps<D extends Record<string, unknown>> extends TdProps {
    cell: Cell<D>;
    hasError?: boolean;
}

export function EditableCell<D extends Record<string, unknown>>({
    cell,
    hasError,
    ...tdProps
}: EditableCellProps<D>) {
    const cellProps = cell.getCellProps();
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = useCallback<OnFocus>(() => {
        setIsFocused(true);
    }, []);

    const onBlur = useCallback<OnBlur>(() => {
        setIsFocused(false);
    }, []);

    const className = classNames(cellProps.className, tdProps.className, {
        focus: isFocused,
        error: hasError
    });

    const testId = classNames(
        inputTableCellTestId,
        `${inputTableCellTestId}-${cell.column.id}`
    );

    return (
        <td
            /* eslint-disable react/jsx-props-no-spreading */
            {...cellProps}
            {...tdProps}
            /* eslint-enable react/jsx-props-no-spreading */
            className={className}
            data-testid={testId}
        >
            {cell.render('Cell', {
                editable: true,
                onFocus,
                onBlur,
                validator: cell.column.validator
            })}
        </td>
    );
}

interface EditableCellInputProps<D extends Record<string, unknown>> {
    value: string;
    row: Row<D>;
    column: Column<D>;
    updateData: InputTableProps<D>['updateData'];
    editable: boolean;
    onFocus: OnFocus;
    onBlur: OnBlur;
    validator?: (s: string) => boolean;
}

export function EditableCellInput<D extends Record<string, unknown>>({
    value,
    row: { index },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
    editable,
    validator,
    onBlur,
    onFocus
}: EditableCellInputProps<D>) {
    const onValueChange = useCallback(
        (newValue: string) => {
            if (id === undefined) {
                return;
            }

            updateData(index, id, newValue);
        },
        [updateData, index, id]
    );

    if (!editable) {
        return `${value}`;
    }

    return (
        <ValidatedInput
            className="editableCell-input _text-2"
            validator={validator}
            value={value}
            onValueChange={onValueChange}
            onFocus={onFocus}
            onBlur={onBlur}
            data-testid={inputTableCellInputTestId}
        />
    );
}

export default InputTable;
