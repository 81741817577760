import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import constants from '~/utils/constants';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { MultiPlanEditApi } from '~/api/MultiPlanEditApi';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectSchedulerId } from '~/reducers/webSolutionSlice';

import { useGetEditHistory, useGetEditRouteHistoryMetrics } from '~/hooks';

export const useUndoScheduleAction = () => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const mainClient = useSelector(selectMainClient);
    const schedulerId = useSelector(selectSchedulerId);
    const clientId = mainClient?.id;

    const { getEditHistory } = useGetEditHistory();
    const { getMetrics } = useGetEditRouteHistoryMetrics();

    const undoScheduleAction = useCallback(async () => {
        const processIndicatorState = {
            message: t('UndoAction.inProgress'),
            type: constants.processIndicator.UNDO,
            inProgress: true,
            error: false,
            position: 'center'
        };

        if (!clientId) {
            console.warn('Client id is missing');
            return;
        }

        if (!schedulerId) {
            console.warn('Scheduler id is missing');
            return;
        }

        try {
            dispatch(addProcessIndicator(processIndicatorState));
            await MultiPlanEditApi.undoScheduleAction({
                clientId,
                schedulerId
            });
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    message: t('UndoAction.done')
                })
            );
            getEditHistory();
            getMetrics();
        } catch (e) {
            console.warn(e);
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    error: true
                })
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clientId, schedulerId]);

    return {
        undoScheduleAction
    };
};
