import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDropDownMenuHandler } from '~/hooks';
import { DropDownMenu, DropDownMenuItem } from '../DropDownMenu';

import { LabelDropdownProps } from './types';
import './LabelDropdown.scss';

const ROOT_CLASS_NAME = 'label-dropdown';

export const LabelDropdown = ({
    label,
    value: selectedValue,
    validValues,
    onChange,
    disabled,
    required,
    placeholder: placeholderValue,
    className,
    'data-testid': dataTestId
}: LabelDropdownProps) => {
    const {
        ref: dropdownRef,
        isOpen,
        onToggle,
        onClose
    } = useDropDownMenuHandler();

    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_fd-column',
        '_w-100',
        className
    );
    const labelClassName = classNames(
        `${ROOT_CLASS_NAME}__label`,
        '_d-flex',
        '_fd-row',
        {
            [`${className}__label`]: className
        }
    );
    const labelTextClassName = classNames(`${ROOT_CLASS_NAME}__label-text`, {
        [`${className}__label-text`]: className
    });
    const asteriskClassName = classNames(`${ROOT_CLASS_NAME}__asterisk`, {
        [`${className}__asterisk`]: className
    });

    const outerContainerClassName = classNames(
        `${ROOT_CLASS_NAME}__outer-container`,
        {
            [`${className}__outer-container`]: className
        }
    );
    const innerContainerClassName = classNames(
        `${ROOT_CLASS_NAME}__inner-container`,
        '_d-flex',
        '_ai-center',
        '_jc-space-between',
        {
            [`${ROOT_CLASS_NAME}__inner-container--open`]: isOpen,
            [`${className}__inner-container`]: className,
            [`${className}__inner-container--open`]: className && isOpen
        }
    );
    const menuTitleClassName = classNames(`${ROOT_CLASS_NAME}__menu-title`, {
        [`${className}__menu-title`]: className
    });
    const menuTitleTextClassName = classNames(
        `${ROOT_CLASS_NAME}__menu-title-text`,
        {
            [`${className}__menu-title-text`]: className
        }
    );
    const menuItemsClassName = classNames(`${ROOT_CLASS_NAME}__menu-items`, {
        [`${className}__menu-items`]: className
    });
    const menuItemClassName = classNames(`${ROOT_CLASS_NAME}__menu-item`, {
        [`${className}__menu-item`]: className
    });
    const dropDownMenuStyles = {
        outerContainer: outerContainerClassName,
        innerContainer: innerContainerClassName,
        menuTitle: menuTitleClassName,
        menuTitleText: menuTitleTextClassName,
        menuItems: menuItemsClassName,
        dropdownToggleColor: 'var(--color-galaxy-800)'
    };

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const labelTestId = `${elementDataTestId}__label`;
    const menuTestId = `${elementDataTestId}__menu`;
    const menuItemTestId = `${elementDataTestId}__menu-item`;

    const toggleDropDown = useCallback(() => {
        if (disabled) return;
        onToggle();
    }, [disabled, onToggle]);

    const handleClickItem = useCallback(
        (value: string) => {
            onChange?.(value);
            onClose();
        },
        [onChange, onClose]
    );

    const dropdownMenuItemData = useMemo(() => {
        if (!validValues) return [];

        return validValues.map((item) => {
            return {
                key: item,
                menuItem: {
                    selected: selectedValue,
                    text: item,
                    onClick: () => {
                        handleClickItem(item);
                    }
                }
            };
        });
    }, [validValues, selectedValue, handleClickItem]);

    const showDropDownMenu = dropdownMenuItemData.length > 0;
    return (
        <label className={elementClassName} data-testid={elementDataTestId}>
            {label && (
                <div className={labelClassName} data-testid={labelTestId}>
                    <span className={labelTextClassName}>{label}</span>
                    {required && <span className={asteriskClassName}>*</span>}
                </div>
            )}
            {showDropDownMenu && (
                <DropDownMenu
                    title={{ name: selectedValue }}
                    placeholder={placeholderValue}
                    isOpen={isOpen}
                    toggle={toggleDropDown}
                    close={onClose}
                    styles={dropDownMenuStyles}
                    icon="chevronDown"
                    ref={dropdownRef}
                    data-testid={menuTestId}
                >
                    {dropdownMenuItemData.map(({ key, menuItem }) => (
                        <DropDownMenuItem
                            key={key}
                            menuItem={menuItem}
                            className={menuItemClassName}
                            data-testid={menuItemTestId}
                        />
                    ))}
                </DropDownMenu>
            )}
        </label>
    );
};
