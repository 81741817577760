import type { RootState } from '~/store';
import { createSlice } from '@reduxjs/toolkit';

import { resetOnLogout } from '~/reducers/common-actions';

export const defaultTasksDrawerFilter = {
    tasksFilterMetric: [],
    isTasksFilterOpen: false
};

export const tasksDrawerFilterSlice = createSlice({
    name: 'tasksDrawerFilter',
    initialState: defaultTasksDrawerFilter,
    reducers: {
        setIsTasksFilterOpen: (state, action) => {
            return { ...state, isTasksFilterOpen: action.payload };
        },
        setTasksFilterMetric: (state, action) => {
            return {
                ...state,
                tasksFilterMetric: action.payload
            };
        },
        resetTasksDrawerFilter: () => {
            return defaultTasksDrawerFilter;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultTasksDrawerFilter;
        });
    }
});

export const {
    setTasksFilterMetric,
    setIsTasksFilterOpen,
    resetTasksDrawerFilter
} = tasksDrawerFilterSlice.actions;

export const selectTasksFilterMetric = (state: RootState) =>
    state.tasksDrawerFilter.tasksFilterMetric;

export const selectIsTasksFilterOpen = (state: RootState) =>
    state.tasksDrawerFilter.isTasksFilterOpen;

export default tasksDrawerFilterSlice.reducer;
