import { TaskTypes } from './ApiTask';
import { Coordinates, TimeWindow } from './CommonTypes';

/**
 * Enum of supported assignment statuses
 * @category API Types
 */
export enum AssignmentStatus {
    ASSIGNED,
    IN_PROGRESS,
    COMPLETED,
    CANCELED
}

/**
 * Enum of supported assignment delay codes. Enum value starts at -1 (early)
 * @category API Types
 */
export enum AssignmentDelay {
    EARLY = -1,
    ON_TIME,
    AT_RISK,
    LATE
}

/**
 * Type for assignment props
 * @category API Types
 */
type AssignmentProps = {
    /**
     * The assignment time windows
     */
    window: Array<TimeWindow>;
    /**
     * The assignment inventory
     * @todo write TS definitions for inventory items
     */
    inventory?: Array<Record<string, unknown>>;
};

/**
 * Type for API assignments
 * @category API Types
 */
export type ApiAssignment = {
    /**
     * Id of the assignment
     */
    id: string;
    /**
     * Task associated to this assignment
     */
    task: string;
    /**
     * Driver associated to this assignment
     */
    driver: string;
    /**
     * The assignment status
     */
    status: AssignmentStatus;
    /**
     * The assignment delay
     */
    delay: AssignmentDelay;
    /**
     * The assignment type
     */
    type: TaskTypes;
    /**
     * The assignment location
     */
    location: Coordinates;
    /**
     * The assignment name
     */
    name?: string;
    /**
     * The assignment arrival time
     */
    arrivalTime?: string;
    /**
     * The assignment completed at time
     */
    completedAt?: string;
    /**
     * The assignment start service at time
     */
    startServiceAt?: string;
    /**
     * The assignment props
     */
    props: AssignmentProps;
    /**
     * The revised assignment start service at time
     */
    revisedStartServiceAt?: string;
    /**
     * The revised assignment completed at time
     */
    revisedCompletedAt?: string;
    /**
     * Assignment level photo URLS
     */
    photoUrls?: Array<string>;
    /**
     * Assignment level signature URL
     */
    signatureUrl?: string;

    /**
     * When the assignment was created
     */
    createdAt: string;

    /**
     * The assignment settings
     */
    settings?: Record<string, boolean | string | number>;
};
