import _ from 'lodash';
import constants from '~/utils/constants';
import {
    isAssignmentAtRisk,
    isAssignmentEarly,
    isAssignmentLate,
    isAssignmentOnTime
} from '~/utils/assignment-utils';
import { idUtils } from '~/utils/id-utils';
import { parseCompartmentDetails } from '~/utils/compartment-utils';

/** @typedef {import('~/api/types').Address} Address */
/** @typedef {import('~/api/types').FullAddress} FullAddress */
/** @typedef {import('~/api/types').Coordinates} Coordinates */
/** @typedef {import('~/api/types').AssignedWebColor} AssignedWebColor */
/** @typedef {import('~/api/types').TimeWindow} TimeWindow */

/**
 * PlanStop data class
 *
 * @category Data Classes
 *
 * @example
 * import { PlanStop } from '~/data-classes';
 *
 * const srcData = {};
 * const planStop = new PlanStop(srcData);
 *
 */
class PlanStop {
    constructor(stopLevelData) {
        this._stopLevelData = stopLevelData;
        this._colorCSS = {
            color: 'white',
            backgroundColor: 'gray'
        };
    }

    /**
     * the plan stop client ID
     * @type {string}
     */
    get clientId() {
        return this._stopLevelData.clientId;
    }

    /**
     * the plan stop route ID
     * @type {string}
     */
    get routeId() {
        return this._stopLevelData.routeId;
    }

    /**
     * the plan stop client-route ID
     * @type {string}
     */
    get clientRouteId() {
        return idUtils.getCombinedId(this.clientId, this.routeId);
    }

    /**
     * the plan stop task ID, an alias of [taskId]{@link PlanStop#taskId}
     * @type {string}
     * @deprecated prefer using [taskId]{@link PlanStop#taskId} for clarity in code
     */
    get stopId() {
        return this._stopLevelData.stopId;
    }

    /**
     * the plan stop task ID
     * @type {string}
     */
    get taskId() {
        return this._stopLevelData.task.taskId;
    }

    /**
     * the plan stop task ID, an alias of [taskId]{@link PlanStop#taskId}
     * @type {string}
     * @deprecated prefer using [taskId]{@link PlanStop#taskId} for clarity in code
     */
    get id() {
        return this.taskId;
    }

    /**
     * the plan stop client-route-task ID
     * @type {string}
     */
    get clientRouteTaskId() {
        return idUtils.getCombinedId(this.clientRouteId, this.taskId);
    }

    /**
     * the plan stop extended task object
     * @type {Object}
     */
    get task() {
        return {
            ...this._stopLevelData.task,
            id: this._stopLevelData.task.taskId,
            stopName: this._stopLevelData.task.location.name,
            arrivalTime: this._stopLevelData.task.eta,
            clientRouteTaskId: this.clientRouteTaskId
        };
    }

    /**
     * the plan stop address object
     * @type {Address}
     */
    get address() {
        return _.pick(this._stopLevelData.task.location, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]);
    }

    /**
     * the plan stop full address object
     * @type {FullAddress}
     */
    get location() {
        return this._stopLevelData.task.location;
    }

    /* @alias taskName */
    /**
     * the plan task stop name
     * @type {String}
     */
    get stopName() {
        return this._stopLevelData.task.location.name;
    }

    /**
     * the estimated time of arrival
     * @type {String}
     */
    get eta() {
        return this._stopLevelData.task.eta;
    }

    /**
     * the estimated time of arrival, expressed in milliseconds
     * @type {number}
     */
    get rawEta() {
        return this._stopLevelData.task.rawEta;
    }

    /**
     * route id provided by client
     * @type {String | null}
     */
    get euid() {
        return this._stopLevelData.task.euid;
    }

    /**
     * the plan stop estimated arrival time, an alias of [eta]{@link PlanStop#eta}
     * @type {String}
     */
    get arrivalTime() {
        return this.eta;
    }

    /**
     * the plan stop estimated service time expressed as ISO duration string
     * @type {String}
     */
    get serviceTime() {
        return this._stopLevelData.task.serviceTime;
    }

    /**
     * whether the plan stop service time is estimated by machine learning (ML)
     */
    get isServiceTimeML() {
        return this._stopLevelData.task.serviceTimeML;
    }

    /**
     * the plan stop service time window
     * @type {TimeWindow[]} containing localized start time and end time string
     */
    get timeWindow() {
        return this._stopLevelData.task.window;
    }

    /**
     * the plan stop service time window, an alias of [timeWindow]{@link PlanStop#timeWindow}
     * @type {TimeWindow[]} containing start time and end time string
     */
    get rawTimeWindow() {
        return this.timeWindow;
    }

    /**
     * the plan stop route date as ISO date
     * @type {string}
     */
    get routeDate() {
        return this._stopLevelData.task.routeDate;
    }

    /**
     * the plan stop route delay code
     * @type {Date}
     */
    get delayFlag() {
        return (
            this._stopLevelData.task.multiTimeWindowDelay ??
            this._stopLevelData.task.delayFlag
        );
    }

    /**
     * whether the plan stop arrival is early
     * @type {boolean}
     */
    get isEarly() {
        return isAssignmentEarly(this.delayFlag);
    }

    /**
     * whether the plan stop arrival is on-time
     * @type {boolean}
     */
    get isOnTime() {
        return isAssignmentOnTime(this.delayFlag);
    }

    /**
     * whether the plan stop arrival is at-risk
     * @type {boolean}
     */
    get isAtRisk() {
        return isAssignmentAtRisk(this.delayFlag);
    }

    /**
     * whether the plan stop arrival is late
     * @type {boolean}
     */
    get isLate() {
        return isAssignmentLate(this.delayFlag);
    }

    /**
     * whether the plan stop sequence number. Note, for a route with multiple reloads,
     * this is not indicative of the overall sequence.
     * @type {number}
     */
    get stopNumber() {
        return this._stopLevelData.task.stopNumber;
    }

    /**
     * Returns the plan stop sequence number.
     * It is indicative of the overall sequence in case of multi trip route (route with reloads)
     * @type {number}
     */
    get driverStopNumber() {
        return this._stopLevelData.task.driverStopNumber;
    }

    /**
     * the plan stop labels
     * @type {string[]}
     */
    get labels() {
        return this._stopLevelData.task.labels || [];
    }

    /**
     * whether the plan stop is planned
     * @type {boolean}
     */
    get isPlanned() {
        return this._stopLevelData.task.isPlanned;
    }

    /**
     * whether the plan stop is joined with another task (two-part task)
     * @type {boolean}
     */
    get isTwoPart() {
        return this._stopLevelData.task.isTwoPart;
    }

    /**
     * the plan stop valid two-part task ID without task type
     * only taxi-cab tasks will have task type prefix in taskId
     * carpooling two-part tasks will not have any task type prefix
     * @type {string | null}
     */
    get twoPartTaskId() {
        if (!this.isTwoPart) return null;
        const idParts = idUtils.splitCombinedId(this.taskId);

        if (idParts.length > 1) return idParts[1];

        return this.taskId;
    }

    /**
     * whether the plan stop is a depot
     * @type {boolean}
     */
    get isDepot() {
        return this._stopLevelData.task.type === constants.taskTypes.DEPOT;
    }

    /**
     * the plan stop task type
     * @type {string}
     */
    get type() {
        return this._stopLevelData.task.type;
    }

    /**
     * whether the plan stop is a pickup task
     * @type {boolean}
     */
    get isPickup() {
        return this.type === constants.taskTypes.PICKUP;
    }

    /**
     * whether the plan stop is a delivery task
     * @type {boolean}
     */
    get isDelivery() {
        return this.type === constants.taskTypes.DELIVERY;
    }

    /**
     * whether the plan stop is a high-priority task
     * @type {boolean}
     */
    get isHighPriority() {
        return (
            this._stopLevelData.task.priority === constants.priorityCodes.HIGH
        );
    }

    /**
     * the plan stop marker coordinates
     * @type {Coordinates}
     */
    get markerCoordinates() {
        return this._stopLevelData.task.markerCoordinates;
    }

    /**
     * the plan stop vehicle volume capacity used
     * @type {number}
     */
    get volumeCapacityUsed() {
        return this._stopLevelData.task.stats.volumeCapacityUsed;
    }

    /**
     * the plan stop size, an alias of [volumeCapacityUsed]{@link PlanStop#volumeCapacityUsed}
     * @type {number}
     */
    get size() {
        return this._stopLevelData.task.stats.volumeCapacityUsed;
    }

    /**
     * The plan stop size by compartment
     * For multi-compartment account setup - array of 1+
     * For single-compartment account setup - array of 1
     * @type {array}
     */
    get sizeByCompartment() {
        const sizeByCompartment =
            this._stopLevelData.task?.stats?.sizeByCompartment;
        return (sizeByCompartment || []).map((compartment) => {
            return parseCompartmentDetails(compartment);
        });
    }

    /**
     * the plan stop vehicle weight capacity used
     * @type {number}
     */
    get weightUsed() {
        return this._stopLevelData.task.stats.weightUsed;
    }

    /**
     * the plan stop number of inventory items
     * @type {number}
     */
    get numInventoryItems() {
        return this._stopLevelData.task.stats.numInventoryItems;
    }

    get recommendationScore() {
        return this._stopLevelData.recommendationScore;
    }

    /**
     * the plan stop assigned web color
     * @type {AssignedWebColor}
     */
    get colorCSS() {
        return this._colorCSS;
    }

    /**
     * update the plan stop assigned web color
     * @method
     * @param {AssignedWebColor} colorCSS - the updated assigned web color object for this plan stop
     */
    set colorCSS(colorCSS) {
        this._colorCSS = colorCSS;
    }

    /**
     * the plan stop driver break duration in ms
     * @type {number}
     */
    get breakDuration() {
        return this._stopLevelData.task.breakDetails?.duration || 0;
    }

    /**
     * the plan stop driver break details
     * @type {(BreakDetails | null)}
     */
    get breakDetails() {
        return this._stopLevelData.task.breakDetails;
    }

    get tripNumber() {
        return this._stopLevelData.tripNumber;
    }

    /**
     * verifies that this PlanStop is the same as another
     * @param {PlanStop} planStop - the plan stop object to compare against
     */
    checkIsSame(planStop) {
        const isAPlanned = this.isPlanned;
        const isBPlanned = planStop.isPlanned;
        const taskIdA = this.taskId;
        const taskIdB = planStop.taskId;
        const hasMatchingIds = taskIdA === taskIdB;
        if (isAPlanned && isBPlanned) {
            return hasMatchingIds;
        }
        if (!isAPlanned && !isBPlanned) {
            const isATwoPart = this.isTwoPart;
            const isBTwoPart = planStop.isTwoPart;
            if (isATwoPart && isBTwoPart) {
                const aType = this.type;
                const bType = planStop.type;
                const hasSameType = aType === bType;
                return hasSameType && hasMatchingIds;
            }
            return hasMatchingIds;
        }
        return false;
    }

    /**
     * Serializes this class back to JSON
     * @returns {Object}
     */
    toJSON() {
        const { task } = this;

        return {
            ...this._stopLevelData,
            task
        };
    }
}

export default PlanStop;
