import React from 'react';
import classNames from 'classnames';
import { Icon, TooltipButton, UIElement } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './StopModalMenuButton.scss';

interface StopModalMenuButtonProps extends UIElement {
    /**
     * The button icon name
     */
    buttonIcon: IconName;

    /**
     * The button icon color
     */
    buttonIconColor?: string;

    /**
     * The button text
     */
    buttonText: string;

    /**
     * The button stop text
     *
     * This text is displayed to the left of the button's arrow icon, when provided.
     * This commonly set to the stop number of the currently selected stop.
     */
    buttonStopText?: string;

    /**
     * Whether to disable the button
     */
    disabled?: boolean;

    /**
     * Whether to display an arrow on the button
     *
     * The arrow indicates that the button has additional interactions after the initial click
     */
    showButtonArrow?: boolean;

    /**
     * The button `click` event handler
     */
    onClick: () => void;
}

/**
 * The root class name that represents a `block` in BEM naming convention
 *
 * All base classnames for `elements` and `modifiers` will be derived from this
 *
 * This will also be used for the default `data-testid` for the component.
 */
const ROOT_CLASS_NAME = 'stopmodalmenubutton';

const StopModalMenuButton = ({
    buttonIcon,
    buttonIconColor = 'meteor',
    buttonText,
    buttonStopText,
    disabled = false,
    showButtonArrow = false,
    onClick,
    className,
    ...extra
}: StopModalMenuButtonProps) => {
    const dataTestId = extra['data-testid'] || ROOT_CLASS_NAME;
    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', className);
    const buttonClassName = classNames(
        `${ROOT_CLASS_NAME}__button`,
        '_d-flex _ai-center _jc-space-between',
        { [`${className}__button`]: className }
    );
    const buttonTextClassName = classNames(
        `${ROOT_CLASS_NAME}__button-text`,
        '_d-flex _ai-center _jc-center _text-3',
        { [`${className}__button-text`]: className }
    );
    const buttonStopClassName = classNames(
        `${ROOT_CLASS_NAME}__button-stop`,
        '_d-flex _ai-center _jc-center _text-3',
        { [`${className}__button-stop`]: className }
    );
    const hasButtonStopText = Boolean(buttonStopText);
    const isButtonArrowDisplayed = hasButtonStopText || showButtonArrow;

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            <TooltipButton
                className={buttonClassName}
                disabled={disabled}
                onClick={onClick}
                data-testid={`${dataTestId}_button`}
            >
                <span
                    className={buttonTextClassName}
                    data-testid={`${dataTestId}_button-text`}
                >
                    <Icon
                        icon={buttonIcon}
                        size={IconSizes.S}
                        color={buttonIconColor}
                        data-testid={`${dataTestId}_button-icon`}
                    />
                    <span data-testid={`${dataTestId}_button-label`}>
                        {buttonText}
                    </span>
                </span>
                {isButtonArrowDisplayed && (
                    <span className={buttonStopClassName}>
                        {hasButtonStopText && (
                            <span
                                data-testid={`${dataTestId}_button-stop-text`}
                            >
                                {buttonStopText}
                            </span>
                        )}

                        {showButtonArrow && (
                            <Icon
                                icon="chevronDown"
                                size={IconSizes.S}
                                color="galaxy-500"
                                sx={{ transform: 'rotate(-90deg)' }}
                                data-testid={`${dataTestId}_button-stop-icon`}
                            />
                        )}
                    </span>
                )}
            </TooltipButton>
        </div>
    );
};

export default StopModalMenuButton;
