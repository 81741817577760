import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeVehicleById } from '~/reducers/vehiclesSlice';
import { VehicleApi } from '~/api/VehicleApi';
import { addToast } from '~/reducers/toastsSlice';

type Props = {
    vehicleId: string;
};
export const useDeleteVehicle = ({ vehicleId }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('error');
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const deleteVehicle = async () => {
        setIsDeleting(true);

        try {
            const {
                data: {
                    data: { id }
                }
            } = await VehicleApi.delete(vehicleId);

            dispatch(removeVehicleById(id));
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('vehicleDeleteError'),
                    variant: 'error'
                })
            );
        } finally {
            setIsDeleting(false);
        }
    };

    return {
        deleteVehicle,
        isDeleting
    };
};
