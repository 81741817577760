import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames';
import { UIElement } from '../types';
import './Highlight.scss';

interface HighlightProps extends UIElement {
    /**
     * the complete input string
     */
    inputText: string;
    /**
     * search text for filter
     */
    searchText?: string;
    /**
     * color for search text highlight
     */
    color?: string;
    /**
     * font weight of the search text highlight
     */
    fontWeight?: string;
}

interface HighlightDisplayText {
    /**
     * The original input string
     */
    original?: string;
    /**
     * the unhighlighted prefix text
     */
    prefix?: string;
    /**
     * the highlighted text
     */
    highlight?: string;
    /**
     * the unhighlighted suffix text
     */
    suffix?: string;
}

const ROOT_CLASS_NAME = 'highlight';

const Highlight: FC<HighlightProps> = ({
    inputText,
    searchText = '',
    color,
    fontWeight,
    className,
    ...extra
}) => {
    const dataTestId = extra['data-testid'] || ROOT_CLASS_NAME;
    const elementClassName = classNames(ROOT_CLASS_NAME, className);
    const highlightClassName = classNames(`${ROOT_CLASS_NAME}__highlight`, {
        [`${ROOT_CLASS_NAME}__highlight--${color}`]: color
    });

    const displayText = useMemo<HighlightDisplayText>(() => {
        const idx = inputText.toLowerCase().indexOf(searchText.toLowerCase());
        const searchTextLength = searchText.length;
        const isHighlighted = idx >= 0;
        const highlightResults = {
            prefix: inputText.substring(0, idx),
            highlight: inputText.substring(idx, idx + searchTextLength),
            suffix: inputText.substring(idx + searchTextLength)
        };

        return isHighlighted ? highlightResults : { original: inputText };
    }, [inputText, searchText]);

    const { original, prefix, highlight, suffix } = displayText;

    return (
        <span className={elementClassName} data-testid={dataTestId}>
            {original}
            {prefix}
            {highlight && (
                <span
                    style={{ fontWeight }}
                    className={highlightClassName}
                    data-testid={`${dataTestId}__highlight`}
                >
                    {highlight}
                </span>
            )}
            {suffix}
        </span>
    );
};

export default memo(Highlight);
