import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectShowUnassignedTasks,
    setShowUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { setIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { selectOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';
import { IconButton, theme } from '~/ui';
import { useIntermodalFeatures, useMapUtils } from '~/hooks';
import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';
import { selectUnassignedTasksV2 } from '~/reducers/tasksV2Slice';

import './on-demand-dispatch-map-control.scss';

export default function OnDemandDispatchMapControl() {
    const { deselectDriver } = useLiveRoutesUtils();

    const onDemandDispatchTaskCount = useSelector(
        selectOnDemandDispatchTaskCount
    );
    const unassignedTasksV2 = useSelector(selectUnassignedTasksV2);

    const { enableLiveDispatch } = useIntermodalFeatures();

    const { isDispatchedRouteMode, mapRouteMode } = useMapUtils();

    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(mapRouteMode)
    );
    const dispatch = useDispatch();

    const shouldRender = useMemo(() => {
        const hasUnassignedTasksSelectedDay = onDemandDispatchTaskCount > 0;
        const hasUnassignedTasksAnyDay =
            enableLiveDispatch && unassignedTasksV2?.length;

        return (
            isDispatchedRouteMode &&
            (hasUnassignedTasksSelectedDay || hasUnassignedTasksAnyDay)
        );
    }, [
        isDispatchedRouteMode,
        onDemandDispatchTaskCount,
        unassignedTasksV2,
        enableLiveDispatch
    ]);

    const handleClick = useCallback(() => {
        dispatch(
            setShowUnassignedTasks({
                mode: mapRouteMode,
                value: !showUnassignedTasks
            })
        );

        if (!enableLiveDispatch) {
            deselectDriver();
            dispatch(setIsOpenUnassignedTasksDrawer(!showUnassignedTasks));
        }
    }, [
        deselectDriver,
        dispatch,
        showUnassignedTasks,
        mapRouteMode,
        enableLiveDispatch
    ]);

    if (!shouldRender) {
        return null;
    }

    return (
        <IconButton
            className="ondemanddispatchmapcontrol_marker"
            text="?"
            textColor={theme.colors.comet}
            textStyle={{
                ...theme.text['14-medium']
            }}
            style={{
                backgroundColor: theme.colors['galaxy-500']
            }}
            onClick={handleClick}
        />
    );
}
