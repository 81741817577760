import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '~/ui';
import constants from '~/utils/constants';

import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import './clustermarker.scss';

function ClusterMarker({
    numClustered = -1,
    expansionZoom,
    colorCSS,
    superClusterIndex,
    clusterId,
    emittedEventHandler,
    id,
    isPlanned,
    lat,
    lng
}) {
    const { mapChildEvents } = constants;
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    const selectedMapStops = useSelector(selectSelectedMapStops);
    function _handleMouseEnter() {
        setIsHovered(true);
        emittedEventHandler({
            event: mapChildEvents.CLUSTER_MOUSEENTER,
            payload: {
                superClusterIndex,
                clusterId,
                coordinates: {
                    lat,
                    lng
                }
            }
        });
    }

    function _handleMouseLeave() {
        setIsHovered(false);
        emittedEventHandler({
            event: mapChildEvents.CLUSTER_MOUSELEAVE,
            payload: {
                superClusterIndex,
                clusterId
            }
        });
    }

    function _handleDragStart() {
        emittedEventHandler({
            event: mapChildEvents.CLUSTER_DRAGSTART,
            payload: {
                isSelected: false,
                isPlanned,
                id,
                superClusterIndex,
                clusterId,
                type: mapChildEvents.CLUSTER_DRAGSTART,
                selectedMapStops
            }
        });
    }

    function _handleMouseUp() {
        emittedEventHandler({
            event: mapChildEvents.CLUSTER_MOUSEUP,
            payload: {
                latLng: {
                    lat,
                    lng
                },
                id,
                isPlanned,
                expansionZoom,
                superClusterIndex,
                clusterId,
                selectedMapStops
            }
        });
    }

    function _getClusterContent() {
        if (isHovered) {
            return <Icon icon="clusterZoomIn" />;
        }
        return (
            <>
                <span className="clustermarker-main">{numClustered}</span>
                <span>{t('stop', { count: numClustered })}</span>
            </>
        );
    }

    return (
        <button
            type="button"
            className="clustermarker"
            draggable="true"
            onDragStart={_handleDragStart}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            onMouseUp={_handleMouseUp}
        >
            <span className="clustermarker-circle" style={colorCSS}>
                {_getClusterContent()}
            </span>
        </button>
    );
}

export default ClusterMarker;
