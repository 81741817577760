import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const customerSearchResultsSlice = createSlice({
    name: 'customerSearchResults',
    initialState: [],
    reducers: {
        setCustomerSearchResults: (state, action) => {
            return action.payload;
        },
        clearCustomerSearchResults: () => []
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const { setCustomerSearchResults, clearCustomerSearchResults } =
    customerSearchResultsSlice.actions;

export const selectCustomerSearchResults = (state) =>
    state.customerSearchResults;

export default customerSearchResultsSlice.reducer;
