import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import {
    MultiPlanEditApi,
    AppendRouteResponseData,
    ChangeRouteDriverResponseData
} from '~/api/MultiPlanEditApi';
import constants from '~/utils/constants';
import { selectAllMainClientPreferences } from '~/reducers/mainClientSlice';
import { IApiResponse } from '~/api/types';

type AppendRouteParams = {
    sourceRouteId: string;
    targetDriverId: string;
    targetVehicleId: string;
    routeDate: string;
};

type ChangeDriverParams = {
    sourceRouteId: string;
    targetDriverId: string;
    date: string;
};

type UseMultiPlanEditReturnValues = {
    appendRoute: (
        params: AppendRouteParams
    ) => Promise<IApiResponse<AppendRouteResponseData>>;
    changeRouteDriver: (
        params: ChangeDriverParams
    ) => Promise<IApiResponse<ChangeRouteDriverResponseData>>;
};

export const useMultiPlanEdit = (): UseMultiPlanEditReturnValues => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const clientPreferences = useSelector(selectAllMainClientPreferences);

    const appendRoute = async ({
        sourceRouteId,
        targetDriverId,
        targetVehicleId,
        routeDate
    }: AppendRouteParams): Promise<IApiResponse<AppendRouteResponseData>> => {
        const processIndicatorState = {
            message: t('ChangeDriverVehicleControl.progress.AppendingRoute'),
            type: constants.processIndicator.APPEND_ROUTE,
            inProgress: true,
            error: false,
            position: 'center'
        };
        try {
            dispatch(addProcessIndicator(processIndicatorState));
            const response = await MultiPlanEditApi.appendRoute({
                routeId: sourceRouteId,
                driverId: targetDriverId,
                vehicleId: targetVehicleId,
                routeDate,
                type: constants.productTypes.SCHEDULER,
                ...(clientPreferences || {})
            });
            return response.data;
        } catch (e) {
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    error: true
                })
            );
            throw e;
        }
    };

    const changeRouteDriver = async ({
        sourceRouteId,
        targetDriverId,
        date
    }: ChangeDriverParams): Promise<
        IApiResponse<ChangeRouteDriverResponseData>
    > => {
        const processIndicatorState = {
            message: t(
                'ChangeDriverVehicleControl.progress.ChangingRouteDriver'
            ),
            type: constants.processIndicator.CHANGE_DRIVER,
            inProgress: true,
            error: false,
            position: 'center'
        };
        try {
            dispatch(addProcessIndicator(processIndicatorState));
            const response = await MultiPlanEditApi.changeRouteDriver({
                routeId: sourceRouteId,
                driverId: targetDriverId,
                date,
                type: 'Scheduler'
            });
            return response.data;
        } catch (e) {
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    error: true
                })
            );
            throw e;
        }
    };

    return {
        appendRoute,
        changeRouteDriver
    };
};
