import { useMemo } from 'react';

import { AssignmentStatus } from '~/api/types';

import { LiveDriver, LiveStop } from '~/data-classes';

import {
    getAssignmentCodeLabel,
    getDelayCodeLabel,
    getProgressBarAssignmentColor,
    getProgressBarDelayColor
} from '~/utils/assignment-utils';

import {
    RouteCardProgressBarGradientProps,
    RouteCardProgressBarGradientStyle,
    RouteCardProgressBarPositionStyle
} from '../types';

const DRIVER_OFFLINE_COLOR = 'var(--color-driver-offline)';

const COMPLETED_ASSIGNMENT = getAssignmentCodeLabel(AssignmentStatus.COMPLETED);

export const useRouteCardProgressBarColors = (routeData: LiveDriver) => {
    const {
        schedule,
        currentStopIndex,
        isCompleted: isRouteCompleted,
        isCompletedWithCanceledTasks: isRouteCompletedWithCanceledTasks,
        isDriverActive
    } = routeData;

    const driverOffline = !isDriverActive;

    const isScheduleCompleted =
        isRouteCompleted || isRouteCompletedWithCanceledTasks;
    const isScheduleEmpty = schedule.length === 0;
    const currentStop = schedule[currentStopIndex] || {};
    const { isDepot } = currentStop;

    const isEmptyProgressBar =
        isScheduleEmpty || (isDepot && !isScheduleCompleted);

    const isHiddenPointer = isEmptyProgressBar || isScheduleCompleted;

    const reduceSchedule = (
        compiled: RouteCardProgressBarGradientProps,
        item: LiveStop,
        idx: number,
        stops: LiveStop[]
    ) => {
        const stopsLength = stops.length;
        const currentStopItem = stops.findIndex(
            (stop) => !stop.isCompleted && !stop.isCanceled
        );
        const { gradient, position, statusColor } = compiled;
        const {
            status: assignmentCode,
            delay: delayCode,
            isCanceled,
            isCompleted
        } = item;

        const currentGradient = [...gradient];
        const start = (idx / stopsLength) * 100;
        const end = ((idx + 1) / stopsLength) * 100;
        const isStopCompleted = isCompleted || isCanceled;

        const delayStatus = getDelayCodeLabel(delayCode);
        const assignmentStatus = getAssignmentCodeLabel(assignmentCode);

        const canceledStopColor =
            isCanceled && getProgressBarDelayColor(assignmentStatus);
        const completedStopColor =
            isStopCompleted && getProgressBarDelayColor(delayStatus, true);
        const delayStatusColor = getProgressBarDelayColor(delayStatus);

        const color =
            canceledStopColor || completedStopColor || delayStatusColor;

        const currentBand = {
            color,
            start,
            end
        };
        const previousBand = currentGradient.pop();

        // consolidate consecutive same gradient color bands
        if (previousBand && previousBand.color === currentBand.color) {
            currentBand.start = previousBand.start;
        } else if (previousBand) currentGradient.push(previousBand);

        // update gradient
        currentGradient.push(currentBand);

        // position values
        const completedPosition = currentStopItem < 0 && 100;
        const currentStopPosition = idx === currentStopItem && start;

        // status color values
        const offlineStatusColor = driverOffline && DRIVER_OFFLINE_COLOR;
        const completedStatusColor =
            currentStopItem < 0 &&
            getProgressBarAssignmentColor(COMPLETED_ASSIGNMENT);
        const currentStopStatusColor =
            idx === currentStopItem &&
            getProgressBarAssignmentColor(assignmentStatus);

        return {
            gradient: currentGradient,
            position: completedPosition || currentStopPosition || position,
            statusColor:
                offlineStatusColor ||
                completedStatusColor ||
                currentStopStatusColor ||
                statusColor
        };
    };

    const statusBar = useMemo(() => {
        return schedule
            .filter((item) => {
                return !item.isDepot;
            })
            .reduce(reduceSchedule, {
                gradient: [],
                position: 0,
                statusColor: ''
            }) as RouteCardProgressBarGradientProps;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [schedule]);

    const routeStatusGradient = useMemo<
        RouteCardProgressBarGradientStyle | undefined
    >(() => {
        if (isEmptyProgressBar) return;

        const gradientBands = statusBar.gradient
            .map((band) => {
                const { color, start, end } = band;
                return `${color} ${start}%, ${color} ${end}%`;
            })
            .join(', ');
        return {
            background: `linear-gradient(90deg, ${gradientBands})`
        };
    }, [isEmptyProgressBar, statusBar]);

    const routeStatusPosition = useMemo<
        RouteCardProgressBarPositionStyle | undefined
    >(() => {
        if (isHiddenPointer) return;

        return {
            left: `${statusBar.position}%`,
            backgroundColor: statusBar.statusColor
        };
    }, [isHiddenPointer, statusBar]);

    return {
        routeStatusGradient,
        routeStatusPosition
    };
};
