import { Task } from '~/data-classes';
import { taskDataFactory } from '~/utils/data-factory';
import { markerMaker } from '~/utils/map';
import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
import Supercluster from 'supercluster';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { DeliveryTask, PickupTask } from '~/api/types';

const makeMarkers = ({
    superCluster,
    geoJSONFeatures,
    superClusterIndex,
    emittedEventHandler
}: {
    superCluster: Supercluster.AnyProps;
    geoJSONFeatures: Supercluster.AnyProps[];
    superClusterIndex: number;
    emittedEventHandler: EmittedEventHandler;
}) => {
    const markers = geoJSONFeatures.map((geoJSONFeature) => {
        const isCluster = geoJSONFeature.properties.cluster;

        if (isCluster) {
            return markerMaker.makeUnassignedClusterMarker({
                superCluster,
                geoJSONFeature,
                superClusterIndex,
                emittedEventHandler
            });
        }

        const { properties: task } = geoJSONFeature;

        const unassignedTask = new Task(task);
        const { isPickup, pickupTask, deliveryTask, isTwoPart } =
            unassignedTask;

        if (!isTwoPart) {
            const stopDataTask = taskDataFactory.makeOnDemandDispatchStop(
                (isPickup ? pickupTask : deliveryTask) as
                    | PickupTask
                    | DeliveryTask
            );

            return markerMaker.makeUnassignedStopMarker(
                emittedEventHandler,
                stopDataTask
            );
        }

        return mapPlanStopUtils
            .mapTwoPartTaskToOnDemandDispatchStops(task)
            .map((stopDataTask) => {
                return markerMaker.makeUnassignedStopMarker(
                    emittedEventHandler,
                    stopDataTask
                );
            });
    });
    return markers;
};

export default makeMarkers;
