/**
 * Available downloadable report categories
 */
export enum DownloadableReportCategories {
    /**
     * Standard or Core report category
     *
     * reports categorized as `core` are standard reports available to clients
     */
    CORE = 'core',

    /**
     * Custom report category
     *
     * reports categorized as `custom` are non-standard reports available to select clients
     */
    CUSTOM = 'custom',

    /**
     * Unknown report category
     *
     * reports that do not belong with either `core` or `custom` category.
     */
    UNKNOWN = 'unknown'
}
