import { createSelector, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { resetOnLogout } from '~/reducers/common-actions';

function deduplicateLocationUpdates(storeUpdates, incomingUpdates) {
    if (!storeUpdates || !storeUpdates.length) return incomingUpdates;
    if (!incomingUpdates || !incomingUpdates.length) return [];
    const oldestIncomingUpdateTime = DateTime.fromISO(
        incomingUpdates[0].serverTime
    );
    const intersectionStart = storeUpdates.findIndex(
        (su) => DateTime.fromISO(su.serverTime) >= oldestIncomingUpdateTime
    );
    if (intersectionStart === -1) {
        return incomingUpdates;
    }
    const intersectionSize = storeUpdates.length - intersectionStart;
    return incomingUpdates.slice(intersectionSize);
}

export const driversLocationsSlice = createSlice({
    name: 'driversLocations',
    initialState: {},
    reducers: {
        setDriversLocations: (state, action) => {
            return action.payload;
        },
        updateDriversLocations: (state, action) => {
            for (const [key, value] of Object.entries(action.payload)) {
                if (!state[key]) state[key] = value;
                const newLocationUpdates = deduplicateLocationUpdates(
                    state[key],
                    value
                );
                state[key] = state[key].concat(newLocationUpdates);
            }
            return state;
        },
        resetDriversLocations: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const {
    setDriversLocations,
    updateDriversLocations,
    resetDriversLocations
} = driversLocationsSlice.actions;

export const selectDriversLocations = (state) => state.driversLocations;

export const selectDriverLocationById = (id) => (state) =>
    state.driversLocations[id];

export const selectDriverLocationsByIds = createSelector(
    selectDriversLocations,
    (_state, driverIds) => driverIds,
    (driverLocations, ids) => ids.map((id) => driverLocations[id])
);

export const selectOldestLastLocationUpdate = createSelector(
    (state, driverIds) => selectDriverLocationsByIds(state, driverIds),
    (driverLocations) => {
        const dateTimeResult = driverLocations.reduce(
            (acc, locationUpdates) => {
                if (!locationUpdates || !locationUpdates.length) return acc;
                const lastUpdateTime = DateTime.fromISO(
                    locationUpdates[locationUpdates.length - 1].serverTime
                );
                return acc && acc < lastUpdateTime ? acc : lastUpdateTime;
            },
            null
        );
        return dateTimeResult?.isValid ? dateTimeResult.toISO() : null;
    }
);

export default driversLocationsSlice.reducer;
