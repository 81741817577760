import { isInteger } from 'lodash';
import generalUtils from '~/utils/general-utils';
import dateUtils from '~/utils/date-utils';
import { ApiParamError } from './errors';
import { DeliveryTaskProps, PickupTaskProps } from '../types';
import {
    NumberParamValidationCallback,
    StringParamValidationCallback,
    TaskDataParamValidationCallback
} from './types';

/**
 * A generic function that validates a provided string as a UUID string
 *
 * This function can be used as-is to test for a UUID string parameter.
 * Without the `errorMessageMap` parameter, this function will return generic error messages.
 *
 * However, it recommended that you provide more specific error messages to facilitate debugging.
 *
 * @param param the parameter value to test
 * @param errorMessageMap the custom error mapping
 * @returns the error message
 */
export const getParamErrorUUID: StringParamValidationCallback = (
    param,
    errorMessageMap
) => {
    const { missing, invalid } = errorMessageMap || {};

    if (!param) {
        return missing || ApiParamError.GENERIC_MISSING_PARAM;
    }

    if (!generalUtils.isValidUUID(param)) {
        return invalid || ApiParamError.GENERIC_INVALID_PARAM_STRING_UUID;
    }
};

/**
 * A generic function that validates a provided string as an ISO 8601 date-time string
 *
 * This function can be used as-is to test for a  ISO 8601 date-time string parameter.
 * Without the `errorMessageMap` parameter, this function will return generic error messages.
 *
 * However, it recommended that you provide more specific error messages to facilitate debugging.
 *
 * @param param the parameter value to test
 * @param errorMessageMap the custom error mapping
 * @returns the error message
 */
export const getParamErrorDateISO: StringParamValidationCallback = (
    param,
    errorMessageMap
) => {
    const { missing, invalid } = errorMessageMap || {};

    if (!param) {
        return missing || ApiParamError.GENERIC_MISSING_PARAM;
    }

    if (!dateUtils.isValidDateISO(param)) {
        return invalid || ApiParamError.GENERIC_INVALID_PARAM_STRING_DATE_ISO;
    }
};

/**
 * A generic function that validates a provided number as an integer
 *
 * This function can be used as-is to test for an integer parameter.
 * Without the `errorMessageMap` parameter, this function will return generic error messages.
 *
 * However, it recommended that you provide more specific error messages to facilitate debugging.
 *
 * @param param the parameter value to test
 * @param errorMessageMap the custom error mapping
 * @returns the error message
 */
export const getParamErrorInteger: NumberParamValidationCallback = (
    param,
    errorMessageMap
) => {
    const { missing, invalid } = errorMessageMap || {};

    if (!param && param !== 0) {
        return missing || ApiParamError.GENERIC_MISSING_PARAM;
    }

    if (!isInteger(param)) {
        return invalid || ApiParamError.GENERIC_INVALID_PARAM_INTEGER;
    }
};

/**
 * An implementation of `getParamErrorUUID`
 *
 * This function validates a parameter that represents a `driver ID`.
 *
 * This returns error messages that identify the parameter as `driver ID`
 *
 * @param param the parameter value to test
 * @returns the error message
 */
export const getParamErrorDriverId: StringParamValidationCallback = (param) => {
    const errorMessageMap = {
        missing: ApiParamError.MISSING_DRIVER_ID,
        invalid: ApiParamError.INVALID_DRIVER_ID
    };
    return getParamErrorUUID(param, errorMessageMap);
};

/**
 * An implementation of `getParamErrorUUID`
 *
 * This function validates a parameter that represents an `assignment ID`.
 *
 * This returns error messages that identify the parameter as `assignment ID`
 *
 * @param param the parameter value to test
 * @returns the error message
 */
export const getParamErrorAssignmentId: StringParamValidationCallback = (
    param
) => {
    const errorMessageMap = {
        missing: ApiParamError.MISSING_ASSIGNMENT_ID,
        invalid: ApiParamError.INVALID_ASSIGNMENT_ID
    };
    return getParamErrorUUID(param, errorMessageMap);
};

/**
 * An implementation of `getParamErrorDateISO`
 *
 * This function validates a parameter that represents `timestamp`.
 *
 * This returns error messages that identify the parameter as `timestamp`
 *
 * @param param the parameter value to test
 * @returns the error message
 */
export const getParamErrorTimestamp: StringParamValidationCallback = (
    param
) => {
    const errorMessageMap = {
        missing: ApiParamError.MISSING_TIMESTAMP,
        invalid: ApiParamError.INVALID_TIMESTAMP
    };
    return getParamErrorDateISO(param, errorMessageMap);
};

/**
 * An implementation of `getParamErrorTaskWindowSlots`
 *
 * This function validates a parameter that represents `delivery/pickup windows`.
 *
 * This returns error messages that identify the parameter as `delivery/pickup windows`
 *
 * @param param the parameter value to test
 * @returns the error message
 */
export const getParamErrorTaskWindowSlots: TaskDataParamValidationCallback = (
    param
) => {
    const { props } = param || {};

    const dataDelivery = (props as DeliveryTaskProps)?.deliveryWindow;
    if (dataDelivery) {
        const filteredDelivery = dataDelivery.filter(
            (info: { start: string; end: string }) => {
                const startDateTime = new Date(info.start);
                const endDateTime = new Date(info.end);
                return startDateTime > endDateTime;
            }
        );
        if (filteredDelivery.length) {
            return ApiParamError.INVALID_DELIVERY_WINDOW;
        }
    }

    const dataPickup = (props as PickupTaskProps)?.pickupWindow;
    if (dataPickup) {
        const filteredPickup = dataPickup.filter(
            (info: { start: string; end: string }) => {
                const startDateTime = new Date(info.start);
                const endDateTime = new Date(info.end);
                return startDateTime > endDateTime;
            }
        );
        if (filteredPickup.length) {
            return ApiParamError.INVALID_PICKUP_WINDOW;
        }
    }
    return undefined;
};
