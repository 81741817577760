import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from '~/hooks';
import {
    selectOperationalStop,
    setExternalTaskType
} from '~/reducers/addOperationalStopsSlice';
import { FlexLayout, LabelInput } from '~/ui';
import DropDownMenu from '~/ui/components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from '~/ui/components/DropDownMenu/DropDownMenuItem';
import { labelInputTestIdSuffix } from '~/ui/components/LabelInput/LabelInput';
import constants from '~/utils/constants';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { OperationalStopInput } from './OperationalStopInput';

import './AddOperationalStopDataContent.scss';

const className = 'add-operational-stop-modal';
export const datePickerId = `${className}-datepicker`;

export const testId = 'addOperationalStopData_testId';
export const addressAptWrapperTestId =
    'addOperationalStopData_addressAptWrapperTestId';
export const addressAptTestId = `${addressAptWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressCityWrapperTestId =
    'addOperationalStopData_addressCityWrapperTestId';
export const addressCityTestId = `${addressCityWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressStateWrapperTestId =
    'addOperationalStopData_addressStateWrapperTestId';
export const addressStateTestId = `${addressStateWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressStreetWrapperTestId =
    'addOperationalStopData_addressStreetWrapperTestId';
export const addressStreetTestId = `${addressStreetWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressZipCodeWrapperTestId =
    'addOperationalStopData_addressZipCodeWrapperTestId';
export const addressZipCodeTestId = `${addressZipCodeWrapperTestId}-${labelInputTestIdSuffix}`;
export const customerIdWrapperTestId =
    'addOperationalStopData_customerIdWrapperTestId';
export const customerIdTestId = `${customerIdWrapperTestId}-${labelInputTestIdSuffix}`;
export const customerNameWrapperTestId =
    'addOperationalStopData_customerNameWrapperTestId';
export const customerNameTestId = `${customerNameWrapperTestId}-${labelInputTestIdSuffix}`;
export const latitudeWrapperTestId =
    'addOperationalStopData_latitudeWrapperTestId';
export const latitudeTestId = `${latitudeWrapperTestId}-${labelInputTestIdSuffix}`;
export const longitudeWrapperTestId =
    'addOperationalStopData_longitudeWrapperTestId';
export const longitudeTestId = `${longitudeWrapperTestId}-${labelInputTestIdSuffix}`;
export const taskTypeRadioGroupTestId =
    'addOperationalStopData_taskTypeRadioGroupTestId';
export const taskTypeRadioTestId = 'addOperationalStopData_taskTypeRadioTestId';
export const datePickerTestId = 'addOperationalStopData_datepickerTestId';
export const externalTaskTypeWrapperTestId =
    'addOperationalStopData_externalTaskTypeWrapperTestId';
export const externalTaskTypeTestId = `${externalTaskTypeWrapperTestId}-${labelInputTestIdSuffix}`;

export const AddOperationalStopDataContent: React.FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['addOperationalStop']);
    const externalTaskTypeDropDownMenuRef = useRef<HTMLDivElement>(null);

    const [
        isExternalTaskTypeDropDownMenuOpen,
        setIsExternalTaskTypeDropDownMenuOpen
    ] = useState(false);
    const toggleExternalTaskTypeDropDown = useCallback(() => {
        setIsExternalTaskTypeDropDownMenuOpen(
            (isDropDownOpen) => !isDropDownOpen
        );
    }, []);
    const closeExternalTaskTypeDropDown = useCallback(() => {
        setIsExternalTaskTypeDropDownMenuOpen(false);
    }, []);
    const isPredefinedExternalTaskTypeEnabled = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );

    const predefinedExternalTaskTypes = Object.values(
        constants.translationKeys.externalTaskTypes.operationalStop
    ).map((translationKey) => t(translationKey));

    const handleMenuItemClick = (externalTaskType: string) => {
        dispatch(setExternalTaskType(externalTaskType));
        closeExternalTaskTypeDropDown();
    };

    const operationalStop = useSelector(selectOperationalStop);
    const { externalTaskType } = operationalStop || {};

    return (
        <div className="operational-tab-content" data-testid={testId}>
            {isPredefinedExternalTaskTypeEnabled ? (
                <FlexLayout
                    className="operational-tab-content__external-task-type _mt-4"
                    flexDirection="column"
                    sx={{ rowGap: '1.6rem' }}
                >
                    <label className="external-task-type-dropdown-label">
                        {t('fields.externalTaskType.label')}
                    </label>
                    <DropDownMenu
                        title={{ name: externalTaskType }}
                        placeholder={t('fields.externalTaskType.label')}
                        styles={{
                            innerContainer:
                                'dropdown-flex-space-between dropdown_outlined _mb-4 external-task-type-dropdown',
                            menuTitle: 'dropdown-selection',
                            menuTitleText: 'dropdown-title-text',
                            dropdownToggleColor: 'var(--color-galaxy-800)'
                        }}
                        ref={externalTaskTypeDropDownMenuRef}
                        isOpen={isExternalTaskTypeDropDownMenuOpen}
                        toggle={toggleExternalTaskTypeDropDown}
                        close={closeExternalTaskTypeDropDown}
                        icon="chevronDown"
                        position="down"
                        data-testid={`${externalTaskTypeWrapperTestId}-Dropdown`}
                    >
                        {predefinedExternalTaskTypes.map((text: string) => (
                            <DropDownMenuItem
                                key={text}
                                menuItem={{
                                    text,
                                    onClick: () => handleMenuItemClick(text)
                                }}
                                data-testid={text}
                            />
                        ))}
                    </DropDownMenu>
                </FlexLayout>
            ) : (
                <LabelInput
                    className="operational-tab-content__external-task-type _mt-4"
                    label={t('fields.externalTaskType.label')}
                    placeholder={t('fields.externalTaskType.placeholder')}
                    data-testid={externalTaskTypeWrapperTestId}
                />
            )}
            <OperationalStopInput />
        </div>
    );
};
