import React from 'react';
import { useTranslation } from 'react-i18next';

const LabelAndValue = ({ label, value = 0 }) => {
    return (
        <span className="zonemanagement-stats-stat _text-2">
            {label}:<span>&nbsp;{value}</span>
        </span>
    );
};

export const Stats = () => {
    const { t } = useTranslation('zoneManagement');

    return (
        <div
            className="zonemanagement-stats _fd-row _d-none"
            data-testid="zone-management-stats"
        >
            <LabelAndValue label={t('stats.totalZones')} />
            <LabelAndValue label={t('stats.totalVehicles')} />
            <LabelAndValue label={t('stats.totalDrivers')} />
        </div>
    );
};
