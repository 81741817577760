import { OpenSearchAttributeCount } from './OpenSearch';

export const enum SortDir {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * Parameters used for pagination-related requests
 */
export interface PaginationParams {
    /**
     * The page number of the paginated API request
     */
    page?: number;

    /**
     * The page size of the paginated API request
     */
    limit?: number;

    /**
     * The sort direction of the paginated API request
     */
    sortdir?: SortDir;
}

/**
 * The extended to include next property for pagination response
 */
export interface ExtendedPaginationParams extends PaginationParams {
    /**
     * this property will identify next number of screen
     */
    next?: number;
}

/**
 * The typical response metadata associated when making a paginated API request
 */
export interface PaginationMetadata extends PaginationParams {
    /**
     * The record count to start at of the paginated API request
     */
    startAt?: number;

    /**
     * The total number of matching records of the paginated API request
     */
    total?: number;
}

/**
 * The typical response metadata associated when making a paginated OpenSearch API request
 */
export interface OpenSearchPaginationMetadata {
    /**
     * The total page number of the paginated API request
     */
    totalPageNumber?: number;

    /**
     * The OpenSearch attributes of the paginated API request
     */
    attributes?: Record<string, OpenSearchAttributeCount[]>;
}

/**
 * The response metadata expected when making the paginated api request for Equipment API
 */
export interface EquipmentPaginationMetadata
    extends OpenSearchPaginationMetadata {
    /**
     * The total number of matching records of the paginated API request
     */
    totalCount?: number;

    /**
     * The current page number of the paginated API request
     */
    currentPageNumber?: number;
}

/**
 * The response metadata expected when making the paginated api request for TaskV2 API
 */
export interface TasksV2PaginationMetadata
    extends PaginationParams,
        OpenSearchPaginationMetadata {
    /**
     * The total number of matching records of the paginated API request
     */
    total?: number;
}
