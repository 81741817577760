import React from 'react';
import PT from 'prop-types';

import './OptimConfigTextInput.scss';
import classNames from 'classnames';

function OptimConfigTextInput({
    placeholder,
    unitText,
    isErrorState = false,
    value,
    onChange,
    disabled,
    readOnly = false,
    name = '',
    type = 'text',
    className = ''
}) {
    return (
        <div
            className={classNames(
                'optim-config-text-input_container',
                className
            )}
        >
            <input
                type={type}
                className={classNames('optim-config-text-input_input', {
                    'optim-config-text-input--error': isErrorState
                })}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                data-testid="optim-config-text-input"
            />
            <div className="_d-flex _ai-center unit-text_container">
                {unitText && (
                    <span
                        className="unit-text_element _ai-center _ws-nowrap"
                        data-testid="unit-text"
                    >
                        {unitText}
                    </span>
                )}
            </div>
        </div>
    );
}

export default OptimConfigTextInput;

OptimConfigTextInput.propTypes = {
    /** Input placeholder */
    placeholder: PT.string,
    /** Unit of input */
    unitText: PT.string,
    /** Input value */
    value: PT.oneOfType([PT.string, PT.number]),
    /** Input onChange function */
    onChange: PT.func,
    /** Error state of input */
    isErrorState: PT.bool,
    /** Input disabled */
    disabled: PT.bool,
    readOnly: PT.bool,
    /** Input name */
    name: PT.string,
    /** Input type */
    type: PT.string
};
