import i18n from 'i18next';

export const localizeColumnsNames = ({
    translationKeyPrefix,
    columnsNames,
    items
}: {
    translationKeyPrefix: string;
    columnsNames: string[];
    items: Record<string, string>[];
}) => {
    const setOfNames = new Set(columnsNames);

    return items.map((item) => {
        const entries = Object.entries(item);
        const newEntries = entries.map(([key, value]) => {
            let newKey = key;

            if (setOfNames.has(key)) {
                newKey = i18n.t(`${translationKeyPrefix}.${key}`);
            }

            return [newKey, value];
        });

        return Object.fromEntries(newEntries);
    });
};
