import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { resetOnLogout } from '~/reducers/common-actions';

const defaultWebColorsState = {
    lastIndex: -1,
    assignedColors: {}
};

export const webColorsSlice = createSlice({
    name: 'webColors',
    initialState: defaultWebColorsState,
    reducers: {
        setWebColorsLastIndex: (state, action) => {
            const isInteger = _.isInteger(action.payload);
            if (isInteger) {
                state.lastIndex = action.payload;
            }
            return state;
        },
        setWebColors: (state, action) => {
            if (!action.payload) return;
            if (!state) {
                state = {};
            }

            if (
                _.has(action.payload, 'id') &&
                _.has(action.payload, 'colors.color') &&
                _.has(action.payload, 'colors.backgroundColor')
            ) {
                const { id, type, colors } = action.payload;
                const assignedColors = type ? { ...colors, type } : colors;
                state.assignedColors[id] = assignedColors;
            }
            return state;
        },
        resetRouteWebColors: (state) => {
            const newState = { ...state };
            const asArray = Object.entries(newState.assignedColors);
            const filtered = asArray.filter((item) => {
                return item[1].type === 'driver';
            });
            const lastIndex = filtered.length - 1;
            const assignedColors = Object.fromEntries(filtered);

            return { ...state, lastIndex, assignedColors };
        },
        resetWebColors: () => {
            return defaultWebColorsState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultWebColorsState;
        });
    }
});

export const {
    setWebColorsLastIndex,
    setWebColors,
    resetRouteWebColors,
    resetWebColors
} = webColorsSlice.actions;

export const selectWebColors = (state) => state.webColors.assignedColors;

export default webColorsSlice.reducer;
