import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { usePlanMapDisplayContext } from '~/components/MapPage/PlanMap/PlanMapDisplayContext';
import { LiveStop } from '~/data-classes';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import constants from '~/utils/constants';
import { TimeWindow } from '~/api/types';
import { makeMarkerPopup } from '~/utils/map/popupMaker/makeMarkerPopup';
import taskUtils from '~/utils/task-utils';
import dateUtilsConverters from '~/utils/date-utils-converters';
import { useFormatTimeToClientTimeZone } from '~/hooks';

/**
 * Creates a `MarkerPopup` component for completed/live stops
 * @param {LiveStop} stopData - The completed stop data
 * @returns {Object} - Object containing the `makeCompletedStopPopup` function
 * @property {Function} makeCompletedStopPopup - Function to create a `MarkerPopup` component for completed/live stops
 */
export function useCompletedStopPopup() {
    const { t } = useTranslation(['translation', 'common', 'markerPopup']);
    const dispatch = useDispatch();

    const { formatTimeToClientTimeZone } = useFormatTimeToClientTimeZone();
    const { setMarkerPopup } = usePlanMapDisplayContext();
    /**
     * Event handler for the popup events
     * @param {Object} event - The event object
     * @param {string} event.event - The type of event
     */
    const popupEventHandler = ({ event = '' }: { event?: string }) => {
        const { mapChildEvents } = constants;
        switch (event) {
            case mapChildEvents.POPUP_MOUSELEAVE:
                setMarkerPopup(null);
                break;
            default:
                break;
        }
    };

    /**
     * Click event handler for the stop detail button
     */
    const onClickStopDetailButton = (taskId: string) => {
        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(taskId));
    };

    const formatTimeWindows = (timeWindows: TimeWindow[]) => {
        return (timeWindows || []).map(({ start, end }) => {
            const startTime = formatTimeToClientTimeZone({
                time: start,
                format: constants.TIME_FORMAT_12H
            });
            const endTime = formatTimeToClientTimeZone({
                time: end,
                format: constants.TIME_FORMAT_12H
            });
            return `${startTime} - ${endTime}`;
        });
    };
    /**
     * Creates the payload for the MarkerPopup component
     * @returns {Object} - The payload object
     * @property {string} className - The CSS class name for the popup
     * @property {string} testId - The test ID for the popup
     * @property {Object} location - The location of the stop
     * @property {string} stopName - The name of the stop
     * @property {string} etaTime - The ETA time for the stop
     * @property {string[]} labels - The labels for the stop
     * @property {string} timeWindows - The time windows for the stop
     * @property {string} serviceTime - The service time for the stop
     * @property {string} routeId - The route ID for the stop
     * @property {string} volumeCapacityUsed - The volume capacity used for the stop
     * @property {string} stopDetailButtonText - The text for the stop detail button
     * @property {Function} onClickStopDetailButton - The click event handler for the stop detail button
     * @property {string} eta - The expected arrival time for the stop
     * @property {boolean} isServiceTimeML - Indicates if the stop has machine learning service time
     * @property {boolean} isCompleted - Indicates if the stop is completed
     * @property {boolean} isPickup - Indicates if the stop is a pickup
     * @property {boolean} isOnTime - Indicates if the stop is on time
     * @property {boolean} isLate - Indicates if the stop is late
     * @property {ApiInventoryItem[]} inventory - the stop inventory list
     * @property {number} numInventoryItems - the stop number of inventory items
     */
    const createPopupPayload = (stopData: LiveStop) => {
        const {
            arrivalTime,
            startServiceAt,
            location,
            stopName,
            labels,
            taskId,
            isCanceled,
            isCompleted,
            rawEta,
            isServiceTimeML,
            isPickup,
            isOnTime,
            isLate,
            serviceTime,
            size: volumeCapacityUsed,
            euid,
            rawTimeWindow,
            inventory,
            numInventoryItems,
            serviceDuration
        } = stopData;

        const routeId = taskUtils.getEuid(euid);

        const etaTime = arrivalTime
            ? t('common:time.estimatedTime', {
                  time: formatTimeToClientTimeZone({ time: arrivalTime })
              })
            : t('common:N/A');

        const actualServiceTime =
            dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
                serviceDuration || 0
            ).minutes;
        const expectedArrivalTime = formatTimeToClientTimeZone({
            time: rawEta,
            format: constants.TIME_FORMAT_12H
        });

        const localizedActualArrivalTime = formatTimeToClientTimeZone({
            time: startServiceAt
        });

        const isUseActualArrivalTime = isCanceled || isCompleted;
        return {
            className: 'completed-stop-popup',
            testId: 'completed-stop-popup',
            location,
            stopData: {
                stopName,
                etaTime: isUseActualArrivalTime
                    ? localizedActualArrivalTime
                    : etaTime,
                labels,
                timeWindows: formatTimeWindows(rawTimeWindow),
                serviceTime: `${taskUtils.formatServiceTime(serviceTime)} ${t(
                    'common:time.minutes'
                )}`,
                actualServiceTime: `${actualServiceTime} ${t(
                    'common:time.minutes'
                )}`,
                routeId,
                volumeCapacityUsed,
                stopDetailButtonText: t('markerPopup:button.moreDetail'),
                onClickStopDetailButton: () => onClickStopDetailButton(taskId),
                eta: expectedArrivalTime,
                isServiceTimeML,
                MLServiceTimeChipText: t('markerPopup:machineLearned'),
                isCompleted,
                isPickup,
                isOnTime,
                isLate,
                inventory,
                numInventoryItems
            }
        };
    };

    /**
     * Creates a `MarkerPopup` component for completed/live stops
     * @returns {React.Component} - The `MarkerPopup` component
     */
    const makeCompletedStopPopup = (
        stopData: LiveStop,
        popupHandler = popupEventHandler
    ) => {
        const popupPayload = createPopupPayload(stopData);
        return makeMarkerPopup(popupPayload, popupHandler);
    };

    return {
        makeCompletedStopPopup,
        onClickStopDetailButton,
        popupEventHandler
    };
}
