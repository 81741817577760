import { validatePermissions } from '~/utils/validatePermissions';
import constants from '~/utils/constants';

export function getUserRoleForFeature(associatedUserGroups, featureName) {
    const isEditor = validatePermissions(
        associatedUserGroups,
        featureName,
        constants.userPermissionRoles.EDITOR
    );

    const isViewer = validatePermissions(
        associatedUserGroups,
        featureName,
        constants.userPermissionRoles.VIEWER
    );
    if (isEditor) {
        return constants.userPermissionRoles.EDITOR;
    }
    if (isViewer) {
        return constants.userPermissionRoles.VIEWER;
    }
    return constants.userPermissionRoles.NO_ACCESS;
}
