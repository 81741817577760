import { store } from '~/store';
import { EditRouteHistoryApi } from '~/api/EditRouteHistoryApi';
import { setEditHistoryByClient } from '~/reducers/editHistoryByClientSlice';
import { setEditHistoryMetrics } from '~/reducers/editHistoryMetricsSlice';

async function addMetricsToStore(clientId, schedulerTaskId) {
    if (!clientId) {
        return Promise.reject('missing clientId');
    }
    if (!schedulerTaskId) {
        return Promise.reject('missing schedulerTaskId');
    }
    try {
        const editHistory = await EditRouteHistoryApi.getEditHistory(
            clientId,
            schedulerTaskId
        );
        store.dispatch(
            setEditHistoryByClient({
                clientId,
                data: editHistory.data.data
            })
        );

        const editHistoryMetrics = await EditRouteHistoryApi.getMetrics(
            clientId,
            schedulerTaskId
        );
        const {
            data: { data }
        } = editHistoryMetrics;
        store.dispatch(setEditHistoryMetrics(data));
    } catch (e) {
        console.warn(e);
    }
}

export const planEditMetricsService = {
    addMetricsToStore
};
