import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import { IconButton, UIElement, theme } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize, IconSizes } from '~/ui/components/Icon/IconSizes';

import './RouteCardMarkerWithHollow.scss';

interface RouteCardMarkerWithHollowProps extends UIElement {
    disabled?: boolean;
    text?: string;
    textColor?: string;
    textStyle?: CSSProperties;
    icon?: IconName;
    iconSize?: IconSize;
    iconColor?: string;
    iconStyle?: CSSProperties;
    onClick?: () => void;
    onMouseEnter?: () => void;
}

const RouteCardMarkerWithHollow = ({
    disabled,
    className,
    text,
    icon,
    textColor = theme.colors.black,
    iconSize = IconSizes.M,
    textStyle,
    iconColor,
    iconStyle,
    onClick,
    onMouseEnter,
    ...extra
}: RouteCardMarkerWithHollowProps) => {
    function getClassName() {
        return classNames('route-card-marker-with-hollow', className);
    }

    return (
        <div
            style={iconStyle}
            className={getClassName()}
            data-testid={
                extra['data-testid'] || 'route-card-marker-with-hollow'
            }
        >
            <IconButton
                onMouseEnter={onMouseEnter}
                onClick={onClick}
                className="select-button"
                disabled={disabled}
                text={text}
                textColor={textColor}
                textStyle={textStyle}
                icon={icon}
                iconSize={iconSize}
                iconColor={iconColor}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-select-button`
                        : 'route-card-select-button'
                }
            />
        </div>
    );
};

export default RouteCardMarkerWithHollow;
