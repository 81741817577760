import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import { resetOnLogout } from '~/reducers/common-actions';

export const getAppGlobalsInitialState = () => ({
    isAppInitialized: false,
    isMarkerPopupInventoryItemsDetailsExpanded: true
});

export const appGlobalsSlice = createSlice({
    name: 'appGlobals',
    initialState: getAppGlobalsInitialState(),
    reducers: {
        setAppInitialized: (state, action) => {
            state.isAppInitialized = action.payload;
            return state;
        },
        setIsMarkerPopupInventoryItemsDetailsExpanded: (state, action) => {
            state.isMarkerPopupInventoryItemsDetailsExpanded = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => getAppGlobalsInitialState());
    }
});

export const {
    setAppInitialized,
    setIsMarkerPopupInventoryItemsDetailsExpanded
} = appGlobalsSlice.actions;

export const selectAppInitialized = (state: RootState) =>
    state.appGlobals.isAppInitialized;

export const selectIsMarkerPopupInventoryItemsDetailsExpanded = (
    state: RootState
) => state.appGlobals.isMarkerPopupInventoryItemsDetailsExpanded;

export default appGlobalsSlice.reducer;
