import axios from 'axios';
import {
    ApiAnalyticsAggregateDriversMetrics,
    ApiAnalyticsAggregateDriversDownload,
    AxiosApiResponse
} from './types';
import constants from '~/utils/constants';

/**
 * Default maximum number of downloadable reports to retrieve from AnalyticsApi.getAggregateDriversDownloads()
 */
const REPORTS_API_LIMIT = 200;

/**
 * Whether to use legacy reporting
 */
const USE_LEGACY_REPORTING = false;

/**
 * Parameters for AnalyticsApi.getAggregateDriversMetricsParams()
 */
interface GetAggregateDriversMetricsParams {
    /**
     * Whether to use legacy reporting
     */
    legacy?: boolean;
}

/**
 * Parameters for AnalyticsApi.getAggregateDriversDownloads()
 */
interface GetAggregateDriversDownloadsParams
    extends GetAggregateDriversMetricsParams {
    /**
     * The max number of items to retrieve
     */
    limit?: number;
    clientId?: string;
}

/**
 * Implementations of API methods under the /analytics path
 *
 * @category API
 */
export default class AnalyticsApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/analytics';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'analytics';

    /**
     * Retrieve all drivers analytics metrics
     *
     * @param {GetAggregateDriversMetricsParams} params - additional query parameters for this API
     * @returns Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversMetrics>>
     */
    static getAggregateDriversMetrics(
        params: GetAggregateDriversMetricsParams = {
            legacy: USE_LEGACY_REPORTING
        }
    ): Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversMetrics>> {
        const options = {
            params
        };
        return axios.get(`${this.path}/drivers`, options);
    }

    /**
     * Retrieve all hosted downloadable reports from AWS
     *
     * @param {GetAggregateDriversDownloadsParams} params - additional query parameters for this API
     * @returns Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversDownload[]>>
     */
    static getAggregateDriversDownloads(
        params: GetAggregateDriversDownloadsParams = {
            limit: REPORTS_API_LIMIT,
            legacy: USE_LEGACY_REPORTING
        }
    ): Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversDownload[]>> {
        const { clientId = '' } = params;
        const options = {
            params: {
                limit: REPORTS_API_LIMIT,
                legacy: USE_LEGACY_REPORTING,
                ...Object.fromEntries(
                    Object.entries(params).filter(([key]) => key !== 'clientId')
                ),
                action: 'download'
            },
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        };
        return axios.get(`${this.path}/drivers`, options);
    }
}
