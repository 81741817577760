import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteCardMetrics } from '~/ui/components/RouteCard/RouteCardMetric/types';
import constants from '~/utils/constants';
import { RouteCardMetric } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';

import './TripCardSummary.scss';

interface TripCardSummaryProps {
    className?: string;
    metrics: RouteCardMetrics[];
    'data-testid'?: string;
}

interface GeneralTripMetrics {
    stops: boolean;
    distance: boolean;
    duration: boolean;
}

interface TripPickupMetrics {
    pickup_weight: boolean;
    pickup_volume: boolean;
}

interface TripDeliveryMetrics {
    delivery_weight: boolean;
    delivery_volume: boolean;
}

interface TripTwoPartTaskMetrics {
    two_part_task_volume: boolean;
    two_part_task_weight: boolean;
}

export const TripCardSummary = ({
    className,
    metrics,
    ...extra
}: TripCardSummaryProps) => {
    const { t } = useTranslation(['common']);
    const rootClassName = 'trip-card-summary';
    const containerClassName = `${rootClassName} ${className}`;

    const generalMetrics: GeneralTripMetrics = {
        [constants.routeSummaryMetrics.STOPS]: true,
        [constants.routeSummaryMetrics.DISTANCE]: true,
        [constants.routeSummaryMetrics.DURATION]: true
    };

    const deliveryMetrics: TripDeliveryMetrics = {
        [constants.tripSpecificSummaryMetrics.DELIVERY_VOLUME]: true,
        [constants.tripSpecificSummaryMetrics.DELIVERY_WEIGHT]: true
    };

    const pickupMetrics: TripPickupMetrics = {
        [constants.tripSpecificSummaryMetrics.PICKUP_VOLUME]: true,
        [constants.tripSpecificSummaryMetrics.PICKUP_WEIGHT]: true
    };

    const twoPartTaskMetrics: TripTwoPartTaskMetrics = {
        [constants.tripSpecificSummaryMetrics.TWO_PART_TASK_VOLUME]: true,
        [constants.tripSpecificSummaryMetrics.TWO_PART_TASK_WEIGHT]: true
    };

    const generalSummaryMetrics = metrics.filter(({ metric }) => {
        return Boolean(generalMetrics[metric as keyof GeneralTripMetrics]);
    });

    const tripDeliveryMetrics = metrics.filter(({ metric }) => {
        return Boolean(deliveryMetrics[metric as keyof TripDeliveryMetrics]);
    });

    const tripPickupMetrics = metrics.filter(({ metric }) => {
        return Boolean(pickupMetrics[metric as keyof TripPickupMetrics]);
    });

    const tripTwoPartTaskMetrics = metrics.filter(({ metric }) => {
        return Boolean(
            twoPartTaskMetrics[metric as keyof TripTwoPartTaskMetrics]
        );
    });

    const hasTwoPartTaskMetrics = Boolean(tripTwoPartTaskMetrics.length);

    return (
        <div
            className={containerClassName}
            data-testid="trip-card-summary-container"
        >
            <div
                className={`${rootClassName}__general-metrics ${rootClassName}__metric-section _d-flex _fd-row`}
                data-testid="general-metrics"
            >
                {generalSummaryMetrics.map((item) => {
                    const {
                        metric,
                        value,
                        maxValue,
                        icon,
                        iconSize,
                        iconColor,
                        icons,
                        tooltipText,
                        isOverCapacity,
                        valuesWithUnits,
                        className: itemClassName
                    } = item;
                    return (
                        <RouteCardMetric
                            key={metric}
                            className={itemClassName}
                            metric={metric}
                            value={value}
                            maxValue={maxValue}
                            icon={icon as IconName}
                            iconSize={iconSize}
                            iconColor={iconColor}
                            icons={icons}
                            tooltipText={tooltipText}
                            isOverCapacity={isOverCapacity}
                            valuesWithUnits={valuesWithUnits}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-metric`
                                    : 'routecard-summary-metric'
                            }
                        />
                    );
                })}
            </div>
            <div
                className={`${rootClassName}__delivery-metrics ${rootClassName}__metric-section _d-flex _fd-row`}
                data-testid="delivery-metrics"
            >
                <div className={`${rootClassName}__metric-icon`}>
                    {t('common:unassignedTaskIcons.delivery')}
                </div>
                {tripDeliveryMetrics.map((item) => {
                    const {
                        metric,
                        value,
                        maxValue,
                        icon,
                        iconSize,
                        iconColor,
                        icons,
                        tooltipText,
                        isOverCapacity,
                        valuesWithUnits,
                        className: itemClassName
                    } = item;
                    return (
                        <RouteCardMetric
                            key={metric}
                            className={itemClassName}
                            metric={metric}
                            value={value}
                            maxValue={maxValue}
                            showMaxValue
                            icon={icon as IconName}
                            iconSize={iconSize}
                            iconColor={iconColor}
                            icons={icons}
                            tooltipText={tooltipText}
                            isOverCapacity={isOverCapacity}
                            valuesWithUnits={valuesWithUnits}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-metric`
                                    : 'routecard-summary-metric'
                            }
                        />
                    );
                })}
            </div>
            <div
                className={`${rootClassName}__pickup-metrics ${rootClassName}__metric-section _d-flex _fd-row`}
                data-testid="pickup-metrics"
            >
                <div className={`${rootClassName}__metric-icon`}>
                    {t('common:unassignedTaskIcons.pickup')}
                </div>
                {tripPickupMetrics.map((item) => {
                    const {
                        metric,
                        value,
                        maxValue,
                        icon,
                        iconSize,
                        iconColor,
                        icons,
                        tooltipText,
                        isOverCapacity,
                        valuesWithUnits,
                        className: itemClassName
                    } = item;
                    return (
                        <RouteCardMetric
                            key={metric}
                            className={itemClassName}
                            metric={metric}
                            value={value}
                            maxValue={maxValue}
                            showMaxValue
                            icon={icon as IconName}
                            iconSize={iconSize}
                            iconColor={iconColor}
                            icons={icons}
                            tooltipText={tooltipText}
                            isOverCapacity={isOverCapacity}
                            valuesWithUnits={valuesWithUnits}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-metric`
                                    : 'routecard-summary-metric'
                            }
                        />
                    );
                })}
            </div>
            {hasTwoPartTaskMetrics && (
                <div
                    className={`${rootClassName}__two-part-task-metrics ${rootClassName}__metric-section _fd-row`}
                    data-testid="two-part-task-metrics"
                >
                    <div className={`${rootClassName}__metric-icon`}>
                        {t('common:unassignedTaskIcons.twoPart')}
                    </div>
                    {tripTwoPartTaskMetrics.map((item) => {
                        const {
                            metric,
                            value,
                            maxValue,
                            icon,
                            iconSize,
                            iconColor,
                            icons,
                            tooltipText,
                            isOverCapacity,
                            valuesWithUnits,
                            className: itemClassName
                        } = item;
                        return (
                            <RouteCardMetric
                                key={metric}
                                className={itemClassName}
                                metric={metric}
                                value={value}
                                maxValue={maxValue}
                                showMaxValue
                                icon={icon as IconName}
                                iconSize={iconSize}
                                iconColor={iconColor}
                                icons={icons}
                                tooltipText={tooltipText}
                                isOverCapacity={isOverCapacity}
                                valuesWithUnits={valuesWithUnits}
                                data-testid={
                                    extra['data-testid']
                                        ? `${extra['data-testid']}-metric`
                                        : 'routecard-summary-metric'
                                }
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
