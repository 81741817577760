import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as resources from '~/locales';

const fallbackLng = 'en';

const options = {
    order: ['navigator'],
    excludeCacheFor: ['cimode']
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        supportedLngs: Object.keys(resources),
        load: 'languageOnly',
        detection: options,
        ns: Object.keys(resources[fallbackLng]),
        defaultNS: 'translation',
        keySeparator: '.',
        backend: {
            loadPath: './locales/{{lng}}/{{ns}}.json'
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            useSuspense: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed'
        },
        debug: false
    });

export default i18n;
