import { cloneDeep } from 'lodash';
import { ChangeEvent } from 'react';
import inputFieldUtils from '~/utils/input-field-utils';
import { ShiftData } from '../types';

interface UseLongShiftBlockProps {
    /**
     * The shift data
     */
    data: ShiftData;
    /**
     * The shift id
     */
    id: string;
    /**
     * The callback that handles shift data update
     */
    onChange: (id: string, shiftData: ShiftData) => void;
}

export const useLongShiftBlock = ({
    data,
    id,
    onChange
}: UseLongShiftBlockProps) => {
    const { shift } = data;

    const handleWeekdaysChange = (days: number[]) => {
        const newData = cloneDeep(data);
        onChange(id, { ...newData, days });
    };

    const handleStartTimeChange = (value: string) => {
        const newData = cloneDeep(data);
        const [editedShift] = shift;
        const newShift = [{ ...editedShift, start: value }];
        onChange(id, { ...newData, shift: newShift });
    };

    const isValidNumber = (value: string) => {
        return value && /^\d+$/.test(value);
    };

    const handleDurationUpdate = (value: string, fieldKey: string) => {
        const fieldValue = inputFieldUtils.trimLeadingZeroCharacters(value);
        const newData = cloneDeep(data);
        const [editedShift] = shift;

        if (fieldValue && !isValidNumber(fieldValue)) return;

        const newShift = [{ ...editedShift, [fieldKey]: fieldValue }];
        onChange(id, { ...newData, shift: newShift });
    };

    const handleMinutesChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleDurationUpdate(e.target.value, 'minutes');
    };

    const handleHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleDurationUpdate(e.target.value, 'hours');
    };

    return {
        handleHoursChange,
        handleMinutesChange,
        handleStartTimeChange,
        handleWeekdaysChange
    };
};
