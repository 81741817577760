import { idUtils } from '~/utils/id-utils';

export const getStopIds = (clientRouteTaskId: string) => {
    if (!clientRouteTaskId) return [];

    if (!idUtils.isTwoPartTaskStopId(clientRouteTaskId))
        return [clientRouteTaskId];

    return idUtils.getTwoPartTaskIds(clientRouteTaskId);
};
