import React from 'react';
import classnames from 'classnames';

import { Icon } from '~/ui';

import './ColorItem.scss';

interface ColorItemProps {
    /**
     * hex value
     */
    color: string;
    /**
     * Indicates if color is selected
     */
    isSelected?: boolean;
}

export const ColorItem = ({ color, isSelected }: ColorItemProps) => {
    const rootClassName = 'color-item';
    const classNames = classnames(
        rootClassName,
        '_d-flex _jc-center _ai-center',
        {
            [`${rootClassName}--selected`]: isSelected
        }
    );

    const outerStyles = isSelected
        ? { backgroundColor: 'white' }
        : { backgroundColor: color };

    return (
        <div
            className={classNames}
            data-testid="color-item"
            style={outerStyles}
        >
            <div
                className={`${rootClassName}__inner-circle _d-flex _jc-center _ai-center`}
                style={{ backgroundColor: color }}
            >
                {isSelected && <Icon icon="checkmark" color="black" />}
            </div>
        </div>
    );
};
