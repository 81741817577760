import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useRowSelect
} from 'react-table';

import PT from 'prop-types';

import { Button, Icon } from '~/ui';
import { TableToolbar } from './TableToolbar';
import { useTableRows } from './useTableRows';

import './Table.scss';

/**
 * The root class name that represents a `block` in BEM naming convention
 *
 * All base classnames for `elements` and `modifiers` will be derived from this
 *
 * This will also be used for the default `data-testid` for the component.
 */
const ROOT_CLASS_NAME = 'table';

function Table({
    columns,
    tableData,
    noDataComponent,
    globalFilterFn,
    stickyTopOffset = 0,
    searchBarPlaceholder,
    renderTableRow,
    getRowProps,
    editEnabled,
    footer,
    className,
    initialPageSize = 10,
    isFooterDisabled = false,
    disableToolbar = false,
    disableStickyHeader = false,
    toolbarCustomComponent,
    toolbarFilter,
    sortTypes = undefined,
    onFilteredRowsUpdated = undefined,
    'data-testid': dataTestId = ROOT_CLASS_NAME
}) {
    const { t } = useTranslation(['driverBooking', 'common']);
    const theadRef = useRef(null);

    const initialState = { pageSize: initialPageSize };
    const tableOptions = {
        columns,
        sortTypes,
        data: tableData,
        initialState,
        globalFilter: globalFilterFn,
        footer
    };

    /**
     * useTable hook from react-tables v7
     *
     * docs: https://github.com/TanStack/table/tree/v7/docs/src/pages/docs
     *
     * @todo upgrade to v8
     */
    const tableInstance = useTable(
        tableOptions,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canNextPage,
        setPageSize,
        state: { pageSize, globalFilter: searchText },
        setGlobalFilter: setSearchText
    } = tableInstance;

    const { pageRows } = useTableRows({
        getRowProps,
        onFilteredRowsUpdated,
        tableInstance
    });

    const elementClassName = classNames('_fd-column', className);
    const wrapperClassName = classNames('table-wrapper _fd-column', {
        [`${className}__wrapper`]: className
    });
    const tableClassName = classNames('table', {
        [`${className}__table`]: className
    });
    const theadClassName = classNames('_header-4-alt table-header', {
        [`${className}__table-header`]: className,
        'table-header_sticky': disableStickyHeader
    });
    const tbodyClassName = classNames('table-body', {
        [`${className}__table-body`]: className
    });
    const footerWrapperClassName = classNames(
        '_d-flex _jc-center _ai-center _mt-5 table-footer-wrapper',
        {
            [`${className}__table-footer-wrapper`]: className
        }
    );
    const footerClassName = classNames('_fd-column _ai-center table-footer', {
        [`${className}__table-footer`]: className
    });

    const setSearchTextDebounced = useAsyncDebounce((value) => {
        setSearchText(value);
    }, 200);

    function tableHeaderStyle(customOffset) {
        // headers borders disappear when scrolling, need 0.1rem offset to cover the space
        const toolbarOffset = disableToolbar
            ? '-0.1rem'
            : 'var(--dimension-table-toolbar-height) - 0.1rem';

        const offset = `${customOffset ?? 0}rem`;

        return {
            top: `calc(${offset} + ${toolbarOffset})`
        };
    }

    const TableRow = renderTableRow;

    const renderSortArrow = (column) => {
        if (!column.canSort) return;

        const styles = {
            color: column.isSorted ? 'neptune-800' : 'galaxy-400'
        };
        if (!column.isSortedDesc) {
            styles.transform = 'rotate(180deg)';
        }

        return <Icon icon="arrowDown" sx={styles} />;
    };

    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <div className={elementClassName} data-testid={dataTestId}>
            {!disableToolbar && (
                <TableToolbar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setSearchTextDebounced={setSearchTextDebounced}
                    searchBarPlaceholder={searchBarPlaceholder}
                    stickyTopOffset={stickyTopOffset}
                    disableStickyHeader={disableStickyHeader}
                    customComponent={toolbarCustomComponent}
                    filter={toolbarFilter}
                />
            )}
            <div className={wrapperClassName}>
                <table
                    {...getTableProps()}
                    className={tableClassName}
                    data-testid={`${dataTestId}__table`}
                >
                    <thead
                        className={theadClassName}
                        style={tableHeaderStyle(stickyTopOffset)}
                        ref={theadRef}
                        data-testid={`${dataTestId}__thead`}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    const {
                                        isSorted,
                                        sx,
                                        getHeaderProps,
                                        getSortByToggleProps,
                                        render
                                    } = column;
                                    const colHeaderClassName = classNames(
                                        '_d-flex _ai-center table-column-header',
                                        {
                                            'table-column-header_sorted':
                                                isSorted
                                        }
                                    );
                                    const headerProps = getHeaderProps(
                                        getSortByToggleProps({
                                            style: sx
                                        })
                                    );
                                    return (
                                        <th
                                            className="table-header-cell"
                                            {...headerProps}
                                        >
                                            <div className={colHeaderClassName}>
                                                {render('Header')}
                                                {renderSortArrow(column)}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        {...getTableBodyProps()}
                        className={tbodyClassName}
                        data-testid={`${dataTestId}__tbody`}
                    >
                        {pageRows.map((row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    rowProps={row.props}
                                    displayedData={row.displayedData}
                                    searchText={searchText}
                                    editEnabled={editEnabled}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {noDataComponent && page.length <= 0 && noDataComponent()}

            {!isFooterDisabled && (
                <div className={footerWrapperClassName} style={footer.sx}>
                    <div
                        className={footerClassName}
                        data-testid={`${dataTestId}__footer`}
                    >
                        <Button
                            variant="label"
                            type="compact"
                            disabled={!canNextPage}
                            onClick={() => setPageSize(pageSize + 10)}
                        >
                            {t('seeMoreLabel')}
                        </Button>
                        <span>
                            {t('showingXOfYLabel', {
                                x: page.length,
                                y: tableData.length
                            })}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

Table.propTypes = {
    /** table columns */
    columns: PT.arrayOf(PT.object).isRequired,
    /** table data */
    tableData: PT.arrayOf(PT.object).isRequired,
    /** component rendered when no data in the table */
    noDataComponent: PT.func,
    /** filter function for search */
    globalFilterFn: PT.func,
    /** top offset (in rem) for table header when scrolling */
    stickyTopOffset: PT.number,
    /** search bar placeholder text */
    searchBarPlaceholder: PT.string,
    /** table row component */
    renderTableRow: PT.func,
    /** passes props for table row */
    getRowProps: PT.func,
    /** enable mutable row data */
    editEnabled: PT.bool,
    /** footer details * */
    footer: PT.objectOf(PT.shape({ sx: PT.shape({ display: PT.string }) })),
    /** table className */
    className: PT.string,
    /** disable sticky header */
    disableStickyHeader: PT.bool,
    /** hides toolbar with search and filters */
    disableToolbar: PT.bool,
    /** custom component displayed in toolbar */
    toolbarCustomComponent: PT.node,
    /** custom table filter component */
    toolbarFilter: PT.func,
    /** callback for receiving the list of filtered rows * */
    onFilteredRowsUpdated: PT.func
};

Table.defaultProps = {
    footer: {}
};

export default Table;
