import classNames from 'classnames';
import React from 'react';
import { IconButton, UIElement } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

interface EditableTextActionsProps extends UIElement {
    /**
     * The editable field error message
     */
    error?: string | null;
    /**
     * Whether to disable the action buttons
     */
    isDisableButtons?: boolean;
    /**
     * The callback function when the `cancel` button is clicked
     */
    onClickCancel: () => void;
    /**
     * The callback function when the `check` button is clicked
     */
    onClickCheck: () => void;
}

const ROOT_CLASS_NAME = 'editabletext__actions';

export const EditableTextActions = ({
    error,
    isDisableButtons,
    onClickCancel,
    onClickCheck,
    className,
    'data-testid': dataTestId
}: EditableTextActionsProps) => {
    const rootClassName = classNames(ROOT_CLASS_NAME, '_d-flex', className, {
        '_jc-space-between': error,
        '_jc-flex-end': !error
    });
    const iconButtonClassName = 'editabletext__button';

    const rootDataTestId = dataTestId || ROOT_CLASS_NAME;
    const errorDataTestId = dataTestId
        ? `${dataTestId}-error`
        : `${ROOT_CLASS_NAME}-error`;

    return (
        <div className={rootClassName} data-testid={rootDataTestId}>
            {error && (
                <div
                    className="editabletext__error"
                    data-testid={errorDataTestId}
                >
                    {error}
                </div>
            )}
            <div className="editabletext__buttons _d-flex _jc-flex-end">
                <div className="_d-inline">
                    <IconButton
                        className={iconButtonClassName}
                        icon="cancel"
                        iconSize={IconSizes.S}
                        iconColor="meteor"
                        onClick={onClickCancel}
                        disabled={isDisableButtons}
                    />
                </div>
                <div className="_d-inline">
                    <IconButton
                        className={iconButtonClassName}
                        icon="checkLine"
                        iconSize={IconSizes.S}
                        onClick={onClickCheck}
                        disabled={isDisableButtons}
                    />
                </div>
            </div>
        </div>
    );
};
