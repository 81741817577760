const checkIfCoordinatesMatch = (a, b) => {
    return a.lat === b.lat && a.lng === b.lng;
};

const isDepotsWithTheSameCoordinates = (firstStop, secondStop) => {
    return checkIfCoordinatesMatch(
        firstStop.markerCoordinates || firstStop.location,
        secondStop.markerCoordinates || secondStop.location
    );
};

const checkIsDepotUnique = (depots, stop) => {
    return !depots.some((depot) => isDepotsWithTheSameCoordinates(depot, stop));
};

// https://tools.ietf.org/html/rfc7946#section-3.2
const getGeoJSONFeaturePoint = (lat, lng, properties = {}) => {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'Point',
            coordinates: [lng, lat]
        }
    };
};

export const markerUtils = {
    checkIfCoordinatesMatch,
    checkIsDepotUnique,
    getGeoJSONFeaturePoint,
    isDepotsWithTheSameCoordinates
};
