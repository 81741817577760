export const getExternalTaskType = (
    pickupExternalTaskType = '',
    deliveryExternalTaskType = ''
) => {
    const pickupSplit = pickupExternalTaskType
        ? pickupExternalTaskType.split(' - ')[0]
        : '';

    const deliverySplit = deliveryExternalTaskType.split(' - ');
    let deliveryValue = '';
    if (deliverySplit.length) {
        if (deliverySplit.length === 1) {
            [deliveryValue] = deliverySplit;
        }
        if (deliverySplit.length === 2) {
            [, deliveryValue] = deliverySplit;
        }
    }

    return pickupSplit && deliveryValue
        ? `${pickupSplit} - ${deliveryValue}`
        : pickupSplit || deliveryValue || '';
};
