import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const processIndicatorSlice = createSlice({
    name: 'processIndicator',
    initialState: {},
    reducers: {
        addProcessIndicator: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { addProcessIndicator } = processIndicatorSlice.actions;
export const selectProcessIndicator = (state) => state.processIndicator;
export default processIndicatorSlice.reducer;
