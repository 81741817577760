import { MakeEquipmentMarkerEffects } from './types';
import makeEquipmentMarkerComponents from '~/utils/map-modes/live-routes-equipment-markers';

export const makeEquipmentMarkerEffects = ({
    isClusteringStops,
    equipmentSuperClusters,
    getClusters,
    makeEquipmentClusterMarkers,
    equipment
}: MakeEquipmentMarkerEffects) => {
    if (isClusteringStops) {
        return equipmentSuperClusters.map((equipmentSuperCluster, index) => {
            const geoJSONFeatures = getClusters(equipmentSuperCluster);

            return makeEquipmentClusterMarkers({
                superCluster: equipmentSuperCluster,
                geoJSONFeatures,
                superClusterIndex: index
            });
        });
    }

    return makeEquipmentMarkerComponents(equipment);
};
