import React, { useMemo } from 'react';
import { uniq } from 'lodash';
import { useStopMarkerLabelsTooltip } from './useStopMarkerLabelsTooltip';
import { getFormattedLabels } from './utils';
import './StopMarkerNameAndLabels.scss';

interface StopMarkerNameAndLabelsProps {
    name?: string;
    labels?: string[];
}

export const StopMarkerNameAndLabels = ({
    name,
    labels
}: StopMarkerNameAndLabelsProps) => {
    const rootClassName = 'stop-marker-name-and-labels';
    const uniqueLabels = useMemo(() => uniq(labels), [labels]);
    const formattedLabels = useMemo(
        () => getFormattedLabels(uniqueLabels),
        [uniqueLabels]
    );
    const { labelsTooltip, setTriggerRef } = useStopMarkerLabelsTooltip({
        labels: uniqueLabels
    });

    const isShowLabels = formattedLabels.length > 0;
    const lastFormattedLabelIndex = formattedLabels.length - 1;

    if (!name && !isShowLabels) return null;

    const renderLabel = (label: string, index: number) => (
        <span key={label} className={`${rootClassName}__label`}>
            {index === lastFormattedLabelIndex ? label : `${label},`}
        </span>
    );

    return (
        <div
            className={`${rootClassName} _d-flex _ai-center _jc-center`}
            data-testid={rootClassName}
        >
            {labelsTooltip}
            <span className={`${rootClassName}__name`}>{name}</span>
            {isShowLabels && (
                <div
                    ref={setTriggerRef}
                    className={`${rootClassName}__labels _d-flex _ai-center`}
                >
                    {formattedLabels.map(renderLabel)}
                </div>
            )}
        </div>
    );
};
