import { useAddTaskModalContext } from '../../AddTaskModalContext';

type Props = {
    invoiceId?: string;
};
export const useDeleteClickHandler = ({ invoiceId }: Props) => {
    const {
        clientTask: { invoices = [] },
        onInvoicesChange
    } = useAddTaskModalContext();

    return () => {
        const index = invoices.findIndex(
            ({ frontendOnlyId }) => frontendOnlyId === invoiceId
        );

        if (index === -1) return;

        const newInvoices = [...invoices];
        newInvoices.splice(index, 1);
        onInvoicesChange(newInvoices);
    };
};
