import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { isEqual } from 'lodash';
import { AddressTaskTypes, TaskTypes } from '~/api/types';
import {
    selectIsOpenAddTaskModal,
    selectIsSaveAndAssignTaskMode,
    selectSelectedEquipment,
    selectSelectedTaskType,
    setIsAssignNewTaskToDispatchedDriver,
    setIsOpenAddTaskModal,
    setIsSaveAndAssignTaskMode,
    setSelectedEquipment,
    setSelectedTabIndex,
    setSelectedTaskType
} from '~/reducers/addTaskSlice';
import { clearCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';
import { selectEquipmentById } from '~/reducers/equipmentSlice';
import { resetGapRoute } from '~/reducers/gapRouteSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import {
    selectIsEquipmentDepotPairing,
    selectIsOpenPairEquipmentControlPopup,
    setIsEquipmentDepotPairing,
    setIsOpenPairEquipmentControlPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { Button, useModal } from '~/ui';
import constants from '~/utils/constants';
import {
    AddTaskModalContext,
    AddTaskModalContextProps
} from './AddTaskModalContext';
import { AddressTabContent } from './AddressTabContent';
import { InventoryTabContent } from './InventoryTabContent';
import { NotesTabContent } from './NotesTabContent';
import { TaskInformationTabContent } from './TaskInformationTabContent';
import { useClientTaskState } from './useClientTaskState';
import { useOnInvoicesChange } from './useOnInvoicesChange';
import { useOnSaveAndCloseClick } from './useOnSaveAndCloseClick';
import {
    AddTaskModalTab,
    AddressTabFields,
    ClientTask,
    ClientTaskErrorState,
    className,
    clientTaskRequiredFieldMap,
    emptyClientTask,
    getAddressErrorState,
    getInventoryItemErrorState,
    getNotesErrorState,
    getSizeByCompartmentErrorState,
    getTimeWindowErrorState,
    tasksFieldToPreserve,
    TimeWindow
} from './utils/addTaskModalUtils';
import {
    getDeliveryLocationFromPlace,
    getPickupLocationFromPlace
} from './utils/locationUtils';

import { useMainClientTimeAdjustment } from '~/hooks';

import './addTaskModal.scss';

export const testId = 'addTaskModal';

export const addressTabDataTestId = 'addTaskModal_addressTabTestId';
export const taskInformationTabTestId = 'addTaskModal_taskInformationTabTestId';
export const inventoryTabTestId = 'addTaskModal_inventoryTabTestId';
export const notesTabTestId = 'addTaskModal_notesTabTestId';

export const cancelButtonTestId = 'addTaskModal_cancelButtonTestId';
export const nextButtonTestId = 'addTaskModal_nextButtonTestId';
export const saveAndCloseButtonTestId = 'addTaskModal_saveAndCloseButtonTestId';
export const saveAndAssignButtonTestId =
    'addTaskModal_saveAndAssignButtonTestId';

interface TabData<T extends React.ComponentType = React.ComponentType> {
    tabTitleTranslationKey: string;
    TabPanelComponent: T;
    tabDataTestId: string;
    tab: AddTaskModalTab;
}

export const tabsData: TabData[] = [
    {
        tabTitleTranslationKey: 'header.address',
        TabPanelComponent: AddressTabContent,
        tabDataTestId: addressTabDataTestId,
        tab: AddTaskModalTab.ADDRESS
    },
    {
        tabTitleTranslationKey: 'header.taskInformation',
        TabPanelComponent: TaskInformationTabContent,
        tabDataTestId: taskInformationTabTestId,
        tab: AddTaskModalTab.TASK_INFORMATION
    },
    {
        tabTitleTranslationKey: 'header.inventory',
        TabPanelComponent: InventoryTabContent,
        tabDataTestId: inventoryTabTestId,
        tab: AddTaskModalTab.INVENTORY_ITEMS
    },
    {
        tabTitleTranslationKey: 'header.notes',
        TabPanelComponent: NotesTabContent,
        tabDataTestId: notesTabTestId,
        tab: AddTaskModalTab.NOTES
    }
];

export const indexToTab = tabsData.reduce((acc, { tab }, index) => {
    acc[index] = tab;
    return acc;
}, {} as { [key: number]: AddTaskModalTab });

export const AddTaskModal: React.FC = () => {
    const { t } = useTranslation(['addTask']);
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectIsOpenAddTaskModal);
    const selectedTaskType = useSelector(selectSelectedTaskType);
    const selectedEquipment = useSelector(selectSelectedEquipment);
    const isSaveAndAssignTaskMode = useSelector(selectIsSaveAndAssignTaskMode);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const selectedEquipmentData = useSelector(
        selectEquipmentById(selectedEquipmentIds)
    );
    const isEquipmentPairing = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const isEquipmentDepotPairing = useSelector(selectIsEquipmentDepotPairing);
    const isOpenAddTaskModal = useSelector(selectIsOpenAddTaskModal);

    const { getClientAdjustedTime } = useMainClientTimeAdjustment();

    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onShow: () => undefined,
        onHide: () => {
            dispatch(clearCustomerSearchResults());
            dispatch(setIsOpenAddTaskModal(false));
            dispatch(setIsSaveAndAssignTaskMode(false));
            dispatch(setIsAssignNewTaskToDispatchedDriver(false));
            dispatch(setIsOpenPairEquipmentControlPopup(false));
            dispatch(setIsEquipmentDepotPairing(false));
            dispatch(resetGapRoute());
        }
    });

    const [tabIndex, setTabIndex] = useState(0);

    const selectedTab = useMemo(() => indexToTab[tabIndex], [tabIndex]);

    const {
        clientTask,
        setClientTask,
        errorState,
        setErrorState,
        addressTabErrorState,
        taskInformationTabErrorState,
        inventoryItemsTabErrorState,
        hasIssuesAddressTab: addressTabHasIssues,
        hasIssuesModal: modalHasIssues,
        tabIssuesMap
    } = useClientTaskState();

    useEffect(() => {
        if (
            isOpenAddTaskModal &&
            (isEquipmentPairing || isEquipmentDepotPairing)
        ) {
            setClientTask((prev) => ({
                ...prev,
                taskType: TaskTypes.PICKUP
            }));
        }
    }, [
        isEquipmentPairing,
        isEquipmentDepotPairing,
        isOpenAddTaskModal,
        setClientTask
    ]);

    useEffect(() => {
        if (isModalOpen) {
            showModal();
        } else {
            hideModal();
        }
        setTabIndex(0);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isModalOpen]);

    const { onSaveAndCloseClick } = useOnSaveAndCloseClick({
        clientTask,
        hideModal
    });

    useEffect(() => {
        dispatch(setSelectedTabIndex(tabIndex));
    }, [tabIndex, dispatch]);

    useEffect(() => {
        if (selectedEquipmentData.length) {
            const hasSingleEquipmentData = selectedEquipmentData.length === 1;
            const [equipment] = selectedEquipmentData;
            if (
                !isEqual(selectedEquipment, equipment) &&
                hasSingleEquipmentData
            )
                dispatch(setSelectedEquipment(equipment));
        } else if (selectedEquipment && !selectedEquipmentData.length) {
            dispatch(setSelectedEquipment());
        }
    }, [selectedEquipmentData, dispatch, selectedEquipment]);

    const { taskType: clientTaskType } = clientTask;
    const isTwoPartTask = clientTaskType === TaskTypes.TWOPART;

    useEffect(() => {
        if (!isModalOpen || !selectedEquipment) {
            setClientTask((prevClientTask) => {
                const createTimeWindows = (taskType: string) => ({
                    start: getClientAdjustedTime(
                        'start',
                        taskType,
                        isTwoPartTask
                    ),
                    end: getClientAdjustedTime('end', taskType, isTwoPartTask)
                });

                return {
                    ...prevClientTask,
                    deliveryTimeWindows: [
                        createTimeWindows(TaskTypes.DELIVERY)
                    ],
                    pickupTimeWindows: [createTimeWindows(TaskTypes.PICKUP)]
                };
            });
            return;
        }
        dispatch(
            setSelectedTaskType(
                selectedEquipment
                    ? AddressTaskTypes.PICKUP
                    : AddressTaskTypes.DELIVERY
            )
        );
        setClientTask((prevClientTask) => {
            const currentTaskType = selectedEquipment
                ? TaskTypes.PICKUP
                : prevClientTask.taskType;

            return {
                ...prevClientTask,
                taskType: currentTaskType,
                [`${currentTaskType}AddressStreet`]:
                    selectedEquipment?.customerAddressLine1 || '',
                [`${currentTaskType}AddressCity`]:
                    selectedEquipment?.customerCity || '',
                [`${currentTaskType}AddressState`]:
                    selectedEquipment?.customerState || '',
                [`${currentTaskType}AddressZipCode`]:
                    selectedEquipment?.customerPostalCode || '',
                [`${currentTaskType}CustomerName`]:
                    selectedEquipment?.customerName || '',
                [`${currentTaskType}Euid`]: selectedEquipment?.customerId || '',
                [`${currentTaskType}Longitude`]:
                    selectedEquipment?.lat?.toString() || '',
                [`${currentTaskType}Latitude`]:
                    selectedEquipment?.lng?.toString() || ''
            };
        });
    }, [
        selectedEquipment,
        isModalOpen,
        setClientTask,
        dispatch,
        getClientAdjustedTime,
        isTwoPartTask
    ]);

    const fillInAddress = useCallback(
        (place: AddressTabFields, taskType: AddressTaskTypes) => {
            const addressErrorState = getAddressErrorState({ place, taskType });

            let equipmentLocation = {};
            if (selectedEquipment) {
                const { lat, lng } = selectedEquipment;
                equipmentLocation = {
                    latitude: lat?.toString() || place[`${taskType}Latitude`],
                    longitude: lng?.toString() || place[`${taskType}Longitude`]
                };
            }

            setErrorState((prev) => {
                return { ...prev, ...addressErrorState };
            });

            let obj = {};

            const isPickupTask = taskType === AddressTaskTypes.PICKUP;

            if (isPickupTask) {
                obj = getPickupLocationFromPlace(place);
            }

            const isDeliveryTask = taskType === AddressTaskTypes.DELIVERY;

            if (isDeliveryTask) {
                obj = getDeliveryLocationFromPlace(place);
            }

            setClientTask((prev) => {
                return {
                    ...prev,
                    ...obj,
                    ...equipmentLocation
                };
            });
        },
        [selectedEquipment, setErrorState, setClientTask]
    );

    const clearAddress = useCallback(
        (taskType: string) => {
            let obj = {};
            if (taskType === 'pickup') {
                obj = getPickupLocationFromPlace(emptyClientTask);
            }
            if (taskType === 'delivery') {
                getDeliveryLocationFromPlace(emptyClientTask);
            }

            setClientTask((prev) => {
                return {
                    ...prev,
                    ...obj
                };
            });
        },
        [setClientTask]
    );

    const isFieldToPreserve = (field: string) => {
        return Object.prototype.hasOwnProperty.call(
            tasksFieldToPreserve,
            field
        );
    };

    const isPickupLaterThanDelivery = (
        pickupTimeWindow: Readonly<TimeWindow>[],
        deliveryTimeWindow: Readonly<TimeWindow>[]
    ) => {
        const pickupEndTimes = pickupTimeWindow.map((window) =>
            new Date(window.end || '').getTime()
        );
        const deliveryStartTimes = deliveryTimeWindow.map((window) =>
            new Date(window.start || '').getTime()
        );
        const latestPickupEndTime = new Date(Math.max(...pickupEndTimes));
        const earliestDeliveryStartTime = new Date(
            Math.min(...deliveryStartTimes)
        );
        const errorValue = !(latestPickupEndTime <= earliestDeliveryStartTime);
        return {
            start: errorValue,
            end: errorValue
        };
    };

    const checkIfExternalTaskTypeValid = useCallback((value: string) => {
        return value.length > constants.externalTaskType.MAX_LENGTH;
    }, []);

    const onStringFieldChange = useCallback(
        <F extends MatchTypeKeys<ClientTask, string>>(
            field: F,
            value: Required<ClientTask>[F]
        ) => {
            setErrorState((prev) => {
                if (field === 'externalTaskType' && value) {
                    return {
                        ...prev,
                        [field]: checkIfExternalTaskTypeValid(value)
                    };
                }
                return {
                    ...prev,
                    [field]: clientTaskRequiredFieldMap(selectedTaskType)[field]
                        ? !value?.length
                        : false
                };
            });

            // this fields need to be preserved when the address is changed.
            if (isFieldToPreserve(field)) {
                emptyClientTask[field] = value;
            }

            setClientTask((prev) => ({
                ...prev,
                [field]: value
            }));
        },
        [
            selectedTaskType,
            setErrorState,
            checkIfExternalTaskTypeValid,
            setClientTask
        ]
    );

    const onInputChange = useCallback(
        (
            field: MatchTypeKeys<ClientTask, string, true>,
            { target: { value } }: React.ChangeEvent<HTMLInputElement>
        ) => {
            onStringFieldChange(field, value);
        },
        [onStringFieldChange]
    );

    const onOtherFieldChange = useCallback(
        <F extends FilterTypeKeys<ClientTask, string | Array<unknown>, true>>(
            field: F,
            value: Required<ClientTask>[F]
        ) => {
            const clientTaskClone = { ...clientTask };
            clientTaskClone[field] = value;
            // this fields need to be preserved when the address is changed.
            if (isFieldToPreserve(field)) {
                emptyClientTask[field] = value;
            }
            setClientTask(clientTaskClone);
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [errorState, clientTask]
    );

    const onArrayFieldChange = useCallback(
        <F extends MatchTypeKeys<Required<ClientTask>, Array<unknown>>>(
            field: F,
            value: Required<ClientTask>[F],
            getArrayItemErrorState: (
                item: Required<ClientTask>[F][number],
                requiredMap: RequiredFieldMapTypeGen<
                    Required<ClientTask>
                >[F]['requiredMap']
            ) => Required<ErrorStateTypeGen<Required<ClientTask>[F][number]>>
        ) => {
            type Field = MatchTypeKeys<Required<ClientTask>, Array<unknown>>;
            const errorStateClone = { ...errorState };

            const requiredMapValue =
                clientTaskRequiredFieldMap(selectedTaskType)[field];

            const { minLength, requiredMap: subRequiredMap } = requiredMapValue;

            type ErrorStateValueData = NonNullable<ClientTaskErrorState[Field]>;
            type SubErrorState = ErrorStateValueData['errorState'];

            const newErrorStateCloneValue: SubErrorState = [];

            value.forEach((valueRow) => {
                const valueRowValues = Object.values(valueRow);
                const rowHasFilledValue = valueRowValues.some(
                    (rowValue) => !!rowValue
                );

                if (!rowHasFilledValue) {
                    newErrorStateCloneValue.push({});
                    return;
                }
                const arrayItemErrorState = getArrayItemErrorState(
                    valueRow,
                    subRequiredMap
                );
                newErrorStateCloneValue.push(arrayItemErrorState);
            });

            const clientTaskClone = { ...clientTask };
            clientTaskClone[field] = value;
            if (clientTaskClone.taskType === 'twoPart') {
                const dateValidationErrorState = isPickupLaterThanDelivery(
                    clientTaskClone.pickupTimeWindows,
                    clientTaskClone.deliveryTimeWindows
                );
                newErrorStateCloneValue.push(dateValidationErrorState);
            }
            errorStateClone[field] = {
                hasError: value.length < minLength,
                errorState: newErrorStateCloneValue
            };
            setErrorState(errorStateClone);
            setClientTask(clientTaskClone);
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [errorState, clientTask]
    );

    const onTimeWindowsChange = useCallback(
        (
            field: 'deliveryTimeWindows' | 'pickupTimeWindows',
            value: Required<ClientTask>[
                | 'deliveryTimeWindows'
                | 'pickupTimeWindows']
        ) => {
            return onArrayFieldChange(field, value, getTimeWindowErrorState);
        },
        [onArrayFieldChange]
    );
    const onInvoicesChange = useOnInvoicesChange({ onArrayFieldChange });

    const onSizeByCompartmentChange = useCallback(
        (value: Required<ClientTask>['sizeByCompartment']) => {
            return onArrayFieldChange(
                'sizeByCompartment',
                value,
                getSizeByCompartmentErrorState
            );
        },
        [onArrayFieldChange]
    );

    const onInventoryItemsChange = useCallback(
        (value: Required<ClientTask>['inventoryItems']) => {
            return onArrayFieldChange(
                'inventoryItems',
                value,
                getInventoryItemErrorState
            );
        },
        [onArrayFieldChange]
    );

    const onNotesChange = useCallback(
        (value: Required<ClientTask>['notes']) => {
            return onArrayFieldChange('notes', value, getNotesErrorState);
        },
        [onArrayFieldChange]
    );

    const addTaskModalContextValue: AddTaskModalContextProps = useMemo(
        () => ({
            clientTask,
            setClientTask,
            fillInAddress,
            clearAddress,
            onStringFieldChange,
            onInputChange,
            onOtherFieldChange,
            onTimeWindowsChange,
            onInvoicesChange,
            onInventoryItemsChange,
            onNotesChange,
            onSizeByCompartmentChange,
            errorState,
            addressTabErrorState,
            inventoryItemsTabErrorState,
            taskInformationTabErrorState
        }),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [
            clientTask,
            setClientTask,
            selectedEquipment,
            setSelectedEquipment,
            fillInAddress,
            onStringFieldChange,
            onInputChange,
            onOtherFieldChange,
            onTimeWindowsChange,
            onInvoicesChange,
            onInventoryItemsChange,
            onNotesChange,
            errorState,
            addressTabErrorState,
            inventoryItemsTabErrorState,
            taskInformationTabErrorState
        ]
    );

    const onTabSelect = useCallback((index: number) => {
        setTabIndex(index);
    }, []);

    const onNextClick = useCallback(() => {
        setTabIndex((i) => i + 1);
    }, []);

    const onCancelClick = useCallback(() => {
        hideModal();
    }, [hideModal]);

    const maxTabIndex = tabsData.length - 1;

    const renderFooter = useCallback(() => {
        const selectedTabHasIssues = tabIssuesMap[selectedTab];

        // First tab
        if (tabIndex === 0) {
            return (
                <div className="_d-flex _jc-flex-end _cg-4">
                    <CancelButton onClick={onCancelClick} variant="secondary" />
                    <NextButton
                        onClick={onNextClick}
                        disabled={selectedTabHasIssues}
                    />
                </div>
            );
        }

        const SaveButton = isSaveAndAssignTaskMode
            ? SaveAndAssignButton
            : SaveAndCloseButton;

        // Last tab
        if (tabIndex === maxTabIndex) {
            return (
                <div className="_d-flex _jc-flex-end _cg-4">
                    <CancelButton onClick={onCancelClick} variant="secondary" />
                    <SaveButton
                        onClick={onSaveAndCloseClick}
                        disabled={modalHasIssues}
                    />
                </div>
            );
        }

        // Any middle tab
        return (
            <div className="_d-flex _jc-space-between">
                <CancelButton onClick={onCancelClick} type="naked" />
                <div className="_d-flex _cg-4">
                    <SaveButton
                        onClick={onSaveAndCloseClick}
                        variant="secondary"
                        disabled={modalHasIssues}
                    />
                    <NextButton
                        onClick={onNextClick}
                        disabled={selectedTabHasIssues}
                    />
                </div>
            </div>
        );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        clientTask,
        tabIndex,
        onCancelClick,
        onNextClick,
        addressTabHasIssues,
        modalHasIssues,
        maxTabIndex,
        onSaveAndCloseClick,
        t
    ]);

    return (
        <AddTaskModalContext.Provider value={addTaskModalContextValue}>
            <Modal
                title={t('header.title')}
                hasCloseButton
                hasAutoHide={false}
                className={className}
                data-testid={testId}
            >
                <Tabs
                    className={`${className}_tabs`}
                    selectedIndex={tabIndex}
                    onSelect={onTabSelect}
                >
                    <TabList>
                        {tabsData.map(
                            ({ tabTitleTranslationKey, tabDataTestId }, i) => (
                                <Tab
                                    key={tabTitleTranslationKey}
                                    data-testid={tabDataTestId}
                                >
                                    <div
                                        className={`_ai-center _jc-center ${className}_header_tab`}
                                    >
                                        <div
                                            className={`${className}_header_number`}
                                        >
                                            {i + 1}
                                        </div>
                                        <div>{t(tabTitleTranslationKey)}</div>
                                    </div>
                                </Tab>
                            )
                        )}
                    </TabList>
                    <div className={`${className}_content`}>
                        {tabsData.map(
                            ({ tabTitleTranslationKey, TabPanelComponent }) => (
                                <TabPanel key={tabTitleTranslationKey}>
                                    <TabPanelComponent />
                                </TabPanel>
                            )
                        )}
                    </div>
                    <div className={`${className}_footer`}>
                        {renderFooter()}
                    </div>
                </Tabs>
            </Modal>
        </AddTaskModalContext.Provider>
    );
};

function CancelButton(props: React.ComponentProps<typeof Button>) {
    const { t } = useTranslation(['addTask']);

    return (
        <Button {...props} data-testid={cancelButtonTestId}>
            {t('footer.cancel')}
        </Button>
    );
}

function NextButton(props: React.ComponentProps<typeof Button>) {
    const { t } = useTranslation(['addTask']);

    return (
        <Button {...props} data-testid={nextButtonTestId}>
            {t('footer.next')}
        </Button>
    );
}

function SaveAndCloseButton(props: React.ComponentProps<typeof Button>) {
    const { t } = useTranslation(['addTask']);

    return (
        <Button {...props} data-testid={saveAndCloseButtonTestId}>
            {t('footer.saveAndClose')}
        </Button>
    );
}

function SaveAndAssignButton(props: React.ComponentProps<typeof Button>) {
    const { t } = useTranslation(['addTask']);

    return (
        <Button {...props} data-testid={saveAndAssignButtonTestId}>
            {t('footer.saveAndAssign')}
        </Button>
    );
}
