import { MapDrawingStyles } from '~/components/MapEngineProvider/types';

export const getStyleSetup = (mapDrawingStyles: MapDrawingStyles | null) => {
    if (!mapDrawingStyles) {
        return null;
    }
    const {
        mapsDrawingStyles: { DOTTED, SOLID },
        mapsDirectionIconPath
    } = mapDrawingStyles;

    return {
        DOTTED,
        SOLID,
        mapsDirectionIconPath,
        routeLineDirectionSymbol: {
            icon: {
                path: mapsDirectionIconPath,
                fillOpacity: 1,
                strokeOpacity: 0
            },
            repeat: '100px'
        }
    };
};
