import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '~/ui';

import { useAddTaskModalContext } from '../AddTaskModalContext';
import { Notes } from '../Notes';

import './NotesTabContent.scss';

export const testId = 'notesTab_testId';
export const addNewNoteButtonTestId = 'notesTab_addNewNoteButtonTestId';

export const NotesTabContent: React.FC = () => {
    const { t } = useTranslation(['addTask']);
    const {
        clientTask: { notes },
        onNotesChange
    } = useAddTaskModalContext();

    const onAddNewNoteClick = useCallback(() => {
        onNotesChange([...notes, {}]);
    }, [onNotesChange, notes]);

    return (
        <div className="notes-tab_content" data-testid={testId}>
            <div className="notes-tab_header-container">
                <div className="_text-1-alt notes-tab_header">
                    {t('notesTab.header')}
                </div>
                <div className="_text-2-alt notes-tab_subHeader">
                    {t('notesTab.subHeader')}
                </div>
            </div>
            <div className="notes-tab_notes-container">
                <div className="add-new-note-btn-container">
                    <Button
                        type="link"
                        data-testid={addNewNoteButtonTestId}
                        className="add-new-note-button"
                        onClick={onAddNewNoteClick}
                    >
                        <div className="button-layout">
                            <Icon icon="addLight" color="meteor" />
                            <div>{t('notesTab.addNewNoteButton')}</div>
                        </div>
                    </Button>
                </div>
                <Notes />
            </div>
        </div>
    );
};
