import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, ModalSize } from '~/ui/components/Modal/types';
import { CreateTripModalRouteCard } from './CreateTripModalRouteCard';
import { CreateTripModalFooter } from './CreateTripModalFooter';
import './CreateTripModal.scss';

interface CreateTripModalProps {
    Modal: (props: ModalProps) => JSX.Element;
    onClose: () => void;
}

export const CreateTripModal = ({ Modal, onClose }: CreateTripModalProps) => {
    const rootClassName = 'create-trip-modal';
    const { t } = useTranslation('createTripModal');

    return (
        <Modal
            hasCloseButton
            variant={ModalSize.SMALL}
            title={t('title')}
            className={rootClassName}
        >
            <>
                <div className={`${rootClassName}__content _fd-column`}>
                    <CreateTripModalRouteCard />
                    <div className={`_fd-column ${rootClassName}__info`}>
                        <span className={`${rootClassName}__description`}>
                            {t('content.description')}
                        </span>
                        <span className={`${rootClassName}__confirmation`}>
                            {t('content.confirmation')}
                        </span>
                    </div>
                </div>
                <CreateTripModalFooter onClose={onClose} />
            </>
        </Modal>
    );
};
