import { isArray } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

/**
 * Redux slice for user-selected task IDs
 * @category Reducers
 * @module selectedTaskIds
 */

export const selectedEquipmentIdsSlice = createSlice({
    name: 'selectedEquipmentIds',
    initialState: [] as string[], // Specify the type as an array of strings
    reducers: {
        addSelectedEquipmentId: (state, action: PayloadAction<string>) => {
            const hasEquipmentId = state.includes(action.payload);
            if (!hasEquipmentId) {
                state.push(action.payload);
            }
        },

        removeSelectedEquipmentId: (state, action: PayloadAction<string>) => {
            const index = state.indexOf(action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },

        setSelectedEquipmentIds: (
            state,
            action: PayloadAction<string | string[]>
        ) => {
            state = isArray(action.payload) ? action.payload : [action.payload];
            return state;
        },

        resetSelectedEquipmentIds: (): string[] => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const {
    addSelectedEquipmentId,
    removeSelectedEquipmentId,
    setSelectedEquipmentIds,
    resetSelectedEquipmentIds
} = selectedEquipmentIdsSlice.actions;

export const selectSelectedEquipmentIds = (state: RootState): string[] =>
    state.selectedEquipmentIds;

export default selectedEquipmentIdsSlice.reducer;
