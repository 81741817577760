import React from 'react';
import constants from '~/utils/constants';
import { TaskTypes } from '~/api/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './ParkingPin.scss';

const rootClassName = 'parkingpin';

function ParkingPin({ taskType }) {
    const { t } = useTranslation(['common']);

    const conditionalClassNames = {
        [`with-task-type`]: taskType,
        [`${rootClassName}--${taskType}`]: taskType
    };
    const blockClassName = classNames(
        rootClassName,
        '_jc-center _ai-center',
        conditionalClassNames
    );

    return (
        <div data-testid={rootClassName} className={blockClassName}>
            {!taskType && constants.mapMarkerLabels.PARKING}
            {taskType === TaskTypes.PICKUP &&
                t('common:unassignedTaskIcons.pickup')}
            {taskType === TaskTypes.DELIVERY &&
                t('common:unassignedTaskIcons.delivery')}
        </div>
    );
}

export default ParkingPin;
