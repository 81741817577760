import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { TooltipButton } from '~/ui';
import {
    TooltipButtonSizes,
    TooltipButtonVariants
} from '~/ui/components/TooltipButton';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { PlanRoute, PlanStop } from '~/data-classes';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { mapPlanStopUtils } from '~/utils/map';
import { getTaskId } from '~/utils/stopUtils';
import SinglePlanEditApi, { TaskIdsByRoute } from '~/api/SinglePlanEditApi';
import { addToast } from '~/reducers/toastsSlice';

interface CreateTripModalFooterProps {
    onClose: () => void;
}

export const CreateTripModalFooter = ({
    onClose
}: CreateTripModalFooterProps) => {
    const rootClassName = 'create-trip-modal-footer';
    const { t } = useTranslation(['common', 'createTripModal']);
    const cancelButtonClassName = `${rootClassName}__cancel`;
    const confirmationButtonClassName = `${rootClassName}__confirm`;
    const dispatch = useDispatch();
    const { data: selectedDrawerCardData } = useSelector(
        selectSelectedDrawerCardData
    );
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const { planStops } = usePlanMapPropsContext();

    const { vehicleId, driverId, date, selectedPlanStops } = useMemo(() => {
        const planRoute = new PlanRoute(selectedDrawerCardData);
        const { selectedStops } = mapPlanStopUtils.mapIdtoStopData(
            planStops,
            selectedMapStopsIds
        ) as unknown as { selectedStops: PlanStop[] };

        return {
            date: planRoute.routeDate,
            driverId: planRoute.driverId,
            selectedPlanStops: selectedStops,
            vehicleId: planRoute.vehicleId
        };
    }, [planStops, selectedDrawerCardData, selectedMapStopsIds]);

    const handleCreateTrip = () => {
        const uniqueSelectedStopsByTaskId = uniqBy(selectedPlanStops, (stop) =>
            getTaskId(stop)
        );

        const taskIdsByRouteId = uniqueSelectedStopsByTaskId.reduce(
            (previousValue: TaskIdsByRoute, stop: PlanStop) => {
                const { routeId } = stop;
                const taskId = getTaskId(stop);
                const tasksIds = previousValue[routeId] ?? [];

                tasksIds.push(taskId);
                previousValue[routeId] = tasksIds;
                return previousValue;
            },
            {}
        );

        (async () => {
            try {
                await SinglePlanEditApi.addNewTripToRoute({
                    date,
                    driverId,
                    taskIdsByRouteId,
                    vehicleId
                });
                onClose();
            } catch (err) {
                console.error(err);
                dispatch(
                    addToast({
                        message: t('common:errorMessage'),
                        variant: 'error'
                    })
                );
            }
        })();
    };

    return (
        <div className={`${rootClassName} _d-flex _jc-flex-end`}>
            <TooltipButton
                className={cancelButtonClassName}
                variant={TooltipButtonVariants.SECONDARY}
                size={TooltipButtonSizes.LARGE}
                data-testid={cancelButtonClassName}
                onClick={onClose}
            >
                {t('createTripModal:footer.cancel')}
            </TooltipButton>
            <TooltipButton
                className={confirmationButtonClassName}
                variant={TooltipButtonVariants.PRIMARY}
                size={TooltipButtonSizes.LARGE}
                data-testid={confirmationButtonClassName}
                onClick={handleCreateTrip}
            >
                {t('createTripModal:footer.confirm')}
            </TooltipButton>
        </div>
    );
};
