import { Coordinates, CoordinatePoint } from '~/api/types';
import {
    Feature as GeoJsonFeature,
    Point as GeoJsonPoint,
    GeoJsonProperties
} from 'geojson';

/**
 * Location Utilities
 *
 * @category Utils
 * @module utils/locationUtils
 *
 * @example
 * import {
 *     getAverageCoordinates,
 *     convertCoordinateToPoint,
 *     convertPointToCoordinate,
 *     convertCoordinateToGeoJsonPoint
 * } from '~/utils/location-utils';
 */

/**
 * Roughly approximates the geographical center of a list of lat lng tuples.
 * Better used for set of points that are geographically close to one another
 * (eg.: stop locations for a route).
 */
export const getAverageCoordinates = (
    allCoordinates: Coordinates[]
): Coordinates | null => {
    const allCoordinatesLength = allCoordinates.length;
    if (!allCoordinatesLength) return null;

    const { lat: sumOfLat, lng: sumOfLng }: Coordinates = allCoordinates.reduce(
        (currentSum, coordinate) => {
            const { lat: currentSumOfLat, lng: currentSumOfLng } = currentSum;
            const { lat, lng } = coordinate;

            return {
                lat: currentSumOfLat + lat,
                lng: currentSumOfLng + lng
            };
        },
        { lat: 0, lng: 0 }
    );

    const averageCoordinates: Coordinates = {
        lat: sumOfLat / allCoordinatesLength,
        lng: sumOfLng / allCoordinatesLength
    };

    return averageCoordinates;
};

/**
 * Converts a coordinate object to a coordinate point array
 */
export const convertCoordinateToPoint = (
    coordinate: Coordinates
): CoordinatePoint => {
    const { lng, lat } = coordinate;
    return [lng, lat];
};

/**
 * Converts a coordinate point array to a coordinate object
 */
export const convertPointToCoordinate = (
    point: CoordinatePoint
): Coordinates => {
    const [lng, lat] = point;
    return { lat, lng };
};

/**
 * Converts a coordinate object to a GeoJSON point feature
 */
export const convertCoordinateToGeoJsonPoint = (
    coordinate: Coordinates,
    properties: GeoJsonProperties = null
): GeoJsonFeature<GeoJsonPoint> => {
    const coordinatePoint = convertCoordinateToPoint(coordinate);
    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: coordinatePoint
        },
        properties
    };
};
