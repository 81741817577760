import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectClients } from '~/reducers/clientsSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';

import {
    ReassignTasksHandlerProps,
    reassignPlanRouteTasks
} from './reassignPlanRouteTasks';

export const useReassignPlanRouteTasks = () => {
    const dispatch = useDispatch();
    const { t: translationFunction } = useTranslation('translation');
    const clients = useSelector(selectClients);
    const selectedDate = useSelector(selectDateOnly);

    const reassignPlanRouteTasksCallback = useCallback(
        (reassignHandlerProps: ReassignTasksHandlerProps) => {
            if (!selectedDate) {
                console.warn('date not selected');
                return;
            }

            (async () => {
                await reassignPlanRouteTasks({
                    clients,
                    dispatch,
                    selectedDate,
                    translationFunction,
                    reassignHandlerProps
                });
            })();
        },
        [clients, dispatch, selectedDate, translationFunction]
    );

    return {
        reassignPlanRouteTasks: reassignPlanRouteTasksCallback
    };
};
