import React from 'react';
import { DrawerButtonGroup, IconButton, Icon } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize } from '~/ui/components/Icon/IconSizes';

import './drawer-notice.scss';

interface DrawerNoticeProps {
    noticeTitle: string;
    noticeBody: React.ReactNode;
    icon: IconName;
    iconColor?: string;
    iconSize?: IconSize;
    onClick?: () => void;
}

function DrawerNotice({
    noticeTitle,
    noticeBody,
    icon,
    iconColor = 'galaxy-800',
    iconSize = 's',
    onClick = () => {}
}: DrawerNoticeProps) {
    function handleOnClick() {
        return onClick && onClick();
    }

    function getIcon(): React.ReactNode {
        if (!icon || !icon.length) return null;
        return <Icon icon={icon} size={iconSize} color={iconColor} />;
    }

    return (
        <div className="drawer-notice" data-testid="drawerNotice">
            <DrawerButtonGroup className="_jc-space-between">
                <div className="drawer-notice_title _d-flex _ai-center">
                    {getIcon()}
                    <span>{noticeTitle}</span>
                </div>
                <IconButton
                    className="drawer-notice_close"
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={handleOnClick}
                />
            </DrawerButtonGroup>
            <div className="drawer-notice_text _text-3-alt">{noticeBody}</div>
        </div>
    );
}

export default DrawerNotice;
