import { UniqueIdentifier } from '@dnd-kit/core';

export const getDraggedItemIndexes = (
    initialOrder: Record<string, unknown>[],
    draggedItemIds: UniqueIdentifier[]
) => {
    return draggedItemIds
        .map((id) => {
            return initialOrder.findIndex(
                (initialItem) => initialItem.id === id
            );
        })
        .filter((index) => index !== -1);
};

export const getDraggedItems = (
    initialOrder: Record<string, unknown>[],
    draggedItemIds: unknown[]
) => {
    return draggedItemIds
        .map((id) => {
            return initialOrder.find((item: Record<string, unknown>) => {
                const { id: itemId } = item;
                return itemId === id;
            });
        })
        .filter(Boolean);
};

const isSingleItemDragged = (draggedItemIds: UniqueIdentifier[] | null) => {
    return Boolean(draggedItemIds && draggedItemIds.length === 1);
};

export const filterOutDraggedItems = (
    originalOrder: Record<string, unknown>[],
    draggedItemIds: UniqueIdentifier[] | null
): Record<string, unknown>[] => {
    if (!draggedItemIds) {
        return originalOrder;
    }
    return originalOrder.filter((item: Record<string, unknown>) => {
        const { id } = item;
        const isDragged = draggedItemIds.indexOf(id as UniqueIdentifier) > -1;
        return !isDragged;
    });
};

export const getDraggedOrder = (
    originalOrder: Record<string, unknown>[],
    draggedItemIds: UniqueIdentifier[] | null,
    dragHandler: UniqueIdentifier | null
) => {
    const isSingleDragged = isSingleItemDragged(draggedItemIds);
    if (isSingleDragged) {
        return originalOrder;
    }
    const toFilterOut = draggedItemIds?.filter((id) => id !== dragHandler);
    return filterOutDraggedItems(
        originalOrder,
        toFilterOut as UniqueIdentifier[]
    );
};

export const getTargetItem = (
    items: Record<string, unknown>[],
    targetItemId: string | number
) => {
    return items.find((item: Record<string, unknown>) => {
        const { id } = item;
        return id === targetItemId;
    });
};

export const resequenceInsertBefore = (
    originalOrder: Record<string, unknown>[],
    itemsToInsert: Record<string, unknown>[],
    insertBeforeIndex: number
) => {
    return [
        ...originalOrder.slice(0, insertBeforeIndex),
        ...itemsToInsert,
        ...originalOrder.slice(insertBeforeIndex)
    ];
};

export const resequenceInsertAfter = (
    originalOrder: Record<string, unknown>[],
    itemsToInsert: Record<string, unknown>[],
    insertAfterIndex: number
) => {
    return [
        ...originalOrder.slice(0, insertAfterIndex + 1),
        ...itemsToInsert,
        ...(originalOrder.length === insertAfterIndex + 1
            ? []
            : originalOrder.slice(insertAfterIndex + 1))
    ];
};

export const shouldInsertBefore = (
    targetNumber: number,
    moveItemNumbers: number[]
) => {
    const smallestNumber = Math.min(...moveItemNumbers);
    return smallestNumber > targetNumber;
};

export const isDraggingInProgress = (
    draggedItemIds: UniqueIdentifier[] | null,
    targetId: UniqueIdentifier | null
) => {
    if (!draggedItemIds || !draggedItemIds?.length) {
        return false;
    }
    if (targetId) {
        return false;
    }
    return true;
};

export const getOptimisticOrder = ({
    initial,
    activeItemIds,
    targetItemId
}: {
    initial: Record<string, unknown>[];
    activeItemIds: (string | number)[];
    targetItemId: string | number;
}) => {
    const targetItem = getTargetItem(initial, targetItemId);
    if (!targetItem) {
        return initial;
    }
    const itemsWithoutDraggedItems: Record<string, unknown>[] =
        filterOutDraggedItems(initial, activeItemIds);
    const targetIndex = itemsWithoutDraggedItems.indexOf(targetItem);
    const movedItemsIndexes = getDraggedItemIndexes(initial, activeItemIds);
    const toInsertBefore = shouldInsertBefore(targetIndex, movedItemsIndexes);
    const draggedItems = getDraggedItems(initial, activeItemIds);

    if (toInsertBefore) {
        return resequenceInsertBefore(
            itemsWithoutDraggedItems,
            draggedItems as Record<string, unknown>[],
            targetIndex
        );
    }

    return resequenceInsertAfter(
        itemsWithoutDraggedItems,
        draggedItems as Record<string, unknown>[],
        targetIndex
    );
};
