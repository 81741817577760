import _ from 'lodash';

/**
 * Tests if the provided string contains both lowercase and uppercase letters
 *
 * @param {String} value
 * @returns {Boolean} true if value contains at least 1 lowercase and 1 uppercase letter
 */
function hasLowercaseAndUppercase(value: string): boolean {
    return /(?=.*[A-Z])/.test(value) && /(?=.*[a-z])/.test(value);
}

/**
 * Tests if the provided string contains at least 1 number
 *
 * @param {String} value
 * @returns {Boolean} true if value contains one or more number
 */
function hasNumber(value: string): boolean {
    return /(?=.*[0-9])/.test(value);
}

/**
 * Tests if the provided string contains at least 1 special character
 *
 * @param {String} value
 * @returns {Boolean} true if value contains one or more special characters
 */
function hasSpecialCharacter(value: string): boolean {
    return /(?=.*[#$%&'()*+,-./:;<=>!?@[\]^_`{|}~])/.test(value);
}
/**
 * Tests if the provided string is a valid staticRouteIds
 *
 * @param {String} staticId
 * @returns {Boolean} true if staticRouteIds is valid
 */
function isStaticRouteIdsValid(staticId: string): boolean {
    return /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/.test(staticId);
}

/**
 * Remove formatting from phone numbers
 *
 * @param {String} phoneNumber
 * @returns {String} phone number without brackets or spacing
 */
function removePhoneNumberFormatting(phoneNumber: string): string {
    return phoneNumber.replace(/([\s()-])/g, '');
}

/**
 * Ensure there is either one single zero character
 * if all characters are zero or that there are no leading zero characters
 * if there are other characters after the leading zero characters.
 *
 * @param {String} inputFieldValue
 * @returns {String} string with single zero character or without any leading zero character
 */
function trimLeadingZeroCharacters(inputFieldValue: string) {
    const stringWithTrimmedWhiteSpace = inputFieldValue
        .replaceAll(' ', '')
        .trim();
    const indexOfLeadingZero = stringWithTrimmedWhiteSpace.indexOf('0');

    if (indexOfLeadingZero === -1) return stringWithTrimmedWhiteSpace;

    const stringWithTrimmedLeadingZeros = _.trimStart(
        stringWithTrimmedWhiteSpace,
        '0'
    );
    return stringWithTrimmedLeadingZeros || '0';
}

export default {
    hasLowercaseAndUppercase,
    hasNumber,
    hasSpecialCharacter,
    isStaticRouteIdsValid,
    removePhoneNumberFormatting,
    trimLeadingZeroCharacters
};
