import { useEffect, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnFiltersState } from '@tanstack/react-table';
import { updateEquipmentById } from '~/reducers/equipmentSlice';
import { selectEquipmentColumnFilters } from '~/reducers/equipmentTableSlice';
import { selectSelectedClientIds } from '~/reducers/selectedClientsSlice';
import { ServerBaseUrlKey, getURL } from '~/servers/servers';
import { useIntermodalFeatures, useMapUtils } from '~/hooks';
import { SocketEquipmentUpdate } from '~/api/types';

export const EquipmentSocketProvider = () => {
    const { isDispatchedRouteMode } = useMapUtils();
    const { enableLiveDispatch: isEnabledLiveDispatchUi } =
        useIntermodalFeatures();

    const equipmentColumnFilters = useSelector(selectEquipmentColumnFilters);
    const selectedClientIds = useSelector(selectSelectedClientIds);
    const pathParams = useMemo(() => {
        /**
         * When connecting to the equipment socket,
         * it needs to be limited to the equipment that this user is allowed to use.
         *
         * As such, the `area` filter is initially added as `queryParams`
         *
         * this value will be overwritten when the user explicitly selected specific client IDs
         * using the `AREA` column filter of the equipment table
         */
        const initialAreaFilter: ColumnFiltersState = selectedClientIds.length
            ? [
                  {
                      id: 'area',
                      value: selectedClientIds
                  }
              ]
            : [];

        const allFilters: ColumnFiltersState = [
            ...initialAreaFilter,
            ...equipmentColumnFilters
        ];

        return allFilters?.reduce<{ [key: string]: string }>(
            (acc, filterValue) => {
                const { id: pathParamKey, value: pathParamValues } =
                    filterValue;
                const pathParamValuesString = Array.isArray(pathParamValues)
                    ? (pathParamValues as string[])?.join(',')
                    : (pathParamValues as string);
                acc[pathParamKey] = pathParamValuesString;
                return acc;
            },
            {}
        );
    }, [equipmentColumnFilters, selectedClientIds]);

    const equipmentSocketUrl =
        getURL(ServerBaseUrlKey.EQUIPMENT_SOCKET) || null;

    const shouldConnect = isDispatchedRouteMode && isEnabledLiveDispatchUi;

    const equipmentSocketOptions = {
        share: false,
        retryOnError: true,
        queryParams: {
            ...pathParams,
            limit: 100
        },
        reconnectAttempts: 2,
        shouldReconnect: () => true
    };

    const { lastJsonMessage: equipmentSocketMessage } = useWebSocket(
        equipmentSocketUrl,
        equipmentSocketOptions,
        shouldConnect
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (!equipmentSocketMessage || !Array.isArray(equipmentSocketMessage))
            return;

        (equipmentSocketMessage as SocketEquipmentUpdate[]).forEach(
            (equipmentUpdate) => {
                const { id } = equipmentUpdate || {};
                if (!id) return;
                const payload = { id, value: equipmentUpdate };
                dispatch(updateEquipmentById(payload));
            }
        );
    }, [equipmentSocketMessage, dispatch]);

    return null;
};
