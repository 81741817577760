import _ from 'lodash';
import { PlanStop, PlanRoute } from '~/data-classes';

export type GetSelectedMapRoutesResult = {
    clientRouteIds: string[];
    getParentClientRouteId: (clientRouteId: string) => string | undefined;
    selectedMapRoutes: string[];
};

interface RouteData {
    client: {
        clientId: string;
        clientName: string;
    };
    route: {
        routeId: string;
        routeName: string;
        routeDate: string;
        isPlanned: boolean;
        stats: { numTasks: number };
    };
    driver: {
        driverId: string;
        driverName: string;
    };
    vehicle: {
        vehicleId: string;
        vehicleEid: string;
        type: string;
        maxWeight: number;
        maxVolume: number;
    };
}
type SerializeRouteDataParams = PlanRoute | RouteData;

const serializeRouteData = (routeData: SerializeRouteDataParams): RouteData => {
    return routeData instanceof PlanRoute ? routeData.toJSON() : routeData;
};

export const splitScheduleByTrips = (schedule: PlanStop[]): PlanStop[][] => {
    const mappedSchedules = [];
    const lastIndex = schedule.length - 1;
    let startIndex = 0;

    for (let i = 1; i < schedule.length; i++) {
        const { stopNumber } = schedule[i];
        const isLastStopInSchedule = i === lastIndex;
        const isStartOfSequence = stopNumber === 0;

        if (!isLastStopInSchedule && !isStartOfSequence) continue;

        if (isLastStopInSchedule) {
            mappedSchedules.push(schedule.slice(startIndex, i + 1));
            continue;
        }

        mappedSchedules.push(schedule.slice(startIndex, i));
        startIndex = i;
    }

    return mappedSchedules;
};

const getSelectedMapRoutes = ({
    planRoutes,
    selectedMapRoutes
}: {
    planRoutes: PlanRoute[];
    selectedMapRoutes: string[];
}): GetSelectedMapRoutesResult => {
    const parentMapping: Record<string, string> = {};
    const newSelectedMapRoutes = selectedMapRoutes.flatMap((mapRoute) => {
        const planRoute = planRoutes.find(
            ({ clientRouteId }) => mapRoute === clientRouteId
        );
        const { clientRouteIds = [] } = planRoute || {};

        if (!clientRouteIds.length) return [];

        parentMapping[mapRoute] = mapRoute;

        (clientRouteIds as string[]).forEach((id) => {
            parentMapping[id] = mapRoute;
        });

        return clientRouteIds;
    });

    return {
        selectedMapRoutes,
        clientRouteIds: _.uniq(newSelectedMapRoutes),
        getParentClientRouteId: (id: string) => parentMapping[id]
    };
};

export const planRouteUtils = {
    getSelectedMapRoutes,
    serializeRouteData,
    splitScheduleByTrips
};
