import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { idUtils } from '~/utils/id-utils';
import { colorUtils } from '~/utils/color-utils';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

export const useGetDriverColor = (driverId = '') => {
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    const driverColor = useMemo(() => {
        if (!driverId) return undefined;

        const matchingRoute = selectedMapRoutes.find((item: string) => {
            const parts = idUtils.splitCombinedId(item);
            return parts[1] === driverId;
        });

        return matchingRoute
            ? colorUtils.getWebColorsForId(matchingRoute)
            : undefined;
    }, [driverId, selectedMapRoutes]);

    return driverColor;
};
