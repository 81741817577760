import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const userGroupsForCurrentUserSlice = createSlice({
    name: 'userGroupsForCurrentUser',
    initialState: [],
    reducers: {
        setUserGroupsForCurrentUser: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const { setUserGroupsForCurrentUser } =
    userGroupsForCurrentUserSlice.actions;

export const selectUserGroupsForCurrentUser = (state) =>
    state.userGroupsForCurrentUser;

export default userGroupsForCurrentUserSlice.reducer;
