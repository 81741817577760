import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const editHistoryByClientSlice = createSlice({
    name: 'editHistoryByClient',
    initialState: {},
    reducers: {
        setEditHistoryByClient: (state, action) => {
            const { data, clientId } = action.payload;

            if (!data) return;

            const newData = data.map((dt) => {
                const { id, change_data, evaluation_data, supplementaryData } =
                    dt;
                return { id, change_data, evaluation_data, supplementaryData };
            });
            return { ...state, [clientId]: newData };
        },
        resetEditHistoryByClient: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setEditHistoryByClient, resetEditHistoryByClient } =
    editHistoryByClientSlice.actions;

export const selectEditHistoryByClient = (state) => state.editHistoryByClient;
export default editHistoryByClientSlice.reducer;
