import { useContext } from 'react';
import { Context } from './types';
import { PlanMapEventsContext } from './PlanMapEventsContext';

export const usePlanMapEventsContext = (): Context => {
    const context = useContext(PlanMapEventsContext);

    if (!context) {
        throw new Error(
            'usePlanMapEventsContext must be within context provider'
        );
    }

    return context;
};
