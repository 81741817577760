import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { keyBy } from 'lodash';

import DepotsApi from '~/api/DepotsApi';
import { ApiAddress, ApiDepot } from '~/api/types';
import { addToast } from '~/reducers/toastsSlice';
import AddressApi from '~/api/AddressApi';

interface HookUseDepotsProps {
    fetchDisabled?: false;
    clientId?: string;
}

type HookUseDepots = {
    depotsList: ApiDepot[];
    getDepotById: (depotId: string) => ApiDepot | null;
    deleteDepot: (depotId: string) => Promise<void>;
    addDepot: (payload: ApiAddress) => Promise<void>;
    editDepot: ({
        addressId,
        payload
    }: {
        addressId: string;
        payload: ApiAddress;
    }) => Promise<void>;
};

const REACT_QUERY_KEY = 'depots';

export const useDepots = (
    { fetchDisabled, clientId = '' }: HookUseDepotsProps = {
        fetchDisabled: false,
        clientId: ''
    }
): HookUseDepots => {
    const dispatch = useDispatch();
    const { t } = useTranslation('error');
    const queryClient = useQueryClient();

    const placeholderData = useMemo(() => {
        return [];
    }, []);

    const { data: depotsData } = useQuery<ApiDepot[]>({
        queryKey: [clientId, REACT_QUERY_KEY],
        queryFn: async () => {
            try {
                const {
                    data: { data: depots }
                } = await DepotsApi.get(clientId);
                return (depots || []) as ApiDepot[];
            } catch (error) {
                console.error(error);
                return [] as ApiDepot[];
            }
        },
        enabled: !fetchDisabled,
        placeholderData
    });

    const { mutateAsync: deleteDepot } = useMutation(
        async (depotId: string) => {
            await DepotsApi.delete(depotId);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(REACT_QUERY_KEY);
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:deleteDepotError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    const { mutateAsync: addDepot } = useMutation(
        async (payload: ApiAddress) => {
            const response = await AddressApi.post(payload);
            const addressId = response.data.data.id as string;
            await DepotsApi.post({ address: addressId });
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(REACT_QUERY_KEY);
            },
            onError: (e) => {
                console.error(e);
                dispatch(
                    addToast({
                        message: t('error:addDepotError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    const { mutateAsync: editDepot } = useMutation(
        async ({
            addressId,
            payload
        }: {
            addressId: string;
            payload: ApiAddress;
        }) => {
            await AddressApi.update(addressId, payload);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(REACT_QUERY_KEY);
            },
            onError: (e) => {
                console.error(e);
                dispatch(
                    addToast({
                        message: t('error:editDepotError'),
                        variant: 'error'
                    })
                );
            }
        }
    );
    const depotsById = useMemo(() => {
        return keyBy(depotsData, 'id');
    }, [depotsData]);

    const getDepotById = (depotId: string) => {
        return (depotId && depotsById[depotId]) || null;
    };

    return {
        depotsList: depotsData || placeholderData,
        getDepotById,
        deleteDepot,
        addDepot,
        editDepot
    };
};
