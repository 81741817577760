import React from 'react';
import classNames from 'classnames';

import './RequiredFieldLabel.scss';

type RequiredFieldLabelProps = {
    label?: string;
    className?: string;
};

export default function RequiredFieldLabel({
    label,
    className
}: RequiredFieldLabelProps): JSX.Element {
    return (
        <span
            className={classNames(className, '_d-flex required-field-label')}
            data-testid="required-input-label"
        >
            {label}
            <span className="required-field-label--required">*</span>
        </span>
    );
}
