import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, TimeInput, TooltipButton } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { TooltipButtonVariants } from '~/ui/components/TooltipButton';
import DaysOfWeek from '~/ui/components/DaysOfWeek';
import TextInput from '~/components/OptimizationConfigurationPage/components/OptimConfigTextInput';
import { useLongShiftBlock } from './useLongShiftBlock';
import { ShiftData } from './types';

import './LongShiftBlock.scss';

interface LongShiftBlockProps {
    /**
     * The shift data
     */
    data: ShiftData;
    /**
     * The shift id
     */
    id: string;
    /**
     * The callback that handles shift removal
     */
    onShiftBlockRemove: (id: string) => void;
    /**
     * The callback that handles shift data update
     */
    onChange: (id: string, shiftData: ShiftData) => void;
}

export const LongShiftBlock = ({
    data,
    id,
    onChange,
    onShiftBlockRemove
}: LongShiftBlockProps) => {
    const rootClassName = 'long-shift-block';
    const { t } = useTranslation(['common', 'driverManagement']);
    const { days, shift } = data;
    const [{ start, hours = '0', minutes = '0' }] = shift;
    const hoursUnitText = t('driverManagement:longShiftBlock.hoursUnit');
    const minutesUnitText = t('driverManagement:longShiftBlock.minutesUnit');
    const hoursUnit = hours ? hoursUnitText : undefined;
    const minutesUnit = minutes ? minutesUnitText : undefined;
    const hoursPlaceholder = hours ? undefined : hoursUnitText;
    const minutesPlaceholder = minutes ? undefined : minutesUnitText;

    const {
        handleHoursChange,
        handleMinutesChange,
        handleStartTimeChange,
        handleWeekdaysChange
    } = useLongShiftBlock({
        data,
        id,
        onChange
    });

    return (
        <div
            className={`${rootClassName} _p-relative`}
            data-testid={rootClassName}
        >
            <TooltipButton
                className={`${rootClassName}__remove _p-absolute`}
                onClick={() => onShiftBlockRemove(id)}
                variant={TooltipButtonVariants.SECONDARY}
                size={TooltipButton.sizes.MEDIUM}
            >
                <Icon icon="cancel" size={IconSizes.S} color="neptune" />
                {t('common:remove')}
            </TooltipButton>
            <p className="_mb-1">{t('common:weekday')}</p>
            <DaysOfWeek
                selectedWeekdays={days}
                size="large"
                isEditable
                onChange={handleWeekdaysChange}
            />
            <div
                className={`${rootClassName}__time-and-duration _mt-6 _d-flex`}
            >
                <div>
                    <p className="_mb-1">
                        {t('driverManagement:longShiftBlock:startTimeLabel')}
                    </p>
                    <TimeInput
                        value={start}
                        variant="naked"
                        data-testid={`${rootClassName}__shift-time`}
                        placeholder="08:00 AM"
                        showPlaceholder={!start}
                        className={`${rootClassName}__shift-time`}
                        onChange={handleStartTimeChange}
                    />
                </div>
                <div>
                    <p className="_mb-1">
                        {t('driverManagement:longShiftBlock:durationLabel')}
                    </p>
                    <div
                        className={`${rootClassName}__hours-and-minutes _d-flex`}
                    >
                        <TextInput
                            placeholder={hoursPlaceholder}
                            unitText={hoursUnit}
                            value={hours}
                            onChange={handleHoursChange}
                            isErrorState={!hours}
                        />
                        <TextInput
                            placeholder={minutesPlaceholder}
                            unitText={minutesUnit}
                            value={minutes}
                            onChange={handleMinutesChange}
                            isErrorState={!minutes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
