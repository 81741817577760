import { PlanStop, PlanRoute, PlanClient } from '~/data-classes';

export const planDataFactory = {
    makePlanStop(stopLevelData) {
        return new PlanStop(stopLevelData);
    },
    makePlanRoute(routeLevelData) {
        return new PlanRoute(routeLevelData);
    },
    makePlanClient(clientLevelData) {
        return new PlanClient(clientLevelData);
    },
    makeRoutePlanStop(routeLevelData, planStopsLevelData) {
        if (Object.keys(planStopsLevelData).length === 0) return;
        const newRouteLevelData = {
            ...routeLevelData,
            stops: planStopsLevelData
        };
        return new PlanRoute(newRouteLevelData);
    }
};
