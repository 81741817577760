import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import ClientsApi from '~/api/ClientsApi';
import { setMainClient } from '~/reducers/mainClientSlice';
import { addToast } from '~/reducers/toastsSlice';

export default function useUpdateClientPreferences(client) {
    const dispatch = useDispatch();

    const { t } = useTranslation([
        'optimizationConfiguration',
        'common',
        'error'
    ]);

    const { mutateAsync, isLoading: isSubmitting } = useMutation(
        'client/preferences/update',
        (body) => ClientsApi.update(client.id, body)
    );

    const handleSubmit = async (values) => {
        try {
            await mutateAsync({
                preferences: { ...values }
            });

            dispatch(
                addToast({
                    message: t(
                        'optimizationConfiguration:clientPreferencesUpdateSuccess'
                    ),
                    variant: 'success'
                })
            );
            dispatch(
                setMainClient({
                    ...client,
                    preferences: {
                        ...client.preferences,
                        ...values
                    }
                })
            );
        } catch (err) {
            dispatch(
                addToast({
                    message: t(
                        'optimizationConfiguration:clientPreferencesUpdateError'
                    ),
                    variant: 'error'
                })
            );
            dispatch(setMainClient(client));
        }
    };

    return {
        isSubmitting,
        handleSubmit
    };
}
