import constants from '~/utils/constants';
import { Coordinates, GoogleMapsApi, GoogleMapsInstance } from '~/api/types';
import { ZoneObject } from '~/components/ZoneManagementPage/types';

export const centerLocationOnMap = ({
    location,
    mapsApi,
    mapInstance
}: {
    /**
     * The location coordinates
     */
    location: Coordinates;
    /**
     * The maps api
     */
    mapsApi: GoogleMapsApi;
    /**
     * The map instance
     */
    mapInstance: GoogleMapsInstance;
}) => {
    const bounds = new mapsApi.LatLngBounds();

    mapsApi.event.addListenerOnce(
        mapInstance,
        constants.mapEvents.BOUNDS_CHANGED,
        () => {
            mapInstance.setZoom(constants.zoneMap.DEFAULT_ZOOM);
        }
    );
    bounds.extend(location);
    mapInstance.fitBounds(bounds);
};

export const getZonesCoordinates = (listOfZones: ZoneObject[] = []) => {
    return listOfZones.flatMap((zone) => {
        const {
            geometry: { coordinates }
        } = zone;

        return (coordinates || []).flatMap((listOfLatLng) => listOfLatLng);
    });
};

export const centerZonesOnMap = ({
    listOfCoordinates,
    mapsApi,
    mapInstance
}: {
    /**
     * The list of coordinates
     */
    listOfCoordinates: number[][];
    /**
     * The maps api
     */
    mapsApi: GoogleMapsApi;
    /**
     * The map instance
     */
    mapInstance: GoogleMapsInstance;
}) => {
    const bounds = new mapsApi.LatLngBounds();

    listOfCoordinates.forEach(([lng, lat]) => {
        bounds.extend({ lng, lat });
    });
    mapInstance.fitBounds(bounds);
};
