import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const availableDriversIdSlice = createSlice({
    name: 'availableDriversId',
    initialState: null,
    reducers: {
        setAvailableDriversId: (state, action) => {
            state = action.payload;
            return state;
        },
        addAvailableDriverId: (state, action) => {
            if (!action.payload) return;
            if (!state) {
                state = {};
            }
            const newState = { ...state };
            newState[action.payload] = true;
            return newState;
        },
        removeAvailableDriverId: (state, action) => {
            const newState = { ...state };
            if (!newState[action.payload]) return;
            delete newState[action.payload];
            return newState;
        },
        resetAvailableDriversId: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const {
    setAvailableDriversId,
    addAvailableDriverId,
    removeAvailableDriverId,
    resetAvailableDriversId
} = availableDriversIdSlice.actions;

export const selectAvailableDriversId = (state) => state.availableDriversId;

export default availableDriversIdSlice.reducer;
