import { useCallback } from 'react';
import { ApiTask } from '~/api/types';
import {
    ActionTypes,
    AssignmentTypes,
    DuplicateTaskModalFormProps,
    ItemTypes
} from '../../types';
import { useSplitTaskByInvoice } from './useSplitTaskByInvoice';

interface UseDuplicateTaskModalContextProviderProps {
    apiTask?: ApiTask;
    onClose: () => void;
}

export const useDuplicateTaskModalContextProvider = ({
    apiTask,
    onClose
}: UseDuplicateTaskModalContextProviderProps) => {
    const { splitTaskByInvoice } = useSplitTaskByInvoice();

    const onSubmit = useCallback(
        async (values: DuplicateTaskModalFormProps) => {
            if (!apiTask) return;

            const isSplitTaskByInvoice =
                values.itemType === ItemTypes.INVOICE &&
                values.actionType === ActionTypes.MOVE_INVOICE_OR_INVENTORY &&
                values.assignmentType === AssignmentTypes.CREATE_TASK;

            if (isSplitTaskByInvoice) {
                const { invoicesIds: selectedInvoicesIds } = values;
                await splitTaskByInvoice({
                    apiTask,
                    onClose,
                    selectedInvoicesIds
                });
            }
        },
        [apiTask, onClose, splitTaskByInvoice]
    );

    return {
        onSubmit
    };
};
