import { useState } from 'react';

type Props = {
    initialActiveItems?: string[];
};

export const useActiveItems = ({
    initialActiveItems = []
}: Props | undefined = {}): {
    activeItems: string[];
    onItemClick: (item: string) => void;
} => {
    const [activeItems, setActiveItems] = useState<string[]>(
        initialActiveItems || []
    );
    const onItemClick = (itemId: string) => {
        setActiveItems((activeIds: string[]) => {
            if (activeIds.find((id) => id === itemId)) {
                activeIds = activeIds.filter((id) => id !== itemId);
            } else {
                activeIds = [...activeIds, itemId];
            }
            return activeIds;
        });
    };

    return {
        activeItems,
        onItemClick
    };
};
