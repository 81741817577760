import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const toastsSlice = createSlice({
    name: 'toasts',
    initialState: [],
    reducers: {
        addToast: (state, action) => {
            state.push(action.payload);
        },
        resetToasts: (state) => {
            state.splice(0, state.length);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const { addToast, resetToasts } = toastsSlice.actions;
export const selectToasts = (state) => state.toasts;
export default toastsSlice.reducer;
