import axios, { AxiosResponse } from 'axios';
import {
    ApiUploadFileUrlResponse,
    ApiUploadHistoryResponse,
    UploadType
} from '../types';

interface GetUploadsProps {
    type?: UploadType;
    page?: number;
    limit?: number;
}

export default class UploadHistoryApi {
    private static readonly path = '/uphistory';

    static getUploads({
        limit,
        page,
        type
    }: GetUploadsProps): Promise<AxiosResponse<ApiUploadHistoryResponse>> {
        const searchParams = new URLSearchParams();

        if (page === 0 || (page && Number.isFinite(page))) {
            searchParams.set('page', page.toString());
        }

        if (limit) {
            searchParams.set('limit', limit.toString());
        }

        if (type) {
            searchParams.set('type', type);
        }

        const url = [this.path, searchParams.toString()].join('?');

        return axios.get(url);
    }

    static getUploadFileUrl({
        id
    }: {
        id: string;
    }): Promise<AxiosResponse<ApiUploadFileUrlResponse>> {
        return axios.get(`${this.path}/${id}/url`);
    }
}
