import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectShowDriverLines,
    setShowDriverLines
} from '~/reducers/mapSettingsSlice';

import { MapSettingMenuItem } from '~/ui';

import './map-settings-menu-sections.scss';

export default function MapSettingMenuRouteLinesSection({
    mapRouteMode,
    showHeader
}) {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const showDriverLinesState = useSelector(
        selectShowDriverLines(mapRouteMode)
    );

    const onDriverLinesToggleStateChange = useCallback(
        (checked) => {
            dispatch(
                setShowDriverLines({ mode: mapRouteMode, value: checked })
            );
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [mapRouteMode]
    );

    return (
        <div data-testid="map-settings-route-lines">
            {showHeader && (
                <span className="mapsettingssection_title">
                    {t('route_one')}
                </span>
            )}
            <MapSettingMenuItem
                iconName="routeLines"
                iconColor="galaxy-500"
                text={t('routeLines')}
                checkedState={showDriverLinesState}
                onChangeState={onDriverLinesToggleStateChange}
            />
        </div>
    );
}
