import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

import { RoutePlanSummaryMetricsState } from './types';

const defaultMetricsState = {
    numRoutes: 0,
    numTasks: 0,
    numUnplannedTasks: 0,
    numUsedDrivers: 0,
    numTotalDrivers: 0,
    numUnits: 0,
    numViolatedTW: 0,
    duration: 0,
    totalMeters: 0
};

export const routePlanSummaryMetricsSlice = createSlice({
    name: 'routePlanSummaryMetrics',
    initialState: { ...defaultMetricsState },
    reducers: {
        setRoutePlanSummaryMetrics: (state, action) => {
            return action.payload;
        },
        resetRoutePlanSummaryMetrics: () => {
            return { ...defaultMetricsState };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return { ...defaultMetricsState };
        });
    }
});

export const { setRoutePlanSummaryMetrics, resetRoutePlanSummaryMetrics } =
    routePlanSummaryMetricsSlice.actions;

export const selectRoutePlanSummaryMetrics = (
    state: RootState
): RoutePlanSummaryMetricsState => state.routePlanSummaryMetrics;

export default routePlanSummaryMetricsSlice.reducer;
