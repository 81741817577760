import { LiveStop } from '~/data-classes';
/**
 * Map Mode Utilities, Dispatched Routes Mode
 *
 * @category Utils
 * @module utils/map/map-livestop-utils
 *
 * @example
 * import { mapPlanStopUtils } from '~/utils/map/map-livestop-utils';
 */

/**
 * Get stops that can have a break inserted after
 * @param {Object[]} liveStopsData - array of live stops
 * @returns {LiveStop[]} An array of live stops that can have a break inserted after.
 * */
function filterLiveStopsWithoutBreak(liveStopsData) {
    const liveStops = liveStopsData.map((stop) => {
        return new LiveStop(stop);
    });

    return liveStops.filter((stop, i, arr) => {
        const isBeforeDepot = arr[i + 1]?.isDepot;
        const isWithBreak = Boolean(arr[i + 1]?.breakDetails);
        return !stop.isDepot && !isWithBreak && !isBeforeDepot;
    });
}

export const mapLiveStopUtils = {
    filterLiveStopsWithoutBreak
};
