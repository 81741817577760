import React from 'react';

import { RouteCardMetric } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';

import { RouteCardMetrics } from '../RouteCardMetric/types';

import './routecard-summary.scss';

interface RouteCardSummaryProps {
    className?: string;
    metrics: RouteCardMetrics[];
    children?: React.ReactNode;
    icon?: IconName;
    iconColor?: string;
    'data-testid'?: string;
}

function RouteCardSummary({
    className,
    metrics,
    ...extra
}: RouteCardSummaryProps) {
    function getClassName() {
        let boxClassName = 'routecard-summary';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function getMetrics() {
        return metrics.map((item, idx) => {
            const {
                metric,
                value,
                maxValue,
                icon,
                iconSize,
                iconColor,
                icons,
                tooltipText,
                isOverCapacity,
                valuesWithUnits,
                className: itemClassName
            } = item;
            const key = `${metric}-${idx}`;

            return (
                <RouteCardMetric
                    key={key}
                    className={itemClassName}
                    metric={metric}
                    value={value}
                    maxValue={maxValue}
                    showMaxValue
                    icon={icon as IconName}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    icons={icons}
                    tooltipText={tooltipText}
                    isOverCapacity={isOverCapacity}
                    valuesWithUnits={valuesWithUnits}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-metric`
                            : 'routecard-summary-metric'
                    }
                />
            );
        });
    }

    if (!metrics?.length) return null;

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-summary'}
        >
            {getMetrics()}
        </div>
    );
}

export default RouteCardSummary;
