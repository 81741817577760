import { useEffect, useState, useMemo } from 'react';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import { PlanStop } from '~/data-classes';

import { usePlanMapPropsContext } from '../PlanMapPropsContext';
import {
    getRoutePlanStopEffects,
    getScheduleClientRouteId,
    scheduleHasMultipleTrips
} from './utils';

import {
    checkShouldFilterOutRoute,
    checkIfRouteIsSelected
} from '~/components/MapPage/PlanMap/utils';
import { useSelector } from 'react-redux';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { splitScheduleByTrips } from '~/utils/plan-route-utils';

type Props = {
    getParentClientRouteId: (clientRouteId: string) => string | undefined;
    clientRouteIdsForSelectedMapRoutes: string[];
};

type ReturnValue = {
    routePlanStopEffects: PlanStop[][];
    visibleOnMapPlanStops: PlanStop[][];
};

export const useRoutePlanStopEffects = ({
    getParentClientRouteId,
    clientRouteIdsForSelectedMapRoutes
}: Props): ReturnValue => {
    const { isStopsClustersMode } = useMapUtils();
    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const hiddenRoutes = useSelector(selectHiddenRoutes);
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);

    const { planStops } = usePlanMapPropsContext();
    const [routePlanStopEffects, setRoutePlanStopEffects] = useState<
        PlanStop[][]
    >([]);

    useEffect(() => {
        if (!isStopsClustersMode) {
            if (routePlanStopEffects.length > 0) {
                setRoutePlanStopEffects([]);
            }
            return;
        }

        const newRoutePlanStopEffects = getRoutePlanStopEffects({
            getParentClientRouteId,
            planStops
        });

        setRoutePlanStopEffects(newRoutePlanStopEffects);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getParentClientRouteId, isStopsClustersMode, planStops]);

    // @TODO extract into a separate hook https://wisesys.atlassian.net/browse/RP-822
    const visibleOnMapPlanStops = useMemo(() => {
        const visibleOnMap: PlanStop[][] = [];

        for (const planStopRoute of routePlanStopEffects) {
            const splitSchedules = splitScheduleByTrips(planStopRoute);
            if (scheduleHasMultipleTrips(splitSchedules)) {
                const parentClientRouteId =
                    getScheduleClientRouteId(splitSchedules);

                if (!parentClientRouteId) {
                    continue;
                }
                const isParentRouteSelected = checkIfRouteIsSelected(
                    parentClientRouteId,
                    clientRouteIdsForSelectedMapRoutes,
                    selectedDrawerRouteId
                );

                if (!isParentRouteSelected) {
                    continue;
                }
                splitSchedules.forEach((schedule: PlanStop[]) => {
                    const { clientRouteId: tripClientRouteId } = schedule[0];
                    const isRouteHidden = hiddenRoutes[tripClientRouteId];
                    if (!isRouteHidden) {
                        visibleOnMap.push(schedule);
                    }
                });
            } else {
                const { clientRouteId } = planStopRoute[0];
                const shouldFilterOut = checkShouldFilterOutRoute(
                    clientRouteId,
                    hiddenRoutes,
                    clientRouteIdsForSelectedMapRoutes,
                    hasIsolatedRoutes,
                    selectedDrawerRouteId
                );
                if (!shouldFilterOut) {
                    visibleOnMap.push(planStopRoute);
                }
            }
        }

        return visibleOnMap;
    }, [
        routePlanStopEffects,
        hiddenRoutes,
        hasIsolatedRoutes,
        selectedDrawerRouteId,
        clientRouteIdsForSelectedMapRoutes
    ]);

    return { routePlanStopEffects, visibleOnMapPlanStops };
};
