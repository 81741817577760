import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetOnLogout } from '~/reducers/common-actions';

export const onDemandDispatchTaskCountSlice = createSlice({
    name: 'onDemandDispatchTaskCount',
    initialState: 0,
    reducers: {
        setOnDemandDispatchTaskCount: (state, action) => {
            const newState = action.payload;
            if (_.isInteger(newState) && newState >= 0) {
                return action.payload;
            }
            return state;
        },
        addOnDemandDispatchTask: (state) => {
            if (state >= Number.MAX_SAFE_INTEGER) {
                return Number.MAX_SAFE_INTEGER;
            }
            return ++state;
        },
        removeOnDemandDispatchTask: (state) => {
            if (state <= 0) {
                return state;
            }
            return --state;
        },
        resetOnDemandDispatchTaskCount: () => {
            return 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return 0;
        });
    }
});

export const {
    setOnDemandDispatchTaskCount,
    addOnDemandDispatchTask,
    removeOnDemandDispatchTask,
    resetOnDemandDispatchTaskCount
} = onDemandDispatchTaskCountSlice.actions;

export const selectOnDemandDispatchTaskCount = (state) =>
    state.onDemandDispatchTaskCount;

export default onDemandDispatchTaskCountSlice.reducer;
