import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditZonePolygonsSetting } from './EditZonePolygonsSetting';
import { ShowZonePolygonsSetting } from './ShowZonePolygonsSetting';
import constants from '~/utils/constants';

export default function MapSettingMenuRoutesSection({ mapRouteMode }) {
    const { t } = useTranslation(['translation']);

    return (
        <>
            <span
                className="mapsettingssection_title"
                data-testid="zones-settings-section-title"
            >
                {t('zone')}
            </span>
            <ShowZonePolygonsSetting mapRouteMode={mapRouteMode} />
            {mapRouteMode === constants.mapRouteModes.PLAN && (
                <EditZonePolygonsSetting mapRouteMode={mapRouteMode} />
            )}
        </>
    );
}
