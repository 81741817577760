import _ from 'lodash';
import {
    CustomCostMatrixAttributeType,
    CustomCostMatrixCalculationType,
    CustomCostMatrixTermType
} from '~/api/types';
import constants from '~/utils/constants';

/**
 * Custom Cost Matrix Utilities
 *
 * @category Utils
 * @module utils/customCostMatrixUtils
 *
 * @example
 * import { customCostMatrixUtils } from '~/utils/custom-cost-matrix-utils';
 */
const downloadFolder = constants.url.DOWNLOADS;
const downloadFilename = 'cost_configuration_template_v3.xlsx';
const downloadUrl = `${downloadFolder}/${downloadFilename}`;
const downloadFormulaFilename = 'cost_configuration_formula';

export const customCostMatrixConstants = {
    downloadFormulaFilename,
    downloadFilename,
    downloadUrl,
    zone: {
        NOZONE: 'NOZONE'
    },
    sheetName: {
        METADATA: 'metaData',
        ZONES: 'zones',
        ZONETOZONECOSTS: 'zoneToZoneCosts',
        VARIABLECOSTS: 'variableCosts'
    },
    metaDataProps: {
        NAME: 'name',
        SERVICE_NAME: 'service_name',
        SERVICE_COST: 'service_cost'
    },
    errorKeys: {
        ZONE_NAME_NOT_FOUND: 'zoneNameNotFound',
        CUSTOM_COST_TERM_NOT_VALID: 'customCostTermNotValid',
        CONSTANT_TERM_VALUE_NOT_VALID: 'constantTermValueNotValid',
        ATTRIBUTE_TERM_NOT_VALID: 'attributeTermNotValid',
        ATTRIBUTE_TERM_VALUES_NOT_VALID: 'attributeTermValuesNotValid',
        CALCULATION_TYPE_NOT_VALID: 'calculationTypeNotValid',
        META_DATA_NOT_VALID: 'metaDataNotValid'
    },
    fixKeys: {
        VALUE_MUST_BE_EXISTING_ZONE: 'valueMustBeExistingZone',
        VALUE_MUST_BE_NUMERIC: 'valueMustBeNumeric',
        VALUE_MUST_BE_NUMERIC_CSV: 'valueMustBeNumericCSV',
        VALUE_MUST_BE_STRING: 'valueMustBeString',
        CUSTOM_COST_TERM_MUST_BE_FROM_ALLOWED:
            'customCostTermMustBeFromAllowed',
        ATTRIBUTE_TERM_MUST_BE_FROM_ALLOWED: 'attributeTermMustBeFromAllowed',
        CALCULATION_TYPE_MUST_BE_FROM_ALLOWED:
            'calculationTypeMustBeFromAllowed',
        PROGRESSIVE_ATTRIBUTES_MUST_EQUAL: 'progressiveAttributesMustEqual',
        SERVICE_NAME_MUST_BE_STRING: 'serviceNameMustBeString',
        SERVICE_COST_MUST_BE_NUMERIC: 'serviceCostMustBeNumeric'
    }
};

/**
 * Determines whether a given zone ID represents `NOZONE` and returns a value meant for the entry payload
 * @param {string} zoneId the zone ID to test
 * @returns {string|null} the zone ID
 */
const getEntryZoneId = (zoneId: string): string | null => {
    // no zone ID needs to be explicitly set to `null` for the final payload
    return zoneId === customCostMatrixConstants.zone.NOZONE ? null : zoneId;
};

/**
 * Determines whether a custom cost term is constant
 * @param {string} customCostTerm the custom cost term to test
 * @returns {boolean} the result of the test
 */
const isCustomCostTermConstant = (customCostTerm: string): boolean => {
    return customCostTerm === CustomCostMatrixTermType.CONSTANT;
};

/**
 * Determines whether a custom cost term is stop
 * @param {string} customCostTerm the custom cost term to test
 * @returns {boolean} the result of the test
 */
const isCustomCostTermStop = (customCostTerm: string): boolean => {
    return customCostTerm === CustomCostMatrixTermType.STOP;
};

/**
 * Determines whether a custom cost term is trip
 * @param {string} customCostTerm the custom cost term to test
 * @returns {boolean} the result of the test
 */
const isCustomCostTermTrip = (customCostTerm: string): boolean => {
    return customCostTerm === CustomCostMatrixTermType.TRIP;
};

/**
 * Determines whether a custom cost matrix calculation type is progressive
 * @param {string} calculationType the calculation type to test
 * @returns {boolean} the result of the test
 */
const isProgressive = (calculationType: string): boolean => {
    return calculationType === CustomCostMatrixCalculationType.PROGRESSIVE;
};

/**
 * Determines whether a custom cost matrix attribute type is `trip_distance`
 * @param {string} attributeType the attribute type to test
 * @returns {boolean} the result of the test
 */
const isAttributeTypeTripDistance = (attributeType: string): boolean => {
    return attributeType === CustomCostMatrixAttributeType.TRIP_DISTANCE;
};

/**
 * Determines whether a custom cost matrix attribute type is `trip_weight`
 * @param {string} attributeType the attribute type to test
 * @returns {boolean} the result of the test
 */
const isAttributeTypeTripWeight = (attributeType: string): boolean => {
    return attributeType === CustomCostMatrixAttributeType.TRIP_WEIGHT;
};

/**
 * Determines whether a custom cost matrix attribute type is `wait_time`
 * @param {string} attributeType the attribute type to test
 * @returns {boolean} the result of the test
 */
const isAttributeTypeWaitTime = (attributeType: string): boolean => {
    return attributeType === CustomCostMatrixAttributeType.WAIT_TIME;
};

/**
 * Determines whether a custom cost matrix attribute type is valid
 * @param {string} attributeType the attribute type to test
 * @returns {boolean} the result of the test
 */
const isValidAttributeType = (attributeType: string): boolean => {
    const validTypes = Object.values(CustomCostMatrixAttributeType);
    const testResult = validTypes.includes(
        attributeType as CustomCostMatrixAttributeType
    );
    return testResult;
};

/**
 * Determines whether a custom cost matrix attribute value is valid
 * @param {string|number} attributeValues the attribute value to test
 * @returns {boolean} the result of the test
 */
const isValidAttributeValues = (attributeValues: string | number): boolean => {
    if (_.isString(attributeValues)) {
        const values = attributeValues.split(',');
        const isAllNumbers = values.every((item) => {
            return !_.isNaN(Number(item)) && Number(item) >= 0;
        });
        return isAllNumbers;
    }

    return _.isNumber(attributeValues) && attributeValues >= 0;
};

/**
 * Determines whether a custom cost matrix calculation type is valid
 * @param {string} calculationType the calculation type to test
 * @returns {boolean} the result of the test
 */
const isValidCalculationType = (calculationType: string): boolean => {
    const validTypes = Object.values(CustomCostMatrixCalculationType);
    const testResult = validTypes.includes(
        calculationType as CustomCostMatrixCalculationType
    );
    return testResult;
};

/**
 * Determines whether a custom cost matrix term type is valid
 * @param {string} customCostTerm the term type to test
 * @returns {boolean} the result of the test
 */
const isValidCustomCostTerm = (customCostTerm: string): boolean => {
    const validTypes = Object.values(CustomCostMatrixTermType);
    const testResult = validTypes.includes(
        customCostTerm as CustomCostMatrixTermType
    );
    return testResult;
};

/**
 * Determines whether a zone name used for a custom cost matrix is valid
 * @param {string} zoneName the zone name
 * @param {Record<string, unknown>} zoneData the zone data
 * @returns {boolean} the result of the test
 */
const isValidZone = (
    zoneName: string,
    zoneData: Record<string, unknown>
): boolean => {
    return Boolean(zoneData[zoneName]);
};

export const customCostMatrixUtils = {
    getEntryZoneId,
    isCustomCostTermConstant,
    isCustomCostTermStop,
    isCustomCostTermTrip,
    isProgressive,
    isAttributeTypeTripDistance,
    isAttributeTypeTripWeight,
    isAttributeTypeWaitTime,
    isValidAttributeType,
    isValidAttributeValues,
    isValidCalculationType,
    isValidCustomCostTerm,
    isValidZone
};
