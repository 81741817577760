import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';
import { useMapUtils } from '~/hooks';
import { DriverBreakRulesByStartTime, EditLiveBreakArgs } from '~/api/types';
import DriverApi from '~/api/DriverApi';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';

type ReturnProps = {
    addLiveBreak: (payload: DriverBreakRulesByStartTime) => Promise<void>;
    deleteLiveBreak: (assignmentId: string) => Promise<void>;
    editLiveBreak: (payload: EditLiveBreakArgs) => Promise<void>;
};

export const useLiveDriverBreak = (): ReturnProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation('driverBreak');
    const { isDispatchedRouteMode } = useMapUtils();
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const liveDriverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const driverId = liveDriverData?.id;
    const addLiveBreak = useCallback(
        async ({ startsAfter, duration }: DriverBreakRulesByStartTime) => {
            const processIndicatorState = {
                message: t('progress.AddingBreak'),
                inProgress: true,
                error: false,
                position: 'center',
                type: constants.processIndicator.ADD_BREAK
            };

            try {
                if (!isDispatchedRouteMode || !driverId) return;
                dispatch(addProcessIndicator(processIndicatorState));
                const breakRule = {
                    startsAfter,
                    duration
                } as DriverBreakRulesByStartTime;
                await DriverApi.addLiveBreak(driverId, breakRule);
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        [dispatch, driverId, t, isDispatchedRouteMode]
    );

    const deleteLiveBreak = useCallback(
        async (assignmentId: string) => {
            const processIndicatorState = {
                message: t('progress.DeletingBreak'),
                inProgress: true,
                error: false,
                position: 'center',
                type: constants.processIndicator.DELETE_BREAK
            };

            try {
                if (!isDispatchedRouteMode || !driverId) return;
                dispatch(addProcessIndicator(processIndicatorState));
                await DriverApi.deleteLiveBreak(driverId, assignmentId);
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        [dispatch, driverId, t, isDispatchedRouteMode]
    );

    const editLiveBreak = useCallback(
        async ({ assignmentId, startsAfter, duration }: EditLiveBreakArgs) => {
            const processIndicatorState = {
                message: t('progress.UpdatingBreakRules'),
                inProgress: true,
                error: false,
                position: 'center',
                type: constants.processIndicator.UPDATE_BREAK_RULES
            };

            try {
                if (!isDispatchedRouteMode || !driverId) return;
                dispatch(addProcessIndicator(processIndicatorState));
                await DriverApi.editLiveBreak(
                    driverId,
                    assignmentId,
                    startsAfter,
                    duration
                );
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [driverId, isDispatchedRouteMode]
    );

    return { addLiveBreak, deleteLiveBreak, editLiveBreak };
};
