import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import constants from '~/utils/constants';
import { useGetTaskInventoryItems } from '~/hooks';
import { InventoryItemsDetails } from '~/ui/components/InventoryItemsDetails';
import { ApiInventoryItem } from '~/api/types';
import {
    selectIsMarkerPopupInventoryItemsDetailsExpanded,
    setIsMarkerPopupInventoryItemsDetailsExpanded
} from '~/reducers/appGlobalsSlice';

interface MapMarkerPopupInventoryItemsDetailsProps {
    /**
     * Whether this is a pickup task
     */
    isPickup: boolean;
    /**
     * Whether the task is completed
     */
    isCompleted: boolean;
    /**
     * The number of inventory items
     */
    numberOfInventoryItems: number;
    /**
     * The task id
     */
    taskId?: string;
    /**
     * The inventory items
     */
    inventoryItems?: ApiInventoryItem[];
}

export const MapMarkerPopupInventoryItemsDetails = ({
    numberOfInventoryItems,
    isCompleted,
    isPickup,
    taskId,
    inventoryItems
}: MapMarkerPopupInventoryItemsDetailsProps) => {
    const dispatch = useDispatch();
    const { taskInventoryItems, isLoading } = useGetTaskInventoryItems({
        taskId,
        isDisabled: Boolean(inventoryItems)
    });

    const filteredInventoryItems = useMemo(
        () =>
            (inventoryItems ?? taskInventoryItems).filter((item) =>
                isPickup
                    ? item.type === constants.taskTypes.PICKUP
                    : item.type === constants.taskTypes.DELIVERY
            ),
        [inventoryItems, isPickup, taskInventoryItems]
    );

    const isMarkerPopupInventoryItemsDetailsExpanded = useSelector(
        selectIsMarkerPopupInventoryItemsDetailsExpanded
    );

    const onToggle = () => {
        dispatch(
            setIsMarkerPopupInventoryItemsDetailsExpanded(
                !isMarkerPopupInventoryItemsDetailsExpanded
            )
        );
    };

    return (
        <InventoryItemsDetails
            inventoryItems={filteredInventoryItems}
            isCompleted={isCompleted}
            inventoryItemsCount={numberOfInventoryItems}
            isLoading={isLoading}
            isInitiallyOpened={isMarkerPopupInventoryItemsDetailsExpanded}
            onToggle={onToggle}
        />
    );
};
