/**
 * Enum of supported tooltip button sizes
 */
export enum TooltipButtonSizes {
    DEFAULT = 'default',
    SMALL = 's',
    MEDIUM = 'm',
    LARGE = 'l'
}

export type TooltipButtonSize = `${TooltipButtonSizes}`;
