import React from 'react';
import { useTranslation } from 'react-i18next';
import { StopModalMenuButton } from '~/ui';
import { useDuplicateTaskModal } from './useDuplicateTaskModal';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useFeatureFlag } from '~/hooks';

interface DuplicateTaskButtonProps {
    taskId: string;
}

export const DuplicateTaskButton = ({ taskId }: DuplicateTaskButtonProps) => {
    const { t } = useTranslation('translation');
    const { duplicateTaskModal, onShowModal } = useDuplicateTaskModal();
    const isFeatureFlagEnabled = useFeatureFlag(FeatureFlag.SPLIT_TASK);

    if (!isFeatureFlagEnabled) return null;

    return (
        <>
            <StopModalMenuButton
                onClick={() => onShowModal(taskId)}
                buttonIcon="addLocationAlt"
                buttonText={t('PlanStopsControl.button.duplicateTask')}
            />
            {duplicateTaskModal}
        </>
    );
};
