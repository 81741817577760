import { useQuery, UseQueryOptions } from 'react-query';
import RoutePlansApi, { GetRoutePlansParams } from '~/api/RoutePlansApi';
import { ApiRoutePlan } from '~/api/types';

import dateUtils from '~/utils/date-utils';

interface UseGetRoutePlansProps
    extends Omit<GetRoutePlansParams, 'client_ids' | 'start_from'> {
    /**
     * The client IDs for the routes
     *
     * This should be the stringified version of a client ids array
     *
     * @example
     * clientIds: clientIdsArr.join(',')
     */
    clientIds?: GetRoutePlansParams['client_ids'];

    /**
     * The route date to start from in ISO format
     */
    routeDate?: GetRoutePlansParams['start_from'];
}

type UseGetRoutePlansReactQueryOptions = Omit<
    UseQueryOptions<ApiRoutePlan[], Error>,
    'queryKey' | 'queryFn'
>;

const REACT_QUERY_KEY = 'routeplans';

export const useGetRoutePlans = (
    {
        clientIds,
        routeDate,
        active = true,
        include
    }: UseGetRoutePlansProps = {},
    reactQueryOptions: UseGetRoutePlansReactQueryOptions = {}
) => {
    const startFromDate =
        (routeDate && dateUtils.convertToISODateOnly(routeDate)) || undefined;

    const fetchRoutePlans = async (
        options: GetRoutePlansParams = {}
    ): Promise<ApiRoutePlan[]> => {
        const {
            data: { data }
        } = await RoutePlansApi.get(options);
        return data;
    };

    // do not run when the start date is not valid
    const defaultReactQueryOptions = {
        enabled: Boolean(startFromDate)
    };

    // merge query options
    const mergedOptions: UseGetRoutePlansReactQueryOptions = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // api options
    const apiOptions: GetRoutePlansParams = {
        client_ids: clientIds,
        start_from: startFromDate,
        active,
        include
    };

    // return the full useQuery Object
    return useQuery<ApiRoutePlan[], Error>(
        [REACT_QUERY_KEY, clientIds, startFromDate, active, include],
        () => fetchRoutePlans(apiOptions),
        mergedOptions
    );
};
