import React from 'react';

import { CompartmentLabel } from '~/ui';
import './CompartmentDetails.scss';

type CompartmentDetailsProps = {
    label: string;
    size: number;
    unit: string;
};

const CompartmentDetails = ({
    label,
    size,
    unit
}: CompartmentDetailsProps): JSX.Element => {
    return (
        <div
            className="_d-flex compartment-details"
            data-testid="compartment-details"
        >
            <CompartmentLabel
                label={label}
                className="compartment-details__label"
            />
            <span className="compartment-details__summary">{`${Math.floor(
                size
            )} ${unit}`}</span>
        </div>
    );
};

export default CompartmentDetails;
