import React from 'react';

interface SelectFileProps {
    /**
     * The file input id
     */
    fileInputId: string;
    /**
     * When files are selected
     */
    onFilesSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * The text to display
     */
    text: string;
}

export const SelectFile = ({
    fileInputId,
    onFilesSelected,
    text
}: SelectFileProps) => {
    return (
        <label
            htmlFor={fileInputId}
            data-testid="file-input-label"
            className="buttonlink"
        >
            {text}
            <input
                id={fileInputId}
                type="file"
                accept=".csv, .xls, .xlsx, .xml"
                onChange={onFilesSelected}
                data-testid="file-input"
                hidden
            />
        </label>
    );
};
