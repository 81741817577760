import React from 'react';

import { Icon } from '~/ui';

export const ModalHeader = ({
    className,
    onClose,
    title,
    iconColor = 'galaxy-800'
}) => {
    return (
        <div className={className}>
            <Icon
                mx="0"
                icon="chevronDown"
                color={iconColor}
                onClick={onClose}
            />
            <span className="_text-2">{title}</span>
        </div>
    );
};
