import { CompartmentInput } from '~/hooks/useMultiCompartmentInputs';
import { v4 as uuid } from 'uuid';
import {
    StopCompartmentDetail,
    TaskCompartmentDetail,
    CompartmentType
} from '~/api/types/CommonTypes';
import { ApiCompartmentTypes } from '~/api/types';

type Compartment = {
    compartmentType: string;
    [unitName: string]: string | number;
};

/**
 * Replaces special characters and white spaces for a given value
 * @type {Function}
 */
export const replaceSpecialCharacters = (value: string) => {
    /**
     * Matches special characters and white spaces
     * @type {RexExp}
     */
    const specialCharactersRegexPattern = /([^\w]|_|\s)/g;
    const replacesWith = '-';

    return value.replace(specialCharactersRegexPattern, replacesWith);
};

/**
 * Initialize MultiCompartmentInput
 */
export const initializeCompartmentInput = () => {
    const firstComponentId = uuid();
    return {
        [firstComponentId]: {
            componentId: firstComponentId,
            selectedCompartmentId: '',
            capacity: '',
            label: '',
            unit: '',
            isFirst: true
        }
    };
};

/**
 * Checks if the compartment is already part of the list and return index
 * @param formattedCompartmentList
 * @param compartmentInput
 * @returns {Number} index
 */
const getIndexOfSameCompartment = (
    formattedCompartmentList: Compartment[],
    compartmentInput: CompartmentInput
) => {
    return formattedCompartmentList.findIndex(
        (compartment) =>
            compartment.compartmentType ===
            compartmentInput.selectedCompartmentId
    );
};

/**
 * Sum the capacities
 * @param firstCapacity
 * @param secondCapacity
 * @returns {Number} total capacity
 */
const sumCapacities = (
    firstCapacity: string | number,
    secondCapacity: string | number
) => {
    return Number(firstCapacity) + Number(secondCapacity);
};

/**
 * Format the compartments for the API
 * @param compartmentInputs
 * @param capacityName - name of the capacity unit (default is: 'capacity')
 * @returns array of compartment id with capacity
 */
export const formatCompartmentList = (
    compartmentInputs: Record<string, CompartmentInput>,
    capacityName = 'capacity'
) => {
    return Object.values(compartmentInputs).reduce(
        (compartmentList: Compartment[], compartmentInput) => {
            const existingCompartmentIndex = getIndexOfSameCompartment(
                compartmentList,
                compartmentInput
            );

            // if compartmentType is already in the list, sum capacities instead of adding to the list
            if (existingCompartmentIndex > -1) {
                const currentCapacity =
                    compartmentList[existingCompartmentIndex][capacityName];

                compartmentList[existingCompartmentIndex][capacityName] =
                    sumCapacities(currentCapacity, compartmentInput.capacity);

                return compartmentList;
            }
            return [
                ...compartmentList,
                {
                    compartmentType: compartmentInput.selectedCompartmentId,
                    [capacityName]: Number(compartmentInput.capacity)
                }
            ];
        },
        []
    );
};

export const parseCompartmentDetails = (
    sizeByCompartment: StopCompartmentDetail | TaskCompartmentDetail
): StopCompartmentDetail => {
    const { compartmentType } = sizeByCompartment as TaskCompartmentDetail;
    if (compartmentType) {
        const { id, label, unit } = compartmentType as CompartmentType;
        const { size } = sizeByCompartment;
        return { id, label, unit, size };
    }
    const { id, label, unit, size } =
        sizeByCompartment as StopCompartmentDetail;
    return { id, label, unit, size };
};

export const parseDefaultCompartmentDetails = (
    sizeByCompartment: (StopCompartmentDetail | TaskCompartmentDetail)[]
): StopCompartmentDetail | undefined => {
    const defaultCompartmentLabel = 'default';
    return sizeByCompartment
        .map(parseCompartmentDetails)
        .find(({ label }) => label === defaultCompartmentLabel);
};

/**
 * Map task's sizeByCompartment for display,
 * adds compartment details if not provided and replaces 'size' with 'capacity'
 * @param sizeByCompartment
 * @param compartmentTypesById
 */
export const mapSizeByCompartment = (
    sizeByCompartment: TaskCompartmentDetail[],
    compartmentTypesById: Record<string, ApiCompartmentTypes>
) => {
    return sizeByCompartment.map((compartment) => {
        const { compartmentType, size } = compartment;

        // when compartmentType contains ID only, get compartment details
        if (typeof compartmentType === 'string') {
            return {
                ...compartmentTypesById[compartmentType],
                capacity: size
            };
        }

        // when compartmentType contains compartment object
        return { ...compartmentType, capacity: size };
    });
};
