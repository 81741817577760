import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from '~/hooks';

import timeUtils from '~/utils/time-utils';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { getCurrentTime } from '~/components/AddTaskModal/utils/addTaskModalUtils';

type TimeType = 'start' | 'end';

export const useMainClientTimeAdjustment = () => {
    const mainClient = useSelector(selectMainClient);
    const { timezone: timeZone } = mainClient || {};
    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );

    const getClientAdjustedTime = useCallback(
        (timeType: TimeType, type: string, isTwoPart = false) => {
            const baseTime = new Date(
                getCurrentTime({ type: timeType, taskType: type, isTwoPart })
            );
            const offsetInMinutes = isClientTimezoneFlagEnabled
                ? timeUtils.getTimezoneOffset({ timeZone })
                : 0;
            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
            const uDate = new Date(baseTime.getTime() + offsetInMilliseconds);
            return uDate;
        },
        [timeZone, isClientTimezoneFlagEnabled]
    );

    return { getClientAdjustedTime };
};
