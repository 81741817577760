import axios from 'axios';
import dateUtils from '~/utils/date-utils';
import { TasksApiErrors } from '../TasksApi';
import { ApiTask, AxiosApiResponse, TasksV2PaginationMetadata } from '../types';
import { GetTasksV2Config, GetTasksV2Params } from './types';
import { getSortParam } from './utils';

/**
 * The maximum number of fields/columns we can sort the getTasks() results by
 */
const GET_TASKS_MAX_SORT_FIELDS = 5;

/**
 * Implementations of API methods under the `/tasks/v2` paths
 *
 * @category API
 */
export default class TasksV2Api {
    /**
     * Path of the multi-task api endpoint
     */
    private static readonly tasksPath = '/tasks/v2';

    /**
     * Sends a GET /tasks/v2 request with the provided params
     */
    static get(
        params: GetTasksV2Params = {
            page: 0,
            limit: 1,
            date: undefined,
            status: undefined
        }
    ): Promise<AxiosApiResponse<ApiTask[], TasksV2PaginationMetadata>> {
        const { date, sort } = params;

        if ((sort ?? []).length > GET_TASKS_MAX_SORT_FIELDS) {
            return Promise.reject(TasksApiErrors.MAX_SORT_FIELDS);
        }
        const convertedDate = date
            ? dateUtils.convertToISODateOnly(date)
            : undefined;

        const [sortParam] = getSortParam(sort) || [];
        const { id: sortId, order: sortOrder } = sortParam || {};

        const config: GetTasksV2Config = {
            params: {
                ...params,
                date: convertedDate || undefined,
                sort: sortId,
                order: sortOrder
            }
        };

        return axios.get(this.tasksPath, config);
    }
}
