import { useDispatch } from 'react-redux';
import { setMapMarkerMode } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { useIsolatedRoutes } from '~/hooks';
import { pageUtils } from '~/utils/page-utils';
import { useFitMapToBounds } from '~/components/MapPage/useFitMapToBounds';

export const useSelectStopsView = () => {
    const dispatch = useDispatch();
    const { isolateMultipleRoutes } = useIsolatedRoutes();
    const { setShouldFitToBounds } = useFitMapToBounds();

    const selectStopsView = () => {
        pageUtils.resetSelectedDrawerCard();
        isolateMultipleRoutes();
        setShouldFitToBounds(true);
        dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
    };

    return {
        selectStopsView
    };
};
