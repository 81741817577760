import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { selectActiveClientIds } from '~/reducers/activeClientsSlice';
import { selectSelectedClientIds } from '~/reducers/selectedClientsSlice';

import { WebMultiClientApi } from '~/api/WebMultiClientApi';
import { useMapUtils } from '../useMapUtils';
import constants from '~/utils/constants';

/**
 * Implements a heartbeat using the WebMultiClientApi
 *
 * + When in `dispatch` map routes mode, performs a call to `WebMultiClientApi` every minute
 *   which triggers several socket messages to be received.
 * + For all other map route modes, performs 1 call on load to allow search functionality
 *
 * @category Hooks
 * @module useWebInterval
 * @param { Omit<UseQueryOptions, 'queryKey' | 'queryFn'>} reactQueryOptions - valid React Query `useQuery` options
 * @returns {UseQueryResult}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useWebInterval } from '~/hooks';
 *
 * // initialize the heartbeat
 * useWebInterval();
 *
 * // initialize with options
 * const options = {
 *     refetchInterval: 10000 // refresh every 10 secs
 * };
 * useWebInterval(options);
 *
 * // trigger a manually
 * const { refetch } = usewebInterval();
 * refetch();
 *
 * @see [React Query v3, useQuery]{@link https://react-query-v3.tanstack.com/reference/useQuery}
 */

const REACT_QUERY_KEY = 'web_multiclient';

export const useWebInterval = (
    reactQueryOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {}
): UseQueryResult => {
    const { isDispatchedRouteMode, mapRouteMode } = useMapUtils();

    /**
     * represents clients connected to the socket
     */
    const activeClientIds = useSelector(selectActiveClientIds);

    /**
     * represents clients as selected by the user
     */
    const selectedClientIds = useSelector(selectSelectedClientIds);

    /**
     * get API response
     */
    const webMultiClient = async (clientIds: string[]) => {
        // clientIds may become empty when switching clients, in a multiclient account
        // this will prevent an unnecessary multiclient call without the `clientIds` params
        if (!clientIds.length) return;

        const { data } = await WebMultiClientApi.get(clientIds);
        return data;
    };

    /**
     * refretches every minute for dispatch mode, otherwise fires once
     */
    const defaultReactQueryOptions = {
        refetchInterval:
            isDispatchedRouteMode && constants.timings.GET_WEB_INTERVAL
    };

    /**
     * merge query options
     */
    const mergedOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    /**
     * represents selected clients connected to the socket
     */
    const selectedActiveClientIds = useMemo(() => {
        return activeClientIds.filter((clientId) =>
            selectedClientIds.includes(clientId)
        );
    }, [activeClientIds, selectedClientIds]);

    return useQuery(
        [REACT_QUERY_KEY, selectedActiveClientIds, mapRouteMode],
        () => webMultiClient(selectedActiveClientIds),
        mergedOptions
    );
};
