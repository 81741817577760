import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetOperationalStopTask,
    selectIsOpenOperationalStopModal,
    setIsOpenOperationalStopModal
} from '~/reducers/addOperationalStopsSlice';
import { clearCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';
import { TooltipButton, useModal } from '~/ui';
import { AddOperationalStopDataContent } from './AddOperationalStopDataContent';
import { useInsertOperationalStopClick } from './useInsertOperationalStopClick';

import './addOperationalStopModal.scss';

export const testId = 'add-operational-stop-modal';

export const addressTabDataTestId = 'addOperationalStopModal_addressTabTestId';
export const taskInformationTabTestId =
    'addOperationalStopModal_taskInformationTabTestId';
export const inventoryTabTestId = 'addOperationalStopModal_inventoryTabTestId';
export const notesTabTestId = 'addOperationalStopModal_notesTabTestId';

export const cancelButtonTestId = 'addOperationalStopModal_cancelButtonTestId';
export const nextButtonTestId = 'addOperationalStopModal_nextButtonTestId';
export const saveAndCloseButtonTestId =
    'addOperationalStopModal_saveAndCloseButtonTestId';
const className = 'add-operational-stop-modal';

export const AddOperationalStopModal: React.FC = () => {
    const { t } = useTranslation('addOperationalStop');
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectIsOpenOperationalStopModal);
    const { onInsertOperationalStopClick, isInvalidForm } =
        useInsertOperationalStopClick();
    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onShow: () => undefined,
        onHide: () => {
            dispatch(clearCustomerSearchResults());
            dispatch(setIsOpenOperationalStopModal(false));
        }
    });

    // hideModal and ShowModal are looping infinitely if we define exhaustive deps
    useEffect(() => {
        if (isModalOpen) {
            showModal();
        } else {
            hideModal();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isModalOpen]);

    const onCancelClick = useCallback(() => {
        dispatch(resetOperationalStopTask());
        hideModal();
    }, [hideModal, dispatch]);

    const CancelButton = () => {
        return (
            <TooltipButton
                className={`${className}__cancel`}
                variant={TooltipButton.variants.SECONDARY}
                size={TooltipButton.sizes.LARGE}
                data-testid={cancelButtonTestId}
                onClick={onCancelClick}
            >
                {t('footer.cancel')}
            </TooltipButton>
        );
    };

    const InsertOperationalStop = () => {
        return (
            <TooltipButton
                className={`${className}__save`}
                variant={TooltipButton.variants.PRIMARY}
                size={TooltipButton.sizes.LARGE}
                data-testid={saveAndCloseButtonTestId}
                onClick={onInsertOperationalStopClick}
                disabled={isInvalidForm}
            >
                {t('footer.insertStop')}
            </TooltipButton>
        );
    };

    const Footer = () => {
        return (
            <div className="_d-flex _jc-flex-end _cg-4">
                <CancelButton />
                <InsertOperationalStop />
            </div>
        );
    };

    return (
        <Modal
            title={t('header.title')}
            hasCloseButton
            hasAutoHide={false}
            className={className}
            data-testid={testId}
        >
            <div className={`${className}__content`}>
                <AddOperationalStopDataContent />
            </div>
            <div className={`${className}__footer`}>
                <Footer />
            </div>
        </Modal>
    );
};
