import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectedIsClusteringUnassignedTasks,
    setIsClusteringUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { MapSettingMenuItem } from '~/ui';
import { MapSettingMenuUnassignedTasksSectionProps } from './types';

export const MapSettingMenuUnassignedTasksSection = ({
    mapRouteMode,
    isShowHeader
}: MapSettingMenuUnassignedTasksSectionProps) => {
    const { t } = useTranslation(['translation']);

    const dispatch = useDispatch();

    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(mapRouteMode)
    );

    const onClusterModeStateChange = useCallback(
        (checked) => {
            dispatch(
                setIsClusteringUnassignedTasks({
                    mode: mapRouteMode,
                    value: checked
                })
            );
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [mapRouteMode]
    );

    const mapSettingMenuItemProps = {
        iconName: 'stopClustering',
        text: t('unassignedTasksClusters'),
        checkedState: isClusteringUnassignedTasks,
        onChangeState: onClusterModeStateChange
    };

    return (
        <div data-testid="map-settings-unassigned-tasks-marker">
            {isShowHeader && (
                <span
                    className="mapsettingssection_title"
                    data-testid="unassigned-tasks-cluster-mode-text"
                >
                    {t('unassignedTasks')}
                </span>
            )}
            <MapSettingMenuItem {...mapSettingMenuItemProps} />
        </div>
    );
};
