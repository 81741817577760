import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import { MapDrawerSettingsState } from './types';
import type { RootState } from '~/store';

/**
 * Redux slice for various map drawer-related settings
 * @category Reducers
 * @module mapSettingsSlice
 *
 * @todo move some drawer-related settings in `mapSettingsSlice` to this one
 */

/** @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction */

/**
 * default slice state
 */
export const defaultDrawerSettings: MapDrawerSettingsState = {
    isLiveDispatchDrawerExpanded: false,
    isOpenDispatchManagementPopup: false,
    isOpenLiveDispatchDrawer: false,
    isOpenSelectedCardsDrawer: false,
    isOpenSuggestDrawer: false,
    isOpenUnassignedTasksDrawer: false,
    isUnassignedTasksTabActive: true,
    isOpenPairEquipmentControlPopup: false,
    isEquipmentDepotPairing: false
};

export const mapDrawerSettingsSlice = createSlice({
    name: 'mapDrawerSettings',
    initialState: defaultDrawerSettings,
    reducers: {
        setIsOpenUnassignedTasksDrawer: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isOpenUnassignedTasksDrawer: action.payload };
        },

        setIsOpenSelectedCardsDrawer: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isOpenSelectedCardsDrawer: action.payload };
        },

        setIsOpenDispatchManagementPopup: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isOpenDispatchManagementPopup: action.payload };
        },

        setIsOpenPairEquipmentControlPopup: (state, action) => {
            const isPayloadValid = _.isBoolean(action.payload);
            if (!isPayloadValid) {
                return state;
            }
            return {
                ...state,
                isOpenPairEquipmentControlPopup: action.payload
            };
        },

        setIsEquipmentDepotPairing: (state, action) => {
            const isPayloadValid = _.isBoolean(action.payload);
            if (!isPayloadValid) {
                return state;
            }
            return {
                ...state,
                isEquipmentDepotPairing: action.payload
            };
        },

        setIsOpenLiveDispatchDrawer: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isOpenLiveDispatchDrawer: action.payload };
        },

        setIsLiveDispatchDrawerExpanded: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isLiveDispatchDrawerExpanded: action.payload };
        },

        setIsUnassignedTasksTabActive: (
            state,
            action: PayloadAction<boolean>
        ) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isUnassignedTasksTabActive: action.payload };
        },

        setIsOpenSuggestDrawer: (state, action: PayloadAction<boolean>) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, isOpenSuggestDrawer: action.payload };
        },

        /**
         * reset map drawers slice to initial state
         * @returns the initial state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapDrawerSettings } from '~/reducers/mapDrawerSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset back to initial state
         * const dispatch = useDispatch();
         * dispatch(resetMapDrawerSettings());
         */
        resetMapDrawerSettings: (): MapDrawerSettingsState => {
            return defaultDrawerSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultDrawerSettings;
        });
    }
});

export const {
    setIsOpenUnassignedTasksDrawer,
    setIsOpenSelectedCardsDrawer,
    setIsOpenSuggestDrawer,
    setIsOpenDispatchManagementPopup,
    setIsOpenPairEquipmentControlPopup,
    setIsOpenLiveDispatchDrawer,
    setIsLiveDispatchDrawerExpanded,
    setIsUnassignedTasksTabActive,
    resetMapDrawerSettings,
    setIsEquipmentDepotPairing
} = mapDrawerSettingsSlice.actions;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the drawer setting
 * const showDrawer = useSelector(selectIsOpenUnassignedTasksDrawer);
 */

export const selectIsOpenUnassignedTasksDrawer = (state: RootState) =>
    state.mapDrawerSettings.isOpenUnassignedTasksDrawer;

export const selectIsOpenSuggestDrawer = (state: RootState) =>
    state.mapDrawerSettings.isOpenSuggestDrawer;

export const selectIsOpenSelectedCardsDrawer = (state: RootState) =>
    state.mapDrawerSettings.isOpenSelectedCardsDrawer;

export const selectIsUnassignedTasksTabActive = (state: RootState): boolean =>
    state.mapDrawerSettings.isUnassignedTasksTabActive;

export const selectIsOpenLiveDispatchDrawer = (state: RootState) =>
    state.mapDrawerSettings.isOpenLiveDispatchDrawer;

export const selectIsOpenDispatchManagementPopup = (state: RootState) =>
    state.mapDrawerSettings.isOpenDispatchManagementPopup;

export const selectIsLiveDispatchDrawerExpanded = (state: RootState) =>
    state.mapDrawerSettings.isLiveDispatchDrawerExpanded;

export const selectIsOpenAnyUnassignedTasksDrawer = (state: RootState) => {
    const { isOpenUnassignedTasksDrawer, isOpenLiveDispatchDrawer } =
        state.mapDrawerSettings;
    return isOpenUnassignedTasksDrawer || isOpenLiveDispatchDrawer;
};
export const selectIsOpenPairEquipmentControlPopup = (state: RootState) =>
    state.mapDrawerSettings.isOpenPairEquipmentControlPopup;

export const selectIsEquipmentDepotPairing = (state: RootState) =>
    state.mapDrawerSettings.isEquipmentDepotPairing;

export default mapDrawerSettingsSlice.reducer;
