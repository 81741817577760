import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import { Icon } from '~/ui';

import './FormErrorMessage.css';

function FormErrorMessage({
    icon = 'errorIconFill',
    iconColor,
    message,
    className = '',
    inline = false
}) {
    const _getClassName = () => {
        const defaultClasses = '_d-flex _ai-center';
        const messageType = inline ? 'inlinemessage' : 'errormessage';
        return classNames(defaultClasses, messageType, className);
    };
    return (
        <div className={_getClassName()} data-testid="formerrormessage">
            <Icon icon={icon} color={iconColor} data-testid={icon} />
            <span className="_ml-3 _fs-0">{message}</span>
        </div>
    );
}

export default FormErrorMessage;

FormErrorMessage.propTypes = {
    /** Icon name */
    icon: PT.string,
    /** Icon color */
    iconColor: PT.string,
    /** Error message */
    message: PT.string,
    className: PT.string,
    inline: PT.bool
};
