import React from 'react';
import classNames from 'classnames';
import { LiveDriver } from '~/data-classes';
import { UIElementWithChildren } from '~/ui';
import { useRouteCardProgressBarColors } from './useRouteCardProgressBarColors';
import './RouteCardProgressBar.scss';

interface RouteCardProgressBarProps extends UIElementWithChildren {
    /**
     * the `LiveDriver` route data to extract the progress bar from
     */
    routeData: LiveDriver;
}

const RouteCardProgressBar = ({
    children,
    className,
    routeData,
    ...extra
}: RouteCardProgressBarProps) => {
    const { routeStatusGradient, routeStatusPosition } =
        useRouteCardProgressBarColors(routeData);
    const { isDriverActive } = routeData;

    const dataTestId = extra['data-testid'] || 'routecard-progressbar';
    const rootClassName = 'routecard-progressbar';
    const blockClassName = classNames(rootClassName, className);

    const infoElementClassName = classNames(
        `${rootClassName}__info`,
        '_d-flex _jc-space-between',
        {
            [`${className}__info`]: className
        }
    );

    const gradientElementClassName = classNames('gradient _p-relative', {
        [`${className}__gradient`]: className
    });

    const pointerElementClassName = classNames(
        'gradient__pointer _d-flex _ai-center _jc-center _p-absolute',
        {
            'gradient__pointer--offline': !isDriverActive,
            [`${className}__pointer`]: className
        }
    );

    return (
        <div className={blockClassName} data-testid={dataTestId}>
            {children && (
                <div
                    className={infoElementClassName}
                    data-testid={`${dataTestId}__info`}
                >
                    {children}
                </div>
            )}
            <div
                className={gradientElementClassName}
                style={routeStatusGradient}
                data-testid={`${dataTestId}__gradient`}
            >
                {routeStatusPosition && (
                    <div
                        className={pointerElementClassName}
                        style={routeStatusPosition}
                        data-testid={`${dataTestId}__pointer`}
                    />
                )}
            </div>
        </div>
    );
};

export default RouteCardProgressBar;
