import { DateTime } from 'luxon';
import { ApiEquipmentAttributes, ApiLiveDriver } from '~/api/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { EquipmentState } from '~/reducers/equipmentSlice';
import { PlanRoute } from '~/data-classes';
import {
    equipmentColumnIdToColumnFilterKeyMap,
    EquipmentTableColumnId
} from '~/components/MapPageDrawers/LiveDispatchDrawer/types';

type HiddenRouteState = Record<string, boolean>;

export const checkIfRouteIsSelected = (
    clientRouteId: string,
    selectedMapRoutesState: string[],
    selectedDrawerRouteIdState: string
) => {
    const isSelectedDrawerRoute = clientRouteId === selectedDrawerRouteIdState;
    if (selectedDrawerRouteIdState && !isSelectedDrawerRoute) {
        return false;
    }
    return selectedMapRoutesState.includes(clientRouteId);
};

export const checkShouldFilterOutRoute = (
    clientRouteId: string,
    hiddenRoutesState: HiddenRouteState,
    selectedMapRoutesState: string[],
    hasIsolatedRoutesState: boolean,
    selectedDrawerRouteIdState: string
) => {
    const isSelectedDrawerRoute = clientRouteId === selectedDrawerRouteIdState;
    if (selectedDrawerRouteIdState && !isSelectedDrawerRoute) {
        return true;
    }
    if (selectedMapRoutesState.length && hasIsolatedRoutesState) {
        const isSelected = selectedMapRoutesState.includes(clientRouteId);
        if (!isSelected) {
            return true;
        }
    }
    return Boolean(hiddenRoutesState[clientRouteId]);
};

const generateDateRange = (dateStrings: string): string[] => {
    const dates = dateStrings
        .split(',')
        .map((dateString) => DateTime.fromISO(dateString));
    const startDate = DateTime.min(...dates);
    const endDate = DateTime.max(...dates);

    const daysDiff = endDate.diff(startDate, 'days').days;

    const dateRange: string[] = [];
    for (let i = 0; i <= daysDiff; i++) {
        dateRange.push(startDate.plus({ days: i }).toISODate());
    }

    return dateRange;
};

const getFilteredEquipmentColumn = ({
    equipment,
    columnFilters
}: {
    equipment: EquipmentState;
    columnFilters: ColumnFiltersState;
}) => {
    const lowercaseFilters = columnFilters.flatMap(({ id, value }) => {
        const transformedValue =
            id ===
            equipmentColumnIdToColumnFilterKeyMap?.[
                EquipmentTableColumnId.UPDATED_AT
            ]
                ? generateDateRange(value as string)
                : (value as string[]).map((val) => val.toLowerCase());

        return transformedValue;
    });

    const setOfFilters = new Set(lowercaseFilters);

    const filteredEquipmentColumn = Object.fromEntries(
        Object.entries(equipment).filter(([, value]) => {
            return columnFilters.every((cF) => {
                const { id } = cF;

                // it is possible for `equipmentValue` to be `undefined`
                // when the equipment data is incomplete as returned by the equipment socket

                // for timestamps, remove the time portion from timestamp values
                // and return only the date part
                const equipmentValue =
                    id ===
                    equipmentColumnIdToColumnFilterKeyMap?.[
                        EquipmentTableColumnId.UPDATED_AT
                    ]
                        ? value.timestamp?.slice(0, 10)
                        : value[id as keyof ApiEquipmentAttributes];

                const targetValues: (string | undefined)[] = Array.isArray(
                    equipmentValue
                )
                    ? equipmentValue
                    : [equipmentValue];

                return targetValues.some(
                    (targetValue) =>
                        targetValue &&
                        setOfFilters.has(targetValue.toLowerCase())
                );
            });
        })
    );

    return filteredEquipmentColumn;
};

function getFilteredGlobal({
    equipment,
    globalFilter
}: {
    equipment: EquipmentState;
    globalFilter: string;
}) {
    const filteredObjects: EquipmentState = {};

    Object.keys(equipment).forEach((key) => {
        const obj = equipment[key];
        const objString = JSON.stringify(obj).toLowerCase();
        if (new RegExp(globalFilter, 'i').test(objString)) {
            filteredObjects[key] = obj;
        }
    });
    return filteredObjects;
}

export const getFilteredEquipments = ({
    equipment,
    globalFilter,
    columnFilters
}: {
    equipment: EquipmentState;
    globalFilter: string;
    columnFilters: ColumnFiltersState;
}): EquipmentState => {
    if (!globalFilter && !columnFilters.length) return equipment;

    let filteredEquipment: EquipmentState = equipment;

    if (columnFilters.length) {
        filteredEquipment = getFilteredEquipmentColumn({
            equipment,
            columnFilters
        });
    }

    if (globalFilter) {
        filteredEquipment = getFilteredGlobal({
            equipment: filteredEquipment,
            globalFilter
        });
    }

    return filteredEquipment;
};

export const getNonDispatchedRoutes = ({
    dispatchedDrivers,
    routesLevelData
}: {
    dispatchedDrivers: ApiLiveDriver[];
    routesLevelData: PlanRoute[];
}) => {
    const dispatchedDriverIds = new Set(dispatchedDrivers.map(({ id }) => id));
    return routesLevelData.filter(
        ({ driverId }) => !dispatchedDriverIds.has(driverId)
    );
};

export const getRouteLevelDataByClientRouteId = (
    clientRouteId: string,
    routesData: { clientRouteId: string }[]
) => {
    return routesData.find((routeLevelData) => {
        return clientRouteId === routeLevelData.clientRouteId;
    });
};
