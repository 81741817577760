import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isNumber } from 'lodash';
import { useFormatTimeToClientTimeZone } from '~/hooks';
import dateUtilsConverters from '~/utils/date-utils-converters';
import { Icon } from '~/ui/';
import { IconSizes } from '../Icon/IconSizes';
import { LiveRoutesMarkerPopupProps } from './types';
import './LiveRoutesMarkerPopup.scss';

const {
    convertMillisecondsToHoursAndMinutesAndSeconds:
        convertMillisecondsToDuration
} = dateUtilsConverters;

function LiveRoutesMarkerPopup({
    driverData,
    exceptionData
}: LiveRoutesMarkerPopupProps) {
    const { t } = useTranslation('translation');

    const { formatTimeToClientTimeZone } = useFormatTimeToClientTimeZone();

    const {
        name,
        numStops,
        isCompleted,
        currentStopIndex,
        schedule,
        timeRemaining
    } = driverData;

    const currentStopText = useMemo(() => {
        const currentStopNumber = (isCompleted && numStops) || currentStopIndex;
        const hasCurrentStop = isNumber(currentStopNumber);
        const hasTotalStops = isNumber(numStops) && numStops > 0;

        if (!hasCurrentStop || !hasTotalStops) return null;

        return t('driverCurrentStop', {
            current: currentStopNumber,
            total: numStops
        });
    }, [t, isCompleted, numStops, currentStopIndex]);

    const statusTimeText = useMemo(() => {
        if (isCompleted && schedule?.length) {
            const lastStopIndex = schedule.length - 1;
            const { completedAt } = schedule[lastStopIndex] || {};
            return completedAt
                ? t('completedTime', {
                      time: formatTimeToClientTimeZone({ time: completedAt })
                  })
                : null;
        }

        if (!isNumber(timeRemaining)) return null;
        const duration = convertMillisecondsToDuration(timeRemaining);
        const localDateTime = DateTime.local();
        const finishTime = formatTimeToClientTimeZone({
            time: localDateTime.plus(duration)
        });
        return t('finishTime', {
            time: finishTime
        });
    }, [t, formatTimeToClientTimeZone, isCompleted, schedule, timeRemaining]);

    return (
        <div className="drivermarker-popup _fd-column _ai-flex-start">
            {exceptionData.length > 0 && (
                <div className="drivermarker-popup__exceptions _fd-row">
                    {exceptionData.map((iconData) => {
                        const { type, icon, iconColor, value } = iconData;
                        return (
                            <div
                                key={type}
                                className="drivermarker-popup__exception _fd-row _ai-center"
                                data-testid={`drivermarker-popup__exception--${type}`}
                            >
                                <Icon
                                    className="drivermarker-popup__exception-icon"
                                    icon={icon}
                                    color={iconColor}
                                    size={IconSizes.S}
                                />
                                <div className="drivermarker-popup__exception-text">
                                    {value}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <div
                className="drivermarker-popup__driver"
                data-testid="drivermarker-popup__driver"
            >
                {name}
            </div>
            {currentStopText && (
                <div
                    className="drivermarker-popup__current-stop _fd-row _ai-center"
                    data-testid="drivermarker-popup__current-stop"
                >
                    <Icon icon="stops" color="galaxy-500" size={IconSizes.S} />
                    <div className="drivermarker-popup__current-stop-text">
                        {currentStopText}
                    </div>
                </div>
            )}
            {statusTimeText && (
                <div
                    className="drivermarker-popup__status-time _fd-row _ai-center"
                    data-testid="drivermarker-popup__status-time"
                >
                    <Icon icon="clock2" size={IconSizes.S} />
                    <div className="drivermarker-popup__status-time-text">
                        {statusTimeText}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LiveRoutesMarkerPopup;
