import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LiveDriver, PlanRoute } from '~/data-classes';
import {
    selectHasIsolatedRoutes,
    selectViewCardDetails
} from '~/reducers/mapSettingsSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

export interface RoutesWithTasks
    extends Omit<PlanRoute | LiveDriver, 'clientRouteId'> {
    /** the client route ID */
    clientRouteId?: string;
}

export function useRoutesWithTasks(routes = [] as RoutesWithTasks[]) {
    const selectedMapRouteIds = useSelector(selectSelectedMapRoutes);
    const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes);
    const viewCardDetails = useSelector(selectViewCardDetails);

    return useMemo(() => {
        const isSelectedRoutesOnly =
            Boolean(selectedMapRouteIds.length) &&
            hasIsolatedRoutes &&
            viewCardDetails;

        const filteredRoutes = routes.filter((route) => {
            const { hasTasks, clientRouteId, clientDriverId } = route;

            if (hasTasks && isSelectedRoutesOnly) {
                return clientRouteId
                    ? selectedMapRouteIds.includes(clientRouteId)
                    : selectedMapRouteIds.includes(clientDriverId);
            }
            return hasTasks;
        });

        return filteredRoutes;
    }, [routes, selectedMapRouteIds, hasIsolatedRoutes, viewCardDetails]);
}
