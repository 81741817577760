import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, FlexLayout, Icon, Text, useClickOutside } from '~/ui';
import MapSettingMenuItem from './MapSettingMenuItem/MapSettingMenuItem';
import {
    MapSettingMenuLiveRoutesSection,
    MapSettingMenuRouteLinesSection,
    MapSettingMenuRoutesSection,
    MapSettingMenuStopsSection,
    MapSettingMenuZoneSection
} from './MapSettingMenuSections';

import { useHereMaps, useIntermodalFeatures, useMapUtils } from '~/hooks';
import constants from '~/utils/constants';
import { MapSettingMenuEquipmentMarkerSection } from './MapSettingMenuSections/MapSettingMenuEquipmentMarkerSection';
import { MapSettingMenuUnassignedTasksSection } from './MapSettingMenuSections/MapSettingMenuUnassignedTasksSection';

import './MapSettingsControl.scss';

function MapSettingsControl() {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [subMenuTitle, setSubMenuTitle] = useState(null);
    const [subMenuList, setSubMenuList] = useState([]);

    const { mapRouteMode } = useMapUtils();

    const { isHerePlanMapActive } = useHereMaps();

    const { enableLiveDispatch } = useIntermodalFeatures();

    const showEquipmentMarkerSection =
        !isHerePlanMapActive &&
        enableLiveDispatch &&
        mapRouteMode === constants.mapRouteModes.DISPATCHED;

    const showUnassignedTasksSection =
        !isHerePlanMapActive &&
        enableLiveDispatch &&
        (mapRouteMode === constants.mapRouteModes.PLAN ||
            mapRouteMode === constants.mapRouteModes.DISPATCHED);

    const ref = useRef();
    const dispatch = useDispatch();

    useClickOutside(ref, onHide);

    function onHide() {
        setIsOpen(false);
    }

    function toggleSettingsWindow() {
        setIsOpen(!isOpen);
        setIsSubMenuOpen(false);
    }

    // close submenu when mapRouteMode changes
    useEffect(() => {
        setIsSubMenuOpen(false);
    }, [mapRouteMode]);

    const handleMenuItemStateChange = useCallback(
        (checked, menuStateFunc) => {
            dispatch(menuStateFunc({ mode: mapRouteMode, value: checked }));
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [mapRouteMode]
    );

    const openSubMenu = useCallback((newSubMenuTitle, newSubMenuList) => {
        setIsSubMenuOpen(true);
        setSubMenuTitle(newSubMenuTitle);
        setSubMenuList(newSubMenuList);
    }, []);

    function backMenuClick() {
        setIsOpen(true);
        setIsSubMenuOpen(false);
        setSubMenuTitle(null);
        setSubMenuList([]);
    }

    function _getModeDependentSections() {
        switch (mapRouteMode) {
            case constants.mapRouteModes.PLAN:
                return (
                    <>
                        {!isHerePlanMapActive && (
                            <MapSettingMenuZoneSection
                                mapRouteMode={mapRouteMode}
                            />
                        )}
                        <MapSettingMenuRoutesSection
                            mapRouteMode={mapRouteMode}
                            openSubMenu={openSubMenu}
                            showPolygonToggle
                        />
                        <MapSettingMenuRouteLinesSection
                            mapRouteMode={mapRouteMode}
                        />
                    </>
                );
            case constants.mapRouteModes.DISPATCHED:
                return (
                    <>
                        {!isHerePlanMapActive && (
                            <MapSettingMenuZoneSection
                                mapRouteMode={mapRouteMode}
                            />
                        )}
                        <MapSettingMenuLiveRoutesSection
                            mapRouteMode={mapRouteMode}
                            openSubMenu={openSubMenu}
                        />
                    </>
                );
            case constants.mapRouteModes.COMPLETED:
                return (
                    <>
                        <MapSettingMenuRoutesSection
                            mapRouteMode={mapRouteMode}
                            openSubMenu={openSubMenu}
                        />
                    </>
                );
            default:
                return null;
        }
    }

    function _getSubmenuClose() {
        return (
            <>
                {_getModeDependentSections()}
                <MapSettingMenuStopsSection
                    mapRouteMode={mapRouteMode}
                    openSubMenu={openSubMenu}
                />
                {showEquipmentMarkerSection && (
                    <MapSettingMenuEquipmentMarkerSection
                        mapRouteMode={mapRouteMode}
                        isShowHeader
                    />
                )}
                {showUnassignedTasksSection && (
                    <MapSettingMenuUnassignedTasksSection
                        mapRouteMode={mapRouteMode}
                        isShowHeader
                    />
                )}
            </>
        );
    }

    function _getSubmenuOpen() {
        return (
            <>
                <FlexLayout
                    alignItems="center"
                    paddingBottom="1rem"
                    onClick={backMenuClick}
                    sx={{
                        cursor: 'pointer',
                        borderBottom: '1px solid var(--color-galaxy-300)'
                    }}
                    data-testid="map-settings-control-sub-menu-wrapper"
                >
                    <Icon
                        icon="chevronDown"
                        color="galaxy-800"
                        marginLeft="1.6rem"
                        sx={{ transform: 'rotate(90deg)' }}
                    />
                    <Text
                        color="galaxy-800"
                        variant="14-normal"
                        padding="0 0.8rem"
                    >
                        {subMenuTitle}
                    </Text>
                </FlexLayout>
                {subMenuList.map((item) => {
                    return (
                        <MapSettingMenuItem
                            key={item.text}
                            iconName={item.iconName}
                            text={item.text}
                            checkedState={item.checkedState}
                            onChangeState={(checked) =>
                                handleMenuItemStateChange(
                                    checked,
                                    item.onChangeHandler
                                )
                            }
                            conditionalSetting={item.hasSub}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <div
            ref={ref}
            className="map-settings-control _p-relative"
            data-testid="map-settings-control-wrapper"
        >
            <Box
                sx={{
                    padding: '0.2rem',
                    backgroundColor: 'var(--color-white)',
                    borderRadius: 'var(--border-radius-l)'
                }}
            >
                <Button
                    className="map-settings-control_btn"
                    type="naked"
                    sx={{
                        borderRadius: 'var(--border-radius-l)',
                        padding: '0.8rem'
                    }}
                    onClick={toggleSettingsWindow}
                >
                    <Icon icon="setting" color="galaxy-800" />
                </Button>
            </Box>
            {isOpen && (
                <FlexLayout
                    className="map-settings-control_menu"
                    flexDirection="column"
                    padding="1.4rem 0"
                    sx={{
                        backgroundColor: 'comet',
                        position: 'absolute',
                        bottom: 'calc(100% + 1rem)',
                        right: '0',
                        borderRadius: '0.8rem',
                        boxShadow: '0 0.2rem 0.6rem var(--color-box-shadow)'
                    }}
                    data-testid="map-settings-control-menu"
                >
                    {!isSubMenuOpen && _getSubmenuClose()}
                    {isSubMenuOpen && _getSubmenuOpen()}
                </FlexLayout>
            )}
        </div>
    );
}

export default MapSettingsControl;
