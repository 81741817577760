import { ApiAddress } from './ApiAddress';
import { ApiInventoryItem } from './ApiInventoryItem';
import { Contact, IPrivateApiFields, TimeWindow } from './CommonTypes';
import { PaginationMetadata, TasksV2PaginationMetadata } from './Pagination';

/**
 * Numerical enum of task priority value. Sequence starts at 1
 * representing a high priority task
 */
export enum TaskPriority {
    HIGH = 1,
    MEDIUM,
    LOW
}

/**
 * Numerical enum of task statuses.
 */
export enum TaskStatus {
    UNASSIGNED,
    ASSIGNED,
    IN_PROGRESS,
    COMPLETED,
    FAILED
}

/**
 * Enum of supported task status conditions
 */
export enum TaskStatusCondition {
    UNASSIGNED,
    DISPATCHED,
    IN_PROGRESS,
    COMPLETED,
    CANCELLED,
    PLANNED
}

/**
 * Enum of supported task types returned by `wise-api`
 */
export enum TaskTypes {
    DEPOT = 'depot',
    PICKUP = 'pickup',
    DELIVERY = 'delivery',
    TWOPART = 'twoPart'
}

/**
 * Enum of task types supported for external task type dropdown
 */
export enum AddTaskTypes {
    PICKUP = TaskTypes.PICKUP,
    DELIVERY = TaskTypes.DELIVERY,
    TWOPART = TaskTypes.TWOPART
}

/**
 * Enum of task types supported for address locations
 */
export enum AddressTaskTypes {
    PICKUP = 'pickup',
    DELIVERY = 'delivery'
}

/**
 * Enum of supported methods to input location data
 */
export enum InputLocationMethods {
    CUSTOMER_DB = 'customer_db',
    AUTOCOMPLETE = 'autocomplete',
    MANUAL = 'manual'
}

/**
 * Constructs a record that can have `pickup` or `delivery` properties, each of type T
 */
export type TaskTypeRecord<T> = Partial<Record<'pickup' | 'delivery', T>>;

export interface ApiNote {
    /**
     * the note scope
     */
    scope?: string;
    /**
     * the note title
     */
    title?: string;
    /**
     * The note text
     */
    text?: string;
}

export type ApiCompartment = {
    /**
     * the compartment size
     */
    size: number;

    /**
     * the compartment type
     */
    compartmentType: string;
};

/**
 * Type agnostic task props fields
 */
interface TypeAgnosticTaskProps {
    /**
     * the task priority
     */
    priority: TaskPriority;
    /**
     * the task size
     */
    size: number;
    /**
     * the task weight
     */
    weight: number;
    /**
     * indicates the task is eligible for pairing
     */
    pairingEligible: boolean;
    /**
     * the task workflows
     */
    workflow?: {
        /**
         * whether the task workflow requires a signature
         */
        requireSignature?: boolean;
        /**
         * whether the task workflow prompts for a signature
         */
        promptSignature?: boolean;
    };
    /**
     * the task notes
     */
    notes?: ApiNote[];
    /**
     * the task external links
     */
    externalLinks?: string[];
    /**
     * the task type
     *
     * Typically, provided to identify the `depot` task
     *
     * May sometimes identify `pickup`, `delivery`, or `twoPart`.
     * Best to still rely on `pickupLocation` and `deliveryLocation` to identify these three.
     */
    type?: `${TaskTypes}`;
    /**
     * when an operational stop is inserted to an assigned two-part task
     * the task id of this two-part task is assigned to the originalTaskId
     * originalTaskId indicates that the 2 new tasks being created are going to replace the assigned two-part task
     */
    originalTaskId?: string;
}

export interface ApiInvoice {
    invoiceNumber: string;
    amountDue: number;
    invoiceId: string;
    weight?: number;
    size?: number;
    labels?: string[];
}

/**
 * Pickup task props fields
 */
export interface PickupTaskProps extends TypeAgnosticTaskProps {
    /**
     * the pickup service time
     */
    pickupServiceTime: string;
    /**
     * the pickup time windows
     */
    pickupWindow: Array<TimeWindow>;
    /**
     * the pickup inventory
     */
    pickupInventory?: Array<ApiInventoryItem>;
    /**
     * the original pickup task props
     */
    originalPickup?: Pick<ApiTask, 'labels'>;
    /**
     * the pickup invoices
     */
    pickupInvoices?: ApiInvoice[];
    /**
     * the pickup time
     */
    pickupTime: string;
    /**
     * the delivery time
     *
     * it will be included as an explicit `null` in pickup tasks.
     */
    deliveryTime: null;
}

/**
 * Delivery task props fields
 */
export interface DeliveryTaskProps extends TypeAgnosticTaskProps {
    /**
     * the delivery service time
     */
    deliveryServiceTime: string;
    /**
     * the delivery time windows
     */
    deliveryWindow: Array<TimeWindow>;
    /**
     * the delivery inventory
     */
    deliveryInventory?: Array<ApiInventoryItem>;
    /**
     * the original pickup task props
     */
    originalDelivery?: Pick<ApiTask, 'labels'>;
    /**
     * the delivery invoices
     */
    deliveryInvoices?: ApiInvoice[];
    /**
     * the pickup time
     *
     * it will be included as an explicit `null` in delivery tasks.
     */
    pickupTime: null;
    /**
     * the delivery time
     */
    deliveryTime: string;
}

/**
 * Two-part task props fields
 */
export interface TwoPartTaskProps
    extends Omit<PickupTaskProps, 'deliveryTime'>,
        Omit<DeliveryTaskProps, 'pickupTime'> {}

/**
 * Type agnostic task fields
 */
export interface TypeAgnosticTask extends IPrivateApiFields {
    /**
     * the task id
     */
    id: string;
    /**
     * the task deduplication id
     */
    deduplicationId: string;
    /**
     * the task consolidation id
     */
    consolidationId: string;
    /**
     * the task route date. ISO-8601 date format
     */
    routeDate: string;
    /**
     * the task name
     */
    name: string;
    /**
     * the task status
     */
    status: TaskStatus;
    /**
     * the task status condition, calculated based on `status` and `routeId`.
     *
     * Generally, the `taskStatus` is set by using `taskUtils.getTaskStatus()`
     * on the `TasksApi.getTask()` or `TasksApi.get()` response
     *
     * The `Task.taskStatusCondition` data class property can calculate
     * it directly if not available
     */
    taskStatus?: TaskStatusCondition;
    /**
     * the task eid
     */
    eid?: number;
    /**
     * the task euid
     */
    euid?: string | null;
    /**
     * the task route id
     */
    routeEid?: string;
    /**
     * the eid of the vehicle associted to this task
     */
    vehicleEid?: string;
    /**
     * indicates the task is locked to a route
     */
    locked: boolean;
    /**
     * indicates the task is locked to a specific position in a route
     */
    orderLocked: boolean;
    /**
     * id of the route this task is planned for
     */
    routeId?: string;
    /**
     * the task forecast type
     */
    forecastType?: string;
    /**
     * types of vehicles required by this task
     */
    vehicleTypes: string[];
    /**
     * the task labels
     */
    labels: string[] | null;
    /**
     * the task eorder
     */
    eorder?: number;
    /**
     * client this task belongs to
     */
    client: string;
    /**
     * notification settings for this task
     */
    notification: {
        /**
         * indicates sms notifications are enabled
         */
        sms: boolean;
        /**
         * indicates email notifications are enabled
         */
        email: boolean;
    };
    /**
     * the 2-part task id, optionally set by Interstellar
     * primarily to uniquely track the delivery and pickup task data for loops as keys
     */
    twoPartId?: string;
    /**
     * the external task type, optionally set by a client on task creation
     */
    externalTaskType?: string;
    /**
     * ISO-8601 timestamp of when the task was created
     */
    createdAt: string;
    /**
     * ISO-8601 timestamp of when the task was last updated
     */
    updatedAt: string;
    /**
     * Compartment list when a client has enabled multiple compartments
     */
    sizeByCompartment: ApiCompartment[];
    /**
     * The task equipment ID
     */
    equipmentId: string | null;
    /**
     * The task equipment payor of freight
     */
    payerOfFreight: string | null;
    /**
     * The task equipment reservation
     */
    reservation: string | null;
    /**
     * The task equipment car kind
     */
    carKind: string | null;
    /**
     * The task equipment drop/stay status
     */
    dropStay: string | null;
    /**
     * The task equipment chassis ID
     */
    chassisId: string | null;
    /**
     * The task equipment empty order number
     *
     * This value is bounded:
     * + min value: 1
     * + max value: 9999
     */
    emptyOrder: number | null;
    /**
     * when an operational stop is inserted to an assigned two-part task
     * the task id of this two-part task is assigned to the originalTaskId
     * originalTaskId indicates that the 2 new tasks being created are going to replace the assigned two-part task
     */
    originalTaskId?: string;
    /**
     * Whether the task is to be shown to the driver
     *
     * This property is meant to allow dispatchers to toggle the visibility of the task on a driver's mobile app.
     *
     * The task will remain visible, albeit with an indicator, to the dispatcher while using `Interstellar`
     */
    showToDriver?: boolean;
}

/**
 * Pickup task fields
 */
export interface PickupTask extends TypeAgnosticTask {
    /**
     * the task pickup contact
     */
    pickupContact: Contact;
    /**
     * the task pickup location
     */
    pickupLocation: ApiAddress;
    /**
     * the pickup task props
     */
    props: PickupTaskProps;
    /**
     * the original pickup task props
     */
    originalProps?: Partial<PickupTaskProps>;
    /**
     * The pickup customers' euid
     */
    pickupEuid: string;
}

/**
 * Delivery task fields
 */
export interface DeliveryTask extends TypeAgnosticTask {
    /**
     * the task delivery contact
     */
    deliveryContact: Contact;
    /**
     * the task delivery location
     */
    deliveryLocation: ApiAddress;
    /**
     * the delivery task props
     */
    props: DeliveryTaskProps;
    /**
     * the original delivery task props
     */
    originalProps?: Partial<DeliveryTaskProps>;
    /**
     * The delivery customers' euid
     */
    deliveryEuid: string;
}

/**
 * Two-part task fields
 */
export interface TwoPartTask
    extends Omit<PickupTask, 'props' | 'originalProps'>,
        Omit<DeliveryTask, 'props' | 'originalProps'> {
    /**
     * the two-part task props
     */
    props: TwoPartTaskProps;
    /**
     * the original two-part task props
     */
    originalProps?: Partial<TwoPartTaskProps>;
}

/**
 * Describes the shape of the task object from `wise-api`
 *
 * **Type Guards:**
 * Use the following task guard utilities to identify a specific task type
 * + taskUtils.checkIsTwoPartTask(): verifies the object is a two-part task, type guard as `TwoPartTask`
 * + taskUtils.checkIsDeliveryTask(): verifies the object is a delivery task, type guard as `DeliveryTask`
 * + taskUtils.checkIsPickupTask(): verifies the object is a pickup task, type guard as `PickupTask`
 */
export type ApiTask = PickupTask | DeliveryTask | TwoPartTask;

export interface ApiLiveDispatch {
    /**
     *  The API Task type. This type can be used for a pickup, a delivery.
     */
    data: ApiTask[];

    /**
     * This is for pagination meta data for API
     */
    meta?: PaginationMetadata;
}

export interface ApiLiveDispatchV2 extends ApiLiveDispatch {
    /**
     * This is for pagination meta data for API
     */
    meta?: TasksV2PaginationMetadata;
}

export interface TypeAgnosticStopData {
    /**
     * The stop marker task client route ID
     */
    clientRouteId: string;
    /**
     * Whether the stop marker task is for a two-part task
     */
    isTwoPart: boolean;
    /**
     * The stop marker task route date
     */
    routeDate: string;
    /**
     * The stop marker task ID
     */
    taskId: string;
    /**
     * Whether the stop marker task is planned
     */
    isPlanned: boolean;
    /**
     * Whether the stop marker task is high priority
     */
    isHighPriority: boolean;
    /**
     * Serializes back to JSON
     */
    toJSON: () => () => ApiTask;
}

export interface TypeSpecificStopData extends TypeAgnosticStopData {
    /**
     * The stop marker task client route task ID
     */
    clientRouteTaskId: string;
    /**
     * The stop marker task latitude
     */
    lat: number | null;
    /**
     * The stop marker task longitude
     */
    lng: number | null;
    /**
     * The stop marker task label
     */
    label: string | null;
}

export interface SplitTaskByInvoiceResponse {
    data: ApiTask[];
}
