import { useState } from 'react';
import { LiveStop, PlanStop } from '~/data-classes';

export const useChangeHandlers = () => {
    const [breakDuration, setBreakDuration] = useState({ hrs: 0, min: 30 });
    const [selectedStop, setSelectedStop] =
        useState<PlanStop | LiveStop | null>(null);

    const handleBreakDurationChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = e.target;
        setBreakDuration((currentState) => ({
            ...currentState,
            [name]: value !== '' ? Number(value) : value
        }));
    };

    const handleStopSelection = (value: PlanStop | LiveStop | null) => {
        setSelectedStop(value);
    };

    return {
        breakDuration,
        selectedStop,
        handleBreakDurationChange,
        handleStopSelection
    };
};
