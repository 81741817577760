import React, { useEffect, useMemo, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import englishLabels from 'react-phone-number-input/locale/en.json';
import japaneseLabels from 'react-phone-number-input/locale/ja.json';
import localizationUtils from '~/utils/localization-utils';

import 'react-phone-number-input/style.css';

interface UsePhoneNumberInputProps {
    /* The initial phone number input value */
    initialValue?: string;
}

export const usePhoneNumberInput = ({
    initialValue
}: UsePhoneNumberInputProps = {}) => {
    const [phoneNumber, setPhoneNumber] =
        useState<string | undefined>(initialValue);

    const defaultCountry = useMemo(
        () => (localizationUtils.isLanguageJapanese() ? 'JP' : 'US'),
        []
    );
    const labels = useMemo(
        () =>
            localizationUtils.isLanguageJapanese()
                ? japaneseLabels
                : englishLabels,
        []
    );

    const phoneNumberInput = (
        <PhoneInput
            international
            defaultCountry={defaultCountry}
            labels={labels}
            value={phoneNumber}
            onChange={setPhoneNumber}
        />
    );

    useEffect(() => {
        setPhoneNumber(initialValue);
    }, [initialValue]);

    return {
        defaultCountry,
        labels,
        phoneNumber,
        phoneNumberInput
    };
};
