/**
 * Web Colors
 * @category Utils
 * @subcategory Constants
 *
 * @constant
 * @type {WebColor[]}
 */
const webColors = [
    {
        bgColor: '#814096',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFA424',
        fgColor: '#09101A'
    },
    {
        bgColor: '#0587D3',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#367D71',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#E8768A',
        fgColor: '#09101A'
    },
    {
        bgColor: '#1C93C0',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#906F5A',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFDA00',
        fgColor: '#09101A'
    },
    {
        bgColor: '#597592',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#4F6EF4',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#CF116D',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#006AFF',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#BA66D5',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FF824A',
        fgColor: '#09101A'
    },
    {
        bgColor: '#37BFAA',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#e8486d',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FF5E3F',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#28BAC8',
        fgColor: '#09101A'
    },
    {
        bgColor: '#694EEF',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#D0E071',
        fgColor: '#09101A'
    },
    {
        bgColor: '#BB9251',
        fgColor: '#09101A'
    },
    {
        bgColor: '#12DFB1',
        fgColor: '#09101A'
    },
    {
        bgColor: '#D964AC',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#75B565',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#96723e',
        fgColor: '#09101A'
    },
    {
        bgColor: '#85B7FF',
        fgColor: '#09101A'
    },
    {
        bgColor: '#37756c',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#78D1F2',
        fgColor: '#09101A'
    },
    {
        bgColor: '#9AA2C4',
        fgColor: '#09101A'
    },
    {
        bgColor: '#402803',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#F183B8',
        fgColor: '#09101A'
    },
    {
        bgColor: '#7395C4',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FF87FA',
        fgColor: '#09101A'
    },
    {
        bgColor: '#914a2a',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#03a890',
        fgColor: '#09101A'
    },
    {
        bgColor: '#e85151',
        fgColor: '#09101A'
    },
    {
        bgColor: '#000080',
        fgColor: '#09101A'
    },
    {
        bgColor: '#2f388f',
        fgColor: '#09101A'
    },
    {
        bgColor: '#872b0c',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#299c06',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#5e1e4a',
        fgColor: '#09101A'
    },
    {
        bgColor: '#3c8210',
        fgColor: '#09101A'
    }
];

export default webColors;
