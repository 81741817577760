import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

const initialState = {};

export const draggedMarkerLocationSlice = createSlice({
    name: 'draggedMarkerLocation',
    initialState,
    reducers: {
        updateDraggedMarkerLocation: (state, action) => {
            state = action.payload;
            return state;
        },
        clearDraggedMarkerLocation: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { updateDraggedMarkerLocation, clearDraggedMarkerLocation } =
    draggedMarkerLocationSlice.actions;

export const selectDraggedMarkerLocation = (state) =>
    state.draggedMarkerLocation;

export default draggedMarkerLocationSlice.reducer;
