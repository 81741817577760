import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import { PlanRoute } from '~/data-classes';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { addSelectedMapRoute } from '~/reducers/selectedMapRoutesSlice';
import { useSelectRoutesView } from '../useSelectRoutesView';
import { useSelectStopsView } from '../useSelectStopsView';
import { idUtils } from '~/utils/id-utils';
import { canHandleNumberOfTasks } from './utils';
import { selectUnplannedRouteData } from '~/reducers/planRoutesLevelDataSlice';

export const useMapViewControl = () => {
    const dispatch = useDispatch();
    const { isPlanRouteMode } = useMapUtils();
    const {
        isMultipleCardSelectEnabled,
        viewCardDetails,
        setIsMultipleCardSelectEnabled
    } = useIsolatedRoutes();
    const { selectRoutesView } = useSelectRoutesView();
    const { selectStopsView } = useSelectStopsView();
    const { routesLevelData } = usePlanMapPropsContext();
    const unplannedRouteData = useSelector(selectUnplannedRouteData);

    const planRoutesWithTasks = useMemo(() => {
        return (routesLevelData as PlanRoute[]).filter((route) => {
            const { hasTasks, isPlanned } = route;

            return hasTasks && isPlanned;
        });
    }, [routesLevelData]);

    const numberOfPlanRoutesTasks = useMemo(() => {
        return sumBy(planRoutesWithTasks, ({ stats: { numTasks } }) => {
            return numTasks;
        });
    }, [planRoutesWithTasks]);

    const unassignedClientRouteId = useMemo(() => {
        const { client: { clientId = '' } = {}, route: { routeId = '' } = {} } =
            unplannedRouteData || {};
        if (!clientId || !routeId) return undefined;
        return idUtils.getCombinedId(clientId, routeId);
    }, [unplannedRouteData]);

    const isStopsViewSelected = Boolean(
        isMultipleCardSelectEnabled && viewCardDetails
    );

    const isRoutesViewSelected = !isStopsViewSelected;

    const isShowMapViewControl = Boolean(
        isPlanRouteMode && canHandleNumberOfTasks(numberOfPlanRoutesTasks)
    );

    const handleSelectRoutesView = () => {
        if (!isRoutesViewSelected) selectRoutesView();
    };

    const handleSelectStopsView = () => {
        if (isStopsViewSelected || !planRoutesWithTasks.length) return;

        planRoutesWithTasks.forEach(({ clientRouteId }) => {
            dispatch(addSelectedMapRoute(clientRouteId));
        });

        if (unassignedClientRouteId)
            dispatch(addSelectedMapRoute(unassignedClientRouteId));

        setIsMultipleCardSelectEnabled(true);
        selectStopsView();
    };

    return {
        isShowMapViewControl,
        isRoutesViewSelected,
        isStopsViewSelected,
        handleSelectRoutesView,
        handleSelectStopsView
    };
};
