import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const filteredDataCountSlice = createSlice({
    name: 'filteredDataCount',
    initialState: null,
    reducers: {
        setFilteredDataCount: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setFilteredDataCount } = filteredDataCountSlice.actions;

export const selectFilteredDataCount = (state) => state.filteredDataCount;

export default filteredDataCountSlice.reducer;
