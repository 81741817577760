import { RouteTripSummary } from '~/components/MapPageDrawers/SelectedCardsDrawer/types';

export const routeHasMultipleTrips = (trips: RouteTripSummary[] = []) => {
    if (!trips) {
        return false;
    }
    return trips.length > 1;
};

export const routeHasReloads = (clientRouteIds: string[] = []) => {
    return (clientRouteIds || []).length > 1;
};

export const checkIfAllTripsAreHidden = (
    clientRouteIds: string[],
    hiddenRoutes: Record<string, boolean>
) => {
    return clientRouteIds.every(
        (tripClientRouteId) => hiddenRoutes[tripClientRouteId]
    );
};
