import { DateTime } from 'luxon';
import { IconName } from '~/ui/components/Icon/IconNames';
import { DownloadableReportCategories } from './types/DownloadableReportCategories';
import { DownloadableReportIcons } from './types/DownloadableReportIcons';
import { DownloadableReportMappingsByType } from './types/mapping';
import { DownloadableReportTypes } from './types/DownloadableReportTypes';
import { GeneratedReport } from './types/GeneratedReport';
import { GeneratedReportClickHandler } from './types/GeneratedReportClickHandler';

/**
 * GeneratedReportItem data class
 *
 * @category Data Classes
 *
 * @example
 * import { GeneratedReportItem } from '~/data-classes/report';
 *
 * const srcData = {};
 * const reportItem = new GeneratedReportItem(srcData);
 *
 */
export class GeneratedReportItem {
    /**
     * The API source data
     * @type {GeneratedReport}
     */
    private readonly report: GeneratedReport;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(reportItem: GeneratedReport) {
        this.report = reportItem;
        this.clickHandler = this.clickHandler.bind(this);
    }

    /**
     * The generated downloadable report key
     */
    get key(): string {
        return `${this.type}_${this.reportDate.toISODate()}`;
    }

    /**
     * The generated downloadable report type
     */
    get type(): DownloadableReportTypes {
        const typeMatch = Object.keys(DownloadableReportMappingsByType).find(
            (key) => this.report.type === key
        ) as DownloadableReportTypes;

        return typeMatch ? this.report.type : DownloadableReportTypes.UNKNOWN;
    }

    /**
     * The generated downloadable report icon
     */
    get icon(): IconName {
        const typeMatch = Object.keys(DownloadableReportMappingsByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return typeMatch
            ? DownloadableReportMappingsByType[typeMatch].icon
            : DownloadableReportIcons.UNKNOWN;
    }

    /**
     * The generated downloadable report category
     */
    get category(): DownloadableReportCategories {
        const typeMatch = Object.keys(DownloadableReportMappingsByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return typeMatch
            ? DownloadableReportMappingsByType[typeMatch].category
            : DownloadableReportCategories.UNKNOWN;
    }

    /**
     * Whether the generated downloadable report is a core report
     */
    get isCoreReport(): boolean {
        return this.category === DownloadableReportCategories.CORE;
    }

    /**
     * Whether the generated downloadable report item is a custom report
     */
    get isCustomReport(): boolean {
        return this.category === DownloadableReportCategories.CUSTOM;
    }

    /**
     * The generated downloadable report date in DateTime
     */
    get reportDate(): DateTime {
        return this.report.reportDate instanceof DateTime
            ? this.report.reportDate
            : DateTime.fromISO(this.report.reportDate);
    }

    /**
     * The generated downloadable report list of disabled file types for download
     */
    get disabledFileDownloadTypes() {
        return this.report?.disabledFileDownloadTypes || [];
    }

    /**
     * The generated downloadable report list of support file types for download
     */
    get supportedFileDownloadTypes() {
        return this.report?.supportedFileDownloadTypes || [];
    }

    /**
     * The generated downloadable report list of support file types for export
     */
    get supportedFileExportTypes() {
        return this.report?.supportedFileExportTypes || [];
    }

    /**
     * The generated downloadable report click handler
     */
    clickHandler(props: GeneratedReportClickHandler) {
        return this.report.clickHandler(props);
    }

    /**
     * Whether to hide / show the export icon
     */
    get isShowReportExportIcon() {
        return this.report?.isShowReportExportIcon;
    }

    /**
     * Whether the generated downloadable report item is within the defined report dates
     */
    isWithinReportDates(filterReportDates: DateTime[] = []): boolean {
        if (!Array.isArray(filterReportDates))
            throw new TypeError('should be an array of DateTime objects');

        return (
            this.reportDate.isValid &&
            filterReportDates.length > 0 &&
            filterReportDates.some((item) => {
                const itemDate = item.toISODate();
                return itemDate === this.reportDate.toISODate();
            })
        );
    }

    /**
     * Serializes this class back to JSON
     * @returns {GeneratedReport}
     */
    toJSON(): GeneratedReport {
        return this.report;
    }
}
