import { PlanStop } from '~/data-classes';

export const scheduleHasMultipleTrips = (schedules: PlanStop[][]) => {
    return schedules.length > 1;
};

export const getScheduleClientRouteId = (schedules: PlanStop[][]) => {
    const [parentSchedule = []] = schedules || [];
    const [{ clientRouteId = '' } = {}] = parentSchedule;

    return clientRouteId || null;
};

export const getRoutePlanStopEffects = ({
    getParentClientRouteId,
    planStops
}: {
    getParentClientRouteId: (clientRouteId: string) => string | undefined;
    planStops: PlanStop[];
}): PlanStop[][] => {
    const newRoutePlanStopEffects = [];
    for (const planStop of planStops) {
        let hasPushed = false;

        for (const planStopRoute of newRoutePlanStopEffects) {
            const parentClientRouteId = getParentClientRouteId(
                planStop.clientRouteId
            );
            const isSameClientRouteId =
                parentClientRouteId === planStopRoute[0].clientRouteId;

            if (!isSameClientRouteId) continue;

            planStopRoute.push(planStop);
            hasPushed = true;
        }

        if (!hasPushed) {
            newRoutePlanStopEffects.push([planStop]);
        }
    }

    return newRoutePlanStopEffects;
};
