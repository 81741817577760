import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import { ApiClient, ClientPreferenceKeys } from '~/api/types';

/**
 * The main client state
 */
export type MainClientState = ApiClient | null;

export const mainClientSlice = createSlice({
    name: 'mainClient',
    initialState: null as MainClientState,
    reducers: {
        setMainClient: (
            state: MainClientState,
            action: PayloadAction<MainClientState>
        ): MainClientState => {
            state = action.payload;
            return state;
        },
        resetMainClient: (): MainClientState => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setMainClient, resetMainClient } = mainClientSlice.actions;

export const selectMainClient = (state: RootState): MainClientState =>
    state.mainClient;

export const selectMainClientPreference =
    (preferenceName: ClientPreferenceKeys) => (state: RootState) =>
        state.mainClient?.preferences?.[preferenceName];

export const selectAllMainClientPreferences = (state: RootState) =>
    state.mainClient?.preferences;

export const selectIsRoutePlannerDisabled = (state: RootState) => {
    const { enableRouteBuilder, enableScheduler } =
        state.mainClient?.preferences || {};
    return !enableScheduler && !enableRouteBuilder;
};

export default mainClientSlice.reducer;
