import { DEFAULT_MAKE_POLYGON_OPTIONS } from './constants';

const { strokeColor: defaultStrokeColor, fillColor: defaultFillColor } =
    DEFAULT_MAKE_POLYGON_OPTIONS;

export const getStrokeColor = (color?: string) => {
    return color || defaultStrokeColor;
};

export const getFillColor = (color?: string) => {
    return color || defaultFillColor;
};

export const hextoRGB = (hex: string, opacity: number | string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (opacity) {
        return `rgba(${r}, ${g}, ${b}, ${opacity.toString()})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
};
