import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, RouteCardTitle, Text } from '~/ui';
import dateUtils from '~/utils/date-utils';
import driverBreakUtils from '~/utils/driver-break-utils';
import { BreakDurationContent } from '../../../AddBreakModal/BreakDurationContent';
import { useChangeHandlers } from '../../../AddBreakModal/useChangeHandlers';

import './EditBreakDuration.scss';

type Props = {
    handleBack: () => void;
    handleClose: () => void;
    handleEditDuration: (durationSeconds: number) => void;
};

function EditBreakDuration({
    handleBack,
    handleClose,
    handleEditDuration
}: Props) {
    const { t } = useTranslation('driverBreakModal');
    const { breakDuration, handleBreakDurationChange } = useChangeHandlers();
    const { isValidBreakDuration } = driverBreakUtils;

    const updateBreakDuration = () => {
        const durationSeconds = dateUtils.convertHoursAndMinutesToSeconds(
            breakDuration.hrs,
            breakDuration.min
        );
        handleEditDuration(durationSeconds);
    };
    return (
        <div data-testid="edit-break-duration-view">
            <div className="edit-break-duration-view__header _d-flex _cg-2 _mb-4">
                <Icon
                    mx="0"
                    icon="chevronDown"
                    color="galaxy-800"
                    onClick={handleBack}
                    data-testid="edit-break-duration-view-chevron-icon"
                />
                <RouteCardTitle
                    className="_text-2"
                    title={t('editDurationView.headerTitle')}
                />
            </div>
            <div className="edit-break-duration-view__body">
                <BreakDurationContent
                    title={t('editDurationView.contentTitle')}
                    duration={breakDuration}
                    handleChange={handleBreakDurationChange}
                />
                <div className="edit-break-duration-view__button">
                    <Button
                        onClick={updateBreakDuration}
                        disabled={!isValidBreakDuration(breakDuration)}
                        data-testid="edit-break-duration-view__confirm-button"
                    >
                        <div className="_ai-center _jc-center">
                            <Text
                                className="_text-3"
                                color="white"
                                variant="12-normal"
                            >
                                {t('editDurationView.confirm')}
                            </Text>
                        </div>
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        data-testid="edit-break-duration-view__cancel-button"
                    >
                        <div className="_ai-center _jc-center">
                            <Text
                                className="_text-3"
                                color="ocean"
                                variant="12-normal"
                            >
                                {t('editDurationView.cancel')}
                            </Text>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default EditBreakDuration;
