/**
 * Enum of supported driver device types
 * @category API Types
 */
export enum VehicleType {
    ENDLOAD = 'truck_endload',
    SIDELOAD = 'truck_sideload'
}

export interface ApiVehicle {
    id?: string;
    licensePlate?: string;
    type?: string;
    makemodel?: string;
    year?: string;
    weightCapacity?: number;
    enableRta?: boolean;
    volumeCapacity?: number;
    description?: string;
    owner?: string;
    client?: string;
    available?: boolean;
    startDepot?: string;
    endDepot?: string;
    eid?: string;
}
