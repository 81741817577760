import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import constants from '~/utils/constants';

export const defaultMapZoom = constants.mapOptionSettings.DEFAULT_ZOOM;
export const maxZoom = constants.mapOptionSettings.MAX_ZOOM;
export const minZoom = constants.mapOptionSettings.MIN_ZOOM;
export const lastPlanMapZoomSlice = createSlice({
    name: 'lastPlanMapZoom',
    initialState: defaultMapZoom,
    reducers: {
        setLastPlanMapZoom: (state, action) => {
            const isNumber = _.isNumber(action.payload);
            if (!isNumber) {
                return state;
            }
            if (action.payload < minZoom) {
                return minZoom;
            }
            if (action.payload > maxZoom) {
                return maxZoom;
            }
            return Math.floor(action.payload);
        },
        resetLastPlanMapZoom: () => {
            return defaultMapZoom;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultMapZoom;
        });
    }
});

export const { setLastPlanMapZoom, resetLastPlanMapZoom } =
    lastPlanMapZoomSlice.actions;
export const selectLastPlanMapZoom = (state) => state.lastPlanMapZoom;
export default lastPlanMapZoomSlice.reducer;
