import React from 'react';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '~/ui';
import { useZones, useZoneVehicleAssociations } from '~/hooks';

import { NoZone } from './NoZone';
import { ListItem } from './ListItem';

export const ZoneList = () => {
    const { data } = useZones();
    const { zoneVehicleAssociations = {} } = useZoneVehicleAssociations();

    const { t } = useTranslation('zoneManagement');
    const zones = data || [];
    const isZonesAvailable = isArray(zones) && Boolean(zones.length);

    return (
        <div
            className="zonemanagement-zonelist"
            data-testid="zone-management-zone-list"
        >
            <div
                className="zonemanagement-zonelist-header heading-1 _d-flex _ai-center"
                data-testid="zone-management-zone-list-header"
            >
                <Icon icon="zoneFilled" />
                <span className="heading-1">{t('zoneList.headerTitle')}</span>
            </div>
            <div
                className="zonemanagement-zonelist-body"
                data-testid="zone-management-zone-list-body"
            >
                {!isZonesAvailable && <NoZone />}
                {isZonesAvailable &&
                    zones.map((zone) => (
                        <ListItem
                            key={zone.id}
                            zone={zone}
                            assignedVehicles={
                                zoneVehicleAssociations[zone.id] || []
                            }
                        />
                    ))}
            </div>
        </div>
    );
};
