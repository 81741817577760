import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    selectDriverUploadResultsByClientId,
    clearDriverUploadResults
} from '~/reducers/uploadResultsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { setDriversByClient } from '~/reducers/driversByClientSlice';
import { setVehicles } from '~/reducers/vehiclesSlice';
import constants from '~/utils/constants';
import driversApi from '~/api/DriversApi';
import { VehicleApi } from '~/api/VehicleApi';

import uploadResultsUtils, {
    getDefaultState
} from '~/utils/upload-results-utils';

import { useIsDriverUploadInitiated } from '../useIsDriverUploadInitiated';

export const useDriverUploadResults = () => {
    const mainClient = useSelector(selectMainClient);
    const clientId = mainClient?.id;
    const uploadResults = useSelector(
        selectDriverUploadResultsByClientId(clientId)
    );
    const [state, setState] = useState(getDefaultState);
    const [latestMessage] = uploadResults.slice(-1);
    const { t } = useTranslation('driverManagement');
    const dispatch = useDispatch();
    const { isDriverUploadInitiated, setIsDriverUploadInitiated } =
        useIsDriverUploadInitiated();

    const getDriversData = async () => {
        try {
            const driversResponse = await driversApi.get();
            const drivers = driversResponse.data.data;
            const driversByClientIds = drivers.reduce((result, driver) => {
                if (!result[driver.client]) {
                    result[driver.client] = [];
                }
                result[driver.client].push(driver);
                return result;
            }, {});
            dispatch(setDriversByClient(driversByClientIds));
        } catch (e) {
            dispatch(setDriversByClient({}));
            console.error(e);
        }
    };

    const getVehiclesData = async () => {
        try {
            const dataResponse = await VehicleApi.get();
            const {
                data: { data: vehicles }
            } = dataResponse;
            const vehiclesById = vehicles.reduce((acc, vehicle) => {
                acc[vehicle.id] = vehicle;
                return acc;
            }, {});
            dispatch(setVehicles(vehiclesById));
        } catch (e) {
            console.error(e);
            dispatch(setVehicles({}));
        }
    };

    useEffect(() => {
        if (!isDriverUploadInitiated || !latestMessage) return;

        const newState = uploadResultsUtils.getNewState({
            latestMessage,
            state
        });
        const {
            isUploaded,
            isUploadSuccessful,
            isUploadComplete,
            uploadStats: { totalCount, status }
        } = newState;
        const inProgress = isUploaded && !isUploadComplete;
        const isError = isUploaded && isUploadComplete && !isUploadSuccessful;
        const processIndicatorState = {
            inProgress,
            error: isError,
            position: 'center',
            type: constants.processIndicator.DRIVER_UPLOAD
        };
        const message = isUploadSuccessful
            ? t('driversAndVehiclesUpload.DriverAndVehicleUploaded', {
                  count: totalCount
              })
            : t('driversAndVehiclesUpload.UploadDriverAndVehicle', {
                  count: totalCount
              });
        setState(newState);
        dispatch(
            addProcessIndicator({
                ...processIndicatorState,
                message
            })
        );

        if (status === constants.uploadStatus.COMPLETE) {
            setIsDriverUploadInitiated(false);
            dispatch(clearDriverUploadResults(clientId));
            getDriversData();
            getVehiclesData();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [latestMessage]);
};
