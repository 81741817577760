import { AutosuggestItem } from '~/api/HereMaps/GeoApi/types';

export const hereMapsPlaceToAddress = (item: AutosuggestItem) => {
    const {
        address,
        position: { lat, lng },
        title
    } = item;
    const { city, postalCode, stateCode, street, houseNumber } = address;
    const [zipCode] = postalCode.split('-');
    const result = {
        addressCity: city,
        addressState: stateCode,
        addressStreet: `${houseNumber} ${street}`,
        addressZipCode: zipCode,
        customerName: title,
        latitude: lat,
        longitude: lng
    };
    return result;
};
