import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GoogleMapsDataFeature } from '~/api/types';
import { useMapContext } from '~/components/ZoneManagementPage/MapContext';
import { GeoJSONObject } from '~/components/ZoneManagementPage/types';

import { useZones } from '~/hooks/index';
import useToastContext from '~/hooks/toastContext';
import { usePageContext } from '~/components/ZoneManagementPage';

type UseEditZoneOnMapReturnValues = {
    handleZoneSave: () => void;
};

export const useEditZoneOnMap = (): UseEditZoneOnMapReturnValues => {
    const { mapInstance } = useMapContext();
    const { data: mapData } = mapInstance || {};

    const { editZone } = useZones();
    const { activeZoneId, setActiveZoneId } = usePageContext();

    const { addToast } = useToastContext();
    const { t } = useTranslation(['zoneManagement', 'error']);

    const [isMapClickListened, setIsMapClickListened] =
        useState<google.maps.MapsEventListener | null>(null);

    // setting up ref because of closure of stale state value in click listener
    const [, setFeatureToEdit] = useState<GoogleMapsDataFeature | null>(null);
    const editedFeatureRef = useRef<GoogleMapsDataFeature | null>(null);
    const setEditedFeature = (feature: GoogleMapsDataFeature | null) => {
        editedFeatureRef.current = feature;
        setFeatureToEdit(feature);
    };

    const [, setInitialFeatureGeometry] =
        useState<google.maps.Data.Geometry | null>(null);
    const initialGeometryRef = useRef<google.maps.Data.Geometry | null>(null);
    const setInitialGeometry = (geometry: google.maps.Data.Geometry | null) => {
        initialGeometryRef.current = geometry;
        setInitialFeatureGeometry(geometry);
    };

    const clearFeature = () => {
        if (editedFeatureRef.current) {
            setEditedFeature(null);
            setInitialGeometry(null);
        }
    };

    const geometryHasChanged = () => {
        const newGeometry = editedFeatureRef?.current?.getGeometry();
        const oldGeometry = initialGeometryRef.current;
        return !_.isEqual(newGeometry, oldGeometry);
    };

    const handleZoneSave = () => {
        if (!editedFeatureRef?.current || !geometryHasChanged()) {
            return;
        }

        editedFeatureRef.current.toGeoJson(async function handleSave(obj) {
            try {
                const geoJsonObject = obj as GeoJSONObject;
                const { geometry } = geoJsonObject;
                const data = {
                    zoneId: editedFeatureRef.current?.getId() as string,
                    update: {
                        geometry
                    }
                };
                await editZone(data);
                addToast({
                    message: t('editZoneOnMap.zoneCoordinateEditSuccess'),
                    variant: 'success'
                });
            } catch (e) {
                console.error(e);
                addToast({
                    message: t('error:zoneCoordinateEditFailed'),
                    variant: 'error'
                });
                editedFeatureRef?.current?.setGeometry(
                    initialGeometryRef?.current
                );
            } finally {
                setActiveZoneId(null);
                clearFeature();
            }
        });
    };

    useEffect(() => {
        if (!activeZoneId) {
            clearFeature();
            return;
        }
        mapData?.forEach((feature) => {
            const isActive = feature.getId() === activeZoneId;
            if (!isActive) {
                return;
            }
            setInitialGeometry(feature.getGeometry());
            setEditedFeature(feature);
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [activeZoneId]);

    useEffect(() => {
        const shouldAddListener = !isMapClickListened && mapInstance;
        if (!shouldAddListener) {
            return;
        }
        const clickListener = mapInstance.addListener('click', handleZoneSave);
        setIsMapClickListened(clickListener);

        return () => {
            // unbind the event to avoid memory leaks
            if (isMapClickListened) {
                (
                    isMapClickListened as unknown as { remove: () => void }
                ).remove();
                setIsMapClickListened(null);
            }
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [activeZoneId]);

    return {
        handleZoneSave
    };
};
