import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApiLiveStop, Coordinates } from '~/api/types';

import {
    useIntermodalFeatures,
    useIsolatedRoutes,
    useMapUtils,
    useOnDemandDispatchMarkerEventHandler
} from '~/hooks';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';

import {
    selectDispatchedDrivers,
    selectCompletedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';

import { makeLiveRoutesComponents } from '~/utils/map-modes/live-routes-stops-mode';
import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';

import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { useDispatchedGetClusters } from '../useDispatchedGetClusters';

import {
    UseDispatchedDriverObjectsProps,
    UseDispatchedDriverObjectsReturnValue
} from './types';

export const useDispatchedDriverObjects = ({
    liveStopsSuperClusters
}: UseDispatchedDriverObjectsProps): UseDispatchedDriverObjectsReturnValue => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance, routesLevelData } = usePlanMapPropsContext();
    const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });
    const onDemandDispatchMarkerEventHandler =
        useOnDemandDispatchMarkerEventHandler();
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );

    const mainClient = useSelector(selectMainClient);
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const completedDrivers = useSelector(selectCompletedDrivers);

    const { driverPrivacy } = mainClient?.preferences || {};

    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            cepLocation: Coordinates;
            schedule: ApiLiveStop[];
        };
    };
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);

    const { isRouteMultiSelectInProgress } = useIsolatedRoutes();
    const { enableLiveDispatch } = useIntermodalFeatures();

    const { getClusters } = useDispatchedGetClusters();

    const liveDrivers = useMemo(() => {
        if (!enableLiveDispatch) return dispatchedDrivers;
        return [...dispatchedDrivers, ...completedDrivers];
    }, [dispatchedDrivers, completedDrivers, enableLiveDispatch]);

    return useMemo(() => {
        if (!isDispatchedRouteMode || isRouteMultiSelectInProgress) {
            return {
                depotStopMarkers: [],
                routeStopMarkers: [],
                routeLines: [],
                routeStopCoordinates: []
            };
        }

        const selectedClientDriverIds = selectedDrawerCard
            ? [selectedDrawerRouteId]
            : selectedMapRoutes;

        const {
            liveRoutesDepotMarkers,
            liveRoutesStopMarkers,
            liveRoutesIsolatedRouteLines
        } = makeLiveRoutesComponents({
            selectedClientDriverIds,
            dispatchedDrivers: liveDrivers,
            onDemandDispatchMarkerEventHandler,
            mapInstance,
            mapRouteMode,
            emittedEventHandler,
            liveStopsSuperClusters,
            getClusters,
            isClusteringStops,
            driverPrivacy
        });

        const selectedDriverStopCoords = selectedClientDriverIds.reduce<
            Coordinates[]
        >((aggregator, clientDriverId) => {
            if (clientDriverId) {
                const [, driverId] = clientDriverId.split('_');
                const driver = liveDrivers.find(({ id }) => id === driverId);
                if (driver) {
                    driver.schedule.forEach((stop: ApiLiveStop) => {
                        aggregator.push(stop.location.location);
                    });
                }
            }
            return aggregator;
        }, []);
        if (selectedDrawerCard) {
            selectedDriverStopCoords.push(selectedDrawerCard.cepLocation);
        }

        return {
            depotStopMarkers: liveRoutesDepotMarkers,
            routeStopMarkers: liveRoutesStopMarkers,
            routeLines: liveRoutesIsolatedRouteLines,
            routeStopCoordinates: selectedDriverStopCoords
        };
    }, [
        emittedEventHandler,
        liveDrivers,
        mapRouteMode,
        isDispatchedRouteMode,
        selectedDrawerCard,
        selectedDrawerRouteId,
        selectedMapRoutes,
        mapInstance,
        getClusters,
        isClusteringStops,
        liveStopsSuperClusters,
        onDemandDispatchMarkerEventHandler,
        isRouteMultiSelectInProgress,
        driverPrivacy
    ]);
};
