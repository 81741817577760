import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import AnalyticsApi from '~/api/AnalyticsApi';
import { DownloadableReports } from '~/data-classes/report';
import { useReportsContext } from '~/components/ReportsPage/ReportsContextProvider';

/**
 * Maximum number of downloadable reports to retrieve from AnalyticsApi
 */
const REPORTS_API_LIMIT = 60;

export const useAggregateDriversDownloads = (
    limit = REPORTS_API_LIMIT,
    reactQueryOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {}
): UseQueryResult => {
    const { selectedClientId } = useReportsContext();

    const getDownloadableReports = async (
        returnLimit: number,
        clientId: string
    ) => {
        const {
            data: { data }
        } = await AnalyticsApi.getAggregateDriversDownloads({
            limit: returnLimit,
            clientId
        });

        return new DownloadableReports(data);
    };

    // initial empty data to prevent errors on first load
    const defaultReactQueryOptions = {
        initialData: new DownloadableReports([])
    };

    // merge query options
    const mergedOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    return useQuery(
        [AnalyticsApi.REACT_QUERY_KEY, limit, selectedClientId],
        () => getDownloadableReports(limit, selectedClientId),
        mergedOptions
    );
};
