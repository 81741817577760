import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    selectIsClusteringEquipment,
    selectShowEquipmentMarker
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { selectEquipment } from '~/reducers/equipmentSlice';

import makeEquipmentClusterMarkers from '~/utils/map-modes/equipment-cluster-mode';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { getFilteredEquipments } from '~/components/MapPage/PlanMap/utils';
import { makeEquipmentMarkerEffects } from '~/components/MapPage/PlanMap/makeEquipmentMarkerEffects';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';

import { useMapUtils } from '~/hooks';

import {
    UseDispatchedEquipmentMarkersProps,
    UseDispatchedEquipmentMarkersReturnValue
} from './types';

import { useDispatchedGetClusters } from '../useDispatchedGetClusters';

export const useDispatchedEquipmentMarkers = ({
    equipmentSuperClusters
}: UseDispatchedEquipmentMarkersProps): UseDispatchedEquipmentMarkersReturnValue => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance } = usePlanMapPropsContext();
    const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();

    const equipment = useSelector(selectEquipment);
    const { globalFilter, columnFilters } = useEquipmentTableStates();

    const isClusteringEquipment = useSelector(
        selectIsClusteringEquipment(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const showEquipmentMarkers = useSelector(
        selectShowEquipmentMarker(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const { getClusters } = useDispatchedGetClusters();

    return useMemo(() => {
        if (
            !isDispatchedRouteMode ||
            !showEquipmentMarkers ||
            !mapInstance ||
            isEmpty(equipment)
        ) {
            return { equipmentMarkers: [] };
        }

        const filteredEquipments = getFilteredEquipments({
            equipment,
            globalFilter,
            columnFilters
        });

        const equipmentMarkers = makeEquipmentMarkerEffects({
            isClusteringStops: isClusteringEquipment,
            equipmentSuperClusters,
            getClusters,
            makeEquipmentClusterMarkers,
            equipment: filteredEquipments
        }) as unknown as JSX.Element[];

        return {
            equipmentMarkers
        };
    }, [
        isDispatchedRouteMode,
        mapInstance,
        equipment,
        equipmentSuperClusters,
        getClusters,
        isClusteringEquipment,
        showEquipmentMarkers,
        globalFilter,
        columnFilters
    ]);
};
