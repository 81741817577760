import { CognitoUserPool } from 'amazon-cognito-identity-js';
import constants from '~/utils/constants';
import authService from '~/auth/aws-cognito-auth-service';
import socketInstance from '~/utils/socket/socket-instance';
import clientsAPI from '~/api/ClientsApi';
import { store } from '~/store';
import { MCWAppInit } from './types';

export const getMcwAppInitFromLocalStorage = () => {
    const item = localStorage.getItem(constants.localStorageKeys.MCW_APP_INIT);
    return item ? (JSON.parse(item) as MCWAppInit) : null;
};

export const refreshSessionToken = async (poolData: CognitoUserPool) => {
    await authService.isSessionExpiring(poolData);
    const mcwAppInit = getMcwAppInitFromLocalStorage();
    const { selectedClients = {} } = store.getState() || {};
    const selectedClientIds = Object.keys(selectedClients);

    if (!mcwAppInit) return;

    const clientsResponse = await clientsAPI.get();
    const accountClientIds = clientsResponse.data.data.map(({ id }) => id);

    const clientIds = selectedClientIds.filter((id) => {
        return accountClientIds.includes(id);
    });

    socketInstance.reconnectToSocketServer({
        clientIds,
        accessToken: mcwAppInit.requestHeaders.authorization,
        socketUrl: mcwAppInit.data.socketUrl
    });
};
