/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';

import Icon, { IconProps } from '../Icon/Icon';
import { filterNilFromObj } from '~/utils/object-utils';

import './radio.scss';

export const radioContainerTestId = 'radio-containerTestId';
export const checkedRadioTestId = 'radio-checkedTestId';
export const unCheckedRadioTestId = 'radio-uncheckedTestId';
export const radioInputTestId = 'radio-inputTestId';

type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

interface RadioIconProps extends Omit<IconProps, 'icon'> {
    checked?: boolean;
}

const RadioIcon = ({ className, checked, ...props }: RadioIconProps) => {
    if (checked) {
        return (
            <Icon
                {...props}
                icon="radioChecked"
                className={classNames(className, 'radio--checked')}
                data-testid={checkedRadioTestId}
            />
        );
    }

    return (
        <Icon
            {...props}
            icon="radioUnchecked"
            className={classNames(className, 'radio--unchecked')}
            data-testid={unCheckedRadioTestId}
        />
    );
};

const Radio = React.forwardRef<HTMLInputElement, InputProps>(function Radio(
    { className, ...props },
    ref
) {
    return (
        <div className="radio-container" data-testid={radioContainerTestId}>
            <input
                ref={ref}
                type="radio"
                data-testid={radioInputTestId}
                {...filterNilFromObj(props)}
                className="radio__input"
            />
            <RadioIcon
                aria-hidden="true"
                className={classNames(className, 'radio__icon')}
                checked={props.checked || props.defaultChecked}
            />
        </div>
    );
});

export default Radio;
