import { useReducer } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

export type CompartmentInput = {
    componentId: string;
    selectedCompartmentId: string;
    capacity: string;
    label: string;
    unit: string;
    isFirst: boolean;
};

export const useMultiCompartmentInputs = (
    customInitialState?: Record<string, CompartmentInput>
) => {
    const firstComponentId = uuid();

    const initialState =
        isEmpty(customInitialState) || !customInitialState
            ? {
                  [firstComponentId]: {
                      componentId: firstComponentId,
                      selectedCompartmentId: '',
                      capacity: '',
                      label: '',
                      unit: '',
                      isFirst: true
                  }
              }
            : customInitialState;

    const multiCompartmentInputsSlice = createSlice({
        name: 'displayedInputs',
        initialState,
        reducers: {
            addInput: (state) => {
                const newId = uuid();
                return {
                    ...state,
                    [newId]: {
                        componentId: newId,
                        selectedCompartmentId: '',
                        capacity: '',
                        label: '',
                        unit: '',
                        isFirst: false
                    }
                };
            },
            removeInput: (state, action) => {
                const componentId = action.payload;
                delete state[componentId];
            },
            updateInput: (state, action) => {
                const { componentId, dropDownItemId, value } = action.payload;
                return {
                    ...state,
                    [componentId]: {
                        ...state[componentId],
                        selectedCompartmentId: dropDownItemId,
                        capacity: value
                    }
                };
            }
        }
    });

    const { addInput, removeInput, updateInput } =
        multiCompartmentInputsSlice.actions;

    const multiCompartmentInputsReducer = multiCompartmentInputsSlice.reducer;

    const [state, dispatch] = useReducer(
        multiCompartmentInputsReducer,
        initialState
    );

    function handleAddCompartmentClick() {
        dispatch(addInput);
    }

    function handleDeleteClick(id: string) {
        dispatch(removeInput(id));
    }

    function handleInputChange(obj: {
        value: string;
        dropDownItemId: string;
        componentId: string;
    }) {
        dispatch(updateInput(obj));
    }

    return {
        displayedInputs: state,
        handleAddCompartmentClick,
        handleDeleteClick,
        handleInputChange
    };
};
