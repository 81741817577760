/* eslint-disable camelcase */
import * as XLSX from 'xlsx';
import { CustomCostMatrix } from '~/data-classes';
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';

import transformToEntries from './transformToEntries';
import transformZoneToZoneSheet from './transformZoneToZoneSheet';
import transformVariableCostsSheet from './transformVariableCostsSheet';
import transformMetaDataSheet from './transformMetaDataSheet';

export class SpreadsheetConversionError extends Error {
    constructor(message, data) {
        super(message);
        this.name = this.constructor.name;
        this.data = data;
    }
}

const convertToJson = (data, zonesData) => {
    const workbook = XLSX.read(data);
    const zones = Object.values(zonesData).reduce(
        (zoneMapping, zone) => {
            const { zoneName, zoneId } = zone;
            zoneMapping[zoneName] = zoneId;
            return zoneMapping;
        },
        { [terms.zone.NOZONE]: terms.zone.NOZONE }
    );

    const zoneToZoneCosts = transformZoneToZoneSheet(
        XLSX.utils.sheet_to_json(
            workbook.Sheets[terms.sheetName.ZONETOZONECOSTS]
        ),
        zones
    );

    const variableCosts = transformVariableCostsSheet(
        XLSX.utils.sheet_to_json(
            workbook.Sheets[terms.sheetName.VARIABLECOSTS]
        ),
        zones
    );

    const metaData = transformMetaDataSheet(
        XLSX.utils.sheet_to_json(workbook.Sheets[terms.sheetName.METADATA])
    );

    if (
        !zoneToZoneCosts.hasErrors &&
        !variableCosts.hasErrors &&
        !metaData.hasErrors
    ) {
        const {
            metaDataEntries: { name, service_cost, service_name }
        } = metaData;
        const entries = transformToEntries([
            ...Object.values(zoneToZoneCosts.zoneEntries),
            ...Object.values(variableCosts.variableEntries)
        ]);
        const customCostMatrix = new CustomCostMatrix({
            name,
            active: true,
            entries
        });
        const taskServices = { service_cost, service_name };

        return {
            customCostMatrix,
            taskServices
        };
    }

    return {
        errors: [
            ...Object.values(metaData.metaDataErrors),
            ...Object.values(zoneToZoneCosts.zoneErrors),
            ...Object.values(variableCosts.variableErrors)
        ]
    };
};

export default convertToJson;
