import { Invoice } from '../../utils/addTaskModalUtils';
import { useAddTaskModalContext } from '../../AddTaskModalContext';

type Props = {
    invoiceId?: string;
};
export const useOnInvoiceUpdate = ({ invoiceId }: Props) => {
    const {
        clientTask: { invoices = [] },
        onInvoicesChange
    } = useAddTaskModalContext();

    return (invoiceField: keyof Invoice, value: string) => {
        const index = invoices.findIndex(
            ({ frontendOnlyId }) => frontendOnlyId === invoiceId
        );

        if (index === -1) return;

        const existingInvoice = invoices[index];
        const newInvoice: Invoice = {
            ...existingInvoice,
            [invoiceField]: value
        };
        const newInvoices = [...invoices];
        newInvoices[index] = newInvoice;
        onInvoicesChange(newInvoices);
    };
};
