import React from 'react';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';

import { DriverMarkerPopup, RouteMarker } from '~/ui';
import { getExceptionIcons } from '~/ui/components/LiveRoutesMarker/utils';
import { colorUtils } from '~/utils/color-utils';
import constants from '~/utils/constants';

/**
 * Map Mode Utilities, Completed Routes Mode
 *
 * @category Utils
 * @module utils/map-modes/completed-routes-mode
 *
 * @example
 * import makeCompletedComponents from '~/utils/map-modes/completed-routes-mode';
 */

/**
 * @typedef CompletedComponents
 * @type {Object}
 * @property {RouteMarker[]} completedRouteMarkers an array of RouteMarker components
 * @property {Coordinates[]} completedRouteMarkerCoordinates an array for coordinates
 */

/**
 * Makes route markers out of completed drivers to be displayed at the
 * average location of all stops on the route
 * @param {Object[]} completedDrivers list of completed drivers
 * @returns {CompletedComponents}
 */
function makeCompletedComponents(completedDrivers) {
    return completedDrivers.reduce(
        (acc, cd) => {
            const completedDriver = new LiveDriver(cd);
            const colorCSS = colorUtils.getWebColorsForId(
                completedDriver.clientDriverId
            );

            const driverPopup = (
                <DriverMarkerPopup
                    driverData={completedDriver}
                    exceptionData={getExceptionIcons(completedDriver)}
                />
            );

            const routeMarker = (
                <RouteMarker
                    key={completedDriver.id}
                    routeLevelData={completedDriver}
                    lat={completedDriver.routeCenter.lat}
                    lng={completedDriver.routeCenter.lng}
                    mapRouteMode={constants.mapRouteModes.COMPLETED}
                    colorCSS={colorCSS}
                    hoverPopup={driverPopup}
                />
            );

            return {
                completedRouteMarkers: [
                    ...acc.completedRouteMarkers,
                    routeMarker
                ],
                completedRouteMarkerCoordinates: [
                    ...acc.completedRouteMarkerCoordinates,
                    completedDriver.routeCenter
                ]
            };
        },
        {
            completedRouteMarkers: [],
            completedRouteMarkerCoordinates: []
        }
    );
}

export default makeCompletedComponents;
