import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { planRouteUtils } from '~/utils/plan-route-utils';
import { selectAllSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { PlanRoute } from '~/data-classes';

interface UseUpdateSelectedDrawerCardDataProps {
    driverId: string;
    numTasks: number;
    routeId: string;
    vehicleId: string;
    handleUpdate: () => void;
}

export const useUpdateSelectedDrawerCardData = ({
    driverId,
    handleUpdate,
    numTasks,
    routeId,
    vehicleId
}: UseUpdateSelectedDrawerCardDataProps) => {
    const allSelectedDrawerCardData = useSelector(
        selectAllSelectedDrawerCardData
    );

    useEffect(() => {
        allSelectedDrawerCardData.forEach(
            ({ data: selectedDrawerCardData }) => {
                const {
                    driver,
                    route,
                    vehicle: { vehicleId: drawerCardVehicleId }
                } = planRouteUtils.serializeRouteData(
                    selectedDrawerCardData as PlanRoute
                );
                const { driverId: drawerCardDriverId } = driver ?? {};
                const { stats, routeId: drawerCardRouteId } = route ?? {};
                const { numTasks: drawerCardNumberOfTasks } = stats ?? {};
                const isDifferentDrawerClicked =
                    drawerCardDriverId !== driverId ||
                    drawerCardVehicleId !== vehicleId ||
                    numTasks !== drawerCardNumberOfTasks;

                if (drawerCardRouteId === routeId && isDifferentDrawerClicked) {
                    handleUpdate();
                }
            }
        );
    }, [
        allSelectedDrawerCardData,
        driverId,
        numTasks,
        routeId,
        vehicleId,
        handleUpdate
    ]);
};
