import { cloneDeep } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import dateUtils, { DAYS_OF_WEEK } from '~/utils/date-utils';
import ShiftBlock from '~/components/DriverVehicleManagementPage/DriverVehicleDetailDrawer/DriverDetail/ShiftBlock';
import { LongShiftBlock } from '~/components/DriverVehicleManagementPage/DriverVehicleDetailDrawer/DriverDetail/LongShiftBlock';
import { createShiftListByDayOfWeek } from './utils';

function addMissingDays(shifts) {
    for (const key in DAYS_OF_WEEK) {
        if (!shifts[key]) {
            shifts[key] = [];
        }
    }
    return shifts;
}

const transformLongShiftToDuration = ({
    start = '',
    hours = '',
    minutes = ''
}) => {
    return {
        start,
        duration: Duration.fromObject({
            hours: Number(hours),
            minutes: Number(minutes)
        }).toISO()
    };
};

function transformShiftEndToDuration(timeslot) {
    const { start, end } = timeslot;
    const startDateTime = DateTime.fromFormat(start, 'T');
    const endDateTime = DateTime.fromFormat(end, 'T');
    return {
        start,
        duration: dateUtils.getDurationString(startDateTime, endDateTime)
    };
}

export const useDriverShifts = (initialShifts) => {
    const [shifts, setShifts] = useState(
        createShiftListByDayOfWeek(initialShifts)
    );
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    /**
     * Edits shift element with matching id with provided data
     * @param {string} id shift id
     * @param {string} newData object containing days and shift with start and end time
     */
    const handleShiftChange = (id, newData) => {
        const newShifts = shifts.map((item) => {
            if (item.id !== id) return item;

            return { ...item, ...newData };
        });
        setShifts(newShifts);
    };

    /**
     * Remove shift element with matching id from shifts array
     * @param {string} id shift id
     */
    const handleShiftBlockRemove = (id) => {
        const newShifts = cloneDeep(shifts);
        const removedShift = newShifts.find((shift) => shift.id === id);
        const removedIndex = newShifts.indexOf(removedShift);
        newShifts.splice(removedIndex, 1);
        setShifts(newShifts);
    };

    /**
     * Add a new empty shift element to shifts array
     */
    const handleAddNewShiftBlock = () => {
        const newShifts = cloneDeep(shifts);
        const newShiftBlock = {
            id: uuidV4(),
            days: [],
            shift: [{ start: '', end: '' }]
        };
        newShifts.push(newShiftBlock);
        setShifts(newShifts);
    };

    const handleAddNewLongShiftBlock = () => {
        const newShifts = cloneDeep(shifts);
        const newShiftBlock = {
            id: uuidV4(),
            days: [],
            shift: [{ start: '', hours: '0', minutes: '0', isLongShift: true }]
        };
        newShifts.push(newShiftBlock);
        setShifts(newShifts);
    };

    /**
     * Add a slot to the shift with matching id in shifts array
     * @param {string} id shift id
     */
    const handleAddNewSlot = (id) => {
        const newShifts = shifts.map((item) => {
            if (item.id !== id) return item;
            return {
                ...item,
                shift: [...item.shift, { start: '', end: '' }]
            };
        });
        setShifts(newShifts);
    };

    /**
     * Remove a slot from the shift with matching id in shifts array
     * @param {string} id shift id
     */
    const handleShiftSlotRemove = (id) => {
        const newShifts = shifts.map((item) => {
            const isEdited = item.id === id;
            if (isEdited) {
                item.shift = item.shift.slice(0, -1);
            }
            return item;
        });
        setShifts(newShifts);
    };

    /**
     * Returns shifts object that can used as `shiftTimes` field of driver object
     * @returns {Object} contains info on individual shift times with start and duration fields
     */
    const getShiftsToPersist = () => {
        const shiftsToPersist = shifts.reduce((acc, item) => {
            const selectedDays = item.days;
            const shiftSlots = item.shift;
            const shiftsWithDuration = shiftSlots.map((shiftSlot) => {
                const { isLongShift } = shiftSlot;
                return isLongShift
                    ? transformLongShiftToDuration(shiftSlot)
                    : transformShiftEndToDuration(shiftSlot);
            });
            selectedDays.forEach((day) => {
                if (acc[day]) {
                    acc[day] = [...acc[day], ...shiftsWithDuration];
                } else {
                    acc[day] = shiftsWithDuration;
                }
            });
            return acc;
        }, {});
        return addMissingDays(shiftsToPersist);
    };

    /**
     * Returns an array of ShiftBlock components, 1 for each shift in shifts array
     * @returns {Array} ShiftBlock components that can be rendered
     */
    const renderShifts = () => {
        return shifts.map((item) => {
            const { days, shift, id } = item;
            const { isLongShift } = shift[0] ?? {};

            if (isLongShift) {
                return (
                    <LongShiftBlock
                        id={id}
                        key={id}
                        data={{
                            days,
                            shift
                        }}
                        onChange={handleShiftChange}
                        onShiftBlockRemove={handleShiftBlockRemove}
                    />
                );
            }

            return (
                <ShiftBlock
                    id={id}
                    key={id}
                    data={{
                        days,
                        shift
                    }}
                    onChange={handleShiftChange}
                    onShiftBlockRemove={handleShiftBlockRemove}
                    onAddNewSlot={handleAddNewSlot}
                    onShiftSlotRemove={handleShiftSlotRemove}
                />
            );
        });
    };

    useEffect(() => {
        const validateSave = () => {
            const isDisabled = shifts.some((item) => {
                const isDaysNotProvided = !item.days.length;
                const isSomeSlotsWithoutStartOrEnd = item.shift.some(
                    ({ start, end, hours, minutes, isLongShift }) => {
                        if (isLongShift) return !(start && hours && minutes);
                        return !start || !end;
                    }
                );

                return isDaysNotProvided || isSomeSlotsWithoutStartOrEnd;
            });
            setIsSaveDisabled(isDisabled);
        };
        validateSave();
    }, [shifts, isSaveDisabled]);

    return {
        shifts,
        isSaveDisabled,
        handleShiftChange,
        handleShiftBlockRemove,
        handleAddNewShiftBlock,
        handleAddNewSlot,
        handleShiftSlotRemove,
        getShiftsToPersist,
        renderShifts,
        handleAddNewLongShiftBlock
    };
};
