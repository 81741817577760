import { useContext } from 'react';
import { Context } from './types';
import { PageContext } from './PageContext';

export const usePageContext = (): Context => {
    const context = useContext(PageContext);

    if (!context) {
        throw new Error('usePageContext must be within context provider');
    }

    return context;
};
