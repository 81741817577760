import { LiveStop } from '~/data-classes';
import Supercluster from 'supercluster';
import { markerMaker } from '~/utils/map';
import { AssignedWebColor } from '~/api/types';
import { MapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { HookOnDemandDispatchMarkerEventHandler } from '~/hooks';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { makeLiveClusterMarkers } from '~/utils/map-modes/live-stops-clusters-mode';

interface MakeLiveStopMarkersProps {
    mapRouteMode: MapRouteMode;
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    driverColor: AssignedWebColor;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    getClusters: (
        superCluster: Supercluster.AnyProps
    ) => Supercluster.AnyProps[];
    isClusteringStops: boolean;
}

export const makeLiveStopMarkers = ({
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    driverColor,
    emittedEventHandler,
    liveStopsSuperClusters,
    getClusters,
    isClusteringStops
}: MakeLiveStopMarkersProps): JSX.Element[] => {
    const markers: JSX.Element[] = [];
    for (const superCluster of liveStopsSuperClusters) {
        const geoJSONFeatures = getClusters(superCluster);
        const clusterIndex = liveStopsSuperClusters.indexOf(superCluster);

        if (isClusteringStops) {
            return makeLiveClusterMarkers({
                superCluster,
                geoJSONFeatures,
                superClusterIndex: clusterIndex,
                emittedEventHandler,
                onDemandDispatchMarkerEventHandler,
                driverColor,
                mapRouteMode
            });
        }

        const clusterMarkers = geoJSONFeatures.flatMap((geoJSONFeature) => {
            const isCluster = geoJSONFeature.properties.cluster;
            const leaves = isCluster
                ? superCluster.getLeaves(geoJSONFeature.id, Infinity, 0)
                : [geoJSONFeature];

            return leaves.map((leaf: LiveStop) =>
                markerMaker.makeLiveStopMarker({
                    leaf,
                    mapRouteMode,
                    onDemandDispatchMarkerEventHandler,
                    emittedEventHandler,
                    driverColor
                })
            );
        });
        markers.push(...clusterMarkers);
    }
    return markers;
};
