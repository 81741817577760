import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { TooltipButton } from '~/ui';
import {
    TooltipButtonVariants,
    TooltipButtonSizes
} from '~/ui/components/TooltipButton';
import { useDuplicateTaskModalContext } from '../DuplicateTaskModalContext';

export const DuplicateTaskModalFooter = () => {
    const rootClassName = 'duplicate-task-modal-footer';
    const { t } = useTranslation('duplicateTaskModal');
    const { onClose } = useDuplicateTaskModalContext();
    const { submitForm } = useFormikContext();
    const onSubmit = () => {
        submitForm().catch((error) => console.error(error));
    };

    return (
        <div className={`${rootClassName} _d-flex _jc-flex-end`}>
            <TooltipButton
                variant={TooltipButtonVariants.SECONDARY}
                size={TooltipButtonSizes.LARGE}
                onClick={onClose}
            >
                {t('footer.cancel')}
            </TooltipButton>
            <TooltipButton
                className={`${rootClassName}__confirm`}
                variant={TooltipButtonVariants.PRIMARY}
                size={TooltipButtonSizes.LARGE}
                onClick={onSubmit}
            >
                {t('footer.confirm')}
            </TooltipButton>
        </div>
    );
};
