import React from 'react';
import classnames from 'classnames';

import './CompartmentUsageDetails.scss';
import { CompartmentLabel } from '~/ui';
import { CompartmentStat } from '~/api/types';

const summaryDefaultClassName = 'compartment-usage-details__summary';

interface CompartmentUsageDetailsProps {
    compartment: CompartmentStat;
    isIndicateOverCapacity?: boolean;
}

const CompartmentUsageDetails = ({
    compartment,
    isIndicateOverCapacity
}: CompartmentUsageDetailsProps): JSX.Element => {
    const { label, capacityUsed, totalAvailableCapacity } = compartment;
    const mappedCapacityUsed = Math.floor(capacityUsed);
    const mappedTotalAvailableCapacity = Math.floor(totalAvailableCapacity);
    const isOverCapacity = mappedCapacityUsed > mappedTotalAvailableCapacity;
    const compartmentSummary = `${mappedCapacityUsed}/${mappedTotalAvailableCapacity}`;
    const summaryClassName = classnames(summaryDefaultClassName, {
        [`${summaryDefaultClassName}--over-capacity`]:
            isIndicateOverCapacity && isOverCapacity
    });

    return (
        <div
            className="compartment-usage-details"
            data-testid="compartment-usage-details"
        >
            <CompartmentLabel
                label={label}
                className="compartment-usage-details__label"
            />
            <span className={summaryClassName}>{compartmentSummary}</span>
        </div>
    );
};

export default CompartmentUsageDetails;
