import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDrawingMapOptions, useEditZoneOnMap } from '~/hooks';
import { useDisplayZonesOnMap } from './useDisplayZonesOnMap';
import { useMapContext } from '../MapContext';

import { selectLastPlanMapCenter } from '~/reducers/lastPlanMapCenterSlice';

import { Map as MapUi } from '~/ui';
import { MapOverlay } from '../MapOverlay';
import constants from '~/utils/constants';

const drawingMode = constants.mapDrawingModes
    .POLYGON as google.maps.drawing.OverlayType.POLYGON;

export const ZoneMap = (): JSX.Element => {
    const lastPlanMapCenter = useSelector(selectLastPlanMapCenter);
    const { isDrawingMode, drawingManagerInstance, mapInstance } =
        useMapContext();

    const mapOptions = useDrawingMapOptions({
        mapCenter: lastPlanMapCenter,
        defaultZoom: constants.zoneMap.DEFAULT_ZOOM
    });

    useDisplayZonesOnMap();
    useEditZoneOnMap();

    useEffect(() => {
        const newDrawingMode = isDrawingMode ? drawingMode : null;
        const currentDrawingMode = drawingManagerInstance?.getDrawingMode();

        if (newDrawingMode !== currentDrawingMode) {
            drawingManagerInstance?.setDrawingMode(newDrawingMode);
        }
    }, [isDrawingMode, drawingManagerInstance]);

    return (
        <MapUi
            googleMapOptions={mapOptions}
            overlay={<MapOverlay mapInstance={mapInstance} />}
            dataTestId="zone-map"
        />
    );
};
