import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

/**
 * The selected card driver state
 */
type SelectedCardDriverState = {
    changeDriverControlActive: boolean;
    changeDriverVehicleControlActive: boolean;
};

const defaultSelectedCardDriver: SelectedCardDriverState = {
    changeDriverControlActive: false,
    changeDriverVehicleControlActive: false
};

export const selectedCardDriverSlice = createSlice({
    name: 'selectedCardDriver',
    initialState: defaultSelectedCardDriver,
    reducers: {
        /**
         * set whether ChangeDriverControl is shown or not
         * @method setChangeDriverControlActive
         * @param {SelectedCardDriverState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {SelectedCardDriverState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setChangeDriverControlActive } from '~/reducers/selectedCardDriverSlice';
         * import { useDispatch } from 'react-redux';
         *
         * const dispatch = useDispatch();
         * dispatch(setChangeDriverControlActive(true));
         */
        setChangeDriverControlActive: (
            state: SelectedCardDriverState,
            action: PayloadAction<boolean>
        ): SelectedCardDriverState => {
            state.changeDriverControlActive = action.payload;
            return state;
        },
        /**
         * set whether ChangeDriverVehicleControl is shown or not
         * @method setChangeDriverVehicleControlActive
         * @param {SelectedCardDriverState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {SelectedCardDriverState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setChangeDriverVehicleControlActive } from '~/reducers/selectedCardDriverSlice';
         * import { useDispatch } from 'react-redux';
         *
         * const dispatch = useDispatch();
         * dispatch(setChangeDriverVehicleControlActive(true));
         */
        setChangeDriverVehicleControlActive: (
            state: SelectedCardDriverState,
            action: PayloadAction<boolean>
        ): SelectedCardDriverState => {
            state.changeDriverVehicleControlActive = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultSelectedCardDriver;
        });
    }
});

export const {
    setChangeDriverControlActive,
    setChangeDriverVehicleControlActive
} = selectedCardDriverSlice.actions;

/**
 * selects the current state of changeDriverControlActive whether it is displayed or not
 * @method
 * @returns {boolean} the current state of changeDriverControlActive
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsChangeDriverControlActive } from '~/reducers/selectedCardDriverSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the current ChangeDriverControl state
 * const isChangeDriverControlActive = useSelector(selectIsChangeDriverControlActive);
 */
export const selectIsChangeDriverControlActive = (state: RootState): boolean =>
    state.selectedCardDriver.changeDriverControlActive;

/**
 * selects the current state of changeDriverVehicleControlActive whether it is displayed or not
 * @method
 * @returns {boolean} the current state of changeDriverVehicleControlActive
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsChangeDriverControlActive } from '~/reducers/selectedCardDriverSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the current ChangeDriverVehicleControl state
 * const isChangeDriverVehicleControlActive = useSelector(selectIsChangeDriverVehicleControlActive);
 */
export const selectIsChangeDriverVehicleControlActive = (
    state: RootState
): boolean => state.selectedCardDriver.changeDriverVehicleControlActive;

export default selectedCardDriverSlice.reducer;
