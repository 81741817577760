import { markerMaker } from '~/utils/map';
import Supercluster from 'supercluster';
import { AssignedWebColor } from '~/utils/color-utils';
import { MapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { HookOnDemandDispatchMarkerEventHandler } from '~/hooks';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';

interface MakeLiveClusterMarkersProps {
    superClusterIndex: number;
    superCluster: Supercluster.AnyProps;
    geoJSONFeatures: Supercluster.AnyProps[];
    emittedEventHandler: EmittedEventHandler;
    mapRouteMode: MapRouteMode;
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    driverColor: AssignedWebColor;
}

export const makeLiveClusterMarkers = ({
    superCluster,
    geoJSONFeatures,
    superClusterIndex,
    emittedEventHandler,
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    driverColor
}: MakeLiveClusterMarkersProps) => {
    return geoJSONFeatures.map((geoJSONFeature) => {
        const isCluster = geoJSONFeature.properties.cluster;
        if (isCluster) {
            return markerMaker.makeClusterMarker({
                superCluster,
                geoJSONFeature,
                superClusterIndex,
                emittedEventHandler,
                onDemandDispatchMarkerEventHandler,
                driverColor
            });
        }
        return markerMaker.makeLiveStopMarker({
            leaf: geoJSONFeature,
            mapRouteMode,
            onDemandDispatchMarkerEventHandler,
            emittedEventHandler,
            driverColor
        });
    });
};
