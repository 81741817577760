import React from 'react';

import { ZoneMap } from '../ZoneMap';
import { AddZoneButton } from '../AddZoneButton';
import { Stats } from '../Stats';
import { PrintMapButton } from '../PrintMapButton';

export const MapSection = () => {
    return (
        <div
            className="zonemanagement-mapsection"
            data-testid="zone-management-map-section"
        >
            <Stats />
            <div className="zonemanagement-mapsection-map _p-relative">
                <AddZoneButton />
                <PrintMapButton />
                <ZoneMap />
            </div>
        </div>
    );
};
