import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { Icon } from '~/ui';
import FuzzyTextSearch from '~/ui/components/FuzzyTextSearch/FuzzyTextSearch';
import { useDepotCoordinates } from '~/hooks';
import { useAutosuggestPlaces } from '~/hooks/HereMaps';
import { hereMapsPlaceToAddress } from '~/utils/map/hereMaps';
import { AddressInputFields } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { AutosuggestItem } from '~/api/HereMaps/GeoApi/types';

import './placesAutocomplete.scss';

interface PlacesAutocompleteProps {
    placeholder?: string;
    onSelect: (item: AddressInputFields) => void;
}

const rootClassName = 'places-autocomplete';

const ResultItem = ({
    item,
    onClick
}: {
    item: AutosuggestItem;
    onClick: (item: AutosuggestItem) => void;
}) => {
    const { address } = item;
    const { label } = address || {};
    if (!address || !label) {
        return null;
    }
    const handleClick = () => {
        onClick(item);
    };

    return (
        <div
            className={`${rootClassName}__result _d-flex _ai-center`}
            data-testid="search-result"
        >
            <Icon
                icon="pin"
                color="galaxy-500"
                size="s"
                className={`${rootClassName}__icon`}
            />
            <button
                type="button"
                className={`${rootClassName}__select-button`}
                onClick={handleClick}
                onKeyDown={handleClick}
                data-testid="result-button"
            >
                {label}
            </button>
        </div>
    );
};

export const PlacesAutocomplete = ({
    placeholder = '',
    onSelect
}: PlacesAutocompleteProps) => {
    const [queryString, setQueryString] =
        useState<string | undefined>(undefined);

    const [debouncedQueryString] = useDebounce(queryString, 500);

    const {
        southwestLatitude,
        southwestLongitude,
        northeastLatitude,
        northeastLongitude
    } = useDepotCoordinates();

    const bounds = useMemo(() => {
        return [
            southwestLongitude,
            southwestLatitude,
            northeastLongitude,
            northeastLatitude
        ];
    }, [
        southwestLatitude,
        southwestLongitude,
        northeastLatitude,
        northeastLongitude
    ]);

    const autocompleteEnabled = Boolean(
        debouncedQueryString && debouncedQueryString.length >= 3
    );

    const { items } = useAutosuggestPlaces({
        queryString: debouncedQueryString,
        enabled: autocompleteEnabled,
        bounds
    });

    const handleItemSelect = useCallback(
        (item: AutosuggestItem) => {
            const addressInfo = hereMapsPlaceToAddress(item);
            onSelect(addressInfo);
            setQueryString(undefined);
        },
        [onSelect]
    );

    const results = useMemo(() => {
        if (!items?.length) {
            return null;
        }
        return items.map((item) => {
            const { id } = item;
            return (
                <ResultItem item={item} key={id} onClick={handleItemSelect} />
            );
        });
    }, [items, handleItemSelect]);

    return (
        <div className={rootClassName}>
            <FuzzyTextSearch
                placeholder={placeholder}
                className={`${rootClassName}__input`}
                handleOnChange={(value: string) => setQueryString(value)}
            >
                {results}
            </FuzzyTextSearch>
        </div>
    );
};
