import { useEffect, useState } from 'react';
import workflowEventsApi from '~/api/workflow-events';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';

function getLatestEventOfType(events, eventTypes) {
    return events.reduce(
        (res, curr) =>
            eventTypes.includes(curr.name) &&
            (!res || new Date(curr.created_at) > new Date(res.created_at))
                ? curr
                : res,
        null
    );
}

export const useLatestWorkflowEvents = (selectedDate) => {
    const [state, setState] = useState({
        latestDriverBookingEvent: undefined,
        latestTaskVerificationEvent: undefined,
        latestRouteOptimizationEvent: undefined,
        latestReminderDatePerClientId: {}
    });

    useEffect(() => {
        if (selectedDate)
            (async () => {
                try {
                    const { data } = await workflowEventsApi.get({
                        route_date: [
                            dateUtils.convertToISODateOnly(selectedDate)
                        ]
                    });

                    const events = data.data.successes;
                    const consts = constants.workflowEvents;

                    const latestRouteOptimizationEvent = getLatestEventOfType(
                        events,
                        [
                            consts.OPTIMIZATION_UNCOMPLETED,
                            consts.OPTIMIZATION_COMPLETED
                        ]
                    );

                    const latestTaskVerificationEvent = getLatestEventOfType(
                        events,
                        [
                            consts.TASK_VERIFICATION_UNCOMPLETED,
                            consts.TASK_VERIFICATION_COMPLETED
                        ]
                    );

                    const latestDriverBookingEvent = getLatestEventOfType(
                        events,
                        [
                            consts.DRIVER_BOOKING_UNCOMPLETED,
                            consts.DRIVER_BOOKING_COMPLETED
                        ]
                    );

                    const allReminders = events.filter(
                        (e) =>
                            e.name ===
                            consts.DRIVER_BOOKING_REMINDER_NOTIFICATION_SENT
                    );

                    const latestReminderDatePerClientId = allReminders.reduce(
                        (acc, curr) => {
                            if (
                                !acc[curr.client_id] ||
                                new Date(curr.created_at) >
                                    new Date(acc[curr.client_id])
                            ) {
                                acc[curr.client_id] = curr.created_at;
                            }
                            return acc;
                        },
                        {}
                    );

                    setState({
                        latestTaskVerificationEvent,
                        latestDriverBookingEvent,
                        latestRouteOptimizationEvent,
                        latestReminderDatePerClientId
                    });
                } catch (e) {
                    console.error(e);
                }
            })();
    }, [selectedDate]);

    return state;
};
