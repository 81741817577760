import { useEffect, useState, useRef } from 'react';
import { TimeoutId } from '@datadog/browser-core';

interface UseDelayedLoader {
    isLoading: boolean;
    delay: number;
}

export const useDelayedLoader = ({ isLoading, delay }: UseDelayedLoader) => {
    const [shouldShowLoader, setShouldShowLoader] = useState<boolean>(false);

    const timeoutId = useRef<TimeoutId | null>(null);

    useEffect(() => {
        const { current } = timeoutId || {};
        if (isLoading) {
            timeoutId.current = setTimeout(() => {
                setShouldShowLoader(true);
            }, delay);
        } else {
            if (timeoutId.current) {
                clearTimeout(current as TimeoutId);
            }
            setShouldShowLoader(false);
        }
        return () => {
            if (current) {
                clearTimeout(current);
            }
            setShouldShowLoader(false);
        };
    }, [isLoading, delay]);

    return {
        shouldShowLoader
    };
};
