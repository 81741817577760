import React, { forwardRef, useState, Ref } from 'react';
import { Box, FlexLayout, Icon, Text, theme } from '~/ui';
import { SortOption } from '~/components/MapPageDrawers/RoutesDrawer/RoutesDrawerSort/RoutesDrawerSort';

interface SingleSelectProps {
    list: SortOption[];
    selected?: string | null;
    prefix?: string;
    variant?: 'primary';
    onItemChange?: (key: string) => void;
    sx?: object;
    'data-testid'?: string;
}

const variantStyles = {
    primary: {
        border: `solid 0.1rem ${theme.colors['galaxy-200']}`,
        borderRadius: '0.8rem',
        color: 'galaxy-800',
        backgroundColor: 'comet',
        textVariant: '14-normal',
        dropdown: {
            borderRadius: '0.4rem',
            boxShadow: '0 0.2rem 0.6rem 0 var(--color-box-shadow)'
        }
    }
};

const SingleSelect = forwardRef(function SingleSelect(
    {
        list,
        selected = null,
        prefix,
        variant = 'primary',
        onItemChange,
        sx,
        ...extra
    }: SingleSelectProps,
    ref: Ref<HTMLDivElement>
) {
    const [isListOpen, setIsListOpen] = useState(false);
    const [selectedItemKey, setSelectedItemKey] =
        useState<string | null>(selected);

    if (!list || list.length === 0) {
        return null;
    }

    const selectedStyle = variantStyles[variant];
    const dataTestId = extra['data-testid'] || 'single-select';

    function toggleList() {
        setIsListOpen(!isListOpen);
    }

    function currentTitle() {
        const item = list.find((i) => i.key === selectedItemKey);
        const text = item ? item.value : '';
        return `${prefix || ''}${text}`;
    }

    function changeSelectedItem(key: string) {
        setSelectedItemKey(key);
        toggleList();
        return onItemChange && onItemChange(key);
    }

    return (
        <Box
            ref={ref}
            sx={{ position: 'relative', ...sx }}
            data-testid={dataTestId}
            {...extra}
        >
            <FlexLayout
                alignItems="center"
                padding="0.6rem 1.2rem"
                justifyContent="space-between"
                sx={{
                    border: selectedStyle.border,
                    borderRadius: selectedStyle.borderRadius,
                    backgroundColor: selectedStyle.backgroundColor
                }}
                onClick={toggleList}
                data-testid={`${dataTestId}_dropdown`}
            >
                <Text
                    disabled
                    color={selectedStyle.color}
                    variant={selectedStyle.textVariant}
                    paddingRight="1.5rem"
                    data-testid={`${dataTestId}_title`}
                >
                    {currentTitle()}
                </Text>
                <Icon icon="chevronDown" data-testid="chevronDown" />
            </FlexLayout>
            {isListOpen && (
                <FlexLayout
                    flexDirection="column"
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: selectedStyle.dropdown.borderRadius,
                        boxShadow: selectedStyle.dropdown.boxShadow,
                        py: '0.8rem',
                        position: 'absolute',
                        top: '4.4rem',
                        width: 'calc(100% - 1.5rem)',
                        zIndex: theme.zIndex.mapControls
                    }}
                >
                    {list.map((item) => (
                        <FlexLayout
                            justifyContent="space-between"
                            key={item.key}
                            padding="1rem 1.0rem 1rem 1.6rem"
                            onClick={() => changeSelectedItem(item.key)}
                            data-testid={`${dataTestId}_dropdown-item`}
                        >
                            <Text
                                color={selectedStyle.color}
                                variant={selectedStyle.textVariant}
                            >
                                {item.value}
                            </Text>
                            {item.key === selectedItemKey && (
                                <Icon icon="checkmark" />
                            )}
                        </FlexLayout>
                    ))}
                </FlexLayout>
            )}
        </Box>
    );
});

export default SingleSelect;
