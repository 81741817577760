import { ApiLiveDriver } from '~/api/types';

export const getFilteredDriver = (
    dispatchedDrivers: ApiLiveDriver[],
    selectedMapStopsIds: string[]
) => {
    return dispatchedDrivers.find(({ schedule }) =>
        schedule.some(({ id: stopId }) => selectedMapStopsIds.includes(stopId))
    );
};
