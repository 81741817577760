import axios, { AxiosResponse } from 'axios';
import {
    ICustomerApiResponse,
    ICustomerData,
    ApiCustomerNotes,
    AxiosApiResponse
} from './types';
import generalUtils from '~/utils/general-utils';

/**
 * Possible errors for /customer API
 * @category API
 */
export enum CustomersApiErrors {
    MISSING_CUSTOMER_ID = 'Missing customerId parameter',
    INVALID_CUSTOMER_ID = 'Invalid customerId parameter, must be a UUID string'
}

/**
 * Implementations of API methods under the /customer path
 *
 * @category API
 */
export default class CustomersApi {
    /**
     * Path of the API endpoint
     */
    private static readonly customerPath = '/customer';

    private static readonly customersPath = '/customers';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY_CUSTOMER = 'customer';

    static getById(id: string): Promise<AxiosResponse<ICustomerApiResponse>> {
        return axios.get(`${this.customerPath}/${id}?extent=address`);
    }

    static searchCustomers(
        searchTerm: string,
        limit = 5
    ): Promise<AxiosResponse<Array<ICustomerApiResponse>>> {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        return axios.get(
            `${this.customersPath}?extent=address&limit=${limit}&socket=1&filter=${encodedSearchTerm}`
        );
    }

    static updateCustomer(
        customerId: string,
        payload: Partial<ICustomerData>
    ): Promise<AxiosResponse<ICustomerApiResponse>> {
        return axios.patch<ICustomerApiResponse>(
            `${this.customerPath}/${customerId}`,
            payload
        );
    }

    static getCustomerNotes(
        customerId: string
    ): Promise<AxiosApiResponse<ApiCustomerNotes[]>> {
        if (!customerId) {
            return Promise.reject(CustomersApiErrors.MISSING_CUSTOMER_ID);
        }

        if (!generalUtils.isValidUUID(customerId)) {
            return Promise.reject(CustomersApiErrors.INVALID_CUSTOMER_ID);
        }

        return axios.get(`${this.customerPath}/${customerId}/notes`);
    }
}
