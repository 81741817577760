import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { HandleChangeProps } from '~/ui/components/Checkbox';
import { DuplicateTaskModalFormProps } from '../../../types';

interface UseDuplicateTaskModalInvoiceProps {
    invoiceRenderProps: FieldArrayRenderProps;
    invoiceId: string;
}

export const useDuplicateTaskModalInvoice = ({
    invoiceRenderProps,
    invoiceId
}: UseDuplicateTaskModalInvoiceProps) => {
    const { values } = useFormikContext<DuplicateTaskModalFormProps>();
    const { invoicesIds } = values;
    const isSelected = invoicesIds.includes(invoiceId);

    const handleToggleCheckBox = useCallback(
        ({ isChecked }: HandleChangeProps) => {
            const index = invoicesIds.indexOf(invoiceId);

            if (isChecked && index === -1) {
                invoiceRenderProps.push(invoiceId);
            }

            if (!isChecked && index !== -1) {
                invoiceRenderProps.remove(index);
            }
        },
        [invoiceRenderProps, invoiceId, invoicesIds]
    );

    return {
        handleToggleCheckBox,
        invoicesIds,
        isSelected
    };
};
