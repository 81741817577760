import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { useReducer } from 'react';

export default function useClientPreferences(client) {
    const initialState = client.preferences;

    const clientPreferencesSlice = createSlice({
        name: 'clientPreferences',
        initialState,
        reducers: {
            updatePreference: (state, action) => {
                state[action.payload.name] = action.payload.value;
            },
            resetForm: () => initialState
        }
    });

    const { updatePreference, resetForm } = clientPreferencesSlice.actions;
    const clientPreferencesReducer = clientPreferencesSlice.reducer;

    const [state, dispatch] = useReducer(
        clientPreferencesReducer,
        initialState
    );

    const handleResetForm = () => {
        dispatch(resetForm());
    };

    const isFormDirty = !isEqual(initialState, state);

    return {
        clientData: state,
        isFormDirty,
        handleResetForm,
        handleChangePreference: (preference) =>
            dispatch(updatePreference(preference))
    };
}
