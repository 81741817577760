import React, { useCallback, useMemo, useState } from 'react';
import { TextInput, TooltipButton } from '~/ui';

import { filterMatchingItems } from './utils';

import './MultiSearchableDropdown.scss';

const rootClassName = 'multisearchable-dropdown';

type DropdownItem = Record<string, string>;

type MultiSearchableDropdownProps = {
    items: DropdownItem[];
    selectedId: string | null;
    onItemSelect: (id: string | null) => void;
    showInList: (item: DropdownItem) => JSX.Element | string;
    formatWhenSelected: (item: DropdownItem) => string;
    searchableKeys?: string[];
    placeholder?: string;
};

export const MultiSearchableDropdown = ({
    items,
    selectedId,
    onItemSelect,
    formatWhenSelected,
    showInList,
    searchableKeys = [],
    placeholder
}: MultiSearchableDropdownProps) => {
    const [inputValue, setInputValue] = useState('');

    const filteredItems = useMemo(() => {
        return filterMatchingItems({
            value: inputValue,
            items,
            searchableKeys
        });
    }, [inputValue, items, searchableKeys]);

    const isDropdownShown = useMemo(
        () =>
            Boolean(inputValue) &&
            filteredItems.length > 0 &&
            !filteredItems.find((item) => item.id === selectedId),
        [filteredItems, inputValue, selectedId]
    );

    const handleInputChange = useCallback(
        (value) => {
            const matches = filterMatchingItems({
                value,
                items: filteredItems,
                searchableKeys
            });
            if (selectedId && matches.find((match) => match.id === selectedId))
                onItemSelect(null);

            setInputValue(value);
        },
        [filteredItems, selectedId, onItemSelect, searchableKeys]
    );

    const handleItemClick = useCallback(
        (id) => {
            setInputValue(
                formatWhenSelected(
                    items.find(
                        (item: DropdownItem): boolean => item.id === id
                    ) || {}
                )
            );
            onItemSelect(id);
        },
        [items, onItemSelect, formatWhenSelected]
    );

    return (
        <div
            className={`${rootClassName}__container`}
            data-testid="multisearchable-dropdown-container"
        >
            <TextInput
                onChange={handleInputChange}
                placeholder={placeholder}
                value={inputValue}
            />
            {isDropdownShown && (
                <div
                    className={`${rootClassName}__list`}
                    data-testid="multisearchable-dropdown-list"
                >
                    {filteredItems.map((item) => {
                        return (
                            <div
                                data-testid="multisearchable-dropdown-list-item"
                                key={item.key || item.id}
                            >
                                <TooltipButton
                                    className={`_ai-center _jc-flex ${rootClassName}__list-item`}
                                    onClick={() => handleItemClick(item.id)}
                                >
                                    {showInList(item)}
                                </TooltipButton>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
