import { useEffect, useMemo } from 'react';
import { partition } from 'lodash';
import { ApiTask, TaskStatus, TaskStatusCondition } from '~/api/types';
import { useGetTasks } from '../useGetTasks';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import {
    UseLiveDispatchTasksProps,
    UseLiveDispatchTasksHook,
    FilteredUnassignedTasks
} from './types';
import { useSelector } from 'react-redux';
import { selectTasks } from '~/reducers/tasksSlice';
import { selectSortConfig } from '~/reducers/liveDispatchTableSlice';

export const MAX_PAGE_SIZE = 200;

export const DEFAULT_PAGINATION_OPTIONS = {
    limit: MAX_PAGE_SIZE,
    page: 0
};

export const useLiveDispatchTasks = ({
    selectedTaskIds,
    apiPaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    setTotalLiveDispatchCount
}: UseLiveDispatchTasksProps = {}): UseLiveDispatchTasksHook => {
    const tasks = useSelector(selectTasks);
    const sortConfig = useSelector(selectSortConfig);
    const selectedDate = useSelector(selectDateOnly);

    /**
     * unassigned tasks data from TasksApi
     *
     * retrieves any unassigned task regardless of route date, when selected date is not available,
     * up to the defined page limit
     */
    const { data: apiTasksData, refetch } = useGetTasks({
        apiOptions: {
            date: selectedDate || undefined,
            status: [TaskStatus.UNASSIGNED],
            ...apiPaginationOptions,
            sort: sortConfig
        }
    });

    useEffect(() => {
        refetch();
    }, [tasks, refetch]);

    /**
     * unassigned tasks data
     */
    const unassignedTasks = useMemo<ApiTask[]>(() => {
        const unassignedTasksData = apiTasksData?.data?.filter((apiTask) => {
            const { status, taskStatus } = apiTask;
            return (
                status === TaskStatus.UNASSIGNED &&
                taskStatus === TaskStatusCondition.UNASSIGNED
            );
        });

        const taskData = unassignedTasksData || [];

        setTotalLiveDispatchCount?.(taskData.length);

        return taskData;
    }, [apiTasksData, setTotalLiveDispatchCount]);

    /**
     * all unassigned tasks filtered into their respective buckets
     */
    const { isFiltered, selected, excluded } =
        useMemo<FilteredUnassignedTasks>(() => {
            const [selectedTasks, excludedTasks] = partition(
                unassignedTasks,
                ({ id }) => {
                    return selectedTaskIds?.includes(id);
                }
            );

            const filtered = Boolean(selectedTasks.length);

            return {
                isFiltered: filtered,
                selected: selectedTasks,
                excluded: excludedTasks
            };
        }, [unassignedTasks, selectedTaskIds]);

    return {
        isFiltered,
        unassignedTasks,
        selectedTasks: isFiltered ? selected : [],
        excludedUnassignedTasks: isFiltered ? excluded : [],
        totalTasks: unassignedTasks.length
    };
};
