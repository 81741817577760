import PT from 'prop-types';
import React from 'react';

import { Box } from '~/ui';

const variantStyles = {
    variable: {
        backgroundColor: {
            lowProgress: 'venus-400',
            mediumProgress: 'neptune-200',
            completedProgress: 'aurora-400'
        }
    },
    constant: {
        backgroundColor: 'neptune-200'
    }
};

const ProgressBar = React.forwardRef(function ProgressBar(
    {
        percentage = 0,
        variant = 'variable',
        striped = true,
        color,
        sx,
        ...extra
    },
    ref
) {
    const selectedStyle = variantStyles[variant];

    function getBackgroundColor() {
        if (color) {
            return color;
        }

        if (variant === 'constant') {
            return selectedStyle.backgroundColor;
        }
        // for variable variant it changes background color of progress
        // low progress is below 50, between 50 and 100 (excluded) is medium progress
        // at 100 its completed progress
        if (percentage < 50) {
            return selectedStyle.backgroundColor.lowProgress;
        }
        if (percentage >= 50 && percentage < 100) {
            return selectedStyle.backgroundColor.mediumProgress;
        }
        return selectedStyle.backgroundColor.completedProgress;
    }

    function getBackgroundImage() {
        return striped
            ? `linear-gradient(-45deg,hsla(0,0%,100%,.3) 25%,
        transparent 0,transparent 50%,hsla(0,0%,100%,.3) 0,
        hsla(0,0%,100%,.3) 75%,transparent 0,transparent)`
            : null;
    }

    return (
        <Box
            ref={ref}
            sx={{
                width: '100%',
                height: '0.8rem',
                backgroundColor: 'galaxy-100',
                borderRadius: '0.8rem',
                overflow: 'hidden',
                ...sx
            }}
            {...extra}
        >
            <Box
                sx={{
                    height: '100%',
                    width: `${percentage}%`,
                    backgroundColor: getBackgroundColor(),
                    backgroundImage: getBackgroundImage(),
                    backgroundSize: '0.9rem 0.9rem'
                }}
            />
        </Box>
    );
});

ProgressBar.propTypes = {
    /** Progress bar completeness (range from 0 to 100) */
    percentage: PT.number,
    /** Text input style variant */
    variant: PT.oneOf(['variable', 'constant']),
    /** Add stripes to progress bar */
    striped: PT.bool,
    /** Color can be controlled from the outside */
    color: PT.string
};

export default ProgressBar;
