import { groupBy } from 'lodash';
import AssignmentsApi from '~/api/AssignmentsApi';
import constants from '~/utils/constants';

export const getDriversAssignments = async () => {
    const allAssignments = [];
    const limit = constants.defaultApiLimits.assignments;
    let page = 0;
    let total = 0;
    do {
        // intentionally sending requests sequentially for a paginated "get all"
        // eslint-disable-next-line no-await-in-loop
        const results = await AssignmentsApi.get({ page, limit, signed: true });
        total = results.data.meta.total ?? 0;
        allAssignments.push(...results.data.data);
    } while (++page * limit < total);

    return groupBy(allAssignments, 'driver');
};
