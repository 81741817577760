import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';
import classNames from 'classnames';

import { FlexLayout } from '~/ui';
import { IconSize } from './IconSizes';
import { getIconSource, IconName, IconNamesKeys } from './IconNames';

import './icon.scss';

export interface IconProps extends React.ComponentProps<typeof FlexLayout> {
    color?: string;
    fillOpacity?: number;
    stroke?: string;
    disabled?: boolean;
    icon?: IconName | undefined;
    size?: IconSize;
    onClick?: () => void;
    'data-testid'?: string;
}

const Icon = React.forwardRef<HTMLDivElement, IconProps>(function Icon(
    {
        color,
        fillOpacity = 1,
        stroke,
        disabled,
        icon,
        size = 'm',
        className = '',
        sx,
        onClick,
        ...extra
    },
    ref
) {
    if (!icon) {
        return null;
    }

    const iconSrc = getIconSource(icon);
    if (!iconSrc) {
        return null;
    }

    function getClassName() {
        const defaultClassName = `icon icon_${icon} icon_size-${size} _jc-center _ai-center`;
        const conditionalClasses = {
            [`icon_color-${color}`]: color,
            icon_disabled: disabled
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        <FlexLayout
            data-testid={extra['data-testid'] || 'icon'}
            className={getClassName()}
            disabled={disabled}
            ref={ref}
            sx={{
                ...sx,
                svg: {
                    fill: color,
                    fillOpacity,
                    stroke
                }
            }}
            onClick={onClick}
            {...extra}
        >
            <InlineSvg src={iconSrc} />
        </FlexLayout>
    );
});

export const iconKeyPropTypes = PT.oneOf(IconNamesKeys);

export default Icon;
