import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { HereMapsConstants } from '~/utils/hereMapsConstants';
import { useMapUtils } from '~/hooks';

interface GetStaticImage {
    lat: number;
    lng: number;
    width?: number;
    height?: number;
    zoom?: number;
    format?: string;
}

interface UseHereMapsReturn {
    getStaticMapImage: (args: GetStaticImage) => string;
    isHereMapsEnabled: boolean;
    isHerePlanMapActive: boolean;
}

export const useHereMaps = (): UseHereMapsReturn => {
    const mainClient = useSelector(selectMainClient);
    const {
        isDispatchedRouteMode,
        isPreRoutePlanningMode,
        isCompletedRouteMode
    } = useMapUtils();

    const {
        preferences: { useHereMapTiles }
    } = mainClient || { preferences: {} };

    const { apiKey, staticMapImageURL } = HereMapsConstants;

    const getStaticMapImage = ({
        lat,
        lng,
        width = 512,
        height = 1024,
        zoom = 12,
        format = 'png'
    }: GetStaticImage) => {
        return encodeURI(
            `${staticMapImageURL}/center:${lat},${lng};zoom=${zoom}/${width}x${height}/${format}?apiKey=${apiKey}`
        );
    };

    const isHerePlanMapActive =
        Boolean(useHereMapTiles) &&
        (isPreRoutePlanningMode ||
            isDispatchedRouteMode ||
            isCompletedRouteMode);

    return {
        getStaticMapImage,
        isHerePlanMapActive,
        isHereMapsEnabled: Boolean(useHereMapTiles)
    };
};
