import axios, { AxiosResponse } from 'axios';
import { getURL, ServerBaseUrlKey } from '~/servers/servers';

/**
 * Base URL of the API endpoint
 */
const BASEURL = getURL(ServerBaseUrlKey.ROUTE_PATH_GEOMETRY);

/**
 * Implementation of API methods under the /path_geometries API
 *
 * @category API
 */
export default class RoutePathGeometryApi {
    /**
     * Base URL of the API endpoint
     */
    private static readonly baseURL = BASEURL;

    /**
     * Path of the API endpoint
     */
    private static readonly path = '/path_geometries';

    /**
     *
     */
    static getPath(payload: string): Promise<AxiosResponse> {
        return axios.get(`${this.path}/?stops=${payload}`, {
            baseURL: this.baseURL
        });
    }
}
