import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TooltipButton } from '~/ui';
import { RouteItemViewSummary } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem';
import { PlanRoute } from '~/data-classes/plan';

import { TooltipButtonVariants } from '~/ui/components/TooltipButton';
import { TooltipButtonSizes } from '~/ui/components/TooltipButton/TooltipButtonSizes';

import './LockedRouteWarning.scss';

interface LockedRouteWarningProps {
    onConfirm: () => void;
    onCancel: () => void;
    route: PlanRoute;
}

export const LockedRouteWarning = ({
    route,
    onConfirm,
    onCancel
}: LockedRouteWarningProps) => {
    const rootClassName = 'locked-route-warning-modal';

    const { t } = useTranslation(['common', 'reassignRoutes']);

    return (
        <>
            <div
                data-testid="locked-route-warning"
                className={`_d-flex _fd-column _jc-center ${rootClassName}__content`}
            >
                <RouteItemViewSummary route={route} />
                <div>
                    <Trans i18nKey="reassignRoutes:lockedRouteWarning.warningBody" />
                </div>
                <div>
                    {t('reassignRoutes:lockedRouteWarning.warningAction')}
                </div>
            </div>

            <div className={`_d-flex _jc-flex-end ${rootClassName}__footer`}>
                <TooltipButton
                    variant={TooltipButtonVariants.SECONDARY}
                    size={TooltipButtonSizes.MEDIUM}
                    onClick={onCancel}
                    data-testid="locked-route-warning-cancel"
                >
                    {t('common:no')}
                </TooltipButton>
                <TooltipButton
                    variant={TooltipButtonVariants.PRIMARY}
                    size={TooltipButtonSizes.MEDIUM}
                    onClick={onConfirm}
                    data-testid="locked-route-warning-confirm"
                >
                    {t('common:yes')}
                </TooltipButton>
            </div>
        </>
    );
};
