import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHereMaps } from '~/hooks';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import {
    GetClustersCallback,
    UseDispatchedGetClustersReturnValue
} from './types';

export const useDispatchedGetClusters =
    (): UseDispatchedGetClustersReturnValue => {
        const { isHerePlanMapActive } = useHereMaps();
        const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
        const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);

        const getClusters = useCallback<GetClustersCallback>(
            (superCluster) => {
                if (isHerePlanMapActive) {
                    return superCluster.points;
                }
                return superCluster.getClusters(
                    lastPlanMapBounds,
                    lastPlanMapZoom
                );
            },
            [isHerePlanMapActive, lastPlanMapBounds, lastPlanMapZoom]
        );

        return { getClusters };
    };
