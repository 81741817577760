import constants from './constants';

export interface Options {
    /**
     * The language code to use for localization.
     * Must be a valid language code accepted by
     * {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language|navigator.language}
     */
    language?: string;

    /**
     * The precision to convert the distance units by
     */
    numDecimalPlaces?: number;

    /**
     * Use abbreviated units
     */
    useAbbreviated?: boolean;
}

export const msToHrs = (val: number) => val / 1000 / 60 / 60;
export const hrsToMs = (val: number) => val * 1000 * 60 * 60;
export const kmToM = (val: number) => val * 1000;
export const mToKm = (val: number) => val / 1000;
export const cmToIn = (val: number) => val / 2.54;

const inToCm = (val: number) => val * 2.54;

export const kgTolbs = (val: number) => val * 2.205;

const lbsToKg = (val: number) => val / 2.205;

const { enUS } = constants.locales;

function convertPoundsToKilogram(
    value: number,
    { numDecimalPlaces }: Options = {}
) {
    return lbsToKg(value).toFixed(numDecimalPlaces);
}

function convertInchesToCentimetres(
    value: number,
    { numDecimalPlaces }: Options = {}
) {
    return inToCm(value).toFixed(numDecimalPlaces);
}

function getInches(centimeters: number, { numDecimalPlaces }: Options = {}) {
    return cmToIn(centimeters).toFixed(numDecimalPlaces);
}

function getPounds(kilograms: number, { numDecimalPlaces }: Options = {}) {
    return kgTolbs(kilograms).toFixed(numDecimalPlaces);
}

function getMiles(meters: number, { numDecimalPlaces }: Options = {}) {
    return (meters * 0.000621371192).toFixed(numDecimalPlaces);
}

function getKilometers(meters: number, { numDecimalPlaces }: Options = {}) {
    return mToKm(meters).toFixed(numDecimalPlaces);
}

function getLocalDistance(
    meters: number,
    { language, numDecimalPlaces }: Options = {}
) {
    const browserLanguage = language || navigator.language;
    return browserLanguage === enUS
        ? getMiles(meters, { numDecimalPlaces })
        : getKilometers(meters, { numDecimalPlaces });
}

function getLocalDistanceUnits(
    { language, useAbbreviated }: Options = {
        useAbbreviated: true
    }
) {
    const browserLanguage = language || navigator.language;
    const milesTranslationKey = useAbbreviated
        ? 'common:distance.miles_abbreviated'
        : 'common:distance.miles';
    return browserLanguage === enUS
        ? milesTranslationKey
        : 'common:distance.km';
}

function convertPixelstoRem(px: number) {
    return px / parseFloat(getComputedStyle(document.body).fontSize);
}

function convertRemToPixels(rem: number) {
    return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
}

export default {
    getInches,
    getPounds,
    getMiles,
    getKilometers,
    getLocalDistance,
    getLocalDistanceUnits,
    convertPixelstoRem,
    convertRemToPixels,
    convertInchesToCentimetres,
    convertPoundsToKilogram
};
