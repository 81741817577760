import Supercluster from 'supercluster';
import { markerMaker } from '~/utils/map';

const makeMarkers = ({
    superCluster,
    geoJSONFeatures,
    superClusterIndex
}: {
    superCluster: Supercluster.AnyProps;
    geoJSONFeatures: Supercluster.AnyProps[];
    superClusterIndex: number;
}) => {
    const markers = geoJSONFeatures.map((geoJSONFeature) => {
        const isCluster = geoJSONFeature.properties.cluster;

        if (isCluster) {
            return markerMaker.makeEquipmentClusterMarker({
                superCluster,
                geoJSONFeature,
                superClusterIndex
            });
        }
        const { properties: equipmentItem } = geoJSONFeature;

        return markerMaker.makeEquipmentMarker({
            equipment: equipmentItem,
            index: superClusterIndex
        });
    });

    return markers;
};

export default makeMarkers;
