import {
    ApiUserGroup,
    UserPermissionFeatures,
    UserPermissionRoles
} from '~/api/types';

export function validatePermissions(
    userGroups: ApiUserGroup[],
    feature: UserPermissionFeatures,
    role: UserPermissionRoles,
    extraPermissions = false
) {
    return userGroups.some((userGroup) => {
        return userGroup.client_access.some((curClient) => {
            return curClient.permission.some(
                (permission) =>
                    permission.feature === feature &&
                    (permission.role === role || extraPermissions)
            );
        });
    });
}
