import React from 'react';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';

import { DriverMarker, RouteLine } from '~/ui';
import { colorUtils } from '~/utils/color-utils';

/**
 * Map Mode Utilities, Dispatched Routes Mode
 *
 * @category Utils
 * @module utils/map-modes/dispatched-routes-mode
 *
 * @example
 * import makeDispatchedComponents from '~/utils/map-modes/dispatched-routes-mode';
 */

/**
 * @typedef DispatchedComponents
 * @type {Object}
 * @property {LiveRoutesMarker[]} driverMarkers an array of LiveRoutesMarker components
 * @property {RouteLine[]} routeLines an array of RouteLine components
 * @property {Coordinates[]} driverMarkerCoordinates an array for coordinates
 */

/**
 * Makes dispatched driver markers and dispatched route lines components.
 * Also returns a list of all marker locations for convenience.
 * @param {*} liveDrivers Uninstiated LiveDriver data, staright from store
 * @param {*} mapInstance Map instance on which the route lines are to be drawn
 * @returns {DispatchedComponents}
 */
function makeDispatchedComponents(liveDrivers, mapInstance) {
    const driverMarkerCoordinates = [];
    const dispatchedComponents = Object.values(liveDrivers).reduce(
        (acc, liveDriverJSON) => {
            const liveDriver = new LiveDriver(liveDriverJSON);
            driverMarkerCoordinates.push(liveDriver.location);

            const colorCSS = colorUtils.getWebColorsForId(
                liveDriver.clientDriverId
            );

            const driverMarker = (
                <DriverMarker
                    key={liveDriver.id}
                    driverData={liveDriver}
                    lat={liveDriver.location.lat}
                    lng={liveDriver.location.lng}
                    colorCSS={colorCSS}
                />
            );

            const routeLine = (
                <RouteLine
                    key={liveDriver.id}
                    mapInstance={mapInstance}
                    driver={liveDriver}
                    colorCSS={colorCSS}
                />
            );

            return {
                driverMarkers: [...acc.driverMarkers, driverMarker],
                routeLines: [...acc.routeLines, routeLine]
            };
        },
        { driverMarkers: [], routeLines: [] }
    );
    return { ...dispatchedComponents, driverMarkerCoordinates };
}

export default makeDispatchedComponents;
