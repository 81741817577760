import React from 'react';
import classnames from 'classnames';

import { DropDownSelection, Icon } from '~/ui';
import { useHandlersAndState, Item } from './useHandlersAndState';

import './SingleItemSelection.scss';

type Props = {
    className?: string;
    inputFieldId: string;
    placeholder?: string;
    title?: string;
    items?: Item[];
    customTitle?: React.ReactNode;
    isHideErrorMessage?: boolean;
    'data-testid'?: string;
};

export const SingleItemSelection = ({
    className,
    customTitle,
    inputFieldId,
    isHideErrorMessage,
    items,
    placeholder,
    title,
    ...extra
}: Props): JSX.Element => {
    const {
        dropdownRef,
        errorMessage,
        handleChange,
        initialValues,
        isDropDownDisplayed,
        isErrorState,
        isSubmitting,
        onHide,
        onToggle
    } = useHandlersAndState({
        inputFieldId,
        items
    });

    const dropdownProps = {
        customTitle,
        handleChange,
        initialValues,
        inputFieldId,
        isDropDownDisplayed,
        isErrorState,
        items,
        onHide,
        onToggle,
        placeholder,
        title,
        hasSelectAll: false,
        isSelectionDisabled: isSubmitting,
        isSingleSelection: true,
        ref: dropdownRef,
        displayTooltip: false
    } as Record<string, unknown>;

    return (
        <div
            className={classnames('singleitemselection _fd-column', className)}
            data-testid={extra['data-testid'] || 'single-item-selection'}
        >
            <DropDownSelection {...dropdownProps} />
            {isErrorState && !isHideErrorMessage && (
                <div
                    className="singleitemselection-error _d-flex _ai-center"
                    data-testid="single-item-selection-error"
                >
                    <Icon icon="warningFill" color="mars" />
                    <span>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};
