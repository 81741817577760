import React from 'react';
import classNames from 'classnames';
import './ButtonLink.scss';

interface ButtonLinkProps {
    className?: string;
    sx?: React.CSSProperties;
    onClick?: () => void;
    children?: React.ReactNode;
}

const ButtonLink = ({ children, onClick, className, sx }: ButtonLinkProps) => {
    const classes = classNames(className, 'buttonlink');
    return (
        <button
            onClick={onClick}
            style={sx}
            type="button"
            className={classes}
            data-testid="buttonlink"
        >
            {children}
        </button>
    );
};

export default ButtonLink;
