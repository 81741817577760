import React from 'react';
import classNames from 'classnames';
import { useDroppable } from '@dnd-kit/core';
import { useDraggedOverStyle } from '~/hooks';
import { UIElementWithChildren } from '../../types';

interface DroppableContainerProps extends UIElementWithChildren {
    id: string | number;
    className?: string;
    data?: Record<string, unknown>;
    isDisabled?: boolean;
}

const ROOT_CLASS_NAME = 'droppable-container';

export const DroppableContainer = ({
    children,
    id,
    isDisabled = false,
    data,
    className,
    'data-testid': dataTestId = ROOT_CLASS_NAME
}: DroppableContainerProps): JSX.Element => {
    const elementClassName = classNames(ROOT_CLASS_NAME, className);

    const { setNodeRef } = useDroppable({
        id,
        disabled: isDisabled,
        data
    });

    const { dragTargetId, style } = useDraggedOverStyle();

    const isDraggedOver = id === dragTargetId;

    return (
        <div
            ref={setNodeRef}
            style={isDraggedOver ? style : {}}
            className={elementClassName}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
};
