import React from 'react';
import { useModal } from '~/ui';
import { CreateTripModal } from './CreateTripModal';

export const useCreateTripModal = () => {
    const {
        isVisible,
        hideModal: onHideModal,
        modal: Modal,
        showModal: onShowModal
    } = useModal();

    const createTripModal = isVisible ? (
        <CreateTripModal Modal={Modal} onClose={onHideModal} />
    ) : null;

    return { createTripModal, onShowModal, onHideModal };
};
