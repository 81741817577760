import React, { useState } from 'react';
import theme from '~/ui/theme';
import { Text } from '~/ui';
import {
    selectShowDriverName,
    selectShowVehicleEid,
    setShowDriverName,
    setShowVehicleEid
} from '~/reducers/mapSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import constants from '~/utils/constants';
import { useTranslation } from 'react-i18next';
import './route-label-submenu.scss';
import RadioListItem from '~/ui/components/RadioListItem';

function RouteLabelSubmenu() {
    const dispatch = useDispatch();
    const { t } = useTranslation(['translation']);
    const [isDriverNameChecked, setIsDriverNameChecked] = useState(
        useSelector(selectShowDriverName(constants.mapRouteModes.DISPATCHED))
    );
    const [isVehicleIdChecked, setIsVehicleIdChecked] = useState(
        useSelector(selectShowVehicleEid(constants.mapRouteModes.DISPATCHED))
    );
    const vehicleId = 'vehicleId';
    const driverName = 'driverName';
    const handleCheckChange = (checkedKey) => () => {
        setIsDriverNameChecked(checkedKey === driverName);
        setIsVehicleIdChecked(checkedKey === vehicleId);

        dispatch(
            setShowDriverName({
                mode: constants.mapRouteModes.DISPATCHED,
                value: checkedKey === driverName
            })
        );
        dispatch(
            setShowVehicleEid({
                mode: constants.mapRouteModes.DISPATCHED,
                value: checkedKey === vehicleId
            })
        );
    };

    return (
        <div data-testid="RouteLabelSubmenu" className="submenu-main">
            <div className="submenu-option">
                <RadioListItem
                    id={driverName}
                    name="routeLabelType"
                    value={driverName}
                    handleChange={handleCheckChange(driverName)}
                    checked={isDriverNameChecked}
                >
                    <div className="submenu-maincontent">
                        <div style={{ width: '14rem' }}>
                            <Text
                                color={
                                    isDriverNameChecked
                                        ? theme.colors['galaxy-800']
                                        : theme.colors['galaxy-500']
                                }
                                variant="14-normal"
                                sx={{ textTransform: 'capitalize' }}
                                onChange={handleCheckChange(driverName)}
                            >
                                {t('Driver name')}
                            </Text>
                        </div>
                        <div
                            className="submenu-radio"
                            style={{
                                border: isDriverNameChecked
                                    ? '0.7rem solid var(--color-ocean)'
                                    : '0.1rem solid var(--color-ocean)'
                            }}
                            data-testid="driver-name-radio-button"
                        />
                    </div>
                </RadioListItem>
            </div>
            <div>
                <RadioListItem
                    id={vehicleId}
                    name="routeLabelType"
                    value={vehicleId}
                    handleChange={handleCheckChange(vehicleId)}
                    checked={isVehicleIdChecked}
                >
                    <div className="submenu-maincontent">
                        <div style={{ width: '14rem' }}>
                            <Text
                                color={
                                    isVehicleIdChecked
                                        ? theme.colors['galaxy-800']
                                        : theme.colors['galaxy-500']
                                }
                                variant="14-normal"
                                onClick={handleCheckChange(vehicleId)}
                            >
                                {t('Vehicle ID')}
                            </Text>
                        </div>
                        <div
                            className="submenu-radio"
                            style={{
                                border: isVehicleIdChecked
                                    ? '0.7rem solid var(--color-ocean)'
                                    : '0.1rem solid var(--color-ocean)'
                            }}
                            data-testid="vehicle-id-radio-button"
                        />
                    </div>
                </RadioListItem>
            </div>
        </div>
    );
}

export default RouteLabelSubmenu;
