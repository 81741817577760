/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PT from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import { FlexLayout, theme } from '~/ui';
import { useMergedRefs } from '~/ui/hooks';

const tooltipStyles = {
    zIndex: theme.zIndex.tooltip,
    padding: '1.2rem 1.2rem',
    borderRadius: '1rem',
    backgroundColor: 'white',
    maxWidth: '24rem',
    marginLeft: '7rem',
    boxShadow: '0rem 0.2rem 1.2rem -0.5rem #666666',

    animation: theme.transitions.getStandardEasingTransition({
        name: 'entry-animation'
    }),
    '@keyframes entry-animation': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    }
};

const gaps = { md: 8, sm: 4 };

const ButtonTooltip = React.forwardRef(
    (
        { children, containedChildren, placement, gapSize = 'md', disabled },
        ref
    ) => {
        const { getTooltipProps, setTooltipRef, setTriggerRef } =
            usePopperTooltip({
                placement,
                offset: [0, 6 + gaps[gapSize]]
            });

        const mergedRefs = useMergedRefs(setTriggerRef, ref);

        const reffedChild = React.cloneElement(children, {
            ref: mergedRefs
        });

        return (
            <>
                {reffedChild}

                {!disabled && (
                    <FlexLayout
                        flexDirection="column"
                        sx={{
                            ...tooltipStyles
                        }}
                        ref={setTooltipRef}
                        {...getTooltipProps()}
                    >
                        {containedChildren}
                    </FlexLayout>
                )}
            </>
        );
    }
);

ButtonTooltip.propTypes = {
    /** Determine placement position of the tooltip */
    placement: PT.oneOf(['top', 'bottom', 'left', 'right']),
    /** Gap between arrow and target (default is `md`) */
    gapSize: PT.oneOf(['md', 'sm']),
    /** A render function for the target on which the tooltip opens.
     *  Must set the provided ref on the element to work! */
    children: PT.element.isRequired,
    containedChildren: PT.node,
    /** Used to disable tooltip */
    disabled: PT.bool
};

export default ButtonTooltip;
