import { useQuery, UseQueryOptions } from 'react-query';
import TaskApi, { GetTasksParams } from '~/api/TasksApi';
import { ApiLiveDispatch } from '~/api/types';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

type UseGetTasksReactQueryOptions = Omit<
    UseQueryOptions<ApiLiveDispatch, Error>,
    'queryKey' | 'queryFn'
>;

interface UseGetTasksParams {
    /**
     * The API options for `TaskApi.get()`
     */
    apiOptions: GetTasksParams;

    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetTasksReactQueryOptions;
}

const REACT_QUERY_KEY = 'tasks';

const DEFAULT_API_OPTIONS = { extent: constants.allSupportedTaskExtents };

export const useGetTasks = ({
    apiOptions = {},
    reactQueryOptions = {}
}: UseGetTasksParams) => {
    const { getTaskStatus } = taskUtils;

    const fetchTasks = async (
        options: GetTasksParams = {}
    ): Promise<ApiLiveDispatch> => {
        const response = await TaskApi.get(options);
        const returningData: ApiLiveDispatch = {
            data: [],
            meta: {
                total: 0
            }
        };

        if (!response) return returningData;

        const {
            data: { data, meta }
        } = response;

        const updatedData = data.map((apiTask) => {
            const taskStatus = getTaskStatus(apiTask);
            return { ...apiTask, taskStatus };
        });

        returningData.data = [...updatedData];
        returningData.meta = { ...meta };

        return returningData;
    };

    const { sort } = apiOptions;

    let fetchTasksOptions = {
        ...apiOptions
    };

    if (!sort?.length) {
        fetchTasksOptions = {
            ...DEFAULT_API_OPTIONS,
            ...apiOptions
        };
    }

    // return the full useQuery Object
    return useQuery<ApiLiveDispatch, Error>(
        [REACT_QUERY_KEY, fetchTasksOptions],
        () => fetchTasks(fetchTasksOptions),
        reactQueryOptions
    );
};
