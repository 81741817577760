import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectMainClient } from '~/reducers/mainClientSlice';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import constants from '~/utils/constants';

function SingleTasksControl({
    taskData,
    onClickDispatchButton = () => {},
    onClickPairButton = () => {},
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {}
}) {
    const mainClient = useSelector(selectMainClient);
    const { t } = useTranslation('translation');

    function _handleClickDispatch() {
        return onClickDispatchButton && onClickDispatchButton();
    }

    function _handleClickPair() {
        return onClickPairButton && onClickPairButton();
    }

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={taskData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
            data-testid="single-tasks-control"
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    buttonIcon="vehicleEmpty"
                    buttonText={t('UnassignedTasksControl.button.dispatch')}
                    onClick={_handleClickDispatch}
                    buttonStopText={t(
                        'UnassignedTasksControl.button.tasksSelected',
                        {
                            count: 1,
                            total: constants.onDemandDispatch
                                .CONTROL_DISPATCH_LIMIT
                        }
                    )}
                    showButtonArrow
                    data-testid="single-tasks-control-dispatch"
                />
                {mainClient?.preferences?.enableTwoPartTaskEditing && (
                    <StopModalMenuButton
                        buttonIcon="link"
                        buttonText={t('UnassignedTasksControl.button.pair')}
                        onClick={_handleClickPair}
                        showButtonArrow
                        data-testid="single-tasks-control-pair"
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default SingleTasksControl;
