import axios from 'axios';
import constants from '~/utils/constants';

/**
 * User Groups API
 * @category API
 * @module api/userGroupsApi
 *
 * @example
 * import userGroupsApi from '~/api/user-groups';
 */

/** @typedef {import('axios').AxiosResponse} AxiosResponse */

export default {
    /**
     * @constant
     * @type {String}
     * @private
     */
    path: '/user_groups',
    /**
     *
     * @param {Object} params
     * @returns {Promise<AxiosResponse<any>>}
     *
     * @example
     * (async () => {
     *      try {
     *          const response = await userGroupsApi.get();
     *          handleResponse(response);
     *      } catch (e) {
     *          console.error(e);
     *      }
     *  })();
     */
    get(params = { limit: 1000 }) {
        return axios.get(this.path, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     *
     * @param {String} userId the user ID as a UUID string
     * @returns {Promise<AxiosResponse<any>>}
     *
     * @example
     * (async () => {
     *      try {
     *          const userId = '27331fd8-2b00-11e5-b345-feff819cdc9f';
     *          const response = await userGroupsApi.getUserGroupsAssignedToUser(userId);
     *          handleResponse(response);
     *      } catch (e) {
     *          console.error(e);
     *      }
     *  })();
     */
    getUserGroupsAssignedToUser(userId) {
        return axios.get(`/users/${userId}${this.path}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     *
     * @param {*} updates
     * @returns {Promise<AxiosResponse<any>>}
     */
    patch(updates) {
        return axios.patch(this.path, updates, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     *
     * @param {*} data
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(data) {
        return axios.post(this.path, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     *
     * @param {*} userGroupId
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(userGroupId) {
        return axios.delete(`${this.path}/${userGroupId}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     *
     * @param {String} userId the user ID
     * @param {String} userGroupId the user group ID
     * @returns {Promise<AxiosResponse<any>>}
     */
    addUserToUserGroup(userId, userGroupId) {
        return axios.post(
            `users/${userId}/user_groups/${userGroupId}`,
            undefined,
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    /**
     * Add multiple users to user groups in pairs (user_id and user_group_id)
     * @param {Object[]} data
     * @param {String} data.user_id
     * @param {String} data.user_group_id
     * @returns {Promise<AxiosResponse<any>>}
     */
    addUsersToUserGroups(data) {
        return axios.post('/users/user_groups', data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     * Remove a user from a user group
     * @param {String} userId
     * @param {String} groupId
     * @returns {Promise<AxiosResponse<any>>}
     */
    removeUserFromUserGroup(userId, groupId) {
        return axios.delete(`/users/${userId}/user_groups/${groupId}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
