import buttons from './buttons';
import colors from './colors';
import general from './general';
import shadows from './shadows';
import sizes from './sizes';
import text from './text';
import transitions from './transitions';
import zIndex from './zIndex';

// base colors required by theme UI
const themeUiColors = {
    background: colors.palette.white,
    text: colors.palette.milkyway,
    primary: colors.palette.black
};

export default {
    breakpoints: ['1528px'],
    colors: {
        ...colors.palette,
        ...themeUiColors
    },
    fonts: text.fonts,
    fontWeights: text.fontWeights,
    styles: {
        ...general.body
    },
    shadows,
    sizes,
    transitions,
    zIndex,
    // variants
    buttons: buttons.variants,
    text: text.variants
};
