import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetOnLogout } from '~/reducers/common-actions';

type BreakDetail = {
    id: string;
    routeId: string;
};
type SliceState = BreakDetail | null;

export const selectedBreakDetailsSlice = createSlice({
    name: 'selectedBreakDetails',
    initialState: null as SliceState,
    reducers: {
        setSelectedBreakDetails: (state, action) => {
            const { payload } = action;
            const hasRequiredKeys = ['id', 'routeId'].every((key) =>
                _.has(payload, key)
            );

            if (payload && hasRequiredKeys) return payload;

            return state;
        },
        resetSelectedBreakDetails: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { resetSelectedBreakDetails, setSelectedBreakDetails } =
    selectedBreakDetailsSlice.actions;

type State = {
    selectedBreakDetails?: BreakDetail | null;
};

export const selectBreakDetails = (state: State) => state.selectedBreakDetails;

export const selectBreakDetailsId = (state: State) =>
    state.selectedBreakDetails?.id;

export default selectedBreakDetailsSlice.reducer;
