import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
    selectShowLiveRouteLabel,
    selectShowLiveRoutePopup,
    selectShowVehicleEid
} from '~/reducers/mapSettingsSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';

import constants from '~/utils/constants';
import { DriverMarkerPopup, Icon } from '~/ui/';
import { getExceptionIcons } from './utils';
import { LiveRoutesMarkerProps } from './types';

import './LiveRoutesMarker.scss';

const ROOT_CLASS_NAME = 'drivermarker';

function LiveRoutesMarker({ driverData, colorCSS }: LiveRoutesMarkerProps) {
    const { handleDriverSelect, isSelectingMultipleCards } =
        useLiveRoutesUtils();
    const [showPopup, setShowPopup] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const showDriverLabel = useSelector(
        selectShowLiveRouteLabel(constants.mapRouteModes.DISPATCHED)
    );
    const showDriverPopup = useSelector(
        selectShowLiveRoutePopup(constants.mapRouteModes.DISPATCHED)
    );
    const showVehicleId = useSelector(
        selectShowVehicleEid(constants.mapRouteModes.DISPATCHED)
    );
    const { t } = useTranslation(['translation']);

    const {
        clientDriverId,
        name: driverName,
        vehicleEid,
        initials
    } = driverData || {};
    const noEid = t('noEid');
    const driverMarkerLabel = showVehicleId ? vehicleEid || noEid : driverName;

    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    useEffect(() => {
        // update when selecting multiple
        if (isSelectingMultipleCards) {
            setIsSelected(selectedMapRoutes.includes(clientDriverId));
            return;
        }

        // reset when selecting singles
        setIsSelected(false);
    }, [clientDriverId, isSelectingMultipleCards, selectedMapRoutes]);

    const handleMouseEnter = () => {
        setShowPopup(showDriverPopup && true);
    };

    const handleMouseLeave = () => {
        setShowPopup(false);
    };

    const handleClick = useCallback(() => {
        const toggleSelected = !isSelected;
        setIsSelected(toggleSelected);
        handleDriverSelect(toggleSelected, driverData);
    }, [handleDriverSelect, isSelected, driverData]);

    if (!driverData) {
        return null;
    }

    const elementClassName = classNames(ROOT_CLASS_NAME, {
        'drivermarker--selected': isSelected
    });

    return (
        <button
            type="button"
            className={elementClassName}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={handleMouseEnter}
            onClick={handleClick}
            data-testid="drivermarker"
        >
            <div className="drivermarker__icon-group _p-relative">
                <span className="drivermarker__circle" style={colorCSS}>
                    {!isSelected && initials}
                    {isSelected && (
                        <Icon
                            color="white"
                            icon="checkmark"
                            data-testid="drivermarker__selected-icon"
                        />
                    )}
                </span>
                {getExceptionIcons(driverData).map((iconData) => {
                    const { type, position, icon, iconColor } = iconData;
                    return (
                        <Icon
                            key={type}
                            className="drivermarker__icon _p-absolute"
                            icon={icon}
                            color={iconColor}
                            size="s"
                            style={{ right: position }}
                            data-testid={`drivermarker__exception-icon--${type}`}
                        />
                    );
                })}
            </div>
            {showDriverLabel && (
                <span
                    className="drivermarker__label"
                    data-testid="drivermarker__label"
                >
                    {driverMarkerLabel}
                </span>
            )}
            {showPopup && (
                <DriverMarkerPopup
                    driverData={driverData}
                    exceptionData={getExceptionIcons(driverData)}
                />
            )}
        </button>
    );
}

export default LiveRoutesMarker;
