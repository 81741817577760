import _ from 'lodash';

type FilterMatchingItemsArgs = {
    value: string;
    items: Record<string, string>[];
    searchableKeys: string[];
};

export const filterMatchingItems = ({
    value,
    items,
    searchableKeys
}: FilterMatchingItemsArgs): Record<string, string>[] => {
    const matches = searchableKeys.flatMap((key) => {
        return items.filter(
            (itm) =>
                itm[key]
                    .toString()
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) >= 0
        );
    });
    return _.uniq(matches);
};
