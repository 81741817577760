import type { RootState } from '~/store';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import { ConfigurableWorkflowSetup } from '~/components/WorkflowPage/types';

/**
 * Configurable workflow reducer actions
 */
export const configurableWorkflowSlice = createSlice({
    name: 'configurableWorkflow',
    initialState: [] as ConfigurableWorkflowSetup[],
    reducers: {
        setWorkflowRule: (state, action) => {
            state = action.payload;
            return state;
        },
        updateSelectedWorkflowRule: (state, action) => {
            const { payload } = action;
            const ruleIndex = state.findIndex(({ step }) => payload === step);

            if (ruleIndex === -1) return state;

            return state.map((item, index) => {
                if (index === ruleIndex) {
                    return {
                        ...item,
                        isSelected: true
                    };
                }
                return item;
            });
        },
        updateUnselectedWorkflowRule: (state, action) => {
            const { payload } = action;
            const ruleIndex = state.findIndex(({ step }) => payload === step);

            if (ruleIndex === -1) return state;

            return state.map((item, index) => {
                if (index === ruleIndex) {
                    return {
                        ...item,
                        isSelected: false,
                        required: false
                    };
                }
                return item;
            });
        },
        resetWorkflowRule: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const {
    setWorkflowRule,
    resetWorkflowRule,
    updateSelectedWorkflowRule,
    updateUnselectedWorkflowRule
} = configurableWorkflowSlice.actions;

export const selectWorkflowRule = (state: RootState) =>
    state.configurableWorkflow;

export default configurableWorkflowSlice.reducer;
