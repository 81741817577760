import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '~/ui';

function GeofenceControlsTitle({ title, icon, onReset }) {
    const { t } = useTranslation(['customerManagement']);

    return (
        <div className="_d-flex _ai-center _jc-space-between _w-100">
            <div className="_d-flex _ai-center _cg-2">
                <Icon icon={icon} data-testid="geofence-controls-icon" />
                <span className="_text-3" data-testid="geofence-controls-title">
                    {title}
                </span>
            </div>
            <Button
                className="customermap-button"
                variant="secondary"
                type="compact"
                onClick={onReset}
                data-testid="geofence-reset-button"
            >
                <span>{t('customerMap.reset')}</span>
            </Button>
        </div>
    );
}

export default GeofenceControlsTitle;
