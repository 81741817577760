import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
    GetMetricsResponseData,
    CumulativeImpact
} from '~/api/EditRouteHistoryApi';
import { resetOnLogout } from '~/reducers/common-actions';

export const editHistoryMetricsSlice = createSlice({
    name: 'editHistoryMetrics',
    initialState: {},
    reducers: {
        setEditHistoryMetrics: (state, action) => {
            const data = action.payload;

            if (!data || !_.isObject(data)) return state;

            return data;
        },
        resetEditHistoryMetrics: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setEditHistoryMetrics, resetEditHistoryMetrics } =
    editHistoryMetricsSlice.actions;

type State = {
    editHistoryMetrics?: GetMetricsResponseData;
};

export const selectEditHistoryCumulativeImpact = (
    state: State
): CumulativeImpact | undefined => state.editHistoryMetrics?.cumulativeImpact;

export default editHistoryMetricsSlice.reducer;
