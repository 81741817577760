import React, { useState } from 'react';
import './CustomerMapMarker.scss';
import classnames from 'classnames';
import constants from '~/utils/constants';

function CustomerMapMarker({ emittedEventHandler, isDraggable = false }) {
    const [isDragging, setIsDragging] = useState(false);

    function _handleDragStart() {
        if (isDraggable) {
            setIsDragging(true);
            emittedEventHandler({
                event: constants.customerMap.childEvents.DRAG_START
            });
        }
    }
    function _handleMouseUp() {
        if (isDraggable) {
            setIsDragging(false);
            emittedEventHandler({
                event: constants.customerMap.childEvents.MOUSE_UP
            });
        }
    }
    function _handleMouseEnter() {
        if (isDraggable)
            emittedEventHandler({
                event: constants.customerMap.childEvents.MOUSE_ENTER
            });
    }

    return (
        <button
            type="button"
            aria-label="pin"
            draggable={isDraggable}
            data-testid="customer-map-marker"
            className={classnames('customer-map-marker', {
                'customer-map-marker__selected': isDragging
            })}
            onDragStart={_handleDragStart}
            onMouseUp={_handleMouseUp}
            onMouseEnter={_handleMouseEnter}
        />
    );
}

export default CustomerMapMarker;
