import { WebPlanApi } from '~/api/WebPlanApi';
import constants from '~/utils/constants';
import { store } from '~/store';
import { addPendingJob } from '~/reducers/pendingJobsSlice';

let throttledRequests = {};
let localThrottle = null;

function processThrottledRequests() {
    const requestArray = [...Object.keys(throttledRequests)];
    requestArray.forEach((task) => {
        throttledRequests[task]();
    });
    clearRequestThrottle();
}

function clearRequestThrottle() {
    clearTimeout(localThrottle);
    localThrottle = null;
    throttledRequests = {};
}

function getDelayBasedOnStops(taskCount) {
    const stopsLength = taskCount || 0;
    const minTaskCount = 500;
    const baseMinDelay = 2500;
    return Math.max(baseMinDelay, (stopsLength / minTaskCount) * baseMinDelay);
}

function reloadThrottleOnLocalRequest(delay) {
    localThrottle = setTimeout(processThrottledRequests, delay);
}

function setRequest(clientId, routeDate, taskCount) {
    if (!localThrottle) {
        const delay = getDelayBasedOnStops(taskCount);
        reloadThrottleOnLocalRequest(delay);
    }

    throttledRequests[clientId] = () => {
        requestWebPlan({
            clientId,
            routeDate
        });
    };
}

function requestWebPlan({
    clientId,
    routeDate,
    type = constants.productTypes.SCHEDULER
}) {
    (async () => {
        const response = await WebPlanApi.get(clientId, {
            type,
            clients: [clientId],
            date: routeDate
        });
        store.dispatch(addPendingJob(response.data.data.job[0].jobId));
    })();
}

export default {
    clearRequestThrottle,
    requestWebPlan,
    setRequest
};
