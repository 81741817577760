import React, { useState } from 'react';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { PlanMapEventsContext } from './PlanMapEventsContext';

type Props = {
    children: React.ReactChild;
};

export const PlanMapEventsContextProvider = ({
    children
}: Props): JSX.Element => {
    const initialEventHandler = () => {};

    const [mapEventHandler, setMapEventHandler] = useState<EmittedEventHandler>(
        // to store a function, need to wrap it over anonymous function
        () => {
            return initialEventHandler;
        }
    );

    const assignEventHandler = (handler: EmittedEventHandler) => {
        setMapEventHandler(() => {
            return handler;
        });
    };

    return (
        <PlanMapEventsContext.Provider
            value={{
                emittedEventHandler: mapEventHandler,
                assignEventHandler
            }}
        >
            {children}
        </PlanMapEventsContext.Provider>
    );
};
