import { PlanRoute } from '~/data-classes';
import { idUtils } from '~/utils/id-utils';

export const getSelectedDrawerCardDataPlanStops = ({
    planStopsLevelData,
    selectedDrawerCardData
}: {
    planStopsLevelData: Record<string, unknown>[];
    selectedDrawerCardData?: unknown;
}) => {
    const { route } = (selectedDrawerCardData ?? {}) as {
        route?: unknown;
    };
    const { clientRouteIds = [] } = route
        ? new PlanRoute(selectedDrawerCardData)
        : {};

    if (!clientRouteIds.length) return [];

    return planStopsLevelData.filter((stop) => {
        const { clientId, routeId } = stop as {
            clientId: string;
            routeId: string;
        };

        return clientRouteIds.includes(
            idUtils.getCombinedId(clientId, routeId)
        );
    });
};
