import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import SinglePlanEditApi from '~/api/SinglePlanEditApi';
import constants from '~/utils/constants';
import { useMapUtils } from '../useMapUtils';
import { AddBreakArgs, DeleteBreakArgs, EditBreakArgs } from '~/api/types';

type ReturnProps = {
    addBreak: (payload: AddBreakArgs) => Promise<void>;
    deleteBreak: (payload: DeleteBreakArgs) => Promise<void>;
    editBreak: (payload: EditBreakArgs) => Promise<void>;
};

export const useDriverBreak = (): ReturnProps => {
    const dispatch = useDispatch();
    const { t } = useTranslation('driverBreak');
    const { isPlanRouteMode } = useMapUtils();

    const deleteBreak = useCallback(
        async ({ clientId, routeId, date, breakRuleId }: DeleteBreakArgs) => {
            const processIndicatorState = {
                message: t('progress.DeletingBreak'),
                inProgress: true,
                error: false,
                position: 'center',
                type: constants.processIndicator.DELETE_BREAK
            };

            try {
                if (!isPlanRouteMode) return;
                dispatch(addProcessIndicator(processIndicatorState));
                const args = {
                    clientId,
                    routeId,
                    date,
                    breakRuleId
                } as DeleteBreakArgs;
                await SinglePlanEditApi.deleteBreak(args);
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        [dispatch, t, isPlanRouteMode]
    );

    const addBreak = useCallback(
        async ({
            clientId,
            routeId,
            date,
            stopIdBeforeBreak,
            durationSeconds
        }: AddBreakArgs) => {
            const processIndicatorState = {
                message: t('progress.AddingBreak'),
                inProgress: true,
                error: false,
                position: 'center',
                type: constants.processIndicator.ADD_BREAK
            };

            try {
                if (!isPlanRouteMode) return;
                dispatch(addProcessIndicator(processIndicatorState));
                const args = {
                    clientId,
                    routeId,
                    date,
                    stopIdBeforeBreak,
                    durationSeconds
                } as AddBreakArgs;
                await SinglePlanEditApi.addBreak(args);
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        [dispatch, t, isPlanRouteMode]
    );

    const editBreak = useCallback(
        async ({
            clientId,
            routeId,
            date,
            breakRuleId,
            stopIdBeforeBreak,
            durationSeconds
        }: EditBreakArgs) => {
            const isResequence = Boolean(stopIdBeforeBreak);
            const processIndicatorState = {
                message: isResequence
                    ? t('progress.ResequencingBreak')
                    : t('progress.EditingBreakDuration'),
                inProgress: true,
                error: false,
                position: 'center',
                type: isResequence
                    ? constants.processIndicator.RESEQUENCE_BREAK
                    : constants.processIndicator.EDIT_BREAK_DURATION
            };

            try {
                if (!isPlanRouteMode) return;
                dispatch(addProcessIndicator(processIndicatorState));
                const args = {
                    clientId,
                    routeId,
                    date,
                    breakRuleId,
                    stopIdBeforeBreak,
                    durationSeconds
                } as EditBreakArgs;
                await SinglePlanEditApi.editBreak(args);
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        },
        [dispatch, t, isPlanRouteMode]
    );

    return { deleteBreak, addBreak, editBreak };
};
