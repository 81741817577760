import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, RouteCardTitle, StopModalMenuButton } from '~/ui';

import './DriverBreakModalMain.scss';

type Props = {
    handleDeselect: () => void;
    handleDelete: () => void;
    setIsEditingDuration: Dispatch<SetStateAction<boolean>>;
    setIsResequencingBreak: Dispatch<SetStateAction<boolean>>;
};

const menuClassName = 'driver-break-modal-main-view__menu _fd-column';

function DriverBreakModalMain({
    handleDelete,
    handleDeselect,
    setIsEditingDuration,
    setIsResequencingBreak
}: Props) {
    const { t } = useTranslation('driverBreakModal');

    return (
        <div
            className="driver-break-modal-main-view__body _fd-column"
            data-testid="driver-break-modal-main-view"
        >
            <div className="driver-break-modal-main-view__header _d-flex">
                <RouteCardTitle className="_text-2" title={t('title')} />
                <IconButton
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={handleDeselect}
                    data-testid="driver-break-modal-main-view-cancel"
                />
            </div>
            <div className={menuClassName}>
                <StopModalMenuButton
                    buttonIcon="edit"
                    buttonText={t('editDuration')}
                    onClick={() => setIsEditingDuration(true)}
                    showButtonArrow
                    data-testid="driver-break-modal-main-view-edit"
                />
                <StopModalMenuButton
                    buttonIcon="routeLine"
                    buttonText={t('resequenceBreak')}
                    onClick={() => setIsResequencingBreak(true)}
                    showButtonArrow
                    data-testid="driver-break-modal-main-view-resequence"
                />
                <StopModalMenuButton
                    buttonIcon="delete"
                    buttonIconColor="mars-500"
                    buttonText={t('deleteBreak')}
                    onClick={handleDelete}
                    data-testid="driver-break-modal-main-view-delete"
                />
            </div>
            <div className={menuClassName}>
                <StopModalMenuButton
                    buttonIcon="iconClose"
                    buttonText={t('deselect')}
                    onClick={handleDeselect}
                    data-testid="driver-break-modal-main-view-deselect"
                />
            </div>
        </div>
    );
}

export default DriverBreakModalMain;
