import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInventoryItem } from '~/api/types';
import { IconSizes } from '../Icon/IconSizes';
import { Collapsible, Icon, Spinner } from '~/ui/components';
import { InventoryListItem } from './InventoryListItem';
import './InventoryItemsDetails.scss';

const ROOT_CLASS_NAME = 'inventory-items-details';

interface InventoryItemsDetailsProps {
    /**
     * The inventory items
     */
    inventoryItems: ApiInventoryItem[];
    /**
     * Whether the task is completed
     */
    isCompleted?: boolean;
    /**
     * The number of inventory items
     */
    inventoryItemsCount: number;
    /**
     * Whether the collapsible panel is initially opened
     */
    isInitiallyOpened?: boolean;
    /**
     * Whether the inventory items are being loaded
     */
    isLoading?: boolean;
    /**
     * The collapsible toggle callback
     */
    onToggle?: () => void;
}

export const InventoryItemsDetails = ({
    inventoryItems,
    inventoryItemsCount,
    isCompleted,
    isInitiallyOpened,
    isLoading,
    onToggle
}: InventoryItemsDetailsProps) => {
    const { t } = useTranslation(['inventory', 'error']);

    const getCardHeader = () => {
        return (
            <div className="inventory-header" data-testid="inventory-header">
                <Icon icon="unitsOutline" size={IconSizes.M} />
                {t('numInventoryItems', {
                    count: inventoryItemsCount
                })}
            </div>
        );
    };

    const hasInventoryItems = Boolean(inventoryItems.length);

    return (
        <>
            <Collapsible
                className={`${ROOT_CLASS_NAME}__collapsible`}
                iconColor="meteor"
                iconPosition={Collapsible.iconPosition.RIGHT}
                cardHeader={getCardHeader()}
                overrideClickHandler={onToggle}
                initOpen={isInitiallyOpened}
                textOpen={t('collapsible.hideDetails')}
                textClose={t('collapsible.viewDetails')}
                data-testid={`${ROOT_CLASS_NAME}-collapsible`}
            >
                {hasInventoryItems && (
                    <div
                        className="inventory-list"
                        data-testid="inventory-list"
                    >
                        <div
                            className="inventory-header-row"
                            data-testid="inventory-list-header"
                        >
                            <span className="inventory-item-name _text-3-alt">
                                {t('itemName')}
                            </span>
                            <div>
                                <span
                                    className="_text-3-alt"
                                    data-testid="inventory-header-planned"
                                >
                                    {t('planned')}
                                </span>
                                {isCompleted && (
                                    <span
                                        className="_text-3-alt"
                                        data-testid="inventory-header-actual"
                                    >
                                        {t('actual')}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div
                            className={`${ROOT_CLASS_NAME}__item-group`}
                            data-testid={`${ROOT_CLASS_NAME}-item-group`}
                        >
                            {inventoryItems.map((item) => {
                                const {
                                    item_name: itemName = '',
                                    actual_quantity: actualQuantity = 0,
                                    expected_quantity: expectedQuantity = 0,
                                    id
                                } = item;
                                return (
                                    <InventoryListItem
                                        key={id}
                                        itemName={itemName}
                                        planned={expectedQuantity}
                                        actual={actualQuantity}
                                        isCompleted={isCompleted}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </Collapsible>
            {isLoading && (
                <div className="_jc-center">
                    <Spinner
                        sx={{
                            width: '2rem',
                            height: '2rem',
                            marginTop: '-2rem'
                        }}
                        color="ocean"
                    />
                </div>
            )}
        </>
    );
};
