import React, { useCallback, useState } from 'react';
import { useModal } from '~/ui';
import { UnarriveSelectedStopModal } from './UnarriveSelectedStopModal';
import { PlanStop } from '~/data-classes';

export const useUnarriveSelectedStopModal = () => {
    const { isVisible, hideModal, modal: Modal, showModal } = useModal();
    const [stopData, setStopData] = useState<PlanStop | null>(null);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    const onShowModal = useCallback(
        (newStopData: PlanStop) => {
            setStopData(newStopData);
            showModal();
        },
        [showModal]
    );

    const onCloseModal = useCallback(() => {
        setStopData(null);
        hideModal();
    }, [hideModal]);

    const unarriveSelectedStopModal = useCallback(
        () =>
            isVisible ? (
                <UnarriveSelectedStopModal
                    Modal={Modal}
                    onClose={onCloseModal}
                    stopData={stopData}
                    setIsConfirmed={setIsConfirmed}
                />
            ) : null,
        [Modal, isVisible, onCloseModal, stopData]
    );

    return {
        unarriveSelectedStopModal,
        onShowModal,
        onCloseModal,
        stopData,
        isConfirmed,
        setIsConfirmed
    };
};
