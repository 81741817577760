import React from 'react';
import generalUtils from '~/utils/general-utils';

import { useAddTaskModalContext } from '../AddTaskModalContext';
import { InvoiceRow } from './InvoiceRow';
import { PaymentRequired } from './PaymentRequired';
import { AddInvoiceRow } from './AddInvoiceRow';

import './Invoice.scss';

export const Invoice = () => {
    const {
        clientTask: { invoices = [], paymentRequired }
    } = useAddTaskModalContext();

    if (!generalUtils.isFlaggedFeatureDisplayed()) return null;

    return (
        <div
            className="add-task-modal__invoice _w-100"
            data-testid="add-task-modal-invoice"
        >
            <PaymentRequired />
            {paymentRequired && (
                <>
                    <AddInvoiceRow />
                    {invoices.map(({ frontendOnlyId }) => (
                        <InvoiceRow
                            invoiceId={frontendOnlyId}
                            key={frontendOnlyId}
                        />
                    ))}
                </>
            )}
        </div>
    );
};
