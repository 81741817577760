import { TimeWindow, TaskTypeRecord, ApiNote, TaskPriority } from '~/api/types';
import { Task } from './Task';

/**
 * LiveDispatchTaskTableRowData data class
 *
 * @category Data Classes
 *
 * @example
 * import { LiveDispatchTaskTableRowData } from '~/data-classes';
 *
 * const srcData = {};
 * const task = new LiveDispatchTaskTableRowData(srcData);
 *
 */
export class LiveDispatchTaskTableRowData extends Task {
    get rowId(): string {
        return this.id;
    }

    get rowName(): string {
        return this.name;
    }

    get rowLabels(): string[] {
        return this.labels;
    }

    get rowDate(): string {
        return this.routeDate;
    }

    get rowExtTaskType(): string | null {
        return this.externalTaskType;
    }

    get rowExtLinks(): string[] | null {
        return this.externalLinks;
    }

    get rowNotes(): ApiNote[] | null {
        return this.notes;
    }

    get rowTaskType(): string {
        return this.taskType;
    }

    get rowTaskPriority(): TaskPriority {
        return this.taskPriority;
    }

    get rowCustomerName(): TaskTypeRecord<string> {
        const pickup = this.pickupTask?.pickupLocation.name;
        const delivery = this.deliveryTask?.deliveryLocation.name;

        return { pickup, delivery };
    }

    get rowCustomerAddress(): TaskTypeRecord<string> {
        const { addressLine1: pickup } = this.pickupLocationAddress || {};
        const { addressLine1: delivery } = this.deliveryLocationAddress || {};

        return { pickup, delivery };
    }

    get rowCustomerCity(): TaskTypeRecord<string> {
        const { city: pickup } = this.pickupLocationAddress || {};
        const { city: delivery } = this.deliveryLocationAddress || {};

        return { pickup, delivery };
    }

    get rowPickupCustomerName(): string | null {
        return this.pickupLocationName ?? null;
    }

    get rowPickupCustomerAddress(): string | null {
        const { addressLine1: pickup } = this.pickupLocationAddress || {};
        return pickup ?? null;
    }

    get rowPickupCustomerCity(): string | null {
        const { city: pickup } = this.pickupLocationAddress || {};
        return pickup ?? null;
    }

    get rowDeliveryCustomerName(): string | null {
        return this.deliveryLocationName ?? null;
    }

    get rowDeliveryCustomerAddress(): string | null {
        const { addressLine1: delivery } = this.deliveryLocationAddress || {};
        return delivery ?? null;
    }

    get rowDeliveryCustomerCity(): string | null {
        const { city: delivery } = this.deliveryLocationAddress || {};
        return delivery ?? null;
    }

    get rowTimeWindow(): TaskTypeRecord<TimeWindow[]> {
        const { pickupWindow: pickup } = this.pickupTask?.props || {};
        const { deliveryWindow: delivery } = this.deliveryTask?.props || {};

        return { pickup, delivery };
    }

    get rowPickupStartTime(): string | null {
        return this.pickupStartTime;
    }

    get rowPickupEndTime(): string | null {
        return this.pickupEndTime;
    }

    get rowDeliveryStartTime(): string | null {
        return this.deliveryStartTime;
    }

    get rowDeliveryEndTime(): string | null {
        return this.deliveryEndTime;
    }

    /**
     * the task equipment ID
     */
    get rowEquipmentId(): string | null {
        return this.equipmentId;
    }

    /**
     * the task equipment payer of freight
     */
    get rowEquipmentPayerOfFreight(): string | null {
        return this.payerOfFreight;
    }

    /**
     * the task equipment reservation
     */
    get rowEquipmentReservation(): string | null {
        return this.reservation;
    }

    /**
     * the task equipment type
     */
    get rowEquipmentType(): string | null {
        return this.equipmentType;
    }

    /**
     * the task equipment drop/stay status
     */
    get rowEquipmentDropStay(): string | null {
        return this.dropStay;
    }
}
