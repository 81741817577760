import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
    ApiLiveDriver,
    ApiShiftEndParams,
    SocketScheduleItem
} from '~/api/types';
import {
    useToastContext,
    useMarkEndOfShift,
    useRemoveEndOfShift,
    useTaskUnassign,
    useLiveRoutesUtils
} from '~/hooks';

import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';

export const useEndOfShiftCallbacks = (stopData: SocketScheduleItem) => {
    const { id: assignmentId, driver: driverId, driverProfile } = stopData;
    const { firstname: firstName, lastname: lastName } = driverProfile || {
        firstname: '',
        lastname: ''
    };

    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as { data?: ApiLiveDriver };

    const { t } = useTranslation('markEndOfShift');
    const { addToast } = useToastContext();
    const { markEndOfShift } = useMarkEndOfShift();
    const { removeEndOfShift } = useRemoveEndOfShift();
    const { unassignTasks } = useTaskUnassign();
    const { deselectDriver } = useLiveRoutesUtils();

    const { schedule, stats } = selectedDrawerCard || {};
    const { isEndingShift: hasLastStopInShift } = stats || {
        isEndingShift: false
    };

    const unassignTaskIds = useMemo(() => {
        if (!schedule) return [];
        const start = schedule.findIndex(
            (liveStop) => liveStop.id === assignmentId
        );

        return schedule
            .slice(start + 1)
            .filter((liveStop) => !liveStop.isDepot)
            .map((liveStop) => liveStop.task);
    }, [assignmentId, schedule]);

    const canUnassignTasks = unassignTaskIds.length > 0;

    const onSuccessUnassignTasks = () => {
        addToast({
            message: t('toast.successUnassignTasks', {
                count: unassignTaskIds.length,
                firstName,
                lastName
            }),
            variant: 'success'
        });
    };

    const onErrorUnassignTasks = () => {
        addToast({
            message: t('toast.errorUnassignTasks', {
                count: unassignTaskIds.length
            }),
            variant: 'error'
        });
    };

    const handleUnassignTasks = useCallback(async () => {
        await unassignTasks(unassignTaskIds, {
            onSuccess: onSuccessUnassignTasks,
            onError: onErrorUnassignTasks
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [unassignTaskIds]);

    const onSuccessMarkEndOfShift = (isEnabledUnassignTasks: boolean) => {
        addToast({
            message: t('toast.successMarkEndOfShift', {
                firstName,
                lastName
            }),
            variant: 'success'
        });
        if (isEnabledUnassignTasks) handleUnassignTasks();
    };

    const onErrorMarkEndOfShift = () => {
        addToast({
            message: t('toast.errorMarkEndOfShift', {
                firstName,
                lastName
            }),
            variant: 'error'
        });
    };

    const handleMarkShiftEnd = useCallback(
        async (isEnabledUnassignTasks: boolean) => {
            const params: ApiShiftEndParams = {
                driverId,
                lastAssignmentId: assignmentId,
                timestamp: DateTime.now().toUTC().toISO()
            };

            await markEndOfShift(params, {
                onSuccess: () =>
                    onSuccessMarkEndOfShift(isEnabledUnassignTasks),
                onError: onErrorMarkEndOfShift
            });

            deselectDriver();
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [driverId, assignmentId]
    );

    const onSuccessRemoveEndOfShift = () => {
        addToast({
            message: t('toast.successRemoveEndOfShift', {
                firstName,
                lastName
            }),
            variant: 'success'
        });
    };

    const onErrorRemoveEndOfShift = () => {
        addToast({
            message: t('toast.errorRemoveEndOfShift', {
                firstName,
                lastName
            }),
            variant: 'error'
        });
    };

    const handleRemoveShiftEnd = useCallback(() => {
        if (!driverId) return;
        removeEndOfShift(driverId, {
            onSuccess: onSuccessRemoveEndOfShift,
            onError: onErrorRemoveEndOfShift
        });
        deselectDriver();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [driverId]);

    return {
        hasLastStopInShift,
        canUnassignTasks,
        handleMarkShiftEnd,
        handleRemoveShiftEnd
    };
};
