import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { LiveStop, PlanStop } from '~/data-classes';
import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useIsStopSelected } from '~/ui/components/StopMarker/useIsStopSelected';
import {
    EmittedEventHandler,
    OnDemandDispatchMarkerEventHandler
} from '~/ui/components/LiveStopMarker/types';
import { useGetStopEventData } from './useGetStopEventData';
import { useGetDriverColor } from '~/ui/components/LiveStopMarker/useGetDriverColor';

import './AssignedStopMarker.scss';

interface AssignedStopMarkerProps {
    task: PlanStop | LiveStop;
    emittedEventHandler: EmittedEventHandler;
    onDemandDispatchMarkerEventHandler: OnDemandDispatchMarkerEventHandler;
}

export const AssignedStopMarker = ({
    task,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler
}: AssignedStopMarkerProps) => {
    const rootClassName = 'assigned-stop-marker';
    const { mapRouteMode } = useMapUtils();
    const liveStopData = task as LiveStop;
    const planStopData = task as PlanStop;

    const { driver, isCompleted, isCanceled, isInProgress } =
        liveStopData || {};
    const driverColor = useGetDriverColor(driver);
    const { driverStopNumber, colorCSS } = planStopData;

    const isSelected = useIsStopSelected({ stopData: planStopData });
    const showStopNumber = useSelector(
        selectShowStopNumber(mapRouteMode as ConfigurableMapRouteMode)
    );

    const isInteractive = !(isCanceled || isInProgress);
    const isDisabled = !isInteractive;
    const isClickable = isInteractive && !isCompleted;
    const { backgroundColor } = driverColor || {};
    const isStopCompletedStyle = {
        borderColor: backgroundColor,
        color: backgroundColor
    };

    const { emitEvent } = useGetStopEventData({
        stop: task,
        emittedEventHandler,
        onDemandDispatchMarkerEventHandler,
        isSelected
    });

    const getClassName = () => {
        return classNames(rootClassName, {
            'stopmarker--selected': isSelected
        });
    };

    const getMarkerStyle = () => {
        if (isCompleted) return isStopCompletedStyle;
        return colorCSS || driverColor;
    };

    const handleOnClick = () => {
        if (!isClickable) return;

        emitEvent();
    };

    return (
        <button
            type="button"
            className={getClassName()}
            onClick={handleOnClick}
            data-testid={rootClassName}
            disabled={isDisabled}
        >
            <div className={`${rootClassName}__body`}>
                <div className="_p-relative" data-testid="default-stop-marker">
                    <span
                        className={`${rootClassName}__circle`}
                        data-testid="stop-marker-circle"
                        style={getMarkerStyle()}
                    >
                        {showStopNumber && driverStopNumber}
                    </span>
                </div>
            </div>
        </button>
    );
};
