import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    selectSelectedMapRoutes
} from '~/reducers/selectedMapRoutesSlice';
import { upsertSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { setSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectClients } from '~/reducers/clientsSlice';
import {
    setIsOpenSelectedCardsDrawer,
    setIsOpenUnassignedTasksDrawer,
    selectIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import {
    setMapMarkerMode,
    selectShowRouteLabel,
    selectShowRoutePopup,
    setShouldFitPlanMapToBounds,
    selectViewCardDetails,
    selectIsMultipleCardSelectEnabled
} from '~/reducers/mapSettingsSlice';

import { Icon } from '~/ui';
import constants from '~/utils/constants';
import filterUtils from '~/utils/filter-utils';

import PlanRoute from '~/data-classes/plan/PlanRoute';
import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';
import { useTripCount } from '~/hooks';
import eventBus from '~/EventBus';

import './routemarker.scss';

function RouteMarker({
    routeLevelData,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler = () => {},
    colorCSS,
    hoverPopup,
    selectable
}) {
    const { deselectDriver } = useLiveRoutesUtils();
    const { t } = useTranslation(['translation', 'trips']);
    const [isSelected, setIsSelected] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const selectedMapRoute = useSelector(selectSelectedMapRoutes);
    const showRouteLabel = useSelector(selectShowRouteLabel(mapRouteMode));
    const showPopup = useSelector(selectShowRoutePopup(mapRouteMode));
    const { showTripCount, tripCount } = useTripCount(routeLevelData);
    const viewCardDetails = useSelector(selectViewCardDetails);
    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const dispatch = useDispatch();
    const clients = useSelector(selectClients);
    const { clientRouteId, isPlanned, vehicleEid } = routeLevelData;

    const shouldRenderStops = !isMultipleCardSelectEnabled && !viewCardDetails;

    let label = '';
    if (isPlanned) {
        label = t('routeName', {
            routeName: vehicleEid
        });
    } else {
        const { clientId } = routeLevelData;
        const clientName = clients[clientId]?.name || '';
        label = filterUtils.getInitials(clientName);
    }
    useEffect(() => {
        const isMarkerSelected = selectedMapRoute.includes(clientRouteId);
        setIsSelected(isMarkerSelected);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedMapRoute]);

    function _toggleIsSelected() {
        if (!selectable) return;

        if (shouldRenderStops) {
            if (!routeLevelData.isPlanned) {
                deselectDriver();
                dispatch(setIsOpenUnassignedTasksDrawer(true));
            } else {
                const routeData =
                    routeLevelData instanceof PlanRoute
                        ? routeLevelData.toJSON()
                        : routeLevelData;
                dispatch(setSelectedDrawerCardId(clientRouteId));
                dispatch(
                    upsertSelectedDrawerCardData({
                        id: clientRouteId,
                        data: routeData
                    })
                );
                dispatch(setIsOpenSelectedCardsDrawer(true));
            }
            dispatch(setShouldFitPlanMapToBounds(true));
            dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
        }
        dispatch(
            isSelected
                ? removeSelectedMapRoute(clientRouteId)
                : addSelectedMapRoute(clientRouteId)
        );

        if (isOpenUnassignedTasksDrawer)
            dispatch(setIsOpenUnassignedTasksDrawer(false));
    }

    function _handleMouseEnter() {
        const payload = {
            location: {
                lat,
                lng
            },
            routeLevelData
        };
        eventBus.publish(constants.mapChildEvents.ROUTE_MOUSEENTER, payload);
        if (emittedEventHandler) {
            emittedEventHandler({
                event: constants.mapChildEvents.ROUTE_MOUSEENTER,
                payload
            });
        }
        setIsHovered(true);
    }

    function _handleMouseLeave() {
        const payload = {
            clientRouteId
        };
        eventBus.publish(constants.mapChildEvents.ROUTE_MOUSELEAVE, payload);

        if (emittedEventHandler) {
            emittedEventHandler({
                event: constants.mapChildEvents.ROUTE_MOUSELEAVE,
                payload
            });
        }
        setIsHovered(false);
    }

    function _getClassName() {
        let boxClassName = 'routemarker';
        boxClassName =
            (isSelected && `${boxClassName} routemarker_selected`) ||
            boxClassName;
        return boxClassName;
    }

    const _getUnAssignedTasksRouteMarker = () =>
        !isPlanned ? (
            <Icon color="white" icon="questionMark" size="s" />
        ) : (
            <Icon color="white" icon="route" />
        );

    return (
        <button
            data-testid="route-marker"
            type="button"
            className={_getClassName()}
            onClick={_toggleIsSelected}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            onFocus={_handleMouseEnter}
        >
            <span className="routemarker-circle" style={colorCSS}>
                {!isSelected && _getUnAssignedTasksRouteMarker()}
                {isSelected && <Icon color="white" icon="checkmark" />}
            </span>
            {showRouteLabel && (
                <span className="routemarker-label">{label}</span>
            )}
            {showTripCount && (
                <span
                    data-testid="trip-count-label"
                    className="routemarker-trip-label"
                    style={colorCSS}
                >
                    {t('trips:tripCount', { count: tripCount })}
                </span>
            )}
            {showPopup && isHovered ? hoverPopup : null}
        </button>
    );
}
export default RouteMarker;
