import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocketScheduleItem } from '~/api/types';
import { useModal } from '~/ui';
import { MarkEndOfShiftModalContent } from './MarkEndOfShiftModalContent';
import { MarkEndOfShiftMenuButton } from './MarkEndOfShiftMenuButton';
import { useEndOfShiftCallbacks } from './useEndOfShiftCallbacks';

interface MarkEndOfShiftControlProps {
    /**
     * The stop data
     */
    stopData: SocketScheduleItem;
}

export const MarkEndOfShiftControl = ({
    stopData
}: MarkEndOfShiftControlProps) => {
    const { t } = useTranslation('markEndOfShift');
    const { hasLastStopInShift, handleRemoveShiftEnd } =
        useEndOfShiftCallbacks(stopData);
    const {
        showModal: showMarkEndOfShiftModal,
        hideModal: hideMarkEndOfShiftModal,
        modal: MarkEndOfShiftModal
    } = useModal();

    return (
        <>
            {!hasLastStopInShift && (
                <MarkEndOfShiftMenuButton
                    onClick={showMarkEndOfShiftModal}
                    buttonText={t('button.markEndOfShift')}
                    data-testid="button-mark-end-of-shift"
                />
            )}
            {hasLastStopInShift && (
                <MarkEndOfShiftMenuButton
                    onClick={handleRemoveShiftEnd}
                    buttonText={t('button.removeEndOfShift')}
                    data-testid="button-remove-end-of-shift"
                />
            )}
            <MarkEndOfShiftModal
                className="mark-end-of-shift-modal"
                hasCustomHeader
            >
                <MarkEndOfShiftModalContent
                    stopData={stopData}
                    hideModal={hideMarkEndOfShiftModal}
                />
            </MarkEndOfShiftModal>
        </>
    );
};
