import React, { useState } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import dateUtils from '~/utils/date-utils';

import DaysOfWeek from '~/ui/components/DaysOfWeek';
import { Icon, TimeInput, TooltipButton } from '~/ui';

import './ShiftBlock.scss';

const supportedShitSlotNumber = 2;

const ShiftBlock = ({
    data,
    id,
    onChange,
    onShiftBlockRemove,
    onShiftSlotRemove,
    onAddNewSlot,
    ...extra
}) => {
    const { days, shift } = data;
    const { t } = useTranslation(['common', 'driverManagement']);

    const [isShiftBlockHovered, setIsShiftBlockHovered] = useState(false);
    const [isSecondShiftSlotHovered, setIsSecondShiftSlotHovered] =
        useState(false);

    const getShiftRemoveButtonClasses = () => {
        const baseClass = 'shiftremovebutton _p-absolute';
        const conditionalClasses = {
            visible: isShiftBlockHovered,
            hidden: !isShiftBlockHovered
        };
        return classNames(baseClass, conditionalClasses);
    };

    const getShiftSlotRemoveButtonClasses = () => {
        const baseClass = 'shiftslotremovebutton _p-absolute';
        const conditionalClasses = {
            visible: isSecondShiftSlotHovered,
            hidden: !isSecondShiftSlotHovered
        };
        return classNames(baseClass, conditionalClasses);
    };

    const getShiftBlockClasses = () => {
        const baseClass = 'shiftblock _p-relative';
        const conditionalClasses = {
            ishovered: isShiftBlockHovered
        };
        return classNames(baseClass, conditionalClasses);
    };

    const getShiftSlotClasses = (idx) => {
        const baseClass = 'shiftslot _p-relative _fd-column';
        const conditionalClasses = {
            ishovered: idx > 0 && isSecondShiftSlotHovered
        };
        return classNames(baseClass, conditionalClasses);
    };

    const handleWeekdaysChange = (newDaySelection) => {
        const newData = cloneDeep(data);
        newData.days = newDaySelection;
        onChange(id, newData);
    };

    const handleShiftChange = (idx, value) => {
        const newData = cloneDeep(data);
        let editedShift = newData.shift[idx];
        if (value) {
            editedShift = { ...editedShift, ...value };
        }
        newData.shift[idx] = editedShift;
        onChange(id, newData);
    };

    const renderShiftSlots = (shiftSlots) => {
        return shiftSlots.map((item, idx) => {
            const { start: shiftStart, end: shiftEnd } = item;
            const dtStart = DateTime.fromISO(shiftStart);
            const dtEnd = DateTime.fromISO(shiftEnd);
            const isValidShift = dtStart.isValid && dtEnd.isValid;
            const shiftDuration = isValidShift
                ? dateUtils.getDuration(dtStart, dtEnd)?.toObject()
                : { hours: 0, minutes: 0 };
            const shiftDurationHelp = t(
                'driverManagement:shiftDuration',
                shiftDuration
            );

            return (
                <div
                    key={`${item.start}-${item.end}`}
                    data-testid={extra['data-testid'] || 'shiftslot'}
                    className={getShiftSlotClasses(idx)}
                    onBlur={() => {}}
                    onFocus={() => {}}
                    onMouseOver={(e) => {
                        e.stopPropagation();
                        setIsSecondShiftSlotHovered(true);
                    }}
                    onMouseOut={(e) => {
                        e.stopPropagation();
                        setIsSecondShiftSlotHovered(false);
                    }}
                >
                    {idx > 0 && (
                        <TooltipButton
                            variant="secondary"
                            size="m"
                            className={getShiftSlotRemoveButtonClasses()}
                            onClick={() => onShiftSlotRemove(id)}
                        >
                            <Icon icon="cancel" size="s" color="neptune" />
                            {t('common:remove')}
                        </TooltipButton>
                    )}
                    <p className="shifttime">
                        {t('driverManagement:shiftTime')} *
                    </p>
                    <div
                        className="shift-inputs _d-flex _jc-flex-start"
                        data-testid={`${item.start}-${item.end}`}
                    >
                        <TimeInput
                            value={item.start}
                            variant="naked"
                            data-testid="shiftstart"
                            placeholder={item.start ? '' : '08:00 AM'}
                            showPlaceholder={!item.start}
                            className="shift-input"
                            onChange={(value) =>
                                handleShiftChange(idx, { start: value })
                            }
                        />
                        -
                        <TimeInput
                            value={item.end}
                            variant="naked"
                            data-testid="shiftend"
                            placeholder={item.end ? '' : '05:00 PM'}
                            showPlaceholder={!item.end}
                            className="shift-input"
                            onChange={(value) =>
                                handleShiftChange(idx, { end: value })
                            }
                        />
                    </div>
                    <p className="shiftduration" data-testid="shiftduration">
                        {shiftDurationHelp}
                    </p>
                </div>
            );
        });
    };

    return (
        <div
            data-testid={extra['data-testid'] || 'shiftblock'}
            className={getShiftBlockClasses()}
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseOver={() => setIsShiftBlockHovered(true)}
            onMouseOut={() => setIsShiftBlockHovered(false)}
        >
            <TooltipButton
                onClick={() => onShiftBlockRemove(id)}
                variant="secondary"
                size="m"
                className={getShiftRemoveButtonClasses()}
            >
                <Icon icon="cancel" size="s" color="neptune" />
                {t('common:remove')}
            </TooltipButton>
            <p className="_mb-1">{t('common:weekday')}</p>
            <DaysOfWeek
                selectedWeekdays={days}
                size="large"
                isEditable
                onChange={handleWeekdaysChange}
            />
            <div data-testid="shifttime" className="_mt-6">
                <div className="_d-flex _ai-center shiftslots">
                    {renderShiftSlots(shift)}
                    {shift.length < supportedShitSlotNumber && (
                        <div className="_d-flex _fd-column addnewslot">
                            <p className="_m-0">
                                {t('driverManagement:twoTimeSlots')}
                            </p>
                            <TooltipButton
                                variant="link"
                                onClick={() => {
                                    setIsShiftBlockHovered(false);
                                    onAddNewSlot(id);
                                }}
                            >
                                <Icon icon="plus" size="s" color="meteor" />
                                {t('driverManagement:addSecondShiftDuration')}
                            </TooltipButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ShiftBlock.propTypes = {
    data: PT.shape({
        days: PT.arrayOf(PT.number).isRequired,
        shift: PT.arrayOf(
            PT.shape({
                start: PT.string,
                end: PT.string
            })
        ).isRequired
    }),
    id: PT.string.isRequired,
    onChange: PT.func.isRequired,
    onShiftBlockRemove: PT.func.isRequired,
    onShiftSlotRemove: PT.func.isRequired,
    onAddNewSlot: PT.func.isRequired
};

export default ShiftBlock;
