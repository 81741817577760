import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { checkIfAllTripsAreHidden, routeHasReloads } from '~/utils/tripUtils';
import { getParentClientRouteIdsWithReloadIds } from '~/components/MapPage/usePlanStopsEffects/utils';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';

export const useIsPlanRouteHidden = () => {
    const { routesLevelData } = usePlanMapPropsContext();
    const hiddenRoutes: Record<string, boolean> =
        useSelector(selectHiddenRoutes);

    const isPlanRouteHidden = useCallback(
        ({ clientRouteId }: { clientRouteId: string }) => {
            const topLevelClientRouteIdsWithReloads =
                getParentClientRouteIdsWithReloadIds(routesLevelData);
            const hasMultipleTrips = routeHasReloads(
                topLevelClientRouteIdsWithReloads[clientRouteId]
            );

            if (!hasMultipleTrips) {
                return Boolean(hiddenRoutes[clientRouteId]);
            }

            const tripClientRouteIds =
                topLevelClientRouteIdsWithReloads[clientRouteId];

            return checkIfAllTripsAreHidden(tripClientRouteIds, hiddenRoutes);
        },
        [hiddenRoutes, routesLevelData]
    );

    return {
        isPlanRouteHidden
    };
};
