import React, { CSSProperties } from 'react';

import { Icon, IconButton, UIElement } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize } from '~/ui/components/Icon/IconSizes';
import { TooltipPlacement } from '~/ui/components/Tooltip';
import './routecard-marker.scss';

interface RouteCardMarkerProps extends UIElement {
    disabled?: boolean;
    visible?: boolean;
    isRouteLocked?: boolean;
    text?: string;
    textColor?: string;
    textStyle?: CSSProperties | undefined;
    icon?: IconName;
    iconSize?: IconSize;
    iconColor?: string;
    iconStyle?: CSSProperties | undefined;
    tooltipMessage?: string;
    tooltipPlacement?: TooltipPlacement;
    onClick?: () => void;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
}

function RouteCardMarker({
    className,
    disabled,
    isRouteLocked = false,
    visible = true,
    text = '',
    textColor = '',
    textStyle = {},
    icon,
    iconSize = 'm',
    iconColor,
    iconStyle,
    tooltipMessage,
    tooltipPlacement = 'bottom',
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...extra
}: RouteCardMarkerProps) {
    function getClassName() {
        let boxClassName = 'routecard-marker';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-marker'}
        >
            <IconButton
                className="routecard_select-button"
                style={iconStyle}
                disabled={disabled}
                visible={visible}
                text={text}
                textColor={textColor}
                textStyle={textStyle}
                icon={icon}
                iconSize={iconSize}
                iconColor={iconColor}
                tooltipMessage={tooltipMessage}
                tooltipPlacement={tooltipPlacement}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}_select-button`
                        : 'routecard_select-button'
                }
            />
            {isRouteLocked && (
                <div className="route-locked-icon__container">
                    <Icon
                        icon="doubleCheckIcon"
                        size="xs"
                        color="white"
                        data-testid="route-locked-indicator"
                    />
                </div>
            )}
        </div>
    );
}

export default RouteCardMarker;
