import React, { useState } from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import './Slider.scss';

function Slider(props) {
    const {
        className,
        defaultValue = '0',
        disabled = false,
        min = '0',
        max = '100',
        step,
        labels,
        onChange = () => {},
        ...extra
    } = props;

    const [percentage, setPercentage] = useState(
        calculatePercentage(defaultValue)
    );

    const dividers = [];
    const numDividers = step && Math.round((max - min) / step);
    for (let i = 0; i < numDividers + 1; i++) {
        dividers.push(<div key={`divider-${i}`} className="divider" />);
    }

    const labelElements =
        labels &&
        labels.map((label) => {
            return <div key={`label-${label.toLowerCase()}`}>{label}</div>;
        });

    /**
     * Calculate percentage from the range
     * @param {string} value
     * @returns {number}
     */
    function calculatePercentage(value) {
        return (
            ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100
        );
    }

    function handleChange(e) {
        setPercentage(calculatePercentage(e.target.value));
        onChange(e);
    }

    const backgroundStyle = {
        background: disabled
            ? `linear-gradient(to right,
              var(--color-galaxy-400) 0%,
              var(--color-galaxy-400) ${percentage}%,
              var(--color-galaxy-200) ${percentage}%,
              var(--color-galaxy-200) 100%)`
            : `linear-gradient(to right,
              var(--color-ocean) 0%,
              var(--color-ocean) ${percentage}%,
              var(--color-neptune-200) ${percentage}%,
              var(--color-neptune-200) 100%)`
    };

    return (
        <div className="slider _p-relative">
            <input
                type="range"
                defaultValue={defaultValue}
                min={min}
                max={max}
                step={step}
                onChange={handleChange}
                disabled={disabled}
                className={classNames('slider-input', className)}
                style={backgroundStyle}
                data-testid={extra['data-testid'] || 'slider'}
            />
            {step && (
                <div className="divider-container _jc-space-between">
                    {dividers}
                </div>
            )}
            {labels && (
                <div className="labels _jc-space-between _text-3">
                    {labelElements}
                </div>
            )}
        </div>
    );
}

Slider.propTypes = {
    /** Class name */
    className: PT.string,
    /** Default value */
    defaultValue: PT.string,
    /** Disabled */
    disabled: PT.bool,
    /** Minimum value */
    min: PT.string,
    /** Maximum value */
    max: PT.string,
    /** Step size */
    step: PT.string,
    /** Change event */
    onChange: PT.func,
    /** Labels */
    labels: PT.array
};

export default Slider;
