import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from '~/ui';
import './ReRunSchedulerModalContent.scss';
import RadioListItem from '~/ui/components/RadioListItem/RadioListItem';
import constants from '~/utils/constants';

const { schedulerKeys } = constants;

function ReRunSchedulerModalContent({
    selectedValue,
    handleChoice,
    handleTaskLockChoice,
    orderLockActive,
    onSchedulerButtonClick
}) {
    const { t } = useTranslation('routePlanner');

    return (
        <>
            <div className="rerunschedulermodal _d-flex _fd-column">
                <ReRunSchedulerModalRadioListItem
                    id="lockRoutes"
                    value={constants.schedulerKeys.lockRoutes}
                    handleChange={handleChoice}
                    selectedValue={selectedValue?.toString()}
                    title={t(
                        'reRunSchedulerOptimization.optimizeAndAddToExistingRoutesTitle'
                    )}
                    description={t(
                        'reRunSchedulerOptimization.optimizeAndAddToExistingRoutesDescription'
                    )}
                >
                    {selectedValue === schedulerKeys.lockRoutes ? (
                        <div className="rerunschedulermodal-subcontent">
                            <div className="subcontent-header">
                                {t('reRunSchedulerOptimization.taskLockHeader')}
                            </div>
                            <div className="subcontent-checkboxsection">
                                <Checkbox
                                    id="orderLock"
                                    name="orderLock"
                                    value={schedulerKeys.lockRoutes}
                                    selectedValues={
                                        orderLockActive
                                            ? [schedulerKeys.lockRoutes]
                                            : []
                                    }
                                    handleChange={handleTaskLockChoice}
                                />
                                <div className="subcontent-checkboxdescription">
                                    <div className="subcontent-checkboxmaindescription">
                                        {t(
                                            'reRunSchedulerOptimization.taskLockDescription'
                                        )}
                                    </div>
                                    <div className="subcontent-checkboxsubdescription">
                                        {t(
                                            'reRunSchedulerOptimization.taskLockSubDescription'
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </ReRunSchedulerModalRadioListItem>
                <ReRunSchedulerModalRadioListItem
                    id="closeRoutes"
                    value={constants.schedulerKeys.closeAllRoutes}
                    handleChange={handleChoice}
                    selectedValue={selectedValue?.toString()}
                    title={t(
                        'reRunSchedulerOptimization.optimizeAndCreateNewRoutesTitle'
                    )}
                    description={t(
                        'reRunSchedulerOptimization.optimizeAndCreateNewRoutesDescription'
                    )}
                />
                <ReRunSchedulerModalRadioListItem
                    id="uncloseRoutes"
                    value={constants.schedulerKeys.uncloseAllRoutes}
                    handleChange={handleChoice}
                    selectedValue={selectedValue?.toString()}
                    title={t(
                        'reRunSchedulerOptimization.optimizeAllTasksTitle'
                    )}
                    status={t('reRunSchedulerOptimization.Cautious')}
                    description={t(
                        'reRunSchedulerOptimization.optimizeAllTasksDescription'
                    )}
                />
            </div>
            <div className="rerunschedulermodal-footer _d-flex _jc-flex-end">
                <Button
                    disabled={!selectedValue}
                    onClick={onSchedulerButtonClick}
                >
                    {t('runScheduler')}
                </Button>
            </div>
        </>
    );
}

export default ReRunSchedulerModalContent;

function ReRunSchedulerModalRadioListItem({
    id,
    value,
    handleChange,
    selectedValue,
    title,
    status,
    description,
    children
}) {
    return (
        <RadioListItem
            id={id}
            name="optimizationChoice"
            value={value}
            handleChange={handleChange}
            selectedValue={selectedValue?.toString()}
            listItemClassName="rerunschedulermodal-item"
        >
            <div className="rerunschedulermodal-maincontent">
                <div style={{ width: '52rem' }}>
                    <div className="rerunschedulermodal-title _d-flex">
                        {title}
                        {status && (
                            <div className="rerunschedulermodal-status">
                                {status}
                            </div>
                        )}
                    </div>
                    <div className="rerunschedulermodal-description">
                        {description}
                    </div>
                </div>
                <div className="rerunschedulermodal-radio" />
            </div>
            {children}
        </RadioListItem>
    );
}
