import React from 'react';
import classNames from 'classnames';
import generalUtils from '~/utils/general-utils';
import './CompartmentLabel.scss';

type CompartmentLabelProps = {
    label: string;
    className?: string;
};

const CompartmentLabel = ({
    label,
    className
}: CompartmentLabelProps): JSX.Element => {
    const classes = classNames('compartment-label', className);
    return (
        <span className={classes} data-testid="compartment-label">
            {generalUtils.capitalizeFirstChar(label)}
        </span>
    );
};
export default CompartmentLabel;
