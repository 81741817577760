import { CSSProperties, useState } from 'react';
import { DragOverEvent, UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';

interface UseDraggedOverStyle {
    dragTargetId: UniqueIdentifier | null;
    style: CSSProperties | undefined;
}

export const useDraggedOverStyle = (): UseDraggedOverStyle => {
    const [dragTarget, setDragTarget] = useState<null | UniqueIdentifier>(null);
    const onDragOver = (event: DragOverEvent) => {
        const { over } = event;
        if (over) {
            const { id } = over;
            setDragTarget(id);
        }
    };

    const clearDragTarget = () => {
        setDragTarget(null);
    };

    useDndMonitor({
        onDragOver,
        onDragCancel: clearDragTarget,
        onDragEnd: clearDragTarget
    });

    const style = dragTarget
        ? {
              borderRadius: 'var(--border-radius-l)',
              border: '0.1rem dashed var(--color-galaxy-800)'
          }
        : undefined;

    return {
        dragTargetId: dragTarget,
        style
    };
};
