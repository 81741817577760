import { IPrivateApiFields } from './CommonTypes';
import { DriverBreakRule } from './DriverBreak';
import { ClientIntermodalPreferences } from './Client';

/**
 * String enum of possible client types.
 * @category API Types
 */
enum ClientType {
    STANDARD = 'standard',
    DRIVER_GROUP = 'driver-group'
}

/**
 * String enum of possible pick up delivery models
 * @category API Types
 */
export enum PickupDeliveryModelType {
    TAXICAB = 'taxicab',
    CARPOOLING = 'carpooling'
}

export interface DriverRecommendation {
    enabled: boolean;
    topTakenPercentage: number;
    skipConstraintChecking: boolean;
}

export interface ClientPreferences {
    maxRouteDuration: number;
    maxTripDuration: number;
    enableCapacityConstraints: boolean;
    lockClusterInitialSolution: boolean;
    balanceBasedOnDuration: boolean;
    balanceBasedOnStops: boolean;
    balanceBasedOnMileage: boolean;
    balanceBasedOnVolume: boolean;
    enableGoogleApiEta: boolean;
    enableGoogleDirectionsApi: boolean;
    shippingUnits: string;
    enableRouteBuilder: boolean;
    enableScheduler: boolean;
    costPerUnitDistance: number;
    machineLearning: number;
    mlSequencing: boolean;
    acceptDelays: boolean;
    acceptEarly: boolean;
    enableDriverTracks: boolean;
    enableClusterInitialSolution: boolean;
    enableDriverShiftTime: boolean;
    useOriginalStopOrder: boolean;
    maxTaskOnRoute: number;
    enableRtaOnEdit: boolean;
    enableTrafficTimes: boolean;
    enableMultiTripOptimization: boolean;
    enableTruckRoutes: boolean;
    createExtraVehicles: boolean;
    enableDriverSequenceML: boolean;
    enableDriverSequenceMLGeo: boolean;
    avoidDifficultTurns: boolean;
    avoidTolls: boolean;
    enableHighPrecisionETA: boolean;
    enableLocationGrid: boolean;
    enableGooglePlacesGeocoding: boolean;
    allowApproximateGeocoding: boolean;
    enableContainerizedScheduler: boolean;
    enableServerlessRouteEditing: boolean;
    forceLongRunningPlannerInfra: boolean;
    usePreCluster: boolean;
    enableTaskConsolidation: boolean;
    enableScheduledNotifications: boolean;
    pickupDeliveryModel: PickupDeliveryModelType;
    useDeliveryTWForTwoPart: boolean;
    useAllAvailableDrivers: boolean;
    limitReorder: number;
    zoneBasedRouting: string;
    sendRtaNotification: boolean;
    disableInProgressAssignmentStatus: boolean;
    enableTwoPartTaskEditing: boolean;
    maxRouteLengthMeter: number;
    balanceBasedOnItems: boolean;
    enableDriverBooking: boolean;
    addDepotsOnDemandToInactiveDriver: boolean;
    enableDriverManagement: boolean;
    enableParallelScheduler: boolean;
    improveRoutesAttractiveness: boolean;
    startRouteImmediately: boolean;
    penalizeWaitTimeProportionally: boolean;
    penalizeLastEdge: boolean;
    minimizeRouteCrossingsInRTA: boolean;
    minimizeWaitingTimes: boolean;
    useCustomCost: boolean;
    autoArriveMode: string;
    defaultGeofenceRadius: number;
    minNumRoutes: number;
    allowDispatchingFutureDates: boolean;
    startAllTripsFromStartDepot: boolean;
    useClientCountry: boolean;
    enableContinuousDispatching: boolean;
    enableCalculateImpact: boolean;
    allowEditingAssignedTasks: boolean;
    useHereMapTiles: boolean;
    driverRecommendation: DriverRecommendation;
    showDispatchCancelButton: boolean;
    driverPrivacy: boolean;
    snapToRoad: boolean;
    customLogo: string;
}

export interface ClientTemplate {
    integrationDownload?: string[];
    integrationExport?: string[];
    integrationName?: string;
}

export interface ClientCourierPreferences {
    maxCompensationPercentage: number;
    minRevenuePerRoute: number;
    capacityBuffer: number;
    revenuePerItem: number;
    servicedLocations: string[];
}

export interface ClientNotifications {
    enableCustomerPortal: boolean;
    displayCustomPortal: boolean;
    customPortalLogoUrl: string;
    emailNotification: boolean;
    smsNotification: boolean;
    notifyOnCreateAssignment: boolean;
    notifyOnStartAssignment: boolean;
    notifyOnCompleteAssignment: boolean;
    notifyBasedOnTime: boolean;
    notificationTime: number;
    notifyOnRequest: boolean;
    notifyOnDelayChange: boolean;
    notifyOnScannedDelivered: boolean;
    notifyOnScannedLoading: boolean;
    shareLocationNStopsAway: number;
}

export interface ClientOnboardingCustomerInformation {
    businessDriverBookingPotential: string[];
    businessIndustry: string;
    businessWarehouseCount: string;
    businessShippingAccounts: string;
    businessStopsPerDay: string;
    businessEnableSameDayWaveScheduling: true;
    businessRouteOptimization: string;
    businessTaskType: string;
}

export interface ClientOnboarding {
    customerInformation: ClientOnboardingCustomerInformation;
    onboarded: true;
}

export type ClientPreferenceKeys = keyof ClientPreferences;

/**
 * Type for API client
 * @category API Types
 */
export interface ApiClient extends IPrivateApiFields {
    /**
     * the client's id
     */
    id: string;
    /**
     * the client's name
     */
    name: string;
    /**
     * the client's address id
     */
    address: string;
    /**
     * the client's domain
     */
    domain: string;
    /**
     * the client's preferences
     */
    preferences: ClientPreferences;
    /**
     * the client's driver break rules
     */
    driverBreakRules: DriverBreakRule[];
    /**
     * the client's courier preferences
     */
    courierPreferences: ClientCourierPreferences;
    /**
     * the client's notification preferences
     */
    notifications: ClientNotifications;
    /**
     * the client's onboarding information
     */
    onboarding: ClientOnboarding;
    /**
     * the client's intermodal preferences
     */
    intermodal?: ClientIntermodalPreferences;
    /**
     * the client's code
     */
    code: string;
    /**
     * the client's timezone (TZ database name, like America/New_York)
     */
    timezone: string;
    /**
     * indicates if a client is a driver_group or a standard client
     */
    type: ClientType;
    /**
     * client's related driver groups
     */
    // eslint-disable-next-line camelcase
    driver_groups: string[];
}
