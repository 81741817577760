import axios from 'axios';
import {
    AxiosApiResponse,
    ApiInventoryItem,
    PaginationMetadata,
    PaginationParams
} from './types';

/* eslint-disable camelcase */
interface GetInventoryItemsParams extends PaginationParams {
    task_id?: string;
    taskIdassignmentId?: string;
    barcodeId?: string;
    signed?: boolean;

    /* Date in YYYY-MM-DD format */
    route_date?: string;
}
/* eslint-enable camelcase */

type GetInventoryItemsResponseData = ApiInventoryItem[];

type GetInventoryItemsResponse = AxiosApiResponse<
    GetInventoryItemsResponseData,
    PaginationMetadata
>;

type UpdateInventoryItemResponse = AxiosApiResponse<ApiInventoryItem>;

interface DeleteInventoryItemResponseData {
    id?: string;
}

type DeleteInventoryItemResponse =
    AxiosApiResponse<DeleteInventoryItemResponseData>;

/**
 * Implementations of API methods under the `/inventoryitems` path
 *
 * @category API
 */
export default class InventoryItemsApi {
    /**
     * Path of the endpoint
     */
    private static readonly path = '/inventoryitems';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'inventoryitems';

    static get(
        params: GetInventoryItemsParams = { signed: false }
    ): Promise<GetInventoryItemsResponse> {
        return axios.get(this.path, {
            params
        });
    }

    static updateInventoryItem(
        id: string,
        payload: ApiInventoryItem
    ): Promise<UpdateInventoryItemResponse> {
        return axios.patch(`${this.path}/${id}`, { ...payload });
    }

    static deleteInventoryItem(
        id: string
    ): Promise<DeleteInventoryItemResponse> {
        return axios.delete(`${this.path}/${id}`);
    }
}
