/* eslint-disable camelcase */
import axios from 'axios';

import constants from '~/utils/constants';
import {
    ApiUser,
    ApiUserGroup,
    AxiosApiResponse,
    PaginationMetadata,
    PaginationParams
} from '~/api/types';

type GetUsersParams = PaginationParams;

interface GetUserToUserGroupAssociationsParams extends PaginationParams {
    user_groups?: string;
    user_group_id?: string[];
    users?: string;
    user_id?: string[];
}

interface GetUserToUserGroupAssociationsResponseData {
    successes?: ApiUser[] | ApiUserGroup[];
    failures?: {
        id?: string;
        error?: unknown;
    }[];
}

type AddUserParams = Partial<ApiUser>;

/**
 * Implemenations of API methods under the /users and /user paths
 */
export class UsersApi {
    /**
     * Path of the single-user API endpoint
     */
    private static readonly userPath = '/user';

    /**
     * Path of the multi-user API endpoint
     */
    private static readonly usersPath = '/users';

    static get(
        params: GetUsersParams = { page: 0, limit: 2500 }
    ): Promise<AxiosApiResponse<ApiUser[], PaginationMetadata>> {
        return axios.get(this.usersPath, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static getUserToUserGroupAssociations(
        params: GetUserToUserGroupAssociationsParams = {}
    ): Promise<AxiosApiResponse<GetUserToUserGroupAssociationsResponseData>> {
        return axios.get(`${this.usersPath}/user_groups`, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static getCurrentUser(): Promise<AxiosApiResponse<ApiUser>> {
        return axios.get(this.userPath, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static addUser(
        params: AddUserParams = {}
    ): Promise<AxiosApiResponse<ApiUser>> {
        return axios.post(this.userPath, {
            ...params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    /**
     * Update user
     */
    static updateUser(
        id: string,
        data: Partial<ApiUser>
    ): Promise<AxiosApiResponse<ApiUser>> {
        return axios.patch(`${this.userPath}/${id}`, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static deleteUser(id: string): Promise<AxiosApiResponse<ApiUser>> {
        if (!id) {
            return Promise.reject('User id to delete must be provided');
        }
        return axios.delete(`${this.userPath}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
