import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const webSolutionSlice = createSlice({
    name: 'webSolution',
    initialState: null,
    reducers: {
        setWebSolution: (state, action) => {
            return action.payload;
        },
        resetWebSolution: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setWebSolution, resetWebSolution } = webSolutionSlice.actions;

type WebSolution = {
    data?: {
        schedulerId?: string;
        hasValidRoutes?: boolean;
    };
};

type State = {
    webSolution?: WebSolution | null;
};

export const selectSchedulerId = (state: State): string | undefined =>
    state.webSolution?.data?.schedulerId;

export const selectHasValidRoutes = (state: State): boolean | undefined =>
    state.webSolution?.data?.hasValidRoutes ?? true;

export default webSolutionSlice.reducer;
