import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clientTaskToApiTask } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { LiveStop } from '~/data-classes';
import { useAddOperationalStops, useWebInterval } from '~/hooks';
import {
    TwoPartStopData,
    resetOperationalStopTask,
    selectOperationalStop,
    selectTwoPartStopData,
    setIsOpenOperationalStopModal
} from '~/reducers/addOperationalStopsSlice';
import { addToast } from '~/reducers/toastsSlice';
import constants from '~/utils/constants';
import {
    getFirstTwoPartApiTask,
    getSecondTwoPartApiTask,
    getStopDataFromLiveStop
} from './utils';
import { addExtraDateToDeliveryWindow } from './utils/addExtraDateToDeliveryWindow';
import { getAllTasks } from '~/reducers/tasksSlice';
import { selectDate } from '~/reducers/selectedDateSlice';

export const useInsertOperationalStopClick = () => {
    const { t } = useTranslation(['addTask', 'addOperationalStop']);

    const dispatch = useDispatch();

    const operationalStopTask = useSelector(selectOperationalStop);
    const selectedDate = useSelector(selectDate);

    const twoPartStopData = useSelector(selectTwoPartStopData);

    const { addOperationalStop } = useAddOperationalStops();
    const { refetch } = useWebInterval();

    const onSuccessAddOperationalStop = useCallback(() => {
        dispatch(resetOperationalStopTask());
        dispatch(setIsOpenOperationalStopModal(false));
        refetch();
        dispatch(
            addToast({
                message: t(
                    'addOperationalStop:messages.addOperationalStopConfirmation'
                ),
                variant: 'info'
            })
        );
        dispatch(
            getAllTasks({
                routeDate: selectedDate
            })
        );
    }, [dispatch, t, selectedDate, refetch]);

    const onErrorAddOperationalStop = useCallback(() => {
        dispatch(
            addToast({
                message: t(
                    'addOperationalStop:messages.addOperationalStopFailure'
                ),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const handleAddOpertionalStop = useCallback(
        async (operationalStopData) => {
            const { pickupStopData, deliveryStopData } =
                twoPartStopData as TwoPartStopData;

            const {
                taskId,
                stopPosition,
                hasCompletedTasks,
                driverStopNumber
            } = pickupStopData as LiveStop;

            const originalTaskId = taskId;

            const position = hasCompletedTasks
                ? stopPosition ?? constants.assignmentPosition
                : driverStopNumber;

            const { timeWindow: pickupWindow } = getStopDataFromLiveStop(
                pickupStopData as LiveStop
            );
            const { timeWindow: deliveryWindow } = getStopDataFromLiveStop(
                deliveryStopData as LiveStop
            );

            const updatedDeliveryWindow = addExtraDateToDeliveryWindow(
                pickupWindow,
                deliveryWindow
            );

            const firstTwoPartApiTask = getFirstTwoPartApiTask({
                pickupStopData: pickupStopData as LiveStop,
                deliveryStopData: operationalStopData,
                pickupWindow,
                deliveryWindow: updatedDeliveryWindow
            });
            const secondTwoPartApiTask = getSecondTwoPartApiTask({
                pickupStopData: operationalStopData,
                deliveryStopData: deliveryStopData as LiveStop,
                pickupWindow,
                deliveryWindow: updatedDeliveryWindow
            });

            try {
                await addOperationalStop(
                    [firstTwoPartApiTask, secondTwoPartApiTask],
                    position,
                    originalTaskId
                );
                onSuccessAddOperationalStop();
            } catch (error) {
                console.error(error);
                onErrorAddOperationalStop();
            }
        },
        [
            onSuccessAddOperationalStop,
            onErrorAddOperationalStop,
            twoPartStopData,
            addOperationalStop
        ]
    );

    const onInsertOperationalStopClick = useCallback(() => {
        try {
            const clientTask = {
                ...operationalStopTask,
                date: new Date(operationalStopTask.date),
                deliveryTimeWindows: [
                    {
                        start: new Date(
                            operationalStopTask.deliveryTimeWindows[0].start
                        ),
                        end: new Date(
                            operationalStopTask.deliveryTimeWindows[0].end
                        )
                    }
                ],
                pickupTimeWindows: [
                    {
                        start: new Date(
                            operationalStopTask.pickupTimeWindows[0].start
                        ),
                        end: new Date(
                            operationalStopTask.pickupTimeWindows[0].end
                        )
                    }
                ]
            };
            const apiTask = clientTaskToApiTask(clientTask);
            handleAddOpertionalStop(apiTask);
        } catch (error) {
            console.error(error);
        }
    }, [operationalStopTask, handleAddOpertionalStop]);

    const isInvalidForm = useMemo(() => {
        const {
            pickupCustomerName,
            pickupServiceTimeMins,
            pickupAddressStreet,
            pickupAddressCity,
            pickupAddressZipCode,
            externalTaskType
        } = operationalStopTask || {};

        return (
            !pickupCustomerName ||
            !pickupServiceTimeMins ||
            !pickupAddressStreet ||
            !pickupAddressCity ||
            !pickupAddressZipCode ||
            !externalTaskType
        );
    }, [operationalStopTask]);

    return {
        onInsertOperationalStopClick,
        isInvalidForm
    };
};
