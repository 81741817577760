import React from 'react';
import { StopModalMenuButton } from '~/ui';
import './MarkEndOfShiftMenuButton.scss';
import { UIElement } from '~/ui/components/types';

interface MarkEndOfShiftMenuButtonProps extends UIElement {
    /**
     * The button text
     */
    buttonText: string;

    /**
     * The button `click` event handler
     */
    onClick: () => void;
}

export const MarkEndOfShiftMenuButton = ({
    buttonText,
    onClick,
    ...extra
}: MarkEndOfShiftMenuButtonProps) => {
    const dataTestId = extra['data-testid'] || 'button-mark-end-of-shift';
    return (
        <StopModalMenuButton
            onClick={onClick}
            buttonIcon="endOfShift"
            buttonIconColor="mars"
            buttonText={buttonText}
            className="mark-end-of-shift-menu-button"
            data-testid={dataTestId}
        />
    );
};
