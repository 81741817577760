import React from 'react';
import classNames from 'classnames';

import './EquipmentLabels.scss';

const maxNumOfLabels = 7;

interface EquipmentLabelsProps {
    labels?: string[];
    className?: string;
    'data-testid'?: string;
}

export const EquipmentLabels = ({
    labels = [],
    className,
    ...extra
}: EquipmentLabelsProps) => {
    const overMax = labels.length > maxNumOfLabels;
    const getClassName = () => {
        const defaultClassName = 'equipment-labels _d-flex';
        return classNames(defaultClassName, className);
    };
    if (!labels?.length) return null;
    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'equipment-labels'}
        >
            <ul className="equipment-labels-list _d-flex _ai-center _jc-flex-start _fw-wrap">
                {labels.reduce((all, item, idx) => {
                    if (overMax && idx > maxNumOfLabels) return all;
                    const displayedMaxLabels =
                        overMax && idx === maxNumOfLabels;
                    const labelItem = displayedMaxLabels
                        ? `${labels.length - maxNumOfLabels}+`
                        : item;
                    const labelTestId = displayedMaxLabels ? 'over' : 'label';
                    all.push(
                        <li
                            key={labelItem}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-${labelTestId}`
                                    : `equipment-labels-${labelTestId}`
                            }
                        >
                            <span>{labelItem}</span>
                        </li>
                    );
                    return all;
                }, [] as React.ReactNode[])}
            </ul>
        </div>
    );
};

EquipmentLabels.maxNumOfLabels = maxNumOfLabels;
