import { TableInstance, Row } from 'react-table';
import { useCallback, useEffect, useMemo } from 'react';
import { MappedRowDataForDisplay, RowData } from './types';

interface UseTableRowsProps {
    /**
     * Helper function for mapping each row to props
     */
    getRowProps: (row: Row<RowData>) => RowData;
    /**
     * The table instance returned by useTable hook
     */
    tableInstance: TableInstance<RowData> & {
        /**
         * The list of page rows returned by the table instance
         */
        page: Row<RowData>[];
    };
    /**
     * Function to be called each time the list
     * of filtered rows are updated
     */
    onFilteredRowsUpdated: (rows: MappedRowDataForDisplay[]) => void;
}

export const useTableRows = ({
    getRowProps,
    onFilteredRowsUpdated,
    tableInstance
}: UseTableRowsProps) => {
    const { page, prepareRow, rows } = tableInstance;
    const mapRows = useCallback(
        (row: Row<RowData>): MappedRowDataForDisplay => {
            prepareRow(row);

            return {
                id: row.id,
                displayedData: row.values,
                props: row.getRowProps(getRowProps(row))
            };
        },
        [getRowProps, prepareRow]
    );

    const pageRows = useMemo(() => {
        return page.map(mapRows);
    }, [mapRows, page]);

    const filteredRows = useMemo(() => {
        return rows.map(mapRows);
    }, [mapRows, rows]);

    useEffect(() => {
        onFilteredRowsUpdated?.(filteredRows);
    }, [filteredRows, onFilteredRowsUpdated]);

    return {
        filteredRows,
        pageRows
    };
};
