import { DateTime } from 'luxon';
import constants from '~/utils/constants';
import { useFormatTimeToClientTimeZone } from '~/hooks';
import { useCallback } from 'react';

const { TIME_FORMAT_12H } = constants;

export const useEtaTimeFormatter = () => {
    const { formatTimeToClientTimeZone } = useFormatTimeToClientTimeZone();

    const getFormattedEta = useCallback(
        (rawEta: string | number) => {
            const etaInMilliseconds = Number(rawEta);

            return formatTimeToClientTimeZone({
                time: etaInMilliseconds
                    ? DateTime.fromMillis(etaInMilliseconds).toISO()
                    : (rawEta as string),
                format: TIME_FORMAT_12H
            });
        },
        [formatTimeToClientTimeZone]
    );

    return { getFormattedEta };
};
