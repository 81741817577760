import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import RoutePathGeometryApi from '~/api/RoutePathGeometryApi';
import { selectMainClient } from '~/reducers/mainClientSlice';

export const useRoutePathGeometry = (markers: string) => {
    const queryKey = 'route-path-geometry';
    const { isPlanRouteMode, isDispatchedRouteMode } = useMapUtils();
    const mainClient = useSelector(selectMainClient);
    const { snapToRoad = false } = mainClient?.preferences ?? {};

    const { data: routePathData, isFetching } = useQuery(
        [queryKey, markers, isPlanRouteMode, snapToRoad, isDispatchedRouteMode],
        async () => {
            const isModeValid = isPlanRouteMode || isDispatchedRouteMode;
            if (!isModeValid || !snapToRoad) return;
            const {
                data: { data }
            } = await RoutePathGeometryApi.getPath(markers);
            return data;
        }
    );
    return { routePathData, isFetching };
};
