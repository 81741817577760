import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
    replaceSelectedMapRoutes,
    selectSelectedMapRoutes
} from '~/reducers/selectedMapRoutesSlice';
import { PlanRoute } from '~/data-classes';
import {
    planRouteUtils,
    GetSelectedMapRoutesResult
} from '~/utils/plan-route-utils';
import { pageUtils } from '~/utils/page-utils';
import { useMapUtils } from '~/hooks';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

type Props = {
    planRoutes: PlanRoute[];
};

export const useSelectedMapRoutes = ({
    planRoutes
}: Props): GetSelectedMapRoutesResult => {
    const dispatch = useDispatch();
    const { isPlanRouteMode } = useMapUtils();
    const selectedMapRoutes: string[] = useSelector(selectSelectedMapRoutes);

    const { clientRouteIds, ...rest } = useMemo(() => {
        return planRouteUtils.getSelectedMapRoutes({
            planRoutes,
            selectedMapRoutes
        });
    }, [planRoutes, selectedMapRoutes]);

    useEffect(() => {
        if (
            !_.xor(selectedMapRoutes, clientRouteIds).length ||
            !isPlanRouteMode
        )
            return;
        dispatch(replaceSelectedMapRoutes(clientRouteIds));
        dispatch(resetSelectedMapStops());

        if (!clientRouteIds.length) {
            pageUtils.resetRouteSelection();
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clientRouteIds, selectedMapRoutes]);

    return {
        clientRouteIds,
        ...rest
    };
};
