import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDropDownMenuHandler } from '~/hooks';
import { DropDownMenu, DropDownMenuItem } from '../DropDownMenu';

import { DetailDropdownFieldProps } from './types';
import './DetailDropdownField.scss';

const ROOT_CLASS_NAME = 'detail-dropdown-field';

export const DetailDropdownField = ({
    validValues,
    selectedValue,
    placeholderValue,
    handleSubmit,
    isEditable,
    children,
    className,
    'data-testid': dataTestId
}: DetailDropdownFieldProps) => {
    const elementClassName = classNames(ROOT_CLASS_NAME, className);
    const outerContainerClassName = classNames(
        `${ROOT_CLASS_NAME}__outer-container`,
        {
            [`${className}__outer-container`]: className
        }
    );
    const innerContainerClassName = classNames(
        `${ROOT_CLASS_NAME}__inner-container`,
        {
            [`${className}__inner-container`]: className
        }
    );
    const menuTitleClassName = classNames(`${ROOT_CLASS_NAME}__menu-title`, {
        [`${className}__menu-title`]: className
    });
    const menuTitleTextClassName = classNames(
        `${ROOT_CLASS_NAME}__menu-title-text`,
        {
            [`${className}__menu-title-text`]: className
        }
    );
    const menuItemsClassName = classNames(`${ROOT_CLASS_NAME}__menu-items`, {
        [`${className}__menu-items`]: className
    });
    const menuItemClassName = classNames(`${ROOT_CLASS_NAME}__menu-item`, {
        [`${className}__menu-item`]: className
    });
    const dropDownMenuStyles = {
        outerContainer: outerContainerClassName,
        innerContainer: innerContainerClassName,
        menuTitle: menuTitleClassName,
        menuTitleText: menuTitleTextClassName,
        menuItems: menuItemsClassName
    };

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const menuTestId = `${elementDataTestId}__menu`;
    const menuItemTestId = `${elementDataTestId}__menu-item`;

    const {
        ref: dropdownRef,
        isOpen,
        onToggle,
        onClose
    } = useDropDownMenuHandler();

    const toggleDropDown = useCallback(() => {
        if (!isEditable) return;
        onToggle();
    }, [isEditable, onToggle]);

    const handleClickItem = useCallback(
        (value: string) => {
            handleSubmit?.({ value, previous: selectedValue });
            onClose();
        },
        [selectedValue, handleSubmit, onClose]
    );

    const dropdownMenuItemData = useMemo(() => {
        if (!validValues) return [];

        return validValues.map((item) => {
            return {
                key: item,
                menuItem: {
                    selected: selectedValue,
                    text: item,
                    onClick: () => {
                        handleClickItem(item);
                    }
                }
            };
        });
    }, [validValues, selectedValue, handleClickItem]);

    const showDropDownMenu = dropdownMenuItemData.length > 0;
    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {showDropDownMenu && (
                <DropDownMenu
                    title={{ name: selectedValue || placeholderValue }}
                    isOpen={isOpen}
                    toggle={toggleDropDown}
                    close={onClose}
                    styles={dropDownMenuStyles}
                    ref={dropdownRef}
                    data-testid={menuTestId}
                >
                    {dropdownMenuItemData.map(({ key, menuItem }) => (
                        <DropDownMenuItem
                            key={key}
                            menuItem={menuItem}
                            className={menuItemClassName}
                            data-testid={menuItemTestId}
                        />
                    ))}
                </DropDownMenu>
            )}
            {children}
        </div>
    );
};
