import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, TextInput } from '~/ui';
import { useGetFilteredStops } from './useGetFilteredStops';
import { LiveStop, PlanStop } from '~/data-classes';
import { useGetFilteredLiveStops } from './useGetFilteredLiveStops';
import { useMapUtils } from '~/hooks';
import { getRouteSequenceNumber } from '~/utils/stopUtils';

type StopSearchFieldProps = {
    setSelectedStop: (value: PlanStop | LiveStop | null) => void;
    selectedStop: PlanStop | LiveStop | null;
};

const StopSearchField = ({
    setSelectedStop,
    selectedStop
}: StopSearchFieldProps) => {
    const { t } = useTranslation('translation');
    const [inputValue, setInputValue] = useState('');
    const inputPlaceholder = t('ResequenceModal.Search.input_placeholder');
    const filteredStops = useGetFilteredStops(inputValue);
    const filteredLiveStops = useGetFilteredLiveStops(inputValue);
    const { isPlanRouteMode, isDispatchedRouteMode } = useMapUtils();

    const isShowDropdown = Boolean(filteredStops?.length) && !selectedStop;
    const isShowLiveDropdown =
        Boolean(filteredLiveStops?.length) && !selectedStop;

    const handleInputChange = (value: string) => {
        setSelectedStop(null);
        setInputValue(value);
    };

    function renderStopButtons(stops: PlanStop[] | LiveStop[]) {
        return stops.map((stop) => {
            const { stopName, stopId } = stop;
            const stopNumber = getRouteSequenceNumber(stop);

            return (
                <Button
                    key={stopId}
                    id={stopId}
                    className="_ai-center _jc-flex"
                    data-testid="stop-search-field__dropdown-item"
                    onClick={() => {
                        setInputValue(`${stopNumber}. ${stopName}`);
                        setSelectedStop(stop);
                    }}
                >
                    <Text className="_text-3" variant="12-normal">
                        {`${stopNumber}. ${stopName}`}
                    </Text>
                </Button>
            );
        });
    }

    return (
        <div className="stop-search-field" data-testid="stop-search-field">
            <TextInput
                placeholder={inputPlaceholder}
                type="text"
                value={inputValue}
                variant="basic"
                onChange={handleInputChange}
                data-testid="stop-search-field__textinput"
            />
            {(isShowDropdown || isShowLiveDropdown) && (
                <div
                    className="stop-search-field__dropdown"
                    data-testid="stop-search-field__dropdown"
                >
                    {isPlanRouteMode && renderStopButtons(filteredStops)}
                    {isDispatchedRouteMode &&
                        renderStopButtons(filteredLiveStops)}
                </div>
            )}
        </div>
    );
};

export default StopSearchField;
