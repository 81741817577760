import React from 'react';
import classNames from 'classnames';

import './routecard.scss';
import { UIElementWithChildren } from '../types';

interface RouteCardProps extends UIElementWithChildren {
    /**
     * Whether this route is selected
     */
    isRouteSelected?: boolean;

    /**
     * Whether this route is hidden
     */
    isRouteHidden?: boolean;

    /**
     * handler when the mouse enters the route card
     *
     * @todo
     * is this really needed? currently used to set hover styles. CSS can do this natively with `:hover`
     */
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;

    /**
     * handler when the mouse leaves the route card
     *
     * @todo
     * is this really needed? currently used to set hover styles. CSS can do this natively with `:hover`
     */
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const RouteCard = React.forwardRef<HTMLDivElement, RouteCardProps>(
    function RouteCard(
        {
            className,
            children,
            isRouteSelected,
            isRouteHidden,
            onMouseEnter,
            onMouseLeave,
            ...extra
        },
        ref
    ) {
        function getClassName() {
            const conditionalClasses = {
                routecard_selected: isRouteSelected,
                routecard_off: isRouteHidden
            };
            return classNames(
                'routecard _d-flex',
                conditionalClasses,
                className
            );
        }

        return (
            <div
                className={getClassName()}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={ref}
                data-testid={extra['data-testid'] || 'routecard'}
            >
                {children}
            </div>
        );
    }
);

export default RouteCard;
