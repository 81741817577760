import React from 'react';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';

interface RouteCardEquipmentIdProps {
    equipmentId: string;
    icon?: IconName;
    className?: string;
    iconColor?: string;
    'data-testid'?: string;
}

function RouteCardEquipmentId({
    equipmentId,
    icon,
    className,
    iconColor,
    ...extra
}: RouteCardEquipmentIdProps) {
    function getClassName() {
        const defaultClassName = 'routecard-equipmentId';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={getClassName()}
            icon={icon}
            iconColor={iconColor}
            iconSize="s"
            data-testid={extra['data-testid'] || 'routecard-equipmentId'}
        >
            {equipmentId}
        </RouteCardText>
    );
}

export default RouteCardEquipmentId;
