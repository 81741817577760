import React from 'react';
import i18n from '~/i18n';
import localizationUtils from '~/utils/localization-utils';
import { Detail, MarkerPopup, RouteCardTitle } from '~/ui';

const _getClusterStats = (leaves) => {
    const stats = {
        numStops: leaves.length,
        numUnits: 0,
        totalWeight: 0,
        numInventoryItems: 0
    };

    for (const leaf of leaves) {
        const planStop = leaf.properties;
        stats.numUnits += planStop.volumeCapacityUsed;
        stats.totalWeight += planStop.weightUsed;
        stats.numInventoryItems += planStop.numInventoryItems;
    }
    return stats;
};

/**
 * Creates a `MarkerPopup` component for cluster markers
 * @method makeClusterPopup
 * @memberof module:utils/map/popupMaker
 * @param {*} superCluster - the cluster data
 * @param {*} clusterId - the cluster ID
 * @param {*} coordinates - the location data
 * @param {*} routeLevelData - the route data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeClusterPopup = (
    superCluster,
    clusterId,
    coordinates,
    routeLevelData,
    emittedEventHandler
) => {
    const leaves = superCluster.getLeaves(clusterId, Infinity);
    const stats = _getClusterStats(leaves);
    const { routeName } = routeLevelData;
    const numInventoryItems = localizationUtils.formatNumInventory(
        stats.numInventoryItems
    );
    const unitsAndWeight = i18n.t('unitsAndWeight', {
        numUnits: stats.numUnits.toFixed(),
        totalWeight: stats.totalWeight.toFixed()
    });
    const numStops = i18n.t('stopWithCount', { count: stats.numStops });
    const extraClasses = '_text-3 _d-flex _ai-flex-end';
    const headerContent = <RouteCardTitle title={numStops} />;
    const bodyContent = (
        <>
            <Detail
                iconName="routeLine"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={routeName}
                className={extraClasses}
            />
            <Detail
                iconName="unitsOutline"
                iconSize="m"
                mainContent={numInventoryItems}
                className={extraClasses}
            />
            <Detail
                iconName="weightEmpty"
                iconSize="m"
                iconColor="galaxy-500"
                className={extraClasses}
                mainContent={unitsAndWeight}
            />
        </>
    );

    return (
        <MarkerPopup
            className="cluster-popup"
            headerContent={headerContent}
            bodyContent={bodyContent}
            bodyClassName="_fd-column"
            emittedEventHandler={emittedEventHandler}
            lat={coordinates.lat}
            lng={coordinates.lng}
            data-testid="cluster-popup"
        />
    );
};
