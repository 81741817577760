import { useCallback, useRef, useState } from 'react';

type ReturnProps = {
    ref: React.RefObject<HTMLDivElement>;
    isOpen: boolean;
    onToggle: () => void;
    onClose: () => void;
};
export const useDropDownMenuHandler = (): ReturnProps => {
    const ref = useRef() as React.RefObject<HTMLDivElement>;
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => {
        setIsOpen((state) => !state);
    }, []);
    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return {
        ref,
        isOpen,
        onToggle,
        onClose
    };
};
