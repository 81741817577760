import React from 'react';
import { useTranslation } from 'react-i18next';

export const NoZone = () => {
    const { t } = useTranslation('zoneManagement');

    return (
        <div
            className="zonemanagement-zonelist-nozone _w-100 _text-1"
            data-testid="zone-management-nozone"
        >
            {t('zoneList.empty')}
        </div>
    );
};
