import { createSlice } from '@reduxjs/toolkit';

/**
 * Redux slice for map side bar settings
 * @category Reducers
 * @module sideBarSlice
 */

export const mapSideBarSlice = createSlice({
    name: 'mapSideBar',
    initialState: {
        mapSideBarOpen: false
    },
    reducers: {
        /**
         * Toggle whether the map sidebar is open or not
         * @method toggleMapSideBarOpen
         * @param {Object} state - the current state
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * import { toggleMapSideBarOpen } from '~/reducers/mapSideBarSlice';
         * import { useDispatch } from 'react-redux';
         *
         * const dispatch = useDispatch();
         *
         * // After user action that should toggle the map side bar:
         * dispatch(toggleMapSideBarOpen());
         */
        toggleMapSideBarOpen: (state) => {
            const { mapSideBarOpen } = state;
            return { ...state, mapSideBarOpen: !mapSideBarOpen };
        }
    }
});

export const { toggleMapSideBarOpen } = mapSideBarSlice.actions;

export const selectMapSideBarState = (state) => state.mapSideBar;

export default mapSideBarSlice.reducer;
