import { ApiInventoryItem } from '~/api/types';
import InventoryItemsApi from '~/api/InventoryItemsApi';

export const getAllSignedInventoryItemsByRouteDate = async (
    routeDate: string
) => {
    let page = 0;
    let isAllDataFetched = false;
    let results: ApiInventoryItem[] = [];

    do {
        const {
            data: { data }
            // eslint-disable-next-line no-await-in-loop
        } = await InventoryItemsApi.get({
            page,
            limit: 1000,
            route_date: routeDate,
            signed: true
        });
        isAllDataFetched = !data.length;
        page += 1;
        results = [...results, ...data];
    } while (!isAllDataFetched);

    return results;
};
