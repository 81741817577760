import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AutosuggestItem } from '~/api/HereMaps/GeoApi/types';

import { GeoSearchApi } from '~/api/HereMaps/GeoApi';

interface HookUseDepotsProps {
    queryString?: string | undefined;
    enabled?: boolean;
    bounds?: number[];
}

const REACT_QUERY_KEY = 'autocomplete-places';

export const useAutosuggestPlaces = ({
    queryString,
    bounds,
    enabled = true
}: HookUseDepotsProps) => {
    const placeholderData = useMemo(() => {
        return [];
    }, []);

    const savedBounds = useMemo(() => {
        return bounds?.toString() || '';
    }, [bounds]);

    const { data: items } = useQuery({
        queryKey: [queryString, savedBounds, REACT_QUERY_KEY],
        queryFn: async () => {
            try {
                const { items: resultItems } =
                    await GeoSearchApi.autoSuggestPlaces({
                        queryString: queryString as string,
                        ...(bounds?.length && { bounds })
                    });
                return resultItems as AutosuggestItem[];
            } catch (error) {
                console.error(error);
                return [];
            }
        },
        cacheTime: 0,
        enabled,
        placeholderData
    });

    return {
        items: items || placeholderData
    };
};
