import React from 'react';
import { IconButton } from '~/ui';
import './AddItemButton.scss';

type Props = {
    'data-testid': string;
    text: string;
    onClick: () => void;
};
export const AddItemButton = ({ onClick, text, ...extraProps }: Props) => {
    return (
        <IconButton
            className="add-task-modal__add-item-button"
            text={text}
            onClick={onClick}
            icon="add"
            iconColor="ocean"
            iconSize="s"
            data-testid={extraProps['data-testid']}
        />
    );
};
