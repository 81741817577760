import { createContext, useContext } from 'react';
import { ApiTask } from '~/api/types';

export interface DuplicateTaskModalContextProps {
    apiTask: ApiTask;
    onClose: () => void;
}

export const DuplicateTaskModalContext =
    createContext<DuplicateTaskModalContextProps | undefined>(undefined);

export const useDuplicateTaskModalContext = () => {
    const context = useContext(DuplicateTaskModalContext);

    if (!context) {
        throw new Error(
            'useDuplicateTaskModalContext must be called within DuplicateTaskModalContext provider'
        );
    }

    return context;
};
