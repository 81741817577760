export enum UploadType {
    TASK_UPLOAD = 'task_upload'
}

export interface ApiUploadHistory {
    createdAt: string;
    countcreated: string;
    countscheduled: string;
    file: string;
    id: string;
    originalname: string;
}

export interface ApiUploadHistoryResponse {
    data: ApiUploadHistory[];
}

export interface ApiUploadFileUrlResponse {
    data: {
        link: string;
    };
}
