import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import adminAPI from '~/api/AdminApi';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import fileUtils from '~/utils/file-utils';
import constants from '~/utils/constants';

const dispatchProgressIndicator = ({
    dispatch,
    inProgress = false,
    isError: error = false,
    t
}) => {
    dispatch(
        addProcessIndicator({
            inProgress,
            error,
            position: 'center',
            message: t('driversAndVehiclesUpload.UploadDriverAndVehicle', {
                count: 1
            }),
            type: constants.processIndicator.DRIVER_UPLOAD
        })
    );
};

const uploadFiles = (clientId, files, dispatch, t) => {
    (async () => {
        try {
            const [file] = files;
            await adminAPI.uploadDriverFile(clientId, file);
            dispatchProgressIndicator({
                dispatch,
                t,
                inProgress: true
            });
        } catch (e) {
            console.error(e);
            dispatchProgressIndicator({
                dispatch,
                t,
                isError: true
            });
        }
    })();
};

export const handlerFactory = (clientId, dispatch, t) => () => {
    return {
        handleDragOver: (e) => e.preventDefault(),
        handleFilesSelected: (e) => {
            uploadFiles(clientId, e.target.files, dispatch, t);
            e.target.value = null;
        },
        handleDrop: (e) => {
            const {
                dataTransfer: { clearData, files, items }
            } = e;
            e.preventDefault();

            uploadFiles(
                clientId,
                items ? fileUtils.getFilesFromDataTransferItems(items) : files,
                dispatch,
                t
            );

            if (items) {
                items.clear();
            } else {
                clearData();
            }
        }
    };
};

export const useUploadDriverFile = () => {
    const dispatch = useDispatch();
    const mainClient = useSelector(selectMainClient);
    const { t } = useTranslation('driverManagement');
    const clientId = mainClient?.id;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const { handleDragOver, handleDrop, handleFilesSelected } = useMemo(
        handlerFactory(clientId, dispatch, t),
        [clientId, t, dispatch]
    );

    return {
        handleDragOver,
        handleDrop,
        handleFilesSelected
    };
};
