import React, { SyntheticEvent, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import {
    addHiddenRoute,
    removeHiddenRoute,
    selectHiddenRoutes
} from '~/reducers/hiddenRoutesSlice';
import { IconButton } from '~/ui';

interface UseRouteTripHideProps {
    clientRouteId: string;
    isParentHovered?: boolean;
    buttonClassName?: string;
}

interface UseRouteTripHideReturn {
    hideButton: JSX.Element;
    isHidden: boolean;
}

export const useHideRouteTrip = ({
    clientRouteId,
    isParentHovered = false,
    buttonClassName
}: UseRouteTripHideProps): UseRouteTripHideReturn => {
    const dispatch = useDispatch();

    const [isToggleHovered, setIsToggleHovered] = useState(false);
    const hiddenRoutes: Record<string, boolean> =
        useSelector(selectHiddenRoutes);

    const isHidden = useMemo(() => {
        return Boolean(hiddenRoutes[clientRouteId]);
    }, [clientRouteId, hiddenRoutes]);

    function toggleRouteVisibility(e: SyntheticEvent) {
        e.stopPropagation();
        if (isHidden) {
            dispatch(removeHiddenRoute(clientRouteId));
        } else {
            dispatch(addHiddenRoute(clientRouteId));
        }
    }

    const hideButtonClassName = classnames(
        'icon-button_clear routeitem__visibility',
        buttonClassName
    );

    const getButton = () => {
        return (
            <IconButton
                className={hideButtonClassName}
                visible={isHidden || isParentHovered}
                data-testid="trip-item-hide-button"
                icon={isHidden ? 'visibleOff' : 'visibleOn'}
                iconSize="s"
                iconColor={
                    isHidden || isToggleHovered ? 'galaxy-800' : 'galaxy-400'
                }
                onClick={toggleRouteVisibility}
                onMouseEnter={() => setIsToggleHovered(true)}
                onMouseLeave={() => setIsToggleHovered(false)}
            />
        );
    };

    return {
        isHidden,
        hideButton: getButton()
    };
};
