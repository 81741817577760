import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const selectedUserGroupIdSlice = createSlice({
    name: 'selectedUserGroupId',
    initialState: null,
    reducers: {
        setSelectedUserGroupId: (state, action) => {
            return action.payload;
        },
        resetSelectedUserGroupId: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setSelectedUserGroupId, resetSelectedUserGroupId } =
    selectedUserGroupIdSlice.actions;

export const selectUserGroupId = (state) => state.selectedUserGroupId;

export default selectedUserGroupIdSlice.reducer;
