import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LiveStop } from '~/data-classes';

import { ApiLiveStop } from '~/api/types';

import { shouldInsertBefore } from '~/hooks/useDragAndDropState/utils';
import { useResequenceDriverTasks } from '~/hooks/useResequenceDriverTasks';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import {
    selectDispatchedDrivers,
    selectLiveDriverById
} from '~/reducers/liveDriversSlice';
import { getFilteredDriver } from '~/hooks/useDriverTasks/utils';

export const useDriverTasks = () => {
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const liveStops = useMemo(() => {
        return serialSelectedLiveDriver?.schedule || [];
    }, [serialSelectedLiveDriver]);

    const { resequenceDriverTasks } = useResequenceDriverTasks();

    const dragDriverTasksToResequence = useCallback(
        (
            resequenceTarget: LiveStop,
            stopsToResequence: LiveStop[],
            stops: LiveStop[]
        ) => {
            const { driverStopNumber: targetDriverStopNumber } =
                resequenceTarget;

            const selectedStopsIds = stopsToResequence.map(
                (stop: LiveStop) => stop.id
            );

            const stopsToResequenceStopNumbers = stopsToResequence.map(
                (stop) => {
                    return stop.driverStopNumber;
                }
            );

            const toInsertBefore = shouldInsertBefore(
                targetDriverStopNumber,
                stopsToResequenceStopNumbers
            );

            const driver = getFilteredDriver(
                dispatchedDrivers,
                selectedStopsIds
            );
            resequenceDriverTasks({
                newDriverStopNumber: targetDriverStopNumber,
                stopsToResequence,
                stops,
                selectedDriverId: driver?.id,
                isTaskResequencingAllowed: Boolean(stopsToResequence.length),
                isInsertAfter: !toInsertBefore
            });
        },
        [dispatchedDrivers, resequenceDriverTasks]
    );

    const nonDepotLiveStops = useMemo(() => {
        return liveStops
            .map((stopData: ApiLiveStop) => {
                const stop = new LiveStop(stopData);
                const { isDepot } = stop;
                return !isDepot ? stop : null;
            })
            .filter(Boolean);
    }, [liveStops]);

    const liveStopsWithDepots = useMemo(() => {
        return liveStops.map((stopData: ApiLiveStop) => {
            return new LiveStop(stopData);
        });
    }, [liveStops]);

    return {
        dragDriverTasksToResequence,
        nonDepotLiveStops,
        liveStopsWithDepots
    };
};
