import React from 'react';
import {
    Button,
    Detail,
    MarkerPopup,
    RouteCardMetric,
    RouteCardTitle,
    CompartmentDetails,
    TextOverflowTooltip
} from '~/ui';
import { ViolationPill } from '~/ui/components/ViolationPill';
import classNames from 'classnames';
import i18n from '~/i18n';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { MapMarkerPopupInventoryItemsDetails } from '~/ui/components/MapMarkerPopupInventoryItemsDetails';

const getLabels = (stopLabels) => {
    if (stopLabels.length === 0) return;
    const maxLabelsDisplayed = 2;
    const displayedLabels =
        stopLabels.length > maxLabelsDisplayed
            ? stopLabels.slice(0, maxLabelsDisplayed)
            : stopLabels;
    return (
        <>
            {displayedLabels.map((label, i) => {
                const key = `label_${i}`;
                return (
                    <TextOverflowTooltip
                        placement="top"
                        content={label}
                        key={key}
                    >
                        <div
                            className="label marker-popup__label _to-ellipsis"
                            data-testid="label"
                        >
                            {label}
                        </div>
                    </TextOverflowTooltip>
                );
            })}
            {stopLabels.length > maxLabelsDisplayed && (
                <div className="label" data-testid="label">{`+${
                    stopLabels.length - maxLabelsDisplayed
                }`}</div>
            )}
        </>
    );
};

const getHeaderContent = ({
    testId,
    stopColor,
    stopText,
    stopName,
    stopIcon = 'clockFill',
    isPickup,
    isLate,
    isCompleted,
    recommendationScore
}) => {
    const getTaskDelayStatusClassNames = () => {
        const baseClass = 'task-delay-status-chip';
        const conditionalClasses = {
            'task-delay-status-chip--on-time': !isLate
        };
        return classNames(baseClass, conditionalClasses);
    };

    const deliveryType = isPickup
        ? i18n.t('stopsDrawer:taskType.pickup')
        : i18n.t('stopsDrawer:taskType.delivery');

    const taskDelayStatus = isLate
        ? i18n.t('delay_late')
        : i18n.t('delay_onTime');

    return (
        <>
            {isCompleted && (
                <div className="indicator-pills">
                    {taskDelayStatus && (
                        <ViolationPill
                            text={taskDelayStatus}
                            icon={isLate ? 'etaWarning' : undefined}
                            className={getTaskDelayStatusClassNames()}
                        />
                    )}
                    {deliveryType && (
                        <span
                            className="task-type-chip"
                            data-testid="task-status"
                        >
                            {deliveryType}
                        </span>
                    )}
                </div>
            )}
            <div className="marker-popup-header__chips _d-flex _ai-center">
                {stopColor && stopText && (
                    <RouteCardMetric
                        icon={stopIcon}
                        iconSize={IconSizes.M}
                        iconColor={stopColor}
                        value={stopText}
                        valueColor={stopColor}
                        data-testid={testId && `${testId}-status`}
                    />
                )}
                {Boolean(recommendationScore) && (
                    <span
                        className="machine-learning-score-chip"
                        data-testid={
                            testId && `${testId}-machine-learning-score-chip`
                        }
                    >
                        {i18n.t('common:machineLearningScoreAssigned')}
                    </span>
                )}
            </div>
            <RouteCardTitle
                title={stopName}
                data-testid={testId && `${testId}-title`}
            />
        </>
    );
};

const getBodyContent = ({
    testId,
    etaTime,
    labels,
    timeWindows,
    isMultiCompartmentClient,
    sizeByCompartment,
    serviceTime,
    isServiceTimeML,
    MLServiceTimeChipText,
    routeId,
    volumeCapacityUsed,
    stopDetailButtonText,
    onClickStopDetailButton = () => {},
    eta,
    isCompleted,
    inventory,
    isPickup,
    numInventoryItems,
    taskId,
    actualServiceTime
}) => {
    const renderCompartments = () => {
        return (sizeByCompartment || []).map((compartment) => {
            const { id, label, unit, size } = compartment;
            return (
                <CompartmentDetails
                    key={id}
                    label={label}
                    id={id}
                    size={size}
                    unit={unit}
                />
            );
        });
    };

    const getServiceTimeElement = () => (
        <span className="service-time">
            <span>
                {i18n.t('markerPopup:estimatedServiceTime', {
                    time: serviceTime
                })}
            </span>
        </span>
    );
    const renderTimeWindows = () => {
        return (timeWindows || []).map((timeWindow, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <div key={index}>{timeWindow}</div>;
        });
    };

    return (
        <>
            <Detail
                iconName="iconRouteFill"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={routeId}
                className="_d-flex _ai-flex-start"
                dataTestId={testId && `${testId}-routeId`}
            />
            <Detail
                iconName="stopwatch"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={
                    isCompleted
                        ? i18n.t('markerPopup:eta', {
                              eta,
                              etaTime
                          })
                        : etaTime
                }
                className="_d-flex _ai-flex-start"
                dataTestId={testId && `${testId}-eta`}
            />
            <Detail
                iconName="clock"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={renderTimeWindows()}
                className="_d-flex _ai-flex-start"
                dataTestId={testId && `${testId}-timeWindow`}
            />
            <div className="_d-flex _ai-center">
                <Detail
                    iconName="hourglass"
                    iconSize="m"
                    iconColor="galaxy-500"
                    mainContent={getServiceTimeElement()}
                    secondaryContent={
                        isCompleted
                            ? i18n.t('markerPopup:actualServiceTime', {
                                  time: actualServiceTime
                              })
                            : ''
                    }
                    className="_d-flex _ai-flex-start"
                    dataTestId={testId && `${testId}-serviceTime`}
                />
                {isServiceTimeML && (
                    <div
                        className="ml-service-time-chip"
                        data-testid={testId && `${testId}-ml-serviceTime-chip`}
                    >
                        {MLServiceTimeChipText}
                    </div>
                )}
            </div>

            {!isMultiCompartmentClient && (
                <Detail
                    iconName="volume"
                    iconSize="m"
                    iconColor="galaxy-500"
                    mainContent={i18n.t('markerPopup:volumeCapacityUsed', {
                        volume: volumeCapacityUsed
                    })}
                    className="_d-flex _ai-flex-start"
                    dataTestId={testId && `${testId}-volume`}
                />
            )}
            {isMultiCompartmentClient && (
                <Detail
                    iconName="volume"
                    iconSize="m"
                    iconColor="galaxy-500"
                    mainContent={renderCompartments()}
                    className="_d-flex"
                    dataTestId={testId && `${testId}-size-by-compartment`}
                />
            )}
            {labels.length > 0 && (
                <Detail
                    iconName="union"
                    iconSize="m"
                    iconColor="galaxy-500"
                    className="_d-flex _ai-flex-start"
                    dataTestId={testId && `${testId}-labels`}
                    mainContent={
                        <div
                            className="_d-flex _fw-wrap _p-relative"
                            style={{
                                rowGap: '0.8rem',
                                columnGap: '0.8rem',
                                cursor: 'default'
                            }}
                        >
                            {getLabels(labels)}
                        </div>
                    }
                />
            )}
            <MapMarkerPopupInventoryItemsDetails
                isPickup={isPickup}
                isCompleted={isCompleted}
                numberOfInventoryItems={numInventoryItems}
                taskId={taskId}
                inventoryItems={inventory}
            />
            <Button
                variant="secondary"
                className="_jc-center _ai-center"
                sx={{ display: 'flex' }}
                onClick={onClickStopDetailButton}
                data-testid={testId && `${testId}-button`}
            >
                <span>{stopDetailButtonText}</span>
            </Button>
        </>
    );
};

/**
 * Creates a `MarkerPopup` component
 * @method makeMarkerPopup
 * @private
 * @memberof module:utils/map/popupMaker
 * @param {*} payload - the stop data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeMarkerPopup = (payload, emittedEventHandler) => {
    const { className, location, stopData, testId } = payload;
    return (
        <MarkerPopup
            className={className}
            headerContent={getHeaderContent({ ...stopData, testId })}
            bodyContent={getBodyContent({ ...stopData, testId })}
            bodyClassName="_fd-column"
            emittedEventHandler={emittedEventHandler}
            lat={location.lat}
            lng={location.lng}
            data-testid={testId}
        />
    );
};
