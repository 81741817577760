import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import './Dropdown.scss';

export interface OptionType {
    value: string;
    label: string;
}

export interface DropdownProps {
    name: string;
    disabled?: boolean;
    value: SingleValue<OptionType>;
    onChange: (
        newValue: SingleValue<OptionType>,
        actionMeta: ActionMeta<OptionType>
    ) => void;
    options: OptionType[];
}

export default function Dropdown(props: DropdownProps) {
    const { name, disabled = false, value, options, onChange } = props;

    return (
        <Select
            name={name}
            inputId={name}
            value={value}
            options={options}
            onChange={onChange}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            isDisabled={disabled}
            className="react-select-container"
            classNamePrefix="react-select"
        />
    );
}
