const variants = {
    primary: {
        color: 'comet',
        bg: 'ocean',
        borderColor: 'ocean',
        '&:hover': {
            bg: 'ocean-500',
            borderColor: 'ocean-500'
        },
        '&:disabled': {
            color: 'galaxy-400',
            bg: 'galaxy-200',
            borderColor: 'galaxy-200'
        }
    },
    'primary-dismissive': {
        color: 'comet',
        bg: 'mars-500',
        borderColor: 'mars-500',
        '&:hover': {
            bg: 'mars-600',
            borderColor: 'mars-600'
        },
        '&:disabled': {
            color: 'galaxy-400',
            bg: 'galaxy-200',
            borderColor: 'galaxy-200'
        }
    },
    secondary: {
        color: 'meteor',
        bg: 'comet',
        borderColor: 'sky',
        '&:hover': {
            color: 'ocean-500',
            bg: 'neptune-100',
            borderColor: 'ocean-500'
        },
        '&:disabled': {
            color: 'galaxy-400',
            bg: 'comet',
            borderColor: 'galaxy-200'
        }
    },
    'secondary-dismissive': {
        color: 'mars-500',
        bg: 'comet',
        borderColor: 'mars-500',
        '&:hover': {
            color: 'mars-600',
            bg: 'mars-100',
            borderColor: 'mars-600'
        },
        '&:disabled': {
            color: 'galaxy-400',
            bg: 'comet',
            borderColor: 'galaxy-200'
        }
    },
    ghost: {
        color: 'meteor',
        bg: 'inherit',
        borderColor: 'transparent',
        '&:hover': {
            color: 'ocean-500',
            bg: 'neptune-100',
            borderColor: 'neptune-100'
        },
        '&:disabled': {
            color: 'galaxy-400'
        }
    },
    label: {
        color: 'meteor',
        bg: 'transparent',
        borderColor: 'transparent',
        outline: 'transparent',
        '&:hover': {
            color: 'neptune-500',
            bg: 'transparent',
            borderColor: 'transparent'
        },
        '&:disabled': {
            color: 'galaxy-400'
        }
    }
};

export default {
    variants
};
