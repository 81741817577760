import React, { useMemo } from 'react';

import { ReassignModalContent } from './ReassignModalContent';
import { ReassignModalHeader } from './ReassignModalHeader';

import './reassign-modal.scss';

export const ReassignModal = ({
    onClose,
    routes,
    selectedStops,
    stopIdPropertyName
}) => {
    const numberOfSelectedStops = selectedStops.length;
    const plannedRoutes = useMemo(
        () => routes.filter(({ isPlanned }) => isPlanned),
        [routes]
    );

    return (
        <div className="reassignmodal">
            <ReassignModalHeader
                numberOfSelectedStops={numberOfSelectedStops}
                onClose={onClose}
            />
            <ReassignModalContent
                routes={plannedRoutes}
                selectedStops={selectedStops}
                stopIdPropertyName={stopIdPropertyName}
            />
        </div>
    );
};
