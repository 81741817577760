import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlMenu,
    StopModalMenuButton,
    TooltipButton
} from '~/ui';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import './manage-stop-control-multiple.scss';

const defaultRootClassName = 'manage-stop-control-multiple';

function ManageStopControlMultiple({
    stopList,
    children,
    className,
    controlTitle,
    controlHelp,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {},
    isTwoPartTask,
    ...extra
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    function _getClassName() {
        return classNames(defaultRootClassName, '_fd-column', className);
    }

    const handleClickViewMore = useCallback(() => {
        const { taskId } = stopList[0].props.selectedStop;
        // strip the `pickup` or `delivery` prefix in cases of a two-part task being selected
        const rootTaskId = taskId.replace(/(pickup|delivery)_/, '');

        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(rootTaskId));
    }, [stopList, dispatch]);

    if (!stopList) return null;

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol-multiple'}
        >
            <div className={`${defaultRootClassName}__main`}>
                {controlTitle && (
                    <span
                        className="_text-2"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-title`
                                : 'managestopcontrol-multiple-title'
                        }
                    >
                        {controlTitle}
                    </span>
                )}
                {controlHelp && (
                    <div className="_d-flex _ai-center">
                        <span
                            className="_text-3-alt"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-help`
                                    : 'managestopcontrol-multiple-help'
                            }
                        >
                            {controlHelp}
                        </span>
                    </div>
                )}
                <div
                    className={`${defaultRootClassName}__stop-list-container _fd-column`}
                >
                    <div
                        className={`${defaultRootClassName}__stop-list`}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-list`
                                : 'managestopcontrol-multiple-list'
                        }
                    >
                        {stopList}
                    </div>
                </div>
                {isTwoPartTask && (
                    <TooltipButton
                        className="managestopcontrol_view-more"
                        variant={TooltipButton.variants.LINK}
                        onClick={handleClickViewMore}
                        data-testid="managestopcontrol-multiple-viewmore"
                    >
                        {t('Collapsible.view_more')}
                    </TooltipButton>
                )}
            </div>
            {children}
            {footerButtonTitle && (
                <ManageStopControlMenu
                    className="managestopcontrol_footer"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-footer`
                            : 'managestopcontrol-multiple-footer'
                    }
                >
                    <StopModalMenuButton
                        buttonIcon={footerButtonIcon}
                        buttonText={footerButtonTitle}
                        onClick={onClickFooterButton}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-footer_button`
                                : 'managestopcontrol-multiple-footer_button'
                        }
                    />
                </ManageStopControlMenu>
            )}
        </div>
    );
}

export { ManageStopControlMultiple };
