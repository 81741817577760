import React from 'react';
import { Icon } from '~/ui';

import './MapZoomControl.scss';

interface MapZoomControlInterface {
    zoomIn: () => void;
    zoomOut: () => void;
}

export const MapZoomControl = ({
    zoomIn,
    zoomOut
}: MapZoomControlInterface): JSX.Element => {
    return (
        <div data-testid="zoom-controls" className="zoom-column">
            <div className="zoom-column-btn-wrapper">
                <button
                    type="button"
                    className="zoom-column-in button_clear"
                    onClick={zoomIn}
                    data-testid="zoom-in-btn"
                >
                    <Icon icon="addLight" size="m" color="galaxy-800" />
                </button>
            </div>
            <div className="zoom-column-divider" />
            <div className="zoom-column-btn-wrapper">
                <button
                    type="button"
                    className="zoom-column-out button_clear"
                    onClick={zoomOut}
                    data-testid="zoom-out-btn"
                >
                    <Icon icon="minusLight" size="m" color="galaxy-800" />
                </button>
            </div>
        </div>
    );
};
