import React, { useMemo } from 'react';
import { getVisibleLabels } from './utils';
import './StopMarkerLabelsTooltip.scss';

interface StopMarkerLabelsTooltipProps {
    labels: string[];
    setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    popperTooltipProps: unknown;
}

export const StopMarkerLabelsTooltip = ({
    labels,
    setTooltipRef,
    popperTooltipProps
}: StopMarkerLabelsTooltipProps) => {
    const rootClassName = 'stop-marker-labels-tooltip';
    const visibleLabels = useMemo(() => getVisibleLabels(labels), [labels]);

    return (
        <div
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...(popperTooltipProps as React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLDivElement>,
                HTMLDivElement
            >)}
            ref={setTooltipRef}
            className={`${rootClassName} _fd-column _jc-center _ai-flex-start`}
        >
            {visibleLabels.map((label) => (
                <span key={label} className={`${rootClassName}__label`}>
                    {label}
                </span>
            ))}
        </div>
    );
};
