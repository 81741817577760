import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectShowZonePolygons,
    setShowZonePolygons
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

import MapSettingMenuItem from '../../MapSettingMenuItem';

type Props = {
    mapRouteMode: ConfigurableMapRouteMode;
};
export const ShowZonePolygonsSetting = ({ mapRouteMode }: Props) => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const isShowPolygons = useSelector(selectShowZonePolygons(mapRouteMode));
    const onChangeState = (value: boolean) => {
        dispatch(setShowZonePolygons({ value, mode: mapRouteMode }));
    };

    return (
        <MapSettingMenuItem
            iconName="routeMarker"
            text={t('zonePolygons')}
            checkedState={isShowPolygons}
            onChangeState={onChangeState}
        />
    );
};
