import React from 'react';
import classNames from 'classnames';

import { CompartmentStat } from '~/api/types';
import { CompartmentUsageDetails } from '~/ui';

import './CompartmentUsageContainer.scss';

type Props = {
    isMultiCompartment?: boolean;
    compartmentStats?: CompartmentStat[] | null;
    className?: string;
    isIndicateOverCapacity?: boolean;
};

const CompartmentUsageContainer = ({
    className,
    isMultiCompartment = false,
    compartmentStats,
    isIndicateOverCapacity
}: Props): JSX.Element | null => {
    const blockClassName = 'compartment-usage-container';
    const classes = classNames(blockClassName, className);

    if (!isMultiCompartment || !compartmentStats?.length) {
        return null;
    }

    return (
        <div className={classes} data-testid="compartment-usage-container">
            {compartmentStats.map((compartment: CompartmentStat) => {
                const { id } = compartment;
                return (
                    <CompartmentUsageDetails
                        key={`compartment-${id}`}
                        compartment={compartment}
                        isIndicateOverCapacity={isIndicateOverCapacity}
                    />
                );
            })}
        </div>
    );
};

export default CompartmentUsageContainer;
