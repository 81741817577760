import { omit } from 'lodash';
import { ApiTask, TimeWindow } from '~/api/types';
import { LiveStop } from '~/data-classes';
import { getTimeWindowFromTime } from './getTimeWindowFromTime';
import { replacePickupWithDelivery } from './replacePickupWithDelivery';

export const omitPropertiesFromLiveStop = [
    'id',
    'driverProfile',
    'driver',
    'driverStopNumber',
    'index',
    'stopNumber',
    'stopnumber',
    'task',
    'timeWindow'
];

export const getStopDataFromLiveStop = (
    stopData: LiveStop
): {
    filteredValues: LiveStop | ApiTask;
    timeWindow: TimeWindow[];
} => {
    const values = Object.values(stopData);
    if (values.length === 0 || !values[0]) {
        return {
            filteredValues: {} as LiveStop | ApiTask,
            timeWindow: []
        };
    }

    const pickedValues = omit(values[0], omitPropertiesFromLiveStop);
    if (Object.keys(pickedValues).length === 0) {
        return {
            filteredValues: {} as LiveStop | ApiTask,
            timeWindow: []
        };
    }

    const filteredValues = replacePickupWithDelivery(pickedValues);
    const timeWindow = getTimeWindowFromTime(values[0] as LiveStop);

    return {
        filteredValues: filteredValues as unknown as LiveStop | ApiTask,
        timeWindow
    };
};
