import { useQuery } from 'react-query';
import { useMemo } from 'react';
import UploadHistoryApi from '~/api/UploadHistoryApi';
import { ApiUploadHistory, UploadType } from '~/api/types';

const emptyList: ApiUploadHistory[] = [];

export interface UseUploadHistoryProps {
    limit?: number;
    page?: number;
    type?: UploadType;
}

export const useUploadHistory = ({
    limit,
    page,
    type
}: UseUploadHistoryProps) => {
    const queryKey = 'upload-history';
    const fetchHistory = async (): Promise<ApiUploadHistory[]> => {
        const {
            data: { data }
        } = await UploadHistoryApi.getUploads({
            limit,
            page,
            type
        });

        return data;
    };

    const { isFetching, isError, data } = useQuery(
        [queryKey, limit, page, type],
        () => fetchHistory()
    );

    return useMemo(() => {
        const uploads = data ?? emptyList;

        return {
            isError,
            isFetching,
            uploads
        };
    }, [data, isError, isFetching]);
};
