import { UseQueryOptions, useQuery } from 'react-query';
import EquipmentApi from '~/api/EquipmentApi';
import { ApiEquipment } from '~/api/types';

type UseGetEquipmentListReactQueryOptions = Omit<
    UseQueryOptions<ApiEquipment, Error>,
    'queryKey' | 'queryFn'
>;

interface UseGetEquipmentListParams {
    /**
     * The equipment ID
     */
    equipmentId: string;

    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetEquipmentListReactQueryOptions;
}

const REACT_QUERY_KEY = 'equipmentId';

export const useGetEquipmentById = ({
    equipmentId,
    reactQueryOptions = {}
}: UseGetEquipmentListParams) => {
    const fetchEquipmentById = async (id: string): Promise<ApiEquipment> => {
        const { data } = await EquipmentApi.getEquipmentById(id);
        return data;
    };

    // return the full useQuery Object
    return useQuery<ApiEquipment, Error>(
        [REACT_QUERY_KEY, equipmentId],
        () => fetchEquipmentById(equipmentId),
        reactQueryOptions
    );
};
