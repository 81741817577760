/* eslint-disable camelcase */
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';
import getEntry from './getEntry';
import getErrors from './getErrors';

const transformVariableCostsSheet = (sheetToJson, zonesData) => {
    const variableEntries = sheetToJson.reduce(
        (entries, rowData) => {
            const errorObj = getErrors(
                terms.sheetName.VARIABLECOSTS,
                rowData,
                zonesData
            );

            if (errorObj.hasErrors) {
                entries.variableErrors[errorObj.key] = errorObj;

                if (!entries.hasErrors) entries.hasErrors = true;
            } else {
                const entryObj = getEntry(
                    terms.sheetName.VARIABLECOSTS,
                    rowData,
                    zonesData
                );
                const existingEntry = entries.variableEntries[entryObj.key];

                if (!existingEntry) {
                    entries.variableEntries[entryObj.key] = entryObj;
                } else {
                    entries.variableEntries[entryObj.key] = {
                        ...existingEntry,
                        variableTerm: {
                            ...existingEntry.variableTerm,
                            ...entryObj.variableTerm
                        }
                    };
                }
            }
            return entries;
        },
        {
            variableEntries: {},
            variableErrors: {},
            hasErrors: false
        }
    );

    return variableEntries;
};

export default transformVariableCostsSheet;
