/**
 * Available downloadable report icons
 */
export enum DownloadableReportIcons {
    /**
     * Auto Arrive report icon
     */
    AUTO_ARRIVE = 'machineLearningOptimize',

    /**
     * Service Time report icon
     */
    TIME_WINDOW_STAT = 'clock',

    /**
     * Driver Raw report icon
     */
    DRIVER_RAW = 'userLine',

    /**
     * Fleet report icon
     */
    FLEET = 'vehicleEmpty',

    /**
     * Route Plan report icon
     */
    ROUTE_PLAN = 'routeLine',

    /**
     * Live Drivers report icon
     */
    LIVE_DRIVER = 'routeLine',

    /**
     * Service Time report icon
     */
    SERVICE_TIME = 'setting',

    /**
     * Manual Sequence report icon
     */
    MANUAL_SEQUENCE = 'setting',

    /**
     * Time Window report icon
     */
    TIME_WINDOW = 'setting',

    /**
     * Depot Departure report icon
     */
    DEPOT_DEPARTURE = 'setting',

    /**
     * Route Compare report icon
     */
    ROUTE_COMPARE = 'setting',

    /**
     * Edit Impact report icon
     */
    EDIT_IMPACT = 'setting',

    /**
     * Signature Zip report icon
     */
    SIGNATURE_ZIP = 'setting',

    /**
     * Inventory Management report icon
     */
    INVENTORY_MANAGEMENT = 'setting',

    /**
     * Proof of Delivery report icon
     */
    PROOF_OF_DELIVERY = 'setting',

    /**
     * Proof of Delivery report (CSV format) icon
     */
    PROOF_OF_DELIVERY_CSV = 'setting',

    /**
     * Adherence report icon
     */
    ADHERENCE = 'setting',

    /**
     * Instant Routes report icon
     */
    INSTANT_ROUTES_REPORT = 'setting',

    /**
     * Generic unknown report icon
     */
    UNKNOWN = 'question'
}
