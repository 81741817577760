import React from 'react';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import localizationUtils from '~/utils/localization-utils';

interface RouteCardAddressProps {
    address: string;
    icon?: IconName;
    className?: string;
    iconColor?: string;
    'data-testid'?: string;
}

function RouteCardAddress({
    address,
    icon,
    className,
    iconColor,
    ...extra
}: RouteCardAddressProps) {
    function getClassName() {
        const defaultClassName = 'routecard-address';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={getClassName()}
            icon={icon}
            iconColor={iconColor}
            iconSize="s"
            data-testid={extra['data-testid'] || 'routecard-address'}
        >
            {localizationUtils.formatAddress(address)}
        </RouteCardText>
    );
}

export default RouteCardAddress;
