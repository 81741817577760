type Callback = (payload: unknown) => unknown;

class EventBus {
    events: Record<string, Set<Callback>>;

    constructor() {
        this.events = {};
    }

    subscribe(event: string, callback: Callback) {
        if (!this.events[event]) {
            this.events[event] = new Set();
        }
        this.events[event].add(callback);
    }

    unsubscribe(event: string, callback: Callback) {
        if (!this.events[event]) return;
        this.events[event].delete(callback);
    }

    publish(event: string, data?: unknown) {
        if (!this.events[event]) return;
        this.events[event].forEach((callback: Callback) => {
            try {
                callback(data);
            } catch (e) {
                console.error(e);
            }
        });
    }
}

const eventBus = new EventBus();

export default eventBus;
