function filterCepResults(payload) {
    const { clientId, data } = payload;
    const { body, channel, stage } = data;
    const {
        error,
        progress,
        routeDate,
        schedulerTaskId,
        preschedulerSolutionInfo: solutionInfo, // getting deprecated
        preschedulerSolutionInfoV2: newSolutionInfo
    } = body;

    return {
        clientId,
        channel,
        error,
        progress,
        routeDate,
        schedulerTaskId,
        solutionInfo,
        newSolutionInfo,
        stage
    };
}

export default {
    filterCepResults
};
