import React from 'react';
import { FlexLayout } from '~/ui';
import { Box } from '..';
import './base-action-bar.scss';

function BaseActionBar({ children }) {
    return (
        <FlexLayout
            className="baseactionbar _p-relative"
            justifyContent="space-between"
        >
            {children}
        </FlexLayout>
    );
}

function BaseActionBarButtonGroup({ children, sx }) {
    return (
        <Box className="baseactionbar_buttongroup _d-flex _ai-center" sx={sx}>
            {children}
        </Box>
    );
}

export { BaseActionBar, BaseActionBarButtonGroup };
