import { isEmpty } from 'lodash';
import { markerMaker } from '../map';
import { ApiEquipment } from '~/api/types';

const makeEquipmentMarkerComponents = (
    equipment: Record<ApiEquipment['id'], ApiEquipment>
) => {
    const hasEquipment = !isEmpty(equipment);
    if (!hasEquipment) return [];
    return Object.values(equipment)
        .filter((equipmentItem) => {
            const { lat, lng } = equipmentItem;
            return lat && lng;
        })
        .map((equipmentItem, index) => {
            return markerMaker.makeEquipmentMarker({
                equipment: equipmentItem,
                index
            });
        });
};

export default makeEquipmentMarkerComponents;
