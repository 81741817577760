import React from 'react';
import { useTranslation } from 'react-i18next';
import { MapViewSelector } from './MapViewSelector';
import { useMapViewControl } from './useMapViewControl';

import './MapViewControl.scss';

export const MapViewControl = () => {
    const { t } = useTranslation('common');
    const {
        handleSelectRoutesView,
        handleSelectStopsView,
        isRoutesViewSelected,
        isShowMapViewControl,
        isStopsViewSelected
    } = useMapViewControl();
    const rootClassName = 'map-view-control';

    if (!isShowMapViewControl) return null;

    return (
        <div
            className={`${rootClassName} _p-absolute _d-flex`}
            data-testid={rootClassName}
        >
            <MapViewSelector
                isSelected={isRoutesViewSelected}
                icon="routeViewSelector"
                onClick={handleSelectRoutesView}
                text={t('route')}
            />
            <div className={`${rootClassName}__divider`} />
            <MapViewSelector
                isSelected={isStopsViewSelected}
                icon="stopViewSelector"
                onClick={handleSelectStopsView}
                text={t('stop')}
            />
        </div>
    );
};
