import { ApiTask, TaskPriority, TaskStatusCondition } from '~/api/types';
import { TaskTableRowData } from './TaskTableRowData';

/**
 * TaskTableData data class
 *
 * @category Data Classes
 *
 * @example
 * import { TaskTableData } from '~/data-classes/report';
 *
 * const srcData = [];
 * const reports = new TaskTableData(srcData);
 *
 */
export class TaskTableData {
    /**
     * The API source data
     * @type {ApiTask[]}
     */
    private readonly initApiTasks: ApiTask[];

    private readonly rawTasks: Record<string, TaskTableRowData>;

    private filteredTasks: TaskTableRowData[];

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(initApiTasks: ApiTask[]) {
        this.initApiTasks = initApiTasks;

        this.rawTasks = this.initApiTasks.reduce((rawTasks, apiTask) => {
            const taskItem = new TaskTableRowData(apiTask);
            rawTasks[taskItem.id] = taskItem;
            return rawTasks;
        }, {} as Record<string, TaskTableRowData>);

        this.filteredTasks = Object.values(this.rawTasks);
    }

    get tasks(): TaskTableRowData[] {
        return this.filteredTasks;
    }

    filterTasksByStatus(status = [] as TaskStatusCondition[]): this {
        this.filteredTasks = Object.values(this.tasks).filter((rawTask) => {
            return (
                !status.length || status.includes(rawTask.taskStatusCondition)
            );
        });
        return this;
    }

    filterTasksByPriority(priorities = [] as TaskPriority[]): this {
        this.filteredTasks = Object.values(this.tasks).filter((rawTask) => {
            return (
                !priorities.length || priorities.includes(rawTask.taskPriority)
            );
        });
        return this;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiTask[]}
     */
    toJSON(): ApiTask[] {
        return this.initApiTasks;
    }
}
