import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetOnLogout } from '~/reducers/common-actions';

export const pendingJobsSlice = createSlice({
    name: 'pendingJobs',
    initialState: null,
    reducers: {
        setPendingJobs: (state, action) => {
            return action.payload;
        },
        addPendingJob: (state, action) => {
            const newState = state ? _.clone(state) : {};
            newState[action.payload] = true;
            return newState;
        },
        addPendingJobs: (state, action) => {
            if (!state) {
                state = {};
            }
            for (const jobId of action.payload) {
                state[jobId] = true;
            }
            return state;
        },
        removePendingJobs: (state, action) => {
            if (!state) {
                state = {};
            }
            for (const jobId of action.payload) {
                delete state[jobId];
            }
            return state;
        },
        removePendingJob: (state, action) => {
            const updatedState = _.clone(state);
            delete updatedState[action.payload];
            return updatedState;
        },
        resetPendingJobs: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const {
    setPendingJobs,
    addPendingJob,
    removePendingJob,
    addPendingJobs,
    removePendingJobs,
    resetPendingJobs
} = pendingJobsSlice.actions;
export const selectPendingJobs = (state) => state.pendingJobs;
export default pendingJobsSlice.reducer;
