import PT from 'prop-types';
import React from 'react';
import { Icon, Text } from '~/ui';
import constants from '~/utils/constants';

import './action-bar-item.scss';

const textWrapperStyles = {
    default: 'actionbaritem_type-default',
    noIcon: 'actionbaritem_type-noicon'
};

function ActionBarItem({
    icon,
    details,
    onClick,
    status = '',
    type = 'default'
}) {
    let hasViolations;
    let statusColor;

    switch (status) {
        case constants.violations.WARNING:
            hasViolations = true;
            statusColor = 'venus';
            break;
        case constants.violations.DANGER:
            hasViolations = true;
            statusColor = 'mars-500';
            break;
        default:
            hasViolations = false;
            statusColor = '';
            break;
    }

    const textWrapperStyle = textWrapperStyles[type];

    return (
        <button
            className="actionbaritem _d-flex _ai-center"
            type="button"
            onClick={onClick}
        >
            {icon && (
                <div className="actionbaritem_icon _d-flex _jc-center _ai-center">
                    <Icon icon={icon} color="neptune" size="m" />
                </div>
            )}
            <div
                className={`actionbaritem_text _d-flex _ai-center ${textWrapperStyle}`}
            >
                <Text variant="16-normal" data-testid="action-bar-item-text">
                    {details}
                </Text>
                {hasViolations && (
                    <div className="_d-flex">
                        <Icon
                            icon="circle"
                            color={statusColor}
                            size="xs"
                            sx={{ position: 'absolute' }}
                        />
                    </div>
                )}
            </div>
        </button>
    );
}
ActionBarItem.propTypes = {
    icon: PT.string,
    details: PT.string,
    onClick: PT.func
};

export default ActionBarItem;
