export const getFormattedLabels = (labels: string[]) => {
    const maxLabelLength = 12;
    const maxDisplayedLabels = 2;
    const formattedLabels = labels.slice(0, maxDisplayedLabels).map((label) => {
        if (label.length <= maxLabelLength) return label;

        return `${label.substring(0, maxLabelLength)}...`;
    });

    if (labels.length <= maxDisplayedLabels) return formattedLabels;

    const [firstLabel, secondLabel] = formattedLabels;

    return [firstLabel, secondLabel, `+${labels.length - maxDisplayedLabels}`];
};
