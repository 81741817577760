/* eslint-disable camelcase */
import {
    customCostMatrixUtils,
    customCostMatrixConstants as terms
} from '~/utils/custom-cost-matrix-utils';
import { idUtils } from '~/utils/id-utils';

const getAttributeValue = (rawValue, firstOfType, lastOfType) => {
    const values = String(rawValue).split(',').map(Number);

    if (firstOfType) {
        return { firstOfType: values };
    }
    if (lastOfType) {
        return { lastOfType: values };
    }
    return { values };
};

const getConstantTermEntry = (rowData) => {
    const { value } = rowData;
    return {
        constantTerm: {
            value
        }
    };
};

const getAttributeTermEntry = (rowData) => {
    const {
        custom_cost_term,
        threshold_attribute,
        threshold_values,
        weight_attribute,
        weight_values,
        calculation_type,
        firstOfType,
        lastOfType,
        __rowNum__: rowNum
    } = rowData;

    const row = rowNum + 1;
    const isProgressive = customCostMatrixUtils.isProgressive(calculation_type);
    const thresholds = {
        attribute: threshold_attribute,
        ...getAttributeValue(threshold_values, firstOfType, lastOfType)
    };
    const weights = {
        attribute: weight_attribute,
        ...getAttributeValue(weight_values, firstOfType, lastOfType)
    };
    const attributeTermKey = idUtils.getCombinedId(
        custom_cost_term,
        threshold_attribute,
        weight_attribute,
        row
    );
    const attributeTermObj = {
        key: attributeTermKey,
        custom_cost_term,
        threshold_attribute,
        weight_attribute,
        thresholds,
        weights,
        isProgressive
    };

    return { variableTerm: { [attributeTermKey]: attributeTermObj } };
};

const getEntry = (sheetName, rowData, zonesData) => {
    const { start_zone_name, end_zone_name } = rowData;
    const start_zone_id = zonesData[start_zone_name];
    const end_zone_id = zonesData[end_zone_name];
    const key = idUtils.getCombinedId(start_zone_id, end_zone_id);

    const commonObj = {
        sheetName,
        key,
        start_zone_id,
        end_zone_id
    };

    switch (sheetName) {
        case terms.sheetName.ZONETOZONECOSTS:
            return {
                ...commonObj,
                ...getConstantTermEntry(rowData)
            };
        case terms.sheetName.VARIABLECOSTS:
            return {
                ...commonObj,
                ...getAttributeTermEntry(rowData)
            };
        default:
            return commonObj;
    }
};

export default getEntry;
