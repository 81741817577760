import { MapSettingsState, SetTabSpecificSettingAction } from './types';
import { stateObjectKeyMap } from './constants';
import { applyToPerTabSettings } from './utils';

const setIsZonePolygonsEditMode = (
    state: MapSettingsState,
    action: SetTabSpecificSettingAction
): MapSettingsState => {
    const {
        payload: { mode, value: isZonePolygonsEditMode }
    } = action;
    const {
        isZonePolygonsEditMode: isZonePolygonsEditModeKey,
        showZonePolygons: showZonePolygonsKey
    } = stateObjectKeyMap;
    const isShowZonePolygons = state[mode]?.[showZonePolygonsKey];
    const newValue = isShowZonePolygons && isZonePolygonsEditMode;

    return applyToPerTabSettings(
        mode,
        isZonePolygonsEditModeKey,
        state,
        newValue
    );
};

export default {
    setIsZonePolygonsEditMode
};
