import axios from 'axios';
import { ApiTaskService, AxiosApiResponse, IApiResponse } from './types';

/**
 * Implementations of API methods under the /task_services path
 *
 * @category API
 */
export class TaskServicesApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/task_services';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'task_services';

    /**
     * Sends a GET /task_services request with the given data for the given client.
     */
    static get(): Promise<AxiosApiResponse<ApiTaskService[]>> {
        return axios.get<IApiResponse<ApiTaskService[]>>(this.PATH);
    }

    /**
     * Sends a POST /task_services request with the given data for the given client.
     *
     * @param {string} clientId the client id
     * @param {Partial<ApiTaskService>} payload the api payload
     */
    static post(
        clientId: string,
        payload: Partial<ApiTaskService>
    ): Promise<AxiosApiResponse> {
        const taskServicePayload = {
            task_services: { ...payload, client_id: clientId }
        };
        return axios.post<IApiResponse>(this.PATH, taskServicePayload);
    }
}
