import config from '~/auth/aws-cognito-config';

export const getAwsIncognitoConfig = () => {
    return config[window.location.hostname] || config.default;
};

export const gotoAwsIncognitoLogoutPage = () => {
    const cognitoConfig = getAwsIncognitoConfig();
    const path = `https://${cognitoConfig.domain}.amazoncognito.com/logout?`;
    const params = new URLSearchParams({
        logout_uri: window.location.origin,
        client_id: cognitoConfig.clientId
    });

    window.location.href = [path, params.toString()].join('');
};
