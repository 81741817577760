import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { resetOnLogout } from '~/reducers/common-actions';

export const defaultRoutesDrawerFilter = {
    filterMetric: [],
    filterResultsCount: 0,
    filterResultsTotal: 0,
    isFiltered: false,
    isFilterOpen: false
};

export const routesDrawerFilterSlice = createSlice({
    name: 'routesDrawerFilter',
    initialState: defaultRoutesDrawerFilter,
    reducers: {
        setIsFilterOpen: (state, action) => {
            return { ...state, isFilterOpen: action.payload };
        },
        setFilterMetric: (state, action) => {
            return {
                ...state,
                filterMetric: action.payload,
                isFiltered: Boolean(action.payload.length)
            };
        },
        setFilterResultsCount: (state, action) => {
            const isInteger = _.isInteger(action.payload);
            if (!isInteger) {
                return state;
            }
            return { ...state, filterResultsCount: action.payload };
        },
        setFilterResultsTotal: (state, action) => {
            const isInteger = _.isInteger(action.payload);
            if (!isInteger) {
                return state;
            }
            return { ...state, filterResultsTotal: action.payload };
        },
        resetRoutesDrawerFilter: () => {
            return defaultRoutesDrawerFilter;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultRoutesDrawerFilter;
        });
    }
});

export const {
    setFilterMetric,
    setFilterResultsCount,
    setFilterResultsTotal,
    setIsFilterOpen,
    resetRoutesDrawerFilter
} = routesDrawerFilterSlice.actions;

export const selectFilterMetric = (state) =>
    state.routesDrawerFilter.filterMetric;

export const selectFilterResultsCount = (state) =>
    state.routesDrawerFilter.filterResultsCount;

export const selectFilterResultsTotal = (state) =>
    state.routesDrawerFilter.filterResultsTotal;

export const selectIsFiltered = (state) => state.routesDrawerFilter.isFiltered;

export const selectIsFilterOpen = (state) =>
    state.routesDrawerFilter.isFilterOpen;

export const selectRoutesDrawerFilter = (state) => state.routesDrawerFilter;

export default routesDrawerFilterSlice.reducer;
