import { useMemo } from 'react';
import { Coordinates } from '~/api/types';
import { LiveStop } from '~/data-classes';
import { useRoutePathGeometry } from '~/hooks';
import { useMapEngineContext } from '~/components/MapEngineProvider';

interface UseDriverScheduleRouteLineProps {
    driverSchedule: LiveStop[];
    driverLocation: Coordinates;
    driverCurrentStopIndex: number;
}
export const useDriverScheduleRouteLine = ({
    driverSchedule,
    driverLocation,
    driverCurrentStopIndex
}: UseDriverScheduleRouteLineProps) => {
    const { mapToolKit } = useMapEngineContext();

    const projectionPath = useMemo(() => {
        const futurePath = driverSchedule
            .slice(driverCurrentStopIndex, driverSchedule.length)
            .filter((stop) => !stop.isDepot)
            .map((stop) => stop.location);
        futurePath.unshift(driverLocation);

        return futurePath;
    }, [driverSchedule, driverLocation, driverCurrentStopIndex]);

    const coordinatesString = useMemo(() => {
        return projectionPath
            .map(({ lat, lng }) => `${lat},${lng}`)
            .join('%3b');
    }, [projectionPath]);

    const { routePathData: encodedPath, isFetching } =
        useRoutePathGeometry(coordinatesString);

    const snapToRoadPath = useMemo(() => {
        if (!encodedPath || !mapToolKit) return;
        return mapToolKit.decodePolylinePath(encodedPath);
    }, [encodedPath, mapToolKit]);

    return useMemo(() => {
        return {
            driverSchedulePath: isFetching
                ? []
                : snapToRoadPath || projectionPath
        };
    }, [isFetching, snapToRoadPath, projectionPath]);
};
