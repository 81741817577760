import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

const initialFilters = {};

interface SelectedTaskFiltersState {
    /**
     * The list of selected status
     */
    status?: number[];
    /**
     * The list of selected priorities
     */
    priorities?: number[];
}

export const selectedTaskFiltersSlice = createSlice({
    name: 'selectedTaskFilters',
    initialState: {} as SelectedTaskFiltersState,
    reducers: {
        setSelectedTaskFilters: (state, { payload }) => {
            return {
                ...state,
                ...payload
            };
        },
        resetSelectedTaskFilters: (): SelectedTaskFiltersState => {
            return initialFilters;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return initialFilters;
        });
    }
});

export const { setSelectedTaskFilters, resetSelectedTaskFilters } =
    selectedTaskFiltersSlice.actions;

export const selectSelectedTaskFilters = (
    state: RootState
): SelectedTaskFiltersState => state.selectedTaskFilters;

export default selectedTaskFiltersSlice.reducer;
