import { useMutation } from 'react-query';
import { RoutesApi } from '~/api/RoutesApi';

export const useRouteUnassign = () => {
    const {
        data: unassignRouteData,
        error: unassignRouteError,
        isError: unassignRouteIsError,
        isLoading: unassignRouteIsLoading,
        isSuccess: unassignRouteIsSuccess,
        mutateAsync: unassignRouteById,
        reset: unassignRouteReset
    } = useMutation((routeId) => {
        return RoutesApi.unassignRouteById(routeId);
    });

    const unassignRoute = async (routeId, sideEffects = {}) => {
        const { onSuccess: onSuccessUnassign, onError: onErrorUnassign } =
            sideEffects;

        try {
            const successData = await unassignRouteById(routeId);

            return (
                (onSuccessUnassign && onSuccessUnassign(successData)) ||
                successData
            );
        } catch (error) {
            console.error('unassignRoute Error: ', error.message);
            return onErrorUnassign && onErrorUnassign(error);
        }
    };

    return {
        unassignRouteData,
        unassignRouteError,
        unassignRouteIsError,
        unassignRouteIsLoading,
        unassignRouteIsSuccess,
        unassignRoute,
        unassignRouteReset
    };
};
