import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    selectedIsClusteringUnassignedTasks,
    selectShowUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { selectTasksV2ApiPaginationOptions } from '~/reducers/tasksV2Slice';

import makeUnassignedClusterMarkers from '~/utils/map-modes/unassigned-tasks-cluster-mode';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';
import { makeUnassignedStopMarkerEffects } from '~/components/MapPage/PlanMap/makeUnassignedStopMarkerEffects';

import {
    useIntermodalFeatures,
    useLiveDispatchTasks,
    useLiveDispatchUnassignedTasksV2,
    useMapUtils
} from '~/hooks';
import { DEFAULT_PAGINATION_OPTIONS } from '~/hooks/useLiveDispatchTasks';

import {
    UseDispatchedUnassignedTaskMarkersProps,
    UseDispatchedUnassignedTaskMarkersReturnValue
} from './types';

import { useDispatchedGetClusters } from '../useDispatchedGetClusters';

export const useDispatchedUnassignedTaskMarkers = ({
    unassignedSuperClusters
}: UseDispatchedUnassignedTaskMarkersProps): UseDispatchedUnassignedTaskMarkersReturnValue => {
    const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const tasksV2ApiPaginationOptions = useSelector(
        selectTasksV2ApiPaginationOptions
    );
    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const { enableLiveDispatch } = useIntermodalFeatures();

    const { unassignedTasks: allUnassignedTasks } = useLiveDispatchTasks({
        selectedTaskIds,
        apiPaginationOptions: DEFAULT_PAGINATION_OPTIONS
    });

    const { unassignedTasks: filteredUnassignedTasks } =
        useLiveDispatchUnassignedTasksV2({
            selectedTaskIds,
            apiPaginationOptions: tasksV2ApiPaginationOptions
        });

    const { getClusters } = useDispatchedGetClusters();

    const unassignedTasks = useMemo(
        () =>
            enableLiveDispatch ? filteredUnassignedTasks : allUnassignedTasks,
        [enableLiveDispatch, filteredUnassignedTasks, allUnassignedTasks]
    );

    return useMemo(() => {
        if (
            !isDispatchedRouteMode ||
            !showUnassignedTasks ||
            !unassignedTasks.length
        ) {
            return {
                unassignedTaskMarkers: [],
                unassignedTaskMarkerCoordinates: []
            };
        }

        const unassignedTaskMarkers = makeUnassignedStopMarkerEffects({
            isClusteringStops: isClusteringUnassignedTasks,
            unassignedSuperClusters,
            getClusters,
            makeUnassignedClusterMarkers,
            emittedEventHandler,
            planTasks: unassignedTasks
        });

        const flattenedUnassignedTasksMarkerEffects =
            unassignedTaskMarkers.flat(Infinity);
        const unassignedTaskMarkerCoordinates =
            flattenedUnassignedTasksMarkerEffects.map((marker: JSX.Element) => {
                const { props } = marker;
                const { lat, lng } = props;
                return { lat, lng };
            });

        return {
            unassignedTaskMarkers,
            unassignedTaskMarkerCoordinates
        };
    }, [
        isDispatchedRouteMode,
        showUnassignedTasks,
        isClusteringUnassignedTasks,
        unassignedSuperClusters,
        getClusters,
        emittedEventHandler,
        unassignedTasks
    ]);
};
