import { useCallback } from 'react';
import { ClientTask, getInvoiceErrorState } from '../utils/addTaskModalUtils';

type Value = Required<ClientTask>['invoices'];
type Field = 'invoices';
type Props = {
    onArrayFieldChange: (
        field: Field,
        value: Value,
        getArrayItemErrorState: (
            item: Required<ClientTask>[Field][number],
            requiredMap: RequiredFieldMapTypeGen<
                Required<ClientTask>
            >[Field]['requiredMap']
        ) => Required<ErrorStateTypeGen<Required<ClientTask>[Field][number]>>
    ) => void;
};

export const useOnInvoicesChange = ({ onArrayFieldChange }: Props) => {
    return useCallback(
        (value: Required<ClientTask>[Field]) => {
            return onArrayFieldChange('invoices', value, getInvoiceErrorState);
        },
        [onArrayFieldChange]
    );
};
