import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    useHereMaps,
    useMapUtils,
    usePlanningFeaturePermissions
} from '~/hooks';

import { selectIsAddBreakModalOpen } from '~/reducers/driverBreaksModalSlice';

import { AddTaskModal } from '~/components/AddTaskModal/AddTaskModal';
import { PlanStopsControl } from '~/components/MapPage/PlanStopsControl';
import { LiveStopsControl } from '~/components/MapPage/LiveStopsControl';
import { UnassignedTasksControl } from '~/components/MapPage/UnassignedTasksControl';

import { AddBreakModal } from './AddBreakModal/AddPlanBreakModal';
import { AddLiveBreakModal } from './AddBreakModal/AddLiveBreakModal';
import { DriverBreakModal } from './DriverBreakModal/DriverPlanBreakModal';
import { DriverLiveBreakModal } from './DriverBreakModal/DriverLiveBreakModal';
import { MapSettingsControl } from './MapSettingsControl';
import { MapViewControl } from './MapViewControl';
import OnDemandDispatchMapControl from './OnDemandDispatchMapControl/OnDemandDispatchMapControl';
import ZoomControl from '~/ui/components/PlanMapOverlay/ZoomControl';
import { ZoomControl as HereMapsZoomControl } from '~/ui/components/HereMaps/ZoomControl';
import { RouteLinesControl } from './RouteLinesControl';
import { AddOperationalStopModal } from '~/components/AddOperationalStopModal/AddOperationalStopModal';
import {
    selectIsLiveDispatchDrawerExpanded,
    selectIsOpenLiveDispatchDrawer
} from '~/reducers/mapDrawerSettingsSlice';

import './PlanMapOverlay.scss';

function PlanMapOverlay({ map }) {
    const [showControls, setShowControls] = useState(false);
    const [showPlanStopsControl, setShowPlanStopsControl] = useState(false);
    const [showOnDemandDispatchControl, setShowOnDemandDispatchControl] =
        useState(false);
    const { isAllowedToEdit: isAllowedToEditPlan } =
        usePlanningFeaturePermissions();
    const showAddBreaksModal = useSelector(selectIsAddBreakModalOpen);
    const isOpenLiveDispatchDrawer = useSelector(
        selectIsOpenLiveDispatchDrawer
    );
    const isLiveDispatchDrawerExpanded = useSelector(
        selectIsLiveDispatchDrawerExpanded
    );
    const { isDispatchedRouteMode, isPlanRouteMode } = useMapUtils();
    const { isHerePlanMapActive } = useHereMaps();

    useEffect(() => {
        return map !== null ? setShowControls(true) : setShowControls(false);
    }, [map]);

    useEffect(() => {
        setShowPlanStopsControl(isAllowedToEditPlan && isPlanRouteMode);
        setShowOnDemandDispatchControl(
            isAllowedToEditPlan && isDispatchedRouteMode
        );
    }, [isAllowedToEditPlan, isPlanRouteMode, isDispatchedRouteMode]);

    const hideMapControls =
        isOpenLiveDispatchDrawer && isLiveDispatchDrawerExpanded;

    return (
        <>
            {!hideMapControls && (
                <>
                    <div className="planmapoverlay planmapoverlay_mapcontrols _d-flex _ai-center _fd-column _p-absolute">
                        <OnDemandDispatchMapControl />
                        {!isHerePlanMapActive && (
                            <ZoomControl
                                map={map}
                                showControls={showControls}
                            />
                        )}
                        {isHerePlanMapActive && (
                            <HereMapsZoomControl
                                map={map}
                                showControls={showControls}
                            />
                        )}
                        <MapSettingsControl />
                    </div>
                    <MapViewControl />
                    <RouteLinesControl />
                </>
            )}

            <div className="planmapoverlay planmapoverlay_stopcontrols _d-flex _p-absolute">
                {showPlanStopsControl && <PlanStopsControl />}
                {showOnDemandDispatchControl && (
                    <>
                        <LiveStopsControl />
                        <UnassignedTasksControl />
                    </>
                )}
                {showAddBreaksModal && isPlanRouteMode && <AddBreakModal />}
                {showAddBreaksModal && isDispatchedRouteMode && (
                    <AddLiveBreakModal />
                )}
                <DriverBreakModal />
                <DriverLiveBreakModal />
            </div>

            <AddTaskModal />
            <AddOperationalStopModal />
        </>
    );
}

export default PlanMapOverlay;
