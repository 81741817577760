import { AddressTaskTypes } from '~/api/types';
import {
    AddressInputFields,
    AddressTabFields,
    ClientTask
} from '../addTaskModalUtils';

const getPickupLocationFromPlace = (place: AddressTabFields) => {
    const {
        pickupAddressCity,
        pickupAddressState,
        pickupAddressStreet,
        pickupAddressZipCode,
        pickupCustomerName,
        pickupLatitude,
        pickupLongitude,
        pickupCustomerId,
        pickupAddressApt,
        pickupEuid
    } = place;
    return {
        pickupAddressApt,
        pickupAddressCity,
        pickupAddressState,
        pickupAddressStreet,
        pickupAddressZipCode,
        pickupCustomerName,
        pickupCustomerId,
        pickupLatitude,
        pickupLongitude,
        pickupEuid
    };
};

const getDeliveryLocationFromPlace = (place: AddressTabFields) => {
    const {
        deliveryAddressApt,
        deliveryAddressCity,
        deliveryAddressState,
        deliveryAddressStreet,
        deliveryAddressZipCode,
        deliveryCustomerName,
        deliveryCustomerId,
        deliveryLatitude,
        deliveryLongitude,
        deliveryEuid
    } = place;
    return {
        deliveryAddressApt,
        deliveryAddressCity,
        deliveryAddressState,
        deliveryAddressStreet,
        deliveryAddressZipCode,
        deliveryCustomerName,
        deliveryCustomerId,
        deliveryLatitude,
        deliveryLongitude,
        deliveryEuid
    };
};

const getTaskSpecificLocationFromPlace = (
    taskType: AddressTaskTypes,
    place: AddressInputFields
) => {
    const {
        customerName,
        addressStreet,
        addressApt,
        addressCity,
        addressState,
        addressZipCode,
        customerId,
        latitude,
        longitude,
        euid
    } = place;

    return {
        [`${taskType}CustomerName`]: customerName,
        [`${taskType}CustomerId`]: customerId,
        [`${taskType}AddressStreet`]: addressStreet,
        [`${taskType}AddressApt`]: addressApt,
        [`${taskType}AddressCity`]: addressCity,
        [`${taskType}AddressState`]: addressState,
        [`${taskType}AddressZipCode`]: addressZipCode,
        [`${taskType}Longitude`]: longitude,
        [`${taskType}Latitude`]: latitude,
        [`${taskType}Euid`]: euid
    };
};

const getTaskSpecificLocationFromClientTask = (
    taskType: AddressTaskTypes,
    clientTask: ClientTask
) => {
    return {
        [`${taskType}CustomerName`]: clientTask[`${taskType}CustomerName`],
        [`${taskType}CustomerId`]: clientTask[`${taskType}CustomerId`],
        [`${taskType}Euid`]: clientTask[`${taskType}Euid`],
        [`${taskType}AddressStreet`]: clientTask[`${taskType}AddressStreet`],
        [`${taskType}AddressApt`]: clientTask[`${taskType}AddressApt`],
        [`${taskType}AddressCity`]: clientTask[`${taskType}AddressCity`],
        [`${taskType}AddressState`]: clientTask[`${taskType}AddressState`],
        [`${taskType}AddressZipCode`]: clientTask[`${taskType}AddressZipCode`],
        [`${taskType}Longitude`]: clientTask[`${taskType}Longitude`],
        [`${taskType}Latitude`]: clientTask[`${taskType}Latitude`]
    };
};

export {
    getDeliveryLocationFromPlace,
    getPickupLocationFromPlace,
    getTaskSpecificLocationFromClientTask,
    getTaskSpecificLocationFromPlace
};
