/* eslint-disable camelcase */

import axios from 'axios';
import dateUtils from '~/utils/date-utils';
import { DateConstructorInput } from '~/utils/date-utils-converters';
import { ApiCourierTaskAssignment, AxiosApiResponse } from './types';

interface PostCourierTaskAssignmentsArgs {
    date?: DateConstructorInput;
    close_routes?: boolean;
}

type PostCourierTaskAssignmentsResponse = AxiosApiResponse<string>;

interface GetCourierTaskAssignmentsArgs {
    date?: DateConstructorInput;
}

interface GetCourierTaskAssignmentsParams {
    date?: string;
}

type GetCourierTaskAssignmentsResponse =
    AxiosApiResponse<ApiCourierTaskAssignment>;

/**
 * Implementations of API methods under the `/courier_task_assignments` path
 *
 * @category API
 */
export default class CourierTaskAssignmentsApi {
    /**
     * Path of the courier task assignments endpoint
     */
    private static readonly path = '/courier_task_assignments';

    static post({
        date: inputDate,
        close_routes
    }: PostCourierTaskAssignmentsArgs): Promise<PostCourierTaskAssignmentsResponse> {
        if (!inputDate) {
            return Promise.reject('date not provided');
        }
        const date = dateUtils.convertToISODateOnly(inputDate);

        return axios.post(this.path, { date, close_routes });
    }

    /**
     * @param id - task assignment run id
     * @param {{date: Date | string | number}} params - run date
     */
    static get(
        id: string,
        inputParams: GetCourierTaskAssignmentsArgs
    ): Promise<GetCourierTaskAssignmentsResponse> {
        if (!id && !inputParams) {
            return Promise.reject('must specify run id or date');
        }

        const params: GetCourierTaskAssignmentsParams = {
            ...inputParams,
            date: inputParams.date
                ? dateUtils.convertToISODateOnly(inputParams.date) ?? undefined
                : undefined
        };

        const path = id ? `${this.path}/${id}` : this.path;
        return axios.get(path, {
            params
        });
    }
}
