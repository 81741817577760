import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, styles } from '~/ui';
import Text from '../Text';

const variantStyles = {
    default: {
        color: 'galaxy-500',
        backgroundColor: 'galaxy-50',
        border: (theme) => `solid 0.1rem ${theme.colors['galaxy-50']}`,
        textVariant: '12-bold',
        selected: {
            color: 'meteor',
            backgroundColor: 'neptune-100',
            border: (theme) => `solid 0.1rem ${theme.colors['neptune-200']}`,
            icon: 'circle',
            iconColor: 'sky',
            iconSize: 'xxxs'
        }
    }
};

const ActionButton = React.forwardRef(function ActionButton(
    { text, selected = false, onClick, variant = 'default', ...extra },
    ref
) {
    const selectedStyle = variantStyles[variant];

    return (
        <FlexLayout
            ref={ref}
            alignItems="center"
            justifyContent="center"
            padding="0.4rem 0.8rem"
            sx={{
                ...styles.interactions.clickable,
                backgroundColor: selected
                    ? selectedStyle.selected.backgroundColor
                    : selectedStyle.backgroundColor,
                border: selected
                    ? selectedStyle.selected.border
                    : selectedStyle.border,
                borderRadius: '1.2rem',
                '&:hover': {
                    backgroundColor: selectedStyle.selected.backgroundColor,
                    border: selectedStyle.selected.border
                }
            }}
            onClick={onClick}
            {...extra}
        >
            {selected && (
                <Icon
                    icon={selectedStyle.selected.icon}
                    size={selectedStyle.selected.iconSize}
                    color={selectedStyle.selected.iconColor}
                    marginRight="0.4rem"
                />
            )}
            <Text
                variant={selectedStyle.textVariant}
                color={
                    selected
                        ? selectedStyle.selected.color
                        : selectedStyle.color
                }
                paddingTop="0.1rem"
            >
                {text}
            </Text>
        </FlexLayout>
    );
});

ActionButton.propTypes = {
    /** Text in button */
    text: PT.string,
    /** Is in selected state */
    selected: PT.bool,
    /** Variant style of action button */
    variant: PT.string,
    /** On button click function */
    onClick: PT.func
};

export default ActionButton;
