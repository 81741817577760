import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon, TextInput, TooltipButton, UIElement } from '~/ui';
import { useEscKey } from '~/hooks';

import './TableToolbar.scss';

interface TableToolbarProps extends UIElement {
    searchText: string;
    setSearchText: (value: string) => void;
    setSearchTextDebounced: (value: string) => void;
    searchBarPlaceholder: string;
    stickyTopOffset?: number;
    disableStickyHeader: boolean;
    customComponent: ReactNode;
    filter: ReactNode;
}

export const TableToolbar = ({
    searchText,
    setSearchText,
    setSearchTextDebounced,
    searchBarPlaceholder,
    stickyTopOffset,
    disableStickyHeader,
    customComponent,
    filter
}: TableToolbarProps) => {
    const { t } = useTranslation(['driverBooking', 'common']);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchMode, setSearchMode] = useState(false);

    const elementClassName = classNames('_d-flex _ai-center tabletoolbar', {
        tabletoolbar_sticky: !disableStickyHeader
    });

    const searchClassName = classNames(
        '_d-flex _ai-center _w-100 tabletoolbar-searchbar',
        { 'tabletoolbar-searchbar_focused': searchFocused }
    );

    const hasFilterOutput = Boolean(filter);

    useEscKey(() => setSearchMode(false));

    useEffect(() => {
        if (searchMode && searchInputRef.current) {
            searchInputRef.current.focus();
        } else {
            setSearchText('');
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [searchMode]);

    const getTableToolbarStyle = (offset?: number) => {
        if (typeof offset !== 'undefined') {
            return { top: `${offset}rem` };
        }
        return { top: 'var(--dimension-bar-offset-height)' };
    };

    const renderSearchBar = () => (
        <div className={searchClassName} data-testid="tabletoolbar__searchbar">
            <TextInput
                className="tabletoolbar-searchinput"
                ref={searchInputRef}
                width="fullWidth"
                placeholder={searchBarPlaceholder || ''}
                variant="search"
                value={searchText}
                onChange={(value: string) => setSearchTextDebounced(value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                data-testid="tabletoolbar__search-input"
            />
            {searchText && (
                <TooltipButton
                    className="_d-flex _jc-center _ai-center tabletoolbar-button_clear"
                    onClick={() => setSearchText('')}
                    data-testid="tabletoolbar__search-clear-button"
                >
                    {t('clearLabel')}
                </TooltipButton>
            )}
            {hasFilterOutput && (
                <div
                    className="tabletoolbar-searchbar-filter"
                    data-testid="tabletoolbar__searchbar-filter-output"
                >
                    {filter}
                </div>
            )}
            <TooltipButton
                className="_d-flex _jc-center _ai-center tabletoolbar-button_close"
                onClick={() => setSearchMode(false)}
                data-testid="tabletoolbar__search-close-button"
            >
                <Icon icon="cancel" color="comet" />
            </TooltipButton>
        </div>
    );

    return (
        <div
            className={elementClassName}
            style={getTableToolbarStyle(stickyTopOffset)}
            data-testid="tabletoolbar"
        >
            {searchMode ? (
                renderSearchBar()
            ) : (
                <div className="_d-flex _ai-center _jc-space-between _w-100 tabletoolbar__action-group">
                    <div className="_d-flex _ai-center tabletoolbar__search-group">
                        <TooltipButton
                            className="_d-flex _ai-center _jc-center _mr-1 tabletoolbar-button_search"
                            onClick={() => setSearchMode(true)}
                            data-testid="tabletoolbar__search-button"
                        >
                            <Icon icon="search" color="galaxy-500" size="s" />
                            <span className="_text-1 _ml-4">
                                {t('common:search')}
                            </span>
                        </TooltipButton>
                        {hasFilterOutput && (
                            <div
                                className="tabletoolbar-filter"
                                data-testid="tabletoolbar__filter-output"
                            >
                                {filter}
                            </div>
                        )}
                    </div>
                    {customComponent}
                </div>
            )}
        </div>
    );
};
