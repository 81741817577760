import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { getStopIds } from '../utils';

export const useIsPlanStopSelected = () => {
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const { isPlanRouteMode } = useMapUtils();

    const isPlanStopSelected = useCallback(
        (clientRouteTaskId) => {
            if (!isPlanRouteMode) return false;

            const stopsIds = getStopIds(clientRouteTaskId);

            return stopsIds.some((stopId) =>
                selectedMapStopIds.includes(stopId)
            );
        },
        [isPlanRouteMode, selectedMapStopIds]
    );

    return {
        isPlanStopSelected
    };
};
