import React from 'react';
import classNames from 'classnames';
import { NavigationTabElement, NavigationTabsGroupElement } from './types';
import { Icon } from '~/ui';
import './NavigationTabsGroup.scss';

export const navigationTabsGroupContainerTestId =
    'navigationTabsGroup-containerTestId';

export const navigationTabsGroupButtonTestId =
    'navigationTabsGroup-buttonTestId';

const ROOT_CLASS_NAME = 'navigationtabsgroup';

function NavigationTabsGroup({ tabs, className }: NavigationTabsGroupElement) {
    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', className);
    const buttonClassName = classNames(`${ROOT_CLASS_NAME}__button`, {
        [`${className}__button`]: className
    });

    return (
        <div
            className={elementClassName}
            data-testid={navigationTabsGroupContainerTestId}
        >
            {tabs.map((item: NavigationTabElement) => {
                const {
                    text,
                    disabled,
                    selected,
                    onClick,
                    icon,
                    iconSize,
                    iconColor
                } = item;

                return (
                    <button
                        key={`navigationtabsgroup__button-${text}`}
                        data-testid={`${navigationTabsGroupButtonTestId}-${text}`}
                        type="button"
                        className={buttonClassName}
                        disabled={disabled}
                        // prevent `data-selected` from rendering when false or falsy
                        // for correct css rule application
                        data-selected={selected || null}
                        onClick={() => {
                            if (!selected) onClick();
                        }}
                    >
                        {icon && (
                            <Icon
                                icon={icon}
                                size={iconSize || 's'}
                                color={iconColor}
                            />
                        )}
                        {text}
                    </button>
                );
            })}
        </div>
    );
}

export default NavigationTabsGroup;
