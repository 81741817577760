import { useContext } from 'react';
import { Context } from './types';
import { MapContext } from './MapContext';

export const useMapContext = (): Context => {
    const context = useContext(MapContext);

    if (!context) {
        throw new Error('useMapContext must be within context provider');
    }

    return context;
};
