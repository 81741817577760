import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import {
    resetLiveDrivers,
    selectDispatchedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedClients } from '~/reducers/selectedClientsSlice';
import { ApiLiveDriver } from '~/api/types';

export function useDispatchedLiveDrivers() {
    const dispatch = useDispatch();
    const selectedClients = useSelector(selectSelectedClients);

    const dispatchedDrivers = useSelector(
        selectDispatchedDrivers
    ) as ApiLiveDriver[];

    useEffect(() => {
        dispatch(resetLiveDrivers());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedClients]);

    return useMemo(() => {
        return dispatchedDrivers
            .filter((driver) => {
                return selectedClients[driver.clientId];
            })
            .map((driver) => new LiveDriver(driver));
    }, [dispatchedDrivers, selectedClients]);
}
