import React, { useState, useMemo } from 'react';
import constants from '~/utils/constants';
import customersApi from '~/api/CustomersApi';
import { debounce } from 'lodash';
import { addToast } from '~/reducers/toastsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    selectCustomerSearchResults,
    clearCustomerSearchResults
} from '~/reducers/customerSearchResultsSlice';
import CustomerSearchResult from '../CustomerSearchResult';
import '../CustomerSearchResult/CustomerSearchResult.scss';
import FuzzyTextSearch from '~/ui/components/FuzzyTextSearch/FuzzyTextSearch';

const CustomerDatabaseAutocomplete = ({ placeholder, onSelect, taskType }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const searchResults = useSelector(selectCustomerSearchResults);
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedCustomerSearch = useMemo(() => {
        return debounce(async (userInput) => {
            try {
                await customersApi.searchCustomers(userInput);
                setSearchTerm(userInput);
            } catch (err) {
                console.error(err);
                dispatch(
                    addToast({
                        message: t('error:customerSearchError'),
                        variant: 'error'
                    })
                );
            }
        }, constants.timings.TEXT_DEBOUNCE);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    function _handleCustomerSearchChange(userInput) {
        if (!userInput) dispatch(clearCustomerSearchResults());
        if (userInput.length >= constants.customerSearch.searchTermLength) {
            debouncedCustomerSearch(userInput);
        }
    }

    return (
        <div
            className="customer-database-autocomplete"
            data-testid="customer-database-autocomplete"
        >
            <FuzzyTextSearch
                placeholder={placeholder}
                handleOnChange={_handleCustomerSearchChange}
            >
                {searchResults.map((customerSearchResult, index) => (
                    <CustomerSearchResult
                        key={customerSearchResult.id ?? index}
                        data={customerSearchResult}
                        searchTerm={searchTerm}
                        handleOnClick={(place) => {
                            return onSelect({
                                ...place,
                                currentTaskType: taskType
                            });
                        }}
                    />
                ))}
            </FuzzyTextSearch>
        </div>
    );
};
export default CustomerDatabaseAutocomplete;
