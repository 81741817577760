import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useProcessedRouteData } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/useProcessedRouteData';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { PlanRoute } from '~/data-classes';
import { RouteCardMarker, RouteCardSummary, RouteCardTitle } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './CreateTripModalRouteCard.scss';

export const CreateTripModalRouteCard = () => {
    const rootClassName = 'create-trip-modal-route-card';
    const { t } = useTranslation('createTripModal');
    const { data: selectedDrawerCardData } = useSelector(
        selectSelectedDrawerCardData
    );
    const planRoute = useMemo(
        () => new PlanRoute(selectedDrawerCardData),
        [selectedDrawerCardData]
    );
    const { summaryMetrics, markerColor, iconColor, titleLine, trips } =
        useProcessedRouteData(planRoute);

    const currentTripsText = t('routeCard.numberOfTrips', {
        count: trips.length
    });

    return (
        <div className={`${rootClassName} _d-flex _w-100`}>
            <RouteCardMarker
                disabled
                iconStyle={markerColor}
                icon="route"
                iconSize={IconSizes.S}
                iconColor={iconColor}
            />
            <div className="_fd-column _w-100">
                <div className="_d-flex">
                    <RouteCardTitle
                        title={titleLine}
                        tooltip={titleLine}
                        tooltipPlacement="top"
                    />
                    <span className={`${rootClassName}__number-of-trips`}>
                        {currentTripsText}
                    </span>
                </div>
                <RouteCardSummary
                    className={`${rootClassName}__summary _d-grid`}
                    metrics={summaryMetrics}
                />
            </div>
        </div>
    );
};
