import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SocketScheduleItem } from '~/api/types';
import { IconButton, TooltipButton } from '~/ui';
import { Toggle } from '~/ui/components/Toggle';
import { useEndOfShiftCallbacks } from '../useEndOfShiftCallbacks';
import './MarkEndOfShiftModalContent.scss';

interface MarkEndOfShiftModalContentParams {
    /**
     * The stop data
     */
    stopData: SocketScheduleItem;

    /**
     * Hides the modal
     */
    hideModal: () => void;
}

export const MarkEndOfShiftModalContent = ({
    stopData,
    hideModal
}: MarkEndOfShiftModalContentParams) => {
    const { stopname: stopName } = stopData;

    const [isEnabledUnassignTasks, setIsEnabledUnassignTasks] = useState(false);
    const { t } = useTranslation('markEndOfShift');
    const { canUnassignTasks, handleMarkShiftEnd } =
        useEndOfShiftCallbacks(stopData);

    const handleClickUnassignTasks = () => {
        setIsEnabledUnassignTasks(!isEnabledUnassignTasks);
    };

    const handleClickMarkEndOfShift = async () => {
        await handleMarkShiftEnd(isEnabledUnassignTasks);
        hideModal();
    };

    const toggleSectionClassName = classNames(
        'mark-end-of-shift-modal__toggle-section',
        {
            'mark-end-of-shift-modal__toggle-section--on':
                isEnabledUnassignTasks
        }
    );

    return (
        <>
            <div className="modal-header mark-end-of-shift-modal__header">
                <div
                    className="_d-flex _ai-center mark-end-of-shift-modal__title"
                    data-testid="mark-end-of-shift-modal__title"
                >
                    <Trans i18nKey="markEndOfShift:title" values={{ stopName }}>
                        <span />
                    </Trans>
                </div>
                <IconButton
                    className="icon-wrapper"
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={hideModal}
                />
            </div>
            <div className="modal-body mark-end-of-shift-modal__body _fd-column">
                <div
                    className="mark-end-of-shift-modal__description"
                    data-testid="mark-end-of-shift-modal__description"
                >
                    <Trans
                        i18nKey="markEndOfShift:description"
                        values={{ stopName }}
                    >
                        <span />
                    </Trans>
                </div>
                {canUnassignTasks && (
                    <div
                        className={toggleSectionClassName}
                        data-testid="mark-end-of-shift-modal__toggle-section"
                    >
                        <div
                            className="mark-end-of-shift-modal__toggle-title"
                            data-testid="mark-end-of-shift-modal__toggle-title"
                        >
                            <Trans
                                i18nKey="markEndOfShift:toggle.title"
                                values={{ stopName }}
                            >
                                <span />
                            </Trans>
                        </div>
                        <div
                            className="mark-end-of-shift-modal__toggle-description"
                            data-testid="mark-end-of-shift-modal__toggle-description"
                        >
                            {t('toggle.description')}
                        </div>
                        <Toggle
                            className="mark-end-of-shift-modal__toggle"
                            isOn={isEnabledUnassignTasks}
                            onToggle={handleClickUnassignTasks}
                        />
                    </div>
                )}
            </div>
            <div className="modal-footer _d-flex _jc-flex-end _ai-center mark-end-of-shift-modal__footer">
                <TooltipButton
                    className="modal-button mark-end-of-shift-modal__button"
                    variant={TooltipButton.variants.DANGER_SECONDARY}
                    size={TooltipButton.sizes.LARGE}
                    onClick={hideModal}
                >
                    {t('button.no')}
                </TooltipButton>
                <TooltipButton
                    className="modal-button mark-end-of-shift-modal__button"
                    variant={TooltipButton.variants.DANGER}
                    size={TooltipButton.sizes.LARGE}
                    onClick={handleClickMarkEndOfShift}
                >
                    {t('button.yes')}
                </TooltipButton>
            </div>
        </>
    );
};
