/* eslint-disable camelcase */
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';
import { idUtils } from '~/utils/id-utils';
import { getErrorObj } from './getErrors';

const getMetaDataErrors = (sheetName, rowData) => {
    const {
        meta_data_property: prop,
        meta_data_value: val,
        __rowNum__: rowNum
    } = rowData;

    const row = rowNum + 1;

    const errorObj = {
        sheetName,
        row,
        key: idUtils.getCombinedId(sheetName, row)
    };

    const rowErrors = [];

    switch (prop) {
        // test custom matrix name
        case 'name':
            // custom cost matrix name is required
            try {
                const isValidMatrixName = _.isString(val);
                if (!isValidMatrixName) {
                    const errorKey = terms.errorKeys.META_DATA_NOT_VALID;
                    const fixKey = terms.fixKeys.VALUE_MUST_BE_STRING;
                    const errorData = {
                        ...errorObj,
                        errorKey,
                        fixKey,
                        col: 'meta_data_value',
                        value: val || 'No value'
                    };
                    rowErrors.push(getErrorObj(errorData));
                }
            } catch (e) {
                console.error(e);
            }

            // getErrorObj
            break;
        // test service name
        case 'service_name':
            // Service name is not a String.
            // Service name is longer than 64 characters.
            try {
                const isValidServiceName = _.isString(val) && val.length <= 64;
                if (!isValidServiceName) {
                    const errorKey = terms.errorKeys.META_DATA_NOT_VALID;
                    const fixKey = terms.fixKeys.SERVICE_NAME_MUST_BE_STRING;
                    const errorData = {
                        ...errorObj,
                        errorKey,
                        fixKey,
                        col: 'meta_data_value',
                        value: val || 'No value'
                    };
                    rowErrors.push(getErrorObj(errorData));
                }
            } catch (e) {
                console.error(e);
            }

            break;
        // test service cost
        case 'service_cost':
            // Service cost is not a number (decimal number ok).
            // Service cost is negative.
            // Service cost is more than 1,000,000,000.
            try {
                const isValidServiceCost =
                    _.isNumber(val) && val > 0 && val < 1000000000;
                if (!isValidServiceCost) {
                    const errorKey = terms.errorKeys.META_DATA_NOT_VALID;
                    const fixKey = terms.fixKeys.SERVICE_COST_MUST_BE_NUMERIC;
                    const errorData = {
                        ...errorObj,
                        errorKey,
                        fixKey,
                        col: 'meta_data_value',
                        value: val || 'No value'
                    };
                    rowErrors.push(getErrorObj(errorData));
                }
            } catch (e) {
                console.error(e);
            }

            break;
        default:
            console.error('Unknown meta data property: ', prop);
    }

    return { ...errorObj, hasErrors: rowErrors.length > 0, errors: rowErrors };
};

const transformMetaDataSheet = (sheetToJson) => {
    const metaDataEntries = sheetToJson.reduce(
        (entries, rowData) => {
            const errorObj = getMetaDataErrors(
                terms.sheetName.METADATA,
                rowData
            );

            if (errorObj.hasErrors) {
                entries.metaDataErrors[errorObj.key] = errorObj;

                if (!entries.hasErrors) entries.hasErrors = true;
            } else {
                const { meta_data_property, meta_data_value } = rowData;
                entries.metaDataEntries[meta_data_property] = meta_data_value;
            }

            return entries;
        },
        {
            metaDataEntries: {},
            metaDataErrors: {},
            hasErrors: false
        }
    );

    return metaDataEntries;
};

export default transformMetaDataSheet;
