export default {
    map: '0',
    mapControls: '1',
    stickyHeader: '3',
    navigation: '21',
    action: '20',
    drawer: '30',
    modal: '40',
    tooltip: '41',
    toast: '120'
};
