import axios from 'axios';
import generalUtils from '~/utils/general-utils';
import dateUtils from '~/utils/date-utils';
import {
    ApiInitialVsActualDriverRoutePaths,
    AxiosApiResponse,
    ClientIvASummary
} from './types';
import { getURL, ServerBaseUrlKey } from '~/servers/servers';

/**
 * Parameters for InitialVsActualApi.getDriverRoutePaths()
 */
export interface GetDriverRoutePathsParams {
    /**
     * The driver ID
     */
    driverId: string;

    /**
     * The target date
     */
    date: string;
}

export interface GetClientIvASummaryParams {
    /**
     * The client ID
     */
    clientId: string;

    /**
     * The target date
     */
    date: string;
}

/**
 * Base URL of the API endpoint
 */
const IVA_BASEURL = getURL(ServerBaseUrlKey.IVA);

/**
 * Possible errors for /initialvsactual API
 * @category API
 */
export enum InitialVsActualApiErrors {
    MISSING_DRIVER_ID = 'Missing driverId parameter',
    MISSING_DATE = 'Missing date parameter',
    INVALID_DRIVER_ID = 'Invalid driverId parameter, must be a UUID string',
    INVALID_DATE = 'Invalid date parameter, must be ISO-formatted string (eg yyyy-mm-dd)'
}

/**
 * Implementations of API methods under the /initialvsactual path
 *
 * @category API
 */
export default class InitialVsActualApi {
    /**
     * Base URL of the API endpoint
     */
    private static readonly baseURL = IVA_BASEURL;

    /**
     * Path of the API endpoint
     */
    private static readonly path = '/initialvsactual';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'initialvsactual';

    private static validateParams(
        params: GetDriverRoutePathsParams
    ): InitialVsActualApiErrors | undefined {
        const { driverId, date } = params || {};

        if (!driverId) {
            return InitialVsActualApiErrors.MISSING_DRIVER_ID;
        }

        if (!generalUtils.isValidUUID(driverId)) {
            return InitialVsActualApiErrors.INVALID_DRIVER_ID;
        }

        if (!date) {
            return InitialVsActualApiErrors.MISSING_DATE;
        }

        if (
            !dateUtils.checkIsDateStringValidDate(date) ||
            !dateUtils.checkIsYearMonthDayFormat(date)
        ) {
            return InitialVsActualApiErrors.INVALID_DATE;
        }
    }

    /**
     * Retrieve a driver's route path
     *
     * @param {GetDriverRoutePathsParams} params - additional query parameters for this API
     * @returns Promise<AxiosApiResponse<ApiInitialVsActualDriverRoutePaths>>
     */
    static getDriverRoutePaths(
        params: GetDriverRoutePathsParams
    ): Promise<AxiosApiResponse<ApiInitialVsActualDriverRoutePaths>> {
        const validationError = InitialVsActualApi.validateParams(params);
        if (validationError) return Promise.reject(validationError);

        const { driverId, date } = params;
        const options = {
            params: { date },
            baseURL: this.baseURL
        };
        return axios.get(`${this.path}/${driverId}/route_paths`, options);
    }

    /**
     * get clients summary
     *
     * @param {GetClientIvASummaryParams} params - additional query parameters for this API
     */
    static getClientIvASummary(
        params: GetClientIvASummaryParams
    ): Promise<AxiosApiResponse<ClientIvASummary>> {
        const { clientId, date } = params;

        if (!clientId) {
            return Promise.reject('Missing clientId parameter');
        }

        if (!date) {
            return Promise.reject('Missing date parameter');
        }

        if (
            !dateUtils.checkIsDateStringValidDate(date) ||
            !dateUtils.checkIsYearMonthDayFormat(date)
        ) {
            return Promise.reject(InitialVsActualApiErrors.INVALID_DATE);
        }

        const options = {
            params: { client: clientId, date },
            baseURL: this.baseURL
        };
        return axios.get(`${this.path}/summary`, options);
    }
}
