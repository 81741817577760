import React from 'react';
import { Tooltip } from '~/ui';
import './simple-tooltip.scss';

function SimpleTooltip({ children, tooltip }) {
    const tooltipSx = {
        fontSize: '1.2rem',
        lineHeight: '1.6rem',
        maxWidth: 'none'
    };

    return (
        <Tooltip placement="top" content={tooltip} sx={tooltipSx} gapSize="xs">
            <span className="simple-tooltip">{children}</span>
        </Tooltip>
    );
}

export default SimpleTooltip;
