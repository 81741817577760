import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

interface DriverBreakState {
    isAddBreakModalOpen: boolean;
}

const initialState: DriverBreakState = {
    isAddBreakModalOpen: false
};

export const driverBreaksSlice = createSlice({
    name: 'driverBreaksModal',
    initialState,
    reducers: {
        setIsAddBreakModalOpen: (
            state: DriverBreakState,
            action: PayloadAction<boolean>
        ) => {
            state.isAddBreakModalOpen = action.payload;
        },
        resetDriverBreaksModal: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return initialState;
        });
    }
});

export const { setIsAddBreakModalOpen, resetDriverBreaksModal } =
    driverBreaksSlice.actions;

export const selectIsAddBreakModalOpen = (state: RootState) =>
    state.driverBreaksModal.isAddBreakModalOpen;

export default driverBreaksSlice.reducer;
