import { useEffect } from 'react';
import { ZoneObject } from '~/components/ZoneManagementPage/types';
import { useMapContext } from '~/components/ZoneManagementPage/MapContext';
import { useFetchMainClientAddress } from '~/hooks';
import {
    centerLocationOnMap,
    centerZonesOnMap,
    getZonesCoordinates
} from './utils';

interface Props {
    /**
     * The list of zones
     */
    zonesList?: ZoneObject[];
}
export const useCenterZonesOnMap = ({ zonesList }: Props) => {
    const { mapInstance, mapsApi } = useMapContext();
    const response = useFetchMainClientAddress();
    const { data } = response || {};
    const { data: mainClientAddress } = data || {};
    const { location: mainClientLocation } = mainClientAddress || {};

    useEffect(() => {
        if (!mapsApi || !mapInstance) return;

        const listOfCoordinates = getZonesCoordinates(zonesList);

        if (listOfCoordinates.length) {
            centerZonesOnMap({
                listOfCoordinates,
                mapsApi,
                mapInstance
            });
            return;
        }

        if (mainClientLocation) {
            centerLocationOnMap({
                mapsApi,
                mapInstance,
                location: mainClientLocation
            });
        }
    }, [mapsApi, mapInstance, mainClientLocation, zonesList]);
};
