import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const selectedTaskRowIdSlice = createSlice({
    name: 'selectedTaskRowId',
    initialState: '',
    reducers: {
        setSelectedTaskRowId: (state, action) => {
            return action.payload;
        },
        resetSelectedTaskRowId: () => {
            return '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return '';
        });
    }
});
export const { setSelectedTaskRowId, resetSelectedTaskRowId } =
    selectedTaskRowIdSlice.actions;
export const selectSelectedTaskRowId = (state) => state.selectedTaskRowId;
export default selectedTaskRowIdSlice.reducer;
