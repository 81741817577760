import { PlanStop } from '~/data-classes';
import { idUtils } from '~/utils/id-utils';
import { getTaskId } from '~/utils/stopUtils';

export const areLinkedStops = (
    firstStop: PlanStop,
    secondStop: PlanStop
): boolean => {
    return getTaskId(firstStop) === getTaskId(secondStop);
};

export const adjustTargetIndex = (
    targetIndex: number,
    routePlanStops: PlanStop[]
): number => {
    const targetStop = routePlanStops[targetIndex];
    const previousStop = routePlanStops[targetIndex - 1];
    const nextStop = routePlanStops[targetIndex + 1];

    if (previousStop && areLinkedStops(previousStop, targetStop)) {
        targetIndex -= 1;
    }

    if (nextStop && areLinkedStops(targetStop, nextStop)) {
        targetIndex += 1;
    }

    return targetIndex;
};

export const getTaskIdsByRouteId = (combinedIds: string[]) => {
    const taskIdsByRouteId: Record<string, string[]> = {};
    const idsWithUniqueTaskIds =
        idUtils.getUniqueSingleAndTwoPartTaskIds(combinedIds);

    for (const combinedId of idsWithUniqueTaskIds) {
        const [, routeId, taskId] = idUtils.splitCombinedId(combinedId);
        const ids = taskIdsByRouteId[routeId] || [];

        ids.push(taskId);
        taskIdsByRouteId[routeId] = ids;
    }

    return taskIdsByRouteId;
};
