/** @jsxRuntime classic */
// known bug - see : https://github.com/system-ui/theme-ui/issues/1160
/** @jsx jsx */
import { jsx } from 'theme-ui';
import PT from 'prop-types';

const styles = {
    display: 'block',
    width: '100%',
    height: '100%',
    '@keyframes lds-dual-ring': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        }
    }
};
const Spinner = ({ color = 'comet', sx = {}, ...extra } = {}) => {
    return (
        <div
            sx={{
                ...styles,
                '&::after': {
                    content: '" "',
                    display: 'inline-block',
                    boxSizing: 'border-box',
                    width: '80%',
                    height: '80%',
                    margin: '10%',
                    borderRadius: '50%',
                    border: '0.25rem solid #fff',
                    borderColor: (theme) =>
                        `${theme.colors[color]} transparent`,
                    animation: 'lds-dual-ring 1.2s linear infinite'
                },
                ...sx
            }}
            data-testid={extra['data-testid'] || 'spinner'}
        />
    );
};

Spinner.propTypes = {
    color: PT.string,
    /** Used to additionally style the spinner, e.g. `height` and `width` properties */
    sx: PT.oneOfType([PT.object])
};

export default Spinner;
