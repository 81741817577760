import React, { useEffect, useState, memo } from 'react';
import { Map } from '~/ui';
import RouteMarker from './RouteMarker';

function SimpleMap({
    routes = [],
    filterOutRoutes = {},
    onMarkerHover,
    className
}) {
    const [mapRef, setMapRef] = useState(null);
    const [routeMarkers, setRouteMarkers] = useState([]);

    useEffect(() => {
        if (!routes.length) {
            return;
        }

        const routeMarkerEffect = [];
        routes.forEach((route) => {
            const { hasTasks } = route;
            if (hasTasks) {
                const { markerCoordinates, clientRouteId } = route;
                routeMarkerEffect.push(
                    <RouteMarker
                        key={clientRouteId}
                        clientRouteId={clientRouteId}
                        deselected={filterOutRoutes[clientRouteId]}
                        lat={markerCoordinates.lat}
                        lng={markerCoordinates.lng}
                        onRouteHover={(id, hovered) => onHover(id, hovered)}
                        selectable
                    />
                );
            }
        });
        setRouteMarkers(routeMarkerEffect);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [routes, filterOutRoutes]);

    useEffect(() => {
        if (!mapRef || !routeMarkers.length) {
            return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        routeMarkers.forEach((routeMarker) => {
            bounds.extend({
                lat: routeMarker.props.lat,
                lng: routeMarker.props.lng
            });
        });

        mapRef.fitBounds(bounds);
    }, [mapRef, routeMarkers]);

    function handleApiLoaded({ map }) {
        setMapRef(map);
    }

    function onHover(id, hovered) {
        if (onMarkerHover) {
            return hovered ? onMarkerHover(id) : onMarkerHover(null);
        }
    }

    function getClassName() {
        const compName = 'simplemap';
        return className ? `${className} ${compName}` : compName;
    }

    const mapOptions = {
        onGoogleApiLoaded: handleApiLoaded,
        yesIWantToUseGoogleMapApiInternals: true
    };

    return (
        <Map
            className={getClassName()}
            mode="simple"
            googleMapOptions={mapOptions}
        >
            {routeMarkers}
        </Map>
    );
}

export default memo(SimpleMap);
