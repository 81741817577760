import React from 'react';
import { ColorItem } from '~/ui';

import './ColorPicker.scss';

interface ResetColorItemProps {
    onSelect: (value: string | undefined) => void;
}

const ResetColorItem = ({ onSelect }: ResetColorItemProps) => {
    const rootClassName = 'color-picker';
    return (
        <div className={`${rootClassName}__reset-item`}>
            <button
                type="button"
                className={`${rootClassName}__button`}
                data-testid="reset-color-item-button"
                onClick={() => onSelect(undefined)}
            >
                <span
                    className={`${rootClassName}__reset-color _d-flex _jc-center _ai-center`}
                />
            </button>
            <div className={`${rootClassName}__reset-line`} />
        </div>
    );
};

interface ColorPickerProps {
    /**
     * colors: array of hex values
     */
    colors: string[];
    /**
     * pre-selected color
     */
    selectedColor?: string;
    /**
     * callback when color is selected
     * @param color: string
     */
    onSelect: (color: string | undefined) => void;
    /**
     * Specifies whether to render the option to reset
     */
    allowNoColor?: boolean;
}

export const ColorPicker = ({
    colors,
    selectedColor,
    onSelect,
    allowNoColor = true
}: ColorPickerProps) => {
    const rootClassName = 'color-picker';

    return (
        <div
            data-testid="color-picker"
            className={`${rootClassName}__container`}
        >
            {allowNoColor && <ResetColorItem onSelect={onSelect} />}
            {colors.map((color) => {
                const isSelected = color === selectedColor;
                return (
                    <button
                        key={color}
                        type="button"
                        className={`${rootClassName}__button`}
                        data-testid="color-item-button"
                        onClick={() => {
                            onSelect(color);
                        }}
                    >
                        <ColorItem color={color} isSelected={isSelected} />
                    </button>
                );
            })}
        </div>
    );
};
