import { CompartmentType } from '~/api/types';

type CompartmentHeaders = {
    [key: string]: number;
};

type CompartmentHeadersProps = {
    [key: string]: CompartmentType;
};

const getCompartmentHeaders = (
    compartments: CompartmentHeadersProps
): CompartmentHeaders => {
    return Object.keys(compartments).reduce(
        (aggregator: CompartmentHeaders, id: string, index: number) => {
            const { label, unit } = compartments[id];
            aggregator[`compartment_${label}_${unit}`] = (index + 1) * 10;
            return aggregator;
        },
        {}
    );
};

export default {
    getCompartmentHeaders
};
