import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui/components';
import { EquipmentMarkerProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedEquipmentIds,
    setSelectedEquipmentIds
} from '~/reducers/selectedEquipmentIdsSlice';
import {
    setIsOpenLiveDispatchDrawer,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import { EquipmentMarkerPopup } from '../EquipmentMarkerPopup';
import {
    selectActiveEquipmentClusterId,
    setActiveEquipmentClusterId
} from '~/reducers/mapSettingsSlice';
import { Equipment } from '~/data-classes';
import { EquipmentDetailsPopup } from '../EquipmentDetailsPopup';

import './EquipmentMarker.scss';

export const EquipmentMarker = ({
    onMouseEnterOnMarker,
    onMouseLeaveFromMarker,
    onMarkerClick,
    isCluster,
    markerLabel,
    allEquipmentInCluster,
    numClustered,
    clusterId,
    showMarkerLabel
}: EquipmentMarkerProps) => {
    const selectedEquipmentIds: string[] = useSelector(
        selectSelectedEquipmentIds
    );
    const dispatch = useDispatch();

    const [isSelected, setIsSelected] = useState(false);
    const [showStackPinPopup, setShowStackPinPopup] = useState(false);
    const [showEquipmentMarkerPopup, setShowEquipmentMarkerPopup] =
        useState(false);

    const activeEquipmentClusterId = useSelector(
        selectActiveEquipmentClusterId
    );

    const isThisActiveCluster = activeEquipmentClusterId === clusterId;

    const onEquipmentMarkerClick = useCallback(
        (equipmentId: string) => {
            dispatch(setIsOpenLiveDispatchDrawer(true));
            dispatch(setIsUnassignedTasksTabActive(false));

            if (selectedEquipmentIds.includes(equipmentId)) {
                const existingEquipmentIds = selectedEquipmentIds.filter(
                    (eachId) => eachId !== equipmentId
                );
                dispatch(setSelectedEquipmentIds([...existingEquipmentIds]));
            } else {
                dispatch(
                    setSelectedEquipmentIds([
                        ...selectedEquipmentIds,
                        equipmentId
                    ])
                );
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [selectedEquipmentIds]
    );

    useEffect(() => {
        if (isCluster && isThisActiveCluster) {
            setIsSelected(true);
            return;
        }
        setIsSelected(false);
    }, [isThisActiveCluster, isCluster]);

    const handleClusterMarkerClick = () => {
        const id = showStackPinPopup || !clusterId ? -1 : clusterId;
        dispatch(setActiveEquipmentClusterId(id));
        setShowEquipmentMarkerPopup(false);
    };

    const handleMarkerClick = () => {
        setIsSelected((val) => !val);
        onMarkerClick?.();
        onEquipmentMarkerClick(markerLabel);
        setShowEquipmentMarkerPopup(false);
    };

    useEffect(() => {
        setShowStackPinPopup(isThisActiveCluster);
    }, [isThisActiveCluster]);

    useEffect(() => {
        const isEquipementSelected = selectedEquipmentIds.includes(markerLabel);
        setIsSelected(isEquipementSelected);
    }, [markerLabel, selectedEquipmentIds]);

    const Marker = () => {
        return (
            <div className="marker__wrapper">
                <Icon
                    className="marker__icon"
                    icon="iconBox"
                    color="galaxy-800-alt"
                />
            </div>
        );
    };

    const SingleMarker = () => {
        const elementClassName = classNames('marker', 'marker--single', {
            'marker--selected': isSelected
        });

        return (
            <div
                className={elementClassName}
                data-testid="equipment-marker__single-marker"
            >
                <Marker />
            </div>
        );
    };

    const ClusterMarker = () => {
        const elementClassName = classNames('marker', 'marker--cluster', {
            'marker--selected': isSelected
        });

        return (
            <div
                className={elementClassName}
                data-testid="equipment-marker__cluster-marker"
            >
                <Marker />
                <Marker />
                <span
                    className="equipment-cluster-count"
                    data-testid="equipment-cluster-count"
                >
                    {numClustered}
                </span>
            </div>
        );
    };

    const labelElementClassName = classNames('marker-label', {
        'marker-label--selected': isSelected
    });

    const handleClick = isCluster
        ? handleClusterMarkerClick
        : handleMarkerClick;

    const handleMouseEnterOnMarker = () => {
        onMouseEnterOnMarker?.();
        if (!isCluster) {
            setShowEquipmentMarkerPopup(true);
        }
    };

    const handleMouseLeaveFromMarker = () => {
        onMouseLeaveFromMarker?.();
        setShowEquipmentMarkerPopup(false);
    };

    return (
        <span className="stack-pin-marker">
            {showStackPinPopup &&
                isCluster &&
                Boolean(allEquipmentInCluster?.length) && (
                    <EquipmentMarkerPopup
                        equipment={allEquipmentInCluster as Equipment[]}
                    />
                )}
            {showEquipmentMarkerPopup && (
                <EquipmentDetailsPopup equipmentId={markerLabel} />
            )}
            <button
                type="button"
                className="equipment-marker"
                onMouseEnter={handleMouseEnterOnMarker}
                onMouseLeave={handleMouseLeaveFromMarker}
                onClick={handleClick}
                data-testid="equipment-marker"
            >
                {!isCluster && <SingleMarker />}
                {isCluster && <ClusterMarker />}
                {showMarkerLabel && !isCluster && (
                    <div
                        className={labelElementClassName}
                        data-testid="equipment-marker__marker-label"
                    >
                        {markerLabel}
                    </div>
                )}
            </button>
        </span>
    );
};
