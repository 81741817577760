import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import { keyBy } from 'lodash';

import { CompartmentInput } from '~/hooks/useMultiCompartmentInputs';
import { FormikMultiCompartmentInputs, TooltipButton, useModal } from '~/ui';

import './CompartmentEditModal.scss';

type CompartmentDataType = {
    id: string;
    label: string;
    unit: string;
    capacity: string;
};
interface CompartmentEditModalProps {
    open: boolean;
    onCancel: () => void;
    onSave?: (compartments: Record<string, CompartmentInput>) => void;
    compartmentsData?: CompartmentDataType[];
}
export default function CompartmentEditModal({
    open,
    onCancel,
    onSave,
    compartmentsData
}: CompartmentEditModalProps) {
    const { t } = useTranslation(['common', 'driverManagement']);
    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onHide: () => {
            onCancel();
        },
        onShow: () => {}
    });

    const formattedCompartmentsData = compartmentsData?.map(
        (compartment, index) => {
            const componentId = uuid();
            return {
                componentId,
                capacity: compartment.capacity || '',
                label: compartment.label || '',
                selectedCompartmentId: compartment.id,
                unit: compartment.unit || '',
                isFirst: index === 0
            };
        }
    );

    const compartmentsInitialValues = {
        compartments: keyBy(formattedCompartmentsData, 'componentId')
    };

    useEffect(() => {
        return open ? showModal() : hideModal();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [open]);

    return (
        <Modal
            className="compartment-edit-modal"
            variant="small"
            title={t(
                'driverManagement:driverVehicleDrawer.compartmentEditModal.title'
            )}
            hasAutohide={false}
            hasCloseButton
        >
            <Formik
                initialValues={compartmentsInitialValues}
                onSubmit={(values) => {
                    if (onSave) onSave(values.compartments);
                }}
            >
                {({ dirty }) => (
                    <div
                        className="compartment-edit-modal__wrapper _fd-column _jc-space-between"
                        data-testid="compartment-edit-modal"
                    >
                        <Form>
                            <div
                                className="compartment-edit-modal__body"
                                data-testid="compartment-edit-modal-body"
                            >
                                <FormikMultiCompartmentInputs
                                    title={t(
                                        'driverManagement:driverVehicleDrawer.compartmentEditModal.multiCompartmentInputsTitle'
                                    )}
                                />
                            </div>
                            <footer
                                className="compartment-edit-modal__footer _d-flex _ai-center _jc-flex-end _p-4"
                                data-testid="compartment-edit-modal-footer"
                            >
                                <TooltipButton
                                    variant="secondary"
                                    size="l"
                                    onClick={hideModal}
                                >
                                    {t('common:cancel')}
                                </TooltipButton>
                                <TooltipButton
                                    variant="primary"
                                    size="l"
                                    disabled={!dirty}
                                    isSubmit
                                >
                                    {t('common:save')}
                                </TooltipButton>
                            </footer>
                        </Form>
                    </div>
                )}
            </Formik>
        </Modal>
    );
}
