import React, { createContext, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ProcessIndicator from '~/ui/components/ProcessIndicator/ProcessIndicator';
import {
    addProcessIndicator,
    selectProcessIndicator
} from '~/reducers/processIndicatorSlice';

import { useUndoRouteEdit } from '~/hooks';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';

import constants from '~/utils/constants';

const ProcessIndicatorContext = createContext();

export default ProcessIndicatorContext;

/**
 * Provides the `ProcessIndicatorContext` and also renders the `ProcessIndicator` with the `children`
 */
function ProcessIndicatorProvider({ children }) {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const processIndicatorInstance = useSelector(selectProcessIndicator);
    const { getUndoButton } = useUndoRouteEdit();
    const undoButton = getUndoButton(processIndicatorInstance.type);
    const { visibleOnMapRouteData } = usePlanMapPropsContext();
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerRouteId)
    );

    const {
        REASSIGN_STOP,
        REASSIGN_LIVE_STOP,
        UNASSIGN_STOP,
        RESEQUENCE_STOP,
        REASSOCIATE_DRIVER_VEHICLE,
        OPTIMIZE_ROUTE,
        APPEND_ROUTE,
        CHANGE_DRIVER,
        ADD_BREAK,
        DELETE_BREAK,
        RESEQUENCE_BREAK,
        EDIT_BREAK_DURATION,
        UPDATE_BREAK_RULES
    } = constants.processIndicator;

    const {
        isProcessIndicatorVisible,
        processIndicatorType,
        processIndicatorPayload
    } = useContext(ProcessIndicatorContext);

    const getUpdatedProcessIndicator = () => {
        const newProcessIndicatorState = {
            type: processIndicatorType,
            inProgress: false,
            error: false,
            position: 'center'
        };
        switch (processIndicatorType) {
            case REASSIGN_LIVE_STOP:
            case REASSIGN_STOP:
                return {
                    ...newProcessIndicatorState,
                    message: t('ReassignedStop', {
                        count: processIndicatorPayload
                    })
                };
            case UNASSIGN_STOP:
                return {
                    ...newProcessIndicatorState,
                    message: t('StopUnassigned', {
                        count: processIndicatorPayload
                    })
                };
            case RESEQUENCE_STOP:
                return {
                    ...newProcessIndicatorState,
                    message: t('StopResequenced', {
                        count: processIndicatorPayload
                    })
                };
            case REASSOCIATE_DRIVER_VEHICLE:
                return {
                    ...newProcessIndicatorState,
                    message: t(
                        'ChangeDriverVehicleControl.progress.ReassociatedDriverVehicle'
                    )
                };
            case APPEND_ROUTE:
                return {
                    ...newProcessIndicatorState,
                    message: t(
                        'ChangeDriverVehicleControl.progress.AppendedRoute'
                    )
                };
            case CHANGE_DRIVER:
                return {
                    ...newProcessIndicatorState,
                    message: t(
                        'ChangeDriverVehicleControl.progress.ChangedDriver'
                    )
                };
            case OPTIMIZE_ROUTE:
                return {
                    ...newProcessIndicatorState,
                    message: t('OptimizedRoute', {
                        count: processIndicatorPayload
                    })
                };
            case ADD_BREAK:
                return {
                    ...newProcessIndicatorState,
                    message: t('progress.AddedBreak', { ns: 'driverBreak' })
                };
            case DELETE_BREAK:
                return {
                    ...newProcessIndicatorState,
                    message: t('progress.DeletedBreak', { ns: 'driverBreak' })
                };
            case RESEQUENCE_BREAK:
                return {
                    ...newProcessIndicatorState,
                    message: t('progress.ResequencedBreak', {
                        ns: 'driverBreak'
                    })
                };
            case EDIT_BREAK_DURATION:
                return {
                    ...newProcessIndicatorState,
                    message: t('progress.EditedBreakDuration', {
                        ns: 'driverBreak'
                    })
                };
            case UPDATE_BREAK_RULES:
                return {
                    ...newProcessIndicatorState,
                    message: t('progress.UpdatedBreakRules', {
                        ns: 'driverBreak'
                    })
                };
            default:
                return newProcessIndicatorState;
        }
    };

    // Update process indicator effect
    useEffect(() => {
        if (
            isProcessIndicatorVisible() &&
            !constants.externalProcessIndicators[processIndicatorType]
        ) {
            const newProcessIndicatorState = getUpdatedProcessIndicator();
            setTimeout(() => {
                dispatch(addProcessIndicator(newProcessIndicatorState));
            }, constants.timings.PROCESS_INDICATOR);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [visibleOnMapRouteData, serialSelectedLiveDriver]);

    return (
        <BaseProcessIndicatorProvider>
            {children}
            <ProcessIndicator
                message={processIndicatorInstance.message}
                promiseInProgress={processIndicatorInstance.inProgress}
                promiseRejected={processIndicatorInstance.error}
                position={processIndicatorInstance.position}
                undoButton={undoButton}
            />
        </BaseProcessIndicatorProvider>
    );
}

/**
 * Provides the `ProcessIndicatorContext` without rendering `ProcessIndicator`
 */
export function BaseProcessIndicatorProvider({ children }) {
    const processIndicatorInstance = useSelector(selectProcessIndicator);

    function isProcessIndicatorVisible() {
        return processIndicatorInstance.inProgress;
    }

    return (
        <ProcessIndicatorContext.Provider
            value={{
                isProcessIndicatorVisible,
                processIndicatorType: processIndicatorInstance.type,
                processIndicatorPayload: processIndicatorInstance.payload
            }}
        >
            {children}
        </ProcessIndicatorContext.Provider>
    );
}

export { ProcessIndicatorProvider };
