import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import constants from '~/utils/constants';

import { useMapUtils } from '~/hooks';
import DriverApi from '~/api/DriverApi';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { DriverReportData } from '~/api/types';

export const useDriverReport = (driverId: string | undefined) => {
    const routeDate = useSelector(selectDateOnly);
    const { isPlanRouteMode } = useMapUtils();

    return useQuery(
        [
            constants.reactQueryKeys.DRIVER_REPORT,
            { driverId, routeDate, isPlanRouteMode }
        ],
        async () => {
            if (isPlanRouteMode || !driverId || !routeDate) return;

            try {
                const response = await DriverApi.getDriverReport(
                    routeDate,
                    driverId
                );
                return response.data.data as unknown as DriverReportData;
            } catch (error) {
                console.error(error);
            }
        }
    );
};
