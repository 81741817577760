import { useTranslation } from 'react-i18next';
import { TaskTypes } from '~/api/types';

interface Props {
    /**
     * The stop
     */
    stop: {
        /**
         * Whether the stop is a delivery task
         */
        isDelivery?: boolean;
        /**
         * Whether the stop is a pickup task
         */
        isPickup?: boolean;
        /**
         * The task type
         */
        type?: string;
    };
}
export const useGetTaskTypeText = ({ stop }: Props) => {
    const { t } = useTranslation('common');
    const { isDelivery, isPickup, type } = stop;

    if (isDelivery || type === TaskTypes.DELIVERY) {
        return t('taskType.delivery');
    }

    if (isPickup || type === TaskTypes.PICKUP) {
        return t('taskType.pickup');
    }

    return undefined;
};
