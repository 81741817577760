import React from 'react';
import { useTranslation } from 'react-i18next';
import './UndoButton.scss';

type Props = {
    clickHandler?: () => void;
};

export const UndoButton = ({ clickHandler }: Props) => {
    const { t } = useTranslation('common');

    return (
        <div
            className="_d-flex undobutton _ai-center _jc-center"
            data-testid="undobutton"
        >
            <button
                className="_text-2"
                type="button"
                onClick={clickHandler}
                data-testid="undobutton-button"
            >
                {t('undo')}
            </button>
        </div>
    );
};
