import { TimeWindow } from '~/api/types';

/**
 * adds extra date to delivery start if pickup start is after pickup start
 * adds extra date to delivery end if pickup start is after pickup start
 * @param pickupWindow
 * @param deliveryWindow
 * @returns deliveryWindow with start and end after pickupWindow start and end
 */
export const addExtraDateToDeliveryWindow = (
    pickupWindow: TimeWindow[],
    deliveryWindow: TimeWindow[]
): TimeWindow[] => {
    const { start: pickupStart, end: pickupEnd } = pickupWindow?.[0] || {};
    return (
        deliveryWindow?.map((window) => {
            let { start: deliveryStart, end: deliveryEnd } = window;

            if (deliveryStart && pickupStart && deliveryStart < pickupStart) {
                const startDate = new Date(deliveryStart);
                startDate.setDate(startDate.getDate() + 1);
                deliveryStart = startDate.toISOString();
            }

            if (deliveryEnd && pickupEnd && deliveryEnd < pickupEnd) {
                const endDate = new Date(deliveryEnd);
                endDate.setDate(endDate.getDate() + 1);
                deliveryEnd = endDate.toISOString();
            }

            return {
                start: deliveryStart,
                end: deliveryEnd
            };
        }) || []
    );
};
