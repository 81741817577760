import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useZones, useZoneVehicleAssociations } from '~/hooks';

import { Icon, IconButton, Tooltip } from '~/ui';
import ZoneEditModal from '~/components/ZoneManagementPage/ZoneList/ZoneEditModal';
import AssignedVehicleItem from '~/components/ZoneManagementPage/ZoneList/AssignedVehicleItem';
import { usePageContext } from '~/components/ZoneManagementPage';

import './ListItem.scss';

export const ListItem = ({ zone, assignedVehicles = [] }) => {
    const { t } = useTranslation('zoneManagement');
    const { deleteZone, editZone } = useZones();
    const { deleteVehicleAssociation, createVehicleAssociation } =
        useZoneVehicleAssociations();
    const { activeZoneId, toggleActiveZoneId } = usePageContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const {
        id,
        properties: { name }
    } = zone;

    const deleteSelectedZone = () => {
        try {
            deleteZone(id);
        } catch (error) {
            console.error(error);
        }
    };

    const saveVehicles = async (newVehicles) => {
        const addedVehicles = _.difference(newVehicles, assignedVehicles);
        const unassignedVehicles = _.difference(assignedVehicles, newVehicles);
        unassignedVehicles.forEach(async (vehicleId) => {
            await deleteVehicleAssociation({ zoneId: id, vehicleId });
        });
        const associationsToCreate = addedVehicles.map((vId) => {
            return {
                zoneId: id,
                vehicleId: vId
            };
        });
        if (associationsToCreate.length) {
            await createVehicleAssociation(associationsToCreate);
        }
    };

    const handleZoneEditSave = async ({
        name: zoneName,
        assignedVehicles: newAssignedVehicles
    }) => {
        const isNameChanged = zoneName !== name;
        try {
            if (isNameChanged) {
                await editZone({ zoneId: id, update: { name: zoneName } });
            }
            await saveVehicles(newAssignedVehicles);
            setIsEditing(false);
        } catch (e) {
            console.error(e);
        }
    };

    const handleVehicleUnassign = (vehicleId) => {
        deleteVehicleAssociation({ zoneId: id, vehicleId });
    };

    const getAssignedVehiclesClassNames = () => {
        return classNames(
            '_d-flex _fd-column _mt-1 _mb-1 assignedVehiclesContainer',
            {
                hidden: !isExpanded
            }
        );
    };

    const onListItemClicked = () => {
        toggleActiveZoneId(id);
    };

    const isActive = activeZoneId === id;

    return (
        <>
            <div
                className="zonemanagement-zonelist-item-container"
                onKeyDown={onListItemClicked}
                role="button"
                tabIndex={0}
                onClick={onListItemClicked}
                data-testid="zone-management-list-item-container"
            >
                <div
                    className={classNames(
                        'zonemanagement-zonelist-item _d-flex _ai-center',
                        {
                            __active: isActive
                        }
                    )}
                    data-testid="zone-management-list-item"
                >
                    <IconButton
                        icon="chevronDown"
                        iconColor="galaxy-800"
                        className="listitem-expandicon"
                        onClick={() => setIsExpanded(!isExpanded)}
                        data-testid="list-expand-button"
                    />
                    <span
                        className="zonemanagement-zonelist-item-name _text-1 _ml-1"
                        data-testid="zone-management-list-item-name"
                    >
                        {name}
                    </span>
                    <span
                        className="zonemanagement-zonelist-item-vehicles _jc-center _ai-center"
                        data-testid="zone-management-list-item-vehicles"
                    >
                        <Icon icon="vehicleEmpty" />
                        <span>&nbsp;{assignedVehicles.length}</span>
                    </span>
                    <div
                        className="zonelistitem-buttongroup _pr-4"
                        data-testid="zonelistitem-buttongroup"
                    >
                        <span
                            className="_jc-center _ai-center zonemanagement-zonelist-item-delete"
                            data-testid="zone-management-list-item-edit"
                        >
                            <Tooltip
                                placement="top"
                                content={t('zoneItem.editZone')}
                            >
                                <Icon
                                    icon="editPencil"
                                    data-testid="edit-icon"
                                    size="s"
                                    onClick={() => setIsEditing(true)}
                                />
                            </Tooltip>
                        </span>
                        <span
                            className="_jc-center _ai-center zonemanagement-zonelist-item-delete"
                            data-testid="zone-management-list-item-delete"
                        >
                            <Tooltip
                                placement="top"
                                content={t('zoneItem.deleteZone')}
                            >
                                <Icon
                                    icon="delete"
                                    data-testid="delete-icon"
                                    onClick={deleteSelectedZone}
                                />
                            </Tooltip>
                        </span>
                        <span
                            className="_jc-center _ai-center zonemanagement-zonelist-item-delete"
                            data-testid="zone-management-list-item-add"
                        >
                            <Tooltip
                                placement="top"
                                content={t('zoneItem.assignVehicles')}
                            >
                                <Icon
                                    icon="addLight"
                                    data-testid="add-icon"
                                    size="s"
                                    onClick={() => setIsEditing(true)}
                                />
                            </Tooltip>
                        </span>
                    </div>
                </div>
                <div className={getAssignedVehiclesClassNames()}>
                    {assignedVehicles.map((vehicleId) => {
                        return (
                            <AssignedVehicleItem
                                key={`${vehicleId}-${id}`}
                                id={vehicleId}
                                onDelete={handleVehicleUnassign}
                            />
                        );
                    })}
                </div>
            </div>
            {isEditing && (
                <ZoneEditModal
                    onCancel={() => {
                        setIsEditing(false);
                    }}
                    onSave={handleZoneEditSave}
                    open={isEditing}
                    zone={{ name, id, assignedVehicles }}
                />
            )}
        </>
    );
};
