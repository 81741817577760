import { useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectCompletedDrivers } from '~/reducers/liveDriversSlice';
import { useEffect, useState } from 'react';
import makeCompletedComponents from '~/utils/map-modes/completed-routes-mode';

import { Coordinates } from '~/api/types';

interface UseCompletedRouteMarkersReturnValue {
    routeMarkers: JSX.Element[];
    routeMarkerCoordinates: Coordinates[];
}

const useCompletedRouteMarkers = (): UseCompletedRouteMarkersReturnValue => {
    const { mapRouteMode, isCompletedRouteMode } = useMapUtils();
    const selectedDate = useSelector(selectDateOnly);
    const completedDrivers = useSelector(selectCompletedDrivers);

    const [routeMarkers, setCompletedRouteMarkers] = useState<JSX.Element[]>(
        []
    );
    const [routeMarkerCoordinates, setCompletedRouteMarkerCoordinates] =
        useState<Coordinates[]>([]);

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        setCompletedRouteMarkers([]);
        setCompletedRouteMarkerCoordinates([]);
    }, [selectedDate]);

    useEffect(() => {
        if (!isCompletedRouteMode) {
            return;
        }
        const {
            completedRouteMarkers: newCompletedRouteMarkers,
            completedRouteMarkerCoordinates
        } = makeCompletedComponents(completedDrivers);
        setCompletedRouteMarkers(
            newCompletedRouteMarkers as unknown as JSX.Element[]
        );
        setCompletedRouteMarkerCoordinates(completedRouteMarkerCoordinates);
    }, [mapRouteMode, isCompletedRouteMode, completedDrivers]);

    return {
        routeMarkers,
        routeMarkerCoordinates
    };
};

export default useCompletedRouteMarkers;
