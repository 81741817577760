/**
 * Enum of supported tooltip button variants
 */
export enum TooltipButtonVariants {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    LINK = 'link',
    DANGER = 'danger',
    DANGER_SECONDARY = 'danger-secondary',
    DANGER_LINK = 'danger-link'
}

export type TooltipButtonVariant = `${TooltipButtonVariants}`;
