import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalHeader from '~/components/MapPage/ModalHeader';

export const ResequenceModalHeader = ({ numberOfStops = 0, clickHandler }) => {
    const { t } = useTranslation('translation');
    const title = t('ResequenceModal.Header.title', {
        count: numberOfStops
    });

    return (
        <ModalHeader
            className="resequencemodal-header"
            onClose={clickHandler}
            title={title}
        />
    );
};
