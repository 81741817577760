import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const forecastSlice = createSlice({
    name: 'forecast',
    initialState: {},
    reducers: {
        setForecast: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setForecast } = forecastSlice.actions;

export const selectForecast = (state) => state.forecast;

export default forecastSlice.reducer;
