const palette = {
    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',

    // primary colors
    milkyway: '#1F2923',
    ocean: '#5EA2FF',
    comet: '#FFFFFF',
    sky: '#82B5FF',
    mars: '#FF5670',
    earth: '#41BFAC',
    venus: '#FF824A',
    saturn: '#FFC775',
    neptune: '#316DBE',
    aurora: '#3FA1B8',
    meteor: '#3282FF',

    // neutral colors
    'galaxy-50': '#F6F8FB',
    'galaxy-80': '#C0C0C0',
    'galaxy-100': '#F0F4F8',
    'galaxy-200': '#D9E2EC',
    'galaxy-300': '#BCCCDC',
    'galaxy-400': '#A6B6C5',
    'galaxy-500': '#6E7882',
    'galaxy-500-alt': '#727A82',
    'galaxy-600': '#486581',
    'galaxy-700': '#334E68',
    'galaxy-800': '#09101A',
    'galaxy-800-alt': '#101214',

    // supporting colors
    'mars-20': '#680012',
    'mars-50': '#FFEDEB',
    'mars-100': '#FFE5E9',
    'mars-200': '#FFBDBD',
    'mars-300': '#FF9B9B',
    'mars-400': '#FF5670',
    'mars-500': '#E12D39',
    'mars-600': '#CF1124',
    'mars-700': '#AB091E',
    'mars-800': '#610316',

    'earth-30': '#00531d',
    'earth-60': '#00A742',
    'earth-100': '#EFFCF6',
    'earth-200': '#C6F7E2',
    'earth-250': '#C8E8EF',
    'earth-300': '#8EEDC7',
    'earth-400': '#41BFAC',
    'earth-500': '#54A39B',
    'earth-600': '#317D7C',
    'earth-700': '#0D645F',
    'earth-800': '#014D40',
    'earth-900': '#008a35',

    'venus-100': '#FFF4EF',
    'venus-200': '#FFDCCD',
    'venus-300': '#FFB290',
    'venus-400': '#FF824A',
    'venus-500': '#F35627',
    'venus-600': '#DE3A11',
    'venus-700': '#AD1D07',
    'venus-800': '#841003',

    'saturn-60': '#D98E0B',
    'saturn-100': '#FFFBF5',
    'saturn-200': '#FFECCF',
    'saturn-300': '#FDD8A3',
    'saturn-400': '#FFC775',
    'saturn-500': '#FFB140',
    'saturn-800': '#8C5E1E',

    'neptune-100': '#F3F8FF',
    'neptune-200': '#DFECFF',
    'neptune-300': '#4191FF',
    'neptune-400': '#316DBE',
    'neptune-500': '#295FA7',
    'neptune-800': '#192949',
    'neptune-900': '#d4bdfc',
    'neptune-950': '#9747ff',

    'aurora-100': '#F5FDFF',
    'aurora-200': '#BFE9F3',
    'aurora-300': '#7CBFCF',
    'aurora-400': '#3FA1B8',
    'aurora-500': '#198BA6',

    'ocean-500': '#478AE5',

    'meteor-60': '#A4CAFF',
    'meteor-70': '#A7AFB8',
    'meteor-80': '#AEC9FF',
    'meteor-90': '#D4E5FF',
    'meteor-95': '#EFF5FF',

    overlay: 'rgba(9, 16, 26, 0.2)' // galaxy-800, 20%
};

export default {
    palette
};
