import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '~/ui';
import { AlertVariants } from './AlertVariants';
import './alert.scss';

function Alert({
    variant,
    icon,
    iconColor = 'galaxy-400',
    iconSize = 's',
    className,
    children,
    ...extra
}) {
    function _getClassName() {
        const defaultClassName = 'alert _d-flex';
        const conditionalClasses = {
            'alert_has-icon': icon,
            [`alert_${variant}`]: variant
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'alert'}
        >
            {icon && (
                <Icon
                    icon={icon}
                    color={iconColor}
                    size={iconSize}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_icon`
                            : 'alert_icon'
                    }
                />
            )}
            <div
                className="alert_details"
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}_details`
                        : 'alert_details'
                }
            >
                {children}
            </div>
        </div>
    );
}

Alert.variants = AlertVariants;

Alert.propTypes = {
    /** Used to set the alert variant */
    variant: PropTypes.oneOf(Object.values(AlertVariants)),
    /** Used to set additional class names to the alert */
    className: PropTypes.string,
    /** Optional icon */
    icon: PropTypes.string,
    /** icon size */
    iconSize: PropTypes.string,
    /** icon fill color */
    iconColor: PropTypes.string
};

export default Alert;
