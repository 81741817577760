import { useSelector } from 'react-redux';

import { selectBreakDetails } from '~/reducers/selectedBreakDetailsSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';

type ReturnProps = {
    date: string;
    breakRuleId: string;
    clientId: string;
    routeId: string;
};

export const useGetParams = (): ReturnProps => {
    const date = useSelector(selectDateOnly) as string;
    const selectedBreakDetail = useSelector(selectBreakDetails);
    const { data: selectedDrawerCardData } =
        useSelector(selectSelectedDrawerCardData) ?? {};
    const { client: { clientId = '' } = {} } = (selectedDrawerCardData ??
        {}) as {
        client: { clientId: string };
    };

    return {
        date,
        breakRuleId: selectedBreakDetail?.id as string,
        clientId,
        routeId: selectedBreakDetail?.routeId as string
    };
};
