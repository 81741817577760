import React, { useEffect, useState, useMemo } from 'react';
import { markerUtils } from '~/utils/map';
import { PlanStop } from '~/data-classes';
import { useMapUtils } from '~/hooks';
import { useRoutePlanStopEffects } from '~/components/MapPage/PlanMap/useRoutePlanStopEffects';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { useSelector } from 'react-redux';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { DepotMarker } from '~/ui';
import { Coordinates } from '~/api/types';

type UseDepotsReturnValue = {
    depots: PlanStop[];
    depotMarkers: JSX.Element[];
};

const useDepots = (): UseDepotsReturnValue => {
    const { isStopsClustersMode, isRoutesMode, mapMarkerMode, mapRouteMode } =
        useMapUtils();
    const [depots, setDepots] = useState<PlanStop[]>([]);

    function makeDepotMarkers(depotElements: PlanStop[]) {
        return depotElements.map(
            (depotPlanStop: {
                taskId: string;
                markerCoordinates: Coordinates;
            }) => {
                const {
                    taskId,
                    markerCoordinates: { lat, lng }
                } = depotPlanStop;
                return <DepotMarker key={taskId} lat={lat} lng={lng} />;
            }
        );
    }

    const depotMarkers = useMemo(() => {
        return makeDepotMarkers(depots);
    }, [depots]);

    const {
        // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        routesLevelData
    } = usePlanMapPropsContext();

    const {
        getParentClientRouteId,
        clientRouteIds: clientRouteIdsForSelectedMapRoutes,
        selectedMapRoutes
    } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { visibleOnMapPlanStops } = useRoutePlanStopEffects({
        getParentClientRouteId,
        clientRouteIdsForSelectedMapRoutes
    });
    const selectedDate = useSelector(selectDateOnly);

    useEffect(() => {
        setDepots([]);
    }, [selectedDate, isStopsClustersMode, selectedMapRoutes]);

    useEffect(() => {
        if (!isStopsClustersMode) {
            return;
        }
        const depotsEffect = [];
        for (const planStopRoute of visibleOnMapPlanStops) {
            for (const planStop of planStopRoute) {
                if (planStop.isDepot) {
                    const isUnique = markerUtils.checkIsDepotUnique(
                        depotsEffect,
                        planStop
                    );
                    if (isUnique) {
                        depotsEffect.push(planStop);
                    }
                }
            }
        }
        setDepots(depotsEffect);
    }, [
        visibleOnMapPlanStops,
        isStopsClustersMode,
        mapMarkerMode,
        mapRouteMode,
        isRoutesMode
    ]);

    return {
        depots,
        depotMarkers
    };
};

export default useDepots;
