import { usePopperTooltip } from 'react-popper-tooltip';
import React from 'react';
import { StopMarkerLabelsTooltip } from './StopMarkerLabelsTooltip';

interface UseStopMarkerLabelsTooltipProps {
    labels: string[];
}

export const useStopMarkerLabelsTooltip = ({
    labels
}: UseStopMarkerLabelsTooltipProps) => {
    const placement = 'right';
    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
        usePopperTooltip({
            placement
        });
    const isShowContent = Boolean(labels.length && visible);
    const labelsTooltip = isShowContent ? (
        <StopMarkerLabelsTooltip
            labels={labels}
            setTooltipRef={setTooltipRef}
            popperTooltipProps={getTooltipProps()}
        />
    ) : null;

    return {
        labelsTooltip,
        setTriggerRef
    };
};
