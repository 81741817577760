import React from 'react';
import _ from 'lodash';

import { markerMaker } from '~/utils/map';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import { RouteLine } from '~/ui';
import { planRouteUtils } from '~/utils/plan-route-utils';
import { idUtils } from '~/utils/id-utils';
import { routeHasMultipleTrips } from '~/utils/tripUtils';

const makeRouteLine = ({
    allPlannedStops,
    clientId,
    driverId,
    mapInstance,
    routeId
}) => {
    const routeStops = _.sortBy(
        allPlannedStops.filter((stop) => stop.routeId === routeId),
        'stopNumber'
    );

    const [firstStop] = routeStops;
    const liveDriverJSON = firstStop && {
        clientId,
        id: driverId,
        stats: {
            currentStop: 0
        },
        cepLocation: firstStop.markerCoordinates,
        schedule: routeStops.map(({ isDepot, markerCoordinates }) => ({
            isDepot,
            location: {
                location: markerCoordinates
            }
        }))
    };

    if (!liveDriverJSON || !mapInstance) return null;

    const liveDriver = new LiveDriver(liveDriverJSON);
    const key = idUtils.getCombinedId(clientId, routeId, liveDriver.id);

    return (
        <RouteLine
            key={key}
            colorCSS={firstStop._colorCSS}
            driver={liveDriver}
            mapInstance={mapInstance}
        />
    );
};

export const makeRouteLines = ({ allPlannedStops, mapInstance, routePlans }) =>
    routePlans
        .filter((routePlan) => routePlan.isPlanned)
        .flatMap((routePlan) => {
            const { clientId, clientRouteId, driverId, routeId, trips } =
                routePlan;

            if (!routeHasMultipleTrips(trips)) {
                return makeRouteLine({
                    allPlannedStops,
                    clientId,
                    driverId,
                    mapInstance,
                    routeId
                });
            }

            const { clientRouteIds } = planRouteUtils.getSelectedMapRoutes({
                planRoutes: [routePlan],
                selectedMapRoutes: [clientRouteId]
            });
            const routePlanStops = allPlannedStops.filter((stop) =>
                clientRouteIds.includes(stop.clientRouteId)
            );
            const schedulesByTrip =
                planRouteUtils.splitScheduleByTrips(routePlanStops);

            return schedulesByTrip.map((tripPlanStops) => {
                const { routeId: tripRouteId } = tripPlanStops[0];

                return makeRouteLine({
                    clientId,
                    driverId,
                    mapInstance,
                    allPlannedStops: tripPlanStops,
                    routeId: tripRouteId
                });
            });
        })
        .filter(Boolean);

function makeMarkers(
    superCluster,
    geoJSONFeatures,
    emittedEventHandler,
    coordinatesOffset,
    t
) {
    const markers = [];
    for (const geoJSONFeature of geoJSONFeatures) {
        const isCluster = geoJSONFeature.properties.cluster;
        if (isCluster) {
            const leaves = superCluster.getLeaves(
                geoJSONFeature.id,
                Infinity,
                0
            );
            for (const leaf of leaves) {
                const marker = markerMaker.makeStopMarker(
                    leaf,
                    {},
                    emittedEventHandler,
                    t
                );
                markers.push(marker);
            }
        } else {
            const marker = markerMaker.makeStopMarker(
                geoJSONFeature,
                coordinatesOffset,
                emittedEventHandler,
                t
            );
            markers.push(marker);
        }
    }
    return markers;
}

export default makeMarkers;
