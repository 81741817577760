import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { selectDriverVehiclePairs } from '~/reducers/driversByClientSlice';
import { selectVehicles } from '~/reducers/vehiclesSlice';
import { Icon, IconButton } from '~/ui';
import driverUtils from '~/utils/driver-utils';

import './AssignedVehicleItem.scss';

const AssignedVehicleItem = ({
    id,
    onClick = () => {},
    onDelete,
    icon,
    className = ''
}) => {
    const { t } = useTranslation([
        'driverManagement',
        'zoneManagement',
        'common'
    ]);

    const vehiclesData = useSelector(selectVehicles);
    const vehicleDriverPairs = useSelector(selectDriverVehiclePairs);
    const assignedDriver = vehicleDriverPairs[id];

    const vehicle = vehiclesData[id];

    const getContainerClassName = () => {
        const baseClasses =
            '_d-flex _ai-center _p-relative zone-management-list-item-vehicle-container';
        return classNames(baseClasses, className);
    };

    if (!vehicle) {
        return null;
    }

    const { eid = '', type = '' } = vehicle;
    const { profile: { name: driverName } = {} } = assignedDriver || {};
    const localizedDriverName = driverName
        ? driverUtils.getLocalizedDriverName(driverName)
        : t('common:N/A');

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => onClick(id)}
            onKeyDown={() => onClick(id)}
            data-testid="zone-management-list-item-vehicle"
            className={getContainerClassName()}
        >
            {icon && <Icon data-testid="associatedvehicle-icon" icon={icon} />}
            <span className="_d-flex _fd-column associatedvehicle-info ">
                <span className="associatedvehicle-name">
                    {eid || t('common:N/A')}
                </span>
                <span className="_d-flex _fd-row associatedvehicle-details">
                    <span className="_d-flex _fd-row _ai-center associatedvehicle-detail">
                        <Icon icon="vehicleEmpty" color="galaxy-400" />
                        {t(`vehicleTypes.${type}`, type)}
                    </span>
                    {assignedDriver && (
                        <span
                            className="_fd-row _ai-center associatedvehicle-detail"
                            data-testid="associatedvehicle-driver"
                        >
                            <Icon icon="userLine" color="galaxy-400" />
                            {localizedDriverName}
                        </span>
                    )}
                </span>
            </span>
            {onDelete && (
                <IconButton
                    tooltipMessage={t(
                        'zoneManagement:zoneItem.unassignVehicle'
                    )}
                    tooltipPlacement="top"
                    icon="cancel"
                    iconColor="mars-600"
                    className="associated-vehicle__delete _p-absolute"
                    onClick={() => onDelete(id)}
                    data-testid="associatedvehicle-unassign"
                />
            )}
        </div>
    );
};

export default AssignedVehicleItem;
