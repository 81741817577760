import { useState, useEffect } from 'react';

/**
 * Checks if the app is embedded in an iframe,
 * used to adapt display for white-labeling
 *
 * @category Hooks
 * @module useIsEmbeddedInIframe
 * @returns {boolean}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useIsEmbeddedInIframe } from '~/hooks';
 *
 * // expose methods
 * const isWhiteLabeled = useIsEmbeddedInIframe();
 *
 */
export const useIsEmbeddedInIframe = (): boolean => {
    const [isEmbedded, setIsEmbedded] = useState(false);

    useEffect(() => {
        if (window.self !== window.top) setIsEmbedded(true);
    }, []);

    return isEmbedded;
};
