import axios from 'axios';
import { ApiAddress, AxiosApiResponse } from './types';

/**
 * Implementations of API methods under the /address path
 *
 * @category API
 */
export default class AddressApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/address';

    static get(addressId: string): Promise<AxiosApiResponse<ApiAddress>> {
        if (!addressId) {
            return Promise.reject('address id not provided');
        }
        return axios.get(`${this.path}/${addressId}`);
    }

    static update(
        addressId: string,
        payload: ApiAddress
    ): Promise<AxiosApiResponse<ApiAddress>> {
        if (!addressId) {
            return Promise.reject('address id not provided');
        }
        return axios.put(`${this.path}/${addressId}`, { ...payload });
    }

    static post(payload: ApiAddress): Promise<AxiosApiResponse<ApiAddress>> {
        return axios.post(this.path, payload);
    }
}
