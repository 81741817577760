import { MutableRefObject, useEffect } from 'react';
import { Coordinates } from '~/api/types';
import useCompletedRouteMarkers from './useCompletedRouteMarkers';
import useCompletedRouteStopMarkersAndLines from './useCompletedRouteStopMarkersAndLines';

type UseCompletedModeMapMarkersProps = {
    stopLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    completedRoutesCoordinatesRef: MutableRefObject<Coordinates[]>;
};

interface UseCompletedModeMapMarkersReturnValue {
    routeMarkers: JSX.Element[];
    routeStopMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    depotStopMarkers: JSX.Element[];
}

const useCompletedModeMapMarkers = ({
    stopLevelCoordinatesRef,
    completedRoutesCoordinatesRef
}: UseCompletedModeMapMarkersProps): UseCompletedModeMapMarkersReturnValue => {
    const { routeMarkers, routeMarkerCoordinates } = useCompletedRouteMarkers();
    const {
        depotStopMarkers,
        routeStopMarkers,
        routeLines,
        routeStopCoordinates
    } = useCompletedRouteStopMarkersAndLines();

    useEffect(() => {
        completedRoutesCoordinatesRef.current = routeMarkerCoordinates;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [routeMarkerCoordinates]);

    useEffect(() => {
        stopLevelCoordinatesRef.current = routeStopCoordinates;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [routeStopCoordinates]);

    return {
        depotStopMarkers,
        routeMarkers,
        routeStopMarkers,
        routeLines
    };
};

export default useCompletedModeMapMarkers;
