import axios from 'axios';
import { ApiAssignment, AxiosApiResponse, PaginationMetadata } from '../types';
import { GetAssignmentsParams } from './types';

export default class AssignmentsApi {
    private static readonly path = '/assignments';

    static get(
        params: GetAssignmentsParams = {
            page: 1,
            limit: 10,
            signed: false
        }
    ): Promise<AxiosApiResponse<ApiAssignment[], PaginationMetadata>> {
        return axios.get(this.path, { params });
    }
}
