import React from 'react';
import { FlexLayout, Text, Icon } from '~/ui';

function MapSettingMenuButton({ iconName, text, onClickHandler }) {
    return (
        <FlexLayout
            className="map-setting-menu-button"
            alignItems="center"
            padding="0 1.6rem"
            justifyContent="space-between"
            onClick={onClickHandler}
            sx={{
                cursor: 'pointer',
                width: '24rem',
                '&:hover': {
                    backgroundColor: 'galaxy-50'
                }
            }}
        >
            <FlexLayout alignItems="center">
                <Icon icon={iconName} margin="1rem 0" />
                <Text
                    color="galaxy-800"
                    variant="14-normal"
                    margin="0 4.4rem 0 1.1rem"
                >
                    {text}
                </Text>
            </FlexLayout>
            <Icon
                icon="chevronDown"
                color="galaxy-800"
                sx={{ transform: 'rotate(-90deg)' }}
            />
        </FlexLayout>
    );
}

export default MapSettingMenuButton;
