import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectShowZonePolygons,
    selectIsZonePolygonsEditMode,
    setIsZonePolygonsEditMode
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import MapSettingMenuItem from '../../MapSettingMenuItem';

type Props = {
    mapRouteMode: ConfigurableMapRouteMode;
};
export const EditZonePolygonsSetting = ({ mapRouteMode }: Props) => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const isShowZonePolygons = useSelector(
        selectShowZonePolygons(mapRouteMode)
    );
    const isZonePolygonsEditMode = useSelector(
        selectIsZonePolygonsEditMode(mapRouteMode)
    );
    const onChangeState = (value: boolean) => {
        dispatch(setIsZonePolygonsEditMode({ value, mode: mapRouteMode }));
    };

    if (!isShowZonePolygons) return null;

    return (
        <MapSettingMenuItem
            iconName="edit"
            text={t('editZones')}
            checkedState={isZonePolygonsEditMode}
            onChangeState={onChangeState}
        />
    );
};
