import React, { useMemo } from 'react';
import { FieldArray } from 'formik';
import { DeliveryTaskProps, PickupTaskProps } from '~/api/types';
import { useDuplicateTaskModalContext } from '../DuplicateTaskModalContext';
import { DuplicateTaskModalInvoice } from './DuplicateTaskModalInvoice';
import './DuplicateTaskModalInvoices.scss';

export const DuplicateTaskModalInvoices = () => {
    const rootClassName = 'duplicate-task-modal-invoices';
    const { apiTask } = useDuplicateTaskModalContext();

    const invoices = useMemo(() => {
        const { props: taskProps } = apiTask;
        const { pickupInvoices = [] } = taskProps as PickupTaskProps;
        const { deliveryInvoices = [] } = taskProps as DeliveryTaskProps;

        return [...pickupInvoices, ...deliveryInvoices];
    }, [apiTask]);

    if (!invoices.length) return null;

    return (
        <div
            className={`${rootClassName} _d-flex _fd-column`}
            data-testid={rootClassName}
        >
            <FieldArray name="invoicesIds">
                {(invoiceRenderProps) => (
                    <>
                        {invoices.map((invoice) => (
                            <DuplicateTaskModalInvoice
                                key={invoice.invoiceId}
                                invoice={invoice}
                                invoiceRenderProps={invoiceRenderProps}
                            />
                        ))}
                    </>
                )}
            </FieldArray>
        </div>
    );
};
