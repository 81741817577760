import React from 'react';
import './TextWithLineBreaks.scss';

interface TextWithLineBreaksProps {
    text: string;
    className?: string;
}

/**
 * Displays text with newline characters as line breaks.
 */

export const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({
    text,
    className = ''
}) => {
    return <div className={`text-with-line-breaks ${className}`}>{text}</div>;
};

export default TextWithLineBreaks;
