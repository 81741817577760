import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '~/ui';
import { replaceSelectedMapStop } from '~/reducers/selectedMapStopsSlice';

import { ResequenceModalButtonGroup } from './ResequenceModalButtonGroup';
import ResequenceModalStopList from './ResequenceModalStopList';
import constants from '~/utils/constants';

import { mapPlanStopUtils } from '~/utils/map';
import MultiSearchableDropdown from '~/components/MapPage/ReassignModal/MultiSearchableDropdown';
import { getRouteSequenceNumber } from '~/utils/stopUtils';

export const ResequenceModalContent = ({
    selectedStops,
    unselectedStops,
    onResequence,
    stopIdPropertyName,
    isLiveStopControl,
    isDifferentRoutes = false
}) => {
    const dispatch = useDispatch();

    const [selectedStopId, setSelectedStopId] = useState(null);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation([
        'translation',
        'selectedCardsDrawer',
        'trips'
    ]);
    const headerTitle = t('ResequenceModal.Content.title');

    const isShowButtonGroup = selectedStops.length === 1;
    const _handleDrop = (e, index) => {
        dispatch(
            replaceSelectedMapStop(
                mapPlanStopUtils.processStopsReorder(
                    e,
                    selectedStops,
                    stopIdPropertyName,
                    dragStartIndex,
                    index
                )
            )
        );
    };

    const formatDepot = (stopNumber) => {
        if (stopNumber === constants.depots.START_DEPOT_STOP_NUMBER) {
            return t('selectedCardsDrawer:depotCard.startDepot');
        }
        return t('selectedCardsDrawer:depotCard.endDepot');
    };

    const formatTrip = (tripNumber) => {
        if (tripNumber) {
            return `\u00B7 ${t('trips:tripSequenceNumber', {
                sequenceNumber: tripNumber
            })} `;
        }
        return '';
    };

    const formatStop = ({ stopNumber, stopName, isDepot, tripNumber }) => {
        if (isDepot) {
            return `${formatDepot(stopNumber)} ${formatTrip(tripNumber)}`;
        }
        return `${stopNumber}. ${stopName} ${formatTrip(tripNumber)}`;
    };

    return (
        <div className="resequencemodal-content">
            <div className="stop-list">
                {selectedStops.map((planStop, index) => (
                    <ResequenceModalStopList
                        key={planStop[stopIdPropertyName]}
                        stopIdPropertyName={stopIdPropertyName}
                        selectedStop={planStop}
                        handleDragStart={() => setDragStartIndex(index)}
                        handleDragOver={(e) => e.preventDefault()}
                        handleDrop={(e) => _handleDrop(e, index)}
                    />
                ))}
            </div>
            <div className="resequencemodal-content-search">
                <Text
                    className="_text-3"
                    color="galaxy-800"
                    variant="12-normal"
                >
                    {headerTitle}
                </Text>
                <MultiSearchableDropdown
                    placeholder={t('ResequenceModal.Search.input_placeholder')}
                    items={unselectedStops.map((stop) => {
                        const { tripNumber, stopName, isDepot } = stop;
                        const sequenceNumber = getRouteSequenceNumber(stop);
                        return {
                            id: stop[stopIdPropertyName],
                            stopNumber: sequenceNumber,
                            isDepot,
                            stopName,
                            tripNumber,
                            key: `${stop[stopIdPropertyName]}-trip${
                                tripNumber || ''
                            }`,
                            formatted: formatStop({
                                stopNumber: sequenceNumber,
                                tripNumber,
                                stopName,
                                isDepot
                            })
                        };
                    })}
                    showInList={formatStop}
                    formatWhenSelected={formatStop}
                    searchableKeys={['stopName', 'stopNumber', 'formatted']}
                    selectedId={selectedStopId}
                    onItemSelect={setSelectedStopId}
                />
                <Button
                    onClick={() => onResequence(selectedStopId)}
                    disabled={isDifferentRoutes || !selectedStopId}
                    className="resequencemodal-content-button"
                    data-testid="resequence-modal-resequence-button"
                >
                    <div className="_ai-center _jc-center">
                        <Icon icon="iconReorder" color="white" />
                        <Text
                            className="_text-3"
                            color="white"
                            variant="12-normal"
                        >
                            {t('PlanStopsControl.button.resequence')}
                        </Text>
                    </div>
                </Button>
                {isLiveStopControl && (
                    <div className="resequencemodal-content-button_disclaimer _text-3">
                        {t('PlanStopsControl.button.disclaimer')}
                    </div>
                )}
            </div>
            {isShowButtonGroup && (
                <ResequenceModalButtonGroup
                    selectedStops={selectedStops}
                    unselectedStops={unselectedStops}
                    stopIdPropertyName={stopIdPropertyName}
                    onResequence={onResequence}
                />
            )}
        </div>
    );
};
