import PT from 'prop-types';
import React from 'react';
import { Text as ThemeText, ThemeUICSSObject } from 'theme-ui';

import { theme, styles } from '~/ui';

type ThemeTextProps = React.ComponentProps<typeof ThemeText>;

interface TextProps extends ThemeTextProps {
    maxWidth?: string;
    textTransform?: ThemeUICSSObject['textTransform'];
    disabled?: boolean;
}

const Text = React.forwardRef<HTMLDivElement, TextProps>(function Text(
    {
        as = 'span',
        children,
        color,
        maxWidth = '',
        sx,
        textTransform = 'none',
        variant, // set expected default text variant
        ...extra
    },
    ref
) {
    const clickableStyle =
        extra.disabled || extra.onClick || extra.onMouseEnter
            ? styles.interactions.clickable
            : {};
    const ellipsisOverflowStyle: ThemeUICSSObject = maxWidth
        ? {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth
          }
        : {};

    return (
        <ThemeText
            as={as}
            color={color}
            ref={ref}
            sx={{
                ...clickableStyle,
                ...ellipsisOverflowStyle,
                textTransform,
                ...sx
            }}
            variant={variant}
            {...extra}
        >
            {children}
        </ThemeText>
    );
});

Text.propTypes = {
    /** Define element, default is span */
    as: PT.instanceOf(PT.any as any), // eslint-disable-line @typescript-eslint/no-explicit-any
    /** Any react node */
    children: PT.node,
    /** Set color of text */
    color: PT.string,
    /** Set max width of text element, trimmed with ellipsis */
    maxWidth: PT.string,
    /** Supports sx props */
    sx: PT.object, // eslint-disable-line react/forbid-prop-types
    /** Set the text-transform property */
    textTransform: PT.oneOf(['uppercase', 'capitalize', 'lowercase', 'none']),
    /** Text style variant (font size and weight) */
    variant: PT.oneOf(Object.keys(theme.text))
} as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export default Text;
