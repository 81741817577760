import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '~/ui';
import { useTranslation } from 'react-i18next';
import constants from '~/utils/constants';
import { useMapUtils, useRunScheduler } from '~/hooks';
import RunSchedulerControl from '~/components/MapPageDrawers/RunSchedulerControl';

import { idUtils } from '~/utils/id-utils';
import { pageUtils } from '~/utils/page-utils';
import { colorUtils } from '~/utils/color-utils';
import { getPreRoutePlanDrawerState } from '~/components/MapPageDrawers/utils';
import { getPlanRoutesAndMarkerColors } from '~/components/MapPage/useSetPlanRoutes/utils';

import { selectTasks } from '~/reducers/tasksSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { selectPlanRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { resetTasksDrawerFilter } from '~/reducers/tasksDrawerFilterSlice';

const {
    taskStatus: { UNASSIGNED }
} = constants;

export const useRunRoutePlanner = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('routePlanner');
    const areDriversAssignedWebColors = colorUtils.hasStoreWebColors();

    const tasks = useSelector(selectTasks);
    const selectedDate = useSelector(selectDateOnly);
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const planRoutesLevelData = useSelector(selectPlanRoutesLevelData);

    const { runScheduler } = useRunScheduler();
    const { isPreRoutePlanningMode } = useMapUtils();
    const { planRoutesEffect } =
        getPlanRoutesAndMarkerColors(planRoutesLevelData);
    const {
        showModal: showReRunSchedulerModal,
        hideModal: hideReRunSchedulerModal,
        modal: ReRunSchedulerModal
    } = useModal();

    const isShowPreRoutePlanDrawer = useMemo(
        () =>
            getPreRoutePlanDrawerState({
                routes: planRoutesEffect,
                areDriversAssignedWebColors,
                isPreRoutePlanningMode
            }),
        [isPreRoutePlanningMode, areDriversAssignedWebColors, planRoutesEffect]
    );

    const selectedStopIds = useMemo(() => {
        return selectedMapStopIds.map((combinedIds: string) => {
            const [, , , taskId] = idUtils.splitCombinedId(combinedIds);
            return taskId;
        });
    }, [selectedMapStopIds]);

    const existingRouteStopIds = useMemo(() => {
        return Object.keys(tasks).filter(
            (taskId) =>
                tasks[taskId].taskStatus !== UNASSIGNED &&
                tasks[taskId].routeDate === selectedDate
        );
    }, [tasks, selectedDate]);

    const mergedStopIds = useMemo(() => {
        return [...selectedStopIds, ...existingRouteStopIds];
    }, [selectedStopIds, existingRouteStopIds]);

    const handleClickRunRoutePlanner = () => {
        if (isShowPreRoutePlanDrawer) {
            runScheduler({
                selectedTasksIds: mergedStopIds
            });

            dispatch(resetTasksDrawerFilter());
            pageUtils.resetStateBeforeSchedulerRun();
            return;
        }
        showReRunSchedulerModal();
    };

    return {
        ReRunSchedulerModal: (
            <ReRunSchedulerModal
                title={t('routePlanner:runScheduler')}
                variant="small"
            >
                <RunSchedulerControl
                    hideReRunSchedulerModal={hideReRunSchedulerModal}
                    selectedTasksIds={mergedStopIds}
                />
            </ReRunSchedulerModal>
        ),
        handleClickRunRoutePlanner
    };
};
