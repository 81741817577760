import React from 'react';
import classNames from 'classnames';

import { IconButton, Text, TextOverflowTooltip } from '~/ui';

import { ManageStopControlListItemMetrics } from './ManageStopControlListItemMetrics';
import './manage-stop-control-list-item.scss';

const defaultRootClassName = 'manage-stop-control-list-item';

function ManageStopControlListItem({
    text,
    iconButtonText,
    iconButtonTextColor,
    iconButtonTextStyle,
    tooltipPlacement = 'top',
    className,
    iconButtonStyle,
    taskTypeText,
    metricsIconsData = [],
    ...extra
}) {
    function _getClassName() {
        return classNames(
            defaultRootClassName,
            '_d-flex _p-relative',
            className
        );
    }

    const isWithMetrics = Boolean(metricsIconsData.length);
    const textAndMetricsContainerClassName = classNames('_fd-column', {
        '_jc-center': !isWithMetrics
    });

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol_list-item'}
        >
            <IconButton
                disabled
                style={iconButtonStyle}
                text={iconButtonText}
                textColor={iconButtonTextColor}
                textStyle={iconButtonTextStyle}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-iconbutton`
                        : 'managestopcontrol_list-item-iconbutton'
                }
            />
            <div
                className={textAndMetricsContainerClassName}
                data-testid="manage-stop-control-list-item__text-and-metrics"
            >
                <TextOverflowTooltip
                    placement={tooltipPlacement}
                    content={text}
                >
                    <Text
                        className="stopitem-title _text-3 _to-ellipsis"
                        variant="12-normal"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-text`
                                : 'managestopcontrol_list-item-text'
                        }
                    >
                        {text}
                    </Text>
                </TextOverflowTooltip>
                <ManageStopControlListItemMetrics
                    iconsData={metricsIconsData}
                />
            </div>
            {taskTypeText && (
                <div
                    className={`${defaultRootClassName}__task-type _p-absolute`}
                >
                    <span>{taskTypeText}</span>
                </div>
            )}
        </div>
    );
}

export { ManageStopControlListItem };
