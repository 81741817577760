import _ from 'lodash';
import { TaskIdsByRoute } from '~/api/SinglePlanEditApi';
import { PlanRoute, PlanStop, LiveStop } from '~/data-classes';
import { getTaskId } from '~/utils/stopUtils';

type Stop = PlanStop | LiveStop;

type ReassignDispatchedPayloadArgs = {
    selectedRoute: PlanRoute | undefined;
    selectedStops: Stop[];
};

type ReassignPlanPayloadArgs = {
    selectedRoute: PlanRoute | undefined;
    selectedStops: PlanStop[];
    selectedTripId?: string;
};

type DispatchedReassignPayload = {
    taskIds: string[];
    driverId: string;
    selectedRouteId: string;
};

type PlanReassignPayload = {
    taskIds: string[];
    taskIdsByRouteId: TaskIdsByRoute;
    driverId: string;
    selectedRouteId: string;
    oldRouteId: string;
    clientId: string;
};

export const getReassignDispatchedPayload = ({
    selectedRoute,
    selectedStops
}: ReassignDispatchedPayloadArgs): DispatchedReassignPayload | undefined => {
    if (!selectedRoute) {
        console.warn('Route not selected');
        return;
    }

    const taskIds = selectedStops.map((stop) => {
        const { taskId } = stop;
        return taskId;
    });

    const { driverId, routeId: targetRouteId } = selectedRoute;

    const payload = {
        taskIds,
        driverId,
        selectedRouteId: targetRouteId
    };
    return payload as DispatchedReassignPayload;
};

export const getReassignPlanPayload = ({
    selectedRoute,
    selectedStops,
    selectedTripId
}: ReassignPlanPayloadArgs): PlanReassignPayload | undefined => {
    if (!selectedRoute) {
        console.warn('Route not selected');
        return;
    }
    const taskIds = selectedStops.map((stop) => {
        const { taskId } = stop;
        return taskId;
    });

    const targetRouteId = selectedTripId || selectedRoute.routeId;

    const { driverId } = selectedRoute;
    const [{ clientId, routeId: oldRouteId }] = selectedStops;

    const stopsWithUniqueTaskIds = _.uniqBy(selectedStops, (stop) =>
        getTaskId(stop)
    );

    const taskIdsByRouteId = stopsWithUniqueTaskIds.reduce(
        (aggregator: TaskIdsByRoute, stop: PlanStop) => {
            const { routeId } = stop;
            const taskId = getTaskId(stop);
            aggregator[routeId] = aggregator[routeId]
                ? [...aggregator[routeId], taskId]
                : [taskId];
            return aggregator;
        },
        {}
    );

    const payload = {
        taskIds,
        taskIdsByRouteId,
        driverId,
        selectedRouteId: targetRouteId,
        oldRouteId,
        clientId
    };
    return payload;
};
