import axios from 'axios';
import { ApiCustomCostMatrix, AxiosApiResponse, IApiResponse } from './types';

/**
 * Implementations of API methods under the /custom_cost_matrices path
 *
 * @category API
 */
export class CustomCostMatrixApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/custom_cost_matrices';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'custom_cost_matrix';

    /**
     * Sends a GET /custom_cost_matrices request with the given data for the given client.
     *
     * @param {boolean} [activeOnly=true] indicates whether to retrive only the active matrix
     */
    static get(
        activeOnly = true
    ): Promise<AxiosApiResponse<ApiCustomCostMatrix[]>> {
        const options = {
            params: {
                active: activeOnly,
                extent: 'entries'
            }
        };

        return axios.get<IApiResponse<ApiCustomCostMatrix[]>>(
            this.PATH,
            options
        );
    }

    /**
     * Sends a POST /custom_cost_matrices request with the given data for the given client.
     *
     * @param {ApiCustomCostMatrix} payload the api payload
     */
    static post(payload: ApiCustomCostMatrix): Promise<AxiosApiResponse> {
        return axios.post<IApiResponse>(this.PATH, payload);
    }

    /**
     * Sends a DELETE /custom_cost_matrices request with the given data for the given client.
     *
     * @param {string} matrixId the matrix id
     */
    static delete(matrixId: string): Promise<AxiosApiResponse> {
        return axios.delete<IApiResponse>(`${this.PATH}/${matrixId}`);
    }

    /**
     * Sends a PATCH /custom_cost_matrices request with the given data for the given client.
     *
     * @param {string} matrixId the matrix id
     * @param {Partial<ApiCustomCostMatrix>} payload the api payload
     */
    static patch(
        matrixId: string,
        payload: Partial<ApiCustomCostMatrix>
    ): Promise<AxiosApiResponse> {
        return axios.patch<IApiResponse>(`${this.PATH}/${matrixId}`, payload);
    }
}
