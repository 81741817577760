import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setIsOpenSelectedCardsDrawer,
    setIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    replaceSelectedMapRoutes,
    selectSelectedMapRoutes,
    setSelectedMapRoute
} from '~/reducers/selectedMapRoutesSlice';
import {
    selectIsMultipleCardSelectEnabled,
    selectViewCardDetails,
    setHasIsolatedRoutes,
    setMapMarkerMode
} from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import {
    selectAllSelectedDrawerCardData,
    upsertSelectedDrawerCardData
} from '~/reducers/selectedDrawerCardDataSlice';
import { planRouteUtils } from '~/utils/plan-route-utils';
import { setSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { useIsPlanRouteHidden } from '~/hooks/useHideParentRoute/useIsPlanRouteHidden';
import { PlanRoute } from '~/data-classes';

export const usePlanRouteItemCardClick = () => {
    const dispatch = useDispatch();
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const { isPlanRouteHidden } = useIsPlanRouteHidden();
    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );
    const viewCardDetails = useSelector(selectViewCardDetails);
    const allSelectedDrawerCardData = useSelector(
        selectAllSelectedDrawerCardData
    );

    const onPlanRouteItemCardClick = useCallback(
        ({
            isInteractive,
            planRoute
        }: {
            isInteractive?: boolean;
            planRoute: PlanRoute;
        }) => {
            const { clientRouteId, hasTasks } = planRoute;
            if (
                !hasTasks ||
                isPlanRouteHidden({ clientRouteId }) ||
                (isMultipleCardSelectEnabled && viewCardDetails)
            )
                return;

            dispatch(setIsOpenUnassignedTasksDrawer(false));

            const isSelectingMultipleCards =
                isMultipleCardSelectEnabled && !viewCardDetails;
            const isRouteSelected = selectedMapRoutes.includes(clientRouteId);

            if (isSelectingMultipleCards) {
                if (!isInteractive) return;

                dispatch(
                    isRouteSelected
                        ? removeSelectedMapRoute(clientRouteId)
                        : addSelectedMapRoute(clientRouteId)
                );
                return;
            }

            dispatch(setIsOpenSelectedCardsDrawer(true));

            const [firstSelectedDrawerCardData] = allSelectedDrawerCardData;
            const includeFirstSelectedDrawerCardDataRouteId =
                firstSelectedDrawerCardData?.isPinned ||
                allSelectedDrawerCardData.length > 1;

            if (!isRouteSelected && includeFirstSelectedDrawerCardDataRouteId) {
                dispatch(
                    replaceSelectedMapRoutes([
                        firstSelectedDrawerCardData.id,
                        clientRouteId
                    ])
                );
                dispatch(setHasIsolatedRoutes(true));
            } else if (!isRouteSelected) {
                dispatch(setSelectedMapRoute(clientRouteId));
                dispatch(setHasIsolatedRoutes(true));
            }

            dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
            dispatch(
                upsertSelectedDrawerCardData({
                    id: clientRouteId,
                    data: planRouteUtils.serializeRouteData(planRoute)
                })
            );
            dispatch(setSelectedDrawerCardId(clientRouteId));
            dispatch(resetSelectedMapStops());
            dispatch(resetSelectedTaskRowId());
        },
        [
            allSelectedDrawerCardData,
            dispatch,
            isMultipleCardSelectEnabled,
            isPlanRouteHidden,
            selectedMapRoutes,
            viewCardDetails
        ]
    );

    return {
        onPlanRouteItemCardClick
    };
};
