import { SortingState } from '@tanstack/react-table';
import constants from '~/utils/constants';

export const getSortParam = (sort: SortingState | undefined) => {
    if (!sort?.length) return;

    return sort.map((sortConfig) => {
        const { id, desc } = sortConfig;
        const {
            sortOrder: { ASC, DESC }
        } = constants;
        const sortDir = desc ? DESC : ASC;
        return {
            id,
            order: sortDir.toLocaleUpperCase()
        };
    });
};
