import React from 'react';
import { Highlight, Icon } from '~/ui';
import localizationUtils from '~/utils/localization-utils';
import './CustomerSearchResult.scss';
import { setCurrentCustomer } from '~/reducers/customersSlice';
import { useDispatch } from 'react-redux';
import { clearCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';

function CustomerSearchResult({ data, searchTerm, handleOnClick }) {
    const dispatch = useDispatch();

    const handleNavigateToCustomerDetails = () => {
        dispatch(setCurrentCustomer(data));
        const {
            name: customerName,
            client: customerId,
            address: {
                addressLine1: addressStreet,
                addressLine2: addressApt,
                city: addressCity,
                state: addressState,
                zipcode: addressZipCode,
                geoLocation: { coordinates }
            },
            euid
        } = data;

        const addressInfo = {
            customerName,
            customerId,
            addressStreet,
            addressApt,
            addressCity,
            addressState,
            addressZipCode,
            longitude: coordinates[0],
            latitude: coordinates[1],
            euid
        };
        handleOnClick(addressInfo);
        dispatch(clearCustomerSearchResults());
    };
    return (
        <button
            type="button"
            data-testid="customer-search-result"
            className="customer-search-result _fd-column"
            onClick={handleNavigateToCustomerDetails}
        >
            <div data-testid="customer-search-result_name">
                <Highlight
                    searchText={searchTerm}
                    inputText={data.name || ''}
                    fontWeight="700"
                    className="_text-5"
                />
            </div>
            <div
                data-testid="customer-search-result_address"
                className="_fd-row _text-3-alt customer-search-result__address"
            >
                <Icon
                    className="customer-search-result__icon"
                    data-testid="address-icon"
                    icon="pin"
                    color="galaxy-500"
                    size="s"
                />
                {localizationUtils.formatAddress(data.address)}
            </div>
            {data.euid && (
                <div
                    data-testid="customer-search-result_euid"
                    className="_fd-row _ai-center _text-3-alt"
                >
                    <Icon
                        data-testid="euid-icon"
                        icon="infoLine"
                        color="galaxy-500"
                        size="s"
                    />
                    <Highlight
                        searchText={searchTerm}
                        inputText={data.euid || ''}
                        fontWeight="700"
                        className="_text-3"
                    />
                </div>
            )}
        </button>
    );
}

export default CustomerSearchResult;
