import { DownloadableReportCategories } from './DownloadableReportCategories';
import { DownloadableReportTypes } from './DownloadableReportTypes';
import { DownloadableReportFilenames } from './DownloadableReportFilenames';
import { DownloadableReportIcons } from './DownloadableReportIcons';

/**
 * Interface of the report types data map
 */
interface DownloadableReportData {
    /**
     * The filename prefix used to help identify the report's type
     *
     * Available only for reports hosted on AWS
     */
    filename?: DownloadableReportFilenames;

    /**
     * The report's category
     */
    category: DownloadableReportCategories;

    /**
     * The report's type
     */
    type: DownloadableReportTypes;

    /**
     * The report's icon
     */
    icon: DownloadableReportIcons;
}

/**
 * Interface of the report group data
 */
interface DownloadableReportGroupData extends DownloadableReportData {
    /**
     * Whether the report is a core report
     */
    isCoreReport: boolean;

    /**
     * Whether the report is a custom report
     */
    isCustomReport: boolean;
}

/**
 * Mapping of report types
 *
 * The order of reports on the `ReportsPage` component are determined by the
 * order of the `DownloadableReportTypes` keys in this object
 */
export const DownloadableReportMappingsByType = {
    [DownloadableReportTypes.ROUTE_PLAN]: {
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.ROUTE_PLAN,
        icon: DownloadableReportIcons.ROUTE_PLAN
    },
    [DownloadableReportTypes.LIVE_DRIVER]: {
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.LIVE_DRIVER,
        icon: DownloadableReportIcons.LIVE_DRIVER
    },
    [DownloadableReportTypes.AUTO_ARRIVE]: {
        filename: DownloadableReportFilenames.AUTO_ARRIVE,
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.AUTO_ARRIVE,
        icon: DownloadableReportIcons.AUTO_ARRIVE
    },
    [DownloadableReportTypes.TIME_WINDOW_STAT]: {
        filename: DownloadableReportFilenames.TIME_WINDOW_STAT,
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.TIME_WINDOW_STAT,
        icon: DownloadableReportIcons.TIME_WINDOW_STAT
    },
    [DownloadableReportTypes.DRIVER_RAW]: {
        filename: DownloadableReportFilenames.DRIVER_RAW,
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.DRIVER_RAW,
        icon: DownloadableReportIcons.DRIVER_RAW
    },
    [DownloadableReportTypes.FLEET]: {
        filename: DownloadableReportFilenames.FLEET,
        category: DownloadableReportCategories.CORE,
        type: DownloadableReportTypes.FLEET,
        icon: DownloadableReportIcons.FLEET
    },
    [DownloadableReportTypes.SERVICE_TIME]: {
        filename: DownloadableReportFilenames.SERVICE_TIME,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.SERVICE_TIME,
        icon: DownloadableReportIcons.SERVICE_TIME
    },
    [DownloadableReportTypes.MANUAL_SEQUENCE]: {
        filename: DownloadableReportFilenames.MANUAL_SEQUENCE,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.MANUAL_SEQUENCE,
        icon: DownloadableReportIcons.MANUAL_SEQUENCE
    },
    [DownloadableReportTypes.TIME_WINDOW]: {
        filename: DownloadableReportFilenames.TIME_WINDOW,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.TIME_WINDOW,
        icon: DownloadableReportIcons.TIME_WINDOW
    },
    [DownloadableReportTypes.DEPOT_DEPARTURE]: {
        filename: DownloadableReportFilenames.DEPOT_DEPARTURE,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.DEPOT_DEPARTURE,
        icon: DownloadableReportIcons.DEPOT_DEPARTURE
    },
    [DownloadableReportTypes.ROUTE_COMPARE]: {
        filename: DownloadableReportFilenames.ROUTE_COMPARE,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.ROUTE_COMPARE,
        icon: DownloadableReportIcons.ROUTE_COMPARE
    },
    [DownloadableReportTypes.EDIT_IMPACT]: {
        filename: DownloadableReportFilenames.EDIT_IMPACT,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.EDIT_IMPACT,
        icon: DownloadableReportIcons.EDIT_IMPACT
    },
    [DownloadableReportTypes.SIGNATURE_ZIP]: {
        filename: DownloadableReportFilenames.SIGNATURE_ZIP,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.SIGNATURE_ZIP,
        icon: DownloadableReportIcons.SIGNATURE_ZIP
    },
    [DownloadableReportTypes.INVENTORY_MANAGEMENT]: {
        filename: DownloadableReportFilenames.INVENTORY_MANAGEMENT,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.INVENTORY_MANAGEMENT,
        icon: DownloadableReportIcons.INVENTORY_MANAGEMENT
    },
    [DownloadableReportTypes.PROOF_OF_DELIVERY]: {
        filename: DownloadableReportFilenames.PROOF_OF_DELIVERY,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.PROOF_OF_DELIVERY,
        icon: DownloadableReportIcons.PROOF_OF_DELIVERY
    },
    [DownloadableReportTypes.PROOF_OF_DELIVERY_CSV]: {
        filename: DownloadableReportFilenames.PROOF_OF_DELIVERY_CSV,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.PROOF_OF_DELIVERY_CSV,
        icon: DownloadableReportIcons.PROOF_OF_DELIVERY_CSV
    },
    [DownloadableReportTypes.ADHERENCE]: {
        filename: DownloadableReportFilenames.ADHERENCE,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.ADHERENCE,
        icon: DownloadableReportIcons.ADHERENCE
    },
    [DownloadableReportTypes.INSTANT_ROUTES_REPORT]: {
        filename: DownloadableReportFilenames.INSTANT_ROUTES_REPORT,
        category: DownloadableReportCategories.CUSTOM,
        type: DownloadableReportTypes.INSTANT_ROUTES_REPORT,
        icon: DownloadableReportIcons.INSTANT_ROUTES_REPORT
    },
    [DownloadableReportTypes.UNKNOWN]: {
        category: DownloadableReportCategories.UNKNOWN,
        type: DownloadableReportTypes.UNKNOWN,
        icon: DownloadableReportIcons.UNKNOWN
    }
} as Record<DownloadableReportTypes, DownloadableReportData>;

/**
 * Mapping of report filename prefixes to report type
 */
export const DownloadableReportFilenamesByType = (
    Object.keys(DownloadableReportMappingsByType) as DownloadableReportTypes[]
).reduce((compiled, reportType) => {
    const { filename } = DownloadableReportMappingsByType[reportType];
    if (filename) compiled[filename] = reportType;
    return compiled;
}, {} as Record<DownloadableReportFilenames, DownloadableReportTypes>);

/**
 * List of report groups data
 */
export const DownloadableReportGroups = Object.values(
    DownloadableReportMappingsByType
).map((data) => {
    const { category } = data;
    const isCoreReport = category === DownloadableReportCategories.CORE;
    const isCustomReport = category === DownloadableReportCategories.CUSTOM;
    return {
        ...data,
        isCoreReport,
        isCustomReport
    } as DownloadableReportGroupData;
});
