import React from 'react';
import PT from 'prop-types';
import { DateTime } from 'luxon';

import { Box, FlexLayout, Text } from '~/ui';

import './circle-day-button.scss';

const styles = {
    default: {
        dayTextColor: 'galaxy-500',
        dateTextColor: 'galaxy-500',
        backgroundColor: 'transparent'
    },
    selected: {
        dayTextColor: 'meteor',
        dateTextColor: 'white',
        backgroundColor: 'ocean'
    },
    disabled: {
        dayTextColor: 'galaxy-400',
        dateTextColor: 'galaxy-400',
        backgroundColor: 'transparent'
    }
};

const taskIndicatorStyles = {
    default: {
        backgroundColor: 'ocean'
    },
    selected: {
        backgroundColor: 'white'
    },
    disabled: {
        backgroundColor: 'galaxy-400'
    }
};

function CircleDayButton({
    date,
    isSelected,
    isDisabled = false,
    onButtonClick,
    hasTasks = false
}) {
    const chosenStyle =
        (isSelected && styles.selected) ||
        (isDisabled && styles.disabled) ||
        styles.default;

    const taskIndicatorStyle =
        (isSelected && taskIndicatorStyles.selected) ||
        (isDisabled && taskIndicatorStyles.disabled) ||
        taskIndicatorStyles.default;

    function handleClick() {
        return onButtonClick && onButtonClick(date.toJSDate());
    }

    return (
        <FlexLayout
            className="circleday-wrapper"
            disabled={isDisabled}
            onClick={handleClick}
        >
            <Text
                variant="10-medium"
                color={chosenStyle.dayTextColor}
                textTransform="uppercase"
            >
                {date.weekdayShort}
            </Text>
            <FlexLayout
                className="circleday-content"
                sx={{
                    backgroundColor: chosenStyle.backgroundColor,
                    position: 'relative'
                }}
            >
                <Text variant="14-medium" color={chosenStyle.dateTextColor}>
                    {date.toLocaleString({
                        month: 'numeric',
                        day: 'numeric'
                    })}
                </Text>
                {hasTasks ? (
                    <Box
                        sx={{
                            ...taskIndicatorStyle,
                            width: '4px',
                            height: '4px',
                            borderRadius: '50%',
                            position: 'absolute',
                            left: '50%',
                            top: '75%',
                            transform: 'translate(-50%)'
                        }}
                    />
                ) : null}
            </FlexLayout>
        </FlexLayout>
    );
}

export default CircleDayButton;

CircleDayButton.propTypes = {
    /** Date to display */
    date: PT.instanceOf(DateTime),
    /** Is date selected */
    isSelected: PT.bool,
    /** Is it disabled */
    isDisabled: PT.bool,
    /** On component click */
    onButtonClick: PT.func
};
