import React from 'react';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CustomData } from '~/components/MapPageDrawers/types';
import { UIElementWithChildren } from '../../types';

interface SortableItemProps extends UIElementWithChildren {
    /**
     * The item ID
     */
    id: string;
    /**
     * The item data
     */
    data?: CustomData;
    /**
     * Whether the item sort is disabled
     */
    isDisabled?: boolean;
}

const ROOT_CLASS_NAME = 'sortable-item';

export const SortableItem = ({
    id,
    data,
    isDisabled = false,
    children,
    className,
    'data-testid': dataTestId = ROOT_CLASS_NAME
}: SortableItemProps): JSX.Element => {
    const elementClassName = classNames(ROOT_CLASS_NAME, className);

    const { attributes, setNodeRef, transform, transition, listeners } =
        useSortable({
            id,
            disabled: isDisabled,
            data
        });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={elementClassName}
            data-testid={dataTestId}
        >
            {children}
        </div>
        /* eslint-enable react/jsx-props-no-spreading */
    );
};
