import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetOnLogout } from '~/reducers/common-actions';

export const driversByClientSlice = createSlice({
    name: 'driversByClient',
    initialState: {},
    reducers: {
        setDriversByClient: (state, action) => {
            return action.payload;
        },
        addDriverByClient: (state, action) => {
            const { data, clientId } = action.payload;

            if (!_.isObject(data)) return state;

            const driversList = state[clientId];
            const newDriversList = driversList
                ? [...driversList, data]
                : [data];

            return {
                ...state,
                [clientId]: newDriversList
            };
        },
        updateDriversByClient: (state, action) => {
            const { driverId, data, clientId } = action.payload;
            if (!_.isObject(data) || !state[clientId]) return state;
            const updatedDrivers = state[clientId].map((driver) => {
                if (driver.id === driverId) return data;
                return driver;
            });
            return {
                ...state,
                [clientId]: updatedDrivers
            };
        },
        resetDriversByClient: () => {
            return {};
        },
        removeDriverById: (state, action) => {
            const { id, clientId } = action.payload;

            if (!state[clientId]) return state;

            const updatedDrivers = state[clientId].filter((driver) => {
                return driver.id !== id;
            });
            return {
                ...state,
                [clientId]: updatedDrivers
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const {
    setDriversByClient,
    addDriverByClient,
    updateDriversByClient,
    resetDriversByClient,
    removeDriverById
} = driversByClientSlice.actions;

export const selectDriversByClient = (state) => state.driversByClient;

export const selectDriverById = (id) => (state) => {
    const alldrivers = Object.keys(state.activeClients || {}).flatMap(
        (clientId) => state.driversByClient[clientId] || []
    );
    return alldrivers.find((driver) => driver.id === id);
};

export const selectDriverVehiclePairs = (state) => {
    const allDrivers = Object.values(state.driversByClient).flatMap(
        (item) => item
    );
    return allDrivers.reduce((pair, driver) => {
        if (!driver.defaultVehicle) return pair;
        pair[driver.defaultVehicle] = driver;
        return pair;
    }, {});
};

export default driversByClientSlice.reducer;
