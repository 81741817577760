import { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import clientTemplateApi from '~/api/ClientTemplateApi';
import {
    selectClientTemplateData,
    setClientTemplateData
} from '~/reducers/clientTemplateDataSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

/**
 * A blank client template with an empty `integrationExport` array
 *
 * When the `GET /template` fails, we need to set a non-empty object
 * to prevent the `useEffect` from infinitely trying again.
 */
export const emptyClientTemplate = { integrationExport: [] };

export const useGetClientTemplate = () => {
    const mainClient = useSelector(selectMainClient);
    const dispatch = useDispatch();
    const clientTemplateData = useSelector(selectClientTemplateData);
    const [templateData, setTemplateData] = useState(clientTemplateData);

    const isTemplateDataEmpty = useMemo(() => {
        return _.isEmpty(clientTemplateData);
    }, [clientTemplateData]);

    const getClientTemplateData = useCallback(async () => {
        try {
            const { id } = mainClient || {};
            if (!id) return;

            // API request for client integration data template
            const dataResponse = await clientTemplateApi.get(id);
            const {
                data: { data: clientData }
            } = dataResponse;
            setTemplateData(clientData);
            dispatch(setClientTemplateData(clientData));
        } catch (e) {
            console.error(e);
            setTemplateData(emptyClientTemplate);
            dispatch(setClientTemplateData(emptyClientTemplate));
        }
    }, [dispatch, mainClient]);

    useEffect(() => {
        if (!isTemplateDataEmpty) {
            return;
        }
        getClientTemplateData();
    }, [isTemplateDataEmpty, clientTemplateData, getClientTemplateData]);

    return { templateData, isTemplateDataEmpty };
};
