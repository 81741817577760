import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle } from '~/ui/components/Toggle';
import { ClientSelectorItem } from '~/ui/components/ClientSelector/ClientSelectorItem';

import './ClientSelector.scss';

interface ClientSelectorProps {
    isMultiAccess: boolean;
    areAllClientsSelected: boolean;
    selectAllClients: () => void;
    selectedClients: Record<string, boolean>;
    handleClientSelect: (id: string) => void;
    clientProps: Record<string, string>[];
}

const ClientSelector = ({
    isMultiAccess,
    areAllClientsSelected,
    selectAllClients,
    selectedClients,
    handleClientSelect,
    clientProps
}: ClientSelectorProps) => {
    const { t } = useTranslation('translation');
    const rootClass = 'client-selector';

    return (
        <div className={`_d-flex _fd-column ${rootClass}__wrapper`}>
            {isMultiAccess && (
                <div
                    className={`_d-flex ${rootClass}__select-all`}
                    data-testid="select-all-clients-toggle"
                >
                    <Toggle
                        data-testid="select-all-clients-button"
                        isOn={areAllClientsSelected}
                        onToggle={selectAllClients}
                    />
                    {t('selectAllClients')}
                </div>
            )}
            <div className={`_d-flex _fd-row ${rootClass}__items`}>
                {clientProps.map(({ name, id }) => {
                    return (
                        <ClientSelectorItem
                            id={id}
                            isSelected={selectedClients[id]}
                            name={name}
                            key={id}
                            onClick={handleClientSelect}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export { ClientSelector };
