import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getURL, ServerBaseUrlKey } from '~/servers/servers';
import {
    ApiVehicleProfileCreate,
    ApiVehicleProfiles,
    ApiVehicleProfileUpdate,
    AxiosApiResponse,
    VehicleProfileCreatePayload,
    VehicleProfileUpdatePayload
} from '~/api/types';

/**
 * Base URL of the API endpoint
 */
const BASEURL = getURL(ServerBaseUrlKey.VEHICLE_PROFILE);

/**
 * Implementations of Vehicle profile API methods
 *
 * @category API
 */
export default class VehicleProfileApi {
    /**
     * Base URL of the API endpoint
     */
    private static readonly baseURL = BASEURL;

    /**
     * Path of the bulk API endpoint
     */
    private static readonly vehicleProfilesPath = '/vehicleProfiles';

    /**
     * The default axios request config
     */
    private static getDefaultRequestConfig(): AxiosRequestConfig {
        return { baseURL: this.baseURL };
    }

    /**
     * Whether vehicle profile service is supported in the current environment
     */
    static isVehicleProfileSupported() {
        return Boolean(BASEURL);
    }

    /**
     * Bulk retrieve all vehicle profiles
     */
    static getVehicleProfiles(): Promise<AxiosResponse<ApiVehicleProfiles>> {
        const options = this.getDefaultRequestConfig();
        return axios.get(this.vehicleProfilesPath, options);
    }

    /**
     * Create a vehicle profile
     */
    static post(
        payload: VehicleProfileCreatePayload
    ): Promise<AxiosResponse<ApiVehicleProfileCreate>> {
        const options = this.getDefaultRequestConfig();
        return axios.post(this.vehicleProfilesPath, payload, options);
    }

    /**
     * Update the vehicle profile
     */
    static put(
        id: string,
        payload: VehicleProfileUpdatePayload
    ): Promise<AxiosResponse<ApiVehicleProfileUpdate>> {
        if (!id) {
            return Promise.reject('vehicle profile id not provided');
        }

        const options = this.getDefaultRequestConfig();
        return axios.put(`${this.vehicleProfilesPath}/${id}`, payload, options);
    }

    /**
     * Delete the vehicle profile
     */
    static delete(
        id: string
    ): Promise<AxiosApiResponse<Record<string, string | unknown>>> {
        if (!id) {
            return Promise.reject('vehicle profile id not provided');
        }

        const options = this.getDefaultRequestConfig();
        return axios.delete(`${this.vehicleProfilesPath}/${id}`, options);
    }
}
