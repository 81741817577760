import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import {
    ColumnFiltersState,
    VisibilityState,
    SortingState,
    ColumnOrderState
} from '@tanstack/react-table';
import { OpenSearchAttributeCount } from '~/api/types';
import type { RootState } from '~/store';
import { GetEquipmentParams } from '~/api/EquipmentApi';

const MAX_PAGE_SIZE = 100;

interface EquipmentTableState {
    equipmentAttributes: Record<string, OpenSearchAttributeCount[]>;
    equipmentColumnFilters: ColumnFiltersState;
    equipmentColumnOrder: ColumnOrderState;
    equipmentColumnVisibility: VisibilityState;
    equipmentSorting: SortingState;
    equipmentGlobalFilter: string;
    totalEquipmentCount: number;
    evaluatedKeys: string[];
    pageIndex: number;
    pageSize: number;
    pageTotal: number;
    apiPaginationOptions: GetEquipmentParams;
}

export const initialState: EquipmentTableState = {
    equipmentAttributes: {},
    equipmentColumnFilters: [],
    equipmentColumnOrder: [],
    equipmentColumnVisibility: {},
    equipmentSorting: [],
    equipmentGlobalFilter: '',
    totalEquipmentCount: 0,
    evaluatedKeys: [],
    pageIndex: 1,
    pageSize: MAX_PAGE_SIZE,
    pageTotal: 1,
    apiPaginationOptions: {
        limit: MAX_PAGE_SIZE,
        page: 1
    }
};

export const equipmentTableSlice = createSlice({
    name: 'equipmentTable',
    initialState,
    reducers: {
        setEquipmentAttributes: (
            state: EquipmentTableState,
            action: PayloadAction<Record<string, OpenSearchAttributeCount[]>>
        ) => {
            return {
                ...state,
                equipmentAttributes: action.payload
            };
        },

        setEquipmentColumnFilters: (
            state: EquipmentTableState,
            action: PayloadAction<ColumnFiltersState>
        ) => {
            return {
                ...state,
                equipmentColumnFilters: action.payload
            };
        },

        setEquipmentColumnOrder: (
            state: EquipmentTableState,
            action: PayloadAction<ColumnOrderState>
        ) => {
            return {
                ...state,
                equipmentColumnOrder: action.payload
            };
        },

        setEquipmentColumnVisibility: (
            state: EquipmentTableState,
            action: PayloadAction<VisibilityState>
        ) => {
            return {
                ...state,
                equipmentColumnVisibility: action.payload
            };
        },

        setEquipmentSorting: (
            state: EquipmentTableState,
            action: PayloadAction<SortingState>
        ) => {
            return {
                ...state,
                equipmentSorting: action.payload
            };
        },

        setEquipmentGlobalFilter: (
            state: EquipmentTableState,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                equipmentGlobalFilter: action.payload
            };
        },

        replaceEquipmentTable: (
            state: EquipmentTableState,
            action: PayloadAction<EquipmentTableState>
        ) => {
            return action.payload;
        },

        setTotalEquipmentCount: (
            state: EquipmentTableState,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                totalEquipmentCount: action.payload
            };
        },

        setEvalualatedKeys: (
            state: EquipmentTableState,
            action: PayloadAction<string[]>
        ) => {
            return {
                ...state,
                evaluatedKeys: action.payload
            };
        },

        setPageIndex: (
            state: EquipmentTableState,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                pageIndex: action.payload
            };
        },

        setPageSize: (
            state: EquipmentTableState,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                pageSize: action.payload
            };
        },

        setPageTotal: (
            state: EquipmentTableState,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                pageTotal: action.payload
            };
        },

        setApiPaginationOptions: (
            state: EquipmentTableState,
            action: PayloadAction<GetEquipmentParams>
        ) => {
            return {
                ...state,
                apiPaginationOptions: action.payload
            };
        },

        /**
         * Resets to a blank equipment state
         */
        resetEquipmentTable: (): EquipmentTableState => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...initialState,
                ...state
            };
        });
    }
});

export const {
    setEquipmentAttributes,
    setEquipmentColumnFilters,
    setEquipmentColumnOrder,
    setEquipmentColumnVisibility,
    setEquipmentSorting,
    setEquipmentGlobalFilter,
    replaceEquipmentTable,
    resetEquipmentTable,
    setApiPaginationOptions,
    setEvalualatedKeys,
    setPageIndex,
    setPageSize,
    setPageTotal,
    setTotalEquipmentCount
} = equipmentTableSlice.actions;

export const selectEquipmentAttributes = (
    state: RootState
): Record<string, OpenSearchAttributeCount[]> =>
    state.equipmentTable.equipmentAttributes;

export const selectEquipmentColumnFilters = (
    state: RootState
): ColumnFiltersState => state.equipmentTable.equipmentColumnFilters;

export const selectEquipmentColumnOrder = (
    state: RootState
): ColumnOrderState => state.equipmentTable.equipmentColumnOrder;

export const selectEquipmentColumnVisibility = (
    state: RootState
): VisibilityState => state.equipmentTable.equipmentColumnVisibility;

export const selectEquipmentSorting = (state: RootState): SortingState =>
    state.equipmentTable.equipmentSorting;

export const selectEquipmentGlobalFilter = (state: RootState): string =>
    state.equipmentTable.equipmentGlobalFilter;

export const selectTotalEquipmentCount = (state: RootState): number =>
    state.equipmentTable.totalEquipmentCount;

export const selectEvalualatedKeys = (state: RootState): string[] =>
    state.equipmentTable.evaluatedKeys;

export const selectPageIndex = (state: RootState): number =>
    state.equipmentTable.pageIndex;

export const selectPageSize = (state: RootState): number =>
    state.equipmentTable.pageSize;

export const selectPageTotal = (state: RootState): number =>
    state.equipmentTable.pageTotal;

export const selectApiPaginationOptions = (
    state: RootState
): GetEquipmentParams => state.equipmentTable.apiPaginationOptions;

export default equipmentTableSlice.reducer;
