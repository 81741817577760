import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    selectTaskUploadResultsByClientId,
    clearTaskUploadResults
} from '~/reducers/uploadResultsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';
import { useIsTaskUploadInitiated } from './useIsTaskUploadInitiated';
import { getAllTasks } from '~/reducers/tasksSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import uploadResultsUtils, {
    getDefaultState
} from '~/utils/upload-results-utils';

export const useTaskUploadResults = () => {
    const mainClient = useSelector(selectMainClient);
    const selectedDate = useSelector(selectDate);
    const clientId = mainClient?.id;
    const uploadResults = useSelector(
        selectTaskUploadResultsByClientId(clientId)
    );
    const [state, setState] = useState(() => getDefaultState());
    const [latestMessage] = uploadResults.slice(-1);
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const { isTaskUploadInitiated, setIsTaskUploadInitiated } =
        useIsTaskUploadInitiated();

    useEffect(() => {
        if (!isTaskUploadInitiated || !latestMessage) return;

        const newState = uploadResultsUtils.getNewState({
            latestMessage,
            state
        });
        const {
            isUploaded,
            isUploadSuccessful,
            isUploadComplete,
            uploadStats: { successfulTasks, totalCount, status, currentCount }
        } = newState;
        const inProgress = isUploaded && !isUploadComplete;
        const isError = isUploaded && isUploadComplete && !isUploadSuccessful;
        const processIndicatorState = {
            inProgress,
            error: isError,
            position: 'center',
            type: constants.processIndicator.TASK_UPLOAD
        };
        const message = isUploadSuccessful
            ? t('TaskUploaded', {
                  count: successfulTasks
              })
            : t('UploadTask', {
                  currentCount,
                  count: totalCount
              });
        setState(newState);
        dispatch(
            addProcessIndicator({
                ...processIndicatorState,
                message
            })
        );

        if (status === constants.uploadStatus.COMPLETE) {
            setIsTaskUploadInitiated(false);
            dispatch(clearTaskUploadResults(clientId));
            dispatch(getAllTasks({ routeDate: selectedDate }));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [latestMessage]);
};
