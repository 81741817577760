import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import timeUtils from '~/utils/time-utils';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useCallback } from 'react';

export const useFormatTimeToClientTimeZone = () => {
    const mainClient = useSelector(selectMainClient);
    const { timezone: selectedClientTimeZone } = mainClient || {};

    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );

    const formatTimeToClientTimeZone = useCallback(
        ({
            time,
            format = 't',
            shouldFormatTime = true
        }: {
            time: string | DateTime;
            format?: string;
            shouldFormatTime?: boolean;
        }) => {
            return timeUtils.formatTimeInTimeZone({
                time,
                timeZone: selectedClientTimeZone,
                format,
                shouldFormatTime,
                isClientTimezoneFlagEnabled
            });
        },
        [selectedClientTimeZone, isClientTimezoneFlagEnabled]
    );

    return { formatTimeToClientTimeZone, selectedClientTimeZone };
};
