import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useFeatureFlag } from '../useFeatureFlag';
import { UseIntermodalFeatures } from './types';
import { carKind } from './carKind';

export const useIntermodalFeatures = (): UseIntermodalFeatures => {
    const mainClient = useSelector(selectMainClient);
    const {
        enableLiveDispatch: isEnabledLiveDispatch,
        enableExternalTaskTypes: isEnabledExternalTaskTypes,
        enableGapInRoute: isEnabledGapInRoute,
        enableResetTasks: isEnabledResetTasks,
        enableOperationalStops: isEnabledOperationalStops,
        enableEndOfShift: isEnabledEndOfShift,
        enableUnarriveStops: isEnabledUnarriveStops,
        enableRescheduleStops: isEnabledRescheduleStops,
        enableTaskEquipmentField = {}
    } = mainClient?.intermodal || {};

    const {
        externalTaskType: isEnabledFieldExternalTaskType,
        equipmentId: isEnabledFieldEquipmentId,
        carKind: isEnabledFieldCarKind,
        emptyOrderNumber: isEnabledFieldEmptyOrder,
        chassisId: isEnabledFieldChassisId,
        showToDriver: isEnabledFieldShowToDriver
    } = enableTaskEquipmentField;

    const isFeatureEnabledExternalTaskTypes = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );
    const isFeatureEnabledResetTasks = useFeatureFlag(FeatureFlag.RESET_TASKS);
    const isFeatureEnabledEditChassisId = useFeatureFlag(
        FeatureFlag.EDIT_CHASSIS_ID
    );
    const isFeatureEnabledShowToDriver = useFeatureFlag(
        FeatureFlag.SHOW_TO_DRIVER
    );
    const isFeatureEnabledUnarriveStops = useFeatureFlag(
        FeatureFlag.UNARRIVE_STOPS
    );

    const enableExternalTaskTypes = Boolean(
        isEnabledExternalTaskTypes || isFeatureEnabledExternalTaskTypes
    );
    const enableResetTasks = Boolean(
        isEnabledResetTasks || isFeatureEnabledResetTasks
    );
    const enableEditChassisId = Boolean(
        isEnabledFieldChassisId || isFeatureEnabledEditChassisId
    );
    const enableShowToDriver = Boolean(
        isEnabledFieldShowToDriver || isFeatureEnabledShowToDriver
    );
    const enableUnarriveStops = Boolean(
        isEnabledUnarriveStops || isFeatureEnabledUnarriveStops
    );

    return {
        carKind,
        enableLiveDispatch: Boolean(isEnabledLiveDispatch),
        enableExternalTaskTypes,
        enableGapInRoute: Boolean(isEnabledGapInRoute),
        enableResetTasks,
        enableOperationalStops: Boolean(isEnabledOperationalStops),
        enableEditEquipmentId: Boolean(isEnabledFieldEquipmentId),
        enableEditExternalTaskType: Boolean(isEnabledFieldExternalTaskType),
        enableEditCarKind: Boolean(isEnabledFieldCarKind),
        enableEditChassisId,
        enableEditEmptyOrder: Boolean(isEnabledFieldEmptyOrder),
        enableShowToDriver,
        enableEndOfShift: Boolean(isEnabledEndOfShift),
        enableUnarriveStops,
        enableRescheduleStops: Boolean(isEnabledRescheduleStops)
    };
};
