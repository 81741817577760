import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetOnLogout } from '~/reducers/common-actions';

export const selectedDepotIdSlice = createSlice({
    name: 'selectedDepotId',
    initialState: '',
    reducers: {
        setSelectedDepotId: (state, action) => {
            if (_.isString(action.payload)) return action.payload;
            return state;
        },
        resetSelectedDepotId: () => {
            return '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return '';
        });
    }
});
export const { setSelectedDepotId, resetSelectedDepotId } =
    selectedDepotIdSlice.actions;
export const selectDepotId = (state) => state.selectedDepotId;
export default selectedDepotIdSlice.reducer;
