import React from 'react';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize } from '~/ui/components/Icon/IconSizes';
import { RouteCardMetric, TextOverflowTooltip } from '~/ui';
import { TooltipPlacement } from '~/ui/components/Tooltip';
import './routecard-title.scss';

export interface RouteCardTitleIcon {
    className?: string;
    value: string | number;
    valueColor?: string;
    maxValue: number | undefined;
    icon: IconName;
    iconSize?: IconSize;
    iconColor?: string;
    icons?: {
        icon: IconName;
        iconSize: IconSize;
        iconColor: string;
    }[];
    'data-testid'?: string;
    metric: string;
}

interface RouteCardTitleProps {
    className?: string;
    children?: React.ReactNode;
    title: string;
    titleIcons?: RouteCardTitleIcon[];
    tooltip?: string;
    tooltipPlacement?: TooltipPlacement;
    'data-testid'?: string;
}

function RouteCardTitle({
    className = '',
    children = undefined,
    title,
    titleIcons = [],
    tooltip = '',
    tooltipPlacement = 'top',
    ...extra
}: RouteCardTitleProps) {
    function getClassName() {
        let boxClassName = 'routecard-top _d-flex _jc-space-between _ai-center';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function getIcons() {
        if (!titleIcons || !titleIcons.length) return null;

        return titleIcons.map((item) => {
            const {
                metric,
                value,
                maxValue,
                icon,
                iconSize,
                iconColor,
                icons
            } = item;

            return (
                <RouteCardMetric
                    key={metric}
                    className={item.className}
                    metric={metric}
                    value={value}
                    maxValue={maxValue}
                    icon={icon}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    icons={icons}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-icon`
                            : 'routecard-icon'
                    }
                />
            );
        });
    }

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-top'}
        >
            {title && (
                <div className="routecard-top_group _d-flex">
                    <TextOverflowTooltip
                        placement={tooltipPlacement}
                        content={tooltip}
                    >
                        <span
                            className="routecard-title _tt-capitalize _ws-nowrap"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-title`
                                    : 'routecard-title'
                            }
                        >
                            {title}
                        </span>
                    </TextOverflowTooltip>
                    {getIcons()}
                </div>
            )}
            {children}
        </div>
    );
}

export default RouteCardTitle;
