import { LiveDriver } from '~/data-classes';
import { LiveRoutesException } from './types';

export const getExceptionIcons = (driverData: LiveDriver) => {
    const extraIconRightShift = 0.6;
    const exceptionIcons: Omit<LiveRoutesException, 'position'>[] = [];
    const { numAtRiskExceptions, numLateExceptions, numInventoryExceptions } =
        driverData;

    if (numAtRiskExceptions > 0) {
        exceptionIcons.push({
            type: 'at-risk',
            icon: 'delayFill',
            iconColor: 'saturn',
            value: numAtRiskExceptions
        });
    }

    if (numLateExceptions > 0) {
        exceptionIcons.push({
            type: 'late',
            icon: 'delayFill',
            iconColor: 'mars',
            value: numLateExceptions
        });
    }

    if (numInventoryExceptions > 0) {
        exceptionIcons.push({
            type: 'inventory',
            icon: 'warningFill3',
            iconColor: 'saturn',
            value: numInventoryExceptions
        });
    }

    const exceptionData: LiveRoutesException[] = exceptionIcons.map(
        (exception, idx) => {
            return {
                ...exception,
                position: `${idx * extraIconRightShift}rem`
            };
        }
    );

    return exceptionData;
};
