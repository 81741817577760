import React, { useEffect, useState } from 'react';

import { ManageStopControl } from '~/ui';

import { useLiveButtonHandlers } from './useLiveButtonHandlers';
import { useIsLiveModalVisible } from './useIsLiveModalVisible';
import DriverBreakModalMainView from '../Views/DriverBreakModalMain';
import ResequenceBreak from '../Views/ResequenceBreak';
import EditBreakDuration from '../Views/EditBreakDuration';

export const DriverLiveBreakModal = () => {
    const { handleDelete, handleDeselect, handleEdit } =
        useLiveButtonHandlers();
    const isLiveModalVisible = useIsLiveModalVisible();
    const [isEditingDuration, setIsEditingDuration] = useState(false);
    const [isResequencingBreak, setIsResequencingBreak] = useState(false);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => handleDeselect(), []);

    useEffect(() => {
        if (isLiveModalVisible) return;
        setIsEditingDuration(false);
        setIsResequencingBreak(false);
    }, [isLiveModalVisible]);

    if (!isLiveModalVisible) return null;

    return (
        <ManageStopControl
            className="driver-break-modal"
            data-testid="driver-break-modal"
        >
            {!isEditingDuration && !isResequencingBreak && (
                <DriverBreakModalMainView
                    handleDelete={handleDelete}
                    handleDeselect={handleDeselect}
                    setIsEditingDuration={setIsEditingDuration}
                    setIsResequencingBreak={setIsResequencingBreak}
                />
            )}

            {isResequencingBreak && (
                <ResequenceBreak
                    handleClose={() => setIsResequencingBreak(false)}
                />
            )}

            {isEditingDuration && (
                <EditBreakDuration
                    handleBack={() => setIsEditingDuration(false)}
                    handleClose={handleDeselect}
                    handleEditDuration={handleEdit}
                />
            )}
        </ManageStopControl>
    );
};
