import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    addSelectedMapStop,
    removeSelectedMapStop
} from '~/reducers/selectedMapStopsSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';

import { findTwoPartTask } from '~/utils/stopUtils';
import constants from '~/utils/constants';

type MarkerEventPayload = {
    /**
     * the marker ID
     */
    id: string;

    /**
     * whether the marker is selected
     */
    isSelected: boolean;

    /**
     * whether the marker stop is part of a two-part task
     */
    isTwoPart: boolean;

    /**
     * the marker stop ID
     */
    stopId: string;
};

type ParamsEmittedEventHandler = {
    /**
     * the event triggered
     */
    event: string;

    /**
     * the event payload data
     */
    payload: MarkerEventPayload;
};

export type HookOnDemandDispatchMarkerEventHandler = (
    eventPayload: ParamsEmittedEventHandler
) => void;

/**
 * A custom hook that provides an emitted event handler suitable for use with
 * a stop marker emitting events described in constants.mapChildEvents. This
 * emitted event handler is meant to be used for on demand dispatch.
 *
 * @category Hooks
 * @module useOnDemandDispatchMarkerEventHandler
 * @returns {Function} the emitted event handler
 * @example <caption>Usage</caption>
 * // import statement
 * import { useOnDemandDispatchMarkerEventHandler } from '~/hooks';
 *
 * // expose methods
 * const emittedEventHandler = useOnDemandDispatchMarkerEventHandler();
 */
export const useOnDemandDispatchMarkerEventHandler =
    (): HookOnDemandDispatchMarkerEventHandler => {
        const dispatch = useDispatch();
        const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
        const liveDriverData = useSelector(
            selectLiveDriverById(selectedDrawerCardId)
        );
        const liveDriverSchedule = liveDriverData?.schedule;

        /**
         * Toggles the selected state of live stop markers
         *
         * @method toggleStopsSelected
         * @private
         * @param {MarkerEventPayload} payload - the event payload data
         */
        const toggleStopsSelected = useCallback(
            (payload: MarkerEventPayload) => {
                const { id, isSelected, isTwoPart, stopId } = payload;

                const toggleStopIds = [id];

                if (isTwoPart) {
                    const result = findTwoPartTask({
                        idToExclude: id,
                        liveDriverSchedule,
                        stopId
                    });
                    if (result) {
                        toggleStopIds.push(result.id);
                    }
                }

                dispatch(resetSelectedTaskRowId());

                for (const toggleId of toggleStopIds) {
                    if (isSelected) {
                        dispatch(removeSelectedMapStop(toggleId));
                    } else {
                        dispatch(addSelectedMapStop(toggleId));
                    }
                }
            },
            [dispatch, liveDriverSchedule]
        );

        /**
         * Handles marker emitted events
         *
         * @method emittedEventHandler
         * @param {object} eventPayload - the event payload
         * @param {string} eventPayload.event - the event triggered
         * @param {object} eventPayload.payload - the event payload data
         */
        const emittedEventHandler = useCallback(
            (eventPayload: ParamsEmittedEventHandler) => {
                const { mapChildEvents } = constants;
                const { event, payload } = eventPayload;

                if (event === mapChildEvents.STOP_MOUSEUP) {
                    toggleStopsSelected(payload as MarkerEventPayload);
                }
            },
            [toggleStopsSelected]
        );

        return emittedEventHandler;
    };
