import { useMutation } from 'react-query';
import TasksApi, { AddOrUpdateTaskData } from '~/api/TasksApi';
import { AxiosApiResponse } from '~/api/types';

export const useAddOperationalStops = () => {
    const {
        isLoading: isLoadingAddOperationalStop,
        mutateAsync: doAddOperationalStopAsync
    } = useMutation(
        (stopData: {
            tasks: AddOrUpdateTaskData[];
            position: number;
            taskId: string;
        }): Promise<AxiosApiResponse<Record<string, string[]>>> => {
            return TasksApi.addOperationalStop(stopData);
        }
    );

    const addOperationalStop = (
        tasks: AddOrUpdateTaskData[],
        position: number,
        taskId: string
    ) => {
        return doAddOperationalStopAsync({ tasks, position, taskId });
    };

    return {
        isLoadingAddOperationalStop,
        addOperationalStop
    };
};
