import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '~/ui';
import { RadioInputData } from '~/ui/components/RadioGroup';
import constants from '~/utils/constants';
import { useAddTaskModalContext } from '../../AddTaskModalContext';

const { YES, NO } = constants.radioOption;

export const PaymentRequired = () => {
    const { t } = useTranslation(['addTask', 'common']);
    const {
        clientTask: { paymentRequired },
        onOtherFieldChange
    } = useAddTaskModalContext();
    const radioData: RadioInputData[] = [
        {
            id: 'addTaskModalPaymentRequired',
            label: t('common:yes'),
            value: YES
        },
        {
            id: 'addTaskModalPaymentNotRequired',
            label: t('common:no'),
            value: NO
        }
    ];
    const onChange = (value: string) => {
        const isPaymentRequired = value === YES;
        onOtherFieldChange('paymentRequired', isPaymentRequired);
    };
    const radioValue = paymentRequired ? YES : NO;

    return (
        <RadioGroup
            required
            radioData={radioData}
            onChange={onChange}
            value={radioValue}
            name="paymentRequired"
            label={t('addTask:invoice.paymentRequired')}
            className="address-tab-content__radio-group-wrapper"
            radioInputGroupClassName="address-tab-content__radio-group"
            radioInputClassName="address-tab-content__radio-input"
            data-testid="add-task-payment-required-radio-group"
            radio-testid="add-task-payment-required-radio"
        />
    );
};
