import React from 'react';
import constants from '~/utils/constants';
import classNames from 'classnames';
import eventBus from '~/EventBus';

import './marker-popup.scss';

function MarkerPopup({
    className,
    headerContent,
    bodyContent,
    bodyClassName,
    emittedEventHandler = () => {},
    ...extra
}) {
    if (!bodyContent) return null;

    function _closePopup() {
        eventBus.publish(constants.mapChildEvents.POPUP_MOUSELEAVE);
        emittedEventHandler({
            event: constants.mapChildEvents.POPUP_MOUSELEAVE
        });
    }

    function _handleMouseEnter() {
        eventBus.publish(constants.mapChildEvents.POPUP_MOUSEENTER);
        emittedEventHandler({
            event: constants.mapChildEvents.POPUP_MOUSEENTER
        });
    }

    function _getClassName() {
        const defaultClassName = 'marker-popup _d-flex _fd-column';
        return classNames(defaultClassName, className);
    }

    function _getBodyClassName() {
        const defaultClassName = 'marker-popup-body _d-flex';
        return classNames(defaultClassName, bodyClassName);
    }

    return (
        <div
            className={_getClassName()}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_closePopup}
            data-testid={extra['data-testid'] || 'marker-popup'}
        >
            {headerContent && (
                <div
                    className="marker-popup-header _d-flex _fd-column"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-header`
                            : 'marker-popup-header'
                    }
                >
                    {headerContent}
                </div>
            )}
            <div
                className={_getBodyClassName()}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-body`
                        : 'marker-popup-body'
                }
            >
                {bodyContent}
            </div>
        </div>
    );
}

export default MarkerPopup;
