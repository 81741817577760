export enum ApiNotificationType {
    EMAIL = 'email',
    SMS = 'sms',
    VOICE = 'voice',
    PUSH_NOTIFICATION = 'push_notification'
}

export enum ApiNotificationStatus {
    SENT = 'sent',
    MISSING_PARAMETERS = 'missing_parameters',
    INVALID_PARAMETERS = 'invalid_parameters',
    SENDING_ERROR = 'sending_error',
    DISPATCHED = 'dispatched',
    UNKNOWN_TYPE = 'unknown_type',
    MISSING_TEMPLATE = 'missing_template'
}

export interface ApiNotification {
    clientId?: string;
    recipientId?: string;
    componentName?: string;
    deliveryTime?: unknown;
    taskId?: string;
    type?: ApiNotificationType;
    status?: ApiNotificationStatus;
    labels?: string[];
    data?: {
        date?: string;
        formattedDate?: string;
        numberOfDrivers?: number;
        [key: string]: unknown;
    };
}
