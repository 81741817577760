import { useMapUtils } from '~/hooks';
import { PlanRoute } from '~/data-classes';
import { routeHasMultipleTrips } from '~/utils/tripUtils';

const useTripCount = (routeData: PlanRoute) => {
    const { isPlanRouteMode } = useMapUtils();

    const { trips } = routeData;
    const tripCount = trips?.length || 0;

    const showTripCount =
        isPlanRouteMode && Boolean(tripCount) && routeHasMultipleTrips(trips);
    return {
        showTripCount,
        tripCount
    };
};

export default useTripCount;
