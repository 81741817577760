import classNames from 'classnames';
import React from 'react';
import { Icon } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize, IconSizes } from '~/ui/components/Icon/IconSizes';

interface ModalTitleProps {
    className?: string;
    dataTestId?: string;
    icon?: IconName;
    iconColor?: string;
    iconSize?: IconSize | IconSizes;
    title: string;
}

export const ModalTitle = ({
    className,
    dataTestId,
    icon,
    iconColor,
    iconSize,
    title
}: ModalTitleProps) => {
    const testIdPrefix = dataTestId ?? 'modal';

    const getClassName = () =>
        classNames('modal-title _d-flex _ai-center', {
            [`${className}__title`]: className
        });

    return (
        <div
            className={getClassName()}
            data-testid={`${testIdPrefix}-title-wrapper`}
        >
            {icon && (
                <Icon
                    icon={icon}
                    size={iconSize}
                    color={iconColor}
                    data-testid={`${testIdPrefix}-title-icon`}
                />
            )}
            <span data-testid={`${testIdPrefix}-title`}>{title}</span>
        </div>
    );
};
