import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '~/reducers/currentUserSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { LegacyRoles } from './types';

export const useLegacyRoles = () => {
    const mainClient = useSelector(selectMainClient);
    const currentUser = useSelector(selectCurrentUser);

    const { id: mainClientId } = mainClient || {};

    const isAdmin = useCallback(() => {
        try {
            const activeUserClient = (currentUser || {})?.access?.find(
                ({ client }: { client: string }) => client === mainClientId
            );
            const { roles = [] } = activeUserClient || {};
            return roles.includes(LegacyRoles.ADMINISTRATOR);
        } catch (e) {
            console.error(e);
            return false;
        }
    }, [currentUser, mainClientId]);

    return {
        isAdmin
    };
};
