import constants from '~/utils/constants';
import { useSelector } from 'react-redux';
import { ApiLiveStop } from '~/api/types';
import {
    EmittedEventHandler,
    OnDemandDispatchMarkerEventHandler
} from '~/ui/components/LiveStopMarker/types';
import { LiveStop, PlanStop } from '~/data-classes';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

interface UseGetStopEventDataProps {
    isSelected: boolean;
    stop: PlanStop | LiveStop;
    emittedEventHandler:
        | EmittedEventHandler
        | OnDemandDispatchMarkerEventHandler;
    onDemandDispatchMarkerEventHandler: OnDemandDispatchMarkerEventHandler;
}

export const useGetStopEventData = ({
    stop,
    isSelected,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler
}: UseGetStopEventDataProps) => {
    const selectedMapStops = useSelector(selectSelectedMapStops);

    const emitEvent = () => {
        if (stop instanceof PlanStop) {
            const { isTwoPart, clientRouteTaskId, taskId, markerCoordinates } =
                stop;
            return emittedEventHandler({
                event: constants.mapChildEvents.STOP_MOUSEUP,
                payload: {
                    isSelected,
                    isTwoPart,
                    id: clientRouteTaskId,
                    selectedMapStops,
                    taskId,
                    stopLevelData: stop as unknown as ApiLiveStop,
                    stopMarkerData: stop as unknown as LiveStop,
                    location: markerCoordinates,
                    stopId: taskId
                }
            });
        }

        const liveStop = new LiveStop(stop as unknown as ApiLiveStop);
        const { isTwoPart, id, stopId } = liveStop;
        return onDemandDispatchMarkerEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                isTwoPart,
                id,
                selectedMapStops,
                taskId: id,
                stopLevelData: liveStop.toJSON(),
                stopId
            }
        });
    };

    return { emitEvent };
};
