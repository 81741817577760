import React, { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { useGetSelectedClientTemplate } from '~/hooks';
import { ClientTemplate, ClientPreferences } from '~/api/types/ApiClient';
import { selectClients } from '~/reducers/clientsSlice';

export interface ReportsContext {
    selectedClientTemplate: ClientTemplate;
    selectedClientId: string;
    selectedClientName: string;
    selectedClientPreferences: ClientPreferences;
    selectedClientTimeZone?: string;
    setSelectedClientId: (id: string) => void;
}

export const ReportsPageContext =
    createContext<ReportsContext | undefined>(undefined);

type Props = {
    children: React.ReactChild;
};

export const ReportsContextProvider = ({ children }: Props): JSX.Element => {
    const mainClient = useSelector(selectMainClient);
    const mainClientId = mainClient?.id || '';

    const clients = useSelector(selectClients);

    const [selectedClientId, setSelectedClientId] = useState(mainClientId);

    const {
        name: selectedClientName,
        preferences: selectedClientPreferences,
        timezone: selectedClientTimeZone
    } = clients[selectedClientId] || {};

    const { templateData: selectedClientTemplate } =
        useGetSelectedClientTemplate(selectedClientId);

    return (
        <ReportsPageContext.Provider
            value={{
                selectedClientName,
                selectedClientPreferences,
                selectedClientId,
                selectedClientTemplate,
                selectedClientTimeZone,
                setSelectedClientId
            }}
        >
            {children}
        </ReportsPageContext.Provider>
    );
};
