import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectIsClusteringEquipment,
    selectShowEquipmentMarker,
    setIsClusteringEquipment,
    setShowEquipmentMarker
} from '~/reducers/mapSettingsSlice';

import { MapSettingMenuItem } from '~/ui';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

import '../map-settings-menu-sections.scss';

interface MapSettingMenuEquipmentMarkerSectionProps {
    mapRouteMode: ConfigurableMapRouteMode;
    isShowHeader: boolean;
}

export const MapSettingMenuEquipmentMarkerSection = ({
    mapRouteMode,
    isShowHeader
}: MapSettingMenuEquipmentMarkerSectionProps) => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const showEquipmentMarker = useSelector(
        selectShowEquipmentMarker(mapRouteMode)
    );
    const isClusteringEquipment = useSelector(
        selectIsClusteringEquipment(mapRouteMode)
    );

    const onEquipmentMarkerToggleStateChange = useCallback(
        (checked) => {
            dispatch(
                setShowEquipmentMarker({ mode: mapRouteMode, value: checked })
            );
        },
        [dispatch, mapRouteMode]
    );

    const onClusterModeStateChange = useCallback(
        (checked) => {
            dispatch(
                setIsClusteringEquipment({
                    mode: mapRouteMode,
                    value: checked
                })
            );
        },
        [dispatch, mapRouteMode]
    );

    const mapSettingMenuItemProps = [
        {
            iconName: 'equipmentPair',
            iconColor: 'galaxy-500' as unknown as undefined,
            text: t('equipment_marker'),
            checkedState: showEquipmentMarker,
            onChangeState: onEquipmentMarkerToggleStateChange
        },
        {
            iconName: 'stopClustering',
            text: t('equipmentCluster'),
            checkedState: isClusteringEquipment,
            onChangeState: onClusterModeStateChange
        }
    ];

    return (
        <div data-testid="map-settings-equipment-marker">
            {isShowHeader && (
                <span className="mapsettingssection_title">
                    {t('equipment')}
                </span>
            )}
            {mapSettingMenuItemProps.map((mapSettingMenuItem) => {
                const { text } = mapSettingMenuItem;
                return (
                    <MapSettingMenuItem key={text} {...mapSettingMenuItem} />
                );
            })}
        </div>
    );
};
