/* eslint-disable camelcase */

import { ApiStop } from './ApiStop';
import { ApiDriver } from './ApiDriver';
import { ApiVehicle } from './ApiVehicle';

export enum ApiRoutePlanType {
    CUSTOM = 'Custom',
    SCHEDULER = 'Scheduler',
    ROUTE_BUILDER = 'RouteBuilder'
}

export enum ApiRoutePlanSubType {
    SINGLE_DEPOT = 'SingleDepot',
    MULTI_DEPOT = 'MultiDepot',
    SEQUENTIAL_INSERTION = 'SequentialInsertion',
    MULTI_DAY_SCHEDULER = 'MultiDayScheduler',
    EDITED_SOLUTION = 'EditedSolution',
    TURBO_SCHEDULER = 'TurboScheduler'
}

export enum ApiRoutePlanEtaType {
    EUCLIDEAN = 'Euclidean',
    GOOGLE = 'Google',
    MIXED = 'Mixed',
    OSRM = 'OSRM',
    HERE = 'Here',
    GOOGLE_DIRECTIONS = 'GoogleDirections',
    ERROR_ETA = 'ErrorEta'
}

export enum ApiRoutePlanStage {
    UPDATE = 'Update',
    DONE = 'Done',
    ERROR = 'Error'
}

/**
 * Note: This is note the same type as ApiRoute
 * This interface is named this way because it is the typing of RoutePlan.routes[].routes[]
 */
export interface ApiRoutePlanRouteRoute {
    routeId?: string;
    vehicleId?: string;
    driverId?: string;
    driver?: ApiDriver;
    vehicle?: ApiVehicle;
    stops?: ApiStop[];
}

export interface ApiRoutePlanRoute {
    vehicleId?: string;
    driverId?: string;
    routes?: ApiRoutePlanRouteRoute[];
}

export interface ApiRoutePlan {
    id?: string;

    /** Date in ISO format */
    route_date?: string;
    client_id?: string;
    type?: ApiRoutePlanType;
    sub_type?: ApiRoutePlanSubType;
    eta_type?: ApiRoutePlanEtaType;
    total_travel_time?: number;
    total_waiting_time?: number;
    number_of_tasks?: number;
    number_of_extra_needed_vehicles?: number;
    number_of_violated_hard_tw?: number;
    total_delay?: number;
    total_service_time?: number;
    total_meters?: number;
    number_of_open_routes?: number;
    number_of_unassigned_tasks?: number;
    time_on_road?: number;
    number_of_violated_tw?: number;
    active?: boolean;
    valid?: boolean;
    stage?: ApiRoutePlanStage;

    /**
     * This is _not_ the same Route type as ApiRoute
     */
    routes?: ApiRoutePlanRoute[];

    scheduler_id?: string;
    num_of_overlaps?: number;
    num_of_stops_in_overlaps?: number;
    number_of_far_from_center?: number;
}
