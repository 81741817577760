import { useMutation } from 'react-query';
import TasksApi, { AddOrUpdateTaskData } from '~/api/TasksApi';
import { ApiTask, AxiosApiResponse, ReactQuerySideEffects } from '~/api/types';
import { getErrorMessage } from '~/utils/errorUtils';

export const useAddTask = () => {
    const { isLoading: isLoadingAddTask, mutateAsync: doAddTaskAsync } =
        useMutation((apiTask: AddOrUpdateTaskData): Promise<
            AxiosApiResponse<ApiTask>
        > => {
            return TasksApi.addTask(apiTask);
        });

    const addTask = async (
        apiTask: AddOrUpdateTaskData,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData: AxiosApiResponse<ApiTask> = await doAddTaskAsync(
                apiTask
            );
            onSuccess?.(successData);
        } catch (error) {
            const message = getErrorMessage(error);
            console.error(message);
            onError?.(error);
        }
    };

    return {
        isLoadingAddTask,
        addTask
    };
};
