import { useEffect, useState, useMemo } from 'react';
import Supercluster from 'supercluster';
import { markerUtils } from '~/utils/map';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { useMapUtils } from '~/hooks';

import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import { useRoutePlanStopEffects } from '~/components/MapPage/PlanMap/useRoutePlanStopEffects';
import constants from '~/utils/constants';

const clusterRadius = constants.mapOptionSettings.DEFAULT_CLUSTER_RADIUS;
const maxZoom = constants.mapOptionSettings.MAX_ZOOM;

type UseSuperClustersReturnValue = {
    schedules: Supercluster.PointFeature<Supercluster.AnyProps>[];
    superClusters: Supercluster.AnyProps[];
};

const useSuperClusters = (): UseSuperClustersReturnValue => {
    const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        routesLevelData,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapsAPI,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapInstance
    } = usePlanMapPropsContext();

    const { isStopsClustersMode } = useMapUtils();

    const {
        getParentClientRouteId,
        clientRouteIds: clientRouteIdsForSelectedMapRoutes
    } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { visibleOnMapPlanStops } = useRoutePlanStopEffects({
        getParentClientRouteId,
        clientRouteIdsForSelectedMapRoutes
    });
    const [schedules, setSchedules] = useState<
        Supercluster.PointFeature<Supercluster.AnyProps>[]
    >([]);

    useEffect(() => {
        if (!isStopsClustersMode) {
            return;
        }
        const schedulesEffect = [];
        for (const planStopRoute of visibleOnMapPlanStops) {
            const featureObjects = [];
            for (const planStop of planStopRoute) {
                if (planStop.isDepot) {
                    continue;
                }
                const { markerCoordinates } = planStop;
                const featureObject = markerUtils.getGeoJSONFeaturePoint(
                    markerCoordinates.lat,
                    markerCoordinates.lng,
                    planStop
                );
                featureObjects.push(featureObject);
            }
            schedulesEffect.push(featureObjects);
        }
        setSchedules([
            ([] as Supercluster.AnyProps[]).concat(...schedulesEffect)
        ] as unknown as Supercluster.PointFeature<Supercluster.AnyProps>[]);
    }, [visibleOnMapPlanStops, isStopsClustersMode]);

    const superClusters = useMemo(() => {
        const superClusterEffects = [];
        for (const schedule of schedules) {
            const superCluster = new Supercluster({
                radius: clusterRadius,
                maxZoom
            });
            superCluster.load(
                schedule as unknown as Supercluster.PointFeature<Supercluster.AnyProps>[]
            );
            superClusterEffects.push(superCluster);
        }
        return superClusterEffects;
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [schedules, mapInstance, mapsAPI]);

    return {
        schedules,
        superClusters
    };
};

export default useSuperClusters;
