import { DateTime } from 'luxon';
import { LiveStop } from '~/data-classes';
import { TimeWindow } from '~/data-classes/plan/PlanStop';

export const formatTime = (
    timeString: string,
    arrivalTime: string
): DateTime | null => {
    const match = timeString.match(/(\d+):(\d+) (AM|PM)/i);
    if (!match) {
        return null;
    }

    const [, hours, minutes, period] = match;
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);

    let formattedHours = hour;
    if (period.toUpperCase() === 'PM' && formattedHours !== 12) {
        formattedHours += 12;
    } else if (period.toUpperCase() === 'AM' && formattedHours === 12) {
        formattedHours = 0;
    }

    return DateTime.fromISO(arrivalTime, { zone: 'utc' })
        .set({
            hour: formattedHours,
            minute,
            second: 0,
            millisecond: 0
        })
        .toUTC();
};

export const getTimeWindowFromTime = (stopData: LiveStop): TimeWindow[] => {
    const { start: startTime, end: endTime } = stopData?.timeWindow[0] || {};
    const { arrivalTime } = stopData;

    const formattedStartTime = formatTime(startTime, arrivalTime);
    const formattedEndTime = formatTime(endTime, arrivalTime);

    if (!formattedStartTime || !formattedEndTime) {
        return [
            {
                start: '',
                end: ''
            }
        ];
    }

    let adjustedEndTime = formattedEndTime;
    if (formattedEndTime < formattedStartTime) {
        adjustedEndTime = formattedEndTime.plus({ days: 1 });
    }

    return [
        {
            start: formattedStartTime.toISO(),
            end: adjustedEndTime.toISO()
        }
    ];
};
