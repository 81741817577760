import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import {
    ColumnFiltersState,
    VisibilityState,
    SortingState,
    ColumnOrderState
} from '@tanstack/react-table';
import type { RootState } from '~/store';

interface LiveDispatchTableState {
    tasksColumnFilters: ColumnFiltersState;
    tasksColumnOrder: ColumnOrderState;
    tasksColumnVisibility: VisibilityState;
    tasksSorting: SortingState;
    sortConfig: SortingState;
    globalFilter: string;
}

export const initialState: LiveDispatchTableState = {
    tasksColumnFilters: [],
    tasksColumnOrder: [],
    tasksColumnVisibility: {},
    tasksSorting: [],
    sortConfig: [],
    globalFilter: ''
};

export const liveDispatchTableSlice = createSlice({
    name: 'liveDispatchTable',
    initialState,
    reducers: {
        setTasksColumnFilters: (
            state: LiveDispatchTableState,
            action: PayloadAction<ColumnFiltersState>
        ) => {
            return {
                ...state,
                tasksColumnFilters: action.payload
            };
        },

        setTasksColumnOrder: (
            state: LiveDispatchTableState,
            action: PayloadAction<ColumnOrderState>
        ) => {
            return {
                ...state,
                tasksColumnOrder: action.payload
            };
        },

        setTasksColumnVisibility: (
            state: LiveDispatchTableState,
            action: PayloadAction<VisibilityState>
        ) => {
            return {
                ...state,
                tasksColumnVisibility: action.payload
            };
        },

        setTasksSorting: (
            state: LiveDispatchTableState,
            action: PayloadAction<SortingState>
        ) => {
            return {
                ...state,
                tasksSorting: action.payload
            };
        },

        setSortConfig: (
            state: LiveDispatchTableState,
            action: PayloadAction<SortingState>
        ) => {
            return {
                ...state,
                sortConfig: action.payload
            };
        },

        setGlobalFilter: (
            state: LiveDispatchTableState,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                globalFilter: action.payload
            };
        },

        replaceLiveDispatchTable: (
            state: LiveDispatchTableState,
            action: PayloadAction<LiveDispatchTableState>
        ) => {
            return action.payload;
        },

        /**
         * Resets to a blank live dispatch table state
         */
        resetLiveDispatchTable: (): LiveDispatchTableState => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...initialState,
                ...state
            };
        });
    }
});

export const {
    setTasksColumnFilters,
    setTasksColumnOrder,
    setTasksColumnVisibility,
    setTasksSorting,
    replaceLiveDispatchTable,
    resetLiveDispatchTable,
    setSortConfig,
    setGlobalFilter
} = liveDispatchTableSlice.actions;

export const selectTasksColumnFilters = (
    state: RootState
): ColumnFiltersState => state.liveDispatchTable.tasksColumnFilters;

export const selectTasksColumnOrder = (state: RootState): ColumnOrderState =>
    state.liveDispatchTable.tasksColumnOrder;

export const selectTasksColumnVisibility = (
    state: RootState
): VisibilityState => state.liveDispatchTable.tasksColumnVisibility;

export const selectTasksSorting = (state: RootState): SortingState =>
    state.liveDispatchTable.tasksSorting;

export const selectSortConfig = (state: RootState): SortingState =>
    state.liveDispatchTable.sortConfig;

export const selectGlobalFilter = (state: RootState): string =>
    state.liveDispatchTable.globalFilter;

export default liveDispatchTableSlice.reducer;
