import React from 'react';

import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { IconButton } from '~/ui';

import './ManageStopControlListItemMetrics.scss';

interface IconData {
    /**
     * The icon data key
     */
    key: string;
    /**
     * The name of the icon
     */
    name: IconName;
    /**
     * The size of the icon
     */
    size: IconSizes;
    /**
     * The text to display beside the icon
     */
    text: string;
}

interface MetricsProps {
    /**
     * The list of icons data
     */
    iconsData?: IconData[];
}
export const ManageStopControlListItemMetrics = ({
    iconsData
}: MetricsProps) => {
    if (!iconsData?.length) return null;

    return (
        <div
            className="manage-stop-control-list-item-metrics _d-flex"
            data-testid="manage-stop-control-list-item-metrics"
        >
            {iconsData.map(({ key, name, size, text }) => (
                <IconButton
                    disabled
                    key={key}
                    icon={name}
                    iconSize={size}
                    text={text}
                />
            ))}
        </div>
    );
};
