import IframeMessagingAdaptor from '~/auth/IframeMessagingAdaptor';

/**
 * A singleton class that manages getting the session information from an authenticated origin.
 *
 * @category API
 *
 * @example
 * import { sessionManager, parseSearch } from '~/api/SessionManager';
 */

class SessionManager {
    constructor() {
        this.origin = null;
        this.crossDomainServerPath = null;
        this.crossDomainAuthenticator = null;
        this.refreshInterval = 0;
    }

    /**
     * Requests the data required to initialize axios from origin's local storage.
     * @returns {Promise<unknown>}
     */
    requestInitFromOrigin() {
        if (!this.crossDomainAuthenticator) {
            if (!this.origin) {
                throw Error('Origin is required for authentication');
            }
            if (!this.crossDomainServerPath) {
                throw Error('Path is required for authentication');
            }
            this.crossDomainAuthenticator = new IframeMessagingAdaptor(
                this.origin,
                this.crossDomainServerPath
            );
        }
        return this.crossDomainAuthenticator.requestInitialization();
    }

    /**
     * Pulls tokens (access, id, refresh) from origin and returns them
     * @async
     */
    async getTokensFromOrigin() {
        const parsedSearch = SessionManager.parseSearch();
        this.setOrigin(parsedSearch.origin);
        this.setPath(parsedSearch.path);
        const init = await this.requestInitFromOrigin();
        const tokens = {
            accessToken: init.requestHeaders.authorization,
            idToken: init.requestHeaders['x-oauth-idtoken'],
            refreshToken: init.requestHeaders['refresh-token']
        };
        return tokens;
    }

    /**
     * Sets the origin to the given origin
     * Is a no-op if origin is falsy or the same as the previous origin.
     * @param {string} origin
     */
    setOrigin(origin) {
        if (!origin || this.origin === origin) {
            return;
        }
        this.origin = origin;
        if (this.crossDomainServerPath) {
            this.crossDomainAuthenticator = new IframeMessagingAdaptor(
                origin,
                this.crossDomainServerPath
            );
        }
    }

    /**
     * Sets the path to the given path
     * Is a no-op if path is falsy or the same as the previous path.
     * @param {string} path
     */
    setPath(path) {
        if (!path || this.path === path) {
            return;
        }
        this.crossDomainServerPath = path;
        if (this.origin) {
            this.crossDomainAuthenticator = new IframeMessagingAdaptor(
                this.origin,
                path
            );
        }
    }

    /**
     * Utility function to parse and URI decode the current search parameters.
     * @returns {{}} param_name: decoded_value dictionary
     */
    static parseSearch() {
        return window.location.search
            .split('&')
            .reduce((parsedSearch, searchParam) => {
                const normalizedSearchParam = searchParam.replace('?', '');
                const [param, value] = normalizedSearchParam.split('=');
                parsedSearch[param] = decodeURIComponent(value);
                return parsedSearch;
            }, {});
    }
}

export const sessionManager = new SessionManager();
export const { parseSearch } = SessionManager;
