import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '~/ui';
import { printMap } from './utils';
import './PrintMapButton.scss';

export const PrintMapButton = () => {
    const { t } = useTranslation('common');
    const buttonText = t('print');

    return (
        <div
            className="print-map-button _p-absolute _jc-center _ai-center"
            data-testid="print-map-button"
        >
            <IconButton
                icon="print"
                iconColor="ocean"
                text={buttonText}
                onClick={() => printMap()}
            />
        </div>
    );
};
