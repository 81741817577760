import React, { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import Tooltip from '~/ui/components/Tooltip';

const TextOverflowTooltip = (
    props: React.ComponentPropsWithRef<typeof Tooltip>
) => {
    const [element, setElement] = useState<HTMLElement | null>(null);
    const [hasEllipsis, setHasEllipsis] = useState(false);

    useResizeObserver(element, ({ target }) =>
        setHasEllipsis(target.scrollWidth > target.clientWidth)
    );

    return <Tooltip disabled={!hasEllipsis} {...props} ref={setElement} />;
};

TextOverflowTooltip.propTypes = Tooltip.propTypes;

export default TextOverflowTooltip;
