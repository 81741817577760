import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentCustomer: {}
};

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCurrentCustomer: (state, action) => {
            state.currentCustomer = action.payload;
        },
        updateCurrentCustomer: (state, action) => {
            state.currentCustomer = {
                ...state.currentCustomer,
                ...action.payload
            };
        }
    }
});

export const { setCurrentCustomer, updateCurrentCustomer } =
    customersSlice.actions;

export default customersSlice.reducer;
