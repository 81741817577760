import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

interface LiveDispatchColumnVisibilityState {
    rowTaskType?: boolean;
    rowExtTaskType?: boolean;
    rowName?: boolean;
    rowLabels?: boolean;
    rowPickupStartTime?: boolean;
    rowPickupEndTime?: boolean;
    rowDeliveryStartTime?: boolean;
    rowDeliveryEndTime?: boolean;
    rowCustomerName?: boolean;
    rowCustomerAddress?: boolean;
    rowCustomerCity?: boolean;
    rowExtLinks?: boolean;
    rowNotes?: boolean;
    rowTaskPriority?: boolean;
}

const initialState: LiveDispatchColumnVisibilityState = {};

export const liveDispatchColumnVisibilitySlice = createSlice({
    name: 'liveDispatchColumnVisibility',
    initialState,
    reducers: {
        updateDropdownColumns: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return initialState;
        });
    }
});

export const { updateDropdownColumns } =
    liveDispatchColumnVisibilitySlice.actions;

export const selectLiveDispatchColumnVisibility = (
    state: RootState
): LiveDispatchColumnVisibilityState => state.liveDispatchColumnVisibility;

export default liveDispatchColumnVisibilitySlice.reducer;
