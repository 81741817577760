import * as XLSX from 'xlsx';
import * as XLSX_STYLE from 'xlsx-js-style';
import { saveAs } from 'file-saver';

const MAX_SHEETNAME_CHARACTERS = 31;

const excelOptions = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
};

function truncateExcelSheetName(name) {
    return name.substring(0, MAX_SHEETNAME_CHARACTERS);
}

function downloadFileBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    downloadFileUrl(url, filename);
}

function downloadFileUrl(url, filename) {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('target', '_blank');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

function createExcelSheet(data, options, name) {
    const sheets = {};
    sheets[name] = XLSX.utils.json_to_sheet(data, options);
    const currentSheet = sheets[name];

    const excludedSuffix = '2';
    const REQUIRED_FIELD_BG = '7CFC00';
    const OPTIONAL_FIELD_BG = 'FFFF00';

    const requiredValues = [
        'Departure_Date_Time_',
        'Location_ID_',
        'Description_',
        'Address_Line1_',
        'City_',
        'State_',
        'Zip_Code_',
        'Units_',
        'Weight_',
        'Service_Time_',
        'TW1_Open_Time_',
        'TW1_Close_Time_'
    ];

    Object.keys(currentSheet).forEach((key) => {
        if (
            key.endsWith(excludedSuffix) ||
            typeof currentSheet[key] === 'string'
        )
            return;

        const cellValue = currentSheet[key].v;
        const color = requiredValues.includes(cellValue)
            ? REQUIRED_FIELD_BG
            : OPTIONAL_FIELD_BG;

        currentSheet[key].s = {
            fill: { fgColor: { rgb: color } }
        };
    });

    return {
        SheetNames: [name],
        Sheets: sheets
    };
}

function string2ArrayBuffer(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
}

function getFilesFromDataTransferItems(items) {
    const files = [];
    for (const item of items) {
        if (item.kind === 'file') {
            files.push(item.getAsFile());
        }
    }
    return files;
}

function saveExcelSheet(excelData, fileName, sheetName) {
    const sheetNameStr = sheetName || truncateExcelSheetName(fileName);

    const newExcelFile = createExcelSheet(
        excelData,
        excelOptions,
        sheetNameStr
    );

    return saveAs(
        new Blob(
            [string2ArrayBuffer(XLSX_STYLE.write(newExcelFile, excelOptions))],
            {
                type: 'application/octet-stream'
            }
        ),
        fileName
    );
}

export default {
    downloadFileBlob,
    downloadFileUrl,
    createExcelSheet,
    string2ArrayBuffer,
    getFilesFromDataTransferItems,
    saveExcelSheet,
    truncateExcelSheetName
};
