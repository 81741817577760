import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormikContext } from 'formik';
import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import { ActionTypes, DuplicateTaskModalFormProps } from '../types';
import './DuplicateTaskModalActions.scss';

export const DuplicateTaskModalActions = () => {
    const rootClassName = 'duplicate-task-modal-actions';
    const { t } = useTranslation('duplicateTaskModal');
    const Label = ({ text }: { text: string }) => (
        <>
            {text}
            <span className={`${rootClassName}__optional`}>
                &nbsp;({t('optional')})
            </span>
        </>
    );

    const radioData: RadioInputData<ActionTypes>[] = [
        {
            id: `${rootClassName}-${ActionTypes.MOVE_INVOICE_OR_INVENTORY}`,
            label: <Label text={t('action.moveInvoiceOrInventory.label')} />,
            description: t('action.moveInvoiceOrInventory.description'),
            value: ActionTypes.MOVE_INVOICE_OR_INVENTORY
        },
        {
            id: `${rootClassName}-${ActionTypes.SET_VOLUME_AND_WEIGHT}`,
            label: <Label text={t('action.setVolumeAndWeight.label')} />,
            description: t('action.setVolumeAndWeight.description'),
            value: ActionTypes.SET_VOLUME_AND_WEIGHT,
            disabled: true
        }
    ];

    const { values } = useFormikContext<DuplicateTaskModalFormProps>();

    return (
        <RadioGroup
            radioData={radioData}
            value={values.actionType}
            name="actionType"
            className={rootClassName}
        />
    );
};
