import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import { ApiEquipment } from '~/api/types';

/**
 * The equipment state
 */
export type EquipmentState = Record<ApiEquipment['id'], ApiEquipment>;

/**
 * The equipment update value
 */
type EquipmentUpdate = Partial<Omit<ApiEquipment, 'id'>>;

/**
 * The payload when updating an equipment by ID
 */
interface EquipmentUpdatePayload {
    /**
     * The equipment ID
     */
    id: ApiEquipment['id'];
    /**
     * The equipment data to be updated
     */
    value: EquipmentUpdate;
}

/**
 * Equipment reducer actions
 */
export const equipmentSlice = createSlice({
    name: 'equipment',
    initialState: {} as EquipmentState,
    reducers: {
        /**
         * Set equipment data into state
         */
        setEquipment: (
            state: EquipmentState,
            action: PayloadAction<ApiEquipment[]>
        ): EquipmentState => {
            const equipmentPayload = action.payload;
            const newState = equipmentPayload.reduce(
                (aggregator: EquipmentState, apiEquipment: ApiEquipment) => {
                    aggregator[apiEquipment.id] = apiEquipment;
                    return aggregator;
                },
                {}
            );

            return {
                ...state,
                ...newState
            };
        },

        /**
         * Updates equipment data by ID into state
         */
        updateEquipmentById: (
            state: EquipmentState,
            action: PayloadAction<EquipmentUpdatePayload>
        ): EquipmentState => {
            const { id, value } = action.payload;

            if (!id) return state;

            return {
                ...state,
                [id]: { ...state[id], ...value }
            };
        },

        /**
         * Resets to a blank equipment state
         */
        resetEquipment: (): EquipmentState => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setEquipment, resetEquipment, updateEquipmentById } =
    equipmentSlice.actions;

/**
 * Selects the current equipment state
 */
export const selectEquipment = (state: RootState): EquipmentState =>
    state.equipment;

/**
 * Selects specific equipment from the current equipment state
 */
export const selectEquipmentById =
    (equipmentIds: string[]) => (state: RootState) => {
        const equipmentData = equipmentIds.reduce<ApiEquipment[]>(
            (compiled, equipmentId) => {
                const selectedEquipment = state.equipment[equipmentId];
                return selectedEquipment
                    ? [...compiled, selectedEquipment]
                    : compiled;
            },
            []
        );

        return equipmentData;
    };

export default equipmentSlice.reducer;
