import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectBreakDetailsId } from '~/reducers/selectedBreakDetailsSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { LiveStop } from '~/data-classes';

export const useIsLiveModalVisible = () => {
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const liveDriverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );

    const { isDispatchedRouteMode } = useMapUtils();
    const selectedBreakDetailsId = useSelector(selectBreakDetailsId);

    const isBreakDetailsValid = useMemo(() => {
        if (!selectedBreakDetailsId || !liveDriverData) return false;
        const liveDriverSchedule = liveDriverData.schedule as LiveStop[];
        return liveDriverSchedule.some((liveStop) => {
            const { id: breakDetailsId } = liveStop.breakDetails || {};

            return breakDetailsId === selectedBreakDetailsId;
        });
    }, [liveDriverData, selectedBreakDetailsId]);

    return isDispatchedRouteMode && isBreakDetailsValid;
};
