/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Grid, ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';
import { Box, FlexLayout, Icon, theme } from '~/ui';
import { useMergedRefs } from '~/ui/hooks';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right';

interface TooltipProps {
    /** Determine placement position of the tooltip */
    placement?: TooltipPlacement;
    /** Determine trigger of the tooltip (default is `hover`) */
    trigger?: 'click' | 'right-click' | 'hover' | 'focus';
    /** Variant of the tooltip (default is `default`) */
    variant?: 'default' | 'info' | 'warning';
    /** Gap between arrow and target (default is `md`) */
    gapSize?: 'md' | 'sm' | 'xs';
    /** Content of the controls found at the bottom of the tooltip */
    controls?: React.ReactNode;
    /** Content of the main part of the tooltip */
    content: React.ReactNode;
    /** Content of the title part of the tooltip */
    title?: React.ReactNode;
    /** Content of the left part of the tooltip (typically an icon) */
    leadingContent?: React.ReactNode;
    /** A render function for the target on which the tooltip opens.
     *  Must set the provided ref on the element to work! */
    children: React.ReactElement;
    /** Used to make controlled component */
    isVisible?: boolean;
    /** Used to disable tooltip */
    disabled?: boolean;
    sx?: ThemeUICSSObject;
    contentSx?: CSSProperties;
    arrowProps?: Record<string, unknown>;
    setRef?: React.Dispatch<React.SetStateAction<HTMLElement> | null>;
}

const arrowSideLength = '0.6rem';

const tooltipStyles: ThemeUIStyleObject = {
    zIndex: theme.zIndex.tooltip,
    padding: '0.8rem 1.2rem',
    borderRadius: '0.4rem',
    backgroundColor: 'galaxy-700',
    color: 'galaxy-100',
    fontSize: '1.4rem',
    lineHeight: '133%',
    maxWidth: '24rem',
    '[data-popper-arrow*="true"]': {
        height: `calc(2 * ${arrowSideLength})`,
        width: `calc(2 * ${arrowSideLength})`,
        '&::after': {
            borderStyle: 'solid',
            content: '""',
            position: 'absolute',
            borderColor: 'transparent',
            borderWidth: `${arrowSideLength}`
        }
    },
    '&[data-popper-placement*="bottom"] [data-popper-arrow*="true"]': {
        height: arrowSideLength,
        marginTop: `calc(-${arrowSideLength} + 1px)`,
        top: 0,
        '&::after': {
            borderBottomColor: 'galaxy-700',
            borderTopWidth: 0
        }
    },
    '&[data-popper-placement*="top"] [data-popper-arrow*="true"]': {
        height: arrowSideLength,
        marginBottom: `calc(-${arrowSideLength} + 1px)`,
        bottom: 0,
        '&::after': {
            borderTopColor: 'galaxy-700',
            borderBottomWidth: 0
        }
    },
    '&[data-popper-placement*="left"] [data-popper-arrow*="true"]': {
        width: arrowSideLength,
        marginRight: `calc(-${arrowSideLength} + 1px)`,
        right: 0,
        '&::after': {
            borderLeftColor: 'galaxy-700',
            borderRightWidth: 0
        }
    },
    '&[data-popper-placement*="right"] [data-popper-arrow*="true"]': {
        width: arrowSideLength,
        marginLeft: `calc(-${arrowSideLength} + 1px)`,
        left: 0,
        '&::after': {
            borderRightColor: 'galaxy-700',
            borderLeftWidth: 0
        }
    },

    animation: theme.transitions.getStandardEasingTransition({
        name: 'entry-animation'
    }),
    '@keyframes entry-animation': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    }
};

const gaps = { md: 8, sm: 4, xs: 0 };

const titleColors = {
    default: 'white',
    info: 'white',
    warning: 'mars-300'
};

const defaultIcons: Record<string, 'warning' | 'warningFill' | undefined> = {
    default: undefined,
    info: 'warning',
    warning: 'warningFill'
};

const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(function Tooltip(
    {
        children,
        leadingContent = null,
        title,
        placement,
        content,
        controls,
        variant = 'default',
        gapSize = 'md',
        trigger = 'hover',
        isVisible,
        disabled,
        sx = {},
        arrowProps,
        setRef,
        contentSx = {}
    },
    ref
) {
    // prevent tooltip display when content is empty
    if (!content) isVisible = false;
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef: setUsePopperTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({
        placement,
        visible: disabled ? false : isVisible,
        offset: [0, 6 + gaps[gapSize]],
        trigger
    });

    const setTooltipRef = (
        newRef: React.SetStateAction<HTMLElement | null>
    ) => {
        setUsePopperTooltipRef(newRef);
        setRef?.(newRef as React.SetStateAction<HTMLElement>);
    };

    const mergedRefs = useMergedRefs(setTriggerRef, ref);

    const titleColor = titleColors[variant];

    const childProps = { ref: mergedRefs };
    const reffedChild: React.ReactElement | null = React.isValidElement(
        children
    )
        ? React.cloneElement(children, childProps)
        : null;

    const {
        style: tooltipStyle,
        'data-popper-interactive': dataPopperInteractive
    } = getTooltipProps();
    const { style: boxStyle, 'data-popper-arrow': dataPopperArrow } =
        getArrowProps({ ...arrowProps });

    return (
        <>
            {reffedChild}

            {visible && (
                <FlexLayout
                    data-testid="tooltip"
                    className="tooltip"
                    flexDirection="column"
                    sx={{
                        ...tooltipStyles,
                        ...sx
                    }}
                    ref={setTooltipRef}
                    style={tooltipStyle as React.CSSProperties}
                    data-popper-interactive={dataPopperInteractive}
                >
                    <Grid columns={['auto 1fr']} gap="0">
                        {(leadingContent || defaultIcons[variant]) && (
                            <Grid
                                sx={{
                                    maxWidth: '2rem',
                                    gridArea: '1/1',
                                    marginRight: '0.8rem',
                                    color: titleColor
                                }}
                            >
                                {leadingContent || (
                                    <Icon icon={defaultIcons[variant]} />
                                )}
                            </Grid>
                        )}
                        {title && (
                            <Grid
                                sx={{
                                    marginBottom: '0.4rem',
                                    gridArea: '1/2',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    lineHeight: '2rem',
                                    color: titleColor
                                }}
                            >
                                {title}
                            </Grid>
                        )}
                        <Grid
                            sx={{
                                gridArea: title ? '2/2' : '1/2/2/2',
                                color: 'galaxy-200',
                                lineHeight: '1.6rem',
                                ...contentSx
                            }}
                        >
                            {content}
                        </Grid>
                    </Grid>

                    {controls}

                    <Box
                        style={boxStyle as React.CSSProperties}
                        data-popper-arrow={dataPopperArrow}
                    />
                </FlexLayout>
            )}
        </>
    );
});

export default Tooltip;
