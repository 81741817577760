import React, { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Button, Icon, InputTable } from '~/ui';
import { nonNegativeFloatValidator } from '~/hooks';

import { useAddTaskModalContext } from '../AddTaskModalContext';
import {
    clientTaskRequiredFieldMap,
    InventoryItem
} from '../utils/addTaskModalUtils';

import './InventoryTabContent.scss';
import { useSelector } from 'react-redux';
import { selectSelectedTaskType } from '~/reducers/addTaskSlice';

export const testId = 'inventoryTab_testId';
export const tableContainerTestId = 'inventoryTab_tableContainerTestId';

export const addNewRowButtonTestId = 'inventoryTab_addNewRowButtonTestId';

export const InventoryTabContent: React.FC = () => {
    const { t } = useTranslation(['addTask']);
    const selectedTaskType = useSelector(selectSelectedTaskType);
    const {
        clientTask: { inventoryItems },
        onInventoryItemsChange,
        inventoryItemsTabErrorState
    } = useAddTaskModalContext();

    const columns = useMemo<Column<InventoryItem>[]>(
        () => [
            {
                Header: t('fields.inventoryItems.fields.name.header'),
                id: 'name',
                required:
                    clientTaskRequiredFieldMap(selectedTaskType).inventoryItems
                        .requiredMap.name,
                accessor: ({ name }) => name
            },
            {
                Header: t('fields.inventoryItems.fields.trackingID.header'),
                id: 'trackingID',
                required:
                    clientTaskRequiredFieldMap(selectedTaskType).inventoryItems
                        .requiredMap.trackingID,
                accessor: ({ trackingID }) => trackingID
            },
            {
                Header: t('fields.inventoryItems.fields.quantity.header'),
                id: 'quantity',
                validator: nonNegativeFloatValidator,
                required:
                    clientTaskRequiredFieldMap(selectedTaskType).inventoryItems
                        .requiredMap.quantity,
                accessor: ({ quantity }) => quantity
            },
            {
                Header: t('fields.inventoryItems.fields.weight.header'),
                id: 'weight',
                validator: nonNegativeFloatValidator,
                required:
                    clientTaskRequiredFieldMap(selectedTaskType).inventoryItems
                        .requiredMap.weight,
                accessor: ({ weight }) => weight
            }
        ],
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        []
    );

    const updateData = useCallback(
        <K extends keyof Readonly<InventoryItem>>(
            rowIndex: number,
            inventoryItemKey: K,
            value: string
        ) => {
            const newInventoryItems = inventoryItems.map((inventoryItem, i) => {
                if (i === rowIndex) {
                    return {
                        ...inventoryItem,
                        [inventoryItemKey]: value
                    };
                }
                return inventoryItem;
            });

            onInventoryItemsChange(newInventoryItems);
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [inventoryItems]
    );

    const onAddNewRowClick = useCallback(() => {
        onInventoryItemsChange([...inventoryItems, {}]);
    }, [onInventoryItemsChange, inventoryItems]);

    return (
        <div className="inventoryTabContent" data-testid={testId}>
            <div className="inventory-tab_header-container">
                <div className="_text-1-alt">{t('inventoryTab.header')}</div>
                <div className="_text-2-alt">{t('inventoryTab.subHeader')}</div>
            </div>
            <div
                className="inventory-tab_table-container"
                data-testid={tableContainerTestId}
            >
                <InputTable
                    columns={columns}
                    data={inventoryItems}
                    updateData={updateData}
                    errorState={
                        inventoryItemsTabErrorState.inventoryItems?.errorState
                    }
                />
                <Button
                    type="link"
                    data-testid={addNewRowButtonTestId}
                    className="add-new-row-button"
                    onClick={onAddNewRowClick}
                >
                    <div className="button-layout">
                        <Icon icon="addLight" color="meteor" />
                        <div>{t('inventoryTab.addNewRowButton')}</div>
                    </div>
                </Button>
            </div>
        </div>
    );
};
