import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { keyBy, uniqBy } from 'lodash';

import { mapPlanStopUtils } from '~/utils/map';
import constants from '~/utils/constants';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import SinglePlanEditApi from '~/api/SinglePlanEditApi';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { selectClients } from '~/reducers/clientsSlice';
import { PlanStop } from '~/data-classes';
import { getTaskId } from '~/utils/stopUtils';
import { adjustTargetIndex, getTaskIdsByRouteId } from './utils';
import { PickupDeliveryModelType } from '~/api/types';
import { selectPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';

type ResequenceParams = {
    targetClientRouteId: string;
    targetRouteId: string;
    selectedStopIds: string[];
    targetStopNumber: number;
    isInsertAfter: boolean;
};

type UseStopResequenceReturn = {
    resequenceStops: ({
        targetClientRouteId,
        targetRouteId,
        targetStopNumber,
        selectedStopIds,
        isInsertAfter
    }: ResequenceParams) => void;
};

const useStopResequence = (): UseStopResequenceReturn => {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const { planStops, routesLevelData } = usePlanMapPropsContext();
    const planStopsLevelData = useSelector(selectPlanStopsLevelData);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const clients = useSelector(selectClients);

    const mappedStopsLevelData = planStopsLevelData.map(
        (stop: PlanStop) => new PlanStop(stop)
    );

    const getResolvedStops = useMemo(() => {
        return planStops.length > 0 ? planStops : mappedStopsLevelData;
    }, [planStops, mappedStopsLevelData]);
    const stopIdToStopMap = useMemo(() => {
        return keyBy(getResolvedStops, 'clientRouteTaskId');
    }, [getResolvedStops]);

    const resequenceStops = useCallback(
        ({
            targetClientRouteId,
            targetRouteId,
            targetStopNumber,
            selectedStopIds = selectedMapStopsIds,
            isInsertAfter = true
        }) => {
            if (!selectedStopIds?.length) {
                return;
            }

            const selectedPlanStops = selectedStopIds
                .map(
                    (clientRouteTaskId: string) =>
                        stopIdToStopMap[clientRouteTaskId]
                )
                .filter(Boolean);
            const [firstSelectedPlanStop] = selectedPlanStops;
            const selectedClient = clients[firstSelectedPlanStop?.clientId];

            const taskIdsByRouteId = getTaskIdsByRouteId(selectedStopIds);

            if (
                !selectedClient ||
                selectedPlanStops.length !== selectedStopIds.length ||
                mapPlanStopUtils.stopsBelongToDifferentParentRoutes(
                    selectedPlanStops,
                    routesLevelData
                )
            )
                return;

            const routePlanStops: PlanStop[] = Object.values(stopIdToStopMap)
                .filter(({ clientRouteId, taskId }) => {
                    return (
                        clientRouteId === targetClientRouteId &&
                        !selectedPlanStops.find(
                            ({ taskId: selectedTaskId }: { taskId: string }) =>
                                selectedTaskId === taskId
                        )
                    );
                })
                .sort(
                    (firstStop, secondStop) =>
                        (firstStop as PlanStop).stopNumber -
                        (secondStop as PlanStop).stopNumber
                );

            let targetIndex = routePlanStops.findIndex(
                ({ stopNumber }) => stopNumber === targetStopNumber
            );

            if (targetIndex === -1) {
                console.error('Cannot find the stop to insert');
                return;
            }

            const targetStop = routePlanStops[targetIndex];
            if (!targetStop) {
                console.error('Cannot find the stop to insert');
                return;
            }

            if (
                targetStop.isTwoPart &&
                selectedClient.preferences.pickupDeliveryModel ===
                    PickupDeliveryModelType.TAXICAB
            ) {
                targetIndex = adjustTargetIndex(targetIndex, routePlanStops);
            }

            let reorderedPlanStops;
            if (isInsertAfter) {
                reorderedPlanStops = [
                    ...routePlanStops.slice(0, targetIndex + 1),
                    ...selectedPlanStops,
                    ...(routePlanStops.length === targetIndex + 1
                        ? []
                        : routePlanStops.slice(targetIndex + 1))
                ];
            } else {
                reorderedPlanStops = [
                    ...routePlanStops.slice(0, targetIndex),
                    ...selectedPlanStops,
                    ...routePlanStops.slice(targetIndex)
                ];
            }

            const reorderedStopsWithoutDepots = uniqBy(
                reorderedPlanStops,
                (stop) => getTaskId(stop)
            ).filter(({ isDepot }) => !isDepot);

            const reorderedTaskIds: string[] = [];
            const reorderedStopIds: string[] = [];

            reorderedStopsWithoutDepots.forEach((planStop) => {
                const { stopId } = planStop;
                reorderedTaskIds.push(getTaskId(planStop));
                reorderedStopIds.push(stopId);
            });

            const numOfSelectedStops = selectedPlanStops.length;
            const processIndicatorState = {
                message: t('ResequenceStop', {
                    count: numOfSelectedStops
                }),
                type: constants.processIndicator.RESEQUENCE_STOP,
                payload: numOfSelectedStops,
                inProgress: true,
                error: false,
                position: 'center'
            };
            (async () => {
                dispatch(addProcessIndicator(processIndicatorState));
                try {
                    await SinglePlanEditApi.resequenceTasks({
                        clientId: firstSelectedPlanStop.clientId,
                        date: firstSelectedPlanStop.routeDate,
                        routeId: targetRouteId,
                        taskIdsByRouteId,
                        taskIds: reorderedTaskIds,
                        stopIds: reorderedStopIds,
                        clientPreferences: selectedClient.preferences
                    });
                    dispatch(resetSelectedMapStops());
                } catch (e) {
                    console.error(e);
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            inProgress: false,
                            error: true
                        })
                    );
                }
            })();
        },
        [
            clients,
            dispatch,
            routesLevelData,
            selectedMapStopsIds,
            stopIdToStopMap,
            t
        ]
    );

    return {
        resequenceStops
    };
};

export default useStopResequence;
