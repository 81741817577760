import { orderBy, uniq } from 'lodash';
import constants from '~/utils/constants';

/**
 * ID Utilities
 *
 * @category Utils
 * @module utils/idUtils
 *
 * @example
 * import { idUtils } from '~/utils/id-utils';
 */

/**
 * Returns a combined id in the form of "id1_id2_id3_..."
 * @param  {...string} ids array of ids to combine
 * @returns {string} combined id in the form of "id1_id2_id3_..."
 */
function getCombinedId(...ids) {
    return ids.join('_');
}

/**
 * Splits the combined id around the '_' separator
 * @param {string} combinedId id to split
 * @returns {string[]} the split id
 */
function splitCombinedId(combinedId) {
    if (typeof combinedId !== 'string') return [];

    return combinedId.split('_');
}

/**
 * Only keeps combinedId with unique task ids and removes task types from the task id
 * If the list contains 2 separate combined ids for a single two-part task, only one will be kept
 * @param {string[]} combinedIds
 * @returns {string[]}
 */
function getUniqueSingleAndTwoPartTaskIds(combinedIds) {
    const ids = combinedIds.map((id) => {
        const [clientId, routeId, taskTypeOrTaskId, twoPartTaskId] =
            splitCombinedId(id);
        const taskId = twoPartTaskId || taskTypeOrTaskId;
        return getCombinedId(clientId, routeId, taskId);
    });
    return uniq(ids);
}

/**
 * Analyzes the reassignment operation of the provided tasks to the
 * target route. This analysis is based on the encoding of the combined
 * task ids which contain their current route id and client id. The analysis
 * partitions the task ids by task status and provides additional metrics
 * such as whether all selected tasks are planned, unplanned, from the same
 * route or from the same client.
 * @param {string[]} combinedIds ids of tasks to be moved
 * @param {string} targetClientRouteId client route id of target route
 * @returns {*} object containing analysis results
 */
function analyzeCombinedIds(combinedIds, targetClientRouteId) {
    const unplanned = constants.entityStates.UNPLANNED;
    let targetClientId;
    if (targetClientRouteId) {
        [targetClientId] = splitCombinedId(targetClientRouteId);
    }
    const analysis = {
        areAllPlanned: true,
        areAllUnplanned: true,
        areAllFromOneClientRoute: true,
        areAllFromTargetClient: true,
        plannedTaskIds: [],
        unplannedTaskIds: [],
        numTasksToRemoveByClientRouteId: {}
    };
    const ids = getUniqueSingleAndTwoPartTaskIds(combinedIds);
    for (const combinedId of ids) {
        const [clientId, routeId, taskId] = splitCombinedId(combinedId);
        const clientRouteId = getCombinedId(clientId, routeId);
        if (routeId === unplanned) {
            analysis.areAllPlanned = false;
            analysis.unplannedTaskIds.push(taskId);
        } else {
            analysis.areAllUnplanned = false;
            analysis.plannedTaskIds.push(taskId);
        }
        if (!analysis.numTasksToRemoveByClientRouteId[clientRouteId]) {
            analysis.numTasksToRemoveByClientRouteId[clientRouteId] = 1;
        } else {
            analysis.numTasksToRemoveByClientRouteId[clientRouteId]++;
        }
        if (clientId !== targetClientId) {
            analysis.areAllFromTargetClient = false;
        }
    }
    if (Object.keys(analysis.numTasksToRemoveByClientRouteId).length > 1) {
        analysis.areAllFromOneClientRoute = false;
    }
    return analysis;
}

/**
 * For two-part task stop ids in the form of clientId_routeId_taskType_taskId,
 * return the complementary id for the stop of the other type.
 * @param {string} stopId stop id for which to get the complement
 * @returns {string} the complementary id
 */
function getComplementaryStopId(stopId) {
    const { DELIVERY, PICKUP } = constants.taskTypes;
    if (stopId.includes(DELIVERY)) {
        return stopId.replace(DELIVERY, PICKUP);
    }
    if (stopId.includes(PICKUP)) {
        return stopId.replace(PICKUP, DELIVERY);
    }
}

/**
 * Check if the id is for a two-part task stop in the form of clientId_routeId_taskType_taskId
 * @param {string} stopId stop id to test
 * @returns {boolean} whether it is a two-part task stop id
 */
function isTwoPartTaskStopId(stopId) {
    const { DELIVERY, PICKUP } = constants.taskTypes;

    const idParts = splitCombinedId(stopId);

    return idParts.length === 4 && [DELIVERY, PICKUP].includes(idParts[2]);
}

/**
 * Check if the id is for an unassigned task
 * @param {string} clientRouteTaskId client route task id to test
 * @returns {boolean} whether it is an unassigned client route task id
 */
function isUnassignedTaskStopId(clientRouteTaskId) {
    return constants.entityStates.UNPLANNED === clientRouteTaskId.split('_')[1];
}

/**
 * Check if the id is for a two-part task delivery stop in the form of clientId_routeId_delivery_taskId
 * @param {string} stopId stop id to test
 * @returns {boolean} whether it is a two-part task delivery stop id
 */
function isTwoPartTaskStopDeliveryId(stopId) {
    if (!isTwoPartTaskStopId(stopId)) return false;

    const { DELIVERY } = constants.taskTypes;
    return stopId.indexOf(DELIVERY) > 0;
}

/**
 * Get the ordered two-part task stop ids for stop id in the form clientId_routeId_taskType_taskId
 * @param {string} clientRouteTaskId the stop id whose complement to return
 * @returns {Array} The ordered two-part task stop ids
 */
function getTwoPartTaskIds(clientRouteTaskId) {
    return orderBy(
        [clientRouteTaskId, getComplementaryStopId(clientRouteTaskId)],
        undefined,
        ['desc']
    );
}

export const idUtils = {
    getCombinedId,
    splitCombinedId,
    getUniqueSingleAndTwoPartTaskIds,
    analyzeCombinedIds,
    getComplementaryStopId,
    getTwoPartTaskIds,
    isTwoPartTaskStopId,
    isTwoPartTaskStopDeliveryId,
    isUnassignedTaskStopId
};
