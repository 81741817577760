import axios from 'axios';

import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import { AxiosApiResponse, InterstellarResults } from './types';

interface GetWebPlanApiParams {
    /**
     * Date in ISO format
     */
    date?: string;

    type?: keyof typeof constants.productTypes;

    clients?: string[];
    detail: 'stops' | 'routes' | 'clients';
    /* eslint-disable camelcase */
    client_route_ids?: string[];
}

interface DetailToResultsMap {
    clients: InterstellarResults['client'];
    routes: InterstellarResults['routes'];
    stops: InterstellarResults['stops'];
}

type GetWebPlanApiResponse<Detail extends GetWebPlanApiParams['detail']> =
    AxiosApiResponse<DetailToResultsMap[Detail]>;

/**
 * Implementations of API methods under the /web/planSolution path
 *
 * @category API
 */
export class WebPlanApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/web/planSolution';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'web_planSolution';

    static get(
        clientId: string,
        params: GetWebPlanApiParams
    ): Promise<GetWebPlanApiResponse<typeof params.detail>> {
        if (!clientId) {
            return Promise.reject('clientId not provided');
        }
        if (!params.date || !params.type) {
            return Promise.reject(
                'missing date or type querystring parameters'
            );
        }

        return axios.get(this.path, {
            params,
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static getRoutesLevelData(date: string, clientIds: string[] = []) {
        const dateInvalidReason = dateUtils.isDateInvalid(date);
        if (dateInvalidReason) {
            return Promise.reject(dateInvalidReason);
        }
        if (!Array.isArray(clientIds)) {
            return Promise.reject('clientIds must be an array');
        }
        return axios.get(this.path, {
            params: {
                detail: 'routes',
                date,
                clients: clientIds
            }
        });
    }
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static getRoutesLevelDataWithStats(date: string, clientIds: string[] = []) {
        const dateInvalidReason = dateUtils.isDateInvalid(date);
        if (dateInvalidReason) {
            return Promise.reject(dateInvalidReason);
        }
        if (!Array.isArray(clientIds)) {
            return Promise.reject('clientIds must be an array');
        }
        return axios.get(this.path, {
            params: {
                detail: 'planPage',
                date,
                clients: clientIds
            }
        });
    }
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static getStopsLevelData(date: string, clientRouteIds: string[] = []) {
        const dateInvalidReason = dateUtils.isDateInvalid(date);
        if (dateInvalidReason) {
            return Promise.reject(dateInvalidReason);
        }
        const isClientRouteIdsValid =
            clientRouteIds &&
            Array.isArray(clientRouteIds) &&
            clientRouteIds.length;
        if (!isClientRouteIdsValid) {
            return Promise.reject('clientRouteIds must be an array');
        }
        return axios.get(this.path, {
            params: {
                detail: 'stops',
                date,
                client_route_ids: clientRouteIds
            }
        });
    }
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */

    static checkPlanSolutionResponseDataHasValues(
        responseData: []
    ): number | boolean {
        return Array.isArray(responseData) && responseData.length;
    }

    /**
     * Sends a DELETE /web/planSolution to delete web plan solution
     *
     * @param {string} clientId target client id
     * @returns {IApiResponse}
     */
    static delete(clientId: string): Promise<AxiosApiResponse> {
        if (!clientId) return Promise.reject('Missing client id');

        return axios.delete(`${this.path}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }
}
