import { useDispatch } from 'react-redux';
import { useDriverBreak } from '~/hooks';
import { resetSelectedBreakDetails } from '~/reducers/selectedBreakDetailsSlice';
import { useGetParams } from './useGetParams';

type ReturnProps = {
    handleDeselect: () => void;
    handleEdit: (durationSeconds: number) => void;
    handleDelete: () => void;
    handleResequence: (stopId: string) => void;
};

export const useButtonHandlers = (): ReturnProps => {
    const dispatch = useDispatch();
    const { date, breakRuleId, clientId, routeId } = useGetParams();

    const { deleteBreak, editBreak } = useDriverBreak();

    const handleDeselect = () => {
        dispatch(resetSelectedBreakDetails());
    };

    const handleDelete = () => {
        deleteBreak({ clientId, routeId, date, breakRuleId });
    };

    const handleResequence = (stopId: string) => {
        editBreak({
            clientId,
            routeId,
            date,
            breakRuleId,
            stopIdBeforeBreak: stopId
        });
    };

    const handleEdit = (durationSeconds: number) => {
        editBreak({
            clientId,
            routeId,
            date,
            breakRuleId,
            durationSeconds
        });
    };

    return {
        handleDeselect,
        handleDelete,
        handleResequence,
        handleEdit
    };
};
