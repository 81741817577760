import _ from 'lodash';
import constants from '~/utils/constants';
import { AssignedWebColor } from '~/api/types';
import { PlanRoute, PlanStop } from '~/data-classes';
import { planDataFactory } from '~/utils/data-factory';
import { idUtils } from '~/utils/id-utils';

export const getDebouncedSetPlanStops = (
    handler: () => void
): _.DebouncedFunc<() => void> => {
    return _.debounce(handler, constants.timings.SET_CONTENT);
};

export const getPlanStopsEffect = ({
    planStopsLevelData,
    clientRouteIds,
    getParentClientRouteId,
    markerColorByClientRoute
}: {
    planStopsLevelData: Record<string, unknown>[];
    clientRouteIds: string[];
    getParentClientRouteId: (d: string) => string | undefined;
    markerColorByClientRoute?: Record<string, AssignedWebColor> | null;
}): PlanStop[] => {
    const planStopsEffect = [];

    for (const planStopData of planStopsLevelData) {
        const planStop = planDataFactory.makePlanStop(planStopData);
        const { clientRouteId } = planStop;
        const isSelected = clientRouteIds.includes(clientRouteId);
        const parentRouteId = getParentClientRouteId(clientRouteId);
        const colorCSS =
            parentRouteId && markerColorByClientRoute?.[parentRouteId];

        if (!isSelected || !colorCSS) continue;

        planStop.colorCSS = colorCSS;
        planStopsEffect.push(planStop);
    }

    return planStopsEffect;
};

export const getParentClientRouteIdsWithReloadIds = (
    planRoutes: PlanRoute[]
): Record<string, string[]> => {
    return planRoutes.reduce(
        (aggregator: Record<string, string[]>, parent: PlanRoute) => {
            const { clientRouteId, clientRouteIds } = parent;
            const currentList = aggregator[clientRouteId] || [];
            currentList.push(...clientRouteIds);
            aggregator[clientRouteId] = currentList;

            return aggregator;
        },
        {}
    );
};

interface AddTripInfoArgs {
    planStopsLevelData: Record<string, string | number>[];
    planRoutes: PlanRoute[];
}
export const addTripInfoToStops = ({
    planStopsLevelData,
    planRoutes
}: AddTripInfoArgs) => {
    const topLevelClientRouteIds =
        getParentClientRouteIdsWithReloadIds(planRoutes);

    return planStopsLevelData.map((stop) => {
        let { clientRouteId } = stop;
        const { clientId, routeId } = stop;
        clientRouteId =
            clientRouteId ||
            idUtils.getCombinedId(clientId as string, routeId as string);

        const topLevelClientRouteId = topLevelClientRouteIds[clientRouteId];
        // belongs to the main route
        if (topLevelClientRouteId?.length) {
            // only 1 trip in a route
            if (topLevelClientRouteId.length === 1) {
                return stop;
            }
            const tripIndex = topLevelClientRouteId.indexOf(
                clientRouteId as string
            );
            if (tripIndex !== -1) {
                return { ...stop, tripNumber: tripIndex + 1 };
            }
            return { ...stop };
        }

        return Object.keys(topLevelClientRouteIds).reduce(
            (aggregator, parentClientRouteId) => {
                const tripIds = topLevelClientRouteIds[parentClientRouteId];
                const tripIndex = tripIds.indexOf(clientRouteId as string);

                if (tripIds.length > 1 && tripIndex !== -1) {
                    return {
                        ...stop,
                        tripNumber: tripIndex + 1
                    };
                }

                return aggregator;
            },
            stop
        );
    });
};
