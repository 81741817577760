import { SortingState } from '@tanstack/react-table';

import {
    BaseConfig,
    ApiTask,
    ApiTaskScheduler,
    AxiosApiResponse,
    DeliveryTask,
    ExtentParams,
    PaginationParams,
    PickupTask,
    TaskStatus
} from '../types';

import { DateConstructorInput } from '~/utils/date-utils-converters';

/**
 * GET /tasks payload type
 * @category API
 */
export interface GetTasksParams extends PaginationParams, ExtentParams {
    /**
     * The date of the tasks we want to fetch
     */
    date?: DateConstructorInput;

    /**
     * A key, comma-separated list of keys, or array of keys designating related entities that
     * should be loaded with the task
     *
     * This parameter is not compatible with the `sort` parameter
     *
     * IF `extent` is provided along with `sort`
     *
     * @example
     * // return each `Task` with the related pickupLocations and deliveryLocations
     * extent: 'pickupLocation,deliveryLocation'
     */
    extent?: string | string[];

    /**
     * An array of sortable columns to sort the tasks response by
     *
     * This parameter is not compatible with the `extent` parameter
     */
    sort?: SortingState;

    status?: TaskStatus[];
}

export interface GetTasksConfig extends BaseConfig {
    params?: Omit<GetTasksParams, 'date' | 'sort'> & {
        date?: string;
        sort?: string;
    };
}

export interface TaskMetrics {
    /**
     * The count of `canceled` tasks
     */
    canceled?: number;
    /**
     * The count of `completed` tasks
     */
    completed?: number;
    /**
     * The count of `dispatched` tasks
     */
    dispatched?: number;
    /**
     * The count of `planned` tasks
     */
    planned?: number;
    /**
     * The count of `total` tasks
     */
    total?: number;
    /**
     * The count of `unassigned` tasks
     */
    unassigned?: number;
}

export interface StartSchedulerParams {
    date?: string;
    rerun?: boolean;
}

export interface StartSchedulerArgs extends Omit<StartSchedulerParams, 'date'> {
    date?: DateConstructorInput;
}

export type StartSchedulerResponse = AxiosApiResponse<ApiTaskScheduler>;

export type StopSchedulerResponse = AxiosApiResponse<ApiTaskScheduler>;

export type AddOrUpdateTaskData = DeepPartial<
    ApiTask & {
        deliveryEuid?: string;
        pickupEuid?: string;
    }
>;

export type UpdateResponse = AxiosApiResponse<ApiTaskScheduler>;

export type DeleteTaskResponse = AxiosApiResponse<ApiTask>;

export interface DeleteTasksResponseData {
    deletedCount?: number;
}

export type DeleteTasksResponse = AxiosApiResponse<DeleteTasksResponseData>;

export interface TaskLockTasksResponseData {
    modifiedTasksCount: number;
}

export type TaskLockTasksResponse = AxiosApiResponse<TaskLockTasksResponseData>;

export interface OrderLockTasksResponseData {
    modifiedTasksCount: number;
}

export type OrderLockTasksResponse =
    AxiosApiResponse<OrderLockTasksResponseData>;

export interface UnlockTasksResponseData {
    modifiedTasksCount: number;
}

export type UnlockTasksResponse = AxiosApiResponse<UnlockTasksResponseData>;

export interface UnassignTaskResponseData {
    message: string;
}

export interface JoinTasksParams {
    /**
     * The ID of the base task
     */
    id: string;

    /**
     * The ID of the task to join into the base task
     */
    joinedTask: string;
}

export interface SplitTaskResponseData {
    /**
     * The delivery task data from the split two-part task
     */
    delivery?: DeliveryTask;

    /**
     * The pickup task data from the split two-part task
     */
    pickup?: PickupTask;
}

export type UnassignTaskResponse = AxiosApiResponse<UnassignTaskResponseData>;

/* eslint-disable camelcase */
export interface GetMetricsParams {
    date?: DateConstructorInput | null;
    start_date?: DateConstructorInput;
    end_date?: DateConstructorInput;
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export interface GetMetricsConfig extends BaseConfig {
    params?: Omit<GetMetricsParams, 'date' | 'start_date' | 'end_date'> & {
        date?: string[];
        start_date?: string;
        end_date?: string;
    };
}
/* eslint-enable camelcase */

export interface GetMetricsResponseData {
    [key: string]: TaskMetrics;
}

export type GetMetricsResponse = AxiosApiResponse<TaskMetrics>;

export interface GeneratePairingsParams {
    taskIds?: string[];
    unassignedTaskIds?: string[];
}

export interface GenerateDepotPairsParams {
    taskIds?: string[];
}

export interface GenerateDepotPairsResponseData {
    tasksModifiedCount?: number;
    unacceptedTaskIds?: string[];
}

export type GenerateDepotPairsResponse =
    AxiosApiResponse<GenerateDepotPairsResponseData>;

/**
 * GET /tasks/{id} payload type
 * @category API
 */
export type GetTaskParams = ExtentParams;

/**
 * Possible errors for /tasks API
 * @category API
 */
export enum TasksApiErrors {
    MAX_SORT_FIELDS = 'You can only sort a maximum of 5 fields',
    INCOMPATIBLE_SORT_EXTENT_FIELDS = 'Invalid parameters, extent cannot be used with sort',
    MISSING_DRIVER_ID = 'Missing driverId parameter',
    MISSING_DATE = 'Missing date parameter',
    MISSING_TASK_ID = 'Missing taskId parameter',
    MISSING_TASKIDS = 'Missing taskIds parameter',
    INVALID_DRIVER_ID = 'Invalid driverId parameter, must be a UUID string',
    INVALID_DATE = 'Invalid date parameter, must be ISO-formatted string (eg yyyy-mm-dd)',
    INVALID_TASKIDS = 'Invalid taskIds parameter, must be an array of UUID strings',
    FAILED_TO_UNASSIGN = 'Failed to unassign tasks before reassignment'
}
