import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlanStop } from '~/data-classes';
import { selectPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { selectBreakDetails } from '~/reducers/selectedBreakDetailsSlice';
import { mapPlanStopUtils } from '~/utils/map';
import { getRouteSequenceNumber } from '~/utils/stopUtils';

export const useGetFilteredStops = (inputValue: string) => {
    const planStopsLevelData = useSelector(selectPlanStopsLevelData);
    const selectedBreak = useSelector(selectBreakDetails);

    const isMatchingInputValue = (planStop: PlanStop) => {
        const { stopName } = planStop;
        const stopNumber = getRouteSequenceNumber(planStop);

        return (
            `${stopNumber}. ${stopName}`
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
        );
    };

    return useMemo(() => {
        if (!planStopsLevelData || !inputValue) return [];

        const planStopsWithoutBreaks = mapPlanStopUtils.filterStopsWithoutBreak(
            planStopsLevelData,
            selectedBreak?.routeId
        ) as PlanStop[];

        return planStopsWithoutBreaks.filter(isMatchingInputValue);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [inputValue, planStopsLevelData]);
};
