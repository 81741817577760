/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import fileDownload from 'js-file-download';
import * as XLSX from 'xlsx';

import { CustomCostMatrixApi } from '~/api/CustomCostMatrixApi';
import { TaskServicesApi } from '~/api/TaskServicesApi';
import { zonesAPI } from '~/api/ZonesApi';
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';
import constants from '~/utils/constants';
import convertToJson, { SpreadsheetConversionError } from './convertToJson';
import convertToSpreadsheet from './convertToSpreadsheet';

export const useCustomCostConfiguration = (selectedClientId) => {
    const [isBusy, setIsBusy] = useState(false);
    const [hasZonesData, setHasZonesData] = useState(false);
    const [hasMatrixData, setHasMatrixData] = useState(false);
    const [hasTaskServiceData, setHasTaskServiceData] = useState(false);
    const queryClient = useQueryClient();

    const {
        isFetching: zonesIsFetching,
        data: zonesData,
        refetch: zonesFetch
    } = useQuery(
        constants.reactQueryKeys.ZONES_CUSTOM_COST_CONFIG,
        async () => {
            const response = await zonesAPI.get();
            const rawZoneData = response.data.data;

            return rawZoneData.reduce((all, zone) => {
                const {
                    id,
                    properties: { name }
                } = zone;
                all[id] = { zoneId: id, zoneName: name };
                return all;
            }, {});
        },
        {
            enabled: false,
            onSettled: (data) => {
                const hasData = Object.keys(data).length > 0;
                setHasZonesData(hasData);
            }
        }
    );

    const {
        isFetching: matrixIsFetching,
        data: matrixData,
        refetch: matrixFetch
    } = useQuery(
        CustomCostMatrixApi.REACT_QUERY_KEY,
        async () => {
            const response = await CustomCostMatrixApi.get();
            const rawMatrixData = response.data.data[0];
            return rawMatrixData;
        },
        {
            enabled: false,
            retry: false,
            onSettled: (data) => {
                const hasData = Boolean(data);
                setHasMatrixData(hasData);
            }
        }
    );

    const {
        isFetching: taskServiceIsFetching,
        data: taskServiceData,
        refetch: taskServiceFetch
    } = useQuery(
        TaskServicesApi.REACT_QUERY_KEY,
        async () => {
            const response = await TaskServicesApi.get();
            const rawTaskServiceData = response.data.data[0];
            return rawTaskServiceData;
        },
        {
            enabled: false,
            retry: false,
            onSettled: (data) => {
                const hasData = Boolean(data);
                setHasTaskServiceData(hasData);
            }
        }
    );

    const { mutateAsync: deleteFormula } = useMutation(
        (matrixId) => {
            return CustomCostMatrixApi.delete(matrixId);
        },
        {
            onSuccess: () => {
                setHasMatrixData(false);
                return queryClient.invalidateQueries(
                    CustomCostMatrixApi.REACT_QUERY_KEY
                );
            }
        }
    );

    const { mutateAsync: patchFormula } = useMutation(
        ({ matrixId, payload }) => {
            return CustomCostMatrixApi.patch(matrixId, payload);
        }
    );

    const { mutateAsync: uploadFormula } = useMutation((customCostMatrix) => {
        return CustomCostMatrixApi.post(customCostMatrix);
    });

    const { mutateAsync: addTaskService } = useMutation(
        ({ clientId, payload }) => {
            return TaskServicesApi.post(clientId, payload);
        },
        {
            onSuccess: () => {
                setHasTaskServiceData(true);
                return queryClient.invalidateQueries(
                    TaskServicesApi.REACT_QUERY_KEY
                );
            }
        }
    );

    useEffect(() => {
        zonesFetch();
        matrixFetch();
        taskServiceFetch();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        const isFetching =
            zonesIsFetching || matrixIsFetching || taskServiceIsFetching;
        setIsBusy(isFetching);
    }, [zonesIsFetching, matrixIsFetching, taskServiceIsFetching]);

    const processConvertToJson = async (event) => {
        const [uploadedFile] = event.target.files;
        const data = await uploadedFile.arrayBuffer();
        const { customCostMatrix, taskServices, errors } = convertToJson(
            data,
            zonesData
        );
        if (errors) {
            throw new SpreadsheetConversionError(
                'Spreadsheet conversion error',
                errors.map((item) => item.errors).flat()
            );
        }
        return { customCostMatrix, taskServices };
    };

    const deleteCurrent = async (sideEffects = {}) => {
        await deleteFormula(matrixData.id, sideEffects);
    };

    // @todo: update to include client's predefined zones
    const downloadBlank = async () => {
        const res = await axios({
            url: terms.downloadUrl,
            method: 'GET',
            baseURL: process.env.PUBLIC_URL,
            responseType: 'blob',
            params: {
                v: Date.now()
            }
        });

        fileDownload(res.data, terms.downloadFilename);
    };

    const downloadCurrent = () => {
        const downloadFilename = `${
            terms.downloadFormulaFilename
        }_${Date.now()}.xlsx`;
        const wb = convertToSpreadsheet(matrixData, zonesData, taskServiceData);
        XLSX.writeFile(wb, downloadFilename);
    };

    const replaceCurrent = async (event, sideEffects = {}) => {
        const matrixId = matrixData.id;
        const { onSuccess: onSuccessReplace, onError: onErrorReplace } =
            sideEffects;

        try {
            // attempt conversion
            const { customCostMatrix } = await processConvertToJson(event);

            // set current to inactive
            await patchFormula({ matrixId, payload: { active: false } });

            // attempt to upload formula
            const successData = await uploadFormula(customCostMatrix);

            // delete previous formula and refresh active matrix data
            await deleteFormula(matrixId);
            await matrixFetch();

            return onSuccessReplace && onSuccessReplace(successData);
        } catch (error) {
            console.error(error.message);

            if (!(error instanceof SpreadsheetConversionError)) {
                // restore previous formula when `uploadFormula` fails
                await patchFormula({ matrixId, payload: { active: true } });
            }
            return onErrorReplace && onErrorReplace(error);
        }
    };

    const uploadNew = async (event, sideEffects = {}) => {
        const { onSuccess: onSuccessUpload, onError: onErrorUpload } =
            sideEffects;

        try {
            // attempt conversion
            const { customCostMatrix, taskServices } =
                await processConvertToJson(event);

            // @note:
            // + attempt to set-up task services, if not available
            // + this is done only as a one-time operation on first upload
            if (!hasTaskServiceData) {
                await addTaskService({
                    clientId: selectedClientId,
                    payload: taskServices
                });
                await taskServiceFetch();
            }

            // attempt to upload formula
            const successData = await uploadFormula(customCostMatrix);

            // refresh active matrix data
            await matrixFetch();

            return onSuccessUpload && onSuccessUpload(successData);
        } catch (error) {
            console.error(error.message);
            return onErrorUpload && onErrorUpload(error);
        }
    };

    return {
        isBusy,
        hasZonesData,
        hasMatrixData,
        hasTaskServiceData,
        matrixData,
        deleteCurrent,
        downloadBlank,
        downloadCurrent,
        replaceCurrent,
        uploadNew
    };
};
