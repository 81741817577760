/* eslint-disable camelcase */
import _ from 'lodash';
import {
    CustomCostMatrixAttributeType,
    CustomCostMatrixTermType,
    CustomCostMatrixCalculationType
} from '~/api/types';
import {
    customCostMatrixUtils,
    customCostMatrixConstants as terms
} from '~/utils/custom-cost-matrix-utils';
import { idUtils } from '~/utils/id-utils';

export const getErrorObj = ({
    errorKey,
    fixKey,
    sheetName,
    row,
    col,
    ...extra
}) => {
    const results = {
        key: idUtils.getCombinedId(sheetName, row, col, errorKey),
        errorKey,
        fixKey,
        params: {
            sheetName,
            row,
            col,
            ...extra
        }
    };

    return results;
};

const getTestResults = (
    {
        start_zone_name,
        end_zone_name,
        custom_cost_term,
        value,
        threshold_attribute,
        threshold_values,
        weight_attribute,
        weight_values,
        calculation_type
    },
    sheetName,
    zonesData
) => {
    const isSheetZoneToZoneCosts =
        sheetName === terms.sheetName.ZONETOZONECOSTS;
    const isSheetVariableCosts = sheetName === terms.sheetName.VARIABLECOSTS;

    const isValidStartZone = customCostMatrixUtils.isValidZone(
        start_zone_name,
        zonesData
    );
    const isValidEndZone = customCostMatrixUtils.isValidZone(
        end_zone_name,
        zonesData
    );

    const isCustomCostTermConstant =
        customCostMatrixUtils.isCustomCostTermConstant(custom_cost_term);
    const isCustomCostTermStop =
        customCostMatrixUtils.isCustomCostTermStop(custom_cost_term);
    const isCustomCostTermTrip =
        customCostMatrixUtils.isCustomCostTermTrip(custom_cost_term);
    const isConstantTermValueValid = _.isNumber(value) && value >= 0;
    const isProgressive = customCostMatrixUtils.isProgressive(calculation_type);

    const isThresholdAttributeValid =
        customCostMatrixUtils.isValidAttributeType(threshold_attribute);
    const isThresholdAttributeTypeTripDistance =
        customCostMatrixUtils.isAttributeTypeTripDistance(threshold_attribute);
    const isThresholdAttributeTypeTripWeight =
        customCostMatrixUtils.isAttributeTypeTripWeight(threshold_attribute);
    const isThresholdAttributeTypeWaitTime =
        customCostMatrixUtils.isAttributeTypeWaitTime(threshold_attribute);
    const isThresholdAttributeValueValid =
        customCostMatrixUtils.isValidAttributeValues(threshold_values);

    const isWeightAttributeValid =
        customCostMatrixUtils.isValidAttributeType(weight_attribute);
    const isWeightAttributeTypeTripDistance =
        customCostMatrixUtils.isAttributeTypeTripDistance(weight_attribute);
    const isWeightAttributeTypeTripWeight =
        customCostMatrixUtils.isAttributeTypeTripWeight(weight_attribute);
    const isWeightAttributeTypeWaitTime =
        customCostMatrixUtils.isAttributeTypeWaitTime(weight_attribute);
    const isWeightAttributeValueValid =
        customCostMatrixUtils.isValidAttributeValues(weight_values);

    const results = {
        isSheetZoneToZoneCosts,
        isSheetVariableCosts,
        isValidStartZone,
        isValidEndZone,
        isCustomCostTermConstant,
        isCustomCostTermStop,
        isCustomCostTermTrip,
        isConstantTermValueValid,
        isProgressive,
        isThresholdAttributeValid,
        isThresholdAttributeTypeTripDistance,
        isThresholdAttributeTypeTripWeight,
        isThresholdAttributeTypeWaitTime,
        isThresholdAttributeValueValid,
        isWeightAttributeValid,
        isWeightAttributeTypeTripDistance,
        isWeightAttributeTypeTripWeight,
        isWeightAttributeTypeWaitTime,
        isWeightAttributeValueValid
    };

    return results;
};

const getErrors = (sheetName, rowData, zonesData) => {
    const {
        start_zone_name,
        end_zone_name,
        custom_cost_term,
        value,
        threshold_attribute,
        threshold_values,
        weight_attribute,
        weight_values,
        calculation_type,
        __rowNum__: rowNum
    } = rowData;

    const row = rowNum + 1;
    const errorObj = {
        sheetName,
        row,
        key: idUtils.getCombinedId(sheetName, row)
    };
    const rowErrors = [];

    const {
        isSheetZoneToZoneCosts,
        isSheetVariableCosts,
        isValidStartZone,
        isValidEndZone,
        isCustomCostTermConstant,
        isCustomCostTermStop,
        isCustomCostTermTrip,
        isConstantTermValueValid,
        isProgressive,
        isThresholdAttributeValid,
        isThresholdAttributeTypeTripDistance,
        isThresholdAttributeTypeTripWeight,
        isThresholdAttributeTypeWaitTime,
        isThresholdAttributeValueValid,
        isWeightAttributeValid,
        isWeightAttributeTypeTripDistance,
        isWeightAttributeTypeTripWeight,
        isWeightAttributeTypeWaitTime,
        isWeightAttributeValueValid
    } = getTestResults(rowData, sheetName, zonesData);

    // test if detected start zone name exists
    try {
        if (!isValidStartZone) {
            const errorKey = terms.errorKeys.ZONE_NAME_NOT_FOUND;
            const fixKey = terms.fixKeys.VALUE_MUST_BE_EXISTING_ZONE;
            const errorData = {
                ...errorObj,
                errorKey,
                fixKey,
                col: 'start_zone_name',
                value: start_zone_name
            };
            rowErrors.push(getErrorObj(errorData));
        }
    } catch (e) {
        console.error(e);
    }

    // test if detected end zone name exists
    try {
        if (!isValidEndZone) {
            const errorKey = terms.errorKeys.ZONE_NAME_NOT_FOUND;
            const fixKey = terms.fixKeys.VALUE_MUST_BE_EXISTING_ZONE;
            const errorData = {
                ...errorObj,
                errorKey,
                fixKey,
                col: 'end_zone_name',
                value: end_zone_name
            };
            rowErrors.push(getErrorObj(errorData));
        }
    } catch (e) {
        console.error(e);
    }

    // test whether detected custom cost term is valid
    try {
        let isValidTermForSheet = true;
        let validValues = Object.values(CustomCostMatrixTermType).join(', ');

        if (isSheetZoneToZoneCosts) {
            isValidTermForSheet = isCustomCostTermConstant;
            validValues = CustomCostMatrixTermType.CONSTANT;
        } else if (isSheetVariableCosts) {
            isValidTermForSheet = isCustomCostTermStop || isCustomCostTermTrip;
            validValues = [
                CustomCostMatrixTermType.STOP,
                CustomCostMatrixTermType.TRIP
            ].join(', ');
        }

        if (!isValidTermForSheet) {
            const errorKey = terms.errorKeys.CUSTOM_COST_TERM_NOT_VALID;
            const fixKey = terms.fixKeys.CUSTOM_COST_TERM_MUST_BE_FROM_ALLOWED;
            const errorData = {
                ...errorObj,
                errorKey,
                fixKey,
                col: 'custom_cost_term',
                value: custom_cost_term,
                validValues
            };
            rowErrors.push(getErrorObj(errorData));
        }
    } catch (e) {
        console.error(e);
    }

    // these tests are only valid for the zone-to-zone costs sheet
    if (isSheetZoneToZoneCosts) {
        // test whether detected value is valid for zone-to-zone costs
        try {
            if (!isConstantTermValueValid) {
                const errorKey = terms.errorKeys.CONSTANT_TERM_VALUE_NOT_VALID;
                const fixKey = terms.fixKeys.VALUE_MUST_BE_NUMERIC;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    col: 'value',
                    value
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }
    }

    // these tests are only valid for the variable costs sheet
    if (isSheetVariableCosts) {
        // test whether detected custom cost threshold attribute is valid
        // Attribute wait_time is not allowed in trip term, (tests threshold attribute)
        try {
            if (
                !isThresholdAttributeValid ||
                (isCustomCostTermTrip && isThresholdAttributeTypeWaitTime)
            ) {
                const errorKey = terms.errorKeys.ATTRIBUTE_TERM_NOT_VALID;
                const fixKey =
                    terms.fixKeys.ATTRIBUTE_TERM_MUST_BE_FROM_ALLOWED;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    customCostTerm: custom_cost_term,
                    col: 'threshold_attribute',
                    value: threshold_attribute,
                    validValues: [
                        CustomCostMatrixAttributeType.TRIP_DISTANCE,
                        CustomCostMatrixAttributeType.TRIP_WEIGHT
                    ].join(', ')
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // Attribute trip_distance/trip_weight is not allowed in stop term, (tests threshold attribute)
        try {
            if (
                isCustomCostTermStop &&
                (isThresholdAttributeTypeTripDistance ||
                    isThresholdAttributeTypeTripWeight)
            ) {
                const errorKey = terms.errorKeys.ATTRIBUTE_TERM_NOT_VALID;
                const fixKey =
                    terms.fixKeys.ATTRIBUTE_TERM_MUST_BE_FROM_ALLOWED;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    customCostTerm: custom_cost_term,
                    col: 'threshold_attribute',
                    value: threshold_attribute,
                    validValues: CustomCostMatrixAttributeType.WAIT_TIME
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // test whether detected custom cost weight attribute is valid
        // Attribute wait_time is not allowed in trip term, (tests weight attribute)
        try {
            if (
                !isWeightAttributeValid ||
                (isCustomCostTermTrip && isWeightAttributeTypeWaitTime)
            ) {
                const errorKey = terms.errorKeys.ATTRIBUTE_TERM_NOT_VALID;
                const fixKey =
                    terms.fixKeys.ATTRIBUTE_TERM_MUST_BE_FROM_ALLOWED;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    customCostTerm: custom_cost_term,
                    col: 'weight_attribute',
                    value: weight_attribute,
                    validValues: [
                        CustomCostMatrixAttributeType.TRIP_DISTANCE,
                        CustomCostMatrixAttributeType.TRIP_WEIGHT
                    ].join(', ')
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // Attribute trip_distance/trip_weight is not allowed in stop term, (tests weight attribute)
        try {
            if (
                isCustomCostTermStop &&
                (isWeightAttributeTypeTripDistance ||
                    isWeightAttributeTypeTripWeight)
            ) {
                const errorKey = terms.errorKeys.ATTRIBUTE_TERM_NOT_VALID;
                const fixKey =
                    terms.fixKeys.ATTRIBUTE_TERM_MUST_BE_FROM_ALLOWED;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    customCostTerm: custom_cost_term,
                    col: 'weight_attribute',
                    value: weight_attribute,
                    validValues: CustomCostMatrixAttributeType.WAIT_TIME
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // test whether detected custom cost threshold value is valid
        try {
            if (!isThresholdAttributeValueValid) {
                const errorKey =
                    terms.errorKeys.ATTRIBUTE_TERM_VALUES_NOT_VALID;
                const fixKey = terms.fixKeys.VALUE_MUST_BE_NUMERIC_CSV;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    col: 'threshold_values',
                    value: threshold_values
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // test whether detected custom cost weight value is valid
        try {
            if (!isWeightAttributeValueValid) {
                const errorKey =
                    terms.errorKeys.ATTRIBUTE_TERM_VALUES_NOT_VALID;
                const fixKey = terms.fixKeys.VALUE_MUST_BE_NUMERIC_CSV;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    col: 'weight_values',
                    value: weight_values
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // test whether detected custom cost calculation type is valid
        try {
            const isValidCalculationType =
                customCostMatrixUtils.isValidCalculationType(calculation_type);
            if (!isValidCalculationType) {
                const errorKey = terms.errorKeys.CALCULATION_TYPE_NOT_VALID;
                const fixKey =
                    terms.fixKeys.CALCULATION_TYPE_MUST_BE_FROM_ALLOWED;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    col: 'calculation_type',
                    value: calculation_type,
                    validValues: Object.values(
                        CustomCostMatrixCalculationType
                    ).join(', ')
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }

        // The threshold and weight attribute must be the same for a progressive term
        try {
            const isSameThresholdWeight =
                threshold_attribute === weight_attribute;
            if (isProgressive && !isSameThresholdWeight) {
                const errorKey = terms.errorKeys.CALCULATION_TYPE_NOT_VALID;
                const fixKey = terms.fixKeys.PROGRESSIVE_ATTRIBUTES_MUST_EQUAL;
                const errorData = {
                    ...errorObj,
                    errorKey,
                    fixKey,
                    col: 'calculation_type',
                    value: calculation_type
                };
                rowErrors.push(getErrorObj(errorData));
            }
        } catch (e) {
            console.error(e);
        }
    }

    return { ...errorObj, hasErrors: rowErrors.length > 0, errors: rowErrors };
};

export default getErrors;
