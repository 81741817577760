import { DateTime } from 'luxon';
import _ from 'lodash';

import RoutePlansApi, { GetRoutePlansResponse } from '~/api/RoutePlansApi';
import { ApiRoutePlan } from '~/api/types';

export const fetchRoutePlansByDateAndUniqueRouteDates = async (): Promise<{
    routePlansByDate: Record<string, ApiRoutePlan[]>;
    uniqueRouteDates: string[];
}> => {
    const routePlansByDate: Record<string, ApiRoutePlan[]> = {};
    const uniqueRouteDates = [];

    const response: GetRoutePlansResponse = await RoutePlansApi.get({
        start_from: DateTime.local().toISODate(),
        active: true,
        include: 'type'
    });
    const routePlans = response.data.data;

    for (const routePlan of routePlans) {
        const { route_date: routeDate } = routePlan;

        if (!routeDate) continue;

        if (!routePlansByDate[routeDate]) {
            routePlansByDate[routeDate] = [];
            uniqueRouteDates.push(routeDate);
        }

        routePlansByDate[routeDate].push(routePlan);
    }

    return {
        routePlansByDate,
        uniqueRouteDates: _.sortBy(uniqueRouteDates)
    };
};
