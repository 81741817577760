import { idUtils } from '~/utils/id-utils';
import driverUtils from '~/utils/driver-utils';
import i18n from '~/i18n';
import PlanStop from '~/data-classes/plan/PlanStop';

/**
 * PlanRoute data class
 *
 * @category Data Classes
 *
 * @example
 * import { PlanRoute } from '~/data-classes';
 *
 * const srcData = {};
 * const planStop = new PlanRoute(srcData);
 *
 */
class PlanRoute {
    constructor(routeLevelData) {
        this._routeLevelData = routeLevelData;
    }

    get clientId() {
        return this._routeLevelData.client.clientId;
    }

    get clientRouteId() {
        return idUtils.getCombinedId(this.clientId, this.routeId);
    }

    get clientRouteTaskId() {
        return idUtils.getCombinedId(this.clientRouteId, this.taskId);
    }

    get isPlanned() {
        return this._routeLevelData.route.isPlanned;
    }

    get isLocked() {
        return this._routeLevelData.route.closed;
    }

    get hasTasks() {
        return Boolean(this.numTasks);
    }

    get routeId() {
        return this._routeLevelData.route.routeId;
    }

    get routeDate() {
        return this._routeLevelData.route.routeDate;
    }

    get routeName() {
        return i18n.t('routeAndName', {
            routeName: this.vehicleEid,
            driverName: this.driverName
        });
    }

    get stats() {
        return this._routeLevelData.route.stats;
    }

    get trips() {
        const { trips } = this._routeLevelData.route;

        return (trips || []).filter(({ routeId }) => routeId);
    }

    get clientRouteIds() {
        return this.trips.map(({ routeId }) => {
            return idUtils.getCombinedId(this.clientId, routeId);
        });
    }

    get markerCoordinates() {
        return this._routeLevelData.route.markerCoordinates;
    }

    get perimeterCoordinates() {
        return this._routeLevelData.route.perimeterCoordinates;
    }

    get driverColor() {
        return this._routeLevelData.driver.color;
    }

    get colorCSS() {
        return {
            color: this._routeLevelData.route.markerColor.textColor,
            backgroundColor:
                this._routeLevelData.route.markerColor.backgroundColor
        };
    }

    set colorCSS(colors) {
        this._routeLevelData.route.markerColor.textColor = colors.color;
        this._routeLevelData.route.markerColor.backgroundColor =
            colors.backgroundColor;
        if (colors.borderColor) {
            this._routeLevelData.route.markerColor.borderColor =
                colors.borderColor;
        }
    }

    get numTasks() {
        return this._routeLevelData.route.stats.numTasks;
    }

    get numInventoryItems() {
        return this._routeLevelData.route.stats.numInventoryItems;
    }

    get distance() {
        return this._routeLevelData.route.stats.distance;
    }

    get duration() {
        return this._routeLevelData.route.stats.duration;
    }

    get volumeCapacityUsed() {
        return this._routeLevelData.route.stats.volumeCapacityUsed;
    }

    get weightUsed() {
        return this._routeLevelData.route.stats.weightUsed;
    }

    get numLateStops() {
        return this._routeLevelData.route.stats.numLateStops;
    }

    get numAtRiskStops() {
        return this._routeLevelData.route.stats.numAtRiskStops;
    }

    get vehicleId() {
        return this._routeLevelData.vehicle.vehicleId;
    }

    get vehicleEid() {
        return this._routeLevelData.vehicle.vehicleEid;
    }

    get vehicleType() {
        return this._routeLevelData.vehicle.type;
    }

    get vehicleMaxVolume() {
        return this._routeLevelData.vehicle.maxVolume;
    }

    get vehicleMaxWeight() {
        return this._routeLevelData.vehicle.maxWeight;
    }

    get vehicleName() {
        return `${this.vehicleType} ${this.vehicleEid}`;
    }

    get driverName() {
        const { driverName } = this._routeLevelData.driver;

        if (driverName === 'No Driver Assigned') {
            return i18n.t('common:noDriverAssigned');
        }

        return driverUtils.getLocalizedDriverName(driverName);
    }

    get driverId() {
        return this._routeLevelData.driver.driverId;
    }

    get clientDriverId() {
        return idUtils.getCombinedId(
            this.clientId,
            this.driverId || 'unassigned'
        );
    }

    get schedule() {
        return (this._routeLevelData.stops || []).map(
            (stop) => new PlanStop(stop)
        );
    }

    /* @alias numTasks */
    get numStops() {
        /** aliased to share a common property with LiveDriver. */
        return this.numTasks;
    }

    /* @alias volumeCapacityUsed */
    get vehicleVolumeUsed() {
        /** aliased to share a common property with LiveDriver. */
        return this.volumeCapacityUsed;
    }

    /* @alias weightUsed */
    get vehicleWeightUsed() {
        /** aliased to share a common property with LiveDriver. */
        return this.weightUsed;
    }

    toJSON() {
        return this._routeLevelData;
    }
}

export default PlanRoute;
