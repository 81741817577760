import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { partition, sortBy } from 'lodash';

import DriverApi from '~/api/DriverApi';
import { LiveStop } from '~/data-classes';
import { ApiLiveDriver } from '~/api/types';

import { useMapUtils } from './useMapUtils';
import { getFilteredDriver } from '~/hooks/useDriverTasks/utils';
import constants from '~/utils/constants';

import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';
import { useWebInterval } from './useWebInterval';

interface GetReorderedTaskIdsProps {
    allStops: LiveStop[];
    newDriverStopNumber: number;
    selectedStops: LiveStop[];
    isInsertAfter: boolean;
}

interface MapStopIdsToLiveStopsProps {
    dispatchedDrivers: ApiLiveDriver[];
    selectedMapStopsIds: string[];
}

export const getReorderedTaskIds = ({
    allStops,
    newDriverStopNumber,
    selectedStops,
    isInsertAfter = true
}: GetReorderedTaskIdsProps) => {
    const selectedDriverStopNumbers = new Set(
        selectedStops.map(({ driverStopNumber }) => driverStopNumber)
    );
    const reOrderedStops: LiveStop[] = [];
    let isReordered = false;

    allStops.forEach((stop) => {
        const { driverStopNumber } = stop;
        const canAddStop = !selectedDriverStopNumbers.has(driverStopNumber);

        if (driverStopNumber !== newDriverStopNumber) {
            if (canAddStop) reOrderedStops.push(stop);

            return;
        }

        isReordered = true;

        if (canAddStop && isInsertAfter) {
            reOrderedStops.push(stop);
        }

        reOrderedStops.push(...selectedStops);

        if (canAddStop && !isInsertAfter) {
            reOrderedStops.push(stop);
        }
    });

    const taskIds = reOrderedStops
        .filter(({ isDepot, status }) => !isDepot && status === 0)
        .map(({ id }) => id);

    return {
        isReordered,
        taskIds
    };
};

export const mapStopIdsToLiveStops = ({
    dispatchedDrivers,
    selectedMapStopsIds
}: MapStopIdsToLiveStopsProps) => {
    if (!selectedMapStopsIds.length) return {};

    const driver = getFilteredDriver(dispatchedDrivers, selectedMapStopsIds);

    const driverId = driver?.id;
    const [selectedStops, unselectedStops] = partition(
        driver?.schedule.map(({ name, task, stopNumber, ...others }) => ({
            ...others,
            stopName: name,
            stopNumber,
            taskId: task
        })),
        ({ id: stopId }) => selectedMapStopsIds.includes(stopId)
    );

    if (selectedStops.length !== selectedMapStopsIds.length) return {};

    const orderedSelectedStops = sortBy(selectedStops, ({ id: stopId }) =>
        selectedMapStopsIds.indexOf(stopId)
    );
    const [firstSelectedStop] = orderedSelectedStops;

    return {
        driver,
        driverId,
        firstSelectedStop,
        unselectedStops,
        selectedStops: orderedSelectedStops,
        allStops: sortBy(
            [...orderedSelectedStops, ...unselectedStops],
            'stopNumber'
        )
    };
};

export const useResequenceDriverTasks = () => {
    const dispatch = useDispatch();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const isRequestInProgress = useRef(false);
    const { t } = useTranslation('translation');
    const { isDispatchedRouteMode } = useMapUtils();
    const { refetch } = useWebInterval();

    const {
        driverId,
        allStops = [],
        selectedStops = []
    } = useMemo(() => {
        if (!isDispatchedRouteMode) return {};

        return mapStopIdsToLiveStops({
            dispatchedDrivers,
            selectedMapStopsIds
        });
    }, [dispatchedDrivers, isDispatchedRouteMode, selectedMapStopsIds]);

    const isResequenceAllowed =
        selectedStops.length > 0 && !isRequestInProgress.current;

    const resequenceDriverTasks = useCallback(
        ({
            newDriverStopNumber,
            stopsToResequence = selectedStops,
            stops = allStops,
            selectedDriverId = driverId,
            isTaskResequencingAllowed = isResequenceAllowed,
            isInsertAfter = true
        }) => {
            if (!isTaskResequencingAllowed) {
                console.warn('resequence is not allowed');
                return;
            }

            const { taskIds, isReordered } = getReorderedTaskIds({
                allStops: stops,
                isInsertAfter,
                newDriverStopNumber,
                selectedStops: stopsToResequence
            });

            if (!isReordered) {
                console.warn('tasks order did not change');
                return;
            }

            const numOfSelectedStops = stopsToResequence.length;
            const processIndicatorState = {
                message: t('ResequenceStop', {
                    count: numOfSelectedStops
                }),
                inProgress: true,
                error: false,
                position: 'center'
            };
            (async () => {
                try {
                    isRequestInProgress.current = true;
                    dispatch(addProcessIndicator(processIndicatorState));
                    await DriverApi.resequenceTasks(selectedDriverId, taskIds);
                    dispatch(resetSelectedMapStops());
                    isRequestInProgress.current = false;
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            message: t('StopResequenced', {
                                count: numOfSelectedStops
                            }),
                            inProgress: false
                        })
                    );
                } catch (e) {
                    console.error(e);
                    isRequestInProgress.current = false;
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            inProgress: false,
                            error: true
                        })
                    );
                }
                setTimeout(refetch, constants.timings.IGNORE_DUPLICATE_FETCH);
            })();
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [allStops, driverId, dispatch, isResequenceAllowed, selectedStops]
    );

    return {
        isResequenceAllowed,
        resequenceDriverTasks
    };
};
