import { useCallback, useMemo } from 'react';
import constants from '~/utils/constants';
import { theme } from '~/ui';
import {
    GoogleMapsApi,
    GoogleMapsDrawingManager,
    GoogleMapsInstance,
    GoogleMapsLatLngLiteral,
    GoogleMapsPolygon
} from '~/api/types';
import { useMapContext } from '~/components/ZoneManagementPage/MapContext';

const createNewDrawingManager = (
    map: GoogleMapsInstance,
    maps: GoogleMapsApi,
    strokeWeight = 3
): GoogleMapsDrawingManager => {
    const drawingManager = new maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polygonOptions: {
            fillColor: theme.colors.ocean,
            fillOpacity: 0.3,
            strokeColor: theme.colors.meteor,
            strokeOpacity: 1,
            strokeWeight
        }
    });

    drawingManager.setMap(map);

    return drawingManager;
};

type OnGoogleApiLoadedProps = { map: GoogleMapsInstance; maps: GoogleMapsApi };
type Options = {
    onGoogleApiLoaded: (props: OnGoogleApiLoadedProps) => void;
    center?: GoogleMapsLatLngLiteral;
    defaultZoom: number;
    options: {
        disableDefaultUI: boolean;
        disableDoubleClickZoom: boolean;
        maxZoom: number;
        rotateControl: boolean;
        zoomControl: boolean;
    };
};

type OnPolygonCompleteToConsumer = (polygon: GoogleMapsPolygon) => void;

type UseDrawingMapOptions = {
    mapCenter: GoogleMapsLatLngLiteral;
    defaultZoom?: number;
    polygonStrokeWeight?: number;
    events?: {
        onPolygonComplete?: OnPolygonCompleteToConsumer;
    };
};

export const useDrawingMapOptions = (
    options: UseDrawingMapOptions
): Options => {
    const { mapCenter, defaultZoom, polygonStrokeWeight, events } = options;
    const { onPolygonComplete: onPolygonCompleteCallback } = events || {};
    const {
        setActivePolygon,
        setDrawingManagerInstance,
        setIsDrawingMode,
        setMapInstance,
        setMapsApi
    } = useMapContext();
    const onPolygonComplete = useCallback((polygon: GoogleMapsPolygon) => {
        setIsDrawingMode(false);
        setActivePolygon(polygon);
        onPolygonCompleteCallback?.(polygon);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
    const onGoogleApiLoaded = useCallback((props: OnGoogleApiLoadedProps) => {
        const { map, maps } = props || {};

        if (!map || !maps?.drawing) {
            console.warn('unable to load google map libraries');
            return;
        }

        const drawingManager = createNewDrawingManager(
            map,
            maps,
            polygonStrokeWeight
        );
        setMapInstance(map);
        setMapsApi(maps);
        setDrawingManagerInstance(drawingManager);
        maps.event.addListener(
            drawingManager,
            constants.mapEvents.POLYGON_COMPLETE,
            onPolygonComplete
        );
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return useMemo<Options>(() => {
        return {
            onGoogleApiLoaded,
            center: mapCenter,
            defaultZoom:
                defaultZoom || constants.mapOptionSettings.DEFAULT_ZOOM,
            options: {
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                maxZoom: constants.mapOptionSettings.MAX_ZOOM,
                rotateControl: false,
                zoomControl: false,
                tilt: 0
            }
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
};
