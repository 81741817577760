/* eslint-disable camelcase */

export enum ApiCourierTaskAssignmentStage {
    UPDATE = 'Update',
    DONE = 'Done',
    ERROR = 'Error'
}

export interface ApiCourierTaskAssignment {
    id?: string;
    client_id?: string;
    assigner_run_id?: string;
    stage?: ApiCourierTaskAssignmentStage;

    /* Date in ISO format */
    assignment_date?: string;
    unassigned_count?: number;
    driver_group_assignments?: DriverGroupAssignment[];
    error_message?: string;
}

/**
 * @TODO This type is likely not complete, but there is not sufficient documentation in `wise-api`
 * as to its full typing
 */
export interface DriverGroupAssignment {
    taskIds?: string[];
    groupId?: string;
    [key: string]: unknown;
}
