import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import { resetOnLogout } from '~/reducers/common-actions';
import { SelectedDrawerCardData } from './types';
import { upsertSelectedDrawerCardData as upsertSelectedDrawerCardDataUtil } from './utils';

export const selectedDrawerCardDataSlice = createSlice({
    name: 'selectedDrawerCardData',
    initialState: [] as SelectedDrawerCardData[],
    reducers: {
        upsertSelectedDrawerCardData: (state, action) => {
            return upsertSelectedDrawerCardDataUtil({
                state,
                payload: action.payload
            });
        },
        toggleSelectedDrawerCardDataIsPinned: (state, action) => {
            const id = action.payload;
            const listOfCardsData = state ?? [];
            const index = listOfCardsData.findIndex((item) => item.id === id);

            if (index === -1) return listOfCardsData;

            const item = listOfCardsData[index];

            listOfCardsData[index] = {
                ...item,
                isPinned: !item.isPinned
            };

            return listOfCardsData;
        },
        removeSelectedDrawerCardData: (state, action) => {
            const id = action.payload;
            const listOfCardsData = state ?? [];
            const index = listOfCardsData.findIndex((item) => item.id === id);

            if (index !== -1) listOfCardsData.splice(index, 1);

            return listOfCardsData;
        },
        resetSelectedDrawerCardData: (): SelectedDrawerCardData[] => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const {
    upsertSelectedDrawerCardData,
    removeSelectedDrawerCardData,
    resetSelectedDrawerCardData,
    toggleSelectedDrawerCardDataIsPinned
} = selectedDrawerCardDataSlice.actions;

export const selectSelectedDrawerCardData = (state: RootState) => {
    const items = state.selectedDrawerCardData ?? [];
    return items.slice(-1)[0];
};

export const selectSelectedDrawerCardDataById =
    (id?: string) => (state: RootState) => {
        const items = state.selectedDrawerCardData ?? [];

        if (!id) return null;

        return items.find((item) => item.id === id) ?? null;
    };

export const selectAllSelectedDrawerCardData = (
    state: RootState
): SelectedDrawerCardData[] => {
    return state.selectedDrawerCardData ?? [];
};

export const selectSelectedDrawerCardDataCount = (state: RootState): number => {
    return (state.selectedDrawerCardData ?? []).length;
};

export default selectedDrawerCardDataSlice.reducer;
