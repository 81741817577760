import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const selectedMapRoutesSlice = createSlice({
    name: 'selectedMapRoutes',
    initialState: [],
    reducers: {
        addSelectedMapRoute: (state, action) => {
            const isSelected = state.some((route) => action.payload === route);
            if (!isSelected) state.push(action.payload);
        },
        removeSelectedMapRoute: (state, action) => {
            const isSelected = state.some((route) => action.payload === route);
            if (isSelected)
                return state.filter((route) => action.payload !== route);
        },
        replaceSelectedMapRoutes: (state, action) => {
            if (!Array.isArray(action.payload)) return state;
            return action.payload;
        },
        setSelectedMapRoute: (state, action) => {
            return [action.payload];
        },
        resetSelectedMapRoutes: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    replaceSelectedMapRoutes,
    setSelectedMapRoute,
    resetSelectedMapRoutes
} = selectedMapRoutesSlice.actions;
export const selectSelectedMapRoutes = (state) => state.selectedMapRoutes;
export default selectedMapRoutesSlice.reducer;
