import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult
} from 'react-query';
import { useDispatch } from 'react-redux';
import { addToast } from '~/reducers/toastsSlice';
import { useTranslation } from 'react-i18next';

import { zonesAPI } from '~/api/ZonesApi';
import constants from '~/utils/constants';

type HookZoneVehicleAssociation = {
    zoneVehicleAssociations: {
        [id: string]: string[];
    }[];
    isFetching: boolean;
    deleteVehicleAssociation: unknown;
    createVehicleAssociation: unknown;
};

type ZoneVehicleAssociationMap = {
    [id: string]: string[];
};

type AssociationRecord = {
    ['vehicle_id']: string;
    ['zone_id']: string;
    ['id']: string;
    ['client_id']: string;
};

type DeleteVehicleAssociationProps = {
    zoneId: string;
    vehicleId: string;
};

type CreateZoneVehicleAssociationRecord = {
    zoneId: string;
    vehicleId: string;
};

type ZoneVehicleAssociationRecord = {
    id: string;
    ['client_id']: string;
    ['vehicle_id']: string;
    ['zone_id']: string;
    ['shift_times']: null;
};

export const useZoneVehicleAssociations = (): HookZoneVehicleAssociation => {
    let zoneVehicleAssociations = {};
    const { t } = useTranslation('error');
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { data, isFetching }: UseQueryResult<ZoneVehicleAssociationRecord[]> =
        useQuery(
            constants.reactQueryKeys.ZONE_VEHICLE_ASSOCIATION,
            async () => {
                const response = await zonesAPI.getVehicleAssociations();
                return response.data.data;
            },
            {
                onError: () => {
                    queryClient.setQueryData(
                        constants.reactQueryKeys.ZONE_VEHICLE_ASSOCIATION,
                        () => {}
                    );
                }
            }
        );

    const { mutateAsync: deleteVehicleAssociation } = useMutation(
        ({ zoneId, vehicleId }: DeleteVehicleAssociationProps) => {
            return zonesAPI.deleteVehicleAssociation({ zoneId, vehicleId });
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(
                    constants.reactQueryKeys.ZONE_VEHICLE_ASSOCIATION
                );
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:zoneVehicleAssociationDeleteError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    const { mutateAsync: createVehicleAssociation } = useMutation(
        (associations: CreateZoneVehicleAssociationRecord[]) => {
            return zonesAPI.createVehicleAssociation(associations);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(
                    constants.reactQueryKeys.ZONE_VEHICLE_ASSOCIATION
                );
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:zoneVehicleAssociationCreateError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    if (data) {
        zoneVehicleAssociations = data.reduce(
            (
                aggr: ZoneVehicleAssociationMap,
                association: AssociationRecord
            ) => {
                if (aggr[association.zone_id]) {
                    aggr[association.zone_id].push(association.vehicle_id);
                } else {
                    aggr[association.zone_id] = [association.vehicle_id];
                }
                return aggr;
            },
            {}
        );
    }

    return <HookZoneVehicleAssociation>{
        zoneVehicleAssociations,
        isFetching,
        deleteVehicleAssociation,
        createVehicleAssociation
    };
};
