import React from 'react';
import classnames from 'classnames';

import { Icon, IconData } from '~/ui/';

import './ViolationBadge.scss';

type ViolationBadgeProps = {
    iconsData: IconData[];
    className?: string;
};

export const ViolationBadge = ({
    iconsData,
    className
}: ViolationBadgeProps) => {
    const itemsWithIcon = iconsData.filter(({ icon }) => icon);

    const getBadgeIcons = () => {
        return (
            <>
                {itemsWithIcon.map((data, index) => {
                    const { icon, iconSize = 'xs' } = data;
                    const key = `${icon}-${index}`;

                    return (
                        <Icon
                            key={key}
                            icon={icon}
                            size={iconSize}
                            className="badge-icon"
                            data-testid={`icon-${icon}`}
                        />
                    );
                })}
            </>
        );
    };

    const getClassNames = () => {
        const conditionalClasses = {
            'violation-badge': Boolean(itemsWithIcon.length)
        };
        return classnames('_fd-row _p-absolute', conditionalClasses, className);
    };

    return (
        <div className={getClassNames()} data-testid="violation-badge">
            {getBadgeIcons()}
        </div>
    );
};
