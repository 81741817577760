import { DateTime } from 'luxon';

/**
 * Check the provided string will parse into a JS Date
 *
 * @param {String} inputString the string to test the date format
 * @returns {Boolean} the result of the test
 *
 * @see [JS Date Object]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
function checkIsDateStringValidDate(inputString: string): boolean {
    const date = new Date(inputString);
    return !Number.isNaN(date.valueOf());
}

/**
 * Check the provided string is in ISO date format, YYYY-MM-DD
 *
 * @param {String} inputString the string to test the date format
 * @returns {Boolean} the result of the test
 */
function checkIsYearMonthDayFormat(inputString: string): boolean {
    return /^[0-9]{4}(-[0-9]{2}){2}$/.test(inputString);
}

/**
 * Check the provided string is in ISO date format, YYYY-MM-DD
 *
 * @param {String} date the string to test the date format
 * @returns {String | Boolean} the error message or false
 */
function isDateInvalid(date: string): string | boolean {
    if (!date) {
        return 'date not provided';
    }

    const isProperFormat = checkIsYearMonthDayFormat(date);
    if (!isProperFormat) {
        return 'date must be in yyyy-mm-dd format';
    }

    const isValidDate = checkIsDateStringValidDate(date);
    if (!isValidDate) {
        return 'must provide a valid date';
    }

    return false;
}

/**
 * Check the provided string is valid ISO 8601 date format
 *
 * This function uses `DateTime.fromISO()` method provided by `luxon` to perform this check.
 * As such, it does not explicitly test for a specific ISO 8601 format.
 *
 * If testing for a specific ISO 8601 format, a separate pattern tester is required.
 *
 * @param {String} date the string to test the date format
 * @returns {Boolean} whether the provided date is valid ISO 8601 date format
 *
 * @see {@link https://moment.github.io/luxon/#/parsing?id=iso-8601 DateTime Parsing ISO 8601}
 */
const isValidDateISO = (date: string) => {
    return DateTime.fromISO(date).isValid;
};

export default {
    checkIsDateStringValidDate,
    checkIsYearMonthDayFormat,
    isDateInvalid,
    isValidDateISO
};
