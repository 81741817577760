import { getURL, ServerBaseUrlKey } from '~/servers/servers';
import { datadogRum } from '@datadog/browser-rum';
import { ContextValue } from '@datadog/browser-core';
import constants from '~/utils/constants';
import { ApiUser } from '~/api/types';

const getEnvironment = () => getURL(ServerBaseUrlKey.ENVIRONMENT);

const isDatadogEnabled = () => {
    const { REACT_APP_DATADOG_RUM_CLIENT_TOKEN = '', REACT_APP_VERSION } =
        process.env || {};
    const environment = getEnvironment();

    return Boolean(
        REACT_APP_DATADOG_RUM_CLIENT_TOKEN && REACT_APP_VERSION && environment
    );
};

export const initDatadogForDeployments = () => {
    const { REACT_APP_DATADOG_RUM_CLIENT_TOKEN = '', REACT_APP_VERSION } =
        process.env || {};
    const environment = getEnvironment();

    if (!isDatadogEnabled()) {
        return;
    }

    const {
        datadog: {
            APP_ID,
            URL,
            SERVICE,
            defaultPrivacyLevel,
            sessionReplaySampleRate,
            sessionSampleRate
        }
    } = constants;

    datadogRum.init({
        applicationId: APP_ID,
        clientToken: REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
        site: URL,
        service: SERVICE,
        env: environment,
        version: REACT_APP_VERSION,
        sessionSampleRate,
        sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel
    });
    datadogRum.startSessionReplayRecording();
};

export const stopSessionReplay = () => {
    if (!isDatadogEnabled()) {
        return;
    }
    datadogRum.stopSessionReplayRecording();
};

interface SetDatadogUserProps {
    userId: string;
    userName: string;
    mainClient: string;
}

export const setDatadogUser = ({
    userId,
    userName,
    mainClient
}: SetDatadogUserProps) => {
    if (!isDatadogEnabled()) {
        return;
    }
    datadogRum.setUser({
        userId,
        userName,
        mainClient
    });
};

export const sendDatadogError = (
    error: Error,
    options: Record<string, string> = {}
) => {
    if (!isDatadogEnabled()) {
        return;
    }
    datadogRum.addError(error, options);
};

export const sendDatadogEvent = (
    action: string,
    details: Record<
        string,
        | string
        | boolean
        | string[]
        | undefined
        | null
        | ContextValue
        | Record<string, string>
    >
) => {
    if (!isDatadogEnabled()) {
        return;
    }
    datadogRum.addAction(action, details);
};

const getActionName = (field: string) => {
    return `CHANGE_${field}`;
};

export const updateDatadogUser = (
    userData: Record<
        string,
        string | boolean | string[] | undefined | null | Record<string, string>
    >
) => {
    if (!isDatadogEnabled()) {
        return;
    }
    const currentUser = datadogRum.getUser();
    Object.keys(userData).forEach((field: string) => {
        if (userData[field] !== currentUser[field]) {
            datadogRum.setUserProperty(field, userData[field]);
            sendDatadogEvent(getActionName(field), {
                oldValue: currentUser[field],
                newValue: userData[field]
            });
        }
    });
};

export const configureUserInfo = (user: ApiUser) => {
    const { id: userId, username } = user;
    return {
        userId,
        userName: username
    };
};
