import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiTask } from '~/api/types';
import { Task } from '~/data-classes';
import { useTaskPairing } from '~/hooks';
import {
    selectIsEquipmentDepotPairing,
    selectIsOpenPairEquipmentControlPopup,
    setIsOpenPairEquipmentControlPopup,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import {
    setSelectedTaskIds,
    resetSelectedTaskIds,
    selectSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { addToast } from '~/reducers/toastsSlice';

export const useHandleTaskPairing = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation(['error']);

    const [selectedTaskId] = useSelector(selectSelectedTaskIds);
    const isEquipmentDepotPairing = useSelector(selectIsEquipmentDepotPairing);
    const isEquipmentPairing = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const { manualPairing, depotPairing } = useTaskPairing();

    const updateTaskStates = useCallback(
        ({
            task: newTask,
            selectedTaskIds: taskIds
        }: { task?: ApiTask; selectedTaskIds?: string[] } = {}) => {
            if (isEquipmentDepotPairing || isEquipmentPairing) {
                dispatch(setIsOpenPairEquipmentControlPopup(false));
                dispatch(setIsUnassignedTasksTabActive(true));
                if (newTask?.id) {
                    dispatch(setSelectedTaskIds([newTask.id]));
                    return;
                }
                // in case of depot pairing api call, we don't get task ids in the response data
                // so, we are getting the task ids submitted to depot pairing here
                const filteredTaskIds = taskIds?.filter(Boolean);
                if (filteredTaskIds?.length) {
                    dispatch(setSelectedTaskIds(filteredTaskIds));
                }
            } else {
                dispatch(resetSelectedTaskIds());
            }
        },
        [dispatch, isEquipmentDepotPairing, isEquipmentPairing]
    );

    const handleTaskPairing = useCallback(
        (newTask: ApiTask) => {
            try {
                const taskIds = [newTask.id, selectedTaskId];
                const apiTask = new Task(newTask);

                if (isEquipmentDepotPairing) {
                    depotPairing({
                        selectedTaskIds: taskIds,
                        updateTaskStatesForDepotPairing: updateTaskStates
                    });
                    return;
                }

                manualPairing({
                    selectedTaskIds: taskIds,
                    updateTaskStates,
                    newTask: apiTask
                });
            } catch (e) {
                const message = isEquipmentDepotPairing
                    ? t('depotPairError.general')
                    : t('manualPairError.general');

                dispatch(
                    addToast({
                        message,
                        variant: 'error'
                    })
                );
            }
        },
        [
            depotPairing,
            dispatch,
            isEquipmentDepotPairing,
            manualPairing,
            selectedTaskId,
            updateTaskStates,
            t
        ]
    );

    return {
        handleTaskPairing,
        updateTaskStates
    };
};
