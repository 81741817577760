import { useState } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { isDraggingInProgress } from './utils';

interface UseDragAndDropState {
    dragHandler: UniqueIdentifier | null;
    draggedItemIds: UniqueIdentifier[] | null;
    isDragging: boolean;
    targetItemId: UniqueIdentifier | null;
    setDragState: (ids: UniqueIdentifier[]) => void;
    setDragHandler: (id: UniqueIdentifier) => void;
    setTargetState: (id: UniqueIdentifier | null) => void;
    clearDragState: () => void;
}

export const useDragAndDropState = (): UseDragAndDropState => {
    const [draggedItemIds, setDraggedItemIds] =
        useState<UniqueIdentifier[] | null>(null);

    const [dragHandler, setDragHandler] =
        useState<UniqueIdentifier | null>(null);

    const [targetId, setTargetId] = useState<UniqueIdentifier | null>(null);

    const setDragState = (ids: UniqueIdentifier[]) => {
        setDraggedItemIds(ids);
    };

    const setTargetState = (id: UniqueIdentifier | null) => {
        setTargetId(id);
    };

    const clearDragState = () => {
        setTargetId(null);
        setDraggedItemIds(null);
        setDragHandler(null);
    };

    const isDragging = isDraggingInProgress(draggedItemIds, targetId);

    return {
        dragHandler,
        isDragging,
        draggedItemIds,
        targetItemId: targetId,
        clearDragState,
        setDragState,
        setDragHandler,
        setTargetState
    };
};
