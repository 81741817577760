/**
 * Available downloadable report types
 */
export enum DownloadableReportTypes {
    /**
     * Auto Arrive report
     *
     * A standard/core report, server generated and downloaded from AWS
     *
     * also known by the report name: `auto_arrive`
     */
    AUTO_ARRIVE = 'autoArrive',

    /**
     * Time Window Stat report
     *
     * A standard/core report, server generated and downloaded from AWS
     *
     * also known by the report name: `time_window_stat`
     */
    TIME_WINDOW_STAT = 'timeWindowStat',

    /**
     * Driver Raw report
     *
     * A standard/core report, server generated and downloaded from AWS
     *
     * also known by the report name: `generic_raw_data`
     */
    DRIVER_RAW = 'driverRaw',

    /**
     * Fleet report
     *
     * A standard/core report, server generated and downloaded from AWS
     */
    FLEET = 'fleet',

    /**
     * Route Plan report
     *
     * A standard/core report, generated on demand by Interstellar
     */
    ROUTE_PLAN = 'routePlan',

    /**
     * Live Drivers report
     *
     * A standard/core report, generated on demand by Interstellar
     */
    LIVE_DRIVER = 'liveDrivers',

    /**
     * Service Time report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `service_time`
     */
    SERVICE_TIME = 'serviceTime',

    /**
     * Manual Sequence report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `manual_sequence`
     */
    MANUAL_SEQUENCE = 'manualSequence',

    /**
     * Time Window report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `time_window_report`
     */
    TIME_WINDOW = 'timeWindow',

    /**
     * Depot Departure report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `depot_departure`
     */
    DEPOT_DEPARTURE = 'depotDeparture',

    /**
     * Route Compare report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `route_compare_report`
     */
    ROUTE_COMPARE = 'routeCompare',

    /**
     * Edit Impact report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `edit_impact`
     */
    EDIT_IMPACT = 'editImpact',

    /**
     * Signature Zip report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `signature_zip`
     */
    SIGNATURE_ZIP = 'signatureZip',

    /**
     * Inventory Management report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `inventory_management`
     */
    INVENTORY_MANAGEMENT = 'inventoryManagement',

    /**
     * Proof of Delivery report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `proof_of_delivery_report`
     */
    PROOF_OF_DELIVERY = 'proofOfDelivery',

    /**
     * Proof of Delivery report (CSV format)
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `proof_of_delivery_report`
     */
    PROOF_OF_DELIVERY_CSV = 'proofOfDeliveryCsv',

    /**
     * Adherence report
     *
     * A non-standard/custom report, server generated and downloaded from AWS
     *
     * also known by the report name: `adherence_report`
     */
    ADHERENCE = 'adherence',

    /**
     * Instant Routes report
     */
    INSTANT_ROUTES_REPORT = 'instantRoutes',

    /**
     * Generic unknown report
     */
    UNKNOWN = 'unknown'
}
