import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMapSettings } from '~/reducers/mapSettingsSlice';
import { selectIsRoutePlannerDisabled } from '~/reducers/mainClientSlice';
import { useChangeMapRouteMode } from '../useChangeMapRouteMode';
import { MapRouteModes, useMapUtils } from '../useMapUtils';

export const useMapSettings = () => {
    const { changeMapRouteMode } = useChangeMapRouteMode();
    const mapSettings = useSelector(selectMapSettings);
    const hidePlannedTab = useSelector(selectIsRoutePlannerDisabled);
    const { isPreRoutePlanningMode } = useMapUtils();

    useEffect(() => {
        if (hidePlannedTab && isPreRoutePlanningMode) {
            changeMapRouteMode(MapRouteModes.DISPATCHED);
        }
    }, [changeMapRouteMode, hidePlannedTab, isPreRoutePlanningMode]);

    return {
        mapSettings
    };
};
