import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnassignedStopMarker } from '~/ui/components/StackPinMarkerPopup/UnassignedStopMarker';
import { ApiTask } from '~/api/types';
import { setSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { setMapMarkerMode } from '~/reducers/mapSettingsSlice';
import {
    setIsLiveDispatchDrawerExpanded,
    setIsOpenLiveDispatchDrawer,
    setIsOpenUnassignedTasksDrawer,
    setIsUnassignedTasksTabActive
} from '~/reducers/mapDrawerSettingsSlice';
import constants from '~/utils/constants';
import { PlanStop, LiveStop } from '~/data-classes';
import { AssignedStopMarker } from '~/ui/components/StackPinMarkerPopup/AssignedStopMarker';
import {
    EmittedEventHandler,
    OnDemandDispatchMarkerEventHandler
} from '~/ui/components/LiveStopMarker/types';

import './StopMarkerList.scss';

type Stop = PlanStop | LiveStop;

interface StopMarkerListProps {
    tasks: ApiTask[] | PlanStop[] | LiveStop[];
    emittedEventHandler: EmittedEventHandler;
    onDemandDispatchMarkerEventHandler: OnDemandDispatchMarkerEventHandler;
    isShowClickAllButton?: boolean;
}

export const StopMarkerList = ({
    tasks,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler,
    isShowClickAllButton
}: StopMarkerListProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const rootClassName = 'stop-marker-list';

    const handleAllButtonClick = () => {
        const selectedTaskIds = tasks.map((task) => task.id);
        dispatch(setSelectedTaskIds(selectedTaskIds));
        dispatch(setIsLiveDispatchDrawerExpanded(true));
        dispatch(setIsUnassignedTasksTabActive(true));
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(setIsOpenLiveDispatchDrawer(true));
        dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
    };

    const renderUnassignedMarker = (task: ApiTask) => {
        return (
            <UnassignedStopMarker
                key={task.id}
                task={task}
                emittedEventHandler={emittedEventHandler}
            />
        );
    };

    const renderAssignedMarker = (task: Stop) => {
        return (
            <AssignedStopMarker
                key={task.id}
                task={task}
                emittedEventHandler={emittedEventHandler}
                onDemandDispatchMarkerEventHandler={
                    onDemandDispatchMarkerEventHandler
                }
            />
        );
    };

    return (
        <>
            {isShowClickAllButton && (
                <button
                    type="button"
                    className={`${rootClassName}__all-button`}
                    data-testid={`${rootClassName}-all-button`}
                    onClick={handleAllButtonClick}
                >
                    {t('stackStopsPopup.all')}
                </button>
            )}
            {tasks.map((task) => {
                const { taskStatus } = task as ApiTask;
                if (taskStatus === constants.taskStatus.UNASSIGNED)
                    return renderUnassignedMarker(task as ApiTask);

                return renderAssignedMarker(task as Stop);
            })}
        </>
    );
};
