import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import _ from 'lodash';

import CompartmentTypesApi from '~/api/CompartmentTypesApi';
import { ApiCompartmentTypes } from '~/api/types';
import constants from '~/utils/constants';

type GetCompartmentsHook = {
    compartments: Record<string, ApiCompartmentTypes>;
    isMultiCompartment: boolean;
    defaultCompartmentId: string;
};

export const useGetCompartments = (): GetCompartmentsHook => {
    let compartments: Record<string, ApiCompartmentTypes> = {};
    let isMultiCompartment = false;
    let defaultCompartmentId = '';
    const queryClient = useQueryClient();

    const { data }: UseQueryResult<ApiCompartmentTypes[]> = useQuery(
        constants.reactQueryKeys.COMPARTMENTS,
        async () => {
            const response = await CompartmentTypesApi.get();
            return response.data.data;
        },
        {
            onError: () => {
                queryClient.setQueryData(
                    constants.reactQueryKeys.COMPARTMENTS,
                    () => {}
                );
            }
        }
    );

    if (data) {
        compartments = _.keyBy(data, 'id');
        isMultiCompartment = data.length > 1;
        const defaultCompartment = data.find(
            (compartment) => compartment.isDefault
        );
        defaultCompartmentId = defaultCompartment?.id || '';
    }

    return { compartments, isMultiCompartment, defaultCompartmentId };
};
