import React, { CSSProperties } from 'react';

import { IconButton } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import { IconSize } from '~/ui/components/Icon/IconSizes';
import { TooltipPlacement } from '~/ui/components/Tooltip';
import './routecard-actionbutton.scss';
import classNames from 'classnames';

interface RouteCardActionButtonProps {
    className?: string;
    disabled?: boolean;
    visible?: boolean;
    text: string;
    icon: IconName;
    iconSize?: IconSize;
    iconColor?: string;
    iconStyle?: CSSProperties | undefined;
    tooltipMessage?: string;
    tooltipPlacement?: TooltipPlacement;
    onClick?: () => void;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    'data-testid'?: string;
}

function RouteCardActionButton({
    className,
    disabled,
    visible = true,
    text,
    icon,
    iconSize = 'm',
    iconColor = 'meteor',
    iconStyle = undefined,
    tooltipMessage = undefined,
    tooltipPlacement = 'bottom',
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...extra
}: RouteCardActionButtonProps) {
    function getClassName() {
        const defaultClassName =
            'routecard-actionbutton _d-flex _fd-column _ai-center';
        const conditionalClassName = {
            'routecard-actionbutton_disabled': disabled
        };
        return classNames(defaultClassName, conditionalClassName, className);
    }

    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-actionbutton'}
        >
            {icon && (
                <IconButton
                    className="routecard-actionbutton_icon"
                    style={iconStyle}
                    disabled={disabled}
                    visible={visible}
                    icon={icon}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    tooltipMessage={tooltipMessage}
                    tooltipPlacement={tooltipPlacement}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_icon`
                            : 'routecard-actionbutton_icon'
                    }
                />
            )}

            {text && (
                <div
                    className="routecard-actionbutton_text _text-3"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_text`
                            : 'routecard-actionbutton_text'
                    }
                >
                    {text}
                </div>
            )}
        </div>
    );
}

export default RouteCardActionButton;
