import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDate } from '~/reducers/selectedDateSlice';
import constants from '~/utils/constants';
import { usePageNavigation } from '../usePageNavigation';

/**
 * Navigates back to home if no date selected
 *
 * @category Hooks
 * @module useNavigateToHomeIfDateNotSelected
 * @returns {void}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useNavigateToHomeIfDateNotSelected } from '~/hooks';
 *
 * // expose methods
 * useNavigateToHomeIfDateNotSelected();
 *
 */
export const useNavigateToHomeIfDateNotSelected = (): void => {
    const selectedDate = useSelector(selectDate);
    const { goToPage } = usePageNavigation();

    useEffect(() => {
        if (!selectedDate) goToPage(constants.url.HOME);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedDate]);
};
