import { useMutation } from 'react-query';
import TasksApi from '~/api/TasksApi';
import {
    ApiTaskScheduler,
    AxiosApiResponse,
    ReactQuerySideEffects
} from '~/api/types';
import { useWebInterval } from '~/hooks/useWebInterval';

export const useTaskUnassign = () => {
    const { refetch } = useWebInterval();

    const {
        isLoading: isLoadingUnassignTasks,
        mutateAsync: doUnassignTasksAsync
    } = useMutation((taskIds: string[]): Promise<
        AxiosApiResponse<ApiTaskScheduler>
    > => {
        return TasksApi.unassignTasks(taskIds);
    });

    const unassignTasks = async (
        taskIds: string[],
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData = await doUnassignTasksAsync(taskIds);
            onSuccess?.(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            onError?.(error);
        }

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    return {
        isLoadingUnassignTasks,
        unassignTasks
    };
};
