import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    EditRouteHistoryApi,
    GetMetricsResponseData
} from '~/api/EditRouteHistoryApi';
import { selectSchedulerId } from '~/reducers/webSolutionSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { setEditHistoryMetrics } from '~/reducers/editHistoryMetricsSlice';

type HookGetEditRouteHistoryMetrics = {
    getMetrics: () => Promise<GetMetricsResponseData | undefined>;
};

/**
 * Gets client edit history
 *
 * @category Hooks
 * @module useGetEditRouteHistoryMetrics
 * @returns {HookGetEditRouteHistoryMetrics}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useGetEditRouteHistoryMetrics } from '~/hooks';
 *
 * // expose methods
 * const { getMetrics } = useGetEditRouteHistoryMetrics();
 *
 */
export const useGetEditRouteHistoryMetrics =
    (): HookGetEditRouteHistoryMetrics => {
        const dispatch = useDispatch();
        const schedulerId = useSelector(selectSchedulerId) as string;
        const mainClient = useSelector(selectMainClient);

        const getMetrics = useCallback(async () => {
            try {
                const clientId = mainClient?.id || '';
                const dataResponse = await EditRouteHistoryApi.getMetrics(
                    clientId,
                    schedulerId
                );
                const {
                    data: { data }
                } = dataResponse;

                dispatch(setEditHistoryMetrics(data));
                return data;
            } catch (e) {
                console.error(e);
                dispatch(setEditHistoryMetrics({}));
            }
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, [mainClient, schedulerId]);

        return { getMetrics };
    };
