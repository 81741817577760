import React, { useState } from 'react';
import PT from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import dateUtils from '~/utils/date-utils';

import { FlexLayout, Icon, Text, theme } from '~/ui';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

function customHeader({
    date,
    decreaseYear,
    increaseYear,
    decreaseMonth,
    increaseMonth
}) {
    const iconWrapperStyle = {
        width: '3.2rem',
        height: '3.2rem',
        borderRadius: '0.8rem',
        '&:hover': {
            backgroundColor: 'galaxy-50'
        }
    };

    return (
        <FlexLayout
            justifyContent="space-between"
            alignItems="center"
            bg="white"
            py="1rem"
            px="1.2rem"
        >
            <FlexLayout
                justifyContent="center"
                alignItems="center"
                sx={iconWrapperStyle}
                onClick={decreaseYear}
            >
                <Icon
                    icon="doubleChevron"
                    color="galaxy-500"
                    size="m"
                    sx={{ transform: 'rotate(90deg)' }}
                />
            </FlexLayout>
            <FlexLayout
                justifyContent="center"
                alignItems="center"
                sx={iconWrapperStyle}
                onClick={decreaseMonth}
            >
                <Icon
                    icon="chevronDown"
                    color="galaxy-500"
                    size="m"
                    sx={{ transform: 'rotate(90deg)' }}
                />
            </FlexLayout>
            <Text
                variant="14-medium"
                color="galaxy-800"
                sx={{ width: '12rem' }}
            >
                {dateUtils.formatMonthYear(date)}
            </Text>
            <FlexLayout
                justifyContent="center"
                alignItems="center"
                sx={iconWrapperStyle}
                onClick={increaseMonth}
            >
                <Icon
                    icon="chevronDown"
                    color="galaxy-500"
                    size="m"
                    sx={{ transform: 'rotate(270deg)' }}
                />
            </FlexLayout>
            <FlexLayout
                justifyContent="center"
                alignItems="center"
                sx={iconWrapperStyle}
                onClick={increaseYear}
            >
                <Icon
                    icon="doubleChevron"
                    color="galaxy-500"
                    size="m"
                    sx={{ transform: 'rotate(270deg)' }}
                />
            </FlexLayout>
        </FlexLayout>
    );
}

const DatePicker = React.forwardRef(function DatePicker(
    { selectedDate, onChange, onSelect, sx, t },
    ref
) {
    const [isFormattedDateDisplayed, setFormattedDateDisplayed] =
        useState(selectedDate);

    const formattedDateStyle = {
        display: isFormattedDateDisplayed ? 'block' : 'none',
        position: 'absolute',
        marginLeft: '4.4rem',
        minWidth: '10.7rem',
        zIndex: theme.zIndex.drawer,
        backgroundColor: 'comet',
        borderRadius: '0.8rem',
        padding: '0.9rem',
        '&:hover': {
            backgroundColor: 'neptune-100',
            borderColor: 'neptune-100'
        }
    };

    const calendarIconWrapperStyle = {
        backgroundColor: 'neptune-100',
        width: '3.6rem',
        height: '3.6rem',
        borderRadius: '0.8rem'
    };

    function updatePlaceholder(e, isFocus) {
        e.target.placeholder = isFocus
            ? t('datepicker.mm/dd/yyyy')
            : t('datepicker.selectDay');
    }

    function showFormattedDate() {
        if (selectedDate) {
            setFormattedDateDisplayed(true);
        }
    }

    function hideFormattedDate() {
        setFormattedDateDisplayed(false);
        document.getElementById('datepicker').focus();
    }

    function onDateChange(date) {
        return onChange && onChange(date.toString());
    }

    return (
        <FlexLayout alignItems="center" sx={sx}>
            <FlexLayout
                sx={calendarIconWrapperStyle}
                justifyContent="center"
                alignItems="center"
                mr="0.8rem"
            >
                <Icon icon="calendar" color="neptune" size="m" />
            </FlexLayout>
            <Text
                onClick={hideFormattedDate}
                sx={formattedDateStyle}
                variant="16-normal"
            >
                {selectedDate
                    ? dateUtils.formatAbbreviatedDate(selectedDate)
                    : ''}
            </Text>
            <ReactDatePicker
                ref={ref}
                id="datepicker"
                selected={selectedDate}
                minDate={new Date()}
                onChange={onDateChange}
                onSelect={onSelect}
                placeholderText={t('datepicker.selectDay')}
                onFocus={(e) => updatePlaceholder(e, true)}
                onBlur={(e) => updatePlaceholder(e, false)}
                onCalendarClose={showFormattedDate}
                showPopperArrow={false}
                formatWeekDay={dateUtils.formatLetterDayOfWeek}
                renderCustomHeader={customHeader}
                autoComplete="off"
            />
        </FlexLayout>
    );
});

DatePicker.propTypes = {
    /** Selected date */
    selectedDate: PT.instanceOf(Date),
    /** On date value change function */
    onChange: PT.func,
    /** On date select function */
    onSelect: PT.func,
    /** Styling the wrapper */
    sx: PT.oneOfType([PT.object])
};

export default withTranslation()(DatePicker);
