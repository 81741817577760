import { WorkBook, JSON2SheetOpts } from 'xlsx';
import * as XLSX from 'xlsx';

/**
 * XLSXUtil data class
 *
 * @category Data Classes
 *
 * @example
 * import { XLSXUtil } from '~/data-classes';
 *
 * const spreadsheet = new XLSXUtil();
 *
 */
export class XLSXUtil {
    private readonly wb: WorkBook;

    constructor() {
        this.wb = XLSX.utils.book_new();
    }

    /**
     * Appends an array of objects as a spreadsheet in the current workbook instance
     *
     * @param {unknown[]} jsonData - an array of objects for the spreadsheet
     * @param {string} sheetName - the spreadsheet name
     * @param {JSON2SheetOpts} opts - options to pass to the underlying `XLSX.utils.json_to_sheet` function
     *
     * @see [XLSX.utils.json_to_sheet()]{@link https://docs.sheetjs.com/docs/api/utilities#array-of-objects-input}
     */
    appendSheet(
        jsonData: unknown[],
        sheetName: string,
        opts?: JSON2SheetOpts
    ): this {
        const sheetData = XLSX.utils.json_to_sheet(jsonData, opts);
        XLSX.utils.book_append_sheet(this.wb, sheetData, sheetName);

        return this;
    }

    /**
     * Saves the current workbook instance as a file.
     *
     * The file format is defaulted to `xlsx` unless specified by a `bookType` option
     *
     * @param {string} filename - the filename for the workbook
     * @param {XLSX.WritingOptions} opts - options to pass to the underlying `XLSX.writeFile` function
     *
     * @see [XLSX.writeFile()]{@link https://docs.sheetjs.com/docs/api/write-options}
     */
    save(filename: string, opts?: XLSX.WritingOptions): void {
        XLSX.writeFile(this.wb, filename, opts);
    }
}
