import React from 'react';

import ZoomControl from '~/ui/components/PlanMapOverlay/ZoomControl';
import MapTypeControl from '~/ui/components/PlanMapOverlay/MapTypeControl';

type Props = {
    mapInstance: unknown;
};

export const MapOverlay = ({ mapInstance }: Props): JSX.Element => {
    return (
        <div
            className="zonemanagement-mapsection-overlay"
            data-testid="zonemanagement-mapsection-overlay"
        >
            <div
                className="zonemanagement-mapsection-overlay-zoomcontrol _p-absolute"
                data-testid="zone-management-zoom-control"
            >
                <ZoomControl map={mapInstance} showControls />
            </div>
            <div
                className="zonemanagement-mapsection-overlay-maptypecontrol _p-absolute"
                data-testid="zone-management-map-type-control"
            >
                <MapTypeControl map={mapInstance} />
            </div>
        </div>
    );
};
