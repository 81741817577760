import React, { createContext, useContext, useState } from 'react';
import { useRouteFiltering } from '../useRouteFiltering';

export const PlanMapPropsContext = createContext({
    planStops: [],
    routesLevelData: [],
    handleLoadingData: () => {}
});

export const usePlanMapPropsContext = () => useContext(PlanMapPropsContext);

export const PlanMapPropsContextProvider = ({
    children,
    planStops = [],
    routesLevelData = [],
    handleLoadingData = () => {}
}) => {
    const [mapInstance, setMapInstance] = useState(null);
    const [mapsAPI, setMapsAPI] = useState(null);

    const { visibleOnMapRouteData } = useRouteFiltering({
        routesLevelData
    });

    return (
        <PlanMapPropsContext.Provider
            value={{
                planStops,
                routesLevelData,
                visibleOnMapRouteData,
                handleLoadingData,
                mapInstance,
                setMapInstance,
                mapsAPI,
                setMapsAPI
            }}
        >
            {children}
        </PlanMapPropsContext.Provider>
    );
};
