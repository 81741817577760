import { UIElement } from '../types';

/**
 * Enum of supported time input variants
 */
export enum TimeInputVariant {
    TABLE = 'table',
    FORM = 'form',
    NAKED = 'naked',
    LIVE_DISPATCH = 'live_dispatch'
}

/**
 * String union of supported time input variants
 *
 * Provided for backward compatibility
 */
export type TimeInputVariants = `${TimeInputVariant}`;

/**
 * Expected properties for the `TimeInput` component
 */
export interface TimeInputProps extends UIElement {
    /**
     * The time input value
     */
    value?: string;
    /**
     * The time input placeholder
     */
    placeholder?: string;
    /**
     * Whether the time input placeholder is shown
     */
    showPlaceholder?: boolean;
    /**
     * The time input `onChange` handler
     */
    onChange?: (newValue: string) => void;
    /**
     * The time input `onFocus` handler
     */
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    /**
     * Whether the time input is disabled
     */
    disabled?: boolean;
    /**
     * The time input variant
     */
    variant?: TimeInputVariant | TimeInputVariants;
    /**
     * Whether the time input empty values are valid
     */
    isEmptyValid?: boolean;
}
