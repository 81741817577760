import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectBreakDetailsId } from '~/reducers/selectedBreakDetailsSlice';
import { selectPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';

type StopLevelData = {
    task?: {
        breakDetails?: {
            id: string;
        };
    };
};

export const useIsModalVisible = () => {
    const planStopsLevelData = useSelector(
        selectPlanStopsLevelData
    ) as StopLevelData[];
    const { isPlanRouteMode } = useMapUtils();
    const selectedBreakDetailsId = useSelector(selectBreakDetailsId);

    const isBreakDetailsValid = useMemo(() => {
        if (!selectedBreakDetailsId) return false;

        return planStopsLevelData.some((stopLevelData) => {
            const { id: breakDetailsId } =
                stopLevelData.task?.breakDetails || {};

            return breakDetailsId === selectedBreakDetailsId;
        });
    }, [planStopsLevelData, selectedBreakDetailsId]);

    return isPlanRouteMode && isBreakDetailsValid;
};
