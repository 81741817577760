import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import './DraggedItem.scss';

export const DraggedItem = ({
    numberOfDraggedItems,
    children
}: {
    numberOfDraggedItems: number;
    children: JSX.Element | JSX.Element[] | null;
}) => {
    const { t } = useTranslation('common');
    const isMultiple = numberOfDraggedItems > 1;
    const rootClass = 'dragged-item';
    const className = classnames(rootClass, {
        [`${rootClass}--multiple`]: isMultiple
    });
    const multipleClassName = `${rootClass}__multiple-count`;
    const contentClassName = `${rootClass}__content`;
    return (
        <div className={className}>
            {isMultiple && (
                <div className={multipleClassName}>
                    {t('numberOfDraggedItems', { count: numberOfDraggedItems })}
                </div>
            )}
            <div className={contentClassName}>{children}</div>
        </div>
    );
};
