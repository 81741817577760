/**
 * Supported suggest metrics used for sorting suggestions
 * @category API
 */

export enum SupportedSuggestMetric {
    /**
     * Total route time of candidate driver
     */
    TOTAL_ROUTE_TIME = 'total_route_time',
    /**
     * Total number of delays of candidate driver
     */
    NUMBER_OF_DELAYS = 'number_of_delays',
    /**
     * ETA of candidate driver
     */
    ETA = 'eta',
    /**
     * Change in route time after adding tasks to candidate driver
     */
    INDUCED_ROUTE_TIME = 'induced_route_time',
    /**
     * Change in number of delays after adding tasks to candidate driver
     */
    INDUCED_NUMBER_OF_DELAYS = 'induced_number_of_delays',
    /**
     * Total cost computed through custom cost matrix
     */
    CUSTOM_COST = 'custom_cost',
    /**
     * Change in cost computed throguh custom cost matrix
     */
    INDUCED_CUSTOM_COST = 'induced_custom_cost'
}

export interface SuggestOptions {
    /**
     * Suggest metric to sort results by
     */
    orderBy?: SupportedSuggestMetric;
    /**
     * Indicates whether candidate route metrics should be included in response
     */
    includeMetrics?: boolean;
    /**
     * Radius beyond which to exclude drivers
     */
    radius?: number;
    /**
     * Indicates whether active drivers should be prioritized
     */
    prioritizeActiveDrivers?: boolean;
}
