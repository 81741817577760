import React from 'react';
import classnames from 'classnames';
import { Icon, UIElement } from '~/ui';

import { IconName } from '../Icon/IconNames';
import { IconSizes } from '../Icon/IconSizes';

import './ViolationPill.scss';

const rootClassName = 'violation-pill';

interface ViolationPillProps extends UIElement {
    text: string;
    icon?: IconName;
    iconColor?: string;
}

export const ViolationPill = ({
    icon,
    text,
    iconColor = 'comet',
    className
}: ViolationPillProps) => {
    const elementClassName = classnames(rootClassName, className);
    const textClassName = classnames(
        `${rootClassName}__text`,
        `${className}__text`
    );

    return (
        <div className={elementClassName} data-testid={rootClassName}>
            <span className={textClassName}>{text}</span>
            {icon && (
                <Icon
                    icon={icon}
                    size={IconSizes.XS}
                    color={iconColor}
                    data-testid={`icon-${icon}`}
                />
            )}
        </div>
    );
};
