import React from 'react';
import { Icon } from '~/ui/components';
import { DepotMarkerProps } from './types';
import './DepotMarker.scss';

const ROOT_CLASS_NAME = 'depot-marker';

const DepotMarker = ({ 'data-testid': dataTestId }: DepotMarkerProps) => {
    return (
        <div
            className={`${ROOT_CLASS_NAME} _jc-center _ai-center`}
            data-testid={dataTestId || ROOT_CLASS_NAME}
        >
            <Icon icon="depot" size="s" color="white" />
        </div>
    );
};

export default DepotMarker;
