import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { ApiVehicleProfile } from '~/api/types';
import VehicleProfileApi from '~/api/VehicleProfileApi';

interface UseFetchVehicleProfilesProps {
    queryKey: string;
}

const emptyList: ApiVehicleProfile[] = [];

export const useFetchVehicleProfiles = ({
    queryKey
}: UseFetchVehicleProfilesProps) => {
    const fetchVehicleProfiles = async (): Promise<ApiVehicleProfile[]> => {
        const {
            data: { data }
        } = await VehicleProfileApi.getVehicleProfiles();
        return data;
    };

    const { isFetching, isError, data } = useQuery(queryKey, () =>
        fetchVehicleProfiles()
    );

    return useMemo(() => {
        const vehicleProfiles = data ?? emptyList;

        return {
            isError,
            isFetching,
            vehicleProfiles
        };
    }, [data, isError, isFetching]);
};
