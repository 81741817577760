import { ApiInventoryItem } from '~/api/types';
import { getAllSignedInventoryItemsByRouteDate } from './getAllSignedInventoryItemsByRouteDate';

export const getSignedInventoryItemsById = async (routeDate: string) => {
    const inventoryItems = await getAllSignedInventoryItemsByRouteDate(
        routeDate
    );
    const entries = inventoryItems
        .map((inventoryItem) => {
            const { id, assignment_id: assignmentId } = inventoryItem;

            if (!(assignmentId && id)) return null;

            return [id, inventoryItem];
        })
        .filter(Boolean) as [string, ApiInventoryItem][];

    return Object.fromEntries(entries);
};
