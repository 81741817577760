import { markerMaker } from '~/utils/map';
import { MakeUnassignedStopMarkerEffects } from './types';

export const makeUnassignedStopMarkerEffects = ({
    isClusteringStops,
    unassignedSuperClusters,
    getClusters,
    makeUnassignedClusterMarkers,
    emittedEventHandler,
    planTasks
}: MakeUnassignedStopMarkerEffects) => {
    if (isClusteringStops) {
        const [superClusterMarkers] = unassignedSuperClusters.map(
            (unassignedSuperCluster, index) => {
                const geoJSONFeatures = getClusters(unassignedSuperCluster);
                return makeUnassignedClusterMarkers({
                    superCluster: unassignedSuperCluster,
                    geoJSONFeatures,
                    superClusterIndex: index,
                    emittedEventHandler
                }) as JSX.Element[];
            }
        );

        return superClusterMarkers;
    }

    return markerMaker.makeUnassignedStopMarkers(
        planTasks,
        emittedEventHandler
    ) as JSX.Element[];
};
