import React, { useState } from 'react';
import classnames from 'classnames';

import DropDownMenu from '../DropDownMenu/DropDownMenu';
import DropDownMenuItem from '../DropDownMenu/DropDownMenuItem';
import LabelInput from '../LabelInput/LabelInput';
import { ValidatedInput } from '~/components/ValidatedInput';
import { floatValidator, useDropDownMenuHandler } from '~/hooks';

import './DropDownWithInputBox.scss';
import '../DropDownMenu/DropDownMenu.scss';

type DropDownMenuItemType = {
    text: string;
    unit?: string;
    id: string;
    onClick?: () => void;
};

interface DropDownWithInputBoxProps {
    id: string;
    dropDownTitle?: string;
    dropDownPlaceholder: string;
    dropDownSelectedItemId?: string;
    dropDownMenuItems: DropDownMenuItemType[];
    inputTestId?: string;
    inputBoxValue?: string;
    inputBoxPlaceholder: string;

    unitValue?: string;
    onChange: (values: {
        componentId: string;
        dropDownItemId: string;
        value: string;
        label?: string;
    }) => void;
}

export default function DropDownWithInputBox({
    id,
    dropDownTitle = '',
    dropDownPlaceholder,
    dropDownSelectedItemId = '',
    dropDownMenuItems,
    inputTestId = undefined,
    inputBoxValue = '',
    inputBoxPlaceholder,
    unitValue = '',
    onChange
}: DropDownWithInputBoxProps) {
    const [title, setTitle] = useState<string>(dropDownTitle);
    const [labelInputValue, setLabelInputValue] =
        useState<string>(inputBoxValue);
    const [isLabelInputFocus, setIsLabelInputFocus] = useState<boolean>(false);
    const [isDropDownFocus, setIsDropDownFocus] = useState(false);
    const [units, setUnits] = useState(unitValue);
    const [selectedItemId, setSelectedItemId] = useState(
        dropDownSelectedItemId
    );

    const {
        ref: dropdownRef,
        isOpen,
        onToggle,
        onClose
    } = useDropDownMenuHandler();

    const separatorClassNames = classnames(
        'dropdown-with-inputbox__separator',
        {
            'dropdown-with-inputbox__separator--focus':
                isLabelInputFocus || isDropDownFocus
        }
    );

    function onClickMenuItem(index: number) {
        const dropDownMenuItem = dropDownMenuItems[index];
        setTitle(dropDownMenuItem.text);
        setUnits(dropDownMenuItem.unit || '');
        setSelectedItemId(dropDownMenuItem.id);
        onToggle();
        onChange({
            label: dropDownMenuItem.text,
            componentId: id,
            dropDownItemId: dropDownMenuItem.id,
            value: labelInputValue
        });
    }

    function handleInputChange(value: string) {
        setLabelInputValue(value);
        onChange({
            label: title,
            componentId: id,
            dropDownItemId: selectedItemId,
            value
        });
    }

    return (
        <div className="_d-flex _ai-center dropdown-with-inputbox ">
            <DropDownMenu
                title={{
                    name: title
                }}
                placeholder={dropDownPlaceholder}
                styles={{
                    outerContainer: '_w-100',
                    innerContainer:
                        'dropdown-flex-space-between dropdown-with-inputbox__outline',
                    menuTitle: 'dropdown-selection',
                    menuTitleText: 'dropdown-title-text',
                    dropdownToggleColor: 'var(--color-galaxy-800)'
                }}
                data-testid="dropdown"
                icon="chevronDown"
                position="down"
                ref={dropdownRef}
                isOpen={isOpen}
                close={onClose}
                toggle={onToggle}
                onMouseEnter={() => setIsDropDownFocus(true)}
                onMouseLeave={() => setIsDropDownFocus(false)}
            >
                {dropDownMenuItems.map(
                    (menuItem: DropDownMenuItemType, index) => (
                        <DropDownMenuItem
                            key={menuItem.text}
                            menuItem={{
                                text: menuItem.text,
                                onClick: () => onClickMenuItem(index)
                            }}
                        />
                    )
                )}
            </DropDownMenu>
            <div className={separatorClassNames} data-testid="separator" />
            <div className="dropdown-with-inputbox__input-container _p-relative _d-flex _w-100 _ai-center">
                <ValidatedInput
                    inputComponent={LabelInput}
                    placeholder={inputBoxPlaceholder}
                    validator={floatValidator}
                    value={labelInputValue}
                    onValueChange={(e) => handleInputChange(e)}
                    onFocus={() => setIsLabelInputFocus(true)}
                    onBlur={() => setIsLabelInputFocus(false)}
                    data-testid={inputTestId ?? 'validated-input'}
                />
                <span
                    className="dropdown-with-inputbox__input-container-unit _p-absolute"
                    data-testid="dropdown-inputbox-units"
                >
                    {units}
                </span>
            </div>
        </div>
    );
}
