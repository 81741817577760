import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import Icon from '../Icon/Icon';
import { TimeInputProps, TimeInputVariant } from './types';

import './TimeInput.scss';

const ROOT_CLASS_NAME = 'time-input';

function TimeInput({
    value,
    placeholder,
    showPlaceholder = false,
    onChange,
    onFocus,
    disabled,
    variant = TimeInputVariant.TABLE,
    isEmptyValid = false,
    className,
    'data-testid': dataTestId
}: TimeInputProps): JSX.Element {
    const isValidTime = useMemo<boolean>(() => {
        const isEmptyValueValid = !value && isEmptyValid;
        const datetime = value ? DateTime.fromFormat(value, 'T') : null;
        const isDateTimeValid = Boolean(datetime?.isValid);
        return isEmptyValueValid || isDateTimeValid;
    }, [value, isEmptyValid]);

    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_d-flex',
        '_ai-center',
        '_w-100',
        className
    );
    const conditionalInputClasses = {
        timeinput__error: !isValidTime,
        with_placeholder: !value && showPlaceholder && placeholder,
        timeinput__form: variant === TimeInputVariant.FORM,
        timeinput__naked: variant === TimeInputVariant.NAKED,
        timeinput__table: variant === TimeInputVariant.TABLE,
        timeinput__live_dispatch: variant === TimeInputVariant.LIVE_DISPATCH,
        [`${className}__input`]: className
    };
    const inputClassName = classNames('timeinput', conditionalInputClasses);

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    const onInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            return onChange?.(e.target.value);
        },
        [onChange]
    );

    return (
        <div className={elementClassName}>
            <input
                type="time"
                className={inputClassName}
                placeholder={placeholder}
                data-placeholder={placeholder}
                value={value}
                onChange={onInputChange}
                onFocus={onFocus}
                disabled={disabled}
                data-testid={elementDataTestId}
            />
            {variant === TimeInputVariant.TABLE && (
                <Icon
                    className="timeinput__icon"
                    icon="clock"
                    color={isValidTime ? 'galaxy-600' : 'mars-500'}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default TimeInput;
