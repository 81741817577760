import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

interface UseStopMarkerLabelProps {
    customLabel?: ReactNode;
    label?: string;
    mapRouteMode?: ConfigurableMapRouteMode;
}

export const useStopMarkerLabel = ({
    customLabel,
    label,
    mapRouteMode = constants.mapRouteModes.PLAN
}: UseStopMarkerLabelProps) => {
    const isShowStopLabel = useSelector(selectShowStopLabel(mapRouteMode));

    if (!isShowStopLabel) return null;

    if (customLabel) return customLabel;

    return <span className="stopmarker-label">{label}</span>;
};
