import useReassignPlanStops from './useReassignPlanStops';
import useReassignDispatchedStops from './useReassignDispatchedStops';

import { PlanRoute, PlanStop } from '~/data-classes';
import { useMapUtils } from '~/hooks';

type UseReassignSetupProps = {
    selectedRouteId: string;
    selectedTripId: string;
    routes: PlanRoute[];
    selectedStops: PlanStop[];
};

const useReassignSetup = ({
    selectedRouteId,
    routes,
    selectedStops,
    selectedTripId
}: UseReassignSetupProps) => {
    const { isPlanRouteMode } = useMapUtils();

    const { reassignStops, showTrips, routeTrips } = useReassignPlanStops({
        selectedRouteId,
        routes,
        selectedStops,
        selectedTripId
    });

    const { reassignDispatchedStops } = useReassignDispatchedStops({
        selectedRouteId,
        routes,
        selectedStops
    });

    if (isPlanRouteMode) {
        return {
            reassignStops,
            showTrips,
            routeTrips
        };
    }
    return {
        reassignStops: reassignDispatchedStops,
        showTrips: false,
        routeTrips: []
    };
};

export default useReassignSetup;
