import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { className as addTaskClassName } from '../../utils/addTaskModalUtils';
import { AddItemButton } from '../../AddItemButton';
import { useAddTaskModalContext } from '../../AddTaskModalContext';
import { useClickHandler } from './useClickHandler';

export const AddInvoiceRow = () => {
    const { t } = useTranslation('addTask');
    const clickHandler = useClickHandler();
    const {
        clientTask: { invoices = [] }
    } = useAddTaskModalContext();

    useEffect(() => {
        if (!invoices.length) clickHandler();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [invoices]);

    return (
        <div
            className="_d-flex _jc-space-between _ai-center"
            data-testid="add-invoice-row"
        >
            <span className={`${addTaskClassName}_label`}>
                {t('invoice.paymentDetails')}
            </span>
            <AddItemButton
                data-testid="add-invoice-row-button"
                text={t('invoice.addAnotherItem')}
                onClick={clickHandler}
            />
        </div>
    );
};
