import React, { useCallback } from 'react';

import { useControlledInput } from '~/hooks';

type InputProps = React.ComponentPropsWithRef<'input'>;
type InputComponent = React.ComponentType<InputProps>;

type OnChange = NonNullable<InputProps['onChange']>;

export interface BaseValidatedInputProps<
    C extends InputComponent = InputComponent
> {
    inputComponent?: C;
    validator?: (s: string) => boolean;

    /**
     * Called whenever the local input value is about to be changed
     */
    onValueChange?: (s: string) => void;
}

export type ValidatedInputProps<C extends InputComponent = InputComponent> =
    InputProps & React.ComponentProps<C> & BaseValidatedInputProps<C>;

/**
 * An input component that can utilize a validator, such that the inputs' current value will only
 * change if the validator passes the incoming input value
 */
export const ValidatedInput = <C extends InputComponent>({
    onChange: propsOnChange,
    inputComponent,
    validator,
    onValueChange,
    ...inputProps
}: ValidatedInputProps<C>): JSX.Element => {
    const initialValue = (inputProps.value ?? '').toString();
    const [value, localOnChange] = useControlledInput({
        initialValue,
        updateStateWithArg: true,
        validator,
        onValueChange
    });

    const onChange = useCallback<OnChange>(
        (e) => {
            propsOnChange?.(e);
            localOnChange?.(e);
        },
        [propsOnChange, localOnChange]
    );

    const InputComponent = inputComponent ?? 'input';

    return <InputComponent {...inputProps} {...{ onChange, value }} />;
};
