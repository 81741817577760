import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { useGetCompartments } from '~/hooks/useGetCompartments';
import fileUtils from '~/utils/file-utils';
import taskTemplateUtils from '~/utils/taskTemplateUtils';

export const useDownloadTaskTemplate = () => {
    const { t } = useTranslation(['taskUpload']);
    const mainClient = useSelector(selectMainClient);
    const { compartments, isMultiCompartment } = useGetCompartments();

    const fileName = t('taskUploadTemplateFileName', {
        clientName: mainClient?.name
    });

    const getCompartmentHeaders = () => {
        if (!isMultiCompartment) {
            return {
                CEs_: '10'
            };
        }
        return taskTemplateUtils.getCompartmentHeaders(compartments);
    };

    const excelData = [
        {
            Departure_Date_Time_: '10/08/2020',
            Sequence_Number_: '1',
            Route_ID_: 'AB1',
            Location_ID_: 'Company Name',
            Description_: 'Headquarters',
            Address_Line1_: '84 Sherman Street',
            City_: 'Cambridge',
            State_: 'MA',
            Zip_Code_: '02140',
            Units_: '10',
            Weight_: '1000',
            Service_Time_: '00:18:32',
            TW1_Open_Time_: '5:00',
            TW1_Close_Time_: '21:00',
            TW2_Open_Time_: '10:00',
            TW2_Close_Time_: '13:00',
            ...getCompartmentHeaders(),
            Priority_: '1',
            Vehicle_Type_1_: 'End_Load_22',
            Task_ID_: '67002',
            Latitude_: '33.2280276',
            Longitude_: '-96.9840259',
            Contact_Name_: 'John Smith',
            Phone_Number_: '857-327-5802',
            Contact_Email_: 'contact@example.com',
            Premise_Type_: 'OffPremise',
            Label_1_: 'Custom-field1',
            Label_2_: 'Custom-field2',
            Note_1_: 'Go to backdoor',
            Inventory_Order_1_: '12345',
            Inventory_ID_1_: 'AC4',
            Inventory_Quantity_1_: '3',
            Inventory_Item_1_: 'apples'
        }
    ];

    const downloadTaskTemplate = () => {
        return fileUtils.saveExcelSheet(
            excelData,
            fileName,
            t('taskUploadSheetName')
        );
    };

    return {
        downloadTaskTemplate
    };
};
