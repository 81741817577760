/* eslint-disable camelcase */
import axios from 'axios';
import dateUtils from '~/utils/date-utils';
import { ApiRoutePlan, AxiosApiResponse, PaginationParams } from './types';

export interface GetRoutePlansParams extends PaginationParams {
    /**
     * This should be the stringified version of a client ids array
     *
     * @example
     * client_ids: clientIds.join(',')
     */
    client_ids?: string;

    /**
     * The route date to start from in ISO format
     */
    start_from?: string;

    active?: boolean;

    /**
     * This should be the stringified version of an array of fields to include in the RoutePlan
     * response. These fields should be fields of `ApiRoutePlan`
     *
     * @example
     * const fields = [
     *  'number_of_tasks',
     *  'number_of_unassigned_tasks',
     *  'number_of_open_routes',
     *  'stage'
     * ];
     * include: fields.join(',')
     */
    include?: string;
}

export type GetRoutePlansResponse = AxiosApiResponse<ApiRoutePlan[]>;

export interface GenerateRoutesReportParams {
    /* Date in ISO format */
    route_date?: string;
    template?: string;
    filename?: string;
    to_export?: boolean;
}

export interface GenerateRoutesReportArgs
    extends Omit<GenerateRoutesReportParams, 'route_date'> {
    /** @TODO Change this to DateConstructorInput */
    route_date?: string | number | Date;
}

export interface GenerateRoutesReportResponseData {
    url?: string;
    name?: string;
}

export type GenerateRoutesReportResponse =
    AxiosApiResponse<GenerateRoutesReportResponseData>;

/**
 * Implementations of API methods under the `/routeplans` path
 *
 * @category API
 */
export default class RoutePlansApi {
    /**
     * Path of the endpoint
     */
    private static readonly path = '/routeplans';

    private static readonly defaultLimit = 1000;

    static get(
        params: GetRoutePlansParams = {}
    ): Promise<GetRoutePlansResponse> {
        const limit = params?.limit || this.defaultLimit;
        return axios.get(this.path, { params: { ...params, limit } });
    }

    static generateRoutesReport(
        inputParams: GenerateRoutesReportArgs = {
            route_date: undefined,
            template: undefined,
            filename: undefined,
            to_export: undefined
        }
    ): Promise<GenerateRoutesReportResponse> {
        if (!inputParams.route_date) {
            return Promise.reject('missing route_date querystring parameter');
        }

        const inputRouteDate = inputParams.route_date;

        const params: GenerateRoutesReportParams = {
            ...inputParams,
            route_date: inputRouteDate
                ? dateUtils.convertToISODateOnly(inputRouteDate) ?? undefined
                : undefined
        };

        return axios.post(`${this.path}/report`, undefined, {
            params
        });
    }
}
