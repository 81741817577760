import React from 'react';
import classNames from 'classnames';

import './manage-stop-control-menu.scss';

function ManageStopControlMenu({ children, className, ...extra }) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_menu _fd-column`;
        return classNames(defaultClassName, className);
    }
    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol_menu'}
        >
            {children}
        </div>
    );
}

export { ManageStopControlMenu };
