import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { useGetTaskDetails } from '~/components/TaskManagementPage/TaskDetailDrawer/useGetTaskDetails';
import { DuplicateTaskModalContext } from './DuplicateTaskModalContext';
import { ActionTypes, AssignmentTypes, ItemTypes } from '../types';
import { useDuplicateTaskModalContextProvider } from './useDuplicateTaskModalContextProvider';

interface DuplicateTaskModalContextProviderProps {
    children: ReactNode;
    onClose: () => void;
    taskId: string | null;
}

export const DuplicateTaskModalContextProvider = ({
    children,
    onClose,
    taskId
}: DuplicateTaskModalContextProviderProps) => {
    const { apiTask } = useGetTaskDetails({
        taskId
    });

    const { onSubmit } = useDuplicateTaskModalContextProvider({
        apiTask,
        onClose
    });

    if (!apiTask) return null;

    return (
        <DuplicateTaskModalContext.Provider
            value={{
                apiTask,
                onClose
            }}
        >
            <Formik
                initialValues={{
                    actionType: ActionTypes.MOVE_INVOICE_OR_INVENTORY,
                    assignmentType: AssignmentTypes.CREATE_TASK,
                    itemType: ItemTypes.INVOICE,
                    invoicesIds: []
                }}
                onSubmit={onSubmit}
            >
                {children}
            </Formik>
        </DuplicateTaskModalContext.Provider>
    );
};
