import axios from 'axios';
import _ from 'lodash';
import constants from '~/utils/constants';
import { AxiosApiResponse } from '~/api/types';
import {
    ZoneDataCreate,
    APIZoneFormat,
    ZoneCoordinates,
    ZoneObject
} from '~/components/ZoneManagementPage/types';

type ZoneDataEdit = {
    name?: string;
    geometry?: {
        type: string;
        coordinates: ZoneCoordinates;
    };
};

type DeleteZoneVehicleAssociation = {
    zoneId: string;
    vehicleId: string;
};

type ZoneVehicleAssociationRecord = {
    zoneId: string;
    vehicleId: string;
};

class ZonesAPI {
    private static readonly path = '/zones';

    private static readonly vehicleAssociationPath = '/zonevehicleassociation';

    public static get(params = {}): Promise<AxiosApiResponse> {
        return axios.get(this.path, {
            params
        });
    }

    public static delete(zoneIds: string[]): Promise<AxiosApiResponse> {
        if (!zoneIds?.length || !Array.isArray(zoneIds)) {
            return Promise.reject('An array of ids is required');
        }

        return axios.delete(this.path, {
            data: { ids: zoneIds }
        });
    }

    public static edit(
        zoneId: string,
        data: ZoneDataEdit
    ): Promise<AxiosApiResponse> {
        if (!zoneId) {
            return Promise.reject('Zone id is required');
        }
        if (_.isEmpty(data)) {
            return Promise.reject('Zone data to update is required');
        }

        return axios.patch(encodeURI(`${this.path}/${zoneId}`), data);
    }

    public static getVehicleAssociations(): Promise<AxiosApiResponse> {
        return axios.get(this.vehicleAssociationPath);
    }

    public static deleteVehicleAssociation(
        data: DeleteZoneVehicleAssociation
    ): Promise<AxiosApiResponse> {
        const { zoneId, vehicleId } = data || {};
        if (!zoneId || !vehicleId) {
            return Promise.reject('Zone id and vehicle id are required');
        }

        return axios.delete(this.vehicleAssociationPath, {
            params: {
                zone_id: zoneId,
                vehicle_id: vehicleId
            }
        });
    }

    public static createVehicleAssociation(
        associations: ZoneVehicleAssociationRecord[]
    ): Promise<AxiosApiResponse> {
        if (!associations?.length) {
            return Promise.reject(
                'Array of zone/vehicle associations must be provided'
            );
        }

        let missingArgs = false;
        for (const association of associations) {
            const { zoneId, vehicleId } = association;
            if (!zoneId || !vehicleId) {
                missingArgs = true;
                break;
            }
        }
        if (missingArgs) {
            return Promise.reject(
                'Zone id and vehicle id must be provided for each association'
            );
        }

        const associationsToCreate = associations.map((record) => {
            return {
                zone_id: record.zoneId,
                vehicle_id: record.vehicleId
            };
        });

        return axios.post(this.vehicleAssociationPath, associationsToCreate);
    }

    public static async create(data: ZoneDataCreate): Promise<ZoneObject> {
        const expectedZoneFormat = {
            type: 'feature',
            properties: {
                name: undefined
            },
            geometry: {
                type: 'Polygon',
                coordinates: undefined
            }
        };

        if (!data) {
            return Promise.reject('Zone object must be provided');
        }
        if (!data.name || !data.coordinates) {
            return Promise.reject('Zone name and coordinates must be provided');
        }

        const mappedData = {
            ...expectedZoneFormat,
            properties: { name: data.name },
            geometry: {
                ...expectedZoneFormat.geometry,
                coordinates: data.coordinates
            }
        } as APIZoneFormat;
        const payload = [mappedData];

        try {
            const createdZoneResponse = await axios.post(
                encodeURI(this.path),
                payload
            );
            const createdZone = createdZoneResponse?.data?.data?.success[0];
            return await Promise.resolve(createdZone);
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }

    public static uploadZoneFile(
        clientId: string,
        file: APIZoneFormat
    ): Promise<ZoneObject> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!file) {
            return Promise.reject('Missing file');
        }

        return axios.post(this.path, file, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }
}

export { ZonesAPI as zonesAPI };
