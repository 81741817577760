import React from 'react';

import { PlanRoute } from '~/data-classes/plan';
import { RouteTripStats } from '~/components/MapPageDrawers/SelectedCardsDrawer/types';
import { TripListItemVariants } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/TripListItem/TripListItem';

import {
    CompartmentUsageContainer,
    RouteCard,
    RouteCardMarker,
    RouteCardSummary,
    RouteCardTitle
} from '~/ui';
import { TripListItem } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/TripListItem';

import { idUtils } from '~/utils/id-utils';
import { useProcessedRouteData } from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/useProcessedRouteData';

import './RouteItemViewSummary.scss';

interface RouteItemViewSummaryProps {
    route: PlanRoute;
}

export const RouteItemViewSummary = ({
    route
}: RouteItemViewSummaryProps): JSX.Element => {
    const {
        isMultiTrips,
        singleTripCompartmentStats,
        summaryMetrics,
        markerColor,
        iconColor,
        isMultiCompartmentClient,
        vehicleCapacity,
        titleLine,
        isLocked,
        trips,
        clientId
    } = useProcessedRouteData(route);

    const rootClassName = 'route-item route-item__summary';

    return (
        <div data-testid={rootClassName} className={rootClassName}>
            <RouteCard>
                <div className="_d-flex _fd-column _rg-2 _w-100">
                    <div className="_d-flex _fd-row _cg-2">
                        <div className="routeitem__markers _fd-column _ai-center _jc-space-between">
                            <RouteCardMarker
                                disabled
                                isRouteLocked={isLocked}
                                iconStyle={markerColor}
                                icon="route"
                                iconSize="s"
                                iconColor={iconColor}
                            />
                        </div>
                        <div className="routeitem-main">
                            <RouteCardTitle
                                title={titleLine}
                                tooltip={titleLine}
                                tooltipPlacement="top"
                            />
                            <RouteCardSummary
                                className="routeitem__summary _d-grid"
                                metrics={summaryMetrics}
                                data-testid="route-item-summary"
                            />
                            {!isMultiTrips && (
                                <CompartmentUsageContainer
                                    isIndicateOverCapacity
                                    isMultiCompartment={
                                        isMultiCompartmentClient
                                    }
                                    compartmentStats={
                                        singleTripCompartmentStats
                                    }
                                />
                            )}
                        </div>
                    </div>
                    {isMultiTrips && (
                        <div className="routeitem__trips">
                            <>
                                {trips.map(
                                    (
                                        trip: Record<string, unknown>,
                                        index: number
                                    ) => {
                                        const {
                                            stats: tripStats,
                                            routeId: tripRouteId
                                        } = trip;
                                        const tripClientRouteId =
                                            idUtils.getCombinedId(
                                                clientId,
                                                tripRouteId as string
                                            );
                                        return (
                                            <TripListItem
                                                key={tripClientRouteId}
                                                isMultiCompartment={
                                                    isMultiCompartmentClient
                                                }
                                                vehicleCapacity={
                                                    vehicleCapacity
                                                }
                                                index={index}
                                                clientRouteId={
                                                    tripClientRouteId
                                                }
                                                markerColor={markerColor}
                                                iconColor={iconColor}
                                                stats={
                                                    tripStats as RouteTripStats
                                                }
                                                variant={
                                                    TripListItemVariants.SMALL
                                                }
                                            />
                                        );
                                    }
                                )}
                            </>
                        </div>
                    )}
                </div>
            </RouteCard>
        </div>
    );
};
