import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import {
    selectAreRoutesLoading,
    selectAreStopsLoading
} from '~/reducers/dataLoadingSlice';
import {
    selectMapRouteMode,
    selectShowUnassignedTasks,
    selectShouldFitPlanMapToBounds,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

import { useIsolatedRoutes } from '~/hooks';
import { useMapViewControl } from '~/ui/components/PlanMapOverlay/MapViewControl/useMapViewControl';
import constants from '~/utils/constants';

export const useFitMapToBounds = () => {
    const dispatch = useDispatch();

    const setShouldFitToBounds = useCallback(
        (value: boolean) => {
            return dispatch(setShouldFitPlanMapToBounds(value));
        },
        [dispatch]
    );

    const shouldFitToBounds = useSelector(selectShouldFitPlanMapToBounds);

    return {
        shouldFitToBounds,
        setShouldFitToBounds
    };
};

export const useMapBoundingEffects = () => {
    const dispatch = useDispatch();

    const { setShouldFitToBounds } = useFitMapToBounds();
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const mapRouteMode = useSelector(selectMapRouteMode);
    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const areRoutesLoading = useSelector(selectAreRoutesLoading);
    const areStopsLoading = useSelector(selectAreStopsLoading);
    const { isRouteMultiSelectInProgress, hasIsolatedRoutes } =
        useIsolatedRoutes();

    const isActivelyMultiSelecting =
        isRouteMultiSelectInProgress && selectedMapRoutes.length > 1;
    const { isStopsViewSelected } = useMapViewControl();

    // reset zoom when selected map routes change
    useEffect(() => {
        if (isActivelyMultiSelecting) {
            return;
        }
        dispatch(setLastPlanMapZoom(constants.mapOptionSettings.DEFAULT_ZOOM));
    }, [
        dispatch,
        isActivelyMultiSelecting,
        showUnassignedTasks,
        selectedMapRoutes,
        hasIsolatedRoutes
    ]);

    // fit bounds effect when routes and stops are done loading
    //
    // @note:
    // + `handleLoadingData()` from `usePlanMapPropsContext() is responsible for
    //   setting `areRoutesLoading` and `areStopsLoading`
    // + a useEffect triggers `handleLoadingData()` when markers and/or mapRouteMode changes
    useEffect(() => {
        const isDataLoading = areRoutesLoading || areStopsLoading;
        setShouldFitToBounds(!isDataLoading && !isStopsViewSelected);
    }, [
        setShouldFitToBounds,
        areRoutesLoading,
        areStopsLoading,
        isStopsViewSelected
    ]);

    // fit bounds when selecting routes
    //
    // @note:
    // + updates when displaying selected route stops or unassigned task markers
    useEffect(() => {
        setShouldFitToBounds(!isActivelyMultiSelecting && !isStopsViewSelected);
    }, [
        setShouldFitToBounds,
        isActivelyMultiSelecting,
        showUnassignedTasks,
        selectedMapRoutes,
        hasIsolatedRoutes,
        isStopsViewSelected
    ]);
};
