import i18n from 'i18next';
import { ApiInventoryItemStatus, TaskTypes } from '~/api/types';

export const getLocalizedInventoryStatus = (status?: string) => {
    switch (status) {
        case ApiInventoryItemStatus.UNCONFIRMED:
        case ApiInventoryItemStatus.CONFIRMED:
        case ApiInventoryItemStatus.CANCELLED:
        case ApiInventoryItemStatus.PARTIAL:
        case ApiInventoryItemStatus.TRY_LATER:
            return i18n.t(`common:inventoryItemStatus.${status}`);
        default:
            return status;
    }
};

export const getLocalizedTaskType = (status: string) => {
    switch (status) {
        case TaskTypes.DELIVERY:
        case TaskTypes.PICKUP:
        case TaskTypes.TWOPART:
            return i18n.t(`common:taskType.${status}`);
        default:
            return status;
    }
};

export const getLocalizedCompletionPercentageNotProvided = () => {
    return i18n.t('reports:liveDriver.completionPercentageNotProvided');
};
