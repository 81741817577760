import React from 'react';
import './CompartmentTag.scss';

interface CompartmentTagProps {
    label: string;
    unit: string;
    capacity: string;
}

function CompartmentTag({ label, unit, capacity }: CompartmentTagProps) {
    return (
        <div
            className="_d-flex _ai-center _jc-space-between compartment-tag"
            data-testid="compartment-tag"
        >
            <span
                className="compartment-tag__label-unit"
                data-testid="label-unit"
            >
                {label}-{unit}
            </span>
            <span
                className="compartment-tag__separator"
                data-testid="separator"
            >
                |
            </span>
            <span className="compartment-tag__capacity" data-testid="capacity">
                {capacity}
            </span>
        </div>
    );
}

export default CompartmentTag;
