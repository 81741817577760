import i18n from 'i18next';
import root from 'window-or-global';
import { Address } from '~/api/types';
import timeUtils from '../time-utils';
import unitsUtils, { Options } from '../unit-converter';
import generalUtils from '../general-utils';
import dateUtilsConverters from '../date-utils-converters';
import constants from '../constants';

const { enUS } = constants.locales;

// see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
const japaneseLanguageIsoCodes = ['ja', 'jpn'];

type GetTotalDistanceTextOptions = Omit<Options, 'language'>;

function getTotalDistanceText(
    totalMeters: number,
    options: GetTotalDistanceTextOptions = { useAbbreviated: true }
) {
    const { numDecimalPlaces, useAbbreviated } = options;
    const distance = unitsUtils.getLocalDistance(totalMeters, {
        numDecimalPlaces
    });
    const units = unitsUtils.getLocalDistanceUnits({ useAbbreviated });
    return i18n.t(units, { distance });
}

function formatDuration(duration: number) {
    const timeRemaining =
        dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
            duration
        );

    return i18n.t('common:time.duration_hm', timeRemaining);
}

function formatUnitsAndWeight(unitsAndWeight: {
    numUnits: string;
    totalWeight: string;
}) {
    const { numUnits, totalWeight } = unitsAndWeight;
    return i18n.t('unitsAndWeight', { numUnits, totalWeight });
}

function formatNumInventory(count: number) {
    return i18n.t('numInventoryItems', { count });
}

function formatNumStops(numStops: number) {
    return i18n.t('stopWithCount', { count: numStops });
}

function formatAddress(address?: Address | string) {
    if (!address) return;

    if (typeof address === 'string') return address;

    const { addressLine2 } = address;
    return addressLine2
        ? i18n.t('common:address.hasLine2', address)
        : i18n.t('common:address.default', address);
}

/** Returns formatted address inputs
 * depending on if language set is Japanese
 *
 * @returns {[]}
 */
function formatAddressInputsByLocale(
    englishFormatAddressInputs: JSX.Element[]
): JSX.Element[] {
    const [addressLine1, addressLine2, ...japaneseFormatAddressInputs] =
        englishFormatAddressInputs;

    japaneseFormatAddressInputs.reverse();
    japaneseFormatAddressInputs.push(addressLine1, addressLine2);

    return isLanguageJapanese()
        ? japaneseFormatAddressInputs
        : englishFormatAddressInputs;
}

/** Returns true/false if language set is Japanese
 *
 * @returns {boolean}
 */
function isLanguageJapanese(): boolean {
    const { language } = root.navigator;
    const [languageCode] = (language || '').split('-');

    return japaneseLanguageIsoCodes.includes(languageCode);
}

/** Returns true/false value for US regions
 *
 * @returns {boolean}
 */
function isUSRegion(): boolean {
    return navigator.language === enUS;
}
/**
 * Converts the given number of seconds to a localized duration
 *
 * @param {number} seconds - The number of seconds to convert
 * @param {string} [timeFormat='duration_hm'] - The format of the localized time. Default is 'duration_hm'
 * @returns {string} The localized duration string
 */
const getLocalizedDuration = (seconds: number, timeFormat = 'duration_hm') => {
    const duration =
        dateUtilsConverters.convertSecondsToHoursAndMinutesAndSeconds(seconds);
    return i18n.t(`common:time.${timeFormat}`, duration);
};

const getLocalizedDurationInMinutes = (
    seconds = 0,
    timeFormat = 'extended_min'
) => {
    const { minutes } = timeUtils.convertSecondsToMinutes(seconds);
    const roundedMinutes = generalUtils.roundToMaxDigitsAfterDecimal(minutes);
    return i18n.t(`common:time.${timeFormat}`, { minutes: roundedMinutes });
};

export default {
    getTotalDistanceText,
    formatDuration,
    formatUnitsAndWeight,
    formatNumInventory,
    formatNumStops,
    formatAddress,
    isLanguageJapanese,
    formatAddressInputsByLocale,
    getLocalizedDuration,
    isUSRegion,
    getLocalizedDurationInMinutes
};
