/* eslint-disable camelcase */
import * as XLSX from 'xlsx';
import { customCostMatrixConstants as terms } from '~/utils/custom-cost-matrix-utils';
import {
    CustomCostMatrixCalculationType,
    CustomCostMatrixTermType
} from '~/api/types';

const getTermsRowData = (commonTermData, customCostTerm, termData, xOfType) => {
    const { thresholds, weights, isProgressive } = termData;
    const rowData = {
        ...commonTermData,
        custom_cost_term: customCostTerm,
        threshold_attribute: thresholds.attribute,
        threshold_values: thresholds.values.join(','),
        weight_attribute: weights.attribute,
        weight_values: weights.values.join(','),
        calculation_type: isProgressive
            ? CustomCostMatrixCalculationType.PROGRESSIVE
            : CustomCostMatrixCalculationType.CUTOFF,
        firstOfType: false,
        lastOfType: false
    };

    const hasXOfTypeThreshold = Boolean(thresholds[xOfType]);
    const hasXOfTypeWeight = Boolean(weights[xOfType]);
    const hasXOfType = hasXOfTypeThreshold || hasXOfTypeWeight;

    if (hasXOfType) {
        rowData[xOfType] = true;
        if (hasXOfTypeThreshold) {
            rowData.threshold_values = thresholds[xOfType].join(',');
        }
        if (hasXOfTypeWeight) {
            rowData.weight_values = weights[xOfType].join(',');
        }
    }

    return { hasXOfType, rowData };
};

const getVariableCostsRowData = (commonRowData, customCostTerm, termData) => {
    const allRows = [];
    const { rowData } = getTermsRowData(
        commonRowData,
        customCostTerm,
        termData
    );
    const { hasXOfType: hasFirstOfType, rowData: firstOfTypeRowData } =
        getTermsRowData(commonRowData, customCostTerm, termData, 'firstOfType');
    const { hasXOfType: hasLastOfType, rowData: lastOfTypeRowData } =
        getTermsRowData(commonRowData, customCostTerm, termData, 'lastOfType');

    allRows.push(rowData);
    if (hasFirstOfType) allRows.push(firstOfTypeRowData);
    if (hasLastOfType) allRows.push(lastOfTypeRowData);

    return allRows;
};

const transformEntries = (entries, zones) => {
    return entries.reduce((all, entry) => {
        const {
            start_zone_id,
            end_zone_id,
            custom_cost_terms: { constantTerm, stopTerms, tripTerms }
        } = entry;

        const commonRowData = {
            start_zone_name:
                zones[start_zone_id]?.zoneName || terms.zone.NOZONE,
            end_zone_name: zones[end_zone_id]?.zoneName || terms.zone.NOZONE
        };

        if (constantTerm) {
            all.push({
                ...commonRowData,
                custom_cost_term: CustomCostMatrixTermType.CONSTANT,
                value: constantTerm.value
            });
        }

        if (tripTerms) {
            tripTerms.forEach((term) => {
                const rows = getVariableCostsRowData(
                    commonRowData,
                    CustomCostMatrixTermType.TRIP,
                    term
                );
                all.push(...rows);
            });
        }

        if (stopTerms) {
            stopTerms.forEach((term) => {
                const rows = getVariableCostsRowData(
                    commonRowData,
                    CustomCostMatrixTermType.STOP,
                    term
                );
                all.push(...rows);
            });
        }

        return all;
    }, []);
};

const transformMatrixMetaData = (matrixMetaData) => {
    const customCostsMeta = Object.keys(matrixMetaData).map((key) => {
        return {
            meta_data_property: key,
            meta_data_value: matrixMetaData[key]
        };
    });
    return [...customCostsMeta];
};

const convertToSpreadsheet = (matrixData, zonesData, taskServiceData) => {
    const { entries, name } = matrixData;
    const { service_name, service_cost } = taskServiceData;

    const customCostMatrix = transformEntries(entries, zonesData);

    const zones = Object.values(zonesData).map((zone) => {
        const { zoneName: zone_name } = zone;
        return { zone_name };
    });

    const metaData = transformMatrixMetaData({
        name,
        service_name,
        service_cost
    });

    const zoneToZoneCosts = customCostMatrix.filter((item) => {
        return item.custom_cost_term === CustomCostMatrixTermType.CONSTANT;
    });

    const variableCosts = customCostMatrix.filter((item) => {
        return item.custom_cost_term !== CustomCostMatrixTermType.CONSTANT;
    });

    const wb = XLSX.utils.book_new();
    const zonesSheet = XLSX.utils.json_to_sheet(zones);
    const metaDataSheet = XLSX.utils.json_to_sheet(metaData);
    const zoneToZoneCostsSheet = XLSX.utils.json_to_sheet(zoneToZoneCosts);
    const variableCostsSheet = XLSX.utils.json_to_sheet(variableCosts);

    XLSX.utils.book_append_sheet(wb, zonesSheet, terms.sheetName.ZONES);
    XLSX.utils.book_append_sheet(wb, metaDataSheet, terms.sheetName.METADATA);
    XLSX.utils.book_append_sheet(
        wb,
        zoneToZoneCostsSheet,
        terms.sheetName.ZONETOZONECOSTS
    );
    XLSX.utils.book_append_sheet(
        wb,
        variableCostsSheet,
        terms.sheetName.VARIABLECOSTS
    );
    return wb;
};

export default convertToSpreadsheet;
