import React from 'react';
import classNames from 'classnames';
import { Icon, TextOverflowTooltip } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';
import './RouteCardLabels.scss';

const maxNumOfLabels = 3;

interface RouteCardLabelsProps {
    labels?: string[];
    icon?: IconName;
    iconColor?: string;
    className?: string;
    'data-testid'?: string;
}

function RouteCardLabels({
    labels = [],
    icon,
    iconColor = 'galaxy-500',
    className,
    ...extra
}: RouteCardLabelsProps) {
    function getIcon() {
        if (!icon || !icon.length) return null;
        return (
            <Icon
                className="label__icon"
                icon={icon}
                color={iconColor}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-icon`
                        : 'routecard-labels-icon'
                }
            />
        );
    }

    function getLabels() {
        const overMax = labels.length > maxNumOfLabels;
        return (
            <ul className="routecard-labels-list _d-flex _ai-center _jc-flex-start _fw-wrap">
                {labels.reduce((all, item, idx) => {
                    if (overMax && idx > maxNumOfLabels) return all;
                    const displayedMaxLabels =
                        overMax && idx === maxNumOfLabels;
                    const labelItem = displayedMaxLabels
                        ? `${labels.length - maxNumOfLabels}+`
                        : item;
                    const labelTestId = displayedMaxLabels ? 'over' : 'label';
                    all.push(
                        <TextOverflowTooltip
                            placement="top"
                            content={labelItem}
                            key={labelItem}
                        >
                            <li
                                data-testid={
                                    extra['data-testid']
                                        ? `${extra['data-testid']}-${labelTestId}`
                                        : `routecard-labels-${labelTestId}`
                                }
                            >
                                <span className="item _to-ellipsis">
                                    {labelItem}
                                </span>
                            </li>
                        </TextOverflowTooltip>
                    );
                    return all;
                }, [] as React.ReactNode[])}
            </ul>
        );
    }

    function getClassName() {
        const defaultClassName = 'routecard-labels _d-flex';
        return classNames(defaultClassName, className);
    }

    if (!labels || !labels.length) return null;
    return (
        <div
            className={getClassName()}
            data-testid={extra['data-testid'] || 'routecard-labels'}
        >
            {getIcon()}
            {getLabels()}
        </div>
    );
}

RouteCardLabels.maxNumOfLabels = maxNumOfLabels;

export default RouteCardLabels;
