import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const currentPageSlice = createSlice({
    name: 'currentPage',
    initialState: '',
    reducers: {
        setCurrentPage: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return '';
        });
    }
});

export const { setCurrentPage } = currentPageSlice.actions;

export const selectCurrentPage = (state) => state.currentPage;

export default currentPageSlice.reducer;
