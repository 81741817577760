import React from 'react';
import { BreakDuration } from '~/api/types';
import TextInput from '~/components/OptimizationConfigurationPage/components/OptimConfigTextInput';
import Text from '~/ui/components/Text';
import driverBreakUtils from '~/utils/driver-break-utils';
import { useTranslation } from 'react-i18next';

import './BreakDurationContent.scss';

type BreakDurationContentProps = {
    title: string;
    duration: BreakDuration;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const BreakDurationContent = ({
    title,
    duration,
    handleChange
}: BreakDurationContentProps) => {
    const { t } = useTranslation('driverBreakModal');

    const { hrs, min } = duration;
    return (
        <div
            className="break-duration-content"
            data-testid="break-duration-content"
        >
            <Text className="_text-3" color="galaxy-800" variant="12-normal">
                {title}
            </Text>
            <TextInput
                type="number"
                unitText={t('hrs')}
                name="hrs"
                isErrorState={!driverBreakUtils.isValidHours(hrs)}
                value={hrs}
                onChange={handleChange}
            />
            <TextInput
                type="number"
                unitText={t('mins')}
                name="min"
                isErrorState={!driverBreakUtils.isValidMinutes(min)}
                value={min}
                onChange={handleChange}
            />
        </div>
    );
};
