import { useMemo, useState } from 'react';
import { useGetEquipmentById } from '~/hooks/useGetEquipmentById';

interface UseEquipmentDetailsPopup {
    /**
     * The equipment ID
     */
    equipmentId: string;
}

export const useEquipmentDetailsPopup = ({
    equipmentId
}: UseEquipmentDetailsPopup) => {
    const [showEquipmentPopup, setShowEquipmentPopup] = useState(false);

    const onSuccess = () => {
        setShowEquipmentPopup(true);
    };

    const { data: equipmentData } = useGetEquipmentById({
        equipmentId,
        reactQueryOptions: {
            onSuccess
        }
    });

    const additionalData = useMemo(() => {
        if (equipmentData) {
            const { additionalData: existingLabels } = equipmentData;
            const labels = Object.values(existingLabels).map((value) => value);
            return labels;
        }
    }, [equipmentData]);

    return {
        equipmentData: showEquipmentPopup ? equipmentData : undefined,
        additionalData
    };
};
