import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { resetOnLogout } from '~/reducers/common-actions';
import constants from '~/utils/constants';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

const getDefaultState = () => ({
    isSchedulerRunning: false,
    latestCepResult: null,
    ignoreCepResultUntilMilliseconds: 0
});

const testIsStoppingScheduler = (
    ignoreCepResultUntilMilliseconds,
    now = DateTime.local().toMillis()
) => {
    return ignoreCepResultUntilMilliseconds > now;
};

export const getMillisecondsToIgnoreCepResults = () => {
    return DateTime.local()
        .plus({
            milliseconds: constants.timings.IGNORE_CEP_RESULT
        })
        .toMillis();
};

export const schedulerProgressSlice = createSlice({
    name: 'schedulerProgress',
    initialState: getDefaultState(),
    reducers: {
        setCepResult: (state, action) => {
            const { ignoreCepResultUntilMilliseconds } = state;

            if (testIsStoppingScheduler(ignoreCepResultUntilMilliseconds)) {
                state.latestCepResult = null;
                state.isSchedulerRunning = false;

                return;
            }

            const { payload: latestCepResult } = action;
            const {
                data: { stage }
            } = latestCepResult;
            const {
                schedulerKeys: { schedulerProgressError, schedulerProgressDone }
            } = constants;

            Object.assign(state, {
                ...getDefaultState(),
                latestCepResult,
                isSchedulerRunning:
                    Boolean(stage) &&
                    ![schedulerProgressDone, schedulerProgressError].includes(
                        stage
                    )
            });
        },
        setIsStoppingScheduler: (state) => {
            Object.assign(state, {
                ...getDefaultState(),
                ignoreCepResultUntilMilliseconds:
                    getMillisecondsToIgnoreCepResults()
            });
        },
        setIsSchedulerRunning: (state, action) => {
            const isSchedulerRunning = action.payload === true;
            const { ignoreCepResultUntilMilliseconds } = state;

            Object.assign(state, {
                ...getDefaultState(),
                isSchedulerRunning,
                ignoreCepResultUntilMilliseconds: isSchedulerRunning
                    ? 0
                    : ignoreCepResultUntilMilliseconds
            });
        },
        resetSchedulerProgress: () => {
            return getDefaultState();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return getDefaultState();
        });
    }
});

export const {
    resetSchedulerProgress,
    setCepResult,
    setIsSchedulerRunning,
    setIsStoppingScheduler
} = schedulerProgressSlice.actions;

export const selectIsLatestCepResultForSelectedDate = (state) => {
    const selectedDate = selectDateOnly(state);
    const {
        schedulerProgress: { latestCepResult }
    } = state;
    const { data: { routeDate: cepResultRouteDate } = {} } =
        latestCepResult || {};

    return Boolean(latestCepResult) && cepResultRouteDate === selectedDate;
};

export const selectIsLatestCepResultForMainClient = (state) => {
    const mainClient = selectMainClient(state);
    const {
        schedulerProgress: { latestCepResult }
    } = state;
    const { clientId: cepClientId } = latestCepResult || {};

    return Boolean(latestCepResult) && cepClientId === mainClient?.id;
};

export const selectIsLatestCepResultForSelectedDateAndMainClient = (state) => {
    return (
        selectIsLatestCepResultForMainClient(state) &&
        selectIsLatestCepResultForSelectedDate(state)
    );
};

export const selectIsSchedulerRunning = (state) => {
    const {
        ignoreCepResultUntilMilliseconds,
        isSchedulerRunning,
        latestCepResult
    } = state.schedulerProgress;

    return (
        isSchedulerRunning &&
        !testIsStoppingScheduler(ignoreCepResultUntilMilliseconds) &&
        (!latestCepResult ||
            selectIsLatestCepResultForSelectedDateAndMainClient(state))
    );
};

export const selectIsStoppingScheduler = (state) =>
    testIsStoppingScheduler(
        state.schedulerProgress.ignoreCepResultUntilMilliseconds
    );

export const selectLatestCepResult = (state) =>
    state.schedulerProgress.latestCepResult;

export default schedulerProgressSlice.reducer;
