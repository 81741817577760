import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    circleRadius: null,
    geofencePolygon: null
};

export const geofenceSlice = createSlice({
    name: 'geofence',
    initialState,
    reducers: {
        setCircleRadius: (state, action) => {
            state.circleRadius = action.payload;
        },
        setGeofencePolygon: (state, action) => {
            state.geofencePolygon = action.payload;
        },
        clearGeofenceState: () => {
            return initialState;
        }
    }
});

export const { setCircleRadius, setGeofencePolygon, clearGeofenceState } =
    geofenceSlice.actions;

export const selectCircleRadius = (state) => state.geofence.circleRadius;
export const selectGeofencePolygon = (state) => state.geofence.geofencePolygon;

export default geofenceSlice.reducer;
