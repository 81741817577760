import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { VehicleApi } from '~/api/VehicleApi';
import { setVehicles } from '~/reducers/vehiclesSlice';
import { ApiVehicle } from '~/api/types';

type HookGetVehicles = {
    vehiclesData: Record<string, ApiVehicle>;
    getVehicleById: (vehicleId: string) => ApiVehicle;
};

const REACT_QUERY_KEY = 'vehicles';

export const useGetVehicles = (): HookGetVehicles => {
    const dispatch = useDispatch();

    const { data: vehiclesList } = useQuery<ApiVehicle[]>(
        REACT_QUERY_KEY,
        async () => {
            const dataResponse = await VehicleApi.get();
            const {
                data: { data: vehicles }
            } = dataResponse;

            return vehicles;
        },
        {
            onSuccess: (vehiclesResponse) => {
                const vehiclesById = vehiclesResponse.reduce((acc, vehicle) => {
                    if (vehicle?.id) acc[vehicle.id] = vehicle;
                    return acc;
                }, {} as Record<string, ApiVehicle>);
                dispatch(setVehicles(vehiclesById));
            },
            onError: (e) => {
                dispatch(setVehicles({}));
                console.error(e);
            }
        }
    );

    const vehiclesData = useMemo(() => {
        const vehiclesById = (vehiclesList || []).reduce((acc, vehicle) => {
            if (vehicle?.id) acc[vehicle.id] = vehicle;
            return acc;
        }, {} as Record<string, ApiVehicle>);
        return vehiclesById;
    }, [vehiclesList]);

    const getVehicleById = (vehicleId: string) => {
        return (vehicleId && vehiclesData[vehicleId]) || {};
    };

    return { vehiclesData, getVehicleById };
};
