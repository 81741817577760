import React, { useRef, useState } from 'react';
import { Icon, IconButton, useClickOutside } from '~/ui';
import classNames from 'classnames';
import './FuzzyTextSearch.scss';

function FuzzyTextSearch({
    placeholder,
    handleOnChange,
    children,
    className = ''
}) {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const searchInputClassNames = classNames(
        'fuzzytextsearch-input',
        '_d-grid',
        '_ai-center',
        { fuzzytextsearch_focus: isFocused }
    );
    const searchResultsClassNames = classNames(
        'fuzzytextsearch-results',
        { '_fd-column': children?.length && isFocused },
        { '_d-none': !isFocused }
    );
    const ref = useRef();

    function _handleOnBlur() {
        setIsFocused(false);
    }

    function _handleOnFocus() {
        setIsFocused(true);
    }

    function _handleOnChange({ target: { value } }) {
        setInputValue(value);
        handleOnChange(value);
    }

    function _clearSearch() {
        setInputValue('');
        const inputElement = document.getElementById('fuzzytextsearch-input');
        inputElement.focus();
        handleOnChange('');
    }

    useClickOutside(ref, _handleOnBlur);

    return (
        <div
            className={`${className} fuzzytextsearch`}
            ref={ref}
            data-testid="fuzzysearchcontainer"
        >
            <div
                data-testid="fuzzytextsearch"
                className={searchInputClassNames}
            >
                <Icon
                    data-testid="search-icon"
                    icon="search"
                    color="galaxy-800"
                    size="m"
                />
                <input
                    id="fuzzytextsearch-input"
                    data-testid="fuzzytextsearch-input"
                    type="text"
                    value={inputValue}
                    placeholder={placeholder}
                    onFocus={_handleOnFocus}
                    onChange={_handleOnChange}
                />
                <IconButton
                    data-testid="cancel-icon"
                    visible={Boolean(inputValue)}
                    icon="cancel"
                    iconSize="l"
                    onClick={_clearSearch}
                />
            </div>
            <div className={searchResultsClassNames}>{children}</div>
        </div>
    );
}

export default FuzzyTextSearch;
