import axios from 'axios';
import constants from '~/utils/constants';
import { ApiTask, AxiosApiResponse } from './types';

interface ChangeRouteDriverParams {
    routeId: string;
    driverId: string;
    date: string;
    type: string;
}

export interface ChangeRouteDriverResponseData {
    schedulerTaskId: string;
}

type ChangeRouteDriverResponse =
    AxiosApiResponse<ChangeRouteDriverResponseData>;

interface ShiftRouteParams {
    driverId1: string;
    driverId2: string;
    routeId: string;
    routeDate: string;
}

interface AppendRouteParams {
    routeId: string;
    routeDate: string;
    driverId: string;
    vehicleId: string;
    type: string;
}

export type AppendRouteResponseData = {
    data: {
        routeId: string;
        driverId: string;
        schedulerTaskId: string;
        tasks: string[];
        vehicleId: string;
    };
};

type AppendRouteResponse = AxiosApiResponse<AppendRouteResponseData>;

type ShiftRouteResponseData1 = ApiTask;

interface ShiftRouteResponseData2 {
    newTask?: ApiTask;
    schedulerTaskIds?: {
        clientId?: string;
        schedulerTaskId?: string;
    }[];
}

type ShiftRouteResponseData = ShiftRouteResponseData1 | ShiftRouteResponseData2;

type ShiftRouteResponse = AxiosApiResponse<ShiftRouteResponseData>;

interface ReassignTasksParams {
    taskIds: string[];
    driverId: string;
    routeId?: string;
    clientId?: string;
}

interface ReassignTasksResponseData {
    schedulerTaskIds?: {
        clientId?: string;
        schedulerTaskId?: string;
    }[];
    taskIds?: string[];
}

type ReassignTasksResponse = AxiosApiResponse<ReassignTasksResponseData>;

/**
 * @category API
 */
export class MultiPlanEditApi {
    static undoScheduleAction({
        clientId,
        schedulerId
    }: {
        clientId: string;
        schedulerId: string;
    }): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing client ID');
        }

        if (!schedulerId) {
            return Promise.reject('Missing scheduler ID');
        }

        return axios.post(
            '/schedules/undo',
            {
                schedulerId
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    }

    static shiftRoute({
        driverId1,
        driverId2,
        routeId,
        routeDate
    }: ShiftRouteParams): Promise<ShiftRouteResponse> {
        if (!routeDate) {
            return Promise.reject('Missing route date');
        }
        if (!driverId1) {
            return Promise.reject('Missing source driver ID');
        }
        if (!driverId2) {
            return Promise.reject('Missing target driver ID');
        }
        if (!routeId) {
            return Promise.reject('Missing source route ID');
        }

        return axios.post(
            '/schedules/evaluate?action=change_driver_and_vehicle&accept',
            {
                driverId1,
                driverId2,
                routeId,
                date: routeDate,
                type: constants.productTypes.SCHEDULER
            }
        );
    }

    static appendRoute({
        routeDate,
        type,
        routeId,
        driverId,
        vehicleId,
        ...rest
    }: AppendRouteParams): Promise<AppendRouteResponse> {
        if (!routeId) {
            return Promise.reject('Missing route ID');
        }
        if (!type) {
            return Promise.reject('Missing type');
        }
        if (!driverId) {
            return Promise.reject('Missing target driver ID');
        }
        if (!vehicleId) {
            return Promise.reject('Missing target vehicle ID');
        }
        if (!routeDate) {
            return Promise.reject('Missing route date');
        }

        return axios.post('/schedules/evaluate?action=append&accept', {
            date: routeDate,
            type,
            routeId,
            driverId,
            vehicleId,
            ...rest
        });
    }

    static changeRouteDriver({
        routeId,
        driverId,
        date,
        type
    }: ChangeRouteDriverParams): Promise<ChangeRouteDriverResponse> {
        if (!routeId) {
            return Promise.reject('Missing route id');
        }

        if (!driverId) {
            return Promise.reject('Missing driver id');
        }

        if (!date) {
            return Promise.reject('Missing date');
        }

        if (!type) {
            return Promise.reject('Missing type');
        }

        return axios.post('/schedules/evaluate?action=change_driver&accept', {
            routeId,
            driverId,
            date,
            type
        });
    }

    static reassignTasks({
        taskIds,
        driverId,
        routeId,
        clientId
    }: ReassignTasksParams): Promise<ReassignTasksResponse> {
        if (!taskIds) {
            return Promise.reject('Missing task IDs');
        }
        if (!Array.isArray(taskIds)) {
            return Promise.reject('task IDs must be array');
        }
        if (!taskIds.length) {
            return Promise.reject('task IDs array cannot be empty');
        }
        if (!driverId) {
            return Promise.reject('Missing target driver ID');
        }
        return axios.patch('/tasks?action=move_route', {
            taskIds,
            driverId,
            routeId,
            clientId
        });
    }
}
