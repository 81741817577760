import {
    KeyboardSensor,
    PointerSensor,
    SensorDescriptor,
    SensorOptions,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

// to allow custom events on children https://github.com/clauderic/dnd-kit/issues/591
const DRAG_ACTIVATION_DELAY = 5;

interface UseDragAndDropSetup {
    sensors: SensorDescriptor<SensorOptions>[];
}

export const useDragAndDropSetup = (): UseDragAndDropSetup => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: DRAG_ACTIVATION_DELAY
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    return {
        sensors
    };
};
