import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { Icon, IconButton, TextInput } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { TextInputVariant } from '~/ui/components/TextInput';

import { EditableTextProps, EditableTextValidation } from './types';
import { EditableTextActions } from './EditableTextActions';
import './EditableText.scss';

const ROOT_CLASS_NAME = 'editabletext';

function EditableText({
    mainContent,
    handleSubmit,
    handleValidation,
    handleCancel = () => {},
    index = 0,
    isEditable = false,
    isEditMode = false,
    showEditIcon = false,
    formatter = (value) => value,
    'data-testid': dataTestId,
    className,
    helperText = '',
    showPencilEditIcon = false,
    isErrorText = false
}: EditableTextProps) {
    const [isTextInput, setIsTextInput] = useState(isEditMode);
    const [error, setError] = useState<EditableTextValidation>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);
    const [isHovered, setIsHovered] = useState(false);

    const shouldShowPencilButtonOnHover =
        isEditable && showPencilEditIcon && isHovered;

    // value of 0 is not considered empty
    const isMainContentEmpty = !mainContent && mainContent !== 0;
    const conditionalClasses = {
        '_jc-flex-end': isMainContentEmpty,
        '_jc-space-between': !isMainContentEmpty,
        'editable-text--enabled': isEditable,
        [`${ROOT_CLASS_NAME}--error`]: isErrorText
    };
    const elemClassName = classNames('_d-flex', className, conditionalClasses);
    const iconClassName = classNames(`${ROOT_CLASS_NAME}__icon`, {
        [`${className}__icon`]: className
    });
    const editModeClassName = classNames(
        `${ROOT_CLASS_NAME}__edit-mode`,
        '_fd-column',
        'edit-mode'
    );

    const onClickCancel = useCallback(() => {
        handleCancel();
        setIsTextInput(false);
        setError(null);
    }, [handleCancel]);

    const onClickCheck = useCallback(() => {
        // no change
        if (textInputRef.current?.value === mainContent) return onClickCancel();

        const { value } = textInputRef.current || { value: '' };

        // client-side validation
        const errorMsg = handleValidation?.(value);
        if (errorMsg) return setError(errorMsg);

        // client-side validation passed
        handleSubmit?.({ index, value });
        setIsTextInput(false);
    }, [onClickCancel, handleSubmit, handleValidation, index, mainContent]);

    const onClickDetail = useCallback(() => {
        if (isEditable) setIsTextInput((state) => !state);
    }, [isEditable]);

    const rootDataTestId = dataTestId || ROOT_CLASS_NAME;
    const editModeDataTestId = `${rootDataTestId}__edit-mode`;
    const actionsDataTestId = `${rootDataTestId}__actions`;
    const iconDataTestId = `${rootDataTestId}__icon`;
    const editableContainerDataTestId = `${rootDataTestId}__container`;
    const editableContentDataTestId = `${rootDataTestId}__content`;
    const pencilIconDataTestId = `${rootDataTestId}__pencil`;
    const inputDataTestId = `${ROOT_CLASS_NAME}-input`;

    const validTabIndex = typeof index === 'number' ? index : undefined;

    return (
        <>
            {isTextInput && (
                <div
                    className={editModeClassName}
                    data-testid={editModeDataTestId}
                >
                    <TextInput
                        ref={textInputRef}
                        value={mainContent}
                        width="fullWidth"
                        variant={
                            error
                                ? TextInputVariant.ERROR
                                : TextInputVariant.EDIT
                        }
                        data-testid={inputDataTestId}
                    />
                    <EditableTextActions
                        onClickCancel={onClickCancel}
                        onClickCheck={onClickCheck}
                        error={error}
                        data-testid={actionsDataTestId}
                    />
                </div>
            )}
            {!isTextInput && (
                <div
                    className={`${ROOT_CLASS_NAME}__container _d-flex _jc-space-between`}
                    data-testid={editableContainerDataTestId}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <div
                        className={classNames(`${ROOT_CLASS_NAME}`, {
                            [`${ROOT_CLASS_NAME}--with-helper`]: helperText
                        })}
                        data-testid={editableContentDataTestId}
                    >
                        <span
                            className={elemClassName}
                            onClick={onClickDetail}
                            onKeyDown={onClickDetail}
                            role="button"
                            tabIndex={validTabIndex}
                            data-testid={rootDataTestId}
                        >
                            {formatter(mainContent)}
                            {showEditIcon && (
                                <Icon
                                    data-testid={iconDataTestId}
                                    size={IconSizes.M}
                                    className={iconClassName}
                                    icon="edit"
                                />
                            )}
                        </span>
                        {helperText && (
                            <div
                                className={classNames(
                                    `${ROOT_CLASS_NAME}__helper`,
                                    {
                                        [`${ROOT_CLASS_NAME}__helper--error`]:
                                            isErrorText
                                    },
                                    '_d-flex',
                                    '_ai-center',
                                    '_pt-0'
                                )}
                                data-testid={`${rootDataTestId}__helper-text`}
                            >
                                {helperText}
                            </div>
                        )}
                    </div>
                    {shouldShowPencilButtonOnHover && (
                        <IconButton
                            icon="editPencilThin"
                            iconSize="xs"
                            onClick={onClickDetail}
                            className={`${ROOT_CLASS_NAME}__button__pencil _d-flex _ai-center _jc-center`}
                            data-testid={pencilIconDataTestId}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default EditableText;
