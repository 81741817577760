import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SinglePlanEditApi from '~/api/SinglePlanEditApi';
import { idUtils } from '~/utils/id-utils';

import { selectDateOnly } from '~/reducers/selectedDateSlice';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';
import { useMapUtils } from './useMapUtils';

export const useUnassignStops = () => {
    const dispatch = useDispatch();
    const routeDate = useSelector(selectDateOnly);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const isRequestInProgress = useRef(false);
    const { t } = useTranslation('translation');
    const { isPlanRouteMode } = useMapUtils();

    const { isUnassignStopsAllowed = false, taskIds = [] } = useMemo(() => {
        if (!isPlanRouteMode) return {};

        const analysis = idUtils.analyzeCombinedIds(selectedMapStops);

        return {
            isUnassignStopsAllowed:
                !isRequestInProgress.current &&
                analysis.areAllPlanned &&
                analysis.areAllFromOneClientRoute,
            taskIds: analysis.plannedTaskIds
        };
    }, [isPlanRouteMode, selectedMapStops, isRequestInProgress]);

    const unassignStops = useCallback(async () => {
        if (!isUnassignStopsAllowed) {
            console.warn('Unassign stop(s) not allowed');
            return;
        }

        const [firstSelectedMapStop] = selectedMapStops;
        const count = firstSelectedMapStop ? selectedMapStops.length : '';
        const processIndicatorState = {
            message: t('UnassignStop', {
                count
            }),
            type: constants.processIndicator.UNASSIGN_STOP,
            payload: count,
            inProgress: true,
            error: false,
            position: 'center'
        };
        try {
            isRequestInProgress.current = true;
            dispatch(addProcessIndicator(processIndicatorState));
            const [clientId, routeId] =
                idUtils.splitCombinedId(firstSelectedMapStop);
            await SinglePlanEditApi.unassignTasks(clientId, {
                date: routeDate,
                routeId,
                taskIds
            });
            dispatch(resetSelectedMapStops());
            isRequestInProgress.current = false;
        } catch (e) {
            console.error(e);
            isRequestInProgress.current = false;
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    error: true
                })
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isUnassignStopsAllowed, selectedMapStops, routeDate, taskIds]);

    return {
        isUnassignStopsAllowed,
        taskIds,
        unassignStops
    };
};
