import { isArray } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import constants from '~/utils/constants';

const {
    mapOptionSettings: { DEFAULT_BOUNDS }
} = constants;

export const lastPlanMapBoundsSlice = createSlice({
    name: 'lastPlanMapBounds',
    initialState: DEFAULT_BOUNDS,
    reducers: {
        setLastPlanMapBounds: (state, action) => {
            const isValid =
                isArray(action.payload) && action.payload.length === 4;
            if (!isValid) {
                return state;
            }
            return action.payload;
        },
        resetLastPlanMapBounds: () => {
            return DEFAULT_BOUNDS;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return DEFAULT_BOUNDS;
        });
    }
});

export const { setLastPlanMapBounds, resetLastPlanMapBounds } =
    lastPlanMapBoundsSlice.actions;
export const selectLastPlanMapBounds = (state) => state.lastPlanMapBounds;
export default lastPlanMapBoundsSlice.reducer;
