import React from 'react';

function HuddledMarkers({ children }) {
    const maxNumOfHuddledMarkers = 3;

    function calculateHuddleStyle(index) {
        const offset = 0.5;
        const zIndex = maxNumOfHuddledMarkers - index;
        return {
            top: `${-offset - index * offset}rem`,
            left: `${-offset - index * offset}rem`,
            zIndex
        };
    }

    function createHuddledMarkers() {
        return children.slice(0, maxNumOfHuddledMarkers).map((child, index) => (
            <div
                className="huddledmarker _p-absolute"
                key={child.key}
                style={calculateHuddleStyle(index)}
            >
                {child}
            </div>
        ));
    }

    return <>{createHuddledMarkers()}</>;
}

export default HuddledMarkers;
