import '~/ui/assets/fonts/Roboto/index.css';

const fonts = {
    body: 'Roboto'
};

const fontStyles = {
    normal: 'normal'
};

const fontWeights = {
    light: '300',
    normal: 'normal',
    medium: '500',
    bold: '700'
};

const variants = {
    '12-light': {
        fontFamily: fonts.body,
        fontSize: '1.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.light
    },
    '10-normal': {
        fontFamily: fonts.body,
        fontSize: '1rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '11-normal': {
        fontFamily: fonts.body,
        fontSize: '1.1rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '12-normal': {
        fontFamily: fonts.body,
        fontSize: '1.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '14-normal': {
        fontFamily: fonts.body,
        fontSize: '1.4rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '16-normal': {
        fontFamily: fonts.body,
        fontSize: '1.6rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '24-normal': {
        fontFamily: fonts.body,
        fontSize: '2.4rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '32-normal': {
        fontFamily: fonts.body,
        fontSize: '3.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.normal
    },
    '8-medium': {
        fontFamily: fonts.body,
        fontSize: '0.8rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '10-medium': {
        fontFamily: fonts.body,
        fontSize: '1rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '11-medium': {
        fontFamily: fonts.body,
        fontSize: '1.1rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '12-medium': {
        fontFamily: fonts.body,
        fontSize: '1.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '14-medium': {
        fontFamily: fonts.body,
        fontSize: '1.4rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '16-medium': {
        fontFamily: fonts.body,
        fontSize: '1.6rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '18-medium': {
        fontFamily: fonts.body,
        fontSize: '1.8rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '24-medium': {
        fontFamily: fonts.body,
        fontSize: '2.4rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium,
        lineHeight: '1.25'
    },
    '32-medium': {
        fontFamily: fonts.body,
        fontSize: '3.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '36-medium': {
        fontFamily: fonts.body,
        fontSize: '3.6rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.medium
    },
    '10-bold': {
        fontFamily: fonts.body,
        fontSize: '1rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.bold
    },
    '12-bold': {
        fontFamily: fonts.body,
        fontSize: '1.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.bold
    },
    '32-bold': {
        fontFamily: fonts.body,
        fontSize: '3.2rem',
        fontStyle: fontStyles.normal,
        fontWeight: fontWeights.bold
    }
};

export default {
    fonts,
    fontWeights,
    variants
};
