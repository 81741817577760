import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';

export const testIsTaskUploadInitiated = ({
    isTaskUploadInProgress,
    progressIndicatorType
}) => {
    return (
        progressIndicatorType === constants.processIndicator.TASK_UPLOAD &&
        isTaskUploadInProgress
    );
};

/**
 * Implements checking to confirm if the user initiated a task upload
 *
 * This is currently a workaround pending when the backend sends enough
 * information that helps us to identify whom the upload events belong to.
 *
 * TODO: https://wisesys.atlassian.net/browse/MCW-1164
 *
 * @category Hooks
 * @module useIsTaskUploadInitiated
 * @returns @returns {{isTaskUploadInitiated: boolean, setIsTaskUploadInitiated: function}}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useIsTaskUploadInitiated } from '~/hooks';
 *
 * // get the use state boolean and the set function
 * const { isTaskUploadInitiated, setIsTaskUploadInitiated } = useIsTaskUploadInitiated();
 *
 * // update state manually
 * const { setIsTaskUploadInitiated } = useIsTaskUploadInitiated();
 * setIsTaskUploadInitiated();
 */
export const useIsTaskUploadInitiated = () => {
    const { inProgress: isTaskUploadInProgress, type: progressIndicatorType } =
        useSelector(selectProcessIndicator);
    const [isTaskUploadInitiated, setIsTaskUploadInitiated] = useState(
        testIsTaskUploadInitiated({
            isTaskUploadInProgress,
            progressIndicatorType
        })
    );

    useEffect(() => {
        const _isTaskUploadInitiated = testIsTaskUploadInitiated({
            isTaskUploadInProgress,
            progressIndicatorType
        });

        if (
            _isTaskUploadInitiated &&
            _isTaskUploadInitiated !== isTaskUploadInitiated
        )
            setIsTaskUploadInitiated(_isTaskUploadInitiated);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [progressIndicatorType, isTaskUploadInProgress]);

    return {
        isTaskUploadInitiated,
        setIsTaskUploadInitiated
    };
};
