import { ApiDriver, ApiTask, SuggestedDriver } from '~/api/types';
import { Task, OnDemandDispatchStop } from '~/data-classes';

export interface OnDemandDispatchDriver extends ApiDriver {
    clientDriverId?: string;
    isLiveDriver?: boolean;
    isDispatchedDriver?: boolean;
    isCompletedDriver?: boolean;
}

export interface OnDemandDispatchSuggestions {
    driver: OnDemandDispatchDriver;
    tasks: Record<string, ApiTask>;
    suggestions: SuggestedDriver[];
}

export const taskDataFactory = {
    makeTask(taskData: ApiTask): Task {
        return new Task(taskData);
    },
    makeOnDemandDispatchStop(taskData: ApiTask): OnDemandDispatchStop {
        return new OnDemandDispatchStop(taskData);
    },
    makeOnDemandDispatchSuggestion(
        driverData: ApiDriver,
        tasksData: ApiTask[],
        suggestionsData: SuggestedDriver[] = []
    ): OnDemandDispatchSuggestions {
        // TODO: convert this into a data class
        const suggestion = {
            driver: { ...driverData },
            tasks: tasksData.reduce((tasks: Record<string, ApiTask>, task) => {
                tasks[task.id] = task;
                return tasks;
            }, {}),
            suggestions: [...suggestionsData]
        };

        return suggestion;
    }
};
