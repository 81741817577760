import React from 'react';
import classNames from 'classnames';
import { DrawerElement } from './types';

import './drawer.scss';

interface DrawerProps extends DrawerElement {
    /**
     * Identify the drawer as closed or open
     *
     * @todo
     * Drawers will no longer require this functionality.
     * Once route drawers are refactored, remove these props
     */
    isClosed?: boolean;

    /**
     * onMouseEnter Handler
     *
     * @todo
     * Drawers will no longer require this functionality.
     * Once route drawers are refactored, remove these props
     */
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;

    /**
     * onMouseLeave Handler
     *
     * @todo
     * Drawers will no longer require this functionality.
     * Once route drawers are refactored, remove these props
     */
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

function Drawer({
    className,
    isClosed = false,
    children,
    onMouseEnter,
    onMouseLeave,
    ...extra
}: DrawerProps) {
    const dataTestId = extra['data-testid'] || 'drawer';

    function getClassName(): string {
        const baseClass = 'drawer _d-flex _fd-column';
        const conditionalClasses = {
            drawer_closed: isClosed
        };
        return classNames(baseClass, conditionalClasses, className);
    }

    return (
        <div
            data-testid={dataTestId}
            className={getClassName()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </div>
    );
}

function DrawerHeader({ children, className, ...extra }: DrawerElement) {
    const dataTestId = extra['data-testid'] || 'drawerHeader';

    function getClassName(): string {
        const boxClassName = 'drawer-header';
        return classNames(boxClassName, className);
    }
    return (
        <div data-testid={dataTestId} className={getClassName()}>
            {children}
        </div>
    );
}

function DrawerBody({ children, className, ...extra }: DrawerElement) {
    const dataTestId = extra['data-testid'] || 'drawerBody';

    function getClassName(): string {
        const boxClassName = 'drawer-body';
        return classNames(boxClassName, className);
    }
    return (
        <div data-testid={dataTestId} className={getClassName()}>
            {children}
        </div>
    );
}

function DrawerFooter({ children, className, ...extra }: DrawerElement) {
    const dataTestId = extra['data-testid'] || 'drawerFooter';

    function getClassName(): string {
        const boxClassName = 'drawer-footer';
        return classNames(boxClassName, className);
    }
    return (
        <div data-testid={dataTestId} className={getClassName()}>
            {children}
        </div>
    );
}

function DrawerButtonGroup({ children, className, ...extra }: DrawerElement) {
    const dataTestId = extra['data-testid'] || 'drawerButtonGroup';

    function getClassName(): string {
        const boxClassName = 'drawer-buttongroup _d-flex _ai-center';
        return classNames(boxClassName, className);
    }
    return (
        <div data-testid={dataTestId} className={getClassName()}>
            {children}
        </div>
    );
}

export { Drawer, DrawerHeader, DrawerBody, DrawerFooter, DrawerButtonGroup };
