import { UniqueIdentifier } from '@dnd-kit/core';
import { useCallback, useMemo } from 'react';
import { LiveStop, PlanStop } from '~/data-classes';
import { useDriverTasks, useStopResequence, useMapUtils } from '~/hooks';
import { shouldInsertBefore } from '~/hooks/useDragAndDropState/utils';
import { StopDragAndDropTypes } from '~/components/MapPageDrawers/types';

interface UseDragToResequenceStopsProps {
    stops: PlanStop[];
}

type PersistFunction = (
    targetItemId: UniqueIdentifier,
    draggedItemIds: UniqueIdentifier[]
) => void;

type UseDragToResequenceStops = Record<string, PersistFunction>;

export const useDragToResequenceStops = ({
    stops
}: UseDragToResequenceStopsProps): UseDragToResequenceStops => {
    const dragAndDropAction = StopDragAndDropTypes.STOP_RESEQUENCE;
    const { resequenceStops } = useStopResequence();
    const { isPlanRouteMode } = useMapUtils();

    const { dragDriverTasksToResequence } = useDriverTasks();

    const dragPlanStopToResequence = useCallback(
        (resequenceTarget: PlanStop, stopsToResequence: PlanStop[]) => {
            const {
                stopNumber: targetStopNumber,
                clientRouteId: targetClientRouteId,
                routeId: targetRouteId
            } = resequenceTarget;
            const clientRouteTaskIds = stopsToResequence.map(
                (stop: PlanStop) => stop.clientRouteTaskId
            );

            const stopsToResequenceStopNumbers = stopsToResequence.map(
                (stop) => {
                    return stop.stopNumber;
                }
            );

            const toInsertBefore = shouldInsertBefore(
                targetStopNumber,
                stopsToResequenceStopNumbers
            );

            resequenceStops({
                targetClientRouteId,
                targetRouteId,
                targetStopNumber,
                selectedStopIds: clientRouteTaskIds,
                isInsertAfter: !toInsertBefore
            });
        },
        [resequenceStops]
    );

    const persistResequence = useCallback(
        (
            targetItemId: UniqueIdentifier,
            draggedItemIds: UniqueIdentifier[]
        ) => {
            if (!targetItemId || !draggedItemIds) {
                return;
            }

            const movedOverDragged = draggedItemIds.includes(targetItemId);
            if (movedOverDragged) {
                return;
            }

            const targetStop = stops.find(
                ({ id }) => id === targetItemId.toString().split('*')[0]
            );
            if (!targetStop) {
                return;
            }
            const selectedStops = draggedItemIds
                .map((draggedId: UniqueIdentifier) => {
                    return stops.find((stop) => {
                        const { id } = stop;
                        return id === draggedId;
                    });
                })
                .filter(Boolean);

            if (isPlanRouteMode) {
                dragPlanStopToResequence(
                    targetStop,
                    selectedStops as PlanStop[]
                );
                return;
            }

            dragDriverTasksToResequence(
                targetStop as unknown as LiveStop,
                selectedStops as unknown as LiveStop[],
                stops as unknown as LiveStop[]
            );
        },
        [
            dragDriverTasksToResequence,
            dragPlanStopToResequence,
            isPlanRouteMode,
            stops
        ]
    );

    return useMemo(
        () => ({
            [dragAndDropAction]: persistResequence
        }),
        [dragAndDropAction, persistResequence]
    );
};
