import { makeClusterPopup } from './makeClusterPopup';
import { makeDispatchedStopPopup } from './makeDispatchedStopPopup';
import { makeRoutePopup } from './makeRoutePopup';
import { makeStopPopup } from './makeStopPopup';

/**
 * Pop-up Maker Utilities
 *
 * @category Utils
 * @module utils/map/popupMaker
 */
export const popupMaker = {
    makeClusterPopup,
    makeDispatchedStopPopup,
    makeRoutePopup,
    makeStopPopup
};
