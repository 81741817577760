import React from 'react';

const RadioListItem = React.forwardRef(function RadioListItem(
    {
        id = '',
        name = '',
        value = '',
        selectedValue = '',
        handleChange,
        listItemClassName,
        children
    },
    ref
) {
    const isSelected = value === selectedValue;
    if (isSelected) {
        listItemClassName += ` ${listItemClassName}_selected`;
    }

    return (
        <label className={listItemClassName} htmlFor={id}>
            <input
                ref={ref}
                id={id}
                type="radio"
                name={name}
                value={value}
                onChange={handleChange}
                hidden
            />
            {children}
        </label>
    );
});

export default RadioListItem;
