import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutesApi } from '~/api/RoutesApi';
import { useToastContext } from '~/hooks';

export const useLockRoutes = () => {
    const { addToast } = useToastContext();
    const { t } = useTranslation('selectedCardsDrawer');

    const lockRoute = useCallback(
        async (routeId) => {
            try {
                await RoutesApi.closeRoutes([routeId]);
                addToast({
                    message: t('toast.routeClosedSuccessfully'),
                    variant: 'success',
                    isPersisted: false
                });
            } catch (e) {
                console.error('Failed to close the route');
                addToast({
                    message: t('toast.routeFailedToClose'),
                    variant: 'error',
                    isPersisted: false
                });
            }
        },
        [addToast, t]
    );

    const unlockRoute = useCallback(
        async (routeId) => {
            try {
                await RoutesApi.uncloseRoutes([routeId]);
                addToast({
                    message: t('toast.routeUnclosedSuccessfully'),
                    variant: 'success',
                    isPersisted: false
                });
            } catch (e) {
                console.error('Failed to unlock the route');
                addToast({
                    message: t('toast.routeFailedToUnclose'),
                    variant: 'error',
                    isPersisted: false
                });
            }
        },
        [t, addToast]
    );

    return {
        lockRoute,
        unlockRoute
    };
};
