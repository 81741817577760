export const carKind = [
    'CD20',
    'CD40',
    'CD45',
    'CD48',
    'CD53',
    'CE53',
    'CF40',
    'CH48',
    'CH53',
    'CI40',
    'CI48',
    'CR40',
    'CR48',
    'CR53',
    'CX99',
    'ED48',
    'ED53',
    'ID20',
    'ID40',
    'ID48',
    'IR40',
    'IX99',
    'PD53',
    'PH53',
    'PR53',
    'UD45',
    'UD48',
    'UR48',
    'UX99',
    'ZX99'
];
