import React from 'react';
import { IconButton } from '~/ui';
import { useTranslation } from 'react-i18next';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import './MarkerPopupFooter.scss';

export const MarkerPopupFooter = () => {
    const rootClassName = 'marker-popup-footer';
    const { t } = useTranslation('common');

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <span>{t('stackStopsPopup.scrollText')}</span>
            <IconButton
                iconColor="galaxy-500"
                icon="arrowRight"
                iconSize={IconSizes.S}
                className={`${rootClassName}__arrow-icon`}
            />
        </div>
    );
};
