import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';

import { resetOnLogout } from '~/reducers/common-actions';

export const defaultTasksDrawerSearch = {
    globalSearch: '',
    isSearchOpen: false
};

export const tasksDrawerSearchSlice = createSlice({
    name: 'tasksDrawerSearch',
    initialState: defaultTasksDrawerSearch,
    reducers: {
        setIsTasksSearchOpen: (state, action) => {
            return { ...state, isSearchOpen: action.payload };
        },
        setGlobalSearch: (state, action) => {
            return { ...state, globalSearch: action.payload };
        },
        resetTasksDrawerSearch: () => {
            return defaultTasksDrawerSearch;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultTasksDrawerSearch;
        });
    }
});

export const { setGlobalSearch, resetTasksDrawerSearch, setIsTasksSearchOpen } =
    tasksDrawerSearchSlice.actions;

export const selectGlobalSearch = (state: RootState): string =>
    state.tasksDrawerSearch.globalSearch;

export const selectIsTasksSearchOpen = (state: RootState): string =>
    state.tasksDrawerSearch.isSearchOpen;

export default tasksDrawerSearchSlice.reducer;
