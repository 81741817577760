import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const userGroupsByIdSlice = createSlice({
    name: 'userGroupsById',
    initialState: {},
    reducers: {
        setUserGroupsById: (state, action) => {
            return action.payload;
        },
        updateUserGroupById: (state, action) => {
            const newState = { ...state };
            newState[action.payload.id] = action.payload;
            return newState;
        },
        deleteUserGroupById: (state, action) => {
            if (!state[action.payload]) return;
            delete state[action.payload];
            return state;
        },
        resetUserGroups: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const {
    setUserGroupsById,
    updateUserGroupById,
    deleteUserGroupById,
    resetUserGroups
} = userGroupsByIdSlice.actions;

export const selectUserGroupsById = (state) => state.userGroupsById;

export default userGroupsByIdSlice.reducer;
