import { Duration, DateTime } from 'luxon';

const oneHourInMinutes = 60;
/**
 * Converts seconds to hours and minutes
 *
 * @param {Number} seconds
 * @returns {Object} An object with hours (number) and minutes (number)
 */
function mapSecondsToHoursAndMinutes(seconds: number) {
    const duration = Duration.fromObject({ seconds });
    const durationAsHours = duration.as('hours');
    const hoursAsString = durationAsHours.toString();
    const indexOfDecimal = hoursAsString.indexOf('.');
    const isWithoutDecimal = indexOfDecimal === -1;

    if (isWithoutDecimal) {
        return {
            hours: durationAsHours,
            minutes: 0
        };
    }

    const hours = Number(hoursAsString.substring(0, indexOfDecimal));
    const durationAsMinutes = Number(duration.as('minutes').toFixed(0));
    const minutes = durationAsMinutes - hours * oneHourInMinutes;

    return {
        hours,
        minutes
    };
}

/**
 * Convert seconds to number of minutes
 *
 * @param { Number } seconds - The number of seconds to calculate the duration from
 * @returns { Object } An object representing the duration in minutes
 */
function convertSecondsToMinutes(seconds: number) {
    return Duration.fromMillis(seconds * 1000)
        .shiftTo('minutes')
        .toObject();
}

/**
 * Converts an ISO string representing a duration to a specified unit
 * @param {string} durationString - The ISO string representing the duration
 * @param {string} unit - The unit to convert to ('milliseconds' or 'hours')
 * @returns {number} The duration in the specified unit
 */
function convertDuration(durationString: string, unit = 'hours'): number {
    const duration = Duration.fromISO(durationString);

    if (!duration.isValid) {
        return NaN;
    }

    return unit === 'milliseconds'
        ? duration.as('milliseconds')
        : duration.as('hours');
}

/**
 * Formats a given time while considering the specified time zone
 *
 * @param time - The time to be formatted, as a string or DateTime object
 * @param timeZone - The specified time zone
 * @param format - The format to be used for the output string. Default is 't'
 * @param isClientTimezoneFlagEnabled - Flag indicating whether to format the time using the client's time zone
 * @param shouldFormatTime - Whether to apply formatting to the output string. Default is true
 * @returns The formatted time as a string, or null if the input time is invalid
 */
function formatTimeInTimeZone({
    time,
    timeZone,
    isClientTimezoneFlagEnabled,
    format = 't',
    shouldFormatTime = true
}: {
    time: string | DateTime;
    isClientTimezoneFlagEnabled?: boolean;
    timeZone?: string;
    format?: string;
    shouldFormatTime?: boolean;
}) {
    const dateTime = typeof time === 'string' ? DateTime.fromISO(time) : time;

    if (!dateTime?.isValid) return null;

    if (!isClientTimezoneFlagEnabled)
        return shouldFormatTime ? dateTime.toFormat(format) : dateTime;

    const timeWithZone = dateTime.setZone(timeZone);

    return shouldFormatTime ? timeWithZone.toFormat(format) : timeWithZone;
}

/**
 * Gets the timezone offset in minutes
 *
 * @param timeZone - The specified time zone
 * @returns {number} The offset in minutes for the specified time zone
 */
function getTimezoneOffset({ timeZone }: { timeZone?: string }) {
    return DateTime.now().setZone(timeZone).offset;
}

export default {
    mapSecondsToHoursAndMinutes,
    convertSecondsToMinutes,
    formatTimeInTimeZone,
    convertDuration,
    getTimezoneOffset
};
