import { useQuery, UseQueryOptions } from 'react-query';
import EquipmentApi, { GetEquipmentParams } from '~/api/EquipmentApi';
import { ApiEquipmentBulk } from '~/api/EquipmentApi/types';

type UseGetRoutePlansReactQueryOptions = Omit<
    UseQueryOptions<ApiEquipmentBulk, Error>,
    'queryKey' | 'queryFn'
>;

interface UseGetEquipmentListParams {
    /**
     * The API options for `EquipmentApi.getEquipment()`
     */
    apiOptions?: GetEquipmentParams;

    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetRoutePlansReactQueryOptions;
}

const REACT_QUERY_KEY = 'equipment';

export const useGetEquipmentList = ({
    apiOptions = {},
    reactQueryOptions = {}
}: UseGetEquipmentListParams = {}) => {
    const fetchEquipment = async (
        options: GetEquipmentParams
    ): Promise<ApiEquipmentBulk> => {
        const { data } = await EquipmentApi.getEquipment(options);
        return data;
    };

    // return the full useQuery Object
    return useQuery<ApiEquipmentBulk, Error>(
        [REACT_QUERY_KEY, apiOptions],
        () => fetchEquipment(apiOptions),
        reactQueryOptions
    );
};
