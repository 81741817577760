import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLiveStopReassignTasks } from '~/hooks';
import constants from '~/utils/constants';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { WebMultiClientApi } from '~/api/WebMultiClientApi';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { PlanRoute, PlanStop } from '~/data-classes';

import { getReassignDispatchedPayload } from '../utils';

type UseReassignDispatchedStopsProps = {
    selectedRouteId: string;
    routes: PlanRoute[];
    selectedStops: PlanStop[];
};

const useReassignDispatchedStops = ({
    selectedRouteId,
    routes,
    selectedStops
}: UseReassignDispatchedStopsProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const { reassignTasks } = useLiveStopReassignTasks();

    const selectedRoute = useMemo(() => {
        return routes.find(({ routeId }) => selectedRouteId === routeId);
    }, [selectedRouteId, routes]);

    const reassignDispatchedStops = useCallback(() => {
        const selectedPayload = getReassignDispatchedPayload({
            selectedRoute,
            selectedStops
        });
        if (!selectedPayload) {
            return;
        }
        const { taskIds, driverId } = selectedPayload;
        const processIndicatorState = {
            message: t('ReassigningStop', {
                count: taskIds.length
            }),
            type: constants.processIndicator.REASSIGN_LIVE_STOP,
            payload: taskIds.length,
            inProgress: true,
            error: false,
            position: 'center'
        };
        dispatch(addProcessIndicator(processIndicatorState));
        reassignTasks(taskIds, driverId, {
            onSuccess: () => {
                // Using this timeout is a poor approach. It should only be here temporarily
                // pending the implementation of the bulk reassign endpoint which will block
                // for the entirety of the operation. This timeout of 5 seconds is meant to
                // allow the manual RTA (capped at 3 seconds) to complete before refreshing
                // the schedules.
                // TODO: Remove when we stop using legacyReassignTasks
                // https://wisesys.atlassian.net/browse/DISP-550
                setTimeout(() => {
                    WebMultiClientApi.get(); // no need to await get web
                    dispatch(resetSelectedMapStops());
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            inProgress: false,
                            error: false
                        })
                    );
                }, constants.timings.MANUAL_RTA_REFRESH_TIMEOUT);
            },
            onError: () => {
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedRoute, selectedStops]);

    return { reassignDispatchedStops };
};

export default useReassignDispatchedStops;
