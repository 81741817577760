/* eslint-disable camelcase */

export enum ForecasterResultType {
    WISE = 'Wise',
    CUSTOM = 'Custom'
}

export enum ForecasterResultStage {
    UPDATE = 'Update',
    DONE = 'Done',
    ERROR = 'Error'
}

export interface ApiForecasterResult {
    id?: string;
    client_id?: string;
    forecaster_run_id?: string;
    type?: ForecasterResultType;
    stage?: ForecasterResultStage;
    forecast_date?: string;
    total_routes?: number;
    unassigned_stops?: number;
    drivers_by_business_partner?: Record<string, unknown>;
    total_distance?: number;
    total_time?: number;
    total_stops?: number;
    total_tasks?: number;
    total_weight?: number;
    total_volume?: number;
    routes: Record<string, unknown>;
}
