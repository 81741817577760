import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text, RouteCardTitle } from '~/ui';
import StopSelectionContent from '../../../AddBreakModal/StopSelectionContent';

import './ResequenceBreak.scss';
import { useResequenceBreakSetup } from './useResequenceBreakSetup';

type Props = {
    handleClose: () => void;
};

function ResequenceBreak({ handleClose }: Props) {
    const { t } = useTranslation('driverBreakModal');
    const { selectedStop, handleStopSelection, resequenceHandler } =
        useResequenceBreakSetup();

    return (
        <div data-testid="resequence-break-view">
            <div className="resequence-break-view__header _d-flex _cg-2 _mb-4">
                <Icon
                    mx="0"
                    icon="chevronDown"
                    color="galaxy-800"
                    onClick={handleClose}
                    data-testid="resequence-break-view-chevron-icon"
                />
                <RouteCardTitle
                    className="_text-2"
                    title={t('resequenceView.headerTitle')}
                />
            </div>
            <div className="resequence-break-view__body">
                <StopSelectionContent
                    title={t('resequenceView.contentTitle')}
                    handleSelect={handleStopSelection}
                    selectedStop={selectedStop}
                />
                <div className="resequence-break-view__button">
                    <Button
                        onClick={resequenceHandler}
                        disabled={!selectedStop}
                        data-testid="resequence-break-view__button"
                    >
                        <div className="_ai-center _jc-center">
                            <Icon icon="iconReorder" color="white" />
                            <Text
                                className="_text-3"
                                color="white"
                                variant="12-normal"
                            >
                                {t('resequenceView.resequence')}
                            </Text>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ResequenceBreak;
