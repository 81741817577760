import React from 'react';
import i18n from '~/i18n';
import localizationUtils from '~/utils/localization-utils';
import filterUtils from '~/utils/filter-utils';
import { Detail, Icon, MarkerPopup, RouteCardTitle, Text } from '~/ui';
import { store } from '~/store';

const _makePlannedRoutePopup = (
    location,
    routeLevelData,
    emittedEventHandler
) => {
    const {
        duration,
        numTasks,
        distance,
        routeName,
        numLateStops,
        numAtRiskStops,
        volumeCapacityUsed
    } = routeLevelData;
    const distanceFormatted = localizationUtils.getTotalDistanceText(
        distance,
        i18n
    );
    const durationFormatted = localizationUtils.formatDuration(duration);

    const numLateAndAtRiskStops = numAtRiskStops + numLateStops;
    const bodyClasses = '_text-3 _d-flex _ai-flex-end flex-basis-50';

    const headerContent = (
        <>
            {numLateAndAtRiskStops > 0 && (
                <span className="_d-flex _ai-center _mb-3">
                    {numLateStops > 0 && <Icon icon="clockFill" color="mars" />}
                    {numAtRiskStops > 0 && (
                        <Icon icon="clockFill" color="venus" />
                    )}
                    <Text className="_text-3" color="galaxy-500" ml="0.2rem">
                        {numLateAndAtRiskStops}
                    </Text>
                </span>
            )}

            <RouteCardTitle className="_ml-1">{routeName}</RouteCardTitle>
        </>
    );
    const bodyContent = (
        <>
            <Detail
                iconName="stops"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={numTasks}
                className={bodyClasses}
            />
            <Detail
                iconName="volume"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={volumeCapacityUsed.toFixed()}
                className={bodyClasses}
            />
            <Detail
                iconName="distance"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={distanceFormatted}
                className={bodyClasses}
            />
            <Detail
                iconName="clock"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={durationFormatted}
                className={bodyClasses}
            />
        </>
    );

    const popup = (
        <MarkerPopup
            className="planned-route-popup"
            headerContent={headerContent}
            bodyContent={bodyContent}
            bodyClassName="_fw-wrap"
            emittedEventHandler={emittedEventHandler}
            lat={location.lat}
            lng={location.lng}
            data-testid="planned-route-popup"
        />
    );
    return popup;
};

const _makeUnplannedRoutePopup = (
    location,
    routeLevelData,
    emittedEventHandler
) => {
    const {
        volumeCapacityUsed,
        weightUsed,
        numTasks,
        numInventoryItems,
        clientId
    } = routeLevelData;
    const volume = `${volumeCapacityUsed.toFixed()} ${i18n.t('unit', {
        count: volumeCapacityUsed
    })}`;
    const weight = `${weightUsed.toFixed()} ${i18n.t('weight')}`;
    const stops = localizationUtils.formatNumStops(numTasks, i18n);
    const inventory = localizationUtils.formatNumInventory(
        numInventoryItems,
        i18n
    );
    const clientEntity = store.getState().clients[clientId];
    const clientInitials = filterUtils.getInitials(clientEntity.name);
    const headerContent = <b>{clientInitials}</b>;

    const bodyContent = (
        <>
            <Detail
                iconName="vehicle"
                iconSize="xs"
                iconColor="galaxy-500"
                className="_d-flex _ai-flex-start"
                mainContent={`${volume}, ${weight}`}
            />
            <Detail
                iconName="stop"
                iconSize="xs"
                iconColor="galaxy-500"
                mainContent={stops}
                className="_d-flex _ai-flex-start"
                secondaryContent={inventory}
            />
        </>
    );

    const popup = (
        <MarkerPopup
            className="unplanned-route-popup"
            headerContent={headerContent}
            bodyContent={bodyContent}
            emittedEventHandler={emittedEventHandler}
            lat={location.lat}
            lng={location.lng}
            data-testid="unplanned-route-popup"
        />
    );
    return popup;
};

/**
 * Creates a `MarkerPopup` component for route markers
 * @method makeRoutePopup
 * @memberof module:utils/map/popupMaker
 * @param {*} location - the locationd sata
 * @param {*} routeLevelData - the route data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeRoutePopup = (
    location,
    routeLevelData,
    emittedEventHandler
) => {
    let popup = null;
    const { isPlanned } = routeLevelData;
    if (isPlanned) {
        popup = _makePlannedRoutePopup(
            location,
            routeLevelData,
            emittedEventHandler
        );
    } else {
        popup = _makeUnplannedRoutePopup(
            location,
            routeLevelData,
            emittedEventHandler
        );
    }
    return popup;
};
