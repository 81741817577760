import { useFetchVehicleProfiles } from './useFetchVehicleProfiles';
import { useCreateVehicleProfile } from './useCreateVehicleProfile';
import { useUpdateVehicleProfile } from './useUpdateVehicleProfile';
import { useDeleteVehicleProfile } from './useDeleteVehicleProfile';

export const useVehicleProfileApi = () => {
    const queryKey = 'vehicle-profiles';
    const fetchVehicleProfiles = useFetchVehicleProfiles({ queryKey });
    const createVehicleProfile = useCreateVehicleProfile({ queryKey });
    const updateVehicleProfile = useUpdateVehicleProfile({ queryKey });
    const deleteVehicleProfile = useDeleteVehicleProfile({ queryKey });

    return {
        createVehicleProfile,
        deleteVehicleProfile,
        fetchVehicleProfiles,
        updateVehicleProfile
    };
};
