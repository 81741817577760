import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, styles } from '~/ui';

const variantStyles = {
    'galaxy-500': {
        color: 'galaxy-500',
        iconSize: 'm'
    }
};

const BackButton = React.forwardRef(function BackButton(
    {
        disabled,
        onClick,
        variant = 'galaxy-500',
        icon = 'chevronDown',
        ...extra
    },
    ref
) {
    const selectedStyle = variantStyles[variant];

    return (
        <FlexLayout
            disabled={disabled}
            ref={ref}
            alignItems="center"
            sx={{
                ...styles.interactions.clickable
            }}
            onClick={onClick}
            {...extra}
        >
            <Icon
                icon={icon}
                size={selectedStyle.iconSize}
                color={selectedStyle.color}
                sx={{ transform: 'rotate(90deg)' }}
            />
        </FlexLayout>
    );
});

BackButton.propTypes = {
    /** Back button disable state */
    disabled: PT.bool,
    /** Variant style of back button */
    variant: PT.string,
    /** On button click function */
    onClick: PT.func
};

export default BackButton;
