import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Supercluster from 'supercluster';
import { selectEquipment } from '~/reducers/equipmentSlice';
import { selectIsClusteringEquipment } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { markerUtils } from '~/utils/map';
import { getFilteredEquipments } from '~/components/MapPage/PlanMap/utils';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';

const clusterRadius = 300;
const maxZoom = constants.mapOptionSettings.MAX_ZOOM;

export const useEquipmentClusters = () => {
    const equipment = useSelector(selectEquipment);
    const { globalFilter, columnFilters } = useEquipmentTableStates();
    const isClusteringEquipment = useSelector(selectIsClusteringEquipment);

    const schedules = useMemo<
        Supercluster.PointFeature<Supercluster.AnyProps>[]
    >(() => {
        if (!isClusteringEquipment) {
            return [];
        }

        const filteredEquipments = getFilteredEquipments({
            equipment,
            globalFilter,
            columnFilters
        });

        return Object.keys(filteredEquipments).map((equipmentId) => {
            const { lat, lng } = filteredEquipments[equipmentId];

            return markerUtils.getGeoJSONFeaturePoint(
                lat,
                lng,
                filteredEquipments[equipmentId]
            ) as unknown as Supercluster.PointFeature<Supercluster.AnyProps>;
        });
    }, [isClusteringEquipment, equipment, globalFilter, columnFilters]);

    const superClusters = useMemo(() => {
        const superClusterEffects: Supercluster.AnyProps[] = [];

        const superCluster = new Supercluster({
            radius: clusterRadius,
            maxZoom
        });
        superCluster.load(
            schedules as unknown as Supercluster.PointFeature<Supercluster.AnyProps>[]
        );
        superClusterEffects.push(superCluster);

        return superClusterEffects;
    }, [schedules]);

    return {
        schedules,
        superClusters
    };
};
