import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSelectedClients } from '~/hooks';
import { selectCurrentUser } from '~/reducers/currentUserSlice';
import {
    configureUserInfo,
    setDatadogUser,
    updateDatadogUser,
    initDatadogForDeployments
} from '~/datadogService';
import { selectSelectedClientIds } from '~/reducers/selectedClientsSlice';
import { selectClients } from '~/reducers/clientsSlice';

const MonitoringProvider = () => {
    const [isMonitoringInitialized, setIsMonitoringInitialized] =
        useState(false);
    const currentUser = useSelector(selectCurrentUser);
    const clients = useSelector(selectClients);
    const selectedClientIds = useSelector(selectSelectedClientIds);
    const { userHasMultiAccess } = useSelectedClients();

    const currentUserClientId = currentUser?.access[0].client;
    const currentUserClientName = clients[currentUserClientId]?.name;

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        if (!isMonitoringInitialized) {
            initDatadogForDeployments();
            setIsMonitoringInitialized(true);
            const userConfig = configureUserInfo(currentUser);

            setDatadogUser({
                ...userConfig,
                mainClient: currentUserClientName || 'Not yet set'
            });
        }
    }, [currentUser, isMonitoringInitialized, currentUserClientName]);

    useEffect(() => {
        updateDatadogUser({ userHasMultiAccess });
    }, [userHasMultiAccess]);

    useEffect(() => {
        const clientConfig = selectedClientIds.reduce(
            (aggregator: Record<string, string>, id) => {
                aggregator[id] = clients[id]?.name;
                return aggregator;
            },
            {}
        );
        updateDatadogUser({ lastActiveClients: clientConfig });
    }, [selectedClientIds, clients]);

    useEffect(() => {
        if (currentUserClientName) {
            updateDatadogUser({ mainClient: currentUserClientName });
        }
    }, [selectedClientIds, currentUserClientName]);

    return null;
};

export { MonitoringProvider };
