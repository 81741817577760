import { createSlice } from '@reduxjs/toolkit';
import { ApiDriver } from '~/api/types';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

interface SelectedDriver {
    driverId?: string;
    driverData?: ApiDriver;
}

const initialState: SelectedDriver = {};

export const liveDispatchSelectedDriverSlice = createSlice({
    name: 'liveDispatchSelectedDriver',
    initialState,
    reducers: {
        setSelectedDriverId: (state, action) => {
            return {
                ...state,
                driverId: action.payload
            };
        },
        setSelectedDriverData: (state, action) => {
            return {
                ...state,
                driverData: action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return initialState;
        });
    }
});

export const { setSelectedDriverId, setSelectedDriverData } =
    liveDispatchSelectedDriverSlice.actions;

export const selectLiveDispatchSelectedDriver = (
    state: RootState
): SelectedDriver => state.liveDispatchSelectedDriver;

export default liveDispatchSelectedDriverSlice.reducer;
