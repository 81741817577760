import PT from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import type * as CSS from 'csstype';

import { Box } from '~/ui';

interface FlexLayoutProps extends React.ComponentProps<typeof Box> {
    alignItems?: CSS.Property.AlignItems;
    flex?: CSS.Property.Flex;
    flexDirection?: CSS.Property.FlexDirection;
    flexWrap?: CSS.Property.FlexWrap;
    justifyContent?: CSS.Property.JustifyContent;
    visibility?: boolean;
    'data-popper-interactive'?: boolean;
}

const FlexLayout = React.forwardRef<HTMLDivElement, FlexLayoutProps>(
    function FlexLayout(
        {
            className,
            alignItems,
            children,
            flex,
            flexDirection,
            flexWrap,
            justifyContent,
            visibility = true,
            sx = {},
            ...extra
        }: FlexLayoutProps,
        ref
    ) {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        function _getClassName() {
            const defaultClassName = 'flexlayout';
            const conditionalClasses = {
                '_d-flex': visibility,
                '_d-none': !visibility,
                [`_fd-${flexDirection}`]: flexDirection,
                [`_fw-${flexWrap}`]: flexWrap,
                [`_jc-${justifyContent}`]: justifyContent,
                [`_ai-${alignItems}`]: alignItems
            };
            return classNames(defaultClassName, conditionalClasses, className);
        }

        return (
            <Box
                className={_getClassName()}
                ref={ref}
                sx={{
                    ...sx,
                    flex
                }}
                {...extra}
            >
                {children}
            </Box>
        );
    }
);

FlexLayout.propTypes = {
    className: PT.string,
    alignItems: PT.string,
    children: PT.node, // React children components
    flex: PT.string,
    flexDirection: PT.string,
    flexWrap: PT.string,
    justifyContent: PT.string,
    sx: PT.object, // eslint-disable-line react/forbid-prop-types
    visibility: PT.bool
} as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export default FlexLayout;
