import type { RootState } from '~/store';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import constants from '~/utils/constants';

export const defaultGapRoute = {
    gapPosition: constants.assignmentPosition,
    isAddTaskToGapRoute: false
};

export const gapRouteSlice = createSlice({
    name: 'gapRoute',
    initialState: defaultGapRoute,
    reducers: {
        setGapPosition: (state, action) => {
            return { ...state, gapPosition: action.payload };
        },
        setIsAddTaskToGapRoute: (state, action) => {
            return { ...state, isAddTaskToGapRoute: action.payload };
        },
        resetGapRoute: () => {
            return defaultGapRoute;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return defaultGapRoute;
        });
    }
});

export const { setGapPosition, resetGapRoute, setIsAddTaskToGapRoute } =
    gapRouteSlice.actions;

export const selectGapPosition = (state: RootState): number =>
    state.gapRoute.gapPosition;

export const selectIsAddTaskToGapRoute = (state: RootState): boolean =>
    state.gapRoute.isAddTaskToGapRoute;

export default gapRouteSlice.reducer;
