import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Task } from '~/data-classes';
import {
    addSelectedMapStop,
    removeSelectedMapStop,
    updateSelectedMapStops,
    resetSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import {
    addSelectedTaskId,
    removeSelectedTaskId,
    resetSelectedTaskIds
} from '~/reducers/selectedTaskIdsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { HandleSelectUnassignedTaskProps } from './types';

export const useUnassignedTaskClickHandler = () => {
    const dispatch = useDispatch();

    const handleSelectUnassignedTask = useCallback(
        ({ stopData, isTaskSelected }: HandleSelectUnassignedTaskProps) => {
            const markerIdsToSelect = [];
            if (stopData.isPickup) {
                markerIdsToSelect.push(
                    stopData.pickupStopData.clientRouteTaskId
                );
            }
            if (stopData.isDelivery) {
                markerIdsToSelect.push(
                    stopData.deliveryStopData.clientRouteTaskId
                );
            }

            dispatch(resetSelectedTaskRowId());

            markerIdsToSelect.forEach((clientRouteTaskId) => {
                if (isTaskSelected) {
                    dispatch(removeSelectedMapStop(clientRouteTaskId));
                } else {
                    dispatch(updateSelectedMapStops(clientRouteTaskId));
                }
            });

            if (isTaskSelected) {
                dispatch(removeSelectedTaskId(stopData.id));
            } else {
                dispatch(addSelectedTaskId(stopData.id));
            }
        },
        [dispatch]
    );

    const handleSelectAllUnassignedTasks = useCallback(
        (unassignedTasks: Task[]) => {
            unassignedTasks.forEach(
                ({
                    isPickup,
                    isDelivery,
                    pickupStopData,
                    deliveryStopData
                }) => {
                    if (isPickup) {
                        dispatch(
                            addSelectedMapStop(pickupStopData.clientRouteTaskId)
                        );
                    }
                    if (isDelivery) {
                        dispatch(
                            addSelectedMapStop(
                                deliveryStopData.clientRouteTaskId
                            )
                        );
                    }
                }
            );
        },
        [dispatch]
    );

    const handleDeselectAllUnassignedTasks = useCallback(() => {
        dispatch(resetSelectedMapStops());
        dispatch(resetSelectedTaskIds());
    }, [dispatch]);

    return {
        handleDeselectAllUnassignedTasks,
        handleSelectUnassignedTask,
        handleSelectAllUnassignedTasks
    };
};
