import { useMutation } from 'react-query';
import DriverApi, { TaskToDispatch } from '~/api/DriverApi';
import {
    IPostDriverAssignmentsResponse,
    AxiosApiResponse,
    ReactQuerySideEffects
} from '~/api/types';
import { useWebInterval } from '~/hooks/useWebInterval';

interface ManualDispatchTaskParams {
    /**
     * the driver ID to manually assign the tasks and dispatch
     */
    driverId: string;

    /**
     * The tasks to dispatch
     */
    tasksToDispatch: TaskToDispatch[];
}

export const useTaskManualDispatch = () => {
    const { refetch } = useWebInterval();

    const {
        isLoading: isLoadingManualDispatchTask,
        mutateAsync: doManualDispatchTask
    } = useMutation(
        ({
            driverId,
            tasksToDispatch
        }: ManualDispatchTaskParams): Promise<
            AxiosApiResponse<IPostDriverAssignmentsResponse>
        > => {
            return DriverApi.dispatchTasks(driverId, tasksToDispatch);
        }
    );

    const manualDispatchTask = async (
        params: ManualDispatchTaskParams,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData = await doManualDispatchTask(params);
            onSuccess?.(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            onError?.(error);
        }

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    return { isLoadingManualDispatchTask, manualDispatchTask };
};
