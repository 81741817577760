import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Slider } from '~/ui';
import ZoomControl from '~/ui/components/PlanMapOverlay/ZoomControl';
import MapTypeControl from '~/ui/components/PlanMapOverlay/MapTypeControl/MapTypeControl';
import GeofenceDropdown from '~/components/CustomerManagementPage/CustomerMap/GeofenceDropdown';
import GeofenceControlsTitle from '~/components/CustomerManagementPage/CustomerMap/CustomerMapOverlay/GeofenceControlsTitle';

import { useGeofence } from '~/hooks/useGeofence';
import { selectCircleRadius } from '~/reducers/geofenceSlice';
import constants from '~/utils/constants';

import './CustomerMapOverlay.scss';

function CustomerMapOverlay({
    mapInstance,
    customerLocation,
    parkingLocations,
    isGeocodeEditable = false,
    isGeofenceEditable = false,
    geofenceCircleRadius,
    geofencePolygon,
    isDisableCircularGeofences = false
}) {
    const { t } = useTranslation(['customerManagement']);
    const circleRadius = useSelector(selectCircleRadius);
    const {
        isCircularMode,
        isFreehandMode,
        handleSliderChange,
        handleEditCircularGeofence,
        handleEditPolygonGeofence,
        handleCircularCancel,
        handleFreehandCancel
    } = useGeofence(
        customerLocation,
        parkingLocations,
        geofencePolygon,
        geofenceCircleRadius,
        isDisableCircularGeofences
    );

    const isGeofenceDropdownActive = () => {
        return !isCircularMode && !isFreehandMode && isGeofenceEditable;
    };

    return (
        <div className="customermap-overlay">
            <div className="customermap-controls customermap-controls_zoom _p-absolute">
                <ZoomControl map={mapInstance} showControls />
            </div>
            <div className="customermap-controls customermap-controls_maptype _p-absolute">
                <MapTypeControl map={mapInstance} />
            </div>
            {isGeofenceDropdownActive() && (
                <div className="customermap-controls customermap-controls_geofence _p-absolute">
                    <GeofenceDropdown
                        onCircularClick={handleEditCircularGeofence}
                        onFreehandClick={handleEditPolygonGeofence}
                    />
                </div>
            )}
            {isGeofenceEditable && isCircularMode && (
                <div className="customermap-controls customermap-controls_geofence customermap-geofence _fd-column _p-absolute">
                    <GeofenceControlsTitle
                        title={t('customerMap.circular')}
                        icon="circularGeofence"
                        onReset={handleCircularCancel}
                    />
                    <div className="customermap-slider">
                        <Slider
                            defaultValue={circleRadius?.toString()}
                            min={constants.customerMap.SLIDER_MIN}
                            max={constants.customerMap.SLIDER_MAX}
                            onChange={handleSliderChange}
                        />
                    </div>
                </div>
            )}
            {isGeocodeEditable && isFreehandMode && (
                <div className="customermap-controls customermap-controls_geofence customermap-geofence _p-absolute">
                    <GeofenceControlsTitle
                        title={t('customerMap.freehand')}
                        icon="zone"
                        onReset={handleFreehandCancel}
                    />
                </div>
            )}
        </div>
    );
}

export default CustomerMapOverlay;
