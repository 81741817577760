import React, { useEffect, useState } from 'react';

/**
 * Extension of `useState` that also resets the `valState` whenever the hook's input `val` changes
 */
export function usePropsState<T>(
    val: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [valState, setValState] = useState(val);

    useEffect(() => {
        setValState(val);
    }, [val]);

    return [valState, setValState];
}
