import { omitBy } from 'lodash';
import { InstantRoutesReportDriver } from '~/data-classes';
import { getSignedInventoryItemsById } from './getSignedInventoryItemsById';

export const getInventoryData = async (
    routeDate: string,
    allDrivers: InstantRoutesReportDriver[]
) => {
    const signedInventoryItemsById = await getSignedInventoryItemsById(
        routeDate
    );

    return allDrivers.flatMap(({ inventory }) => {
        return inventory.map((inventoryItem) => {
            const { id = '' } = inventoryItem;
            const signedItem = signedInventoryItemsById[id];
            const signatureUrl = signedItem?.signature_url ?? null;
            const photoUrls = signedItem?.photo_urls ?? [];
            const photoUrl = photoUrls.join(',') || null;

            return omitBy(
                {
                    ...inventoryItem,
                    signatureUrl,
                    photoUrl
                },
                (_, key) => {
                    return key === 'id';
                }
            );
        });
    });
};
