import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { OnDemandDispatchStop, PlanStop } from '~/data-classes';
import { SocketScheduleItem } from '~/api/types';
import { getMetricsFromStopOrTask } from '~/utils/stopUtils';

interface Props {
    /**
     * The selected stop (planned stop, unassigned task, or live stop)
     */
    stop: PlanStop | OnDemandDispatchStop | SocketScheduleItem;
}
export const useGetMetricsIconsData = ({ stop }: Props) => {
    const { size } = stop;
    const { serviceTime } = getMetricsFromStopOrTask({ stop });
    const { t } = useTranslation('translation');
    const serviceTimeText = serviceTime
        ? `${serviceTime} ${t('common:time.minutes')}`
        : undefined;

    return useMemo(() => {
        return [
            serviceTimeText && {
                key: '1',
                name: 'hourglass',
                text: serviceTimeText,
                size: IconSizes.S
            },
            size && {
                key: '2',
                name: 'volume',
                text: size.toString(),
                size: IconSizes.S
            }
        ].filter(Boolean);
    }, [serviceTimeText, size]);
};
