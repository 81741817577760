import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';

type TestDriverUploadProps = {
    isDriverUploadInProgress: boolean;
    progressIndicatorType: string;
};

type HookDriverUploadProps = {
    isDriverUploadInitiated: boolean;
    setIsDriverUploadInitiated: React.Dispatch<React.SetStateAction<boolean>>;
};

export const testIsDriverUploadInitiated = ({
    isDriverUploadInProgress,
    progressIndicatorType
}: TestDriverUploadProps): boolean => {
    return (
        progressIndicatorType === constants.processIndicator.DRIVER_UPLOAD &&
        isDriverUploadInProgress
    );
};

/**
 * Implements checking to confirm if the user initiated Driver/Vehicle upload
 *
 * This is currently a workaround pending when the backend sends enough
 * information that helps us to identify whom the upload events belong to.
 *
 * TODO: https://wisesys.atlassian.net/browse/RP-166
 *
 * @category Hooks
 * @module useIsDriverUploadInitiated
 * @returns @returns {{isDriverUploadInitiated: boolean, setIsDriverUploadInitiated: function}}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useIsDriverUploadInitiated } from '~/hooks';
 *
 * // get the use state boolean and the set function
 * const { isDriverUploadInitiated, setIsDriverUploadInitiated } = useIsDriverUploadInitiated();
 *
 * // update state manually
 * const { setIsDriverUploadInitiated } = useIsDriverUploadInitiated();
 * setIsDriverUploadInitiated();
 */
export const useIsDriverUploadInitiated = (): HookDriverUploadProps => {
    const {
        inProgress: isDriverUploadInProgress,
        type: progressIndicatorType
    } = useSelector(selectProcessIndicator);
    const [isDriverUploadInitiated, setIsDriverUploadInitiated] = useState(
        testIsDriverUploadInitiated({
            isDriverUploadInProgress,
            progressIndicatorType
        })
    );

    useEffect(() => {
        const isUploadInitiated = testIsDriverUploadInitiated({
            isDriverUploadInProgress,
            progressIndicatorType
        });

        if (isUploadInitiated && isUploadInitiated !== isDriverUploadInitiated)
            setIsDriverUploadInitiated(isUploadInitiated);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [progressIndicatorType, isDriverUploadInProgress]);

    return {
        isDriverUploadInitiated,
        setIsDriverUploadInitiated
    };
};
