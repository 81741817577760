import constants from '~/utils/constants';

export const getDefaultState = () => ({
    isUploadComplete: false,
    isUploadSuccessful: false,
    isUploaded: false,
    uploadStats: {
        status: '',
        currentCount: 0,
        totalCount: 0,
        currentName: '',
        failedTasks: 0,
        successfulTasks: 0
    }
});

function getNewState({ latestMessage, state: currentState }) {
    const defaultState = getDefaultState();
    const { uploadStats: defaultUploadStats } = defaultState;
    const { uploadStats: currentStateUploadStats } = currentState;
    const { totalCount = 0, currentCount = 0 } = currentStateUploadStats || {};
    const {
        status,
        stats,
        task,
        error,
        upload: { countscheduled = 0, countcreated = 0 } = {}
    } = latestMessage || {};

    const {
        uploadStatus: {
            COMPLETE,
            ACCEPTED,
            FAILED,
            STATS,
            TASK_CREATED,
            DRIVER_VEHICLE_PROCESSED
        }
    } = constants;
    if ([ACCEPTED, STATS].includes(status)) {
        return {
            ...defaultState,
            isUploaded: true,
            uploadStats: {
                ...defaultUploadStats,
                status,
                totalCount: stats || 1
            }
        };
    }

    if (status === TASK_CREATED) {
        return {
            ...defaultState,
            isUploaded: true,
            uploadStats: {
                ...defaultUploadStats,
                currentCount: currentCount + 1,
                status,
                totalCount: totalCount || 1,
                currentName: task
            }
        };
    }

    if (status === DRIVER_VEHICLE_PROCESSED) {
        return {
            ...defaultState,
            isUploaded: true,
            uploadStats: {
                ...defaultUploadStats,
                status,
                totalCount: totalCount || 1,
                currentName: task
            }
        };
    }

    if (status === COMPLETE) {
        const failedTasks = countscheduled - countcreated;
        const successfulTasks = countcreated;

        return {
            ...defaultState,
            isUploaded: true,
            isUploadComplete: true,
            isUploadSuccessful: true,
            uploadStats: {
                ...defaultUploadStats,
                currentCount,
                status,
                failedTasks,
                successfulTasks,
                totalCount: failedTasks + successfulTasks
            }
        };
    }

    if (!error) {
        return {
            ...defaultState,
            uploadStats: {
                ...defaultUploadStats,
                status
            }
        };
    }

    return {
        ...defaultState,
        isUploaded: true,
        isUploadComplete: true,
        uploadStats: {
            ...defaultUploadStats,
            totalCount,
            status: FAILED
        }
    };
}

export default { getNewState };
