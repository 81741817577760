import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps } from 'formik';
import classNames from 'classnames';
import { uniq } from 'lodash';
import { ApiInvoice } from '~/api/types';
import { Checkbox } from '~/ui';
import { useDuplicateTaskModalInvoice } from './useDuplicateTaskModalInvoice';

interface DuplicateTaskModalInvoiceProps {
    invoice: ApiInvoice;
    invoiceRenderProps: FieldArrayRenderProps;
}

export const DuplicateTaskModalInvoice = ({
    invoice,
    invoiceRenderProps
}: DuplicateTaskModalInvoiceProps) => {
    const rootClassName = 'duplicate-task-modal-invoice';
    const { t } = useTranslation('duplicateTaskModal');
    const {
        amountDue,
        invoiceId,
        invoiceNumber,
        weight,
        labels,
        size: volume
    } = invoice;

    const { handleToggleCheckBox, invoicesIds, isSelected } =
        useDuplicateTaskModalInvoice({
            invoiceRenderProps,
            invoiceId
        });

    const className = classNames(`${rootClassName} _d-flex`, {
        [`${rootClassName}--selected`]: isSelected
    });

    const volumeText = volume
        ? t('invoiceCard.volume', {
              volume
          })
        : null;
    const weightText = weight
        ? t('invoiceCard.weight', {
              weight
          })
        : null;
    const weightAndVolumeText = [weightText, volumeText]
        .filter(Boolean)
        .join(' • ');

    const labelsForDisplay = useMemo(() => {
        const uniqueLabels = uniq(labels);

        if (!uniqueLabels.length) return [];

        const allowedNumberOfVisibleLabels = 4;

        const visibleLabels = uniqueLabels.slice(
            0,
            allowedNumberOfVisibleLabels
        );
        const otherLabelsCount =
            uniqueLabels.length - allowedNumberOfVisibleLabels;

        if (otherLabelsCount < 1) {
            return visibleLabels;
        }

        return [...visibleLabels, `${otherLabelsCount}+`];
    }, [labels]);

    const renderLabels = () => {
        if (!labelsForDisplay.length) return null;

        return (
            <div className={`${rootClassName}__labels _d-flex`}>
                {labelsForDisplay.map((label) => (
                    <span key={label} className={`${rootClassName}__label`}>
                        {label}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className={className} key={invoiceId} data-testid={rootClassName}>
            <Checkbox
                id={invoiceId}
                value={invoiceId}
                handleChange={handleToggleCheckBox}
                selectedValues={invoicesIds}
            />
            <div className="_d-flex _fd-column">
                <span className={`${rootClassName}__amount`}>
                    {t('invoiceCard.amountDue', {
                        amountDue
                    })}
                </span>
                <span>
                    {t('invoiceCard.invoiceNumber', {
                        invoiceNumber
                    })}
                </span>
                {Boolean(weightAndVolumeText) && (
                    <span className={`${rootClassName}__units`}>
                        {weightAndVolumeText}
                    </span>
                )}
                {renderLabels()}
            </div>
        </div>
    );
};
