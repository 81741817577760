import React from 'react';
import { PageContextProvider } from './PageContext';
import { MapContextProvider } from './MapContext';
import ZoneManagementPageLayout from './ZoneManagementPageLayout/ZoneManagementPageLayout';

const ZoneManagementPage = (): JSX.Element => {
    return (
        <PageContextProvider>
            <MapContextProvider>
                <ZoneManagementPageLayout />
            </MapContextProvider>
        </PageContextProvider>
    );
};

export default ZoneManagementPage;
