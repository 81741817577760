import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SuggestApi } from '~/api/SuggestApi';
import { SuggestMetric, SupportedSuggestMetric } from '~/api/types';

import { Icon, TextOverflowTooltip } from '~/ui';
import dateUtilsConverters from '~/utils/date-utils-converters';
import unitConverter from '~/utils/unit-converter';

export interface SuggestedMetricType {
    metricType: 'routeTime' | 'customCost' | 'routeDistance';
    totalSuggestMetricName: keyof SuggestMetric;
    inducedSuggestMetricName: keyof SuggestMetric;
    icon: React.ComponentProps<typeof Icon>['icon'];
    transformTotal: (value: number, t?: TFunction) => string;
    transformInduced?: (value: number, t?: TFunction) => string;
}

interface SuggestedDriverMetricProps {
    suggestMetricType: SuggestedMetricType;
    orderedBy: SupportedSuggestMetric;
    totalValue: number;
    inducedValue: number;
}

export const suggestMetricTypes = {
    routeTime: {
        metricType: 'routeTime',
        totalSuggestMetricName: 'newTotalRouteTime',
        inducedSuggestMetricName: 'inducedRouteTime',
        icon: 'clock2',
        transformTotal(value: number, t: TFunction) {
            const hoursMinutes =
                dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
                    value * 1000
                );
            if (!hoursMinutes.hours) {
                return t('common:time.minNoSpace', {
                    time: hoursMinutes.minutes
                });
            }
            return t('common:time.duration_hm', hoursMinutes);
        }
    } as SuggestedMetricType,
    customCost: {
        metricType: 'customCost',
        totalSuggestMetricName: 'customCost',
        inducedSuggestMetricName: 'inducedCustomCost',
        icon: 'cost',
        transformTotal(value: number) {
            // Todo: use i18Next v21's currency formatting. Not available in i18Next 19.
            return `$${Math.abs(value ?? 0).toFixed(2)}`;
        }
    } as SuggestedMetricType,
    // The following are not currently supported by the API
    routeDistance: {
        metricType: 'routeDistance',
        totalSuggestMetricName: 'newTotalRouteDistance',
        inducedSuggestMetricName: 'inducedRouteDistance',
        icon: 'distance',
        transformTotal(value: number, t: TFunction) {
            const translationKey = unitConverter.getLocalDistanceUnits();
            return t(translationKey, {
                distance: unitConverter.getLocalDistance(value)
            });
        }
    } as SuggestedMetricType
};

const SuggestedDriverMetric = ({
    suggestMetricType,
    orderedBy,
    totalValue,
    inducedValue
}: SuggestedDriverMetricProps) => {
    const { t } = useTranslation(['translation', 'common']);
    const {
        metricType,
        icon,
        transformTotal,
        transformInduced,
        totalSuggestMetricName,
        inducedSuggestMetricName
    } = suggestMetricType;

    function defaultTransformInduced(value: number) {
        const transformedValue = transformTotal(Math.abs(value), t);
        if (value < 0) {
            return `- ${transformedValue}`;
        }
        return `+ ${transformedValue}`;
    }

    const totalMetric = useMemo(
        () => transformTotal(totalValue, t),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [totalValue, t]
    );
    const inducedMetric = useMemo(
        () =>
            transformInduced
                ? transformInduced(inducedValue, t)
                : defaultTransformInduced(inducedValue),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [inducedValue, t]
    );

    if (!suggestMetricType) {
        return null;
    }

    return (
        <div className="suggesteddrivermetric">
            <div className="_d-flex _ai-center">
                <Icon icon={icon} size="s" />
                <span className="suggesteddrivermetric-label">
                    {t(`SuggestMetrics.${metricType}`)}
                </span>
            </div>
            <TextOverflowTooltip content={totalMetric} placement="bottom">
                <div
                    className="suggestdrivermetric-value _to-ellipsis"
                    data-highlight={
                        SuggestApi.getExplainMetricInResponseForOrderBy(
                            orderedBy
                        ) === totalSuggestMetricName
                    }
                >
                    {totalMetric}
                </div>
            </TextOverflowTooltip>
            <TextOverflowTooltip content={inducedMetric} placement="bottom">
                <div
                    className="suggestdrivermetric-value _to-ellipsis"
                    data-highlight={
                        SuggestApi.getExplainMetricInResponseForOrderBy(
                            orderedBy
                        ) === inducedSuggestMetricName
                    }
                >
                    {inducedMetric}
                </div>
            </TextOverflowTooltip>
        </div>
    );
};

export default SuggestedDriverMetric;
