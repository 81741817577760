import React, { useState } from 'react';
import {
    GoogleMapsApi,
    GoogleMapsDrawingManager,
    GoogleMapsInstance,
    GoogleMapsPolygon
} from '~/api/types';
import { MapContext } from './MapContext';

type Props = {
    children: React.ReactChild;
};

export const MapContextProvider = ({ children }: Props): JSX.Element => {
    const [activePolygon, setActivePolygon] =
        useState<GoogleMapsPolygon | null>(null);
    const [drawingManagerInstance, setDrawingManagerInstance] =
        useState<GoogleMapsDrawingManager | null>(null);
    const [isDrawingMode, setIsDrawingMode] = useState<boolean>(false);
    const [mapInstance, setMapInstance] =
        useState<GoogleMapsInstance | null>(null);
    const [mapsApi, setMapsApi] = useState<GoogleMapsApi | null>(null);

    return (
        <MapContext.Provider
            value={{
                activePolygon,
                drawingManagerInstance,
                isDrawingMode,
                mapInstance,
                mapsApi,
                setActivePolygon,
                setDrawingManagerInstance,
                setIsDrawingMode,
                setMapInstance,
                setMapsApi
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
