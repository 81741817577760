import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui';
import { CheckboxProps } from './types';
import './Checkbox.scss';

const ROOT_CLASS_NAME = 'checkbox';

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    function Checkbox(
        {
            id = '',
            name = '',
            value = '',
            selectedValues = [],
            disabled = false,
            children,
            className = '',
            handleChange,
            'data-testid': dataTestId
        },
        ref
    ) {
        const isChecked = selectedValues.includes(value);
        const conditionalClasses = {
            'checkbox--checked': isChecked,
            'checkbox--disabled': disabled,
            [className]: className?.length,
            [`${className}--checked`]: className?.length && isChecked,
            [`${className}--disabled`]: className?.length && disabled
        };
        const elementClassName = classNames(
            ROOT_CLASS_NAME,
            conditionalClasses
        );
        const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
        const inputTestId = `${elementDataTestId}-input`;

        const handleCheckboxChange = useCallback(() => {
            if (disabled) {
                return;
            }
            const updatedValues = [...selectedValues];
            const index = updatedValues.indexOf(value);

            if (index !== -1) {
                updatedValues.splice(index, 1);
            } else {
                updatedValues.push(value);
            }

            handleChange?.({
                isChecked: updatedValues.includes(value),
                value,
                selectedValues: updatedValues
            });
        }, [handleChange, disabled, selectedValues, value]);

        return (
            <label
                htmlFor={id}
                className={elementClassName}
                data-testid={elementDataTestId}
            >
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    ref={ref}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                    hidden
                    data-testid={inputTestId}
                />
                <div className="checkbox-box">
                    <Icon icon="checkmark" size="xs" color="white" />
                </div>
                {children}
            </label>
        );
    }
);

export default Checkbox;
