import { DateTime } from 'luxon';
import { ApiAnalyticsAggregateDriversDownload } from '~/api/types';
import { IconName } from '~/ui/components/Icon/IconNames';

import { DownloadableReportCategories } from './types/DownloadableReportCategories';
import { DownloadableReportFilenames } from './types/DownloadableReportFilenames';
import { DownloadableReportIcons } from './types/DownloadableReportIcons';
import { DownloadableReportTypes } from './types/DownloadableReportTypes';
import {
    DownloadableReportFilenamesByType,
    DownloadableReportMappingsByType
} from './types/mapping';

/**
 * DownloadableReportItem data class
 *
 * @category Data Classes
 *
 * @example
 * import { DownloadableReportItem } from '~/data-classes/report';
 *
 * const srcData = {};
 * const reportItem = new DownloadableReportItem(srcData);
 *
 */
export class DownloadableReportItem {
    /**
     * The API source data
     * @type {ApiAnalyticsAggregateDriversDownload}
     */
    private readonly report: ApiAnalyticsAggregateDriversDownload;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(reportItem: ApiAnalyticsAggregateDriversDownload) {
        this.report = reportItem;
    }

    /**
     * The downloadable report AWS key
     */
    get key(): string {
        return this.report.Key;
    }

    /**
     * The downloadable report filename
     */
    get filename(): string {
        const [filename] = this.key.split('/').reverse();
        return filename;
    }

    /**
     * The downloadable report type
     */
    get type(): DownloadableReportTypes {
        const reportMatch = Object.keys(DownloadableReportFilenamesByType).find(
            (key) =>
                this.filename.startsWith(`${key}${this.reportDate.toISODate()}`)
        ) as DownloadableReportFilenames;

        return reportMatch
            ? DownloadableReportFilenamesByType[reportMatch]
            : DownloadableReportTypes.UNKNOWN;
    }

    /**
     * The downloadable report icon
     */
    get icon(): IconName {
        const typeMatch = Object.keys(DownloadableReportMappingsByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return typeMatch
            ? DownloadableReportMappingsByType[typeMatch].icon
            : DownloadableReportIcons.UNKNOWN;
    }

    /**
     * The downloadable report category
     */
    get category(): DownloadableReportCategories {
        const typeMatch = Object.keys(DownloadableReportMappingsByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return typeMatch
            ? DownloadableReportMappingsByType[typeMatch].category
            : DownloadableReportCategories.UNKNOWN;
    }

    /**
     * Whether the downloadable report is a core report
     */
    get isCoreReport(): boolean {
        return this.category === DownloadableReportCategories.CORE;
    }

    /**
     * Whether the downloadable report item is a custom report
     */
    get isCustomReport(): boolean {
        return this.category === DownloadableReportCategories.CUSTOM;
    }

    /**
     * The downloadable report date in DateTime
     */
    get reportDate(): DateTime {
        const [reportDate] = this.filename.match(/\d{4}-\d{2}-\d{2}/g) || [''];
        return DateTime.fromISO(reportDate);
    }

    /**
     * The last modified date in DateTime
     */
    get lastModified(): DateTime {
        return DateTime.fromISO(this.report.LastModified);
    }

    /**
     * The size in KB
     */
    get size(): number {
        return this.report.Size;
    }

    /**
     * The AWS bucket link
     */
    get link(): string {
        return this.report.link;
    }

    /**
     * The AWS bucket link's hostname
     */
    get linkHostname(): string {
        const [hostname] = this.link.split(this.key);
        return hostname.slice(0, hostname.length - 1);
    }

    /**
     * The AWS bucket link's path, including search params
     */
    get linkPath(): string {
        const path = this.link.slice(this.linkHostname.length);
        return path;
    }

    /**
     * Whether the downloadable report item is within the defined report dates
     */
    isWithinReportDates(filterReportDates: DateTime[] = []): boolean {
        if (!Array.isArray(filterReportDates))
            throw new TypeError('should be an array of DateTime objects');

        return (
            this.reportDate.isValid &&
            filterReportDates.length > 0 &&
            filterReportDates.some((item) => {
                const itemDate = item.toISODate();
                return itemDate === this.reportDate.toISODate();
            })
        );
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiAnalyticsAggregateDriversDownload}
     */
    toJSON(): ApiAnalyticsAggregateDriversDownload {
        return this.report;
    }
}
