import { ApiCustomCostMatrix, CustomCostMatrixEntries } from '~/api/types';

/**
 * CustomCost data class
 *
 * @category Data Classes
 *
 * @example
 * import { CustomCostMatrix } from '~/data-classes/cost/CustomCostMatrix';
 *
 * const srcData = {};
 * const customCost = new CustomCostMatrix(srcData);
 *
 */
export class CustomCostMatrix {
    /**
     * The API source data
     * @type {ApiCustomCostMatrix}
     */
    private readonly customCost: ApiCustomCostMatrix;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(customCost: ApiCustomCostMatrix) {
        this.customCost = customCost;
    }

    get active(): boolean {
        return this.customCost.active;
    }

    get clientId(): string {
        return this.customCost.client_id;
    }

    get id(): string {
        return this.customCost.id;
    }

    get name(): string {
        return this.customCost.name;
    }

    get createdAt(): string {
        return this.customCost.created_at;
    }

    get updatedAt(): string {
        return this.customCost.updated_at;
    }

    get entries(): Array<CustomCostMatrixEntries> | undefined {
        return this.customCost.entries;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiCustomCostMatrix}
     */
    toJSON(): ApiCustomCostMatrix {
        return this.customCost;
    }
}
