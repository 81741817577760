import { SelectedDrawerCardData } from '../types';

interface ActionPayload {
    id?: string;
    data?: unknown;
}

const insertNewItem = ({
    listOfCardsData,
    payload
}: {
    listOfCardsData: SelectedDrawerCardData[];
    payload: ActionPayload;
}) => {
    const { data, id } = payload;

    if (!id || !data) return listOfCardsData;

    const newData = {
        id,
        data,
        isPinned: false
    };

    const maxNumberOfCardsData = 2;
    const lastItem = listOfCardsData[listOfCardsData.length - 1];
    const canAppendToList =
        !lastItem ||
        (lastItem.isPinned && listOfCardsData.length < maxNumberOfCardsData);

    if (canAppendToList) {
        listOfCardsData.push(newData);
        return listOfCardsData;
    }

    listOfCardsData[listOfCardsData.length - 1] = newData;

    return listOfCardsData;
};

export const upsertSelectedDrawerCardData = ({
    state,
    payload
}: {
    state: SelectedDrawerCardData[];
    payload: SelectedDrawerCardData;
}) => {
    const { id, data } = payload;
    const listOfCardsData = state ?? [];
    const itemIndex = listOfCardsData.findIndex((item) => item.id === id);

    if (itemIndex === -1) {
        return insertNewItem({
            listOfCardsData,
            payload
        });
    }

    const item = listOfCardsData[itemIndex];

    listOfCardsData[itemIndex] = {
        ...item,
        ...(Boolean(data) && {
            data
        })
    };

    return listOfCardsData;
};
