import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import constants from '~/utils/constants';
import './map-type-control.scss';

function MapTypeControl({ map }) {
    const [isSatelliteView, setSatelliteView] = useState(false);
    const { t } = useTranslation('customerManagement');

    function _getClassName() {
        const defaultClassName = 'map-type-control _d-flex _jc-center';
        const conditionalClassNames = {
            'map-type-control_satelliteview': !isSatelliteView,
            'map-type-control_defaultview': isSatelliteView
        };
        return classNames(defaultClassName, conditionalClassNames);
    }

    function _getButtonLabel() {
        const defaultLabel = t('customerMap.defaultView');
        const satelliteLabel = t('customerMap.satelliteView');
        return isSatelliteView ? defaultLabel : satelliteLabel;
    }

    function toggleView() {
        const defaultMapTypeView = constants.mapView.type.DEFAULT;
        const satelliteMapTypeView = constants.mapView.type.SATELLITE;

        if (isSatelliteView) map.setMapTypeId(defaultMapTypeView);
        else map.setMapTypeId(satelliteMapTypeView);
        setSatelliteView(!isSatelliteView);
    }

    return (
        <button
            data-testid="maptypecontrol"
            className={_getClassName()}
            type="button"
            onClick={toggleView}
        >
            {_getButtonLabel()}
        </button>
    );
}

export default MapTypeControl;
