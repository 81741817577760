import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlanRoute from '~/data-classes/plan/PlanRoute';
import { selectPlanRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';

import { PlanClientRoute } from '~/api/types';

export function usePlanRoutes() {
    const planRoutesLevelData = useSelector(
        selectPlanRoutesLevelData
    ) as PlanClientRoute[];

    return useMemo(
        () => planRoutesLevelData.map((data) => new PlanRoute(data)),
        [planRoutesLevelData]
    );
}
