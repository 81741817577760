import { useMutation } from 'react-query';
import AssignmentApi from '~/api/AssignmentApi';
import { ApiAssignment, AxiosApiResponse } from '~/api/types';
import { UpdateAssignmentStatus } from './types';

export const useUpdateAssignmentStatus = () => {
    const {
        isLoading: isLocationUpdateAssignmentStatus,
        mutateAsync: doUpdateAssignmentStatusAsync
    } = useMutation(({ stopId, status }: UpdateAssignmentStatus): Promise<
        AxiosApiResponse<ApiAssignment>
    > => {
        const payload = {
            status,
            timestamp: new Date().toISOString()
        };
        return AssignmentApi.status(stopId, payload);
    });

    const updateAssignmentStatus = (payload: UpdateAssignmentStatus) => {
        return doUpdateAssignmentStatusAsync(payload);
    };

    return {
        isLocationUpdateAssignmentStatus,
        updateAssignmentStatus
    };
};
