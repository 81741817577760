import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Icon } from '~/ui';

import './WithExpandButton.scss';

type IconStyles = {
    [key: string]: string;
};

type Props = {
    isOpen?: boolean;
    onClick?: () => void;
    children?: string | ReactNode;
    iconStyles?: IconStyles;
    isDisabled?: boolean;
    isFullyClickable?: boolean;
};

const BLOCK_CLASSNAME = 'expand-button';

export const WithExpandButton = ({
    isOpen,
    onClick,
    children,
    iconStyles = { top: '1.2rem', right: '1.2rem' },
    isDisabled = false,
    isFullyClickable = true
}: Props): JSX.Element => {
    const handleClick = () => {
        if (isDisabled || !onClick) return;
        onClick();
    };
    return (
        <div
            className={`${BLOCK_CLASSNAME}__container _p-relative`}
            onClick={isFullyClickable ? handleClick : () => {}}
            onKeyDown={isFullyClickable ? handleClick : () => {}}
            role="button"
            tabIndex={-1}
            data-testid="expand-button"
        >
            <div className={classNames(`${BLOCK_CLASSNAME}__content`)}>
                {children}
            </div>
            <Icon
                className={classNames(`${BLOCK_CLASSNAME}__icon`, {
                    [`${BLOCK_CLASSNAME}__icon--disabled`]: isDisabled
                })}
                sx={iconStyles}
                icon={isOpen ? 'chevronTriangleUp' : 'chevronTriangleDown'}
                size="xs"
                color="galaxy-800"
                disabled={isDisabled}
                onClick={isFullyClickable ? () => {} : handleClick}
            />
        </div>
    );
};
