import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { resetOnLogout } from '~/reducers/common-actions';

const getUploadProgressInitialState = () => {
    return {
        isTaskUploadInProgress: false,
        inProgressFileName: null,
        inProgressStartMilliseconds: 0
    };
};

const getInitialState = () => {
    return {
        isOpenTaskUploadModal: false,
        ...getUploadProgressInitialState()
    };
};

export const taskUploadSlice = createSlice({
    name: 'taskUpload',
    initialState: getInitialState(),
    reducers: {
        setIsOpenTaskUploadModal: (state, action) => {
            const { payload: isOpenTaskUploadModal } = action;

            if (!isOpenTaskUploadModal) return getInitialState();

            state.isOpenTaskUploadModal = true;

            return state;
        },
        setTaskUploadProgressInitiated: (state, action) => {
            const { payload } = action;
            const { fileName } = payload || {};
            const { isOpenTaskUploadModal } = state;

            if (!isOpenTaskUploadModal || !fileName) return state;

            state.isTaskUploadInProgress = true;
            state.inProgressFileName = fileName;
            state.inProgressStartMilliseconds = DateTime.now().toMillis();

            return state;
        },
        clearTaskUploadProgress: (state) => {
            return {
                ...state,
                ...getUploadProgressInitialState()
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return getInitialState();
        });
    }
});

export const {
    clearTaskUploadProgress,
    setIsOpenTaskUploadModal,
    setTaskUploadProgressInitiated
} = taskUploadSlice.actions;
export const selectIsOpenTaskUploadModal = (state) =>
    state.taskUpload.isOpenTaskUploadModal;

export const selectIsTaskUploadInProgress = (state) =>
    state.taskUpload.isTaskUploadInProgress;

export const selectInProgressFileName = (state) =>
    state.taskUpload.inProgressFileName;

export const selectInProgressStartDateTime = (state) =>
    DateTime.fromMillis(state.taskUpload.inProgressStartMilliseconds);

export default taskUploadSlice.reducer;
