/**
 * Available downloadable report filenames
 */
export enum DownloadableReportFilenames {
    /**
     * Auto Arrive report filename
     *
     * filename follows the format: 'auto_arrive_depart_report_{date}.xlsx'
     */
    AUTO_ARRIVE = 'auto_arrive_depart_report_',

    /**
     * Time Window Stat report filename
     *
     * filename follows the format: 'Time_Window_Stats_Report_{date}.xlsx'
     */
    TIME_WINDOW_STAT = 'Time_Window_Stats_Report_',

    /**
     * Driver Raw report filename
     *
     * filename follows the format: 'Driver_Daily_Raw_Data_{date}.xlsx'
     */
    DRIVER_RAW = 'Driver_Daily_Raw_Data_',

    /**
     * Fleet report filename
     *
     * filename follows the format: 'fleet_agg_{date}.xlsx'
     */
    FLEET = 'fleet_agg_',

    /**
     * Service Time report
     *
     * filename follows the format: 'Service_Time_Report_{date}.xlsx'
     */
    SERVICE_TIME = 'Service_Time_Report_',

    /**
     * Manual Sequence report
     *
     * filename follows the format: 'manual_sequence_{date}.xlsx'
     */
    MANUAL_SEQUENCE = 'manual_sequence_',

    /**
     * Time Window report
     *
     * filename follows the format: 'time_window_{date}.xlsx'
     */
    TIME_WINDOW = 'time_window_',

    /**
     * Depot Departure report
     *
     * filename follows the format: 'fleet_leaving_depot_report_{date}.xlsx'
     */
    DEPOT_DEPARTURE = 'fleet_leaving_depot_report_',

    /**
     * Route Compare report
     *
     * filename follows the format: 'route_compare_report_{date}.xlsx'
     */
    ROUTE_COMPARE = 'route_compare_report_',

    /**
     * Edit Impact report
     *
     * filename follows the format: 'edit_impact_{date}.xlsx'
     */
    EDIT_IMPACT = 'edit_impact_',

    /**
     * Signature Zip report
     *
     * filename follows the format: 'customer_signatures_{date}.xlsx'
     */
    SIGNATURE_ZIP = 'customer_signatures_',

    /**
     * Inventory Management report
     *
     * filename follows the format: 'inventory_management_report_{date}.xlsx'
     */
    INVENTORY_MANAGEMENT = 'inventory_management_report_',

    /**
     * Proof of Delivery report
     *
     * filename follows the format: 'Proof_of_Delivery_{date}.xlsx'
     */
    PROOF_OF_DELIVERY = 'Proof_of_Delivery_',

    /**
     * Proof of Delivery report (CSV format)
     *
     * filename follows the format: 'Proof_of_Delivery_CSV_{date}.xlsx'
     */
    PROOF_OF_DELIVERY_CSV = 'Proof_of_Delivery_CSV_',

    /**
     * Adherence report
     *
     * filename follows the format: 'Raw_Data_Report_{date}.xlsx'
     */
    ADHERENCE = 'Raw_Data_Report_',

    /**
     * Instant Routes report
     *
     * filename follows the format: 'InstantRoutesReport{date}.xlsx'
     */
    INSTANT_ROUTES_REPORT = 'InstantRoutesReport'
}
