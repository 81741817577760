import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { ApiClient } from '~/api/types';
import { setSelectedClients as updateSelectedClients } from '~/reducers/selectedClientsSlice';
import { ClientSelector } from '~/ui';
import { useSelectedClients } from '~/hooks';

interface UseClientSelector {
    userHasMultiAccess: boolean;
    clientSelector?: JSX.Element;
    updateClientSelection: () => void;
    isClientSelected: boolean;
}

const useClientSelector = (): UseClientSelector => {
    const dispatch = useDispatch();
    const {
        userHasMultiAccess,
        allClients,
        selectedClients: initialSelectedClients
    } = useSelectedClients();
    const [selectedClients, setSelectedClients] = useState({
        ...initialSelectedClients
    });
    const clientProps = useMemo(() => {
        return Object.values(allClients).map((client) => {
            const { name, id } = client as ApiClient;
            return { name, id };
        });
    }, [allClients]);

    const allClientsSelectedLocally = useMemo(() => {
        const currentSelectedClientIds = Object.keys(selectedClients);
        return (
            currentSelectedClientIds.length === Object.keys(allClients).length
        );
    }, [allClients, selectedClients]);

    const isClientSelected = Boolean(Object.keys(selectedClients).length);

    useEffect(() => {
        setSelectedClients({ ...initialSelectedClients });
    }, [initialSelectedClients]);

    const handleClientSelect = useCallback((id) => {
        setSelectedClients({ [id]: true });
    }, []);

    const selectAllClients = useCallback(() => {
        if (allClientsSelectedLocally) {
            const firstClientId = clientProps[0].id;
            setSelectedClients({
                [firstClientId]: true
            });
        } else {
            const allActiveClients = Object.values(allClients).reduce(
                (aggregator: Record<string, boolean>, client) => {
                    const { id } = client as ApiClient;
                    aggregator[id] = true;
                    return aggregator;
                },
                {}
            );
            setSelectedClients(allActiveClients);
        }
    }, [clientProps, allClientsSelectedLocally, allClients]);

    const updateClientSelection = useCallback(() => {
        const isClientSelectionChanged = !isEqual(
            initialSelectedClients,
            selectedClients
        );
        if (!isClientSelectionChanged) {
            return;
        }
        dispatch(updateSelectedClients(Object.keys(selectedClients)));
    }, [selectedClients, initialSelectedClients, dispatch]);

    const clientSelector = useMemo(() => {
        return (
            <ClientSelector
                isMultiAccess={userHasMultiAccess}
                areAllClientsSelected={allClientsSelectedLocally}
                selectAllClients={selectAllClients}
                selectedClients={selectedClients}
                handleClientSelect={handleClientSelect}
                clientProps={clientProps}
            />
        );
    }, [
        userHasMultiAccess,
        allClientsSelectedLocally,
        selectAllClients,
        selectedClients,
        handleClientSelect,
        clientProps
    ]);

    return {
        userHasMultiAccess,
        clientSelector,
        isClientSelected,
        updateClientSelection
    };
};

export { useClientSelector };
