import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { zonesAPI } from '~/api/ZonesApi';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import fileUtils from '~/utils/file-utils';
import constants from '~/utils/constants';

const uploadZoneFile = (clientId, files, dispatch, t, queryClient) => {
    const fileReader = new FileReader();
    const [file] = files;

    const processIndicatorState = {
        message: t('uploadZoneModal.uploadingZoneFile'),
        type: constants.processIndicator.ZONE_UPLOAD,
        inProgress: true,
        error: false,
        position: 'center'
    };

    fileReader.onload = async (e) => {
        try {
            dispatch(addProcessIndicator(processIndicatorState));
            const data = JSON.parse(e.target.result);
            await zonesAPI.uploadZoneFile(clientId, data.features || data);

            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    message: t('uploadZoneModal.uploadedZoneFile'),
                    inProgress: false
                })
            );

            return queryClient.invalidateQueries(
                constants.reactQueryKeys.ZONES
            );
        } catch (error) {
            console.error(error);
            dispatch(
                addProcessIndicator({
                    ...processIndicatorState,
                    inProgress: false,
                    message: t('error:zoneUploadError'),
                    error: true
                })
            );
        }
    };
    fileReader.readAsText(file);
};

export const handlerFactory = (clientId, dispatch, t, queryClient) => () => {
    return {
        handleDragOver: (e) => e.preventDefault(),
        handleFilesSelected: (e) => {
            uploadZoneFile(clientId, e.target.files, dispatch, t, queryClient);
            e.target.value = null;
        },
        handleDrop: (e) => {
            const {
                dataTransfer: { clearData, files, items }
            } = e;
            e.preventDefault();

            uploadZoneFile(
                clientId,
                items ? fileUtils.getFilesFromDataTransferItems(items) : files,
                dispatch,
                t,
                queryClient
            );

            if (items) {
                items.clear();
            } else {
                clearData();
            }
        }
    };
};

export const useUploadZoneFile = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const mainClient = useSelector(selectMainClient);

    const { t } = useTranslation(['zoneManagement', 'error']);
    const clientId = mainClient?.id;

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const { handleDragOver, handleDrop, handleFilesSelected } = useMemo(
        handlerFactory(clientId, dispatch, t, queryClient),
        [clientId, t, dispatch, queryClient]
    );

    return {
        handleDragOver,
        handleDrop,
        handleFilesSelected
    };
};
