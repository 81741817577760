/* global H */
import { isNumber } from 'lodash';
import Polyline from '~/utils/map/decodeUtils/polyline';
import {
    HereMapsGroup,
    HereMapsMapInstance,
    HereMapsObject,
    HereMapsPolyline,
    HereMapsPolygon,
    SpatialStyle,
    HereMapsShapes
} from '~/components/HereMaps/types';
import { Coordinates } from '~/api/types';
import { MutableRefObject } from 'react';
import { ArrowStyle } from '~/components/MapEngineProvider/types';
import {
    getPolygon,
    highlightPolygon,
    unhighlightPolygon
} from '~/utils/map/hereMaps/polygonUtils';
import { MakePolygonDrawingOptions } from '~/utils/map/map-drawing-utils';
import { hextoRGB } from '~/utils/map/drawing/utils';
import { HereMapsConstants } from '~/utils/hereMapsConstants';

const MIN_POINTS = 2;

const { DEFAULT_POLYGON_FILL_OPACITY, DEFAULT_STROKE } =
    HereMapsConstants.polygons;

const decodePolylinePath = (encodedPath: string, precision = 5) => {
    const decodedPath = Polyline.decode(encodedPath, precision);
    return decodedPath.map((pair) => {
        const [lat, lng] = pair;
        return { lat, lng };
    });
};

const drawMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    mapInstance.addObject(object);
};

const removeMapObject = (
    object: HereMapsObject,
    mapInstance: HereMapsMapInstance
) => {
    if (!object || !mapInstance) {
        return;
    }
    try {
        mapInstance.removeObject(object);
    } catch (e) {
        console.error(e);
    }
};

const getMapObjectFromAll = (
    allMapObjects: Record<string, HereMapsShapes>,
    id: string
) => {
    return allMapObjects[id];
};

const removeAllMapObjects = (
    allMapObjects: Record<string, HereMapsShapes>,
    mapInstance: HereMapsMapInstance
) => {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        removeMapObject(mapObject, mapInstance);
    }
};

const getPolylineStyle = ({
    strokeColor,
    strokeWeight,
    lineDash,
    arrowStyle
}: {
    strokeColor?: string;
    strokeWeight?: number;
    lineDash?: number[];
    arrowStyle?: ArrowStyle;
}) => {
    const options = {
        ...(strokeColor && { strokeColor }),
        ...(isNumber(strokeWeight) && { lineWidth: strokeWeight }),
        ...(lineDash && { lineDash }),
        ...(arrowStyle && {
            arrowStyle: {
                ...arrowStyle,
                ...(strokeColor && { strokeColor })
            }
        })
    };
    return options;
};

const createPolyline = (
    path: Coordinates[],
    options: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
        arrowStyle?: ArrowStyle;
    }
) => {
    const { strokeColor, lineWidth, lineDash = [0], arrowStyle } = options;
    if ((path?.length || 0) < MIN_POINTS) {
        return null;
    }
    const linestring = new H.geo.LineString();
    path.forEach((point) => {
        linestring.pushPoint(point);
    });
    const polyline = new H.map.Polyline(linestring, {
        style: {
            lineWidth,
            strokeColor,
            lineDash
        },
        data: {
            arrowLine: false
        }
    });
    const group = new H.map.Group();
    group.addObject(polyline);
    if (arrowStyle) {
        const arrowLine = new H.map.Polyline(linestring, {
            style: arrowStyle as SpatialStyle,
            data: {
                arrowLine: true
            }
        });
        group.addObject(arrowLine);
    }
    return group;
};

const updatePolyline = (
    path: Coordinates[],
    lineRef: MutableRefObject<HereMapsGroup | null>,
    style: {
        strokeColor: string;
        lineWidth: number;
        lineDash?: number[];
        arrowStyle?: ArrowStyle;
    }
) => {
    const { arrowStyle, ...lineStyle } = style;

    if ((path?.length || 0) < MIN_POINTS) {
        return lineRef.current ? lineRef : null;
    }
    if (lineRef.current) {
        const linestring = new H.geo.LineString();
        path.forEach((point: Coordinates) => {
            linestring.pushPoint(point);
        });
        const objects = lineRef.current.getObjects();
        objects.forEach((object) => {
            (object as HereMapsPolyline).setGeometry(linestring);
            const data = object.getData();
            const { arrowLine } = data || {};
            if (arrowLine && arrowStyle) {
                (object as HereMapsPolyline).setStyle(
                    arrowStyle as SpatialStyle
                );
                return;
            }
            (object as HereMapsPolyline).setStyle(lineStyle);
        });
    } else {
        const polylineGroup = createPolyline(path, style);
        lineRef.current = polylineGroup;
    }
    return lineRef;
};

const emphasizePolygon = (polygon: HereMapsPolygon) => {
    highlightPolygon(polygon);
};

const unEmphasizePolygon = (polygon: HereMapsPolygon) => {
    unhighlightPolygon(polygon);
};

const makePolygon = (
    polygonPaths: Coordinates[],
    makePolygonOptions: MakePolygonDrawingOptions
) => {
    const { color } = makePolygonOptions;
    const rgbaColor = hextoRGB(color as string, DEFAULT_POLYGON_FILL_OPACITY);
    const config = {
        perimeterCoordinates: polygonPaths,
        color: rgbaColor,
        strokeColor: color as string,
        strokeWeight: DEFAULT_STROKE
    };
    return getPolygon(config);
};

export const hereMapsDrawingUtils = {
    updatePolyline,
    createPolyline,
    drawMapObject,
    removeMapObject,
    removeAllMapObjects,
    makePolygon,
    getPolylineStyle,
    decodePolylinePath,
    getMapObjectFromAll,
    emphasizePolygon,
    unEmphasizePolygon
};
