import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: null,
    reducers: {
        setCurrentUser: (state, action) => {
            return action.payload;
        },
        resetCurrentUser: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setCurrentUser, resetCurrentUser } = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) => state.currentUser;

export default currentUserSlice.reducer;
