import React from 'react';
import classNames from 'classnames';
import { Icon, TooltipButton } from '~/ui';
import { IconName } from '~/ui/components/Icon/IconNames';

import './MapViewSelector.scss';

interface MapViewSelectorProps {
    /**
     * SVG icon string ID
     */
    icon?: IconName;

    /**
     * Whether the selector is selected
     */
    isSelected?: boolean;

    /**
     * The text to display
     */
    text: string;

    /**
     * The click handler
     */
    onClick: () => void;
}
export const MapViewSelector = ({
    icon,
    isSelected,
    onClick,
    text
}: MapViewSelectorProps) => {
    const rootClassName = 'map-view-selector';
    const buttonClassName = classNames(rootClassName, '_ai-center', {
        [`${rootClassName}--selected`]: isSelected
    });

    return (
        <TooltipButton
            className={buttonClassName}
            data-testid={rootClassName}
            onClick={onClick}
        >
            <div className={`${rootClassName}__icon _jc-center _ai-center`}>
                <Icon icon={icon} color="galaxy-400" />
            </div>
            <span className={`${rootClassName}__text`}>{text}</span>
        </TooltipButton>
    );
};
