import { useMutation, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import VehicleProfileApi from '~/api/VehicleProfileApi';

interface UseDeleteVehicleProfileProps {
    queryKey: string;
}

export const useDeleteVehicleProfile = ({
    queryKey
}: UseDeleteVehicleProfileProps) => {
    const deleteVehicleProfile = async (id: string): Promise<void> => {
        await VehicleProfileApi.delete(id);
    };

    const queryClient = useQueryClient();

    const { isLoading, isError, isSuccess, mutateAsync, data } = useMutation(
        deleteVehicleProfile,
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(queryKey);
            }
        }
    );

    return useMemo(() => {
        return {
            isError,
            isLoading,
            isSuccess,
            mutateAsync
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [data, isError, isLoading, isSuccess, mutateAsync]);
};
