import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsSchedulerRunning } from '~/reducers/schedulerProgressSlice';

export const useSchedulerProgressModalVisibilityManager = ({
    showModal,
    hideModal,
    isVisible
}) => {
    const isSchedulerRunning = useSelector(selectIsSchedulerRunning);
    const isShowModal = isSchedulerRunning && !isVisible;
    const isHideModal = !isSchedulerRunning && isVisible;

    useEffect(() => {
        if (isShowModal) {
            showModal();
            return;
        }

        if (isHideModal) hideModal();
    }, [isShowModal, isHideModal, showModal, hideModal]);
};
