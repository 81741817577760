import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { VehicleApi } from '~/api/VehicleApi';
import { ApiVehicle } from '~/api/types';
import { addVehicle } from '~/reducers/vehiclesSlice';

import useToastContext from '../toastContext';

type ReturnPros = {
    addNewVehicle: (payload: ApiVehicle) => Promise<boolean>;
};

export const useAddNewVehicle = (): ReturnPros => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'error']);
    const { addToast } = useToastContext();

    const addNewVehicle = useCallback(async (payload: ApiVehicle) => {
        try {
            const {
                data: { data: vehicle }
            } = await VehicleApi.post(payload);

            dispatch(addVehicle(vehicle));
            addToast({
                message: t('common:vehicleAdded'),
                variant: 'success'
            });

            return true;
        } catch (e) {
            console.error(e);
            addToast({
                message: t('error:addNewVehicleError'),
                variant: 'error'
            });

            return false;
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return { addNewVehicle };
};
