import { useMutation } from 'react-query';
import TasksApi from '~/api/TasksApi';
import {
    AxiosApiResponse,
    ReactQuerySideEffects,
    IPostDriverAssignmentsResponse
} from '~/api/types';

type HookLiveStopReassignTasks = {
    /** whether the reassign function is busy */
    isReassignBusy: boolean;
    /** reassigns selected tasks from a route */
    reassignTasks: (
        taskIds: string[],
        driverId: string,
        sideEffects?: ReactQuerySideEffects
    ) => void;
};

type ParamsDoReassignTasksAsync = {
    /** the selected task IDs */
    taskIds: string[];
    /** the target driver ID */
    driverId: string;
};

/**
 * Implements reassign tasks for selected driver tasks
 *
 * @category Hooks
 * @module useLiveStopReassignTasks
 * @returns {HookLiveStopReassignTasks}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useLiveStopReassignTasks } from '~/hooks';
 *
 * // expose constant and methods
 * const { isReassignBusy, reassignTasks } = useLiveStopReassignTasks();
 *
 */
export const useLiveStopReassignTasks = (): HookLiveStopReassignTasks => {
    const { isLoading: isReassignBusy, mutateAsync: doReassignTasksAsync } =
        useMutation((payload: ParamsDoReassignTasksAsync): Promise<
            AxiosApiResponse<IPostDriverAssignmentsResponse>
        > => {
            const { taskIds, driverId } = payload;
            // TODO: @klaframboise replace legacyReassignTasks with reassignTasks when endpoint available
            // https://wisesys.atlassian.net/browse/DISP-550
            return TasksApi.legacyReassignTasks(taskIds, driverId);
        });

    /**
     * Reassign selected tasks to target driver
     *
     * @method reassignTasks
     * @param {string[]} taskIds - the selected task IDs
     * @param {string} driverId - the target driver ID
     * @param {ReactQuerySideEffects} sideEffects - side effect callbacks
     */
    const reassignTasks = async (
        taskIds: string[],
        driverId: string,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const {
            onSuccess: onSuccessReassignTasks,
            onError: onErrorReassignTasks
        } = sideEffects;

        try {
            const successData = await doReassignTasksAsync({
                taskIds,
                driverId
            });
            if (onSuccessReassignTasks) onSuccessReassignTasks(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            if (onErrorReassignTasks) onErrorReassignTasks(error);
        }
    };

    return {
        isReassignBusy,
        reassignTasks
    };
};
