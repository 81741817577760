import React from 'react';
import classNames from 'classnames';

import './manage-stop-control.scss';

function ManageStopControl({ children, className, ...extra }) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }
    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol'}
        >
            {children}
        </div>
    );
}

export { ManageStopControl };
