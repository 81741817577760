import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import { AssignmentTypes, DuplicateTaskModalFormProps } from '../types';
import './DuplicateTaskModalChooseAssignment.scss';

export const DuplicateTaskModalChooseAssignment = () => {
    const rootClassName = 'duplicate-task-modal-choose-assignment';
    const { t } = useTranslation('duplicateTaskModal');

    const radioData: RadioInputData<AssignmentTypes>[] = [
        {
            id: `${rootClassName}-${AssignmentTypes.CREATE_TASK}`,
            label: t('assignment.createTask'),
            value: AssignmentTypes.CREATE_TASK
        },
        {
            id: `${rootClassName}-${AssignmentTypes.ASSIGN_TASK_TO_DRIVER}`,
            label: t('assignment.assignTaskToDriver'),
            value: AssignmentTypes.ASSIGN_TASK_TO_DRIVER,
            disabled: true
        }
    ];

    const { values } = useFormikContext<DuplicateTaskModalFormProps>();

    return (
        <div className={`${rootClassName} _d-flex _fd-column`}>
            <span className={`${rootClassName}__title`}>
                {t('assignment.title')}
            </span>
            <RadioGroup
                radioData={radioData}
                value={values.assignmentType}
                name="assignmentType"
            />
        </div>
    );
};
