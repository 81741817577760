import React from 'react';
import classnames from 'classnames';
import { Icon } from '~/ui';

import './ClientSelectorItem.scss';

interface ClientSelectorItemProps {
    /**
     * The client display name
     */
    name: string;
    /**
     * The client ID
     */
    id: string;
    /**
     * Whether this client is selected
     */
    isSelected?: boolean;
    /**
     * The callback function when this client is clicked
     */
    onClick: (id: string) => void;
}

const ClientSelectorItem = ({
    name,
    id,
    onClick,
    isSelected = false
}: ClientSelectorItemProps): JSX.Element => {
    const rootClass = 'client-selector-item';
    const buttonClassNames = classnames(`${rootClass}`, '_d-flex');

    return (
        <button
            data-testid="client-selector-item"
            type="button"
            className={buttonClassNames}
            onClick={() => onClick(id)}
        >
            <div className={`${rootClass}__name`}>{name}</div>
            <Icon
                icon={isSelected ? 'radioChecked' : 'radioUnchecked'}
                size="l"
                color="neptune-400"
                className={`${rootClass}__icon`}
                data-testid={
                    isSelected
                        ? 'selected-client-item'
                        : 'unselected-client-item'
                }
            />
        </button>
    );
};

export { ClientSelectorItem };
