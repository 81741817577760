export const replacePickupWithDelivery = <T>(pickupTask: T): T => {
    if (typeof pickupTask !== 'object' || pickupTask === null) {
        return pickupTask;
    }

    if (Array.isArray(pickupTask)) {
        return pickupTask.map(replacePickupWithDelivery) as T;
    }

    return Object.fromEntries(
        Object.entries(pickupTask).map(([key, value]) => {
            const newKey = key.replace(/pickup/gi, (match) =>
                match[0] === 'P' ? 'Delivery' : 'delivery'
            );
            return [newKey, replacePickupWithDelivery(value)];
        })
    ) as T;
};
