import React, { SyntheticEvent, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import {
    addHiddenRoutes,
    removeHiddenRoutes
} from '~/reducers/hiddenRoutesSlice';
import { IconButton } from '~/ui';
import { getParentClientRouteIdsWithReloadIds } from '~/components/MapPage/usePlanStopsEffects/utils';
import { useIsPlanRouteHidden } from './useIsPlanRouteHidden';

interface UseRouteHideProps {
    clientRouteId: string;
    isHovered?: boolean;
    buttonClassName?: string;
}

interface UseRouteHideReturn {
    hideButton: JSX.Element;
    isHidden: boolean;
}

export const useHideParentRoute = ({
    clientRouteId,
    isHovered = false,
    buttonClassName
}: UseRouteHideProps): UseRouteHideReturn => {
    const dispatch = useDispatch();

    const [isToggleHovered, setIsToggleHovered] = useState(false);
    const { routesLevelData } = usePlanMapPropsContext();

    const topLevelClientRouteIdsWithReloads: Record<string, string[]> =
        useMemo(() => {
            return getParentClientRouteIdsWithReloadIds(routesLevelData);
        }, [routesLevelData]);
    const { isPlanRouteHidden } = useIsPlanRouteHidden();

    const isHidden = useMemo(() => {
        return isPlanRouteHidden({ clientRouteId });
    }, [clientRouteId, isPlanRouteHidden]);

    function toggleRouteVisibility(e: SyntheticEvent) {
        e.stopPropagation();
        const clientRouteIds = topLevelClientRouteIdsWithReloads[clientRouteId];
        if (isHidden) {
            dispatch(removeHiddenRoutes(clientRouteIds));
            return;
        }
        dispatch(addHiddenRoutes(clientRouteIds));
    }

    const hideButtonClassName = classnames(
        'icon-button_clear',
        buttonClassName
    );

    const getButton = () => {
        return (
            <IconButton
                className={hideButtonClassName}
                visible={isHidden || isHovered}
                icon={isHidden ? 'visibleOff' : 'visibleOn'}
                iconSize="s"
                iconColor={
                    isHidden || isToggleHovered ? 'galaxy-800' : 'galaxy-400'
                }
                onClick={toggleRouteVisibility}
                onMouseEnter={() => setIsToggleHovered(true)}
                onMouseLeave={() => setIsToggleHovered(false)}
                data-testid="route-item-hide-button"
            />
        );
    };

    return {
        isHidden,
        hideButton: getButton()
    };
};
