/**
 * Available report file action types
 */
export enum ReportFileActionTypes {
    /**
     * Export File
     */
    EXPORT = 'exportFile',
    /**
     * Download File
     */
    DOWNLOAD = 'downloadFile'
}
