import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
    DropDownWithInputBox,
    Icon,
    IconButton,
    RequiredFieldLabel,
    TooltipButton
} from '~/ui';
import { useGetCompartments } from '~/hooks/useGetCompartments';
import {
    CompartmentInput,
    useMultiCompartmentInputs
} from '~/hooks/useMultiCompartmentInputs';

import '../MultiCompartmentInputs/MultiCompartmentInputs.scss';

type MultiCompartmentInputsType = {
    title: string;
    isRequiredField?: boolean;
};

export default function FormikMultiCompartmentInputs({
    title,
    isRequiredField = true
}: MultiCompartmentInputsType): JSX.Element {
    const { t } = useTranslation('driverManagement');

    const { compartments } = useGetCompartments();
    const compartmentList = Object.values(compartments).map((compartment) => {
        return {
            text: compartment.label || '',
            unit: compartment.unit || '',
            id: compartment.id || ''
        };
    });

    const { values, setFieldValue } =
        useFormikContext<Record<string, Record<string, CompartmentInput>>>();

    const {
        displayedInputs,
        handleDeleteClick,
        handleInputChange,
        handleAddCompartmentClick
    } = useMultiCompartmentInputs(values.compartments);

    useEffect(() => {
        setFieldValue('compartments', displayedInputs);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [displayedInputs]);

    return (
        <div
            className="_fd-column multi-compartment-inputs"
            data-testid="multi-compartment-inputs"
        >
            <div className="_d-flex _jc-space-between _ai-center">
                {isRequiredField ? (
                    <RequiredFieldLabel label={title} />
                ) : (
                    <span className="multi-compartment-inputs__title">
                        {title}
                    </span>
                )}
                <TooltipButton
                    className="multi-compartment-inputs__add-button"
                    size={TooltipButton.sizes.MEDIUM}
                    data-testid="add-compartment-button"
                    onClick={handleAddCompartmentClick}
                >
                    <Icon icon="plus" size="xs" color="ocean" />
                    {t('addNewVehicleForm.compartment.addButton')}
                </TooltipButton>
            </div>
            <div className="multi-compartment-inputs__content _fd-column">
                {Object.values(displayedInputs).map((input) => (
                    <div
                        key={input.componentId}
                        className="_d-flex _ai-center _w-100 _p-relative multi-compartment-inputs__input-row"
                    >
                        <DropDownWithInputBox
                            id={input.componentId}
                            dropDownTitle={input.label}
                            dropDownSelectedItemId={input.selectedCompartmentId}
                            dropDownPlaceholder={t(
                                'addNewVehicleForm.compartment.dropdownPlaceholder'
                            )}
                            dropDownMenuItems={compartmentList}
                            inputBoxValue={input.capacity}
                            inputBoxPlaceholder={t(
                                'addNewVehicleForm.compartment.textboxPlaceholder'
                            )}
                            unitValue={input.unit}
                            onChange={handleInputChange}
                        />
                        {!input.isFirst && (
                            <IconButton
                                className="multi-compartment-inputs__delete-icon"
                                icon="delete"
                                iconColor="galaxy-500"
                                onClick={() =>
                                    handleDeleteClick(input.componentId)
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
