import { DateTime } from 'luxon';
import { DownloadableReportItem } from './DownloadableReportItem';
import { ApiAnalyticsAggregateDriversDownload } from '~/api/types';
import { GeneratedReportItem } from './GeneratedReportItem';

/**
 * DownloadableReports data class
 *
 * @category Data Classes
 *
 * @example
 * import { DownloadableReports } from '~/data-classes/report';
 *
 * const srcData = [];
 * const reports = new DownloadableReports(srcData);
 *
 */
export class DownloadableReports {
    /**
     * The API source data
     * @type {ApiAnalyticsAggregateDriversDownload[]}
     */
    private readonly initReports: ApiAnalyticsAggregateDriversDownload[];

    private readonly rawReports: Record<
        string,
        DownloadableReportItem | GeneratedReportItem
    >;

    private filtered: boolean;

    private filteredReports: (DownloadableReportItem | GeneratedReportItem)[];

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(initReports: ApiAnalyticsAggregateDriversDownload[]) {
        this.initReports = initReports;

        this.rawReports = this.initReports.reduce((rawReports, report) => {
            const reportItem = new DownloadableReportItem(report);
            rawReports[reportItem.key] = reportItem;
            return rawReports;
        }, {} as Record<string, DownloadableReportItem>);

        this.filteredReports = [];

        this.filtered = false;
    }

    get reports(): (DownloadableReportItem | GeneratedReportItem)[] {
        return this.filtered
            ? this.filteredReports
            : Object.values(this.rawReports);
    }

    get hasCoreReports(): boolean {
        return this.reports.some((report) => report.isCoreReport);
    }

    get hasCustomReports(): boolean {
        return this.reports.some((report) => report.isCustomReport);
    }

    addGeneratedReportItem(generatedReports: GeneratedReportItem[]): this {
        // reset the filter, in-case previously filtered
        this.filtered = false;
        generatedReports.forEach((report) => {
            this.rawReports[report.key] = report;
        });
        return this;
    }

    filterReportsByDates(filterReportDates: DateTime[] = []): this {
        this.filteredReports = Object.values(this.rawReports).filter((report) =>
            report.isWithinReportDates(filterReportDates)
        );
        this.filtered = true;
        return this;
    }

    groupReportsByDate(): Record<
        string,
        (DownloadableReportItem | GeneratedReportItem)[]
    > | null {
        const reportsByDate = this.reports.reduce((allReports, report) => {
            const reportDate = report.reportDate.toISODate();

            if (!allReports[reportDate]) {
                allReports[reportDate] = [];
            }

            allReports[reportDate].push(report);

            return allReports;
        }, {} as Record<string, (DownloadableReportItem | GeneratedReportItem)[]>);

        return Object.keys(reportsByDate).length > 0 ? reportsByDate : null;
    }

    groupReportsByType(): Record<
        string,
        (DownloadableReportItem | GeneratedReportItem)[]
    > | null {
        const reportsByType = this.reports.reduce((allReports, report) => {
            const reportType = report.type;

            if (!allReports[reportType]) {
                allReports[reportType] = [];
            }

            allReports[reportType].push(report);

            return allReports;
        }, {} as Record<string, (DownloadableReportItem | GeneratedReportItem)[]>);

        return Object.keys(reportsByType).length > 0 ? reportsByType : null;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiAnalyticsAggregateDriversDownload[]}
     */
    toJSON(): ApiAnalyticsAggregateDriversDownload[] {
        return this.initReports;
    }
}
