import React from 'react';

import { MapZoomControl } from '~/ui/components/MapZoomControl';

function ZoomControl({ map, showControls }) {
    const smallZoomStep = 1;

    function zoomIn() {
        if (map.zoom < map.maxZoom) {
            map.setZoom(map.zoom + smallZoomStep);
        }
    }

    function zoomOut() {
        if (map.zoom > map.minZoom) {
            map.setZoom(map.zoom - smallZoomStep);
        }
    }

    if (!showControls) {
        return null;
    }

    return <MapZoomControl zoomIn={zoomIn} zoomOut={zoomOut} />;
}

export default ZoomControl;
