import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '~/ui';

type Props = {
    className?: string;
    'data-testid': string;
    onClick: () => void;
};

export const DeleteButton = ({ className, onClick, ...extraProps }: Props) => {
    const { t } = useTranslation('common');
    return (
        <IconButton
            className={className}
            onClick={onClick}
            icon="delete"
            iconColor="galaxy-800"
            data-testid={extraProps['data-testid']}
            tooltipMessage={t('common:delete')}
            tooltipPlacement="top"
        />
    );
};
