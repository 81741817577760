import { createSelector, createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: {},
    reducers: {
        setClients: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setClients } = clientsSlice.actions;

export const selectClients = (state) => state.clients;

export const selectClientIds = createSelector(
    (state) => state.clients,
    (clients) => {
        return Object.keys(clients);
    }
);

export default clientsSlice.reducer;
