import { isArray } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

/**
 * The selected task IDs state
 */
type SelectedTaskIdsState = string[];

export const selectedTaskIdsSlice = createSlice({
    name: 'selectedTaskIds',
    initialState: [] as SelectedTaskIdsState,
    reducers: {
        addSelectedTaskId: (state, action) => {
            const hasTaskId = state.includes(action.payload);
            if (!hasTaskId) {
                state.push(action.payload);
            }
            return state;
        },
        removeSelectedTaskId: (state, action) => {
            const index = state.indexOf(action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
            return state;
        },
        setSelectedTaskIds: (state, action) => {
            return isArray(action.payload) ? action.payload : [action.payload];
        },
        resetSelectedTaskIds: (): SelectedTaskIdsState => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const {
    addSelectedTaskId,
    removeSelectedTaskId,
    setSelectedTaskIds,
    resetSelectedTaskIds
} = selectedTaskIdsSlice.actions;

export const selectSelectedTaskIds = (state: RootState): SelectedTaskIdsState =>
    state.selectedTaskIds;

export default selectedTaskIdsSlice.reducer;
