import { RefObject } from 'react';
import { Header } from '@tanstack/react-table';
import constants from '~/utils/constants';

import {
    Equipment,
    LiveDispatchTaskTableRowData,
    LiveStop,
    PlanRoute,
    PlanStop
} from '~/data-classes';

type StopArray = PlanStop[] | LiveStop[];

const {
    depots: { START_DEPOT, END_DEPOT },
    entityStates: { UNPLANNED }
} = constants;

interface IsTwoPart {
    /**
     * Whether this stop is a two-part task
     */
    isTwoPart?: boolean;
}

interface IsTwoPartAndDeliveryProps extends IsTwoPart {
    /**
     * Whether this stop is a delivery
     */
    isDelivery?: boolean;
}

interface IsTwoPartAndPickupProps extends IsTwoPart {
    /**
     * Whether this stop is a pickup
     */
    isPickup?: boolean;
}

interface GetTwoPartSetupProps {
    /**
     * A tupple that consists of `pickup` and `delivery` stop data
     *
     * The `pickup` data is expected to be first item in the array.
     *
     * The `delivery` data is expected to be second item in the array.
     */
    twoPartStops: StopArray;
    /**
     * The stop references
     */
    stopRefs?: Record<string, RefObject<unknown>>;
}

const isTwoPartAndDelivery = ({
    isTwoPart,
    isDelivery
}: IsTwoPartAndDeliveryProps = {}) => {
    return Boolean(isTwoPart && isDelivery);
};

const isTwoPartAndPickUp = ({
    isTwoPart,
    isPickup
}: IsTwoPartAndPickupProps) => {
    return Boolean(isTwoPart && isPickup);
};

export const groupTwoPartTasks = (stops: StopArray) => {
    const mappedStops = [];
    for (let i = 0; i < stops.length; i++) {
        const currentStop = stops[i];
        const isCurrentStopTwoPartAndPickup = isTwoPartAndPickUp(currentStop);
        const nextStopIndex = i + 1;
        const nextStop = stops[nextStopIndex];
        const isNextStopTwoPartAndDelivery = isTwoPartAndDelivery(nextStop);
        const isTwoPart =
            isCurrentStopTwoPartAndPickup && isNextStopTwoPartAndDelivery;

        if (!isTwoPart) {
            mappedStops.push({
                stop: currentStop
            });
            continue;
        }

        mappedStops.push({
            twoPartStops: [currentStop, nextStop]
        });
        i = nextStopIndex;
    }

    return mappedStops;
};

export const getTwoPartSetup = ({
    twoPartStops,
    stopRefs = {}
}: GetTwoPartSetupProps) => {
    const [pickupStopData, deliveryStopData] = twoPartStops;
    const pickupId = pickupStopData.id;
    const deliveryId = deliveryStopData.id;

    const pickup = {
        stopData: pickupStopData,
        stopRef: stopRefs[pickupId]
    };
    const delivery = {
        stopData: deliveryStopData,
        stopRef: stopRefs[deliveryId]
    };
    return { pickupId, deliveryId, pickup, delivery };
};

export const groupScheduleByDepots = (schedule: StopArray) => {
    const result = [];
    let subArray = [];

    for (const stop of schedule) {
        const { isDepot, stopName } = stop;

        const isStartDepot = isDepot && stopName === START_DEPOT;
        const isEndDepot = isDepot && stopName === END_DEPOT;

        if (isStartDepot && subArray.length > 0) {
            result.push(subArray);
            subArray = [];
        }

        subArray.push(stop);

        if (isEndDepot) {
            result.push(subArray);
            subArray = [];
        }
    }
    if (subArray.length > 0) {
        result.push(subArray);
    }

    return result;
};

export const getPreRoutePlanDrawerState = ({
    routes,
    areDriversAssignedWebColors,
    isPreRoutePlanningMode
}: {
    routes: PlanRoute[];
    areDriversAssignedWebColors: boolean;
    isPreRoutePlanningMode: boolean;
}) => {
    const isSomeRoutesWithTasks = routes.some(
        ({ hasTasks, routeId }) => hasTasks && routeId !== UNPLANNED
    );

    const areDriversAndTasksNotReady =
        !areDriversAssignedWebColors || !isSomeRoutesWithTasks;
    return isPreRoutePlanningMode && areDriversAndTasksNotReady;
};

export const checkIfLastColumnEntry = (
    header:
        | Header<LiveDispatchTaskTableRowData, unknown>
        | Header<Equipment, unknown>
        | undefined
) => {
    if (!header) return false;

    const { index, headerGroup } = header;
    if (!index || !headerGroup?.headers) return false;

    const lastEntryIndex = headerGroup?.headers.length - 1;
    const secondLastEntryIndex = headerGroup?.headers.length - 2;
    return index === lastEntryIndex || index === secondLastEntryIndex;
};
