import React from 'react';
import classNames from 'classnames';

import './manage-stop-control-modal.scss';
import { Icon, Text, TooltipButton } from '~/ui';

function ManageStopControlModal({
    modalTitle,
    onClickTitle = () => {},
    children,
    className,
    ...extra
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_modal _fd-column`;
        return classNames(defaultClassName, className);
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol_modal'}
        >
            {modalTitle && (
                <div
                    className="managestopcontrol_modal-header"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-header`
                            : 'managestopcontrol_modal-header'
                    }
                >
                    <TooltipButton
                        onClick={onClickTitle}
                        className="button_clear"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-header_button`
                                : 'managestopcontrol_modal-header_button'
                        }
                    >
                        <Icon
                            icon="chevronDown"
                            color="galaxy-800"
                            className="_t-rotate-90"
                        />
                        <Text
                            className="_text-2"
                            variant="14-normal"
                            color="galaxy-800"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-header_title`
                                    : 'managestopcontrol_modal-header_title'
                            }
                        >
                            {modalTitle}
                        </Text>
                    </TooltipButton>
                </div>
            )}
            {children && (
                <div
                    className="managestopcontrol_modal-content _d-flex _fd-column"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-content`
                            : 'managestopcontrol_modal-content'
                    }
                >
                    {children}
                </div>
            )}
        </div>
    );
}

export { ManageStopControlModal };
