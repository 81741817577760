import axios from 'axios';
import {
    ApiDriver,
    ApiDriverLocationUpdate,
    AxiosApiResponse,
    IApiResponse
} from './types';
import generalUtils from '~/utils/general-utils';
import { DateTime } from 'luxon';

/**
 * Possible errors for /drivers API
 * @category API
 */
export enum DriversApiErrors {
    MISSING_DRIVER_ID = 'Missing driverIds parameter',
    INVALID_DRIVER_ID = 'Invalid driverIds parameter, must be an array of UUID strings',
    INVALID_AFTER = 'Invalid after parameter, must be ISO-formatted date-time string (eg 2022-12-06T00:30:00.000Z)'
}

/**
 * Implementations of the API methods under the `/drivers` path
 *
 * @category API
 */
export default class DriversApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/drivers';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'drivers';

    static get(): Promise<AxiosApiResponse<ApiDriver[]>> {
        return axios.get<IApiResponse<ApiDriver[]>>(this.path, {
            params: {
                extent: 'vehicle',
                limit: 1000
            }
        });
    }

    static getLocationUpdates(
        driverIds: string[],
        after?: string
    ): Promise<AxiosApiResponse<ApiDriverLocationUpdate>> {
        if (!driverIds) {
            return Promise.reject(DriversApiErrors.MISSING_DRIVER_ID);
        }

        const validIds =
            Array.isArray(driverIds) &&
            !!driverIds.length &&
            driverIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(DriversApiErrors.INVALID_DRIVER_ID);
        }

        const params = {
            driver_ids: driverIds,
            after: '',
            time: ''
        };

        if (after) {
            const validAfter = DateTime.fromISO(after).isValid;
            if (!validAfter) {
                return Promise.reject(DriversApiErrors.INVALID_AFTER);
            }
            params.after = after;
        }

        if (generalUtils.isFlaggedFeatureDisplayed()) {
            params.time = 'millisecond';
        }

        return axios.get<IApiResponse<ApiDriverLocationUpdate>>(
            `${this.path}/locations`,
            {
                params
            }
        );
    }
}
