import { isPlainObject, pick } from 'lodash';
import Color from 'colorjs.io';

import { store } from '~/store';
import { setWebColorsLastIndex, setWebColors } from '~/reducers/webColorsSlice';
import { theme } from '~/ui';
import allWebColors from './webColors';

/**
 * Color Utilities
 *
 * @category Utils
 * @module utils/colorUtils
 *
 * @example
 * import { colorUtils } from '~/utils/color-utils';
 */

/** @typedef {import('~/api/types').WebColor} WebColor */
/** @typedef {import('~/api/types').AssignedWebColor} AssignedWebColor */

/**
 * Retrieves the default preset web color object
 *
 * @return {WebColor} the default web color object
 */
function getDefaultWebColor() {
    return {
        fgColor: theme.colors.comet,
        bgColor: theme.colors['galaxy-500']
    };
}

/**
 * Retrieves the default preset assigned web color object
 *
 * @return {AssignedWebColor} the default assigned web color object
 */
function getDefaultAssignedWebColor() {
    const { fgColor: color, bgColor: backgroundColor } = getDefaultWebColor();
    return {
        color,
        backgroundColor
    };
}

/**
 * Retrieves the last index value used to assign web colors from redux store
 *
 * @return {Number} the last index value
 */
function getStoreWebColorsLastIndex() {
    const { webColors } = store.getState();
    return webColors.lastIndex;
}

/**
 * Retrieves all assigned web colors from redux store
 *
 * @return {Object} an object composed of web color objects
 */
function getStoreWebColors() {
    const { webColors } = store.getState();
    return webColors.assignedColors;
}

/**
 * Tests redux store for web colors
 *
 * @return {Boolean} the result of the test
 */
function hasStoreWebColors() {
    const webColors = getStoreWebColors();
    return isPlainObject(webColors) && Object.keys(webColors).length > 0;
}

/**
 * Retrieves preset colors from the `allWebColors` array
 *
 * Should the provided `index` param be greater than total preset colors,
 * color selection will rotate back to the top.
 *
 *
 * @param {Number} index the array index to retrieve from the `webColors` array
 * @return {WebColor} a web color object
 */
function getWebColorByIndex(index) {
    const webColorsLength = allWebColors.length;
    const newColor = allWebColors[index % webColorsLength];

    return newColor;
}

/**
 * Retrieves web colors assigned to target ID from redux store
 *
 *
 * @param {String} id the ID the assigned web colors are set against
 * @param {AssignedWebColor} [userAssignedWebColor] the desired assigned web colors to use if not available
 * @return {AssignedWebColor} an assigned web color object
 */
function getWebColorsForId(id, userAssignedWebColor) {
    const webColors = getStoreWebColors();
    const defaultWebColor =
        userAssignedWebColor || getDefaultAssignedWebColor();

    // the redux store may have other properties set with the target ID
    // pick only the relevant properties for the return to match `AssignedWebColor` typedef
    return webColors[id]
        ? pick(webColors[id], ['color', 'backgroundColor'])
        : defaultWebColor;
}

/**
 * Set web colors to target ID into redux store
 *
 * @private
 * @param {Object} payload the payload object
 * @param {Object} payload.id the assigned ID to set against
 * @param {AssignedWebColor} payload.colors the assigned web colors to store
 */
function setWebColorsForId(payload) {
    store.dispatch(setWebColors(payload));
}

/**
 * Dynamically assign web colors to target ID into redux store
 *
 * @private
 * @param {String} id the assigned ID to set against
 * @param {String} idType the ID type, helps identify what the color is being assigned to
 * @param {AssignedWebColor} [assignedWebColors] the desired assigned web colors to store
 * @return {AssignedWebColor} an assigned web color object
 */
function assignWebColors(id, idType, assignedWebColors) {
    const payload = { id, type: idType, colors: assignedWebColors };

    // dynamically assign colors if not provided
    if (!assignedWebColors) {
        const index = getStoreWebColorsLastIndex() + 1;
        const webColor = getWebColorByIndex(index);
        payload.colors = {
            color: webColor.fgColor,
            backgroundColor: webColor.bgColor
        };

        // set to redux store for next use
        store.dispatch(setWebColorsLastIndex(index));
    }

    setWebColorsForId(payload);

    return payload.colors;
}

/**
 * Dynamically assign web colors to a driver
 *
 * @param {String} clientDriverId the client-driver ID to set against
 * @param {AssignedWebColor} [assignedWebColors] the desired assigned web colors to store
 * @return {AssignedWebColor} an assigned web color object
 */
function assignWebColorsToDriver(clientDriverId, assignedWebColors) {
    return assignWebColors(clientDriverId, 'driver', assignedWebColors);
}

/**
 * Dynamically assign web colors to a route
 *
 * @param {String} clientRouteId the client-route ID to set against
 * @param {AssignedWebColor} [assignedWebColors] the desired assigned web colors to store
 * @return {AssignedWebColor} an assigned web color object
 */
function assignWebColorsToRoute(clientRouteId, assignedWebColors) {
    return assignWebColors(clientRouteId, 'route', assignedWebColors);
}

/**
 * Tests if a given ID was assigned a web color
 *
 * @param {String} id the ID to test against
 * @return {Boolean} the result of the test
 */
function isWebColorAssignedForId(id) {
    const webColors = getStoreWebColors();
    return isPlainObject(webColors) && Boolean(webColors[id]);
}

/**
 * Selects a complementing contrasting color
 *
 * @param {String} baseColor - color to calculate the contrasting color for
 * @return {String} contrasting color as hex value
 */
const getContrastColor = (baseColor) => {
    try {
        const base = new Color(baseColor);
        const contrastOptions = ['#09101A', '#ffffff'];
        let pickedContrast;
        let contrastFactor = 0;
        contrastOptions.forEach((option) => {
            const contrastColor = new Color(option);
            const factor = base.contrastWCAG21(contrastColor);
            if (factor > contrastFactor) {
                contrastFactor = factor;
                pickedContrast = option;
            }
        });
        return pickedContrast;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

export const colorUtils = {
    getDefaultWebColor,
    getDefaultAssignedWebColor,
    getStoreWebColorsLastIndex,
    getStoreWebColors,
    hasStoreWebColors,
    getWebColorByIndex,
    getWebColorsForId,
    assignWebColorsToDriver,
    assignWebColorsToRoute,
    isWebColorAssignedForId,
    getContrastColor
};
