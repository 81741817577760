/**
 * Enum of map route modes
 */
export enum MapRouteModes {
    EMPTY = 'empty',
    PLAN = 'plan',
    DISPATCHED = 'dispatched',
    COMPLETED = 'completed'
}

/**
 * Enum of map marker modes
 */
export enum MapMarkerModes {
    STOPS_CLUSTERS = 'stops-clusters',
    ROUTES = 'routes'
}
