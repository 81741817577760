import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip } from '~/ui';
import { useAddTaskModalContext } from '../AddTaskModalContext';
import type { Note as NoteType } from '../utils/addTaskModalUtils';

type OnDivClick = NonNullable<React.ComponentProps<'div'>['onClick']>;
type OnDivKeyUp = NonNullable<React.ComponentProps<'div'>['onKeyUp']>;

type OnButtonClick = NonNullable<React.ComponentProps<'button'>['onClick']>;

export const Notes: React.FC = () => {
    const {
        clientTask: { notes },
        onNotesChange
    } = useAddTaskModalContext();
    const { t } = useTranslation(['addTask']);
    const [selectedNoteIndex, setSelectedNoteIndex] = useState(0);

    if (selectedNoteIndex > notes.length - 1) {
        setSelectedNoteIndex(notes.length - 1);
    }

    return (
        <>
            {notes.map((note, i) => {
                const onNoteChange = (newNote: NoteType) => {
                    const notesClone = [...notes];
                    notesClone[i] = newNote;
                    onNotesChange(notesClone);
                };

                const onClick = () => {
                    setSelectedNoteIndex(i);
                };

                const onDeleteClick: OnButtonClick = (e) => {
                    e.stopPropagation();

                    /**
                     * @TODO For time's sake, we temporarily use `confirm` as the modal confirmation
                     * Come back to this and replace with a custom modal UI
                     */
                    /* eslint-disable-next-line no-restricted-globals, no-alert */
                    const shouldDelete = confirm(
                        t('notesTab.confirmDeleteNoteMessage')
                    );

                    if (shouldDelete) {
                        const notesClone = [...notes];
                        notesClone.splice(i, 1);
                        onNotesChange(notesClone);
                    }
                };

                const onKeyUp: OnDivKeyUp = ({ key }) => {
                    if (key === 'Enter') {
                        setSelectedNoteIndex(i);
                    }
                };

                /**
                 * Notes do not have a stable identifier that we can use for a react array key
                 * Stringifying the note contents can lead to duplicate keys, so for now array index is the
                 * best option
                 *
                 * New notes are always added to the end of the array, and no sorting functionality is
                 * currently possible
                 */
                return (
                    <Note
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={i}
                        note={note}
                        onNoteChange={onNoteChange}
                        isOpen={selectedNoteIndex === i}
                        onClosedNoteClick={onClick}
                        onClosedNoteKeyUp={onKeyUp}
                        onDeleteClick={onDeleteClick}
                    />
                );
            })}
        </>
    );
};

export const closedNoteContainerTestId = 'closed-note-container_testId';
export const closedNoteTitleTestId = 'closed-note-title_testId';
export const closedNoteContentTestId = 'closed-note-content-testId';

export const openNoteContainerTestId = 'open-note-container_testId';
export const noteTitleInputTestId = 'note-title-input_testId';
export const noteContentInputTestId = 'note-content-input_testId';

export const deleteNoteBtnTestId = 'delete-note-btn_testId';

export interface NoteProps {
    note: NoteType;
    onNoteChange: (note: NoteType) => void;
    isOpen?: boolean;
    onClosedNoteClick?: OnDivClick;
    onClosedNoteKeyUp?: OnDivKeyUp;
    onDeleteClick?: OnButtonClick;
}

export function Note({
    note,
    onNoteChange,
    isOpen,
    onClosedNoteClick,
    onClosedNoteKeyUp,
    onDeleteClick
}: NoteProps) {
    const { t } = useTranslation(['addTask']);

    const genOnNoteFieldChange = useCallback(
        (field: keyof NoteType) => {
            return ({ target: { value } }: { target: { value: string } }) => {
                return onNoteChange({
                    ...note,
                    [field]: value
                });
            };
        },
        [onNoteChange, note]
    );

    const onNoteTitleInputChange = useMemo(
        () => genOnNoteFieldChange('title'),
        [genOnNoteFieldChange]
    );

    const onNoteContentInputChange = useMemo(
        () => genOnNoteFieldChange('content'),
        [genOnNoteFieldChange]
    );

    if (!isOpen) {
        return (
            <div
                className="closed-note-container"
                onClick={onClosedNoteClick}
                onKeyUp={onClosedNoteKeyUp}
                role="menuitem"
                tabIndex={0}
                data-testid={closedNoteContainerTestId}
            >
                <div className="closed-note-left-container">
                    <div
                        className="closed-note-title"
                        data-testid={closedNoteTitleTestId}
                    >
                        {note.title ??
                            t('notesTab.fields.notes.title.placeholder')}
                    </div>
                    <div
                        className="closed-note-content"
                        data-testid={closedNoteContentTestId}
                    >
                        {note.content ??
                            t('notesTab.fields.notes.content.placeholder')}
                    </div>
                </div>
                <div className="closed-note-right-container">
                    <Tooltip content={t('common:delete')} placement="top">
                        <Button
                            type="link"
                            onClick={onDeleteClick}
                            data-testid={deleteNoteBtnTestId}
                        >
                            <Icon icon="delete" color="galaxy-800" />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        );
    }

    return (
        <div
            className="open-note-container"
            data-testid={openNoteContainerTestId}
        >
            <input
                className="note-title-input"
                value={note.title ?? ''}
                onChange={onNoteTitleInputChange}
                placeholder={t('notesTab.fields.notes.title.placeholder')}
                data-testid={noteTitleInputTestId}
            />
            <textarea
                className="note-content-textarea"
                value={note.content ?? ''}
                onChange={onNoteContentInputChange}
                placeholder={t('notesTab.fields.notes.content.placeholder')}
                data-testid={noteContentInputTestId}
            />
        </div>
    );
}
