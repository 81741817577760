import { useMutation, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { ApiVehicleProfile, VehicleProfileCreatePayload } from '~/api/types';
import VehicleProfileApi from '~/api/VehicleProfileApi';

interface UseFetchVehicleProfilesProps {
    queryKey: string;
}

export const useCreateVehicleProfile = ({
    queryKey
}: UseFetchVehicleProfilesProps) => {
    const createVehicleProfile = async (
        payload: VehicleProfileCreatePayload
    ): Promise<ApiVehicleProfile> => {
        const {
            data: { data }
        } = await VehicleProfileApi.post(payload);
        return data;
    };

    const queryClient = useQueryClient();

    const { isLoading, isError, isSuccess, mutateAsync, data } = useMutation(
        createVehicleProfile,
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(queryKey);
            }
        }
    );

    return useMemo(() => {
        const vehicleProfile = data ?? null;

        return {
            isError,
            isLoading,
            isSuccess,
            mutateAsync,
            vehicleProfile
        };
    }, [data, isError, isLoading, isSuccess, mutateAsync]);
};
