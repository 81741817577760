import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import addressAPI from '~/api/AddressApi';
import constants from '~/utils/constants';
import { addToast } from '~/reducers/toastsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

export const useFetchMainClientAddress = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('error');
    const mainClient = useSelector(selectMainClient);
    const mainClientAddressId = mainClient?.address;

    return useQuery(
        [constants.reactQueryKeys.ADDRESS_BY_ID, mainClientAddressId],
        async () => {
            if (!mainClientAddressId) {
                console.warn('no main client');

                return;
            }

            try {
                const { data } = await addressAPI.get(mainClientAddressId);

                return data;
            } catch (error) {
                console.warn(error);
                dispatch(
                    addToast({
                        message: t('clientAddressError'),
                        variant: 'warning'
                    })
                );
            }
        }
    );
};
