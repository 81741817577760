import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const bookingMetricsSlice = createSlice({
    name: 'bookingMetrics',
    initialState: {},
    reducers: {
        setBookingMetrics: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setBookingMetrics } = bookingMetricsSlice.actions;

export const selectBookingMetrics = (state) => state.bookingMetrics;

export default bookingMetricsSlice.reducer;
