import { useCallback } from 'react';
import dateUtils from '~/utils/date-utils';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectDate } from '~/reducers/selectedDateSlice';

interface ShiftTime {
    start: string;
    end: string;
}

export const useIsOutOfService = () => {
    const mainClient = useSelector(selectMainClient);
    const { timezone } = mainClient || {};
    const selectedDate = useSelector(selectDate);
    const weekday = dateUtils.getIntWeekDay(selectedDate);

    const isOutOfService = useCallback(
        ({ driverId, shiftTimesByDayOfWeek }) => {
            const isDriverOutOfService = () => {
                if (!driverId) return true;

                function isDriverShiftActiveNow() {
                    return shiftTimesByDayOfWeek[weekday]?.some(
                        (shiftTime: ShiftTime) =>
                            dateUtils.checkIfCurrentTimeIncluded(
                                shiftTime.start,
                                shiftTime.end,
                                timezone
                            )
                    );
                }

                const driverHasNoShiftsToday =
                    !shiftTimesByDayOfWeek?.[weekday]?.length;
                const driverHasNoShifts = !Object.keys(shiftTimesByDayOfWeek)
                    .length;
                const driverHasNoShiftsForCurrentTime =
                    !driverHasNoShiftsToday && !isDriverShiftActiveNow();
                return (
                    driverHasNoShifts ||
                    driverHasNoShiftsToday ||
                    driverHasNoShiftsForCurrentTime
                );
            };

            const isVehicleOutOfService = !driverId;

            return {
                isDriverOutOfService: isDriverOutOfService(),
                isVehicleOutOfService
            };
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [timezone, selectedDate]
    );

    return {
        isOutOfService
    };
};
