import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRunRoutePlanner } from '~/components/MapPage/PlanStopsControl/useRunRoutePlanner';
import { hasAssignedStops } from '~/components/MapPage/PlanStopsControl/utils';
import ResequenceModalStopList from '~/components/MapPage/ResequenceModal/ResequenceModalStopList';
import { TextWithLineBreaks } from '~/components/TextWithLineBreaks';
import {
    useIntermodalFeatures,
    useOnDemandDispatchUnassignTasks,
    useToastContext,
    useUnassignStops
} from '~/hooks';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';
import {
    replaceSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import {
    ManageStopControlMenu,
    ManageStopControlMultiple,
    StopModalMenuButton
} from '~/ui';
import constants from '~/utils/constants';
import { mapPlanStopUtils } from '~/utils/map';
import { MarkEndOfShiftControl } from '../../MarkEndOfShiftControl';
import { useUnarriveSelectedStopModal } from '../useUnarriveSelectedStopModal';
import { CreateTripButton } from '../CreateTripButton';

import './MultiStopsControl.scss';

function MultiStopsControl({
    selectedPlanStops,
    stopIdPropertyName,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onSwitchView,
    onClickFooterButton,
    isTwoPartTask,
    isPlanStopControl
}) {
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const mainClient = useSelector(selectMainClient);

    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const mapRouteMode = useSelector(selectMapRouteMode);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const {
        unarriveSelectedStopModal: UnarriveSelectedStopModal,
        onShowModal
    } = useUnarriveSelectedStopModal();

    const isAnyCompleted = selectedPlanStops.some(
        ({ isCompleted }) => isCompleted
    );

    const { handleClickRunRoutePlanner, ReRunSchedulerModal } =
        useRunRoutePlanner();
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { enableUnarriveStops, enableEndOfShift } = useIntermodalFeatures();

    const { addToast } = useToastContext();

    const isPlannedStopsSelected = hasAssignedStops(selectedMapStopIds);
    const [endOfShiftTask] = selectedPlanStops.filter(
        (selectedStop) => selectedStop.type === constants.taskTypes.DELIVERY
    );

    const pickupTaskStatus = selectedPlanStops
        .filter((planStop) => planStop.type === constants.taskTypes.PICKUP)
        .map((planStop) => planStop.status);

    const showUnarriveButton =
        enableUnarriveStops &&
        isLiveStopControl &&
        isTwoPartTask &&
        pickupTaskStatus[0] === 1;
    const showReassignButton =
        [constants.mapRouteModes.PLAN, constants.mapRouteModes.EMPTY].includes(
            mapRouteMode
        ) && !showUnarriveButton;
    const shouldRenderButton = isPlanStopControl && !isPlannedStopsSelected;
    const showLiveStopUnassignButton =
        isLiveStopControl && !showUnarriveButton && !isAnyCompleted;
    const showDispatchCancelButton =
        isLiveStopControl &&
        mainClient?.preferences?.showDispatchCancelButton &&
        !showUnarriveButton &&
        !isAnyCompleted;
    const showMarkEndOfShiftButton =
        isLiveStopControl && isTwoPartTask && enableEndOfShift;
    const showPlanStopUnassignButton =
        !isLiveStopControl && !showUnarriveButton && !isAnyCompleted;
    const showResequenceButton = !showUnarriveButton && !isAnyCompleted;
    const showCreateTripButton = isPlanStopControl;

    function _handleClickLiveStopUnassign() {
        unassignTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    const handleClickLiveStopUnarrive = () => {
        onShowModal(selectedPlanStops[0]);
    };

    function _handleClickLiveStopCancel() {
        cancelTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    const _handleDrop = (e, index) => {
        dispatch(
            replaceSelectedMapStop(
                mapPlanStopUtils.processStopsReorder(
                    e,
                    selectedPlanStops,
                    stopIdPropertyName,
                    dragStartIndex,
                    index,
                    isOpenUnassignedTasksDrawer
                )
            )
        );
    };

    function _getStopList() {
        return selectedPlanStops.map((selectedStop, index) => (
            <ResequenceModalStopList
                key={selectedStop[stopIdPropertyName]}
                stopIdPropertyName={stopIdPropertyName}
                selectedStop={selectedStop}
                handleDragStart={() => setDragStartIndex(index)}
                handleDragOver={(e) => e.preventDefault()}
                handleDrop={(e) => _handleDrop(e, index)}
            />
        ));
    }

    return (
        <ManageStopControlMultiple
            className="multi-stops-control"
            controlTitle={t('PlanStopsControl.title.no_of_stops_selected', {
                length: selectedMapStopIds.length
            })}
            controlHelp={t('PlanStopsControl.title.selection_info')}
            stopList={_getStopList()}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
            isTwoPartTask={isTwoPartTask}
        >
            {showResequenceButton && (
                <ManageStopControlMenu>
                    <StopModalMenuButton
                        disabled={disableResequencing}
                        buttonIcon="iconReorder"
                        buttonText={t('PlanStopsControl.button.resequence')}
                        onClick={() =>
                            onSwitchView(
                                constants.stopsControl.views.RESEQUENCE
                            )
                        }
                        showButtonArrow
                        data-testid="button-resequence"
                    />
                </ManageStopControlMenu>
            )}
            <div className="_d-flex _ai-center multi-stops-control__selected-stops-info">
                <span className="_text-3-alt">
                    {t('PlanStopsControl.title.selected_stops_info')}
                </span>
            </div>
            <ManageStopControlMenu>
                {/**
                 * @todo remove `disabled` once AT2-437 and AT2-438 are resolved
                 * @see [Add reassign API]{@link https://wisesys.atlassian.net/browse/AT2-437}
                 * @see [Add reassign hook]{@link https://wisesys.atlassian.net/browse/AT2-438}
                 */}
                {showReassignButton && (
                    <StopModalMenuButton
                        buttonIcon="iconRouteFill"
                        buttonText={t('PlanStopsControl.button.reassign')}
                        showButtonArrow
                        onClick={() =>
                            onSwitchView(constants.stopsControl.views.REASSIGN)
                        }
                        data-testid="button-reassign"
                    />
                )}
                {showLiveStopUnassignButton && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-live-stop-unassign_button"
                    />
                )}

                {showDispatchCancelButton && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                        data-testid="button-cancel"
                    />
                )}
                {showMarkEndOfShiftButton && (
                    <MarkEndOfShiftControl stopData={endOfShiftTask} />
                )}
                {showPlanStopUnassignButton && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClick={unassignStops}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-plan-stop-unassign_button"
                    />
                )}
                {showUnarriveButton && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopUnarrive}
                        buttonIcon="locationOff"
                        buttonText={t('PlanStopsControl.button.unarrive')}
                        data-testid="button-live-stop-unarrive"
                    />
                )}
                {showCreateTripButton && <CreateTripButton />}
            </ManageStopControlMenu>
            {shouldRenderButton && (
                <ManageStopControlMenu className="multi-stops-control__control-menu">
                    <StopModalMenuButton
                        onClick={handleClickRunRoutePlanner}
                        className="multi-stops-control__menu-button"
                        buttonIcon="autoplay"
                        buttonIconColor="white"
                        buttonText={
                            <TextWithLineBreaks
                                text={t(
                                    'PlanStopsControl.button.runRoutePlanner'
                                )}
                            />
                        }
                        data-testid="run-route-planner-menu-button"
                    />
                </ManageStopControlMenu>
            )}
            {ReRunSchedulerModal}
            <UnarriveSelectedStopModal />
        </ManageStopControlMultiple>
    );
}

export default MultiStopsControl;
