import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    IApiResponse,
    SupplementalData,
    ExportIntegrationResponseData,
    ExportThirdPartyRouteResponseData,
    ThirdPartySystemSupplementalData
} from './types';

/**
 * Implementations of API methods under the /admin path
 *
 * @category API
 */
export default class AdminApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/admin';

    /**
     * Sends a POST /admin?action=upload_schedule_file to upload a schedule file
     *
     * @param {string} clientId target client
     * @param {File} file to upload
     * @returns {IApiResponse}
     */
    static uploadScheduleFile(
        clientId: string,
        file: File
    ): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!file) {
            return Promise.reject('Missing file');
        }

        const formData = new FormData();
        formData.append('file', file);

        return axios.post<IApiResponse>(
            `${this.PATH}?action=upload_schedule_file`,
            formData,
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    /**
     * Sends a POST /admin?action=upload_driver_file to upload drivers file
     *
     * @param {string} clientId target client
     * @param {File} file to upload
     * @returns {IApiResponse}
     */
    static uploadDriverFile(
        clientId: string,
        file: File
    ): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!file) {
            return Promise.reject('Missing file');
        }

        const formData = new FormData();
        formData.append('file', file);

        return axios.post<IApiResponse>(
            `${this.PATH}?action=upload_driver_file`,
            formData,
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    /**
     * Sends a POST /admin?action=upload_third_party_file
     *
     * @param {string} staticIds static route ids - separated by commas (100, 200)
     * @param {Array<string>} dates start and end dates range
     * @returns {IApiResponse}
     */
    static uploadThirdPartyFile(
        staticIds: string,
        dates: string[]
    ): Promise<AxiosApiResponse> {
        if (!dates || !Array.isArray(dates) || !dates.length) {
            return Promise.reject('dates not provided');
        }

        return axios.post<IApiResponse>(
            `${this.PATH}?action=upload_third_party_file`,
            { staticIds, dates }
        );
    }

    /**
     * Sends a POST /admin?action=export_order_integration_file
     *
     * @param {Array<string>} date route plan date
     * @param {SupplementalData} supplementalData
     * @returns {ExportIntegrationResponseData}
     */
    static exportOrderIntegrationFile(
        date: string[],
        supplementalData: SupplementalData
    ): Promise<ExportIntegrationResponseData> {
        if (!date?.length || !Array.isArray(date)) {
            return Promise.reject('Dates not provided, No Data found.');
        }

        return axios.post(`${this.PATH}?action=export_order_integration_file`, {
            date,
            supplementalData
        });
    }

    /**
     * Sends a POST /admin?action=export_third_party_route
     *
     * @param {Array<string>} dates route plan date
     * @returns {ExportThirdPartyRouteResponseData}
     */
    static exportThirdPartyRoutes(
        dates: string[],
        supplementalData?: ThirdPartySystemSupplementalData
    ): Promise<ExportThirdPartyRouteResponseData> {
        if (!dates?.length || !Array.isArray(dates)) {
            return Promise.reject('Dates not provided, No Data found.');
        }

        return axios.post(`${this.PATH}?action=export_third_party_route`, {
            dates,
            ...(supplementalData && { supplementalData })
        });
    }

    /**
     * Sends a POST /admin?action=reset_schedule to reset schedule
     *
     * @param {string} clientId target client id
     * @returns {IApiResponse}
     */
    static resetSchedule(clientId: string): Promise<AxiosApiResponse> {
        if (!clientId) return Promise.reject('Missing client id');

        return axios.post<IApiResponse>(`${this.PATH}?action=reset_schedule`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }
}
