import React from 'react';
import classNames from 'classnames';

import './routecard-marker-no-button.scss';

interface RouteCardMarkerNoButtonProps {
    className?: string;
    initials: string;
    colorCSS: Pick<React.CSSProperties, 'color' | 'backgroundColor'>;
    'data-testid'?: string;
}

/**
 * A variant of RouteCardMarker that doesn't use a button element.
 * Useful for when trying to nest a route card marker in another button.
 */
const RouteCardMarkerNoButton = ({
    className,
    initials,
    colorCSS,
    'data-testid': dataTestid
}: RouteCardMarkerNoButtonProps) => {
    function getClassName() {
        return classNames('routecard-marker_no-button', className);
    }
    return (
        <div
            style={{ ...colorCSS }}
            className={getClassName()}
            data-testid={dataTestid || 'routecard-marker_no-button'}
        >
            {initials}
        </div>
    );
};

export default RouteCardMarkerNoButton;
