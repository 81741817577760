import { useMemo } from 'react';
import root from 'window-or-global';
import { useIsEmbeddedInIframe } from '~/hooks';
import { allowedWhiteLabeledDomains } from './allowedWhiteLabeledDomains';

export const useIsHideWiseLogo = () => {
    const isEmbeddedInIframe = useIsEmbeddedInIframe();
    const referrer = root.document?.referrer ?? '';

    return useMemo(() => {
        return (
            isEmbeddedInIframe &&
            !allowedWhiteLabeledDomains.some(
                (domain) => referrer.indexOf(domain) === 0
            )
        );
    }, [isEmbeddedInIframe, referrer]);
};
