import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LiveStop } from '~/data-classes';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { mapLiveStopUtils } from '~/utils/map/map-livestop-utils';
import { getRouteSequenceNumber } from '~/utils/stopUtils';

export const useGetFilteredLiveStops = (inputValue: string) => {
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const liveDriverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );

    const isMatchingInputValue = (liveStop: LiveStop) => {
        const { stopName } = liveStop;
        const stopNumber = getRouteSequenceNumber(liveStop);

        return (
            `${stopNumber}. ${stopName}`
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
        );
    };

    return useMemo(() => {
        if (!liveDriverData || !inputValue) return [];
        const liveStopsWithoutBreaks =
            mapLiveStopUtils.filterLiveStopsWithoutBreak(
                liveDriverData.schedule
            ) as LiveStop[];
        return liveStopsWithoutBreaks.filter(isMatchingInputValue);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [inputValue, liveDriverData, selectedDrawerCardId]);
};
