const getStandardEasingTransition = ({
    name = 'all',
    duration = '250ms'
} = {}) => `${name} ${duration} cubic-bezier(0.4,0,0.2,1)`;

export default {
    getStandardEasingTransition,
    'standard-easing': getStandardEasingTransition(),
    'icon-transition': getStandardEasingTransition({ duration: '150ms' })
};
