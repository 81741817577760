import { useEffect, useState } from 'react';
import { Coordinates } from '~/api/types';
import { useDepots } from '~/hooks';
import constants from '~/utils/constants';

const {
    depots: { searchLimitRange }
} = constants;

export const useDepotCoordinates = () => {
    const { depotsList } = useDepots();
    const [southwestLatitude, setSouthwestLatitude] = useState(0);
    const [southwestLongitude, setSouthwestLongitude] = useState(0);
    const [northeastLatitude, setNortheastLatitude] = useState(0);
    const [northeastLongitude, setNortheastLongitude] = useState(0);

    useEffect(() => {
        if (!depotsList.length) return;

        const hqDepot = depotsList.find(({ hq }) => hq === true);
        if (!hqDepot?.address?.location) return;

        const { lat, lng } = hqDepot.address.location as Coordinates;

        setSouthwestLatitude(lat - searchLimitRange);
        setSouthwestLongitude(lng - searchLimitRange);
        setNortheastLatitude(lat + searchLimitRange);
        setNortheastLongitude(lng + searchLimitRange);
    }, [depotsList]);

    return {
        southwestLatitude,
        southwestLongitude,
        northeastLatitude,
        northeastLongitude
    };
};
