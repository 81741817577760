export enum DriverRoutePathType {
    /**
     * The polyline represents the initial path of the driver's route
     */
    INITIAL_PATH = 'INITIAL_PATH',

    /**
     * The polyline represents the actual path of the driver's route
     */
    ACTUAL_PATH = 'ACTUAL_PATH'
}

export interface DriverRoutePath {
    /**
     * The driver ID
     */
    driverId: string;

    /**
     * The driver's route ID
     */
    routeId: string;

    /**
     * The client ID
     */
    clientId: string;

    /**
     * The polyline encoding of the line to be drawn
     */
    polyline: string;

    /**
     * The route path type of the polyline
     */
    pathType: DriverRoutePathType;

    /**
     * The route date
     */
    routeDate: string;

    /**
     * The route duration in seconds
     */
    routeDuration: number;

    /**
     * The route length in meters
     */
    routeLength: number;
}

export type ApiInitialVsActualDriverRoutePaths = DriverRoutePath[];

export interface ClientIvASummary {
    clientId: string;
    lateStops: {
        planned: Planned;
        actual: Actual;
        status: string;
        percentDifference: number;
        averageLateStopPerDriver: number;
        topDrivers: {
            driverId: string;
            status: string;
            planned: Planned;
            actual: Actual;
            percentDifference: number;
        }[];
    };
    outOfOrder: {
        totalOutOfOrderStops: number;
        totalStops: number;
        status: string;
        percentDifference: number;
        averageOutOfOrderStopsPerDriver: number;
        topDrivers: {
            driverId: string;
            numberOfViolations: number;
            totalStops: number;
            percentageStopsOutOfOrder: number;
            status: string;
        }[];
    };
    canceledStops: {
        totalCanceledStops: number;
        totalStops: number;
        status: string;
        percentageCanceled: number;
        averageCanceledStopsPerDriver: number;
        topDrivers: {
            driverId: string;
            numberOfViolations: number;
            totalStops: number;
            percentageOfStopsCanceled: number;
            status: string;
        }[];
    };
    serviceTime: {
        plannedTotalServiceTime: string;
        actualTotalServiceTime: string;
        status: string;
        percentDifference: number;
        averageServiceTimePerDriver: string;
        topDrivers: {
            driverId: string;
            numberOfViolations: number;
            totalStops: number;
            plannedTotalServiceTime: string;
            actualTotalServiceTime: string;
            percentDifference: number;
            status: string;
        }[];
    };
}

export interface Planned {
    /**
     * The number of planned on time stops
     */
    onTime: number;
    /**
     * The number of planned stops at risk of being late
     */
    atRisk: number;
    /**
     * The number of planned late stops
     */
    late: number;
    /**
     * The sum of 'late' and 'atRisk' stops
     */
    lateOrAtRisk: number;
    /**
     * The total number of planned stops
     */
    total: number;
}

export interface Actual {
    /**
     * The number of actual on time stops
     */
    onTime: number;
    /**
     * The number of actual late stops
     */
    late: number;
    /**
     * The total number of actual stops
     */
    total: number;
}
