import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';

export const selectedDrawerCardIdSlice = createSlice({
    name: 'selectedDrawerCardId',
    initialState: null,
    reducers: {
        setSelectedDrawerCardId: (state, action) => {
            return action.payload;
        },
        resetSelectedDrawerCardId: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return null;
        });
    }
});

export const { setSelectedDrawerCardId, resetSelectedDrawerCardId } =
    selectedDrawerCardIdSlice.actions;

export const selectSelectedDrawerCardId = (state) => state.selectedDrawerCardId;

export default selectedDrawerCardIdSlice.reducer;
