import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectClientIds, selectClients } from '~/reducers/clientsSlice';
import { selectActiveClients } from '~/reducers/activeClientsSlice';
import { ApiClient } from '~/api/types';
import {
    selectSelectedClientIds,
    selectSelectedClients
} from '~/reducers/selectedClientsSlice';

interface UseSelectedClientsReturn {
    areClientsReady: boolean;
    userHasMultiAccess: boolean;
    allClients: Record<string, ApiClient>;
    selectedClients: Record<string, boolean>;
    connectedClients: Record<string, boolean>;
    areAllMultiClientsSelected: boolean;
    areMultipleClientsSelected: boolean;
}

const useSelectedClients = (): UseSelectedClientsReturn => {
    const clients = useSelector(selectClients);
    const clientIds = useSelector(selectClientIds);
    const selectedClients = useSelector(selectSelectedClients);
    const selectedClientIds = useSelector(selectSelectedClientIds);
    const activeClients = useSelector(selectActiveClients);
    const areAllMultiClientsSelected = useMemo(() => {
        return (
            selectedClientIds.length > 1 &&
            selectedClientIds.length === clientIds.length
        );
    }, [clientIds, selectedClientIds]);

    const isMultiAccess = useMemo(() => {
        return clientIds.length > 1;
    }, [clientIds]);

    const areClientsReady = useMemo(() => {
        return Boolean(clientIds.length);
    }, [clientIds]);

    const areMultipleClientsSelected = useMemo(() => {
        return selectedClientIds.length > 1;
    }, [selectedClientIds]);

    return {
        allClients: clients,
        areClientsReady,
        userHasMultiAccess: isMultiAccess,
        selectedClients,
        connectedClients: activeClients,
        areAllMultiClientsSelected,
        areMultipleClientsSelected
    };
};

export { useSelectedClients };
