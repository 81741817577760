import React from 'react';
import PT from 'prop-types';
import './loading.scss';

function LoadingBar() {
    return (
        <div className="loading_bar" data-testid="loading-bar">
            <div
                className="loading_indicator"
                data-testid="loading_indicator"
            />
        </div>
    );
}

function Loading({ fillViewport, offsetLevel }) {
    function _getClassName() {
        let boxClassName = 'loading _ai-center _jc-center';
        boxClassName = fillViewport
            ? `${boxClassName} loading_fillviewport _p-fixed`
            : `${boxClassName} loading_voffset`;
        return boxClassName;
    }
    return (
        <div className={_getClassName()} data-offset={offsetLevel}>
            <LoadingBar />
        </div>
    );
}

Loading.propTypes = {
    /**
     * Indicates whether the loading component should take the whole viewport, without offset.
     * When false, the component will be offset so as to be centered on top of the map.
     */
    fillViewport: PT.bool
};

Loading.defaultProps = {
    fillViewport: false
};

export { Loading, LoadingBar };
