import { DateTime } from 'luxon';
import { idUtils } from '~/utils/id-utils';
import driverUtils from '~/utils/driver-utils';
import {
    isAssignmentCompleted,
    isAssignmentCanceled
} from '~/utils/assignment-utils';
import LiveStop from './LiveStop';
import {
    ApiLiveDriver,
    ApiLiveStop,
    Coordinates,
    DriverLocationUpdate,
    VehicleType
} from '~/api/types';

/**
 * LiveDriver data class
 *
 * @category Data Classes
 *
 * @example
 * import { LiveDriver } from '~/data-classes';
 *
 * const srcData = {};
 * const liveDriver = new LiveDriver(srcData);
 *
 */
class LiveDriver {
    /**
     * The API source data
     * @type {ApiLiveDriver}
     */
    private readonly _liveDriver: ApiLiveDriver;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(liveDriver: ApiLiveDriver) {
        this._liveDriver = liveDriver;
    }

    /**
     * the live driver ID
     */
    get id(): string {
        return this._liveDriver.id;
    }

    /**
     * @borrows LiveDriver#id
     * the live driver ID
     * aliased to share a common property with PlanRoute
     */
    get driverId(): string {
        return this._liveDriver.id;
    }

    /**
     * the client ID
     */
    get clientId(): string {
        return this._liveDriver.clientId;
    }

    /**
     * the client-driver ID
     */
    get clientDriverId(): string {
        return idUtils.getCombinedId(this.clientId, this.id || 'unassigned');
    }

    /**
     * the driver name
     */
    get name(): string {
        const { name, firstname, lastname } = this._liveDriver.profile;
        const driverName = name || `${firstname} ${lastname}`;

        return driverUtils.getLocalizedDriverName(driverName);
    }

    /**
     * the driver initials
     */
    get initials(): string {
        return this._liveDriver.profile.initials;
    }

    /**
     * the route name
     */
    get routeName(): string {
        return this._liveDriver.routeName;
    }

    /**
     * the number of at-risk exceptions
     */
    get numAtRiskExceptions(): number {
        return this._liveDriver.stats.numAtRiskExceptions;
    }

    /**
     * the number of late exceptions
     */
    get numLateExceptions(): number {
        return this._liveDriver.stats.numLateExceptions;
    }

    /**
     * the number of inventory exceptions
     */
    get numInventoryExceptions(): number {
        return this._liveDriver.stats.numInventoryExceptions;
    }

    /**
     * indicates whether this driver has late stops
     */
    get hasLateStop(): boolean {
        return this.numLateExceptions > 0;
    }

    /**
     * indicates whether this driver has at-risk stops
     */
    get hasStopRisk(): boolean {
        return this.numAtRiskExceptions > 0;
    }

    /**
     * indicates whether this driver failed deliveries
     */
    get hasFailedDelivery(): boolean {
        return this.numInventoryExceptions > 0;
    }

    /**
     * indicates whether this driver encountered issues
     */
    get hasIssues(): boolean {
        return this.hasLateStop || this.hasStopRisk || this.hasFailedDelivery;
    }

    /**
     * the current stop's array index value
     */
    get currentStopIndex(): number {
        return this._liveDriver.stats.currentStop;
    }

    /**
     * the number of stops
     */
    get numStops(): number {
        return this._liveDriver.stats.numStops;
    }

    /**
     * indicates whether this driver has stops
     */
    get hasStops(): boolean {
        return !!this.numStops;
    }

    /**
     * the time remaining
     */
    get timeRemaining(): number | undefined {
        return this._liveDriver.stats?.timeRemaining;
    }

    /**
     * the number of confirmed inventory items
     */
    get numConfirmedInventoryItems(): number {
        return this._liveDriver.stats.numConfirmedInventoryItems;
    }

    /**
     * the number of inventory items
     */
    get numInventoryItems(): number {
        return this._liveDriver.stats.numInventoryItems;
    }

    /**
     * indicates whether this driver has completed this route
     */
    get isCompleted(): boolean {
        return this._liveDriver.stats.isDriverComplete;
    }

    /**
     * indicates whether this driver is active
     */
    get isDriverActive(): boolean {
        return this._liveDriver.stats.active;
    }

    /**
     * the total route distance
     * @todo `distance` is not available from the source. need to get it populated
     */
    get distance(): number {
        return this._liveDriver.stats.distance || 0;
    }

    /**
     * the vehicle ID
     */
    get vehicleId(): string {
        return this._liveDriver.vehicle.id;
    }

    /**
     * the vehicle type
     */
    get vehicleType(): VehicleType {
        return this._liveDriver.vehicle.type;
    }

    /**
     * the vehicle name
     */
    get vehicleName(): string {
        return `${this.vehicleType} ${this.routeName}`;
    }

    /**
     * the used volume capacity of this vehicle
     */
    get volumeCapacityUsed(): number {
        return this._liveDriver.vehicle.stats.volumeCapacityUsed;
    }

    /**
     * the maximum volume capacity of this vehicle
     */
    get maxVolume(): number {
        return this._liveDriver.vehicle.maxVolume;
    }

    /**
     * the used weight capacity of this vehicle
     */
    get weightCapacityUsed(): number {
        return this._liveDriver.vehicle.stats.weightCapacityUsed;
    }

    /**
     * the maximum weight capacity of this vehicle
     */
    get maxWeight(): number {
        return this._liveDriver.vehicle.maxWeight;
    }

    /**
     * the driver schedule
     */
    get schedule(): LiveStop[] {
        const reducedSchedule = (this._liveDriver.schedule || []).reduce(
            (all, stop) => {
                const { schedule, currentStopFound } = all;

                if (!currentStopFound) {
                    const isCanceled = isAssignmentCanceled(stop.status);
                    const isCompleted = isAssignmentCompleted(stop.status);
                    if (!(isCompleted || isCanceled) && !stop.isDepot) {
                        const updatedStop = {
                            ...stop,
                            isCurrentStop: true
                        } as ApiLiveStop;
                        schedule.push(new LiveStop(updatedStop));
                        return { schedule, currentStopFound: true };
                    }
                }

                schedule.push(new LiveStop(stop));
                return { schedule, currentStopFound };
            },
            { schedule: [] as LiveStop[], currentStopFound: false }
        );

        return reducedSchedule.schedule;
    }

    /**
     * the driver's current task in the schedule
     */
    get currentStop(): LiveStop | undefined {
        return this.schedule.find((stop) => stop.isCurrentStop);
    }

    /**
     * the driver's last location update
     */
    get lastLocationUpdate() {
        return this._liveDriver.lastLocationUpdate;
    }

    /**
     * indicates whether this driver has a schedule with only `completed` and `canceled` tasks
     */
    get isCompletedWithCanceledTasks(): boolean {
        // @note:
        // + hacky way to get a completed route with canceled tasks
        //   to be also considered `isComplete`
        // + ideally, API/CEP will flag it accordingly
        // @todo:
        // + remove `isCompletedWithCanceledTasks` when API-1927 is resolved
        return (
            this.schedule.length > 0 &&
            this.schedule.every(
                (task) =>
                    isAssignmentCompleted(task.status) ||
                    isAssignmentCanceled(task.status)
            )
        );
    }

    /**
     * the driver location
     */
    get location(): Coordinates {
        if (this._liveDriver.latestLocationUpdate) {
            const cepLocationTime = DateTime.fromISO(
                this._liveDriver.lastLocationUpdate
            );
            const locationUpdateTime = DateTime.fromISO(
                this._liveDriver.latestLocationUpdate.serverTime
            );
            const { lat, lng } = this._liveDriver.latestLocationUpdate;
            return locationUpdateTime > cepLocationTime
                ? { lat, lng }
                : this._liveDriver.cepLocation;
        }
        return this._liveDriver.cepLocation;
    }

    /**
     * updates the driver location
     * @method
     * @param {DriverLocationUpdate} latestUpdate
     */
    set latestLocationUpdate(latestUpdate: DriverLocationUpdate) {
        this._liveDriver.latestLocationUpdate = latestUpdate;
    }

    /**
     * the driver location direction in degrees
     */
    get direction(): number {
        return this._liveDriver.latestLocationUpdate?.direction || 45;
    }

    /**
     * the route center
     */
    get routeCenter(): Coordinates | null {
        return this._liveDriver.routeCenter;
    }

    /**
     * indicates whether the route is planned
     *
     * this property allows for driver data to be used with route marker
     *
     * @type {Boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get isPlanned(): boolean {
        return true;
    }

    /**
     * @borrows LiveDriver#hasStops
     */
    get hasTasks(): boolean {
        /** aliased to share a common property with PlanRoute. */
        return this.hasStops;
    }

    /**
     * @borrows LiveDriver#name
     */
    get driverName(): string {
        /** aliased to share a common property with PlanRoute. */
        return this.name;
    }

    /**
     * @borrows LiveDriver#timeRemaining
     */
    get duration(): number | undefined {
        /** aliased to share a common property with PlanRoute. */
        return this.timeRemaining;
    }

    /**
     * @borrows LiveDriver#maxVolume
     */
    get vehicleMaxVolume(): number {
        /** aliased to share a common property with PlanRoute. */
        return this.maxVolume;
    }

    /**
     * @borrows LiveDriver#maxWeight
     */
    get vehicleMaxWeight(): number {
        /** aliased to share a common property with PlanRoute. */
        return this.maxWeight;
    }

    /**
     * @borrows LiveDriver#volumeCapacityUsed
     */
    get vehicleVolumeUsed(): number {
        /** aliased to share a common property with PlanRoute. */
        return this.volumeCapacityUsed;
    }

    /**
     * @borrows LiveDriver#weightCapacityUsed
     */
    get vehicleWeightUsed(): number {
        /** aliased to share a common property with PlanRoute. */
        return this.weightCapacityUsed;
    }

    /**
     * @borrows LiveDriver#routeName
     */
    get vehicleEid(): string {
        /** aliased to share a common property with PlanRoute. */
        return this.routeName;
    }

    /**
     * whether this driver is ending their shift
     */
    get isEndingShift(): boolean {
        return Boolean(this._liveDriver.stats?.isEndingShift);
    }

    /**
     * Serializes this class back to JSON
     */
    toJSON(): ApiLiveDriver {
        return this._liveDriver;
    }

    /**
     *  the electric vehicle's remaining battery
     *  CURRENTLY MOCKED by modifying the max weight of the vehicle
     */
    get evBatteryRemaining(): number | undefined {
        const { maxWeight } = this._liveDriver.vehicle;
        const last2Digits = maxWeight % 100;
        return last2Digits === 0 ? undefined : last2Digits; // to simulate non-electric vehicles
    }
}
export default LiveDriver;
