import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalHeader from '~/components/MapPage/ModalHeader';

export const ReassignModalHeader = ({ numberOfSelectedStops = 0, onClose }) => {
    const { t } = useTranslation('translation');
    const title = t('ReassignModal.Header.title', {
        count: numberOfSelectedStops
    });

    return (
        <ModalHeader
            className="reassignmodal-header"
            onClose={onClose}
            title={title}
        />
    );
};
