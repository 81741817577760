import html2canvas from 'html2canvas';

const defaultMapContainerId = 'map';

export const htmlCanvasProps = {
    useCORS: true,
    width: 924,
    height: 532,
    ignoreElements: (node: { nodeName: string }) => {
        return node.nodeName === 'IFRAME';
    }
};

const saveBlob = (blob: Blob | null) => {
    const newWindow = window.open();

    if (!blob || !newWindow) return;

    const url = URL.createObjectURL(blob);
    const newImg = document.createElement('img');

    newImg.onload = () => {
        URL.revokeObjectURL(url);
        newWindow.print();
    };

    newImg.src = url;
    newWindow.document.body.appendChild(newImg);
};

export const printMap = (elementId: string = defaultMapContainerId) => {
    const htmlElement = document.getElementById(elementId);

    if (htmlElement) {
        html2canvas(htmlElement, htmlCanvasProps).then((canvas) => {
            canvas.toBlob(saveBlob);
        });
    }
};
