import { useDispatch, useSelector } from 'react-redux';
import { useLiveDriverBreak } from '~/hooks/useLiveDriverBreak';
import {
    resetSelectedBreakDetails,
    selectBreakDetailsId
} from '~/reducers/selectedBreakDetailsSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { LiveStop } from '~/data-classes';
import dateUtils from '~/utils/date-utils';

type ReturnProps = {
    handleDeselect: () => void;
    handleDelete: () => void;
    handleResequence: (startsAter: number) => void;
    handleEdit: (duration: number) => void;
};

export const useLiveButtonHandlers = (): ReturnProps => {
    const dispatch = useDispatch();
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const liveDriverData = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const selectedBreakDetailsId = useSelector(selectBreakDetailsId);

    const { deleteLiveBreak, editLiveBreak } = useLiveDriverBreak();

    const handleDeselect = () => {
        dispatch(resetSelectedBreakDetails());
    };

    const getAssignmentOfSelectedBreak = (liveDriverSchedule: LiveStop[]) => {
        return liveDriverSchedule.find(
            (liveStop) => liveStop.breakDetails?.id === selectedBreakDetailsId
        );
    };

    const getAssignmentDetails = (liveDriverSchedule: LiveStop[]) => {
        const assignmentOfSelectedBreak =
            getAssignmentOfSelectedBreak(liveDriverSchedule);
        const assignmentId = assignmentOfSelectedBreak?.id;
        const assignmentNumber = assignmentOfSelectedBreak?.stopNumber;
        return { assignmentOfSelectedBreak, assignmentId, assignmentNumber };
    };

    const getTimeElapsedSinceRouteCreation = (
        assignmentOfSelectedBreak: LiveStop
    ) => {
        const assignmentArrivalTime = assignmentOfSelectedBreak?.arrivalTime
            ? dateUtils
                  .convertISODateToJsDate(assignmentOfSelectedBreak.arrivalTime)
                  ?.getTime()
            : null;
        const routeStartTime = dateUtils
            .convertISODateToJsDate(liveDriverData.schedule[0].arrivalTime)
            ?.getTime();

        if (!assignmentArrivalTime || !routeStartTime) return 0;

        const currentTime = Date.now();
        return currentTime < routeStartTime ? 0 : currentTime - routeStartTime;
    };

    const handleDelete = async () => {
        const liveDriverSchedule = liveDriverData.schedule as LiveStop[];
        const { assignmentId } = getAssignmentDetails(liveDriverSchedule);
        if (!assignmentId) return;
        await deleteLiveBreak(assignmentId);
    };

    const handleResequence = async (startsAfter: number) => {
        const liveDriverSchedule = liveDriverData.schedule as LiveStop[];
        const { assignmentId, assignmentOfSelectedBreak } =
            getAssignmentDetails(liveDriverSchedule);
        const durationInMilliseconds =
            assignmentOfSelectedBreak?.breakDetails?.duration;
        if (!assignmentId || !durationInMilliseconds) return;
        const duration = durationInMilliseconds / 1000;

        const assignmentArrivalTime = assignmentOfSelectedBreak?.arrivalTime
            ? dateUtils
                  .convertISODateToJsDate(assignmentOfSelectedBreak.arrivalTime)
                  ?.getTime()
            : null;
        if (assignmentArrivalTime && assignmentArrivalTime < startsAfter) {
            startsAfter -= durationInMilliseconds;
        }

        const timeElapsedSinceRouteCreation = getTimeElapsedSinceRouteCreation(
            assignmentOfSelectedBreak
        );
        startsAfter += timeElapsedSinceRouteCreation;

        await editLiveBreak({ assignmentId, startsAfter, duration });
    };

    const handleEdit = async (duration: number) => {
        const liveDriverSchedule = liveDriverData.schedule as LiveStop[];
        const { assignmentId, assignmentNumber, assignmentOfSelectedBreak } =
            getAssignmentDetails(liveDriverSchedule);
        if (!assignmentNumber) return;
        const previousAssignmentArrivalTime =
            liveDriverSchedule[assignmentNumber - 1].arrivalTime;
        let startsAfter = dateUtils
            .convertISODateToJsDate(previousAssignmentArrivalTime)
            ?.getTime();
        if (!assignmentId || !assignmentOfSelectedBreak || !startsAfter) return;

        const timeElapsedSinceRouteCreation = getTimeElapsedSinceRouteCreation(
            assignmentOfSelectedBreak
        );

        startsAfter += timeElapsedSinceRouteCreation;

        await editLiveBreak({ assignmentId, startsAfter, duration });
    };

    return {
        handleDeselect,
        handleDelete,
        handleResequence,
        handleEdit
    };
};
