import React from 'react';
import { useFormikContext } from 'formik';
import classnames from 'classnames';

import './FormikTextInputField.scss';

type Props = {
    'data-testid'?: string;
    inputFieldId: string;
    label: string;
    className?: string;
    isOptional?: boolean;
    placeholder?: string;
};

export const FormikTextInputField = ({
    className,
    inputFieldId,
    isOptional = false,
    label,
    placeholder,
    ...extra
}: Props): JSX.Element => {
    const {
        errors = {},
        touched = {},
        values = {},
        isSubmitting,
        setFieldTouched,
        setFieldValue
    } = useFormikContext<Record<string, string>>();
    const isWithError = Boolean(errors[inputFieldId]);
    const isTouched = Boolean(touched[inputFieldId]);
    const isErrorState = isTouched && isWithError;
    const value = values[inputFieldId];

    return (
        <label
            htmlFor={inputFieldId}
            className={classnames(
                'formiktextinputfield _w-100 _d-grid',
                className
            )}
            data-testid={extra['data-testid'] || 'label-textinputfield'}
        >
            <div className="_fd-row" data-testid="label-textinputfield-title">
                {label && (
                    <>
                        <span className="formiktextinputfield-title">
                            {label}
                        </span>
                        {!isOptional && (
                            <span className="formik-text-input-field__asterisk">
                                *
                            </span>
                        )}
                    </>
                )}
            </div>

            <input
                type="text"
                id={inputFieldId}
                disabled={isSubmitting}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-input-element`
                        : 'textInputField'
                }
                className={classnames('label-input', {
                    'label-input--error': isErrorState
                })}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    setFieldValue?.(inputFieldId, e.target.value);
                }}
                onBlur={() => {
                    setFieldTouched?.(inputFieldId);
                }}
            />
        </label>
    );
};
