import colors from '~/ui/theme/colors';

export const DEFAULT_POLYGON_FILL_OPACITY = 0.3;

export const DEFAULT_MAKE_POLYGON_OPTIONS = {
    strokeColor: colors.palette.ocean,
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: colors.palette.ocean,
    fillOpacity: DEFAULT_POLYGON_FILL_OPACITY
};
