import { useMutation } from 'react-query';
import { ShiftEndApi } from '~/api/ShiftEndApi';
import {
    AxiosApiResponse,
    ReactQuerySideEffects,
    DeleteDriverResponseData
} from '~/api/types';
import { useWebInterval } from '~/hooks/useWebInterval';

type HookRemoveEndOfShiftFlag = {
    /**
     * removes end of shift flag from the driver
     */
    removeEndOfShift: (
        driverId: string,
        sideEffects: ReactQuerySideEffects
    ) => void;
};

/**
 * Implements removing end of shift flag for selected driver
 *
 * @category Hooks
 * @module useRemoveEndOfShift
 * @returns {HookRemoveEndOfShiftFlag}
 * @example <caption>Usage</caption>
 */
export const useRemoveEndOfShift = (): HookRemoveEndOfShiftFlag => {
    const { refetch } = useWebInterval();

    const { mutateAsync: doRemoveEndOfShift } = useMutation(
        (
            driverId: string
        ): Promise<AxiosApiResponse<DeleteDriverResponseData>> => {
            return ShiftEndApi.delete(driverId);
        }
    );

    /**
     * Remove End of shift flag from selected driver
     *
     * @method removeEndOfShift
     * @param {ReactQuerySideEffects} sideEffects - side effect callbacks
     */
    const removeEndOfShift = async (
        driverId: string,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData = await doRemoveEndOfShift(driverId);
            onSuccess?.(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            onError?.(error);
        }

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    return {
        removeEndOfShift
    };
};
