import { useContext } from 'react';
import { Context } from './types';
import { PlanMapDisplayContext } from './PlanMapDisplayContext';

export const usePlanMapDisplayContext = (): Context => {
    const context = useContext(PlanMapDisplayContext);

    if (!context) {
        throw new Error(
            'usePlanMapDisplayContext must be within context provider'
        );
    }

    return context;
};
