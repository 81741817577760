/* eslint-disable camelcase */

/**
 * Enum of supported custom cost matrix attribute types
 * @category API Types
 */
export enum CustomCostMatrixAttributeType {
    TRIP_DISTANCE = 'trip_distance',
    TRIP_WEIGHT = 'trip_weight',
    WAIT_TIME = 'wait_time'
}

/**
 * Enum of supported custom cost matrix calculation types
 * @category API Types
 */
export enum CustomCostMatrixCalculationType {
    PROGRESSIVE = 'progressive',
    CUTOFF = 'cutoff'
}

/**
 * Enum of supported custom cost matrix term types
 * @category API Types
 */
export enum CustomCostMatrixTermType {
    CONSTANT = 'constant',
    STOP = 'stop',
    TRIP = 'trip'
}

/**
 * Expected custom cost matrix attribute data used by [GET|POST] /custom_cost_matrices
 * @category API
 */
export interface CustomCostMatrixAttribute {
    /**
     * the custom cost attribute
     */
    attribute: CustomCostMatrixAttributeType;
    /**
     * the custom cost attribute values
     */
    values: Array<number>;
    /**
     * the custom cost attribute values for first of type scenarios
     */
    firstOfType?: Array<number>;
    /**
     * the custom cost attribute values for last of type scenarios
     */
    lastOfType?: Array<number>;
}

/**
 * Expected custom cost matrix attribute term (stopTerms and tripTerms) data
 * @category API
 */
export interface CustomCostMatrixAttributeTerm {
    /**
     * the custom cost attribute thresholds
     */
    thresholds: CustomCostMatrixAttribute;
    /**
     * the custom cost attribute weights
     */
    weights: CustomCostMatrixAttribute;
    /**
     * indicates whether the custom cost attribute term is progressive
     */
    isProgressive: boolean;
}

/**
 * Expected custom cost matrix constant term data
 * @category API
 */
export interface CustomCostMatrixConstantTerm {
    /**
     * the custom cost matrix constant term value
     */
    value: number;
}

/**
 * Expected custom cost matrix terms data
 * @category API
 */
export interface CustomCostMatrixTerms {
    /**
     * the custom cost constant term
     */
    constantTerm?: CustomCostMatrixConstantTerm;
    /**
     * the custom cost stop terms
     */
    stopTerms?: Array<CustomCostMatrixAttributeTerm>;
    /**
     * the custom cost tripterms
     */
    tripTerms?: Array<CustomCostMatrixAttributeTerm>;
}

/**
 * Expected custom cost matrix entries data
 * @category API
 */
export interface CustomCostMatrixEntries {
    /**
     * the custom cost entry starting zone ID
     */
    start_zone_id: string;
    /**
     * the custom cost entry ending zone ID
     */
    end_zone_id: string;
    /**
     * the custom cost configuration terms
     */
    custom_cost_terms: CustomCostMatrixTerms;
}

/**
 * Type custom cost matrix fields
 * @category API Types
 */
export interface ApiCustomCostMatrix {
    /**
     * the client ID associated with this custom cost matrix
     */
    client_id: string;
    /**
     * the custom cost matrix ID
     */
    id: string;
    /**
     * the custom cost descriptive name
     */
    name: string;
    /**
     * indicates whether the custom cost configuration is active
     */
    active: boolean;
    /**
     * the custom cost configuration entries
     */
    entries?: Array<CustomCostMatrixEntries>;
    /**
     * the create date for this custom cost matrix
     */
    created_at: string;
    /**
     * the last updated date for this custom cost matrix
     */
    updated_at: string;
}
