import _ from 'lodash';
import { idUtils } from '~/utils/id-utils';
import { planDataFactory, taskDataFactory } from '~/utils/data-factory';
import { routeHasMultipleTrips } from '~/utils/tripUtils';

/**
 * Map Mode Utilities, Dispatched Routes Mode
 *
 * @category Utils
 * @module utils/map/map-planstop-utils
 *
 * @example
 * import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
 */

/**
 * Map selected ids to stops data
 * @param {Object[]} planStops - array
 * @param {String[]} selectedMapStopsIds - array of string
 * @returns {{
 *  selectedStops: Object[],
 *  firstSelectedClientRouteId: string,
 *  unselectedStops:Object[] }} Object containing first selected id, selected stops data and unselected stops data
 * */
function mapIdtoStopData(planStops, selectedMapStopsIds) {
    const stopIdToStopMap = _.keyBy(planStops, 'clientRouteTaskId');
    const selectedStops = selectedMapStopsIds
        .map((stopId) => stopIdToStopMap[stopId])
        .filter(Boolean);

    const [firstSelectedStop] = selectedStops;

    const unselectedStops = _filterUnSelectedStops(
        planStops,
        selectedMapStopsIds
    );
    const allStops = _.sortBy(
        [...selectedStops, ...unselectedStops],
        'stopNumber'
    );

    return {
        allStops,
        firstSelectedStop,
        selectedStops,
        unselectedStops
    };
}

/**
 * Get unselected stops
 * @param {Object[]} planStops - array
 * @param {String[]} selectedMapStopsIds - array of string
 * @param {String} firstSelectedClientRouteId - string
 * @returns {Object[]} array
 * @private
 * */
function _filterUnSelectedStops(planStops, selectedMapStopsIds) {
    return planStops.filter(
        ({ clientRouteTaskId }) =>
            !selectedMapStopsIds.includes(clientRouteTaskId)
    );
}

/**
 * Evaluates if all stops have the same parent route (in case of reloads)
 * @param {array} selectedStops - array - plan stops or live stops
 * @returns {boolean} boolean
 * @private
 * */
export const stopsBelongToDifferentParentRoutes = (
    selectedStops,
    routeLevelData
) => {
    if (!selectedStops?.length || selectedStops.length < 2) {
        return false;
    }

    const topLevelRouteClientRoutes = routeLevelData.reduce(
        (aggregator, route) => {
            const { clientRouteId, clientRouteIds } = route;
            aggregator[clientRouteId] = aggregator[clientRouteId]
                ? [...aggregator[clientRouteId], ...clientRouteIds]
                : [...clientRouteIds];
            return aggregator;
        },
        {}
    );

    const uniqueParentRoutes = selectedStops.reduce((aggregator, stop) => {
        const { clientRouteId } = stop;
        // stop belongs to parent route
        if (topLevelRouteClientRoutes[clientRouteId]) {
            return [...aggregator, clientRouteId];
        }
        // stop belongs to a reloaded trip
        Object.keys(topLevelRouteClientRoutes).forEach(
            (parentClientRouteId) => {
                const clientRouteIds =
                    topLevelRouteClientRoutes[parentClientRouteId];
                if (routeHasMultipleTrips(clientRouteIds)) {
                    aggregator = [
                        ...aggregator,
                        ...(clientRouteIds.indexOf(clientRouteId) !== -1
                            ? [parentClientRouteId]
                            : [])
                    ];
                }
            }
        );
        return aggregator;
    }, []);
    return new Set(uniqueParentRoutes).size > 1;
};

/**
 * Drag and Drop to reorder items in a list
 * @param {Object} DragEvent - object
 * @param {Object[]} stops - array
 * @param {String} stopIdPropertyName - string
 * @param {Number} dragStartIndex - number
 * @param {Number} index - number
 * @param {Boolean} isOpenUnassignedTasksDrawer - boolean
 * @returns {String[]} array of string
 * */
function processStopsReorder(
    e,
    stops,
    stopIdPropertyName,
    dragStartIndex,
    index,
    isOpenUnassignedTasksDrawer = false
) {
    e.stopPropagation();
    const stopsArray = [...stops];
    const dragStartStopItem = stopsArray[dragStartIndex];

    stopsArray.splice(dragStartIndex, 1);
    stopsArray.splice(index, 0, dragStartStopItem);

    return stopsArray.map((stop) => {
        if (isOpenUnassignedTasksDrawer) {
            return stop.isPickup
                ? stop.pickupStopData.clientRouteTaskId
                : stop.deliveryStopData.clientRouteTaskId;
        }
        return stop[stopIdPropertyName];
    });
}

/**
 * Map two-part task data to on demand dispatch stops
 * @param {Object} apiTask - Api task object
 * @returns {Object[]} array with pickup and delivery on demand dispatch stops
 * */
function mapTwoPartTaskToOnDemandDispatchStops(apiTask) {
    const { deliveryLocation, pickupLocation } = apiTask;
    const pickupData = {
        ...apiTask,
        name: pickupLocation.name,
        deliveryLocation: null
    };
    const deliveryData = {
        ...apiTask,
        name: deliveryLocation.name,
        pickupLocation: null
    };

    const pickupOnDemandDispatchStop =
        taskDataFactory.makeOnDemandDispatchStop(pickupData);
    const deliveryOnDemandDispatchStop =
        taskDataFactory.makeOnDemandDispatchStop(deliveryData);

    return [pickupOnDemandDispatchStop, deliveryOnDemandDispatchStop];
}

/**
 * Map selected stop ids to tasks data
 * @param {Object[]} selectedMapStops - array
 * @param {Object} tasksData - object
 * @returns {Object[]} array of object
 * */
function mapIdtoTasksData(selectedMapStops, tasksData) {
    return selectedMapStops
        .map((id) => {
            // the incoming id is in the format: {{clientId}}_{{routeId}}_{{taskType}}_{{taskId}}
            const taskId = idUtils.splitCombinedId(id).pop();

            if (!tasksData[taskId]) return;

            // track the uniquely assembled 2-part task id
            const currentTaskData = { ...tasksData[taskId], twoPartId: id };
            const { deliveryLocation, pickupLocation } = currentTaskData;
            const isTwoPart = Boolean(deliveryLocation && pickupLocation);

            if (!isTwoPart)
                return taskDataFactory.makeOnDemandDispatchStop(
                    currentTaskData
                );

            const [pickupStopData, deliveryStopData] =
                mapTwoPartTaskToOnDemandDispatchStops(currentTaskData);

            if (idUtils.isTwoPartTaskStopDeliveryId(id)) {
                return deliveryStopData;
            }

            return pickupStopData;
        })
        .filter(Boolean);
}

/**
 * Get stops that can have a break inserted after
 * @param {Object[]} stopsLevelsData - array
 * @param {String | undefined} routeId - routeId of the trip the stop should belong to
 * @returns {Object[]} array of Plan Stops
 * */
function filterStopsWithoutBreak(stopsLevelData, routeId) {
    let planStops = stopsLevelData.map((stop) =>
        planDataFactory.makePlanStop(stop)
    );
    if (routeId)
        planStops = planStops.filter((stop) => stop.routeId === routeId);
    return planStops.filter((stop, i, arr) => {
        const isBeforeDepot = arr[i + 1]?.isDepot;
        const isWithBreak = Boolean(arr[i + 1]?.task.breakDetails);
        return !stop.isDepot && !isWithBreak && !isBeforeDepot;
    });
}

export const mapPlanStopUtils = {
    mapIdtoStopData,
    stopsBelongToDifferentParentRoutes,
    processStopsReorder,
    mapIdtoTasksData,
    filterStopsWithoutBreak,
    mapTwoPartTaskToOnDemandDispatchStops
};
