import React, { WheelEvent } from 'react';
import { StackPinMarkerPopupProps } from './types';
import classNames from 'classnames';

import { MarkerPopupFooter } from './MarkerPopupFooter';

import './StackPinMarkerPopup.scss';

export const rootClassName = 'stacked-stop-pin-popup';
export const stackedStops = `${rootClassName}__stacked-stops`;
export const scrollText = `${rootClassName}__scroll-text`;

export const StackPinMarkerPopup = ({
    tasksCount,
    children
}: StackPinMarkerPopupProps) => {
    const handleWheel = (event: WheelEvent<HTMLDivElement>) => {
        /**
         * This function stops the map zooming when scrolling the Pins inside the Popup
         */
        event.stopPropagation();
    };

    const getScrollableClassName = (boxClassName: string) => {
        const conditionalClasses = {
            [`${boxClassName}__scrollable`]: tasksCount > 5
        };
        return classNames(boxClassName, conditionalClasses);
    };

    return (
        <div className={getScrollableClassName(rootClassName)}>
            <div
                className={getScrollableClassName(stackedStops)}
                onWheel={handleWheel}
                data-testid={stackedStops}
            >
                {children}
            </div>
            <MarkerPopupFooter />
        </div>
    );
};
