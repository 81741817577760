import React from 'react';
import { useTranslation } from 'react-i18next';

import './RouteCardExportPill.scss';

export const RouteCardExportPill = () => {
    const { t } = useTranslation('translation');
    const rootClassName = 'route-card-export-pill';

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <span
                className={`${rootClassName}__text`}
                data-testid={`${rootClassName}-text`}
            >
                {t('exported')}
            </span>
        </div>
    );
};
