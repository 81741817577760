import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    UseMutateAsyncFunction,
    useMutation,
    useQuery,
    useQueryClient
} from 'react-query';

import { ApiAssignment } from '~/api/types';
import TasksApi from '~/api/TasksApi';
import AssignmentApi from '~/api/AssignmentApi';

import { addToast } from '~/reducers/toastsSlice';

import { UpdateRevisedTimes } from '~/components/TaskManagementPage/TaskDetailDrawer/types';

const placeholderData: ApiAssignment[] = [];

interface UseTaskAssignmentsResult {
    /**
     * The task assignments
     */
    assignments: ApiAssignment[];
    updateRevisedTimes: UseMutateAsyncFunction<
        unknown,
        unknown,
        UpdateRevisedTimes
    >;
}

interface UseTaskAssignmentsProps {
    /**
     * The task id
     */
    taskId?: string;
}

export const useTaskAssignments = ({
    taskId
}: UseTaskAssignmentsProps): UseTaskAssignmentsResult => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { t } = useTranslation('taskManagement');

    const queryKeyPrefix = 'taskAssignments';
    const { data: assignmentsData = placeholderData } = useQuery<
        ApiAssignment[]
    >({
        placeholderData,
        enabled: Boolean(taskId),
        queryKey: [queryKeyPrefix, taskId],
        queryFn: async () => {
            try {
                const {
                    data: { data: taskAssignments }
                } = await TasksApi.getTaskAssignments(taskId as string);

                return taskAssignments;
            } catch (error) {
                console.error(error);
                return placeholderData;
            }
        }
    });

    const { mutateAsync: updateRevisedTimes } = useMutation(
        async ({
            assignmentId,
            revisedStartServiceAt,
            revisedCompletedAt
        }: UpdateRevisedTimes) => {
            await AssignmentApi.updateRevisedTimes({
                assignmentId,
                revisedStartServiceAt,
                revisedCompletedAt
            });
        },
        {
            onSuccess: () => {
                dispatch(
                    addToast({
                        message: t('reviseTime.successUpdate'),
                        variant: 'success'
                    })
                );
                return queryClient.invalidateQueries(queryKeyPrefix);
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:updateRevisedTimesError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    return {
        assignments: assignmentsData,
        updateRevisedTimes
    };
};
