import { useDispatch } from 'react-redux';
import { VehicleApi } from '~/api/VehicleApi';
import { updateDriversByClient } from '~/reducers/driversByClientSlice';
import { updateVehicle } from '~/reducers/vehiclesSlice';

export const useUpdateVehicle = () => {
    const dispatch = useDispatch();
    async function updateVehicleInfo(vehicleId, update, driver) {
        const {
            data: { data: vehicle }
        } = await VehicleApi.put(vehicleId, update);

        dispatch(
            updateVehicle({
                id: vehicleId,
                data: vehicle
            })
        );

        // update driver.vehicle to avoid it being a stale value
        if (driver) {
            const { id: driverId, client: clientId } = driver;
            dispatch(
                updateDriversByClient({
                    driverId,
                    data: { ...driver, vehicle },
                    clientId
                })
            );
        }
    }
    return { updateVehicleInfo };
};
