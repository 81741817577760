import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import constants from '~/utils/constants';

const {
    mapOptionSettings: { DEFAULT_CENTER }
} = constants;

export const lastPlanMapCenterSlice = createSlice({
    name: 'lastPlanMapCenter',
    // wiseSystems HQ until user starts panning plan map
    initialState: DEFAULT_CENTER,
    reducers: {
        setLastPlanMapCenter: (state, action) => {
            const isObject = _.isObject(action.payload);
            const hasLatLng =
                Object.prototype.hasOwnProperty.call(action.payload, 'lat') &&
                Object.prototype.hasOwnProperty.call(action.payload, 'lng');
            if (isObject && hasLatLng) {
                return {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                };
            }
            return state;
        },
        resetLastPlanMapCenter: () => {
            return DEFAULT_CENTER;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return DEFAULT_CENTER;
        });
    }
});

export const { setLastPlanMapCenter, resetLastPlanMapCenter } =
    lastPlanMapCenterSlice.actions;
export const selectLastPlanMapCenter = (state) => state.lastPlanMapCenter;
export default lastPlanMapCenterSlice.reducer;
