import * as iconSource from '~/ui/assets/icons';

/**
 * String union of supported svg icon files
 */
export type IconName = keyof typeof iconSource;

/**
 * Get svg icon source url
 */
export const getIconSource = (icon: IconName): string | undefined =>
    iconSource[icon as keyof typeof iconSource];

/**
 * Array of supported svg icon files
 */
export const IconNamesKeys = Object.keys(iconSource);
