import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import InitialVsActualApi, {
    GetDriverRoutePathsParams
} from '~/api/InitialVsActualApi';

export const useDriverRoutePaths = (
    params: GetDriverRoutePathsParams,
    reactQueryOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {}
): UseQueryResult => {
    // get API response
    const getDriverRoutePaths = async (
        routePathsParams: GetDriverRoutePathsParams
    ) => {
        const {
            data: { data }
        } = await InitialVsActualApi.getDriverRoutePaths(routePathsParams);

        return data;
    };

    const defaultReactQueryOptions: Omit<
        UseQueryOptions,
        'queryKey' | 'queryFn'
    > = {
        // prevent data from being refetched until after 1 hour
        staleTime: 1000 * 60 * 60 * 1
    };

    // merge query options
    const mergedOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // return the full useQuery Object
    const { driverId, date } = params;
    return useQuery(
        [InitialVsActualApi.REACT_QUERY_KEY, driverId, date],
        () => getDriverRoutePaths(params),
        mergedOptions
    );
};
