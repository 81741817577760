import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import InventoryItemsApi from '~/api/InventoryItemsApi';

import { useToastContext } from '~/hooks';

export const useInventoryItem = () => {
    const { t } = useTranslation('error');

    const queryClient = useQueryClient();
    const { addToast } = useToastContext();

    const { mutateAsync: deleteInventoryItem } = useMutation(
        async (id: string) => {
            await InventoryItemsApi.deleteInventoryItem(id);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(
                    InventoryItemsApi.REACT_QUERY_KEY
                );
            },
            onError: (error) => {
                console.error(error);
                addToast({
                    message: t('error:inventoryItemsFetchError'),
                    variant: 'error'
                });
            }
        }
    );

    return { deleteInventoryItem };
};
