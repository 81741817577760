import { useQuery, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { SuggestApi } from '~/api/SuggestApi';
import { SuggestOptions, SupportedSuggestMetric, ApiDriver } from '~/api/types';

import { selectDriversByClient } from '~/reducers/driversByClientSlice';

import {
    OnDemandDispatchDriver,
    OnDemandDispatchSuggestions,
    taskDataFactory
} from '~/utils/data-factory';
import constants from '~/utils/constants';

import { selectLiveDrivers } from '~/reducers/liveDriversSlice';
import { idUtils } from '~/utils/id-utils';

const bulkSuggest = async ({
    selectedTaskIds,
    drivers,
    apiOptions,
    excludedDrivers = []
}: {
    selectedTaskIds: string[];
    drivers: OnDemandDispatchDriver[];
    apiOptions: SuggestOptions;
    excludedDrivers?: string[];
}) => {
    const { data: bulkSuggestResponse } = await SuggestApi.bulkSuggest({
        tasks: selectedTaskIds,
        excludedDrivers,
        options: apiOptions
    });

    const { suggestedDrivers, tasks } = bulkSuggestResponse.data;

    const consolidatedSuggestions = suggestedDrivers.reduce(
        (acc: Record<string, OnDemandDispatchSuggestions>, suggestionData) => {
            const { driver: driverId } = suggestionData;

            if (!acc[driverId]) {
                const driverData = drivers.find(
                    (driver) => driver.id === driverId
                );
                if (!driverData) return acc;
                acc[driverId] = taskDataFactory.makeOnDemandDispatchSuggestion(
                    driverData,
                    tasks
                );
            }

            acc[driverId].suggestions.push(suggestionData);

            return acc;
        },
        {}
    );

    // the suggest api currently returns drivers of all status types
    // we are manually filtering the data to only include `dispatched` drivers
    const filteredSuggestions = Object.values(consolidatedSuggestions).filter(
        (item) => {
            return item.driver.isDispatchedDriver;
        }
    );

    return filteredSuggestions;
};

// the bulk suggest API uses `total route time` under the hood as the default order by
// by not explicitly specifying it, the API will resolve tie breakers and provide results accordingly
export const defaultBulkSuggestOrderBy =
    SupportedSuggestMetric.TOTAL_ROUTE_TIME;

export const defaultBulkSuggestOptions = {
    includeMetrics: true,
    prioritizeActiveDrivers: true
};

export const useBulkSuggest = (
    selectedTaskIds: string[],
    apiOptions: SuggestOptions = {},
    reactQueryOptions: Record<string, unknown> = {}
): UseQueryResult<OnDemandDispatchSuggestions> => {
    // get cached drivers from redux
    const liveDrivers = useSelector(selectLiveDrivers);
    const driversByClientId = useSelector(selectDriversByClient);
    const [...drivers] = Object.values(driversByClientId)[0] as ApiDriver[];

    const driversData = drivers.map((driver) => {
        const { client: clientId, id: driverId } = driver;
        const clientDriverId = idUtils.getCombinedId(
            clientId as string,
            driverId as string
        );
        const liveDriver = liveDrivers[clientDriverId];
        const isLiveDriver = Boolean(liveDriver);
        const isDispatchedDriver =
            isLiveDriver && !liveDriver.stats.isDriverComplete;
        const isCompletedDriver =
            isLiveDriver && liveDriver.stats.isDriverComplete;
        return {
            ...driver,
            clientDriverId,
            isLiveDriver,
            isDispatchedDriver,
            isCompletedDriver
        };
    });

    const excludedDrivers = driversData
        .filter(({ isLiveDriver }) => !isLiveDriver)
        .map(({ id }) => id) as string[];

    // get API response
    return useQuery(
        [constants.reactQueryKeys.TASKS_BULK_SUGGEST, selectedTaskIds],
        () =>
            bulkSuggest({
                selectedTaskIds,
                excludedDrivers,
                drivers: driversData,
                apiOptions
            }),
        reactQueryOptions
    );
};
