import React, { useCallback, useState } from 'react';
import { useModal } from '~/ui';
import { DuplicateTaskModal } from './DuplicateTaskModal';

export const useDuplicateTaskModal = () => {
    const { isVisible, hideModal, modal: Modal, showModal } = useModal();
    const [taskId, setTaskId] = useState<string | null>(null);

    const onShowModal = useCallback(
        (newTaskId: string) => {
            setTaskId(newTaskId);
            showModal();
        },
        [showModal]
    );

    const onHideModal = useCallback(() => {
        setTaskId(null);
        hideModal();
    }, [hideModal]);

    const duplicateTaskModal = isVisible ? (
        <DuplicateTaskModal
            Modal={Modal}
            onClose={onHideModal}
            taskId={taskId}
        />
    ) : null;

    return { duplicateTaskModal, onShowModal, onHideModal, taskId };
};
