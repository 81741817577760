import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DriverApi from '~/api/DriverApi';
import {
    updateDriversByClient,
    removeDriverById,
    addDriverByClient
} from '~/reducers/driversByClientSlice';
import { addToast } from '~/reducers/toastsSlice';
import { setSelectedDriverVehicleRowId } from '~/reducers/selectedDriverVehicleRowIdSlice';
import { addVehicle } from '~/reducers/vehiclesSlice';

export const useUpdateDriver = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['error', 'driverManagement']);
    async function updateDriver(driverId, update, clientId) {
        const {
            data: { data }
        } = await DriverApi.patch(driverId, update, clientId);
        dispatch(updateDriversByClient({ driverId, data, clientId }));
    }

    async function unassignVehicleFromDriver(selectedDriver) {
        const { id: driverId, client: clientId } = selectedDriver;
        const {
            data: { data }
        } = await DriverApi.unassignVehicleFromDriver(driverId, clientId);

        const { items: drivers } = data;
        const { driver, defaultVehicle } = drivers[0];
        const driverVehicleId = `${driver}_${defaultVehicle}`;

        dispatch(
            updateDriversByClient({
                driverId,
                data: drivers[0],
                clientId
            })
        );
        dispatch(setSelectedDriverVehicleRowId(driverVehicleId));
    }

    async function assignVehicleToDriver(selectedDriver, vehicleId) {
        const { id: driverId, client: clientId } = selectedDriver;
        const {
            data: { data }
        } = await DriverApi.assignDefaultVehicle(driverId, vehicleId, clientId);

        const { items: drivers } = data;
        const { driver, defaultVehicle } = drivers[0];
        const driverVehicleId = `${driver}_${defaultVehicle}`;

        dispatch(
            updateDriversByClient({
                driverId,
                data: drivers[0],
                clientId
            })
        );
        dispatch(setSelectedDriverVehicleRowId(driverVehicleId));
    }

    async function deleteDriver(selectedDriver) {
        const { id: driverId, client: clientId } = selectedDriver;

        try {
            const {
                data: { data }
            } = await DriverApi.delete(driverId);

            const { id } = data;
            dispatch(removeDriverById({ id, clientId }));
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('driverDeleteError'),
                    variant: 'error'
                })
            );
        }
    }

    async function createDriver(
        driverInfo,
        onSuccess = () => {},
        onError = () => {}
    ) {
        try {
            const {
                data: {
                    data: { client, driver }
                }
            } = await DriverApi.post(driverInfo);

            dispatch(addDriverByClient({ data: driver, clientId: client }));
            onSuccess();
            dispatch(
                addToast({
                    message: t('driverManagement:addDriverSuccess'),
                    variant: 'success'
                })
            );
        } catch (error) {
            console.error(error);
            onError(t('driverAddError'));
        }
    }

    async function createDriverVehicle(driverPayload, vehiclePayload) {
        try {
            const params = {
                ...driverPayload,
                eid: vehiclePayload.eid,
                vehicle: vehiclePayload
            };
            const {
                data: {
                    data: { client, driver, vehicle }
                }
            } = await DriverApi.post(params);

            dispatch(addDriverByClient({ data: driver, clientId: client }));
            dispatch(addVehicle(vehicle));

            dispatch(
                addToast({
                    message: t('driverManagement:addDriverVehicleSuccess'),
                    variant: 'success'
                })
            );
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    return {
        assignVehicleToDriver,
        createDriver,
        deleteDriver,
        updateDriver,
        unassignVehicleFromDriver,
        createDriverVehicle
    };
};
